


import React, { useState } from "react";
import { Layout, Dropdown, Button, Menu, Typography } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
const { Text } = Typography;
export default function SortBy(props) {
  const { setsort, setsortBy, setisFilterApplied } = props;
  const [sortValue, setSortValue] = useState("Relevance");
  const [sortType, setsortType] = useState("");
  const renderSortStatus = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            setSortValue("Relevance");
            setisFilterApplied(true);
            setsortType("");
            setsort("");
            setsortBy("");
          }}
        >
          Relevance
          {sortValue === "Relevance" && sortType === "" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setSortValue("(Low - High)");
            setisFilterApplied(true);
            setsortType("Price");
            setsort("asc");
            setsortBy("price");
          }}
        >
          Price (Low -High)
          {sortValue === "(Low - High)" && sortType === "Price" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setSortValue("(High - Low)");
            setisFilterApplied(true);
            setsortType("Price");
            setsort("desc");
            setsortBy("price");
          }}
        >
          Price (High - Low)
          {sortValue === "(High - Low)" && sortType === "Price" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <Dropdown
      overlay={() => renderSortStatus()}
      placement="bottomCenter"
      trigger={["click"]}
      className="sort-dropdown-admin"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Button>
        <span style={{ color: "#827e7e" }}>Sort By: </span>
        <span>
          <Text strong>&nbsp; {sortType}</Text>
          <Text strong style={{ color: "red" }}>
            {" "}
            {sortValue}
          </Text>
        </span>
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
}
