

import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./breadCrumb.scss";
import { ENV_TYPE } from "../../../../Utils/constants";
import { formatBreadCrumbData } from "./breadCrumbHelper";

import {
  addCategoryfilter,
  addSearchFilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
  clearProductListingFilter,
} from "../../../../ReduxStore/action/productActions";

function BreadCrumb(props) {
  const { breadCrumbData } = props;
  const [breadcrumbList, setbreadcrumbList] = useState();

  const handleHomePgaeRedirection = () => {
    props.clearProductListingFilter();
    ENV_TYPE === "production"
      ? props.history.push("/")
      : props.history.push("/customer/home-page");
  };
  const handleRedirection = (data) => {
    console.log("Redirection data: ", data);

    switch (data.type) {
      case "product":
        props.history.push(
          ENV_TYPE === "production"
            ? `/product-detail/${data.id}`
            : `/customer/product-detail/${data.id}`
        );
        break;

      case "search":
        props.addSearchFilter(data.label);
        props.history.push(
          ENV_TYPE === "production"
            ? `/product-listing/${data.label}`
            : `/customer/product-listing/${data.label}`
        );
        break;

      case "category":
        props.addCategoryfilter(data.id);
        props.history.push(
          ENV_TYPE === "production"
            ? `/product-listing/${data.label}`
            : `/customer/product-listing/${data.label}`
        );
        break;

      case "subCategory0":
        props.addSubCategory00filter(data.id);
        props.history.push(
          ENV_TYPE === "production"
            ? `/product-listing/${data.label}`
            : `/customer/product-listing/${data.label}`
        );
        break;

      case "subCategory1":
        props.addSubCategory01filter(data.id);
        props.history.push(
          ENV_TYPE === "production"
            ? `/product-listing/${data.label}`
            : `/customer/product-listing/${data.label}`
        );
        break;

      case "subCategory2":
        props.addSubCategory02filter(data.id);
        props.history.push(
          ENV_TYPE === "production"
            ? `/product-listing/${data.label}`
            : `/customer/product-listing/${data.label}`
        );
        break;
      case "subCategory3":
        props.addSubCategory03filter(data.id);
        props.history.push(
          ENV_TYPE === "production"
            ? `/product-listing/${data.label}`
            : `/customer/product-listing/${data.label}`
        );
        break;

      case "section":
        props.history.push(
          ENV_TYPE === "production"
            ? `/product-listing/${data.label}/${data.id}`
            : `/customer/product-listing/${data.label}/${data.id}`
        );

      default:
        console.log("invalid case");
    }
  };

  useEffect(() => {
    console.log("bread crumb data for product listing: ", breadCrumbData);

    breadCrumbData?.type &&
      setbreadcrumbList(formatBreadCrumbData(breadCrumbData));
  }, [breadCrumbData]);

  return (
    <Breadcrumb className="breadcrumb_data">
      <Breadcrumb.Item
        style={{ cursor: "pointer" }}
        onClick={() => handleHomePgaeRedirection()}
      >
        Home
      </Breadcrumb.Item>
      {breadcrumbList?.length > 0 &&
        breadcrumbList.map((data, index) => {
          return (
            <Breadcrumb.Item
              key={data.id}
              onClick={() => handleRedirection(data)}
              style={{ cursor: "pointer" }}
            >
              {data.label}
            </Breadcrumb.Item>
          );
        })}
    </Breadcrumb>
  );
}

const mapDispatchToProps = {
  addCategoryfilter,
  addSearchFilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
  clearProductListingFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(BreadCrumb));
