

import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Typography, Input, Modal, message, Button } from "antd";
import "./managePincode.css";
import axios from "axios";
import { setPincode } from "../../../ReduxStore/action/productActions";
import CompanyCartLogo from "../../../Assets/Images/Logo/DL_Logo.png";

import Cookies from "universal-cookie";
import { DOMAIN, ENV_TYPE } from "../../../Utils/constants";
import { checkProductAvailabilityAtPincode, isDeliverable, nearestPincodeForPincode, validatePincode } from "../../../ApiAction/CustomerApiActions/productApi";
import { fetchCartCount } from "../../../ReduxStore/action";
import { addToCart } from "../../../ApiAction/SellerApiActions/sellerApi";
import { history } from "../../../App";
import Loading from "../../../components/GeneralComponents/Loading";
import { AppContext } from "../../../components/GeneralComponents/appContext";
import { formatProductData } from "../../../components/Customer/Components/ProductListing/productListingHelper";

const cookies = new Cookies();
const { Text } = Typography;
const warning = Modal.warning;

const ManagePincode = (props) => {
  const [isError, setisError] = useState(false);
  const [pincodeValue, setPincodeValue] = useState(props.pinNo);
  const [isLoadingPin, setisLoadingPin] = useState(false);
  const [activeCustomer, setactiveCustomer] = useState(
    JSON.parse(localStorage.getItem("activeCustomer"))
  );
  const ProviderProps = useContext(AppContext);

  const handleUpdateGuestCart = async (product, selectedItem) => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart"));
    const temp = {
      productId: product.productId,
      productName: product.productName,
      vendorId: product.vendorId,
      vendorType: product.vendorType,
      quantity: ProviderProps.quantity,
      image: product.productImage,
      itemCurrentPrice: selectedItem,
      processedPriceAndStocks: product.processedPrice,
      itemId: product.productId + selectedItem.serialNumber,
    };
    console.log(temp, guestCart, "guest cart validation");
    if (guestCart) {

      if (await handleCheckInCart(product, selectedItem)) {
        // message.warning("Product already added to cart");
        history.push(
          ENV_TYPE === "production" ? `/cart` : `/customer/cart`
        );
      } else {
        localStorage.setItem("guestCart", JSON.stringify([...guestCart, temp]));
        message.success("Product added to cart");
        //this part of code is for calculating guest cart count
        const cartCount = guestCart.reduce((prev, cur) => {
          return prev + cur.quantity;
        }, 0);
        props.fetchCartCount(cartCount + temp.quantity);
      }
    } else {
      localStorage.setItem("guestCart", JSON.stringify([temp]));
      message.success("Product added to cart", 3);
      //this part of code is for calculating guest cart count
      props.fetchCartCount(temp.quantity);
    }
  };

  const handleCustomerAddToCart = (product, selectedItem) => {

    if (!activeCustomer) {
      handleUpdateGuestCart(product, selectedItem);
      return;
    }

    const paylaod = {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
      item: {
        productId: product.productId,
        vendorId: product.vendorId,
        vendorType: product.vendorType,
        quantity: Math.abs(ProviderProps.quantity),
        itemCurrentPrice: selectedItem,
      },
    };

    addToCart(paylaod)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.fetchCartCount(props.cartCountStore + 1);
            message.success(response.data.message);
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleCheckInCart = async (product, selectedItem) => {

    const guestCart = JSON.parse(localStorage.getItem("guestCart"));
    let isAlreadyExists = false;

    if (guestCart) {
      isAlreadyExists = await guestCart.find((item) => {
        return (
          item.productId == product.productId &&
          item.itemCurrentPrice.serialNumber === selectedItem.serialNumber
        );
      });
    } else if (props.cartItems) {
      isAlreadyExists = await props.cartItems.find((item) => {
        return (
          item.productId == product.productId &&
          item.serialNumber === selectedItem.serialNumber
        );
      });
    }
    return isAlreadyExists;
  };

  const addProductToCart = async () => {

    props.setVisible(false);
    ProviderProps.setPincodeModalVisible(false);

    if(!ProviderProps.product.serviceLocations.includes(pincodeValue) && !ProviderProps.product.serviceLocations.includes('All India')) {

      const payload = {
        name: ProviderProps.product.productName?.replace(/\//g,' '),
        pincode: pincodeValue,
        quantity: ProviderProps.quantity
      }

      checkProductAvailabilityAtPincode(payload).then(async (response) => {

        let product = ProviderProps.product;
        let selectedItem = ProviderProps.selectedItem;

        if(response.status === 200) {
          if(response.data.status == "success") {

            if(!response.data.availability)
              message.info("Vendor may not deliver to pincode")

            product = formatProductData([response.data.product])[0];

            let temp;
            temp = product.processedPrice
              .find(p => p.quantity == selectedItem.quantity && p.unit == selectedItem.unit)

            if(temp) {

              let priceMatchingStock = product.processedPrice
              .find(p => p.quantity == selectedItem.quantity && p.unit == selectedItem.unit
                && p.sellingPrice == selectedItem.sellingPrice);

              if(priceMatchingStock)
                selectedItem = priceMatchingStock
              else {
                selectedItem = temp;
                stockVariationWarning(selectedItem);
              }

            } else {
              selectedItem = product.processedPrice?.[0];
              stockVariationWarning(selectedItem);
            }

            if(selectedItem.stock >= ProviderProps.quantity) {
              setPincodeInHeader(pincodeValue);
              if (props.cartItems) {

                if (await handleCheckInCart(product, selectedItem)) {
                  history.push(
                    ENV_TYPE === "production" ? `/cart` : `/customer/cart`
                  );
                } else {
                  handleCustomerAddToCart(product, selectedItem);
                }
              } else handleCustomerAddToCart(product, selectedItem);

              // handleCheckAvailibality(pincodeValue);

            } else {
              message.error("Product out of stock")
            }
          } else {
            console.log("LOCATION :::::", history.location)

            setPincodeValue(pincodeValue);
            handleCheckAvailibality(pincodeValue);

            Modal.warn({
              title: 'Product not deliverable to this pincode',
              okText: "Ok",
              content: (
                <div>
                  <p>Please contact vendor directly.</p>
                  <p>Products available for <b>{pincodeValue}</b> will be shown now</p>
                </div>
              )
            });
          }

          ProviderProps.setQuantity(null);
          ProviderProps.setSelectedItem(null);
          ProviderProps.setProduct(null);

        }

        // nearestPincodeForPincode(payload).then((response) => {
        //   if(response.status == 200) {
        //     if(response.data.status == "success") {
        //       product = formatProductData([response.data.product])[0];
        //     } else if(response.data.message == "Product not in stock")
        //       message.warn("Product out of stock", 6)
        //   }
        //   productChangeModal(product);
        // });

      });
    } else {
      handleCustomerAddToCart(ProviderProps.product, ProviderProps.selectedItem);
    }


  }

  const stockVariationWarning = (selectedItem) => {
    warning({
      title: "Selected quantity or selling price for product in pincode is not available",
      className: "create-confirm-modal",
      content: (
        <div className="product-not-deliverable-container">
          <p>New Selling Price: </p><b>₹ {selectedItem.sellingPrice}</b>
          <p>Quantity: </p><b>{`${selectedItem.quantity} ${selectedItem.unit}`}</b>
        </div>
      )
    });
  }

  // const productChangeModal = (product) => {
  //   console.log(product)
  //   const serviceLocations = product.serviceLocations.map(o => Number(o));
  //   const nearestPincode = serviceLocations.reduce((prev, curr) => Math.abs(curr - pincodeValue) < Math.abs(prev - pincodeValue) ? curr : prev);
  //   if(nearestPincode != pincodeValue) {
  //     Modal.confirm({
  //       title: 'Product not available at pincode',
  //       cancelText: "No",
  //       okText: "Yes",
  //       content: (
  //         <div>
  //           <p>Nearest available pincode: <b>{nearestPincode}</b></p>
  //           <p>Do you want to change to this pincode?</p>
  //         </div>
  //       ),
  //       onOk: async () => {
  //         let selectedItem = product.processedPrice?.[0];
  //         if(selectedItem.stock >= ProviderProps.quantity) {
  //           if (props.cartItems) {

  //             if (await handleCheckInCart(product, selectedItem)) {
  //               history.push(
  //                 ENV_TYPE === "production" ? `/cart` : `/customer/cart`
  //               );
  //             } else {
  //               handleCustomerAddToCart(product, selectedItem);
  //             }
  //           } else handleCustomerAddToCart(product, selectedItem);

  //         } else {
  //           message.error("Product out of stock")
  //         }
  //         props.setVisible(false);
  //         handleCheckAvailibality(nearestPincode);
  //       },
  //       onCancel: () => handleCheckAvailibality(pincodeValue)
  //     });
  //   }
  // }

  const handleShowWarning = (message) => {
    warning({
      title: message,
      className: "create-confirm-modal",
    });
  };

  const handleClearPincode = () => {
    if (ENV_TYPE == "dev") {
      cookies.remove("activeUserPincode", {
        path: "/",
      });
    } else
      cookies.remove("activeUserPincode", {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    props.setPincode("");
    props.setVisible(true);
    setPincodeValue("");
    props.setPinNo("");
  };

  const handleValidatePincode = (event) => {
    if (event.target.value === "") {
      console.log("closable exicuted here");
      handleClearPincode();
    }
    console.log(event.target.value, ":pincode field");
    var numbers = /^[0-9]+$/;

    if (event.target.value.match(numbers)) {
      console.log("not error")
      setisError(false);
      setPincodeValue(event.target.value);
    } else setisError(true);
  };

  const handlePincodeUpdate = (pincodeValue) => {
    var numbers = /^[1-9][0-9]{5}$/;
    console.log(pincodeValue);
    if (!isError && pincodeValue.match(numbers)) {
        setisLoadingPin(true);
        validatePincode(pincodeValue)
          .then((response) => {
            if (response.status == 200 && response.data.status == "success") {
              handleCheckAvailibality(pincodeValue, true);
              console.log("event : ", pincodeValue);
            } else {
              handleShowWarning("Please enter a valid pincode");
              setisLoadingPin(false);
            }
          })
          .catch((error) => {
            console.log("Pincode validation Error :: ", error.message);
          });

    } else {
      // handleClearPincode();
      setisError(true);
    }
  };

  const handleCheckAvailibality = (pincode, addProduct) => {
    isDeliverable(pincode)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "failed") {
            if (!response.data.availability) {
              handleShowWarning(response.data.message);
              handleClearPincode();
              if(!ProviderProps.product)
                setPincodeValue(props.pincode);
            }
          } else {
            if (!response.data.availability) {
              if(response.data.nearestPincode) {
                setPincodeValue(pincode);
                props.setPincode(response.data.nearestPincode);
                ProviderProps.setNearestPincode(response.data.nearestPincode);
              } else {
                ProviderProps.setNearestPincode(response.data.nearestPincode);
                setPincodeValue(props.pincode);
              }
            } else ProviderProps.setNearestPincode(null);
            if(ProviderProps.product && addProduct)
              addProductToCart()
            else {
              setPincodeInHeader(pincode, response.data.nearestPincode)
            }
          }
          // ProviderProps.setProductAvailability(response.data.availability);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setisLoadingPin(false);
      });
  };

  const setPincodeInHeader = (pincode, nearestPincode) => {
    let pincodeToSet = nearestPincode? nearestPincode : pincode;
    if (ENV_TYPE == "dev") {
      cookies.set("activeUserPincode", pincodeToSet, {
        path: "/",
      });
    } else
      cookies.set("activeUserPincode", pincodeToSet, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    props.setPincode(pincodeToSet);
    props.setPinNo(pincode);
    // Enter code to close pincode popover below:
    props.setVisible(false);
    setPincodeValue(pincodeToSet);
    ProviderProps.setPinCode(pincode);
  }

  const checkVisibility = () => {
    console.log(cookies.get("activeUserPincode"))
    props.setVisible(false)
    if(pincodeValue) {
      if(cookies.get("activeUserPincode") !== pincodeValue)
        ProviderProps.setPincodeModalVisible(false)
    } else {
      ProviderProps.setPincodeModalVisible(false);
    }
    ProviderProps.setProduct(null);
    ProviderProps.setSelectedItem(null);
    ProviderProps.setQuantity(null);
  }

  useEffect(() => {
    console.log(props.authModalType, ":auth modal type");
    if (props.authModalType === "login") {
      props.setVisible(false);
    } else if (!props.authModalType) {
      props.setVisible(true);
    }
  }, [props.authModalType]);

  return (
    <div className="manage-pincode-container pincode_finder">
      <Button type="link" className="manage-pincode-close-button"
        onClick={() => checkVisibility()}>X</Button>
      <div className="manage-pincode-header">
        <Text>Your Delivery Pincode</Text>
      </div>
      <div className="manage-pincode-container-content">
        <div style={{ display: "flex" }}>
          <img
            src={CompanyCartLogo}
            alt="company-cartlogo"
            style={{ width: "40px", marginRight: "15px" }}
          />
        </div>
        <div className="pincode_content">
          <Text style={{ fontSize: "14px" }}>
            Enter your pincode to check availablity and faster delivery options
          </Text>
        </div>
      </div>
      <div className="input_pincode">
        <Input
          allowClear={true}
          size="large"
          type="text"
          maxLength="6"
          required={true}
          value={pincodeValue}
          onPressEnter={() => handlePincodeUpdate(pincodeValue)}
          onChange={handleValidatePincode}
        />
        <Button
          type="primary"
          size="large"
          className="pincode-go-btn"
          onClick={() => handlePincodeUpdate(pincodeValue)}
        >
          {isLoadingPin ? <Loading style={{ color: "white" }} /> : "GO"}
        </Button>
      </div>
      {isError && <Text type="danger">Enter a valid pincode</Text>}
    </div>
  );
};

const mapDispatchToProps = { setPincode, fetchCartCount };

const mapStateToProps = (state) => {
  return {
    authModalUserType: state.authModalReducer.userType,
    authModalType: state.authModalReducer.modalType,
    cartCountStore: state.cartCountReducer,
    cartItems: state.cartItemsReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePincode);
