


import React, { useState } from "react";

import { FILE_PATH } from "../../../../../Utils/constants";

//import VideoPlayerModal from "./vedioPlayerModal";

import VideoIcon from "../../../../../Assets/Images/Icons/videoplay.png";
import PlaceholderImage from "../../../../../Assets/Images/PlaceHolderImages/placeholder-product-card.jpg";
import MyGallery from "./ImageGallery";

class ProductSliderGallery extends React.Component {
  constructor() {
    super();
    this.state = {
      showIndex: false,
      showThumbnails: true,
      showVideo: {},
      images: [],
    };

    // this.images = [
    //   {
    //     original:
    //       "https://i.pinimg.com/originals/ab/67/53/ab6753ec1cef75f1cc2052487b1f4059.jpg",
    //     thumbnail:
    //       "https://i.pinimg.com/originals/ab/67/53/ab6753ec1cef75f1cc2052487b1f4059.jpg",
    //     embedUrl:
    //       "https://www.youtube.com/embed/mR37QilFsco?autoplay=1&showinfo=0",
    //     renderItem: this._renderVideo.bind(this),
    //   },

    //   {
    //     original:
    //       "https://i.pinimg.com/originals/e3/f2/ab/e3f2abee4a2c7648c19fb767aef9472f.jpg",
    //     thumbnail:
    //       "https://i.pinimg.com/originals/e3/f2/ab/e3f2abee4a2c7648c19fb767aef9472f.jpg",
    //   },
    //   {
    //     original:
    //       "https://i.pinimg.com/564x/9b/b3/c6/9bb3c65e4793fda29672febb692620ff.jpg",
    //     thumbnail:
    //       "https://i.pinimg.com/564x/9b/b3/c6/9bb3c65e4793fda29672febb692620ff.jpg",
    //   },
    //   {
    //     original:
    //       "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpmoaPrCavXY435y0qYTOncdMYYViCUoiqFw&usqp=CAU",
    //     thumbnail:
    //       "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpmoaPrCavXY435y0qYTOncdMYYViCUoiqFw&usqp=CAU",
    //   },
    //   {
    //     original:
    //       "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRjA8HtQ09SpK1Fum9otMNTfweWTDeP2yqBFg&usqp=CAU",
    //     thumbnail:
    //       "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRjA8HtQ09SpK1Fum9otMNTfweWTDeP2yqBFg&usqp=CAU",
    //   },
    // ];
  }

  componentDidMount() {
    this.setState({ images: this.props.productImages });
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.productImages !== this.props.productImages) {
      this.setState({ images: this.props.productImages });
    }
  }

  _toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url]);
    this.setState({
      showVideo: this.state.showVideo,
    });

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false });
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false });
      }
    }
  }

  _renderVideo(item) {
    return (
      <div>
        <span className="offer-tag">30% off</span>
        {this.state.showVideo[item.embedUrl] ? (
          <div className="video-wrapper">
            <a
              className="close-video"
              onClick={this._toggleShowVideo.bind(this, item.embedUrl)}
            ></a>
            <iframe
              width="100%"
              height="100%"
              src={item.embedUrl}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          <a onClick={this._toggleShowVideo.bind(this, item.embedUrl)}>
            <div className="play-button">
              <div className="play-icn">
                <img src={VideoIcon} />
              </div>
            </div>
            <img className="image-gallery-image" src={item.original} />
            {item.description && (
              <span
                className="image-gallery-description"
                style={{ right: "0", left: "initial" }}
              >
                {item.description}
              </span>
            )}
          </a>
        )}
      </div>
    );
  }
  render() {
    return (
      <section className="app">
        <MyGallery
          additionalClass="app-image-gallery"
          items={this.state.images}
        />
      </section>
    );
  }
}

export default ProductSliderGallery;
