


import { API_SERV_ADDR, API_SERV_ADDR_2 } from "../../../Utils/constants";
import Axios from "../../../ApiAction/axios";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export const checkAvailability = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/check-deliverable`,
    method: "POST",
    data: payload,
  });
};

export const fetchCheckoutSearchSuggestions = (data) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/quick-checkout/suggestions`,
    method: "POST",
    data: data,
  });
};

export const addSearchSelection = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/add-product`,
    method: "POST",
    data: payload,
  });
};

export const fetchUserSuggestion = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/suggestion`,
    method: "POST",
    data: payload,
  });
};

export const addUser = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/cart-update/customerId-address`,
    method: "POST",
    data: payload,
  });
};

export const fetchQuickCheckoutOrders = () => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/vendor-carts`,
    method: "POST",
    data: {
      vendorId: cookies.get("userId"),
    },
  });
};
export const getCart = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/cart`,
    method: "POST",
    data: payload,
  });
};

export const addCart = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/add-cart`,
    method: "POST",
    data: payload,
  });
};

export const removeCart = (cartid) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/cart-delete/${cartid}`,
    method: "DELETE",
  });
};

export const removeCartItem = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/remove-item`,
    method: "POST",
    data: payload,
  });
};

export const removeMultipleCartItems = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/remove-multiple-item`,
    method: "POST",
    data: payload,
  });
};

export const updateCartBatch = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/update-item`,
    method: "POST",
    data: payload,
  });
};

export const deliveryChargeChange = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/delivery-charge`,
    method: "POST",
    data: payload,
  });
};

export const CheckoutCart = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/quick-checkout/add-order`,
    method: "POST",
    data: payload,
  });
};

export const ViewInvoice = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/${payload}`,
    method: "GET",
    data: payload,
  });
};

// {"accountType":"customer"
// ,"paymentMode":"COD",
// "deliveryCharge":50,
// "customerId":"afd0e5087bd3a691a6afb4f181be47d0", --pending
// "checkoutCartId":"CU16166651868737130039"}
