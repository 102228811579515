


import React, { useState, useEffect } from "react";

import { Typography, Input, Row, Col, Pagination } from "antd";

import { withRouter } from "react-router-dom";

import Cookies from "universal-cookie";
import {
  getProducts,
  getProductsStoreLinkedWarehouse,
} from "../../ApiAction/SellerApiActions/sellerApi";
import ProductCard from "../ProductCard";
import "./store.scss";

import { formatProductData } from "../Customer/Components/ProductListing/productListingHelper";

const { Title, Text } = Typography;

const LinkedWarehouse = (props) => {
  const cookies = new Cookies();
  const [isMobileView, setisMobileView] = useState(false);

  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(0);
  const [warehouseName, setWarehouseName] = useState("");
  const [searchVal, setSearchVal] = useState(0);

  useEffect(() => {
    getProductsList("", 1);
  }, []);

  const getProductsList = (search = searchVal, page = 1) => {
    const term = [];

    term.push({
      vendorId: [cookies.get("linkedWarehouse")],
    });
    // term.push({
    //   isDraft: [keyType === "listed"?"false":"true"],
    // });
    const payload = {
      offset: (page - 1) * 10,
      size: 10,

      key: search,
      sortBy: "lastModifiedDate",
      sortType: props.sort ? props.sort.sortBy : "asc",
      filter: {
        term: term,
      },
    };
    getProductsStoreLinkedWarehouse(payload, cookies.get("linkedWarehouse"))
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(formatProductData(response.data.products));
          setPage(Math.ceil(response.data.count / 10) * 10);
          setWarehouseName(response.data.warehouse.storeName);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="manage-products-container">
      {" "}
      <div className="linked-warehouse-container">
        <h1 level={4} className="sub-title-tags"> Products</h1>

        <Input
          placeholder="Search"
          enterButton="Search"
          size="large"
          style={{ width: "30%" }}
          // onSearch={(value) => {
          //     handleproductSearch(value)
          // }}
          onChange={(event) => {
            setSearchVal(event.target.value);
            getProductsList(event.target.value, 1);
          }}
          className="manage-order-header-search"
        />
        <div style={{ fontWeight: "500", color: "black" }}>
          Warehouse: <span style={{ color: "#3e6df3" }}> {warehouseName}</span>{" "}
        </div>
      </div>
      <Row gutter={[0, 0]}>
        {productList.map((data) => {
          return (
            <Col className="gutter-row product_col" key={data.productId}>
              <ProductCard product={data} linkedWarehouse loading={false} />
            </Col>
          );
        })}
      </Row>
      {page > 10 && (
        <Pagination
          size="small"
          total={page}
          //   current={1}
          // defaultCurrent={defaultPage}
          showSizeChanger={false}
          className="customer-pagination"
          onChange={(page) => {
            getProductsList(searchVal, page);
            window.scrollTo(0, 0);
          }}
        />
      )}
    </div>
  );
};

export default withRouter(LinkedWarehouse);
