


import React from "react";
// import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

//redux-dependencies
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./ReduxStore";
import thunk from "redux-thunk";
import { hydrate, render } from "react-dom";

const store = createStore(rootReducer, applyMiddleware(thunk));
var DEBUG = process.env.NODE_ENV === "production" ? false : true;

if (!DEBUG) {
  if (!window.console) window.console = {};
  var methods = [
    "log",
    "debug",
    "warn",
    "info",
    "warning",
    "dir",
    "warning",
    "error",
    "debug",
    "assert",
  ];
  for (var i = 0; i < methods.length; i++) {
    console[methods[i]] = function () {};
  }
}

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
}
if (module.hot) {
  module.hot.accept();
}

// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
