

import axios from "axios";
import Axios from "../axios";
import { API_SERV_ADDR, API_SERV_ADDR_2 } from "../../Utils/constants";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const listProducts = (payload) => {
  return axios({
    url: `${API_SERV_ADDR}/product/list`,
    method: "POST",
    data: payload,
  });
};

export const fetchSectionProducts = (payload) => {

  return Axios({
    url: `${API_SERV_ADDR_2}/section/get-contents`,
    method: "POST",
    data: payload,
  });
};

//updated
export const getProductDetails = (productId, pinCode) => {
  return axios({
    url: `${API_SERV_ADDR}/product/details/${productId}`,
    method: "POST",
    data: {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
      pinCode: pinCode ? pinCode : "other",
    },
  });
};

export const getVendorProductDetails = (productId) => {
  return axios({
    url: `${API_SERV_ADDR}/vendor/product/details/${productId}`,
    method: "POST",
    data: {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
    },
  });
};


let cancelToken;
//search and filter product
export const searchProducts = (payload) => {
    
  return axios({
    url: `${API_SERV_ADDR_2}/product/search/filter`,
    method: "POST",
    data: payload,
  });
};

export const searchFMCGProducts = (payload) => {
    
  return axios({
    url: `${API_SERV_ADDR_2}/product/fast-moving/list`,
    method: "POST",
    data: payload,
  });
};

export const checkProductAvailabilityAtPincode = (payload) => {

  return axios({
    url: `${API_SERV_ADDR_2}/product/availability-at-pincode`,
    method: "POST",
    data: payload,
  })
}

export const nearestPincodeForPincode = (payload) => {

  return axios({
    url: `${API_SERV_ADDR_2}/product/nearest-for-pincode`,
    method: "POST",
    data: payload,
  })
}

//product-listing filter option
export const getFilterOptions = (payload) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelled");
  }
  cancelToken = axios.CancelToken.source(); 
  return axios({
    url: `${API_SERV_ADDR_2}/product/brand-category-list`,
    method: "POST",
    data: payload,
    cancelToken: cancelToken.token,
  });
};

export const isDeliverable = (pincode) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/availability-pincode/${pincode}`,
    method: "GET",
  });
};

export const validatePincode = (pincode) => {
  return Axios({
    url: `${API_SERV_ADDR}/validate-pincode/${pincode}`,
    method: "GET"
  });
};

export const checkAvailiablity = (productId, pinCode) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/${productId}/availability/${pinCode}`,
    method: "GET",
  });
};

//api for fast moving products
//when we click type category a new intermediate screen will be shown
//with top 10 fast moving products of that category
export const product_fastMoving = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR_2}/product/fast-moving`,
    method: "POST",
    data: payload,
  });
};

// to get brands of type category
export const categoryGetBrands = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR_2}/category/get-brand`,
    method: "POST",
    data: payload,
  });
};