


import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import Banner from "../../components/Banner";
import OneLineCardstSection from "./Components/OneLineCardstSection";
import "./Homepage.scss";
import { useHistory } from "react-router-dom";
import { fetchHomePageData } from "../../ApiAction/CustomerApiActions/homepageAPI";
import { fetchHomePageSections } from "../../ReduxStore/action/homePageAction";
import TypeCategory from "./Components/TypeCategory";
import { formatProductData } from "../../components/Customer/Components/ProductListing/productListingHelper";
import { fetchCompanyDetails } from "../../ReduxStore/action";

function HomePage(props) {
  const history = useHistory();
  const lastLocation = useLastLocation();
  const [homePageData, sethomePageData] = useState(
    JSON.parse(localStorage.getItem("homePageSections")) || []
  );
  const [typeCategoryVisible,setTypeCategoryVisible] = useState(true)
  const [loading, setLoading] = useState(false);

  // const sectionId = "ed30ea4dfe4bd3192606bf20ba984c0b";

  const renderBanner = (section) => {
    return <div>
      {section.sectionType === "BIG_OFFER" && (
        <p className="big-offer-title" style={{fontWeight:'bold'}}>{section.sectionTitle}</p>
      )}
      <Banner key={section.sectionId} banners={section.sectionData} />
    </div>;
  };
  const renderPopularCategory = (section) => {
    return (
      <OneLineCardstSection
        key={section.sectionId}
        type={"category"}
        title={section.sectionTitle}
        viewall={false}
        categories={section.sectionData}
        loading={loading}
      />
    );
  };
  const renderCategorySection = (section) => {
    return (
      <OneLineCardstSection
        key={section.sectionId}
        type={"product"}
        title={section.sectionTitle}
        sectionId={section.sectionId}
        productData={formatProductData(section.sectionData)}
        maxDiscount={section.sectionMaxDiscount}
        viewall={true}
        loading={loading}
      />
    );
  };

    const renderStoreData = (section) => {
      return (
        <OneLineCardstSection
          key={section.sectionId}
          type={"product"}
          title={section.sectionTitle}
          sectionId={section.sectionId}
          productData={formatProductData(section.sectionData)}
          viewall={true}
          loading={loading}
        />
      );
    };

  const renderSection = (section) => {
    switch (section.sectionType) {
      case "BANNER":
        if (section.sectionOrder !== 0) return renderBanner(section);
        break;
      case "POPULAR_CATEGORY":
        return renderPopularCategory(section);
      case "CATEGORY":
        if (section?.sectionData?.length > 0) {
          return renderCategorySection(section);
        }
        break;
      case "STORE":
        if (section?.sectionData?.length > 0) {
          return renderStoreData(section);
        }
      case "BIG_OFFER":
        return renderBanner(section);
      default:
        return null;
    }
  };

  const fetchHomePageDataHandler = () => {
    setLoading(true);
    fetchHomePageData({ pinCode: props.pinCode })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.fetchHomePageSections(response.data.sections);
            sethomePageData(response.data.sections);
            localStorage.setItem(
              "homePageSections",
              JSON.stringify(response.data.sections)
            );
          }
        }
      })
      .catch((e) => {})
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    fetchHomePageDataHandler();
  }, [props.pinCode]);

  // useEffect(() => {
  //   if (history.action === "POP") {
  //     console.log("homepage Data in store: ", props.homePageData);

  //     if (
  //       props?.homePageData?.length > 0 &&
  //       lastLocation?.pathname.includes("/product-detail/")
  //     ) {
  //       console.log(" back nav active");
  //     } else {
  //       console.log(" initial loading");
  //       fetchHomePageDataHandler();
  //     }
  //   } else {
  //     console.log(" call api");
  //     fetchHomePageDataHandler();
  //   }
  //   window.scrollTo(0, 0);
  // }, [props.pinCode]);

  window.addEventListener("resize", ()=> {
    if(window.innerWidth < 700) {
      setTypeCategoryVisible(false)
    }else {
      setTypeCategoryVisible(false)
    }

  })


  return (
    <div style={{background: '#fff', transform: "translateY(0px)", boxSizing: 'border-box'}}>

        <TypeCategory />

      {homePageData?.[0]?.sectionType === "BANNER" && (
        <Banner banners={homePageData?.[0]?.sectionData} />
      )}

      <section className="main_section_board" >
        <div className="container" >
          <div className="home-page-body home_page__section">
            {homePageData.map((section) => {
              return renderSection(section);
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    pinCode: state.productReducer.pinCode,
    homePageData: state.homePageReducer.homePageData,
    companyDetails: state.homePageReducer.companyDetails
  };
};
const mapDispatchToProps = {
  fetchHomePageSections,
  fetchCompanyDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
