

import {
  ADD_CATEGORY_FILTER,
  ADD_SEARCH_FILTER,
  ADD_SUBCATEGORY_00_FILTER,
  ADD_SUBCATEGORY_01_FILTER,
  ADD_SUBCATEGORY_02_FILTER,
  ADD_SUBCATEGORY_03_FILTER,
  ADD_BRAND_FILTER,
  ADD_SELLER_ID,
  ADD_STORE_ID,
  ADD_PRICE_RANGE_FILTER,
  SET_PINCODE,
  RESET_CATEGORIES_FILTER,
  CLEAR_PRODUCT_LISTING_FILTER,
} from "../action/types";

import Cookies from "universal-cookie";
import { ClockCircleOutlined } from "@ant-design/icons";
const cookies = new Cookies();

export default function productReducer(
  state = {
    categoryId: [],
    subCategory0Id: [],
    subCategory1Id: [],
    subCategory2Id: [],
    subCategory3Id: [],
    brandId: [],
    sellerId: [],
    storeId: [],
    searchKey: "",
    priceRanges: {},
    pinCode: "",
  },
  action
) {
  switch (action.type) {
    case ADD_CATEGORY_FILTER:
      state = {
        ...state,
        categoryId: [action.data],
        subCategory0Id: [],
        subCategory1Id: [],
        subCategory2Id: [],
        subCategory3Id: [],
        brandId: [],
        sellerId: [],
        storeId: [],
      };
      break;
    case ADD_SUBCATEGORY_00_FILTER:
      state = {
        ...state,
        subCategory0Id: [action.data],
        categoryId: [],
        subCategory1Id: [],
        subCategory2Id: [],
        subCategory3Id: [],
        brandId: [],
        sellerId: [],
        storeId: [],
      };
      break;
    case ADD_SUBCATEGORY_01_FILTER:
      state = {
        ...state,
        subCategory1Id: [action.data],
        categoryId: [],
        subCategory0Id: [],
        subCategory2Id: [],
        subCategory3Id: [],
        brandId: [],
        sellerId: [],
        storeId: [],
      };
      break;
    case ADD_SUBCATEGORY_02_FILTER:
      state = {
        ...state,
        subCategory2Id: [action.data],
        categoryId: [],
        subCategory0Id: [],
        subCategory1Id: [],
        subCategory3Id: [],
        brandId: [],
        sellerId: [],
        storeId: [],
      };
      break;
    case ADD_SUBCATEGORY_03_FILTER:
      state = { ...state, subCategory3Id: [action.data] };
      break;
    case ADD_SEARCH_FILTER:
      state = {
        ...state,
        searchKey: action.data,
        categoryId: [],
        subCategory0Id: [],
        subCategory1Id: [],
        subCategory2Id: [],
        subCategory3Id: [],
        brandId: [],
        sellerId: [],
        storeId: [],
      };
      break;
    case ADD_BRAND_FILTER:
      state = { ...state, brandId: action.data };
      break;
    case ADD_SELLER_ID:
      state = { ...state, sellerId: action.data };
      break;
    case ADD_STORE_ID:
      state = { ...state, storeId: action.data };
      break;              
    case ADD_PRICE_RANGE_FILTER:
      state = { ...state, priceRanges: action.data };
      break;
    case SET_PINCODE:
      state = { ...state, pinCode: action.data };
      break;

    case RESET_CATEGORIES_FILTER:
      state = {
        ...state,
        categoryId: [],
        subCategory0Id: [],
        subCategory1Id: [],
        subCategory2Id: [],
        subCategory3Id: [],
        brandId: [],
        sellerId: [],
        storeId: [],        
      };
      break;
    case CLEAR_PRODUCT_LISTING_FILTER:
      state = {
        ...state,
        categoryId: [],
        subCategory0Id: [],
        subCategory1Id: [],
        subCategory2Id: [],
        subCategory3Id: [],
        brandId: [],
        sellerId: [],
        storeId: [],
        searchKey: "",
        priceRanges: {},
      };
      break;
    default:
    // console.log("no changes", action);
  }

  return state;
}
