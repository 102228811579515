import React, { useEffect, useState } from "react";
import { verifyPayment } from "../../../../ApiAction/CustomerApiActions/orderAPI";
import LoadingGif from "../../../../Assets/Images/Icons/loading.gif";
import {
  fetchOrderResponse,
  fetchOrderFailure,
} from "../../../../ReduxStore/action/customerOrderAction";
import {
  fetchCartCount,
  fetchCartItems,
} from "../../../../ReduxStore/action/index";
import { connect } from "react-redux";
import { ENV_TYPE } from "../../../../Utils/constants";
import Cookies from "universal-cookie";
import SignupSuccessModal from "../../../GeneralComponents/SIgnupSuccessModal";
import { Button,Typography } from "antd";
import { history } from "../../../../App";
const { Text } = Typography;

const VerifyPayment = (props) => {

    const cookies = new Cookies();
    const [successModal, setSuccessModal] = useState(false);
    const [signUpResponse, setSignUpResponse] = useState();

    const handleRedirectToOrderResponse = (orderStatus) => {
        switch (cookies.get("accountType")) {
          case "customer":
            props.history.push(
              ENV_TYPE === "production"
                ? `/order-response/${orderStatus}`
                : `/customer/order-response/${orderStatus}`
            );
            break;
          case "warehouse":
            props.history.push(`/warehouse/order-response/${orderStatus}`);
            break;
          case "store":
            props.history.push(`/store/order-response/${orderStatus}`);
            break;
          default:
            console.log("invalid userType");
        }
        window.scrollTo(0, 0);
    };

    const getOrderStatus = (orderGroupId) => {

        const payload = {
            orderGroupId,
            userId: JSON.parse(localStorage.getItem("activeCustomer"))?.userId
        };
        
       verifyPayment(payload)
          .then((response) => {
              if(orderGroupId.startsWith("SI")) {
                setSuccessModal(true);
                setSignUpResponse(response.data);
              }
              else if(response.data.status === "success") {
                  props.fetchOrderResponse(response.data.order);
                  handleRedirectToOrderResponse("success")
              } else {
                  props.fetchOrderResponse(response.data.order);
                  if(response.data.message === "userId mismatch") {
                      props.history.push("/")
                      return
                  }
                  if(response.data.code === "PAYMENT_PENDING") {
                    handleRedirectToOrderResponse("pending")
                    return
                  }
                  handleRedirectToOrderResponse("failure")
              }
          })
          .catch(() => {history.push("/")})
    } 

    useEffect(() => {
      if(props.match.params.orderGroupId) {
          getOrderStatus(props.match.params.orderGroupId);
      }
    }, []);

    return (
      <div>
        {!successModal? (
          <div style={{"padding-top": "10%", "backgroundColor": "white", "textAlign": "center"}}>
              <div style={{"font-size": "45px"}}>Checking order status, please wait</div>
              <img src={LoadingGif} width="400px"></img>
          </div>
        ) : signUpResponse?.status == "success"?
          (
            <SignupSuccessModal
              visible={successModal}
              setvisible={() => {
                  ENV_TYPE === "production"? history.push("/") : 
                  history.push("/login-store")
                }
              }
              message={"Successfully Registered"}
              signUpResponse={signUpResponse}
            />
          ) : (
            <div style={{"padding-top": "10%", "backgroundColor": "white", "textAlign": "center"}}>
              {signUpResponse?.paymentStatus === "PENDING"? (
                <div>
                  <div style={{"font-size": "45px"}}>Payment pending</div>
                  <div style={{"font-size": "25px"}}>Please wait sometime before trying again</div>
                  <div style={{"margin": "20px"}}>
                    <Text type="secondary" strong>
                      Payment ID: {signUpResponse?.orderGroupId}
                    </Text>
                  </div>
                  <div>
                  {signUpResponse?.phonePeTransactionId && (
                    <Text type="secondary" strong>
                      PhonePe TransactionID: {signUpResponse?.phonePeTransactionId}
                    </Text>
                  )}
                  {signUpResponse?.ccAvenueOrderId && (
                    <Text type="secondary" strong>
                      CCAvenue OrderID: {signUpResponse?.ccAvenueOrderId}
                    </Text>
                  )}
                  </div>
                </div>
                ) : (
                <div>
                  <div style={{"font-size": "45px"}}>Payment failed</div>
                  <div style={{"font-size": "25px"}}>Try registering again</div>
                </div>
              )}
              <Button
                  type="primary"
                  onClick={() => ENV_TYPE === "production"? history.push("/") : 
                    history.push("/login-store")
                  }
                  style={{"margin": "20px"}}
                >
                {signUpResponse?.paymentStatus == "PENDING"? "Login": "Register"}
              </Button>
            </div>
          )
        }
      </div>
    );
};
const mapDispatchtoProps = {
    fetchOrderResponse,
    fetchCartCount,
    fetchCartItems,
    fetchOrderFailure,
  };
  export default connect(null, mapDispatchtoProps)(VerifyPayment);