


import { API_SERV_ADDR } from "../../Utils/constants";
import Axios from "../axios";

import Cookies from "universal-cookie";
import { getLogAttributes } from "../../components/Admin/Logger/notificationBar/LogHelper";
const cookies = new Cookies();

export const updateDeliveryCharge = (data) => {
  let payload = {
    ...data,
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  };
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/user/add-delivery-charge-criteria`,
    method: "POST",
    data: payload,
  });
};

export const fetchDeliveryCharge = () => {
  let payload = {
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  };

  return Axios({
    url: `${API_SERV_ADDR}/user/get-delivery-charge-criteria`,
    method: "POST",
    data: payload,
  });
};

export const calculateDeliveryCharge = (data) => {
  let payload = {
    ...data,
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/user/cart/calculate-delivery-charge`,
    method: "POST",
    data: payload,
  });
};

export const excludeDeliveryCharge = (orderGroupId) => {
  let userId = cookies.get("userId");
  return Axios({
    url: `${API_SERV_ADDR}/order/by-hand-delivery/${userId}/${orderGroupId}`,
    method: "POST",
  });
};
