

import React, { Fragment, useEffect, useState } from "react";

import { Typography, Input, Spin } from "antd";
import CategoryList from "./categoryList";
import AddIcon from "../../../../Assets/Images/Icons/add.svg";
import * as ManageCategoryHelper from "../manageCategoryHelper";
import { FaPlusCircle } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Search } = Input;
const CategoryListContainer = (props) => {
  const { categoryList, onClick, refetch, setrefetch, isView } = props;

  const [selectedCatogeryLevelZero, setSelectedCatogeryLevelZero] = useState(0);
  const [selectedCatogeryLevelOne, setselectedCatogeryLevelOne] = useState(0);
  const [selectedCAtogeryLevelTwo, setselectedCAtogeryLevelTwo] = useState(0);
  const [selectedCatogeryLevelThree, setselectedCatogeryLevelThree] =
    useState(0);
  const [selectedCatogeryLevelFour, setselectedCatogeryLevelFour] = useState(0);

  const [levelZeroSearchKey, setLevelZeroSearchKey] = useState(); // this is for level -1 AKA Type Category
  const [levelOneSearchKey, setlevelOneSearchKey] = useState();
  const [levelTwoSearchKey, setlevelTwoSearchKey] = useState();
  const [levelThreeSearchKey, setlevelThreeSearchKey] = useState();
  const [levelFourSerchKey, setLevelFourSearchKey] = useState();
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [categoryData, setcategoryData] = useState([]);

  const renderSearchBar = (setSearchKey) => {
    return (
      <Search
        placeholder="input search text"
        onSearch={(value) => setSearchKey(value)}
        style={{ width: "100%" }}
        className="manage-category-list-search"
      />
    );
  };

  //onchanging the search key selectedcategory levels upto that should be cleared

  //searching on first layer
  useEffect(() => {
    if (levelZeroSearchKey) {
      setSelectedCatogeryLevelZero(0);
      setselectedCatogeryLevelOne(0);
      setselectedCAtogeryLevelTwo(0);
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [levelZeroSearchKey]);

  //searching on first layer
  useEffect(() => {
    if (levelOneSearchKey) {
      setselectedCatogeryLevelOne(0);
      setselectedCAtogeryLevelTwo(0);
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [levelOneSearchKey]);

  //searching on second layer
  useEffect(() => {
    if (levelTwoSearchKey) {
      setselectedCAtogeryLevelTwo(0);
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [levelTwoSearchKey]);

  //searching on third layer
  useEffect(() => {
    if (levelThreeSearchKey) {
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [levelThreeSearchKey]);

  //onchanging different categories selection on each layer its associated sublevels should be changed

  //on Changing catogery in first layer
  useEffect(() => {
    if (selectedCatogeryLevelOne) {
      setselectedCAtogeryLevelTwo(0);
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [selectedCatogeryLevelOne]);

  //on Changing catogery in Second layer
  useEffect(() => {
    if (selectedCAtogeryLevelTwo) {
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [selectedCAtogeryLevelTwo]);

  //on Changing catogery in Third layer
  useEffect(() => {
    if (selectedCatogeryLevelThree) {
      setselectedCatogeryLevelFour(0);
    }
  }, [selectedCatogeryLevelThree]);

  useEffect(() => {
    setcategoryData(categoryList);
  }, [props]);
  return (
    <div className="admin-manage-category-main">
      {/* category type (level -1) */}
      <div className="category-level-each">
        <div className="category-level-header">
          <Text strong>Type</Text>
          {!isView && (
            <span
              className="category-add-button"
              onClick={() => onClick(-1, [])}
            >
              <FaPlusCircle /> Add
            </span>
          )}
        </div>

        {renderSearchBar(setLevelZeroSearchKey)}

        <React.Fragment>
          {props.isLoading ? (
            <Spin
              indicator={antIcon}
              style={{
                width: "100%",
                height: "10em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          ) : (
            <CategoryList
              level={-1}
              categories={ManageCategoryHelper.formatCategoryList({
                list: categoryData,
                filterKey: levelZeroSearchKey,
              })}
              setCategoryLevel={setSelectedCatogeryLevelZero}
              selectedCategory={selectedCatogeryLevelZero}
              refetch={refetch}
              setrefetch={setrefetch}
              isView={isView}
            />
          )}
        </React.Fragment>
      </div>

      {/* category level0 */}
      <div className="category-level-each">
        <Fragment>
          {categoryData.map((data, index) => {
            if (data.id === selectedCatogeryLevelZero) {
              return data.subCategories?.length ? (
                <Fragment>
                  <div className="category-level-header">
                    <Text strong>Category</Text>
                    {!isView && (
                      <span
                        className="category-add-button"
                        onClick={() => onClick(0, [selectedCatogeryLevelZero])}
                      >
                        <FaPlusCircle /> Add
                      </span>
                    )}
                  </div>

                  {renderSearchBar(setlevelOneSearchKey)}
                  <CategoryList
                    level={1}
                    categories={ManageCategoryHelper.formatCategoryList({
                      list: data.subCategories,
                      filterKey: levelOneSearchKey,
                    })}
                    setCategoryLevel={setselectedCatogeryLevelOne}
                    selectedCategory={selectedCatogeryLevelOne}
                    refetch={refetch}
                    setrefetch={setrefetch}
                    isView={isView}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="category-level-header">
                    <Text strong>Category</Text>
                    {!isView && (
                      <span
                        className="category-add-button"
                        onClick={() => onClick(0, [selectedCatogeryLevelZero])}
                      >
                        <FaPlusCircle /> Add
                      </span>
                    )}
                  </div>
                </Fragment>
              );
            }
          })}
        </Fragment>
      </div>

      {/* category level1 */}
      <div className="category-level-each">
        <Fragment>
          {categoryData.map((data, index) => {
            if (data.id === selectedCatogeryLevelZero) {
              return data.subCategories.length
                ? data.subCategories.map((data1, index1) => {
                    if (data1.id === selectedCatogeryLevelOne) {
                      return data1.subCategories.length ? (
                        <Fragment>
                          <div className="category-level-header">
                            <Text strong>Child 1</Text>
                            {!isView && (
                              <span
                                className="category-add-button"
                                onClick={() =>
                                  onClick(1, [
                                    selectedCatogeryLevelZero,
                                    selectedCatogeryLevelOne,
                                  ])
                                }
                              >
                                <FaPlusCircle /> Add
                              </span>
                            )}
                          </div>
                          {renderSearchBar(setlevelTwoSearchKey)}

                          <CategoryList
                            level={2}
                            categories={ManageCategoryHelper.formatCategoryList(
                              {
                                list: data1.subCategories,
                                filterKey: levelTwoSearchKey,
                              }
                            )}
                            setCategoryLevel={setselectedCAtogeryLevelTwo}
                            selectedCategory={selectedCAtogeryLevelTwo}
                            parentCategoryId={data1.id}
                            refetch={refetch}
                            setrefetch={setrefetch}
                            isView={isView}
                          />
                        </Fragment>
                      ) : (
                        <Fragment>
                          <div className="category-level-header">
                            <Text strong>Child 1</Text>
                            {!isView && (
                              <span
                                className="category-add-button"
                                onClick={() =>
                                  onClick(1, [
                                    selectedCatogeryLevelZero,
                                    selectedCatogeryLevelOne,
                                  ])
                                }
                              >
                                <FaPlusCircle /> Add
                              </span>
                            )}
                          </div>
                        </Fragment>
                      );
                    }
                  })
                : "";
            }
          })}
        </Fragment>
      </div>

      {/* category level2 */}
      <div className="category-level-each">
        <Fragment>
          {categoryData.map((data, index) => {
            if (data.id === selectedCatogeryLevelZero) {
              return data.subCategories.length
                ? data.subCategories.map((data1, index1) => {
                    if (data1.id === selectedCatogeryLevelOne) {
                      return data1.subCategories.length
                        ? data1.subCategories.map((data2, index2) => {
                            if (data2.id === selectedCAtogeryLevelTwo) {
                              return data2.subCategories.length ? (
                                <Fragment>
                                  <div className="category-level-header">
                                    <Text strong>Child 2</Text>
                                    {!isView && (
                                      <span
                                        className="category-add-button"
                                        onClick={() =>
                                          onClick(2, [
                                            selectedCatogeryLevelZero,
                                            selectedCatogeryLevelOne,
                                            selectedCAtogeryLevelTwo,
                                          ])
                                        }
                                      >
                                        <FaPlusCircle /> Add
                                      </span>
                                    )}
                                  </div>
                                  {renderSearchBar(setlevelThreeSearchKey)}

                                  <CategoryList
                                    level={3}
                                    categories={ManageCategoryHelper.formatCategoryList(
                                      {
                                        list: data2.subCategories,
                                        filterKey: levelThreeSearchKey,
                                      }
                                    )}
                                    setCategoryLevel={
                                      setselectedCatogeryLevelThree
                                    }
                                    selectedCategory={
                                      selectedCatogeryLevelThree
                                    }
                                    parentCategoryId={data2.id}
                                    refetch={refetch}
                                    setrefetch={setrefetch}
                                    isView={isView}
                                  />
                                </Fragment>
                              ) : (
                                <Fragment>
                                  <div className="category-level-header">
                                    <Text strong>Child 2</Text>
                                    {!isView && (
                                      <span
                                        className="category-add-button"
                                        onClick={() =>
                                          onClick(2, [
                                            selectedCatogeryLevelZero,
                                            selectedCatogeryLevelOne,
                                            selectedCAtogeryLevelTwo,
                                          ])
                                        }
                                      >
                                        <FaPlusCircle /> Add
                                      </span>
                                    )}
                                  </div>
                                </Fragment>
                              );
                            }
                          })
                        : "";
                    }
                  })
                : "";
            }
          })}
        </Fragment>
      </div>
      {/* category level3 */}
      <div className="category-level-each">
        <Fragment>
          {categoryData.map((data, index) => {
            if (data.id === selectedCatogeryLevelZero) {
              return data.subCategories.length
                ? data.subCategories.map((data1, index1) => {
                    if (data1.id === selectedCatogeryLevelOne) {
                      return data1.subCategories.length
                        ? data1.subCategories.map((data2, index2) => {
                            if (data2.id === selectedCAtogeryLevelTwo) {
                              return data2.subCategories.length
                                ? data2.subCategories.map((data3, index3) => {
                                    if (
                                      data3.id === selectedCatogeryLevelThree
                                    ) {
                                      return data3.subCategories.length ? (
                                        <Fragment>
                                          <div className="category-level-header">
                                            <Text strong>Child 3</Text>
                                            {!isView && (
                                              <span
                                                className="category-add-button"
                                                onClick={() =>
                                                  onClick(3, [
                                                    selectedCatogeryLevelZero,
                                                    selectedCatogeryLevelOne,
                                                    selectedCAtogeryLevelTwo,
                                                    selectedCatogeryLevelThree,
                                                  ])
                                                }
                                              >
                                                <FaPlusCircle /> Add
                                              </span>
                                            )}
                                          </div>
                                          {renderSearchBar(
                                            setLevelFourSearchKey
                                          )}

                                          <CategoryList
                                            level={4}
                                            categories={ManageCategoryHelper.formatCategoryList(
                                              {
                                                list: data3.subCategories,
                                                filterKey: levelFourSerchKey,
                                              }
                                            )}
                                            setCategoryLevel={
                                              setselectedCatogeryLevelFour
                                            }
                                            selectedCategory={
                                              selectedCatogeryLevelFour
                                            }
                                            parentCategoryId={data3.id}
                                            refetch={refetch}
                                            setrefetch={setrefetch}
                                            isView={isView}
                                          />
                                        </Fragment>
                                      ) : (
                                        <Fragment>
                                          <div className="category-level-header">
                                            <Text strong>Child 3</Text>
                                            {!isView && (
                                              <span
                                                className="category-add-button"
                                                onClick={() =>
                                                  onClick(3, [
                                                    selectedCatogeryLevelZero,
                                                    selectedCatogeryLevelOne,
                                                    selectedCAtogeryLevelTwo,
                                                    selectedCatogeryLevelThree,
                                                  ])
                                                }
                                              >
                                                <FaPlusCircle /> Add
                                              </span>
                                            )}
                                          </div>
                                        </Fragment>
                                      );
                                    }
                                  })
                                : "";
                            }
                          })
                        : "";
                    }
                  })
                : "";
            }
          })}
        </Fragment>
      </div>
    </div>
  );
};

export default CategoryListContainer;
