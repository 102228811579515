


import React, { useEffect, useState, Fragment } from "react";
import { Menu } from "antd";

import { NavLink, Link } from "react-router-dom";
import Icon from "@ant-design/icons";
import "antd/dist/antd.css";
import "../Admin/admin.scss";
import { sideBarDataAdmin, sideBarDataWorkReport } from "../../Utils/Seller/Data";
import { ReactComponent as CartIcon } from "../../Assets/Images/Icons/open-menu.svg";
import { fetchSort } from "../../ReduxStore/action";
import { connect } from "react-redux";

import ManageReturnsIcon from "../../Assets/Images/Icons/Seller/returns.svg";
import ManageReturnsIconActive from "../../Assets/Images/Icons/Seller/returnsActive.svg";
import { get_data_entry_users } from "../../ApiAction/AdminApiAction/logApi";
const { SubMenu } = Menu;
const WorkReportMenu = (props) => {
  const { activeTab, setSearchval, dataEntryUsers } = props;
  const [urlPath, setUrlPath] = useState("");
  const [selectedMenu, setActiveMenu] = useState("my-profile");

  const menuItemClick = (data) => {
    setActiveMenu(data.key);
    setSearchval && setSearchval("");
    if (props.isNav) {
      props.setNavVisible(false);
    }
  };

  useEffect(() => {
    setUrlPath(props.pathname);
  }, [props]);

  return (
    <Menu
      className="side-nav-menu"
      // theme="dark"
      mode="inline"
      defaultSelectedKeys={[activeTab]}
      selectedKeys={[activeTab]} //activeTab refers to the current active tab
      onClick={menuItemClick}
      style={{borderRadius: "1em"}}
    >
      {sideBarDataWorkReport.map((data) => {
        return (
          <Menu.Item
            key={data.key}
          // disabled={data.key !== "manage-products" ? true : false}
          >
            <NavLink to={data.url}>
              <span>
                {" "}
                <img
                  src={data.key === activeTab ? data.activeIcon : data.icon}
                  style={{ width: "20px", marginRight: "10px" }} alt={data.label}
                />
              </span>
              <span className="side-menu-link-content">
                <span>{data.label}</span>
                {data.newModule && (<span className="new-label">{data.newModule}</span>)}
              </span>
            </NavLink>
          </Menu.Item>
        );
      })}

      <SubMenu
        key="work-report"
        title={
          <Fragment>
            <span>
              <img alt="Reports"
                src={
                  "work-report" === activeTab
                    ? ManageReturnsIconActive
                    : ManageReturnsIcon
                }
                style={{ width: "20px", marginRight: "10px", marginLeft: "5px"}}
              />
            </span>
            <span className="side-menu-link-content" style={{fontWeight:"400",fontSize:"15px",lineHeight:"normal"}}>
              <span>Work Reports</span>
            </span>
          </Fragment>
        }
      >
        <div style={{"overflow-y": "clip", "overflow-y": "scroll", height: "300px"}}>
          {
            dataEntryUsers.map((user, index) => {
              return (
                <Menu.Item key={"report-" + index}>
                <NavLink to={{pathname:"/work-report/report", userId: user.id, name: user.name}}>
                  <span className="side-menu-link-content">{user.name}</span>
                </NavLink>
              </Menu.Item>
              )
            })
          }
        </div>
      </SubMenu>

      <SubMenu
        key="sales-report"
        title={
          <Fragment>
            <span>
              <img alt="Reports"
                src={
                  "sales-report" === activeTab
                    ? ManageReturnsIconActive
                    : ManageReturnsIcon
                }
                style={{ width: "20px", marginRight: "10px" }}
              />
            </span>
            <span className="side-menu-link-content" style={{fontWeight:"400",fontSize:"15px",lineHeight:"normal"}}>
              <span>Reports</span>
            </span>
          </Fragment>
        }
      >
        <Menu.Item key="sales-report">
          <NavLink to={"/work-report/sales-report"}>
            <span className="side-menu-link-content">Sales Report</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="stocks-report">
          <NavLink to={"/work-report/stocks-report"}>
            <span className="side-menu-link-content">Stocks Report</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="search-report">
          <NavLink to={"/work-report/search-report"}>
            <span className="side-menu-link-content">Search Report</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="expiry-report">
          <NavLink to={"/work-report/expiry-report"}>
            <span className="side-menu-link-content">Expiry Report</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkReportMenu);
