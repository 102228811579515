


import React from "react";
import { Divider, Table } from "antd";

import "./reportsTable.scss";
import TableHeader from "./tableHeader";
import TableView from "./tableView";

const ReportsTable = (props) => {
  const {
    columns,
    dataSource,
    type,
    reportSummary,
    download,
    searchKey,
    setsearchKey,
    isLoadingReport,
    summary,
    setRegenerate,
  } = props;
  return (
    <div className="reports_table_container">
      <TableHeader
        title={type}
        reportSummary={reportSummary}
        summary={summary}
        download={download}
        searchKey={searchKey}
        setsearchKey={setsearchKey}
        setRegenerate={setRegenerate}
        isLoadingReport={isLoadingReport}
      />
      <Divider />
      <TableView
        type={type}
        dataSource={dataSource}
        columns={columns}
        isLoadingReport={isLoadingReport}
      />
    </div>
  );
};

export default ReportsTable;
