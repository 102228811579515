

import axios from "axios";
import Cookies from "universal-cookie";
import { history } from "../App.js";
import { message } from "antd";
import { ENV_TYPE } from "../Utils/constants.js";
var CryptoJS = require("crypto-js");

const cookies = new Cookies();

const Axios = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status <= 304,
});
export default Axios;

//  request interceptor for adding authorisation header
Axios.interceptors.request.use(function (config) {
  var encryptedToken, bytes, token;
  if (localStorage.getItem("authToken")) {
    if((config.url.includes('/log') || config.url.includes('work-report')) &&
      window.location.href.includes('work-report')) {
      token = JSON.parse(localStorage.getItem("activeCustomer")).logToken
    } else {
      encryptedToken = localStorage.getItem("authToken");
      bytes = CryptoJS.AES.decrypt(
        encryptedToken,
        process.env.REACT_APP_ENCRYPTION_KEY
      );
      token = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    config.headers.Authorization = "Bearer " + token;
  } else if (sessionStorage.getItem("authToken")) {
    encryptedToken = sessionStorage.getItem("authToken");
    bytes = CryptoJS.AES.decrypt(
      encryptedToken,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    token = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    config.headers.Authorization = "Bearer " + token;
  }

  return config;
});

//response interceptor for handling 401 unauthorised
Axios.interceptors.response.use(undefined, (error) => {
  if (error.message === "Network Error" && !error.response) {
    console.log(
      "Network error - make sure you have active internet connection"
    );
  }

  const { status, data, config } = error.response;

  if (status === 401) {
    console.log("unthorised error handler to be exicuted here");
    message.error("please login to continue.");
    localStorage.removeItem("activeCustomer");
    localStorage.removeItem("activeUser");
    localStorage.removeItem("authToken");
    sessionStorage.removeItem("authtoken");
    localStorage.removeItem("remember");
    // cookies.remove("authToken", { path: "/" });
    cookies.remove("vendorId", { path: "/" });
    cookies.remove("userId", { path: "/" });
    cookies.remove("activeUserPincode", { path: "/" });
    if (ENV_TYPE == "production") history.push("/");
    else
      switch (cookies.get("accountType")) {
        case "customer":
          history.push("/customer");
          break;
        case "store":
          history.push("/login-store");
          break;
        case "seller":
          history.push("/login-seller");
          break;
        case "warehouse":
          history.push("/login-warehouse");
          break;
        case "admin":
          history.push("/login-admin");
          break;
        case "logger":
          history.push("/logger-admin");
          break;
        case "workAdmin":
          history.push("/login-work-report");
          break;
        case "affiliate":
          history.push("/login-affiliate");
          break;
        default:
          console.log("invalid case");
      }

    cookies.remove("accountType", { path: "/" });
  }
});
