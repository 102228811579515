


import { API_SERV_ADDR, API_SERV_ADDR_2 } from "../../Utils/constants";
import Axios from "../axios";
import { getLogAttributes } from "../../components/Admin/Logger/notificationBar/LogHelper";

//add brand
export const addBrand = (payload) => {
  payload = getLogAttributes(payload, "Manage Brands")
  return Axios({
    url: `${API_SERV_ADDR}/brand/add`,
    method: "POST",
    data: payload,
  });
};

//upload brandimage
export const uploadBrandImage = (brandId, brandImage) => {
  const formData = new FormData();
  formData.append("files", brandImage);

  return Axios({
    url: `${API_SERV_ADDR}/brand/logo-upload/${brandId}`,
    headers: { "Content-Type": "multipart/form-data" },
    method: "POST",
    data: formData,
  });
};

//list brands
export const fetchBrandList = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR_2}/brand/list`,
    method: "POST",
    data: payload,
  });
};

//update brand
export const updateBrand = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/brand/update`,
    method: "POST",
    data: payload,
  });
};

//block or unblock brand
export const blockBrand = (brandId) => {
  return Axios({
    url: `${API_SERV_ADDR}/brand/block/${brandId}`,
    method: "GET",
  });
};

//archeive or unarcheive brand
export const archeiveBrand = (brandId) => {
  return Axios({
    url: `${API_SERV_ADDR}/brand/archive-unarchive/${brandId}`,
    method: "GET",
  });
};
