


import React from "react";
import { Button, message, Modal } from "antd";
import { Authenticate } from "../../../ApiAction/TaxApiActions";
const confirm = Modal.confirm;
const InitiateEwayBillGeneration = () => {
  const handleAuthenticateEwayBill = () => {
    Authenticate().then((response) => {
      if (response.status === 200) {
        console.log(response, ": response for ewaybill authentication");
        if (response.data.status_cd === "1") {
          message.success(
            "Successfully Authenticated for Eway-Bill generation please continue generating Eway-Bill now"
          );
        } else {
          message.error(
            "Failed to Authenticated for Eway-Bill generation please try again later"
          );
        }
      }
    });
  };

  const handleInitiateEwayBillGeneration = () => {
    confirm({
      title: "Do you want to Authenticate Eway Bill generation !!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        handleAuthenticateEwayBill();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <Button type="primary" onClick={() => handleInitiateEwayBillGeneration()}>
      Initiate EwayBill
    </Button>
  );
};

export default InitiateEwayBillGeneration;
