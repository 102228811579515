


export const formatBreadCrumbData = (rawData) => {
  // console.log("raw data for bread crumb data", rawData);
  const type = rawData.type;
  const searchWithKeys = ["searchWithCategory", "sectionWithCategory"];
  var result = [];
  if (searchWithKeys.includes(rawData?.type)) {
    delete rawData["search"];
    delete rawData["section"];

    console.log("raw data after preprocessing:", rawData);
  }
  delete rawData["type"];

  Object.keys(rawData).map(function (key, index) {
    const temp = {
      type: key,
      label: rawData[key]?.name ? rawData[key].name : rawData[key].key,
      id: rawData[key]?.id ? rawData[key].id : rawData[key].key,
    };

    result.push(temp);
  });

  console.log(result, ": result ");
  if (type === "product") {
    return result;
  } else return result.reverse();
};
