


import React, { useEffect, useState } from "react";

import { formatBasicOrderSummary } from "./orderDetailsHelper";
import "./orderDetails.scss";
import { Typography, Divider } from "antd";

const { Text, Title } = Typography;
const OrderDetails = (props) => {
  const { orderSummary } = props;
  const [basicOrderDetails, setbasicOrderDetails] = useState([]);

  useEffect(() => {
    setbasicOrderDetails(formatBasicOrderSummary(orderSummary));
  }, [orderSummary]);

  return (
    <div className="order_details_container full_ord">
      <div className="basic_order_details order_summary_data">
        {basicOrderDetails.map((data) => {
          return (
            <div className="each_basic_order_details">
              <Text strong> {data.key}</Text>
              <Text type={"secondary"}>{data.value}</Text>
            </div>
          );
        })}
      </div>

      <div className="order_details_product_list px-2">
        {orderSummary.orderedItems.map((data) => {
          return (
            <div className="each_product_in_order loop_order">
              <div className="prod_details">
                <Text>
                  {data.productName} ( {data.itemCurrentPrice.quantity}{" "}
                  {data.itemCurrentPrice.unit} )
                </Text>
                <Text type={"secondary"}>Qty: {data.quantity}</Text>
                {data.vendorType === "STORE" && (
                  <div className="order_delivery_estimation">
                    <span>Delivery in 24 hours</span>
                  </div>
                )}
              </div>
              <div className="prod_price">
                <Text strong>₹ {data.itemCurrentPrice.sellingPrice}</Text>
              </div>
            </div>
          );
        })}
      </div>
      <Divider />
      {/* //subtotal */}
      <div className="px-2">
        <div className="each_product_in_order">
          <div className="prod_details hg-1">
            <Text> Sub Total (Inclusive of Tax)</Text>
          </div>
          <div className="prod_price">
            <Text strong>₹ {orderSummary.subTotal}</Text>
          </div>
        </div>
        {/* //tax */}

        <div className="each_product_in_order">
          <div className="prod_details hg-1">
            <Text> Total tax</Text>
          </div>
          <div className="prod_price">
            <Text strong>
              ₹ {orderSummary.totalTax ? orderSummary.totalTax : 0}
            </Text>
          </div>
        </div>

        {/* //delivery charge */}
        <div className="each_product_in_order" style={{ margin: "6px 0" }}>
          <div className="prod_details hg-1">
            <Text>Delivery charges</Text>
          </div>
          <div className="prod_price">
            <Text strong>
              ₹ {orderSummary?.deliveryCharge ? orderSummary.deliveryCharge : 0}
            </Text>
          </div>
        </div>
        <Divider />
        {/* //order Total */}
        <div className="each_product_in_order">
          <div className="prod_details hg-1">
            <Text>Total</Text>
          </div>
          <div className="prod_price">
            <Text strong style={{ fontSize: "17px" }}>
              ₹ {orderSummary.totalPrice}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
