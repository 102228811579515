


import React from "react";

export const SuitCaseIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 36 36"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#fed000"
                d="M33 8.905h-5.311V3.846a2 2 0 00-2-2H10.312a2 2 0 00-2 2v5.059H3c-1.656 0-3 1.344-3 3v19.25a3 3 0 003 3h30a3 3 0 003-3v-19.25a3 3 0 00-3-3zM12.312 5.848h11.377v3.06H12.312v-3.06z"
                data-original="#000000"
            ></path>
        </svg>
    );
}



export const CircleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#fed000"
                d="M256 0C114.837 0 0 114.837 0 256s114.837 256 256 256 256-114.837 256-256S397.163 0 256 0z"
                data-original="#000000"
            ></path>
        </svg>
    );
}



export const MoliculeIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 469.333 469.333"
        >
            <path
                xmlns="http://www.w3.org/2000/svg"
                fill="#fed000"
                d="M416 320c-13.371 0-25.461 5.117-34.832 13.277l-73.073-55.88c7.372-12.616 11.905-27.094 11.905-42.73 0-15.022-4.238-28.941-11.082-41.215l50.094-36.742c9.469 8.581 21.901 13.957 35.655 13.957 29.406 0 53.333-23.927 53.333-53.333S424.073 64 394.667 64s-53.333 23.927-53.333 53.333c0 7.948 1.866 15.427 4.997 22.21l-49.885 36.59c-15.563-16.418-37.426-26.799-61.779-26.799-6.798 0-13.327 1.004-19.669 2.513l-12.285-32.772c19.012-11.108 31.954-31.51 31.954-55.074 0-35.292-28.708-64-64-64s-64 28.708-64 64 28.708 64 64 64c4.16 0 8.207-.466 12.148-1.228l12.277 32.751c-22.641 11.982-39.344 33.563-44.18 59.531l-45.014-2.642c-3.724-25.794-25.756-45.746-52.565-45.746C23.927 170.667 0 194.594 0 224s23.927 53.333 53.333 53.333c24.639 0 45.229-16.884 51.323-39.629l45.253 2.656c1.466 21.715 10.79 41.245 25.529 55.51l-39.612 52.819c-8.785-4.544-18.603-7.357-29.159-7.357-35.292 0-64 28.708-64 64s28.708 64 64 64 64-28.708 64-64c0-17.048-6.801-32.467-17.704-43.949l39.677-52.906c12.46 7.125 26.676 11.522 42.027 11.522 23.745 0 45.214-9.786 60.698-25.491l72.809 55.677c-3.419 7.03-5.507 14.819-5.507 23.147 0 29.406 23.927 53.333 53.333 53.333s53.333-23.927 53.333-53.333S445.406 320 416 320z"
                data-original="#000000"
            ></path>
        </svg>
    );
}



export const StarIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="11"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 512 512"
        >
            <g xmlns="http://www.w3.org/2000/svg" fill="#fed000">
                <path
                    d="M294.525 205.492L256 127.416l-38.525 78.076-86.163 12.525 62.344 60.761-14.721 85.811L256 324.071l77.065 40.518-14.721-85.811 62.344-60.761z"
                    data-original="#000000"
                ></path>
                <path
                    d="M256 0C115.39 0 0 115.39 0 256s115.39 256 256 256 256-115.39 256-256S396.61 0 256 0zm116.909 419.433L256 357.968l-116.909 61.465 22.339-130.166-94.6-92.197 130.723-18.999L256 59.623l58.447 118.447 130.723 18.999-94.6 92.197z"
                    data-original="#000000"
                ></path>
            </g>
        </svg>
    );
}
export const MasterCard = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 46 28"
            xmlSpace="preserve"
        >
            <path
                fill="#F2F2F2"
                stroke="#CCC"
                strokeMiterlimit="10"
                d="M0.5 0.5H45.5V27.5H0.5z"
            ></path>
            <path fill="#FF5F00" d="M19.335 8.011H26.669V19.99H19.335z"></path>
            <path
                fill="#ED1C24"
                d="M20.09 14.001A7.608 7.608 0 0123 8.012a7.618 7.618 0 00-10.696 1.283A7.617 7.617 0 0023 19.991a7.605 7.605 0 01-2.91-5.99z"
            ></path>
            <path d="M34.597 18.722v-.245h.106v-.051h-.252v.051h.1v.245h.046zm.489 0v-.296h-.076l-.089.211-.089-.211h-.076v.296h.054v-.225l.083.193h.058l.082-.193v.225h.053z"></path>
            <path
                fill="#FAA61A"
                d="M35.325 14.001A7.617 7.617 0 0123 19.989a7.617 7.617 0 000-11.978 7.616 7.616 0 0112.325 5.987v.003z"
            ></path>
        </svg>
    );
}


export const VisaMaster = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 46 28"
            xmlSpace="preserve"
        >
            <path fill="#F2F2F2" d="M0 1H45V28H0z"></path>
            <path fill="#CCC" d="M46 28H0V0h46v28zM1 27h44V1H1v26z"></path>
            <g>
                <path
                    fill="#00579F"
                    d="M19.749 8.426l-1.788 11.126h2.858l1.787-11.126h-2.857zm8.621 4.532c-.999-.492-1.611-.826-1.611-1.33.012-.459.518-.929 1.647-.929a4.976 4.976 0 012.128.412l.26.115.388-2.327a7.19 7.19 0 00-2.565-.459c-2.822 0-4.809 1.468-4.822 3.566-.023 1.548 1.423 2.408 2.505 2.924 1.106.527 1.482.871 1.482 1.34-.012.722-.894 1.056-1.717 1.056-1.14 0-1.752-.172-2.682-.574l-.376-.171-.399 2.418c.671.3 1.906.562 3.187.575 2.999 0 4.951-1.445 4.975-3.682.01-1.225-.753-2.166-2.4-2.934zM38.508 8.46h-2.211c-.681 0-1.199.197-1.494.896l-4.245 10.196h2.999l.826-2.207h3.355l.429 2.216h2.645L38.508 8.46zm-3.293 6.656c.058.007 1.151-3.604 1.151-3.604l.871 3.604h-2.022zm-19.642-6.69l-2.8 7.56-.305-1.49c-.518-1.721-2.141-3.59-3.952-4.518l2.564 9.563h3.024l4.493-11.114-3.024-.001z"
                ></path>
                <path
                    fill="#FAA61A"
                    d="M11.527 9.86c-.218-.848-.914-1.42-1.859-1.432H5.233l-.045.207c3.459.853 6.363 3.477 7.304 5.944l-.965-4.719z"
                ></path>
            </g>
        </svg>
    );
}