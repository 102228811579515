


export const availiableProductsFilterOptions = [
  {
    key: "linkedWarehouse",
    value: "Products (Linked Warehouse",
  },
  {
    key: "seller",
    value: "Products (Sellers)",
  },
];
