

import { Divider, message ,Modal} from "antd";
import Form from "antd/lib/form/Form";
import Search from "antd/lib/input/Search";
import confirm from "antd/lib/modal/confirm";
// import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import React, { Fragment, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { fetchHighestDiscountProducts, replaceCentralRepoProduct, universalBarcodeSearch } from "../../../../ApiAction/AdminApiAction/adminApi";
import { fetchCentralRepo } from "../../../../ApiAction/AdminApiAction/centralRepoApi";
import { FILE_PATH } from "../../../../Utils/constants";
import { GoVerified } from "react-icons/go";


const ReplaceProductModal = (props) =>{
    const [barcodeSearching, setBarcodeSearching] = useState("");
    const [productData, setProductData] = useState([]);
    const{visible,setVisible,product,prodId,setReload} = props;
    const [selectedProd, setSelectedProd] = useState("");
    const [prodName, setProdName] = useState("");
    const [loading, setIsLoading] = useState(false);
    const [replaceUpdateCount,setReplaceUpdateCount] = useState("")
    const [replaceProdCount,setReplaceProdCount] = useState("")
    const [confirmVisible,setConfirmVisible] = useState(false);
    const [replaceFrom, setReplaceFrom] = useState("")
    const [replaceTo, setReplaceTo] = useState("")
    // const [form] = Form.useForm();
    const cookies = new Cookies();



    const handleReplaceProduct = () =>{
        setIsLoading(true);
        const payload = {
          fromId: prodId,
          fromName:product,
          toId:selectedProd,
          toName:prodName,
          userId: cookies.get("userId") ? cookies.get("userId") : "",
      }
      replaceCentralRepoProduct(payload).then((response) => {
        if(response?.data?.status === "success"){
            setReplaceProdCount(response?.data?.updatedCount);
            setReplaceUpdateCount(response?.data?.productCount);
            setReplaceFrom(response?.data?.from);
            setReplaceTo(response?.data?.to);
            setVisible(false);
            setReload(prev => !prev);
            Modal.success({
                title: 'Successfully Replaced',
                content: (
                    <div>
                    <p>{response?.data?.message}</p>
                    <p>Product count:  {response?.data?.productCount}</p>
                    <p>Update count:  {response.data.updatedCount}</p>
                    {response?.data?.mergeCount && (<p>Merge count:  {response.data.mergeCount}</p>)}
                    {response?.data?.deletedCount && (<p>Delete count:  {response.data.deletedCount}</p>)}
                  </div>
                ),
            });
        }
        else{
            message.error(response?.data?.message)
        }
      })
      .finally(() => {
        // setProductData([]);
        setIsLoading(false);
      });
      };



    const handleProductSearch = (searchWord) =>{
        const payload = {
          key: searchWord,
          size: 50,
          offset: 0,
          sortBy: "addedDate",
          sortType: "desc",
        //   isDraft:false,
          archive: false,
      }
    //   setIsLoading(true);
      setProductData([]);
      fetchCentralRepo(payload).then((response) => {
        console.log("SUCCESSSSSSS______" + response?.data?.count);
        if(response?.data?.status === "success"){
            setProductData(response?.data?.products);
            
        }
        else{
            setProductData([]);
        }
      })
      .finally(() => {
        // setProductData([]);
      });
      };

      const handleProductSelect = (prod) => {
        setSelectedProd(prod?.id);
        setProdName(prod?.source?.name)
      }


    return(
        <Modal
            title={<h3 className="color-orange">Replace Central Repo Product</h3>}
            visible={visible}
            confirmLoading={loading}
            onCancel={() => {
                setVisible(false);
                setProdName("");
                setSelectedProd("");
                // form.resetFields();
                
              }}   
            okText="Replace"
            onOk={() => {
                handleReplaceProduct();
            }} 
            className="replace-product-model">
            <Fragment>
                <div className="manage-cat-modal-breadcrumb">
                <Text strong>
                            <p style={{"color":"black"}}>Selected Product: <br/>
                            <b style={{"color" : "#808080"}}>{product}</b></p>
                            
                        {/* Search product and select the product to replace. */}
                        </Text>
                {prodName?.length >  0 &&
                        <Text strong>
                            <p style={{"color":"black"}}>Replace Product with: <br/>
                            <b style={{"color" : "green"}}>{prodName}</b></p>
                        {/* Search product and select the product to replace. */}
                        </Text>
                    }
                </div>
                <Form
                    // form={form}
                    className="replace-product-modal-form"
                >
                    <Text>Search Product*</Text>
                    <div >
                    <Search
                        placeholder="Search and select your Product here."
                        size="large"
                        onChange={(e) => handleProductSearch(e.target.value)}
                        onSearch={(value) => handleProductSearch(value)}
                    />
                    
                    {productData.length=== 0 ? (
                        <div className="barcode-container-view-box" style={{
                                color: "red"
                              }}> No Data. Search a product.</div>
                    ) :
                    <div className="replace-product-main-container-view ">
                    {productData?.map((data, index) => (
                        <>
                        <div 
                            className="barcode-container-view-box "
                            key={index} 
                            onClick={()=>{handleProductSelect(data)}}
                            style={{
                                backgroundColor: data?.id === selectedProd ? '#e9ecea' : '',
                                // color: selectedProd ? 'white' : '',
                              }}
                         >
                            <div className="barcode-container-image-box">
                            {data?.source?.images?.length > 0 ? (
                                <img
                                alt="Product Pic"
                                src={FILE_PATH + data?.source?.images[0]}
                                className="replace-product-container-product-image"
                                />
                            ) : (
                                <img
                                alt="Product Pic"
                                // src={dummyImage}
                                className="replace-product-container-product-image"
                                />
                            )}
                            </div>
                            <div>
                                <div style={{display: "flex"}}>
                                    <h4 className="barcode-container-main-heading">{data?.source?.name}</h4>
                                    <span style={{paddingLeft: "5px"}}>{data?.source?.verified && <GoVerified></GoVerified>}</span>
                                </div>
                                <div>
                                    <h4 className="barcode-container-main-heading color-orange">
                                        {/* {catName + subCatOne + subCatTwo + subcatThree + subCatFour !== null && subCatFour}</h4> */}
                                        {data?.source?.categoryName + 
                                            (data?.source?.subcCategory0Name &&
                                                data.source?.subCategory0Name != ""
                                                ? " / " + data?.source?.subCategory0Name
                                                : "") +
                                            (data?.source?.subCategory1Name &&
                                                data.source?.subCategory1Name != ""
                                                ? " / " + data?.source?.subCategory1Name
                                                : "") +
                                            (data?.source?.subCategory2Name &&
                                                data.source?.subCategory2Name != ""
                                                ? " / " + data?.source?.subCategory2Name
                                                : "") +
                                            ((data?.source?.subCategory3Name &&
                                                data.source?.subCategory3Name != ""
                                                ? " / " + data?.source?.subCategory3Name
                                                : ""))
                                            
                                            
                                            }
                                            </h4>
                                </div>
                            </div>
                        </div>
                    <Divider />
                        </>
                    
                    ))}
                    </div>    
                    }
                </div>
                </Form>
            </Fragment>
         </Modal>
  
    )
}
export default ReplaceProductModal;