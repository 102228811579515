import {
    REFETCH_REQUEST_PAYMENT
} from "../action/types";


export default function requestPayment(
    state = {
        refetchRequestPayment: false,
    },
    action
) {
    switch (action.type) {

        case REFETCH_REQUEST_PAYMENT:
            state = { ...state, refetchRequestPayment: !state.refetchRequestPayment };
            break;

        default:
        // console.log("invalid choice");
    }

    return state;
}
