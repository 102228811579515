


import React, { useEffect, Fragment, useState, useContext } from "react";
import { Button, Typography, Modal, message } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import Cookies from "universal-cookie";

import "./orderList.scss";
import CardIcon from "../../../../../Assets/Images/Icons/card.svg";
import DateIcon from "../../../../../Assets/Images/Icons/date.svg";
import NotifyIcon from "../../../../../Assets/Images/Icons/notify.svg";
import TrackIcon from "../../../../../Assets/Images/Icons/track.svg";

import {
  CancelOrderItem,
  returnPeriodExpired,
} from "../../../../../ApiAction/CustomerApiActions/orderAPI";
import { ENV_TYPE, FILE_PATH } from "../../../../../Utils/constants";
import { refetchOrderList } from "../../../../../ReduxStore/action/customerOrderAction";

import AddressAndReturnDate from "./addressAndReturnDate";
import ReturnRequest from "./returnRequest";
import OrderContext from "./Context/orderContext";
import CancelOrderRequest from "./cancelOrderRequest";
import { history } from "../../../../../App";

const { Text } = Typography;
const confirm = Modal.confirm;
const cookies = new Cookies();
const eligibleStatus = ["ORDERED", "PACKED"];

//this componnent is used in Active order listing, past order listing, return request modal,

const OrderProduct = (props) => {
  const { product, groupId, isRequest, isStore } = props;
  const [returnRequestVisible, setreturnRequestVisible] = useState(false);
  const [cancelOrderModal, setCancelOrderVisible] = useState(false);
  const [returnEnds, setReturnEnds] = useState(product.isReturnPeriodOver)

  const orderContext = useContext(OrderContext);

  const accountType = cookies.get("accountType")
    ? cookies.get("accountType")
    : "customer";

  const handleCancelConfirm = (reason = "") => {
    const payload = {
      orderGroupId: groupId,
      orderId: product.orderId,
      reasonOfCancellation: reason,
    };

    CancelOrderItem(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success("Item canceled succefully");
            props.refetchOrderList();
          }
        }
      })
      .catch(() => {});
  };

  const handleCancelOrder = (reason = "") => {
    setCancelOrderVisible(false);
    confirm({
      title: `Do you want to Cancel this Item!!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        handleCancelConfirm(reason);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleCancelItem = () => {
    setCancelOrderVisible(true);
  };

  const lastDateForReturn = moment(product.itemOrderStatus.deliveredDate)
    .add(product?.returnPeriod ? product.returnPeriod + 1 : 0, "days")
    .format("MMMM Do YYYY");
  // console.log("Last date for return: ", lastDateForReturn);

  //inside this use effect we are calling function to update returnPeriodOver as true
  useEffect(() => {
    console.log("Printing Values inside UseEffect :: ")
    console.log(product.itemOrderStatus.currentStatus && !product?.isReturnPeriodOver)
    if (
      product.itemOrderStatus.currentStatus === "DELIVERED" &&
      !product?.isReturnPeriodOver
    ) {
      var endDate =
        moment(product.itemOrderStatus.deliveredDate)
          .add(product?.returnPeriod ? product.returnPeriod : 0, "days")
          .format("X") * 1000;
      var currentDate = moment(new Date()).format("X") * 1000;
      console.log("End Date :: "+endDate)
      console.log("Current Date :: "+currentDate)
      if (currentDate >= endDate) {
        //if return period is today or any previous days
        console.log("inside if")
        const payload = {
          orderGroupId: groupId,
          orderId: product.orderId,
        };
        setReturnEnds(true)
        returnPeriodExpired(payload);
      }else {
        setReturnEnds(false)
      }
    }
  }, [product]);

  const renderDriverDetails = (infoContent) => {
    return (
      <Fragment>
        {infoContent ? (
          <Fragment>
            <div>
              <Text>Driver Name: </Text>
              <Text strong> {infoContent.driverName}</Text>
            </div>
            <div>
              <Text>Driver Contact Number: </Text>
              <Text strong> {infoContent.driverNumber}</Text>
            </div>
            <div>
              <Text>Vehicle Number: </Text>
              <Text strong> {infoContent.vehicleNumber}</Text>
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true
    } catch {
      return false
    }
  }

  const renderItemTrackingDetail = (infoContent) => {
    return (
      <Fragment>
        {infoContent? (
          <Fragment>
            <div>
              <Text>Tracking ID : </Text>
              <Text strong> {infoContent.trackingId} </Text>
            </div>
            <div>
              <Text>Tracking Link : </Text>
              {
                isValidUrl(infoContent.trackingLink)?
                  <Text strong> <a href={infoContent.trackingLink} target="_blank" rel="noopener noreferrer">{infoContent.trackingLink}</a> </Text> :
                  <Text strong> {infoContent.trackingLink} </Text>
              }             
            </div>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  }

  const renderTrackingDetails = (infoTitle, driverDetails) => {
    Modal.info({
      title: infoTitle,
      content: driverDetails,

      onOk() {},
    });
  };

  const handleTrackOrder = (status) => {
    let infoTitle;
    let infoContent;

    if (status.currentStatus === "SHIPPED") {
      infoTitle = "This Item has been shipped.";
      infoContent = status.shipmentTracker;
    } else {
      infoTitle = "This item is yet to be shipped";
    }
    switch (cookies.get("accountType")) {
      case "warehouse":
        renderTrackingDetails(infoTitle, renderDriverDetails(infoContent));
        break;
      case "store":
        renderTrackingDetails(infoTitle, renderDriverDetails(infoContent));
        break;
      case "customer":
        renderTrackingDetails(infoTitle, renderItemTrackingDetail(infoContent));
      default:
        console.log("invalid choice");
    }
  };
  const displayCurrentStatus = (currentStatus) => {
    switch (currentStatus) {
      case "RETURN_REQUESTED":
        return "RETURN REQUESTED";
        break;

      case "RETURN_REQUEST_REJECTED":
        return "REQUEST REJECTED";
        break;

      case "RETURN_REQUEST_ACCEPTED":
        return "REQUEST ACCEPTED";
        break;

      default:
        return currentStatus;
    }
  };

  const handleRedirection = () => {
    // if (accountType === "store") {
    //   history.push(
    //     `/store/linked-warehouse/product-detail/${product.productId}`
    //   );
    // } else
    if (accountType === "customer") {
      history.push(
        ENV_TYPE === "production"
          ? `/product-detail/${product.productId}`
          : `/customer/product-detail/${product.productId}`
      );
    }
    //  else if (accountType === "warehouse") {
    //   history.push(
    //     `/warehouse/linked-warehouse/product-detail/${product.productId}`
    //   );
    // }
  };

  return (
    <div className="order_product_container">
      {" "}
      <div className="order_product_image" style={{ width: "135px" }}>
        <div
          className="cursor_pointer"
          onClick={handleRedirection}
        >
          <img
            src={FILE_PATH + product.image}
            alt="product_image"
            style={{ width: "100%", alignSelf: "center" }}
          />
        </div>
        {!isRequest && (
          <div
            className="product_order_status customer_status"
            dataorder={product.itemOrderStatus.currentStatus.toLowerCase()}
          >
            {" "}
            {displayCurrentStatus(product.itemOrderStatus.currentStatus)}
            {/* {product.itemOrderStatus.currentStatus} */}
          </div>
        )}
      </div>
      <div className="order_product_details">
        {!isRequest && (
          <div className="dlt1">
            <Text type="secondary">ODR {product.orderId}</Text>
          </div>
        )}

        <div className="dlt2 cursor_pointer" onClick={handleRedirection}>
          <Text strong>{product.productName}</Text>
        </div>

        <div className="dlt3">
          <Text strong className="order_product_DLP">
            ₹ {product.itemCurrentPrice.sellingPrice}
          </Text>{" "}
          &nbsp;
          <Text strong className="order_product_weight">
            {`${product.itemCurrentPrice.quantity}  ${product.itemCurrentPrice.unit}`}
          </Text>
          &nbsp;
          <Text strong className="order_product_quantity">
            <span style={{ color: "gray", fontWeight: "500" }}> Qty:</span>{" "}
            {product.quantity}
          </Text>
        </div>

        {isRequest ? ( //this part only appears in request return modal only
          <Fragment>
            <div className="custr_mng_rtrn" style={{ marginTop: "8px" }}>
              <div className="date_return">
                <img
                  className="order_product_icon"
                  src={DateIcon}
                  alt="date_icon"
                  style={{ width: "16px" }}
                />

                {moment(orderContext.orderSummary.orderedDate).format(
                  " MMM DD, hh:mm A"
                )}
              </div>
              <div className="ord_typ">
                <img
                  src={CardIcon}
                  alt="card_icon"
                  className="order_product_icon"
                  style={{ width: "20px" }}
                />

                {orderContext.orderSummary.paymentMode}
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="dlt4">
            <Text type="secondary" delete>
              ₹ {product.itemCurrentPrice.price}
            </Text>
          </div>
        )}
        {["ORDERED", "PACKED", "SHIPPED"].includes(
          product.itemOrderStatus.currentStatus
        ) &&
          isStore && ( //only store products are deliverable within 24hrs
            <div className="dlt5">
              <button>Delivery in 24 hours</button>
            </div>
          )}
      </div>
      {/* //this part of code to be used in past order  || active order and not for request return// */}
      {!isRequest &&
        (product.itemOrderStatus.currentStatus === "DELIVERED" ? (
          <div className="order_prouct_details_actio ret-avail">
            {" "}
            <div className="d-flex return_info">
              <img
                src={NotifyIcon}
                style={{ width: "13px", marginRight: "5px" }}
                alt="icon"
              />
              {returnEnds === true ? (
                <Text strong className="canceled_item_indicator">
                  Return Period Expired
                </Text>
              ) : (
                <Text strong className="canceled_item_indicator">
                  Return available till {lastDateForReturn}
                </Text>
              )}
            </div>{" "}
            {!returnEnds && (
              <Button
                style={{
                  float: "right",
                  marginTop: "10px",
                  backgroundColor: "#0074be",
                  border: "1px solid red",
                  marginRight: "5px",
                }}
                type="primary"
                onClick={() => setreturnRequestVisible(true)}
                className="return_info_btn"
              >
                {" "}
                Request Return
              </Button>
            )}
          </div>
        ) : product.itemOrderStatus.currentStatus !== "CANCELLED" &&
          product.itemOrderStatus.currentStatus !== "RETURN_REQUESTED" &&
          product.itemOrderStatus.currentStatus !== "RETURN_REQUEST_REJECTED" &&
          product.itemOrderStatus.currentStatus !==
            "RETURN_REQUEST_ACCEPTED" ? (
          <Fragment>
            <div className="track-cancle">
              <div className="order_prouct_details_action cancle-ord">
                <Button
                  disabled={
                    eligibleStatus.includes(
                      product.itemOrderStatus.currentStatus
                    )
                      ? false
                      : true
                  }
                  style={{
                    border: "1px solid red",
                    marginRight: "5px",
                  }}
                  type="text"
                  onClick={() => handleCancelItem()}
                >
                  Cancel Item
                </Button>
              </div>
              <div className="order_prouct_details_action trck_ord">
                <Button
                  icon={<img src={TrackIcon} className="track_icon" />}
                  onClick={() => handleTrackOrder(product.itemOrderStatus)}
                >
                  {" "}
                  Track Order
                </Button>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="order_prouct_details_action justify-content-end">
            <Text strong className="canceled_item_indicator">
              {product.itemOrderStatus.currentStatus == "CANCELLED"
                ? "Item canceled"
                : "Requested for return"}
            </Text>
          </div>
        ))}
      {isRequest && ( //this part of code will only visible in return request modal
        <AddressAndReturnDate
          shippingAddress={orderContext.orderSummary.shippingAddress}
          lastDateForReturn={lastDateForReturn}
        />
      )}
      <ReturnRequest
        visible={returnRequestVisible}
        setvisible={setreturnRequestVisible}
        product={product}
        groupId={groupId}
      />
      {cancelOrderModal === true && (
        <CancelOrderRequest
          visible={cancelOrderModal}
          setVisible={setCancelOrderVisible}
          setSuccess={handleCancelOrder}
          cancelItem
        />
      )}
    </div>
  );
};

const mapDispatchToProps = { refetchOrderList };

export default connect(null, mapDispatchToProps)(OrderProduct);
