


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import {
  fetchExpiryReport,
  exportExpiryReport,
} from "../../../../ApiAction/AdminApiAction/reportsApi";
import {
  getExpiryReportColumns,
  expiryReportColumns,
  formatExpiryReport,
} from "../Utils/reportHelper";
import ReportsTable from "../ReportsTable";

const ExpiryReport = (props) => {
  const { reportSummary, searchVal, vendorId } = props;

  const [dataSource, setdataSource] = useState([]);
  const [reload, setreload] = useState(false);
  const [isLoadingExpiryReport, setIsLoadingExpiryReport] = useState(false);
  const [regenerate, setRegenerate] = useState(false);

  const handleExportExpiryReport = async () => {
    const payload = {
      offset: 0,
      size: 10,
      sortBy: "expiryDate",
      sortType: "asc",
      vendorId: vendorId ? vendorId : "",
      key: searchVal ? searchVal : "",
    };
    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endTime"] = props.dateRange.endtime;
    }

    const expiryReport = await exportExpiryReport(payload)
      .then((response) => {
        console.log(response, "response for voucher code excel sheet");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `expiry_report.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        message.error("Could not export file please try again");
      });
    // .finally(() => {});
    return true;
  };
  useEffect(() => {
    const payload = {
      offset: 0,
      size: 10,
      vendorId: vendorId ? vendorId : "",
      sortBy: "expiryDate",
      sortType: "asc",
      key: searchVal ? searchVal : "",
    };
    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endTime"] = props.dateRange.endtime;
    }
    setIsLoadingExpiryReport(true);
    fetchExpiryReport(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setdataSource(formatExpiryReport(response.data.documents));
          }
        }
        console.log("response for expiry report: ", response);
      })
      .catch(() => {
        message.error("some thing went wrong please try again");
      })
      .finally(() => {
        setIsLoadingExpiryReport(false);
      });
  }, [searchVal, props.dateRange, reload, regenerate]);
  return (
    <ReportsTable
      download={handleExportExpiryReport}
      columns={getExpiryReportColumns({ reload: reload, setreload: setreload })}
      dataSource={dataSource}
      type={"Expiry"}
      reportSummary={reportSummary}
      summary={
        "The most recent 10 expiry items are listed below. To get the complete expiry report, download csv"
      }
      isLoadingReport={isLoadingExpiryReport}
      setRegenerate={setRegenerate}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
    brandId: state.reportReducer.brandId,
    dateRange: state.reportReducer.dateRange,
    vendorFilter: state.reportReducer.vendorId,
  };
};

export default connect(mapStateToProps)(ExpiryReport);
