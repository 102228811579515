import React, { useState, useEffect } from "react";
import { Table, Pagination, Divider, Tag, Popover, Button, Tabs } from "antd";
import { SafetyCertificateFilled, InfoCircleOutlined } from "@ant-design/icons";
import FilterRequest from "../../Seller/RequestPayment/FilterRequest";
import Column from "antd/lib/table/Column";
import { RequestConstants } from "../../../Utils/constants";
import {
  getManageAccount,
  getInvoiceSplits,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import moment from "moment";
import PlatformFilters from "../../Seller/PlatformRecovery/PlatformFilters";
import ManagePlatformTable from "../../Seller/PlatformRecovery/ManagePlatformTable";
import { VscCircleSlash } from "react-icons/vsc";
import Cookies from "universal-cookie";

function ManagePlatformIndex(props) {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const [statusKey, setStatusKey] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadType] = useState("admin");
  const [placeholder] = useState("Search here (Eg: Nadathara)");
  const [manageAccountsData, setManageAccountsData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState();
  const cookies = new Cookies();
  const [accountType] = useState(cookies.get("accountType"));
  const [colorFilterKey, setColorFilterKey] = useState("-1");
  const [activeCustomer] = useState(
    JSON.parse(localStorage.getItem("activeCustomer"))
  );

  const { TabPane } = Tabs;
  const { vendorType, type } = props;
  const [activeTab, setActiveTab] = useState(
    vendorType === "SELLER" ? "1" : "2"
  );

  const handleActiveTab = (activeTab) => {
    setActiveTab(activeTab);
  };

  useEffect(() => {
    setActiveTab(
      type === "summary" ? "4" : vendorType === "SELLER" ? "1" : "2"
    );
    setSelectedVendorId("");
  }, [vendorType]);

  useEffect(() => {
    if(accountType === "affiliate") {
      setSelectedVendorId(activeCustomer?.userCode?.substring(3));
    }
  }, []);

  return (
    <div className="manage-accounts">
      <div className="manage-top-part">
        <h2 style={{ fontWeight: "bold" }}>
          <SafetyCertificateFilled />{" "}
          {type === "summary" ? (
            "Platform Charge Summary"
          ) : (
            <>
              {vendorType == "AFFILIATE" ?
              accountType == "affiliate" ?
                "Affiliate Charge" : "Manage Affiliate Charge"
              : "ManagePlatform Charge of "}
              <p className="platform-vendor-label">
                {" "}
                {vendorType === "SELLER" ? "SELLERS" :
                  vendorType === "AFFILIATE" ? "" : "STORES"}
              </p>
            </>
          )}
        </h2>
        <h4 style={{ color: "rgba(0,0,0,0.5)" }}>
        {accountType == "affiliate" ? "Invoice status can be tracked here" :
        "All Transactions related to " + (vendorType == "AFFILIATE" ? "Affiliate" : "Platform")  + " Charge can be performed here."}
        </h4>
        {/* This Popover will display information about this Service */}
      </div>
      <Divider />
      {accountType !== "affiliate" && (
        <PlatformFilters
          downloadType={downloadType}
          placeHolder={placeholder}
          setSearchVal={setSearchVal}
          setStatusKey={setStatusKey}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          searchVal={searchVal}
          startTime={startTime}
          endTime={endTime}
          statusKey={statusKey}
          totalDoc={totalCount}
          setOffset={setOffset}
          setCurrentPage={setCurrentPage}
          offset={offset}
          vendorType={vendorType}
          setSelectedVendorId={setSelectedVendorId}
          showVendorNameFilter={true}
          type={type}
          setColorFilterKey={setColorFilterKey}
        />
      )}

      {type === "summary" ? (
        <>
          {accountType === "admin" && (
            <ManagePlatformTable
              vendorType={vendorType}
              startTime={startTime}
              endTime={endTime}
              searchVal={searchVal}
              activeTab={"4"}
              vendorId={selectedVendorId}
              keys="4"
              colorFilterKey={colorFilterKey}
            />
          )}
        </>
      ) : (
        <>
          {vendorType === "SELLER" ? (
            <Tabs
              activeKey={activeTab}
              defaultActiveKey={activeTab}
              onChange={handleActiveTab}
            >
              <TabPane tab="Open" key="1">
                <ManagePlatformTable
                  vendorType={vendorType}
                  requestStatus="OPEN"
                  startTime={startTime}
                  endTime={endTime}
                  searchVal={searchVal}
                  activeTab={activeTab}
                  vendorId={selectedVendorId}
                  keys="1"
                />
              </TabPane>
              <TabPane tab="Paid" key="7">
                <ManagePlatformTable
                  vendorType={vendorType}
                  requestStatus="PAID"
                  startTime={startTime}
                  endTime={endTime}
                  searchVal={searchVal}
                  activeTab={activeTab}
                  vendorId={selectedVendorId}
                  keys="7"
                />
              </TabPane>
            </Tabs>
          ) : (
            <Tabs
              activeKey={activeTab}
              defaultActiveKey={activeTab}
              onChange={handleActiveTab}
            >
              <TabPane tab="Open" key="2">
                <ManagePlatformTable
                  vendorType={vendorType}
                  requestStatus="OPEN"
                  startTime={startTime}
                  endTime={endTime}
                  searchVal={searchVal}
                  activeTab={activeTab}
                  vendorId={selectedVendorId}
                  setSelectedVendorId={setSelectedVendorId}
                  keys="2"
                />
              </TabPane>
              <TabPane tab="Invoiced" key="3">
                <ManagePlatformTable
                  vendorType={vendorType}
                  requestStatus="INVOICED"
                  startTime={startTime}
                  endTime={endTime}
                  searchVal={searchVal}
                  activeTab={activeTab}
                  vendorId={selectedVendorId}
                  keys="3"
                />
              </TabPane>
              <TabPane tab="Processing" key="5">
                <ManagePlatformTable
                  vendorType={vendorType}
                  requestStatus="PROCESSING"
                  startTime={startTime}
                  endTime={endTime}
                  searchVal={searchVal}
                  vendorId={selectedVendorId}
                  activeTab={activeTab}
                  keys="5"
                />
              </TabPane>
              <TabPane tab="Paid" key="6">
                <ManagePlatformTable
                  vendorType={vendorType}
                  requestStatus="PAID"
                  startTime={startTime}
                  endTime={endTime}
                  searchVal={searchVal}
                  vendorId={selectedVendorId}
                  activeTab={activeTab}
                  keys="6"
                />
              </TabPane>
            </Tabs>
          )}
        </>
      )}
    </div>
  );
}

export default ManagePlatformIndex;
