


import React from "react";
import { Typography } from "antd";

import NotifyIcon from "../../../../../Assets/Images/Icons/notify.svg";
import LocationIcon from "../../../../../Assets/Images/Icons/Seller/location.svg";

const { Text } = Typography;



const getValueWithFormat = (shippingAddress) => {
  

  if(shippingAddress)
  {
  

    const filterArray=[
     
      shippingAddress.buildingName?shippingAddress.buildingName:null,
      shippingAddress.addressLine1?shippingAddress.addressLine1:null,
      shippingAddress.addressLine2?shippingAddress.addressLine2:null,
      shippingAddress.district?shippingAddress.district:null,
      shippingAddress.state?shippingAddress.state:null
     
    
    ];
    console.log("data??",filterArray);
  
    const filtered = filterArray.filter(function (el) {
      return el != null;
    })
    const filteredNullStringValues = filtered.filter(Boolean)
  
    return filteredNullStringValues && filteredNullStringValues.toString().split(',').join(', ');
  }
  
  
      
    };



const AddressAndReturnDate = (props) => {
  const { shippingAddress } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div className="allocte-dt" style={{ display: "flex" }}>
        <img
          src={LocationIcon}
          style={{ width: "20px", marginRight: "10px", 
          alignSelf: "flex-start" }}
        />
        <div className="line-break-location">
          {/* {shippingAddress.buildingName +
            ", " +
            shippingAddress.addressLine1 +
            ", " +
            shippingAddress.addressLine2 +
            ", " +
            shippingAddress.pinCode +
            ", " +
            shippingAddress.district +
            ", " +
            shippingAddress.state} */}
            {getValueWithFormat(shippingAddress)}
        </div>
      </div>
      <div style={{ display: "flex", marginTop:"15px" }}>
        <img src={NotifyIcon} style={{ width: "20px", marginRight: "10px" }} />
        <Text className="agn-cnt">Return available till {props.lastDateForReturn}</Text>
      </div>
    </div>
  );
};

export default AddressAndReturnDate;
