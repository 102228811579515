


import React, { useEffect, Fragment, useState } from "react";

import { Card, Typography, Button } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";

import { ENV_TYPE } from "../../../../../Utils/constants";

import "./orderResponse.scss";
import OrderDetails from "./Components/orderDetails";

import OrderSuccessVector from "../../../../../Assets/Images/Vectors/Payent_Success.svg";
import OrderFailedVector from "../../../../../Assets/Images/Vectors/Payment_failed.svg";
import WarningVector from "../../../../../Assets/Images/Vectors/Warning.svg";

const { Title, Text } = Typography;
const cookies = new Cookies();

const OrderResponse = (props) => {
  const [orderStatus, setorderStatus] = useState();
  const [orderSummary, setorderSummary] = useState();
  const [isPending, setPending] = useState();
  const isCustomer = cookies.get("accountType") === "customer";

  const handleContinueShopping = () => {
    switch (cookies.get("accountType")) {
      case "customer":
        props.history.push(ENV_TYPE === "production" ? "/" : `/customer`);
        break;
      case "warehouse":
        props.history.push(`/warehouse/check-availiablity`);
        break;
      case "store":
        props.history.push(`/store/check-availiablity`);
        break;
      default:
        console.log("invalid choice");
    }
  };

  //check order status
  useEffect(() => {
    console.log("order status: ", props.match.params.status);
    setorderStatus(props.match.params.status === "success" ? true : false);
    setPending(props.match.params.status === "pending")
  }, [props.match]);

  //fetch order summary from storeI
  useEffect(() => {
    if (props.orderSummary?.orderedItems) {
      setorderSummary(props.orderSummary);
    } else {
      handleContinueShopping();
      // props.history.push(`/customer`);
    }
  }, [props]);

  return (
    <div className="order_response_container final-order-recvd">
      <div style={{ maxWidth: "1300px", margin: "0 auto" }}>
        <Card style={{ width: "100%" }}>
          {isCustomer && (
            <Fragment>
              <div
                className={
                  orderStatus
                    ? "order_response_title_success"
                    : isPending? "order_response_title_pending":
                    "order_response_title_failed"
                }
              >
                <Text>
                  {" "}
                  {orderSummary?.paymentMode === "COD"
                    ? orderStatus
                      ? "Order Confirmed !"
                      : isPending? "Order Unconfirmed !": "Order Failed !"
                    : orderStatus
                    ? "Payment Successful!"
                    : isPending? "Payment Pending" : "Payment Failed!"}
                </Text>
              </div>
              <div className="order_response_vector">
                <img
                  src={orderStatus ? OrderSuccessVector : OrderFailedVector}
                  style={{ width: "350px" }}
                />
              </div>
            </Fragment>
          )}

          <div className="order_response_details">
            {orderStatus ? (
              <Fragment>
                <Text className="order_detail_title">
                  Your Order is confirmed
                </Text>
                <OrderDetails orderSummary={orderSummary} />
              </Fragment>
            ) : (
              <div className="order_failed">
                <img
                  alt="failure vector"
                  src={WarningVector}
                  style={{ width: "200px" }}
                />

                <Text strong style={{ fontSize: "18px", padding: "15px" }}>
                  {isPending? "Please wait for sometime" : "Please try after sometime."}
                </Text>
                <Button className="place_order_btn">Place Order</Button>
              </div>
            )}
            <Button
              className="continue_shopping_button"
              type="primary"
              onClick={() => handleContinueShopping()}
            >
              Continue Shopping
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    orderSummary: state.customerOrderReducer.orderResponse,
  };
};

export default withRouter(connect(mapStateToProps, null)(OrderResponse));
