


import React, { useEffect, useState, Fragment } from "react";
import { Menu } from "antd";

import { NavLink } from "react-router-dom";
import "antd/dist/antd.css";
import "../Admin/admin.scss";
import { fetchSort } from "../../ReduxStore/action";
import { connect } from "react-redux";

import ManageReturnsIcon from "../../Assets/Images/Icons/Seller/returns.svg";
import ManageReturnsIconActive from "../../Assets/Images/Icons/Seller/returnsActive.svg";
import { sideBarDataAffiliate } from "../../Utils/Seller/Data";
const { SubMenu } = Menu;
const AffiliateMenu = (props) => {
  const { activeTab, setSearchval } = props;
  const [urlPath, setUrlPath] = useState("");
  const [selectedMenu, setActiveMenu] = useState("my-profile");

  const menuItemClick = (data) => {
    setActiveMenu(data.key);
    setSearchval && setSearchval("");
    if (props.isNav) {
      props.setNavVisible(false);
    }
  };

  useEffect(() => {
    setUrlPath(props.pathname);
  }, [props]);

  return (
    <Menu
      className="side-nav-menu"
      // theme="dark"
      mode="inline"
      defaultSelectedKeys={[activeTab]}
      selectedKeys={[activeTab]} //activeTab refers to the current active tab
      onClick={menuItemClick}
      style={{borderRadius: "1em"}}
    >
      {sideBarDataAffiliate.map((data) => {
        return (
          <Menu.Item
            key={data.key}
          // disabled={data.key !== "manage-products" ? true : false}
          >
            <NavLink to={data.url}>
              <span>
                {" "}
                <img
                  src={data.key === activeTab ? data.activeIcon : data.icon}
                  style={{ width: "20px", marginRight: "10px" }} alt={data.label}
                />
              </span>
              <span className="side-menu-link-content">
                <span>{data.label}</span>
                {data.newModule && (<span className="new-label">{data.newModule}</span>)}
              </span>
            </NavLink>
          </Menu.Item>
        );
      })}

      <SubMenu
        key="sales-report"
        title={
          <Fragment>
            <span>
              <img alt="Reports"
                src={
                  "sales-report" === activeTab
                    ? ManageReturnsIconActive
                    : ManageReturnsIcon
                }
                style={{ width: "20px", marginRight: "10px" }}
              />
            </span>
            <span className="side-menu-link-content" style={{fontWeight:"400",fontSize:"15px",lineHeight:"normal"}}>
              <span>Reports</span>
            </span>
          </Fragment>
        }
      >
        <Menu.Item key="sales-report">
          <NavLink to={"/affiliate/sales-report"}>
            <span className="side-menu-link-content">Sales Report</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="affiliate-charge-report">
          <NavLink to={"/affiliate/affiliate-charge-report"}>
            <span className="side-menu-link-content">Affiliate Charge Report</span>
          </NavLink>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AffiliateMenu);
