

import React, { useState, useEffect } from "react";
import Avatar from "antd/lib/avatar/avatar";
import { Table, Button, Pagination, Popover, Modal, message } from "antd";
import CustomerDetailsModal from "./customerDetailsModal";
import SellerRequest from "../ManageSeller/sellerRequest";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  deleteUsers,
  userBlock,
  userArchive,
  deleteUserId,
} from "../../../ApiAction/AdminApiAction/adminApi";
import { ENV_TYPE, DOMAIN } from "../../../Utils/constants";
import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";
import EmptyList from "../../GeneralComponents/emptyList";
import {
  findBrowser,
  findDeviceModel,
} from "../Logger/notificationBar/LogHelper";
import {changeOrderStatusAuthenticatedUsers} from "../../../Utils/Seller/Data";

const { Column } = Table;
const confirm = Modal.confirm;

const ManageCustomerTable = (props) => {
  const cookies = new Cookies();
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  // const [isLoadingCustomer, setIsLoadingCustomer] = useState(props.isLoadingCustomer);
  const onChange = (page) => {
    props.onPageChange(page);
  };

  const viewRequests = () => {
    setRequestModal(true);
  };
  const setApproved = (id) => {
    setRequestModal(false);
    setData(data.filter((item) => item.id !== id));
  };
  const switchToSeller = (id) => {
    if (ENV_TYPE == "dev") {
      cookies.set("userId", id, {
        path: "/",
      });

      cookies.set("accountType", "seller", {
        path: "/",
      });
    } else {
      cookies.set("userId", id, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      cookies.set("accountType", "seller", {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    }
    props.history.push(`/seller`);
  };

  const deleteUserID = (customerId) => {

    if(customerId === "") {
      message.error("Invalid ID")
      return
    }
    confirm({
      title: `Do you want to delete this user!!`,
      className: "create-confirm-modal",
      content: customerId,
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        deleteUserId(customerId)
          .then((response) => {
            if (response.data.status == "success") {
              setData(
                data.filter((item) =>
                  item.referralId !== customerId
                )
              );
              message.success("Deleted customer");
            } else {
              message.error(response.data.message);
            }
          })
          .catch(() => {
            message.error("Failed to delete customer")
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const deleteUser = (id, value, block) => {
    const payload = {
      userId: id,
      adminUser: cookies.get("userId"),
      pageURL: window.location.href,
      screenName: props.isSeller ? "Manage Seller" : "Manage Customer",
      browserInfo: findBrowser(),
      accountType: cookies.get("accountType"),
      deviceModel: findDeviceModel(),
    };
    confirm({
      title: `Do you want to ${value ? "Unarchive" : "archive"} this user!!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        userArchive(payload)
          .then((response) => {
            if (response.data.status == "success")
              setData(
                data.map((item) =>
                  item.id === id
                    ? { ...item, isArchived: !value, block: !block }
                    : item
                )
              );
            // setData(data.filter(item => item.id !== id))
          })
          .catch(() => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const blockUnblockUser = (id, value) => {
    const payload = {
      userId: id,
      adminUser: cookies.get("userId"),
      pageURL: window.location.href,
      screenName: props.isSeller ? "Manage Seller" : "Manage Customer",
      browserInfo: findBrowser(),
      accountType: cookies.get("accountType"),
      deviceModel: findDeviceModel(),
    };
    confirm({
      title: value
        ? "Do you want to unblock this user!!"
        : "Do you want to block this user!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        userBlock(payload)
          .then((response) => {
            if (response.data.status == "success")
              setData(
                data.map((item) =>
                  item.id === id ? { ...item, block: !value } : item
                )
              );
          })
          .catch(() => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    setData(props.userData);
    setShowModal(false);
  }, [props]);

  return (
    <div
      onClick={() => {
        setShowModal(false);
      }}
    >
      <Table
        // loading = {true}
        dataSource={data}
        locale={{
          emptyText: (
            <span style={{ color: "black", fontWeight: "600" }}>
              {props.isSeller ? (
                props.isLoadingSeller ? (
                  <LoadingCartItem />
                ) : (
                  <EmptyList msg={"No sellers found"} />
                )
              ) : props.isLoadingCustomer ? (
                <LoadingCartItem />
              ) : (
                <EmptyList msg={"No customers found"} />
              )}
            </span>
          ),
        }}
        pagination={false}
        onRow={(item) => ({
          onClick: (e) => {
            e.stopPropagation();
            setShowModal(!showModal);
            setSelectedId(item.id);
          },
        })}
      >
        <Column
          title={""}
          dataIndex="firstName"
          render={(name, item) => (
            <div style={{ paddingLeft: "3%" }}>
              <Avatar size={40} icon={item.name && item.name.charAt(0)} />
            </div>
          )}
        />

        <Column
          title={
            data?.length > 0
              ? props.isSeller
                ? "Seller Id"
                : props.isAffiliate
                  ? "Affiliate Id"
                : "Customer Id"
              : ""
          }
          dataIndex="id"
          render={(name, item) => (
            <div className="table-customer-id">{item.userCode}</div>
          )}
        />
        <Column
          title={
            data?.length > 0
              ? props.isSeller
              ? "Seller Name"
              : props.isAffiliate
                ? "Affiliate Name"
                : "Customer Name"
              : ""
          }
          dataIndex="name"
          render={(name, item) => (
            <div>
              <Popover
                content={
                  <CustomerDetailsModal
                    details={item}
                    viewRequests={viewRequests}
                    switchToSeller={switchToSeller}
                    isSeller={props.isSeller}
                    isAffiliate={props.isAffiliate}
                    requests={props.requests}
                  />
                }
                placement="bottomRight"
                trigger="click"
                visible={item.id === selectedId && showModal}
                // getPopupContainer={trigger => trigger.parentElement}
                // onVisibleChange={()=>setShowModal(!showModal)}
              >
                <div className="table-customer-name">{item.name}</div>
              </Popover>
            </div>
          )}
        />
        <Column
          title={data?.length > 0 ? !props.isSeller && !props.isAffiliate && "Customer ID" : ""}
          dataIndex="referralId"
          render={(name, item) => (
            <div className="table-customer-id">{props.isAffiliate? "" : item.referralId}</div>
          )}
        />
        <Column
          title={""}
          dataIndex="name"
          render={(name, item) => (
            <div className="cusromer-actions">
              {!props.delete && (
                <Button
                  className={
                    item.block
                      ? "button-customer-unblock"
                      : "button-customer-block"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    blockUnblockUser(item.id, item.block);
                  }}
                >
                  {item.block ? "Unblock" : "Block"}
                </Button>
              )}
              <Button
                className="button-customer-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  // deleteUser(item.id)
                  deleteUser(item.id, item.isArchived, item.block);
                }}
              >
                {item.isArchived ? "Unarchive" : "Archive"}
              </Button>
              {props.location.pathname === "/admin/manage-customer" &&
               changeOrderStatusAuthenticatedUsers.includes(JSON.parse(localStorage.getItem("activeCustomer"))?.userId)? (
                <Button
                className="button-customer-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  // deleteUser(item.id)
                  deleteUserID(item.referralId);
                } }
              >
                  Delete
                </Button>
              ):""}
            </div>
          )}
        />
      </Table>
      {props.total > 10 && (
        <Pagination
          size="small"
          total={props.total}
          current={props.currentPage}
          defaultCurrent={1}
          showSizeChanger={false}
          className="customer-pagination"
          onChange={(page) => {
            onChange(page);
            window.scrollTo(0, 0);
          }}
        />
      )}
      {requestModal && (
        <SellerRequest
          selectedId={selectedId}
          visible={requestModal}
          setSuccess={setApproved}
          setVisible={setRequestModal}
          isAffiliate={props.isAffiliate}
          isFromStore
          accountType={"affiliate"}
        />
      )}
    </div>
  );
};
export default withRouter(ManageCustomerTable);
