


import React from "react";
import { Table, Tag, Space, Typography } from "antd";

const { Text } = Typography;
const StockOverviewTable = (props) => {
  const { data } = props;
  const columns = [
    {
      title: "Sl no",
      dataIndex: "slno",
      key: "slno",
      width: "15%",
      render: (text) => (
        <Text strong style={{ fontSize: "12px" }}>
          {text}
        </Text>
      ),
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      width: "45%",
      render: (text) => (
        <Text strong style={{ fontSize: "12px" }}>
          {text}
        </Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <Text strong style={{ color: "#3e6df3", fontSize: "12px" }}>
          {text}
        </Text>
      ),
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => (
        <Text strong style={{ fontSize: "12px" }}>
          {text}
        </Text>
      ),
    },
  ];

  return (
    <Table
      size="medium"
      pagination={false}
      columns={columns}
      dataSource={data}
      scroll={{ y: 500 }}
    />
  );
};

export default StockOverviewTable;
