


import React, { useState, useEffect } from "react";
import { Button, Typography, Row, Col, Select, Input, Modal, Upload, message, Divider } from "antd";
import { PlusSquareOutlined } from '@ant-design/icons';
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { addAdminSection, getAdminSections, addAdminSectionReorder, getCategoryBrand, deleteAdminSection, deleteBannerSection, deletePopularSection } from "../../../ApiAction/AdminApiAction/adminApi";
import Loading from "../../GeneralComponents/Loading";
import DeleteIcon from "../../../Assets/Images/Icons/Seller/delete.svg";
import DragIcon from "../../../Assets/Images/Icons/Seller/move.svg";
import Carousel from "react-multi-carousel";
import { responsive, bannersresponsive, popularcategoryresponsive } from "../../../Utils/Seller/Data"
import "./manageSection.scss"
import AddNewSections from "./addNewSection";
import UpdateBanner from "./updateBanner";
import { FILE_PATH } from "../../../Utils/constants";
const { Option } = Select;
const { Dragger } = Upload;
const { Title } = Typography;
const confirm = Modal.confirm;

const ManageSections = (props) => {
  const [updateModal, setUpdateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sectionList, setSectionList] = useState([])
  const [categoryListLevel1, setCategoryListLevel1] = useState([])
  const [categoryListLevel2, setCategoryListLevel2] = useState([])
  const [sectionOrder, setSectionOrder] = useState(0)
  const [sectionId, setSectionId] = useState("")
  const [type, setType] = useState("")
  const [typeList, setTypeList] = useState(["Store", "Banner", "Category"])
  const [brandList, setBrandList] = useState(["Store", "Banner", "Category"])
  const [selectedBrandList, setSelectedBrandList] = useState([])
  const [manageBanner, setManageBanner] = useState(["1", "2"]);
  useEffect(() => {

    getSections()

  }, []);

  const getSections = (payload) => {
    getAdminSections()
      .then((response) => {
        // setSectionList(response.data.sections.map(ite))
        const features = response.data.sections.map((item, index) => {

          const singleItem = {
            id: index,
            sectionId: item.id,
            version: item.version,
            source: item.source
          }
          return singleItem;

        })
        setSectionList(features)
        if (response.data.sections.length > 0) {
          setSectionOrder(response.data.sections[response.data.sections.length - 1].source.sectionOrder)
        }

      })
      .catch(() => {

      })
  }

  const addSection = (payload) => {
    setIsLoading(true)
    addAdminSection(payload)
      .then((response) => {
        getSections()
        setIsLoading(false)
      })
      .catch(() => {

      })
  }

  const deleteSection = (id) => {
    confirm({
      title: `Do you want to delete!!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        deleteAdminSection(id)
          .then((response) => {
            getSections()
          })
          .catch(() => {

          })
      },
      onCancel() {
        console.log("Cancel");
      },
    });

  }

  const deleteBanner = (id, section) => {
    const payload = {
      sectionId: section,
      bannerId: id
    }
    confirm({
      title: `Do you want to delete!!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        deleteBannerSection(payload)
        .then((response) => {
          getSections()
        })
        .catch(() => {

        })
      },
      onCancel() {
        console.log("Cancel");
      },
    });


  }
  const deletePopular = (id, section) => {
    const payload = {
      sectionId: section,
      popularCategoryId: id
    }

    confirm({
      title: `Do you want to delete!!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        deletePopularSection(payload)
        .then((response) => {
          getSections()
        })
        .catch(() => {

        })
      },
      onCancel() {
        console.log("Cancel");
      },
    });

  }

  const handleOrderChange = (updatedList) => {
    console.log(updatedList)
    setSectionList(updatedList);
    const features = updatedList.map((item, index) => {

      const singleItem = {
        id: item.sectionId,
        sectionOrder: index
      }
      return singleItem;

    })
    const payload = {
      sections: features
    }
    addAdminSectionReorder(payload)
      .then((response) => {

      })
      .catch(() => {

      })
  };


  return (
    <div className="manage-products-container manage-section__admin">

      <div className="manage-products-header">
        <h1 level={4} className="sub-title-tags"> Sections</h1>
      </div>

      <div className="sessoin_dragable">
        {isLoading && <Loading />}
        <RLDD
          className="example"
          items={sectionList}
          onChange={handleOrderChange}
          itemRenderer={(item) => {
            return (
              item.source.sectionType === "BANNER" || item.source.sectionType === "BIG_OFFER"?
                <div className="section-layout bl-layout">
                  <div className="justify-space-between" style={{marginBottom:"25px"}}>
                  <div className="section-title">{item.source.sectionTitle} </div>
                  <div className="minimum-tags"
                   onClick={() => {

                    deleteSection(item.sectionId)

                  }}>Delete</div>

                  </div>

                  <Carousel responsive={bannersresponsive} className="carousel-container-addbanner">

                    {item.source.banners && (
                      item.source.banners.map((banner, index) => {
                        return (
                          <div className="carousel_popular">
                            <div className="add-product-image">
                              <img
                                src={FILE_PATH + banner.image}
                                alt="loremIpsum" className="image-add-product" />



                              <img
                                src={DeleteIcon}
                                onClick={() => {

                                  deleteBanner(banner.bannerId, item.sectionId)

                                }}
                                className="add-image-delete"
                                alt="loremIpsum"
                              />

                            </div>
                            <Input
                              placeholder="Section Name"
                              className="banner-type-category-input"
                              disabled
                              value={banner.bannerCategoryName}
                            />
                          </div>
                        );
                      })
                    )}

                    <div className="add-product-image">
                      <div
                        onClick={() => {
                          item.source.sectionType === "BIG_OFFER"? setType("BIG OFFER")
                          : setType("BANNER")
                          setSectionId(item.source.sectionId)
                          setUpdateModal(true)

                        }}
                        // src={item.url}
                        alt="loremIpsum" className="image-add-product manage-banner-add" >
                        <PlusSquareOutlined style={{ color: "#f2f2f2", fontSize: "25px" }} />
                      </div>





                    </div>
                  </Carousel>
                </div>
                :
                item.source.sectionType === "POPULAR_CATEGORY" ?
                  <div className="section-layout bl-layout">

                    <div className="justify-space-between" style={{marginBottom:"25px"}}>
                  <div className="section-title">{item.source.sectionTitle} </div>
                  <div className="minimum-tags"
                   onClick={() => {

                    deleteSection(item.sectionId)

                  }}>Delete</div>

                  </div>
                    <Carousel responsive={popularcategoryresponsive} className="carousel-container-addpopular"
                    >

                      {item.source.categoriesInSection && (
                        item.source.categoriesInSection.map((banner, index) => {
                          return (
                            <div class="carousel_popular">

                              <div className="add-product-image">
                                <img
                                  src={FILE_PATH + banner.image}
                                  alt="loremIpsum" className="image-add-product" />



                                <img
                                  src={DeleteIcon}
                                  onClick={() => {

                                    deletePopular(banner.popularCategoryId, item.sectionId)

                                  }}
                                  className="add-image-delete" alt="loremIpsum"
                                />

                              </div>
                              <Input
                                placeholder="Section Name"
                                className="banner-type-category-input"
                                disabled
                                value={banner.popularCategoryName}
                              />
                            </div>
                          );
                        })
                      )}

                      <div className="add-product-image" >
                        <div
                          onClick={() => {
                            setType("POPULAR CATEGORY")
                            setSectionId(item.source.sectionId)
                            setUpdateModal(true)
                          }}
                          // src={item.url}
                          alt="loremIpsum" className="image-add-product manage-banner-add" >
                          <PlusSquareOutlined style={{ color: "#f2f2f2", fontSize: "25px" }} />
                        </div>





                      </div>
                    </Carousel>
                  </div>
                  :
                  (item.source.sectionType === "CATEGORY" || item.source.sectionType === "STORE") ?
                    <div className="section-layout category_drag_layout">
                      <img
                        src={DragIcon} className="move_drag" alt="loremIpsum"/>
                      <img
                        src={DeleteIcon}
                        onClick={() => {

                          deleteSection(item.sectionId)

                        }} alt="loremIpsum"
                        className="delete_opt"
                        style={{ width: "15px", cursor: "pointer" }}
                      />
                      <Row>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
                          <div className="add-product-tile">Section Name
              <Input
                              placeholder="Section Name"
                              //   className="tracking-input"
                              disabled
                              value={item.source.sectionTitle}
                            />
                          </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
                          <div className="add-product-tile">Section Type
                  <Input
                              placeholder="Section Name"
                              //   className="tracking-input"
                              disabled
                              value={item.source.sectionType}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
                          <div className="add-product-tile first-letter-capital" >{item.source.sectionType}
                            <Input
                              placeholder="Section Name"
                              //   className="tracking-input"
                              style={{ color: "#3e6df3" }}
                              disabled
                              value={item.source.sectionTypeName}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    :
                    <div>
                    </div>
            );
          }}
        // onChange={handleOrderChange}

        />
        {/* {sectionList.map(item =>

        )} */}
      </div>
      <Divider />
      <div style={{ marginTop: "35px" }}>
        <AddNewSections
          sectionOrder={sectionOrder + 1}
          addSection={addSection} />
      </div>

      <UpdateBanner visible={updateModal} setCancel={setUpdateModal}
        updateSection={addSection}
        sectionId={sectionId}
        type={type} />


    </div>
  );
};

export default ManageSections;
