


import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { message, Pagination, Modal } from "antd";
import ReportsTable from "../ReportsTable";
import { columns, salesColumnWithoutSort } from "../Utils/reportHelper";
import { fetchSort } from "../../../../ReduxStore/action";
import {
  fetchSalesReport,
  exportSalesReport,
  exportExpiryReport,
} from "../../../../ApiAction/AdminApiAction/reportsApi";
import { formatSalesReport } from "../Utils/reportHelper";
const SalesReport = (props) => {
  const cookies = new Cookies();
  const warning = Modal.warning;
  const { vendorId, reportSummary, isStore } = props;
  const [dataSource, setdataSource] = useState([]);
  const [isLoadingSalesReport, setIsLoadingSalesReport] = useState(false);
  const [totalSize, settotalSize] = useState();
  const [columnData, setcolumnData] = useState();
  const [pageNo, setpageNo] = useState(1);

  const handleShowWarning = (msg) => {
    warning({
      title: msg,
      className: "create-confirm-modal",
    });
  };

  //handling date range part starts here

  const handleValidateDateRange = (startDate, endDate) => {
    console.log("startDate: ", startDate, "endDate: ", endDate);

    var date1 = new Date(startDate);
    var date2 = new Date(endDate);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 186) {
      handleShowWarning(
        `Please choose a date range of lesser than or equal to ${
          isStore ? "Six" : "One"
        } month.`
      );
      return false;
    } else {
      return true;
    }
    console.log(Difference_In_Days, ":difference in days");
  };

  const handleExportSalesReport = async () => {
    const payload = {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
      // sortType: props.sort ? props.sort.sortBy : "desc",
      key: props.searchVal,
      vendorId: vendorId ? vendorId : props.vendorFilter,
      // brandId: props.brandId ? props.brandId : "",
    };
    if (props?.sort?.sortType) {
      payload["sortBy"] = props.sort.sortType;
    }

    if (props?.sort?.sortBy) {
      payload["sortType"] = props.sort.sortBy;
    }

    if (props?.sort?.groupBy) {
      payload["type"] = props?.sort?.groupBy;
    }
    if (props?.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endTime"] = props.dateRange.endtime;
    }

    if (props?.dateRange) {
      if (
        handleValidateDateRange(
          props.dateRange.startTime,
          props.dateRange.endtime
        )
      ) {
        const salesReport = await exportSalesReport(payload)
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.status === "failed") {
                handleShowWarning(
                  "Couldn't download sales report. Please try again later"
                );
              } else {
                console.log(response, "response for voucher code excel sheet");
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `sales_report.xlsx`);
                document.body.appendChild(link);
                link.click();
              }
            } else {
              handleShowWarning(
                "Couldn't download sales report. Please try again later"
              );
            }
          })
          .catch((error) => {
            message.error("Could not export file please try again");
          })
          .finally(() => {});
        return true;
      } else {
        return true;
      }
    } else {
      handleShowWarning(
        `Please Choose a date range maximum of ${
          isStore ? "Six months" : "One month"
        }  to download sales report.`
      );
      return true;
    }
  };

  const handleUpdateColumns = () => {
    const expectForInvoice = [
      "batchNumber",
      "productName",
      "brandName",
      "productVariant",
      "vendorName",
      "minDiscountPerCent",
    ];
    const expectForOtherGroup = [
      "orderGroupId",
      "customerCode",
      "referralId",
      "customerName",
      "orderedDate",
    ];

    const isWarehouseExpect = ["customerId", "referralId"];
    const groupBy = props.sort.groupBy ? props.sort.groupBy : "";

    console.log(groupBy, ":groupBy");
    if (groupBy) {
      switch (groupBy) {
        case "orderGroupId":
          setcolumnData(
            salesColumnWithoutSort.filter((data) => {
              // if (cookies.get("accountType") === "warehouse") {
              //   return (
              //     !expectForInvoice.includes(data.dataIndex) &&
              //     !isWarehouseExpect.includes(data.dataIndex)
              //   );
              // } else

              return !expectForInvoice.includes(data.dataIndex);
            })
          );
          break;
        case "productName":
          setcolumnData(
            salesColumnWithoutSort.filter((data) => {
              return ![...expectForOtherGroup, "productVariant"].includes(
                data.dataIndex
              );
            })
          );
          break;

        case "batchNumber":
          setcolumnData(
            salesColumnWithoutSort.filter((data) => {
              return ![...expectForOtherGroup, "productVariant"].includes(
                data.dataIndex
              );
            })
          );
          break;
        case "productNameVariant":
          setcolumnData(
            salesColumnWithoutSort.filter((data) => {
              return ![...expectForOtherGroup, "batchNumber"].includes(
                data.dataIndex
              );
            })
          );
          break;
        default:
          setcolumnData(
            salesColumnWithoutSort.filter((data) => {
              return !expectForOtherGroup.includes(data.dataIndex);
            })
          );
      }
    } else {
      setcolumnData(
        columns.filter((data) => {
          if (cookies.get("accountType") === "warehouse") {
            return !isWarehouseExpect.includes(data.dataIndex);
          } else return data;
        })
      );
    }
  };

  const handleOnPageChange = (page, pageSize) => {
    console.log(page, pageSize);
    setpageNo(page);

    window.scrollTo(0, 0);
  };

  const handleFetchSalesReport = (pageNo) => {
    const payload = {
      offset: (pageNo - 1) * 10,
      size: 10,
      accountType: cookies.get("accountType"),
      // userId: cookies.get("userId"),
      // sortType: "desc",
      sortBy: props?.sort?.sortType ? props?.sort?.sortType : "orderedDate",
      sortType: props?.sort?.sortBy ? props?.sort?.sortBy : "desc",
      key: props.searchVal,

      vendorId: vendorId ? vendorId : props.vendorFilter,
      // brandId: props.brandId ? props.brandId : "",
    };

    if (props?.sort?.groupBy) {
      payload["type"] = props?.sort?.groupBy;
    }

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endTime"] = props.dateRange.endtime;
    }
    setIsLoadingSalesReport(true);
    handleUpdateColumns();
    fetchSalesReport(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log("reponse for sales report: ", response.data.documents);
            settotalSize(response.data.count);
            setdataSource(
              formatSalesReport(
                response.data.documents,
                response?.data?.count ? (pageNo - 1) * 10 : 0
              )
            );
          }
        }
      })
      .finally(() => {
        setIsLoadingSalesReport(false);
      });
  };
  useEffect(() => {
    console.log("sort:", props.sort);
    handleFetchSalesReport(pageNo);
  }, [
    props.sort,
    props.brandId,
    props.dateRange,
    props.searchVal,
    props.vendorFilter,
    pageNo,
  ]);

  useEffect(() => {
    const payload = {
      groupBy: "",
      sortType: "",
      sortBy: "",
    };
    props.fetchSort(payload);
  }, []);
  return (
    <Fragment>
      <ReportsTable
        download={handleExportSalesReport}
        columns={columnData}
        dataSource={dataSource}
        type={"Sales"}
        reportSummary={reportSummary}
        summary={
          totalSize
            ? ""
            : "The latest 10 sales are listed below. To get the complete sales report, download csv"
        }
        isLoadingReport={isLoadingSalesReport}
      />
      {totalSize > 10 && (
        <Pagination
          className="customer-pagination"
          current={pageNo}
          total={totalSize > 10000 ? 10000 : totalSize}
          showSizeChanger={false}
          onChange={handleOnPageChange}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
    brandId: state.reportReducer.brandId,
    dateRange: state.reportReducer.dateRange,
    vendorFilter: state.reportReducer.vendorId,
  };
};
const mapDispatchToProps = {
  fetchSort,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesReport);
