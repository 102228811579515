


import React, { useEffect, useState } from "react";
import {
  Modal,
  Typography,
  Divider,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  message,
} from "antd";
import moment from "moment";
import { getSellerProfile } from "../../../ApiAction/SellerApiActions/sellerApi";
import { generateEwayBill } from "../../../ApiAction/SellerApiActions/vendoOrderAPI";
import Loading from "../../GeneralComponents/Loading";

const { Text } = Typography;
const { Option } = Select;

const GenerateEwayBill = (props) => {
  const [form] = Form.useForm();
  const { visible, setvisible, orderDetails } = props;
  const [isLoadingEwayBill, setIsLoadingEwayBill] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  const supplyTypeOptions = [
    { key: "O", value: "Outward" },
    { key: "I", value: "Inward" },
  ];

  const subSupplyTypeOptions = [
    { key: "1", value: "Supply" },
    { key: "2", value: "Import" },
    { key: "3", value: "Export" },
    { key: "4", value: "Job Work" },
    { key: "5", value: "For Own Use" },
    { key: "6", value: "Job work Returns" },
    { key: "7", value: "Sales Return" },
    { key: "8", value: "Others" },
    { key: "9", value: "SKD/CKD" },
    { key: "10", value: "Line Sales" },
    { key: "11", value: "Recipient  Not Known" },
    { key: "12", value: "Exhibition or Fairs" },
  ];

  const transModeOptions = [
    { key: "1", value: "Road" },
    { key: "2", value: "Rail" },
    { key: "3", value: "Air" },
    { key: "4", value: "Ship" },
  ];

  const stateCodeOptions = [
    { key: "1", value: "JAMMU AND KASHMIR" },
    { key: "2", value: "HIMACHAL PRADESH" },
    { key: "3", value: "PUNJAB" },
    { key: "4", value: "CHANDIGARH" },
    { key: "5", value: "UTTARAKHAND" },
    { key: "6", value: "HARYANA" },
    { key: "7", value: "DELHI" },
    { key: "8", value: "RAJASTHAN" },
    { key: "9", value: "UTTAR PRADESH" },
    { key: "10", value: "BIHAR" },
    { key: "11", value: "SIKKIM" },
    { key: "12", value: "ARUNACHAL PRADESH" },
    { key: "13", value: "NAGALAND" },
    { key: "14", value: "MANIPUR" },
    { key: "15", value: "MIZORAM" },
    { key: "16", value: "TRIPURA" },
    { key: "17", value: "MEGHALAYA" },
    { key: "18", value: "ASSAM" },
    { key: "19", value: "WEST BENGAL" },
    { key: "20", value: "JHARKHAND" },
    { key: "21", value: "ORISSA" },
    { key: "22", value: "CHHATTISGARH" },
    { key: "23", value: "MADHYA PRADESH" },
    { key: "24", value: "GUJARAT" },
    { key: "25", value: "DAMAN AND DIU" },
    { key: "26", value: "DADAR AND NAGAR HAVELI" },
    { key: "27", value: "MAHARASTRA" },
    { key: "28", value: "ANDHRA PRADESH" },
    { key: "29", value: "KARNATAKA" },
    { key: "30", value: "GOA" },
    { key: "31", value: "LAKSHADWEEP" },
    { key: "32", value: "KERALA" },
    { key: "33", value: "TAMIL NADU" },
    { key: "34", value: "PONDICHERRY" },
    { key: "35", value: "ANDAMAN AND NICOBAR" },
    { key: "36", value: "TELANGANA" },
    { key: "97", value: "OTHER TERRITORY" },
    { key: "99", value: "OTHER COUNTRY" },
  ];

  const docTypeOptions = [
    { key: "INV", value: "Tax Invoice" },
    { key: "BIL", value: "Bill of Supply" },
    { key: "BOE", value: "Bill of Entry" },
    { key: "CHL", value: "Delivery Challan" },
    { key: "CNT", value: "Credit Note" },
    { key: "OTH", value: "Others" },
  ];
  const transactionTypeOptions = [{ key: "1", value: "Regular" }];

  const vehicleTypeOptions = [
    { key: "R", value: "Regular" },
    { key: "O", value: "Over Dimenstional Cargo" },
  ];

  const gstInValidator = (rule, value, callback) => {
    if (
      value !== undefined &&
      value.length > 0 &&
      !/^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1})$/.test(value)
    ) {
      callback("Plese enter valid GSTIN");
    } else {
      callback();
    }
  };

  const supplierFormFields = [
    //from

    {
      label: "GSTIN* ",
      placeHolder: "Enter GSTIN*",
      name: "fromGstin",
      type: "input",
      validator: gstInValidator,
    },
    {
      label: "From warehouse*",
      placeHolder: "Enter Warehouse Name*",
      name: "fromTrdName",
      type: "input",
    },
    {
      label: "warehouse address line1*",
      placeHolder: "Enter Warehouse address* ",
      name: "fromAddr1",
      type: "input",
    },
    {
      label: "warehouse address line2*",
      placeHolder: "Enter Warehouse address* ",
      name: "fromAddr2",
      type: "input",
    },
    {
      label: "Place*",
      placeHolder: "Enter Place*",
      name: "fromPlace",
      type: "input",
    },
    {
      label: "Pincode*",
      placeHolder: "Enter Pincode*",
      name: "fromPincode",
      type: "input",
    },
    {
      label: " State*",
      placeHolder: "Enter State*",
      name: "fromStateCode",
      type: "select",
      options: stateCodeOptions,
    },
  ];

  const reciepientFormFields = [
    //to

    {
      label: "GSTIN of reciepient*",
      placeHolder: "Enter GSTIN*",
      name: "toGstin",
      type: "input",
      validator: gstInValidator,
    },
    {
      label: "Recipient Name*",
      placeHolder: "Enter Warehouse Name*",
      name: "toTrdName",
      type: "input",
    },
    {
      label: "Recipient address line1*",
      placeHolder: "Enter Warehouse address* ",
      name: "toAddr1",
      type: "input",
    },
    {
      label: "Recipient address line2*",
      placeHolder: "Enter Warehouse address* ",
      name: "toAddr2",
      type: "input",
    },
    {
      label: "Recipient Place*",
      placeHolder: "Enter Place*",
      name: "toPlace",
      type: "input",
    },
    {
      label: "Recipient Pincode*",
      placeHolder: "Enter Pincode*",
      name: "toPincode",
      type: "input",
    },
    {
      label: "Recipient State*",
      placeHolder: "Enter Pincode*",
      name: "toStateCode",

      type: "select",
      options: stateCodeOptions,
    },
  ];

  const documentFormFields = [
    //

    {
      label: "Document no*",
      placeHolder: "Enter Document no*",
      name: "docNo",
      type: "input",
    },
    {
      label: "Document Type*",
      placeHolder: "Document Type*",
      name: "docType",
      type: "select",
      options: docTypeOptions,
    },
    {
      label: "Document Date*",
      placeHolder: "Document Date*",
      name: "docDate",
      type: "date",
    },
    {
      label: "Supply type*",
      placeHolder: "Supply Type*",
      name: "supplyType",
      type: "select",
      options: supplyTypeOptions,
    },
    {
      label: "Sub Type*",
      placeHolder: "Sub Type*",
      name: "subSupplyType",
      type: "select",
      options: subSupplyTypeOptions,
    },
    {
      label: "Transaction Type*",
      placeHolder: "Transaction Type*",
      name: "transactionType",
      type: "select",
      options: transactionTypeOptions,
    },
  ];
  const transportationFormFields = [
    {
      label: "Vehicle no*",
      placeHolder: "Enter Vehicle no*",
      name: "vehicleNo",
      type: "input",
    },
    {
      label: "Vehicle Type*",
      placeHolder: "Vehicle Type*",
      name: "vehicleType",
      type: "select",
      options: vehicleTypeOptions,
    },
    {
      label: "Transaction Mode*",
      placeHolder: "Transaction Mode*",
      name: "transMode",
      type: "select",
      options: transModeOptions,
    },

    {
      label: "Distance in KM*",
      placeHolder: "Enter Distance in KM*",
      name: "transDistance",
      type: "input",
    },
  ];

  const handleDateChange = (date, dateString) => {};

  const renderField = (data) => {
    switch (data.type) {
      case "input":
        return <Input placeholder={data.placeHolder} />;
      case "select":
        return (
          <Select
            allowClear={true}
            size="middle"
            showSearch
            onSelect={(value, option) => {}}
            style={{ width: "100%" }}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {data.options &&
              data.options.map((item, index) => {
                return (
                  <Option key={item.key} value={item.key}>
                    {item.value}
                  </Option>
                );
              })}
          </Select>
        );
      case "date":
        return (
          <DatePicker
            onChange={handleDateChange}
            className="sort-date-order eway-bill-date"
          />
        );

      default:
        console.log("invalid choice");
    }
  };

  const handleInitialiseFields = () => {
    getSellerProfile().then((response) => {
      if (response.status === 200) {
        console.log(
          response.data.profile.source,
          ": response for seller profile data"
        );
        const profileData = response.data.profile.source;

        form.setFieldsValue({
          fromGstin: profileData?.tradeDetails?.gstNumber,
          fromTrdName: profileData?.name,
          fromAddr1: profileData?.address?.addressLine1,
          fromAddr2: profileData?.address?.addressLine2,
          fromPlace: profileData?.address?.district,
          fromPincode: profileData?.address?.pinCode,

          toTrdName: orderDetails?.shippingAddress?.ownerName,
          toAddr1: orderDetails?.shippingAddress?.addressLine1,
          toAddr2: orderDetails?.shippingAddress?.addressLine2,
          toPlace: orderDetails?.shippingAddress?.district,
          toPincode: orderDetails?.shippingAddress?.pinCode,

          toGstin: orderDetails?.gstNumber,

          docNo: orderDetails?.orderGroupId,
        });
      }
    });
  };

  const handleGenerateEwayBill = (values) => {
    const payload = {
      ip: "54.224.115.38",
      orderGroupId: orderDetails.orderGroupId,
      eWayBill: {
        ...values,
        fromPincode: Number(values.fromPincode),
        toPincode: Number(values.toPincode),
        fromStateCode: Number(values.fromStateCode),
        toStateCode: Number(values.toStateCode),
        transactionType: Number(values.transactionType),
        docDate: moment(values.docDate).format("DD/MM/YYYY"),
      },
    };
    console.log("values from form", payload);
    setIsLoadingEwayBill(true);
    seterrorMessage();
    generateEwayBill(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log(response, ":response of generate ewaybill");
            message.success(response.data.message);
            props.getProductsList();
            form.resetFields();
            setvisible(false);
          } else if (response.data.status === "failed") {
            message.warning(response.data.message);
            seterrorMessage(response.data.message);
          }
        } else {
          message.error("Sorry! Something went wrong. Please try again later!");
        }
      })
      .finally(() => {
        setIsLoadingEwayBill(false);
      });
  };

  const handleCloseModal = () => {
    setvisible(false);
    form.resetFields();
  };

  useEffect(() => {
    console.log(orderDetails, ":order details in generate ewaybill modal");
    visible && handleInitialiseFields();
  }, [orderDetails, visible]);
  return (
    <div>
      <Modal
        title="Generate Eway-Bill"
        visible={visible}
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
        footer={null}
        width={1000}
        className="ord_dlt_pop_ext"
      >
        <Form form={form} onFinish={handleGenerateEwayBill}>
          <h4>Bill From</h4>
          <hr />
          <Row>
            {supplierFormFields.map((data) => {
              return (
                <Col span={8} className="add-image-col">
                  <Text type="secondary" style={{ fontSize: "12px" }}>
                    {data.label}
                  </Text>
                  <Form.Item
                    name={data.name}
                    rules={[
                      {
                        required: true,
                        message: "This field is required !",
                      },
                      { validator: data.validator ? data.validator : "" },
                    ]}
                  >
                    {renderField(data)}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>

          <h4>Bill To</h4>
          <hr />
          <Row>
            {reciepientFormFields.map((data) => {
              return (
                <Col span={8} className="add-image-col">
                  <Text type="secondary" style={{ fontSize: "12px" }}>
                    {data.label}
                  </Text>
                  <Form.Item
                    name={data.name}
                    rules={[
                      {
                        required: true,
                        message: "This field is required !",
                      },
                      { validator: data.validator ? data.validator : "" },
                    ]}
                  >
                    {renderField(data)}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>

          <h4>Transaction Details</h4>
          <hr />
          <Row>
            {documentFormFields.map((data) => {
              return (
                <Col span={8} className="add-image-col">
                  <Text type="secondary" style={{ fontSize: "12px" }}>
                    {data.label}
                  </Text>
                  <Form.Item
                    name={data.name}
                    rules={[
                      {
                        required: true,
                        message: "This field is required !",
                      },
                    ]}
                  >
                    {renderField(data)}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          <h4>Transportation Details</h4>
          <hr />
          <Row>
            {transportationFormFields.map((data) => {
              return (
                <Col span={8} className="add-image-col">
                  <Text type="secondary" style={{ fontSize: "12px" }}>
                    {data.label}
                  </Text>
                  <Form.Item
                    name={data.name}
                    rules={[
                      {
                        required: true,
                        message: "This field is required !",
                      },
                    ]}
                  >
                    {renderField(data)}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          {errorMessage && (
            <Text type="danger" strong>
              {errorMessage}
            </Text>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => handleCloseModal()}> Cancel</Button>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ marginLeft: "20px", width: "150px" }}
              >
                {isLoadingEwayBill ? (
                  <Loading style={{ color: "white" }} />
                ) : (
                  "Generate EwayBill"
                )}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default GenerateEwayBill;
