import { Tabs, Divider } from 'antd';
import React, { useState } from 'react'
import { SiGoogleads } from "react-icons/si";
import ActiveAds from './ActiveAds';
import './manageAds.scss'
import NewAds from './NewAds';

const AdIndex = () => {
    const { TabPane } = Tabs;
    const [ activeTab, setActiveTab ] = useState(2)
    const [ reloadData, setReloadData ] = useState(false)

    const renderTabName = (name) => {
        return (
            <p className="tab-name">{name}</p>
        )
    }

    const handleTabChange = (activeTab) => {
        console.log("Acitve Tab :: ", activeTab)
        setActiveTab(activeTab)
    }

    return (
        <div className="manage-accounts">
            <div className="manage-top-part">
                <h2 style={{ fontWeight: "bold" }}><SiGoogleads /> Manage Advertisement</h2>
                <h4 style={{ color: "rgba(0,0,0,0.5)" }}>Manage Advertisement can handle all the ads that shown in site.</h4>
                {/* This Popover will display information about this Service */}
            </div>
            <Divider />
            <Tabs className="manage-ads-tabs" defaultActiveKey={2} onChange={handleTabChange}>                
                <TabPane tab={renderTabName("Publish Ads")} key="1">
                    <NewAds activeTab={activeTab} TabKey="1" setActiveTab={setActiveTab} />
                </TabPane>
                <TabPane tab={renderTabName("Active Ads")} key="2">
                    <ActiveAds activeTab={activeTab} TabKey="2" reloadData={reloadData} setReloadData={setReloadData} />
                </TabPane>                                
            </Tabs>
        </div>
    )
}

export default AdIndex
