

import React, { useEffect, useState } from "react";
import "./MobileMegaMenu.css";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from "antd";
import { CaretRightOutlined, LeftOutlined } from "@ant-design/icons";

import CompanyLogo from "../../Assets/Images/Logo/DL_Logo.png";
import { getMegamenu } from "../../ApiAction/CustomerApiActions/homepageAPI";

import {
  addCategoryfilter,
  addSearchFilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
} from "../../ReduxStore/action/productActions";
import { ENV_TYPE } from "../../Utils/constants";
const { Text } = Typography;

var CryptoJS = require("crypto-js");

function MobileMegamenu(props) {
  const { visible, data, setmobileMenuVisible } = props;

  const [selectedlevel0, setselectedlevel0] = useState();
  const [selectedlevel1, setselectedlevel1] = useState();
  const [selectedlevel2, setselectedlevel2] = useState();
  const [selectedlevel3, setselectedlevel3] = useState();

  const [heading, setheading] = useState({
    level0: "",
    level1: "",
    level2: "",
    level3: "",
  });

  //to add selected categoryName to bread crumb
  const handleManageBreadCrumb = (data) => {
    let breadCrumbList = [];
    let breadcrumb = {
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${data.categoryName}`
          : `/customer/product-listing/${data.categoryName}`,
      type: "catogery",
    };
    breadcrumb["label"] = data.categoryName;
    breadcrumb["id"] = data.id;

    breadCrumbList.push(breadcrumb);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  //this part of code redirects to equalent catogeries/subcatogeries productListing page
  const handleRedirection = (e, category, level) => {
    let encryptedCategoryId = CryptoJS.AES.encrypt(
      JSON.stringify(category?.id),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();
    // e.stopPropogation();
    handleManageBreadCrumb(category);
    console.log("New level", level);
    switch (level) {
      case -1:
        //props.addCategoryfilter(category.id);
        setmobileMenuVisible(false);
        props.history.push(
          ENV_TYPE === "production"
            ? `/fastmoving-listing/${encryptedCategoryId}`
            : `/customer/fastmoving-listing/${encryptedCategoryId}`
        );
        return;
      case 0:
        props.addSubCategory00filter(category.id);
        break;
      case 1:
        props.addSubCategory01filter(category.id);
        break;
      case 2:
        props.addSubCategory02filter(category.id);
        break;
      case 3:
        props.addSubCategory03filter(category.id);
        break;
      default:
        console.log("invalid category level");
    }

    props.history.push(
      ENV_TYPE === "production"
        ? `/product-listing/${category.categoryName}`
        : `/customer/product-listing/${category.categoryName}`
    );
    setmobileMenuVisible(false);
  };

  const getHeader = () => {
    if (heading.level3 && heading.level3 !== "") {
      return heading.level3;
    } else if (heading.level2 && heading.level2 !== "") {
      return heading.level2;
    } else if (heading.level1 && heading.level1 !== "") {
      return heading.level1;
    } else if (heading.level0 && heading.level0 !== "") {
      return heading.level0;
    } else return null;
  };

  const handleNavigateToBack = () => {
    if (selectedlevel3) {
      setheading({ ...heading, level3: "" });
      setselectedlevel3();
    } else if (selectedlevel2) {
      setheading({ ...heading, level2: "" });
      setselectedlevel2();
    } else if (selectedlevel1) {
      setheading({ ...heading, level1: "" });
      setselectedlevel1();
    } else if (selectedlevel0) {
      setheading({ ...heading, level0: "" });
      setselectedlevel0();
    }
  };

  useEffect(() => {
    // console.log("hi i am here", props);
    // console.log("heading updated", heading);
  }, [heading]);

  return (
    <div
      className={
        visible
          ? "mobile-megamenu-modal-container mobile__menu_wrapper  " //case when the menu is triggered
          : "mobile-megamenu-modal-container  " // case when the menu is closed
      }
    >
      {getHeader() ? (
        <div
          className="megamenu-back"
          style={{
            display: "flex",
            height: "64px",
            alignSelf: "center",
            alignItems: "center",
            marginLeft: "45px",
          }}
          onClick={() => handleNavigateToBack()}
        >
          <LeftOutlined
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              fontSize: "20px",
            }}
          />
          <Text className="cat_back_name" strong>
            {getHeader()}
          </Text>
        </div>
      ) : (
        <img
          onClick={() => setmobileMenuVisible(false)}
          className="mobile-menu-header-company-logo"
          src={CompanyLogo}
          alt={"company logo"}
        />
      )}

      {/* level1 catogery// */}

      {!selectedlevel0 && (
        <div className="mobile-menu-catogery-container">
          {data &&
            data.map((catogery, index) => {
              return (
                <div key={index}>
                  <div
                    className="mobile-menu-catogeries-each"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      onClick={(e) => {
                        handleRedirection(e, catogery, -1);
                      }}
                    >
                      {catogery.categoryName}
                    </Text>
                    <CaretRightOutlined
                      onClick={() => {
                        //handleTriggerNextLevel
                        setselectedlevel0(index + 1);
                        setheading({
                          ...heading,
                          level0: catogery.categoryName,
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {selectedlevel0 && !selectedlevel1 && (
        <div className="mobile-menu-catogery-container">
          {data &&
            data.map((catogery, index) => {
              if (index === selectedlevel0 - 1) {
                return catogery.subCategories?.length
                  ? catogery.subCategories.map((data1, index1) => {
                      return (
                        <>
                          <div
                            className="mobile-menu-catogeries-each"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text
                              onClick={(e) => {
                                handleRedirection(e, data1, 0);
                              }}
                            >
                              {data1.categoryName}
                            </Text>
                            <CaretRightOutlined
                              onClick={() => {
                                //handleTriggerNextLevel
                                setselectedlevel1(index1 + 1);
                                setheading({
                                  ...heading,
                                  level1: data1.categoryName,
                                });
                              }}
                            />
                          </div>
                        </>
                      );
                    })
                  : "";
              }
            })}
        </div>
      )}

      {/* //level2 catogery// */}

      {selectedlevel0 && selectedlevel1 && !selectedlevel2 && (
        <div className="mobile-menu-catogery-container">
          {data &&
            data.map((catogery, index) => {
              if (index === selectedlevel0 - 1) {
                return catogery.subCategories?.length
                  ? catogery.subCategories.map((data1, index1) => {
                      if (index1 === selectedlevel1 - 1) {
                        return data1.subCategories?.length
                          ? data1.subCategories.map((data2, index2) => {
                              return (
                                <>
                                  <div
                                    className="mobile-menu-catogeries-each"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Text
                                      onClick={(e) => {
                                        handleRedirection(e, data2, 1);
                                      }}
                                    >
                                      {data2.categoryName}
                                    </Text>
                                    <CaretRightOutlined
                                      onClick={() => {
                                        //handleTriggerNextLevel
                                        setselectedlevel2(index2 + 1);
                                        setheading({
                                          ...heading,
                                          level2: data2.categoryName,
                                        });
                                      }}
                                    />
                                  </div>
                                </>
                              );
                            })
                          : "";
                      }
                    })
                  : "";
              }
            })}
        </div>
      )}

      {/* //level3 catogery// */}

      {selectedlevel0 && selectedlevel1 && selectedlevel2 && !selectedlevel3 && (
        <div className="mobile-menu-catogery-container">
          {data &&
            data.map((catogery, index) => {
              if (index === selectedlevel0 - 1) {
                return catogery.subCategories?.length
                  ? catogery.subCategories.map((data1, index1) => {
                      if (index1 === selectedlevel1 - 1) {
                        return data1.subCategories?.length
                          ? data1.subCategories.map((data2, index2) => {
                              if (index2 === selectedlevel2 - 1) {
                                return data2.subCategories?.length
                                  ? data2.subCategories.map((data3, index3) => {
                                      return (
                                        <>
                                          <div
                                            className="mobile-menu-catogeries-each"
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Text
                                              onClick={(e) => {
                                                handleRedirection(e, data3, 2);
                                                setselectedlevel3(index3 + 1);
                                                setheading({
                                                  ...heading,
                                                  level3: data3.categoryName,
                                                });
                                              }}
                                            >
                                              {data3.categoryName}
                                            </Text>
                                            <CaretRightOutlined
                                              onClick={() => {
                                                //handleTriggerNextLevel
                                                setselectedlevel2(index2 + 1);
                                                setheading({
                                                  ...heading,
                                                  level2: data2.categoryName,
                                                });
                                              }}
                                            />
                                          </div>
                                        </>
                                      );
                                    })
                                  : "";
                              }
                            })
                          : "";
                      }
                    })
                  : "";
              }
            })}
        </div>
      )}

      {/* level4 catogery// */}

      {selectedlevel0 && selectedlevel1 && selectedlevel2 && selectedlevel3 && (
        <div className="mobile-menu-catogery-container">
          {data &&
            data.map((catogery, index) => {
              if (index === selectedlevel0 - 1) {
                return catogery.subCategories?.length
                  ? catogery.subCategories.map((data1, index1) => {
                      if (index1 === selectedlevel1 - 1) {
                        return data1.subCategories?.length
                          ? data1.subCategories.map((data2, index2) => {
                              if (index2 === selectedlevel2 - 1) {
                                return data2.subCategories?.length
                                  ? data2.subCategories.map((data3, index3) => {
                                      if (index3 === selectedlevel3 - 1) {
                                        return data3.subCategories?.length
                                          ? data3.subCategories.map(
                                              (data4, index4) => {
                                                return (
                                                  <>
                                                    <div
                                                      className="mobile-menu-catogeries-each"
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      <Text
                                                        onClick={(e) => {
                                                          handleRedirection(
                                                            e,
                                                            data4,
                                                            3
                                                          );
                                                        }}
                                                      >
                                                        {data4.categoryName}
                                                      </Text>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )
                                          : "";
                                      }
                                    })
                                  : "";
                              }
                            })
                          : "";
                      }
                    })
                  : "";
              }
            })}
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = {
  addCategoryfilter,
  addSearchFilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
};

export default withRouter(connect(null, mapDispatchToProps)(MobileMegamenu));
