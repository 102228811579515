


import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { fetchCentralRepo } from "../../../../ApiAction/AdminApiAction/centralRepoApi";
import Cookies from 'universal-cookie'

const { Option } = Select;

const ProductAuctoComplete = (props) => {
  const { initialiseProductDetails, onClear } = props;
  const [searchKey, setsearchKey] = useState("");
  const [productList, setproductList] = useState([]);
  const [suggestionList, setsuggestionList] = useState([]);
  const cookies = new Cookies()

  const handlOnClear = () => {
    // onClear();
    console.log("cleared suggestion");
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);

    if (value) {
      initialiseProductDetails(value, true);
    } else onClear();
  };

  const handleReturnProductDetails = (productId) => {
    const productDetails = productList.filter((product) => {
      return product.source.productId === productId;
    })[0];

    console.log(productDetails, ":selected product details");
  };

  const onBlur = () => {
    console.log("blur");
  };

  const onFocus = () => {
    console.log("focus");
  };

  const onSearch = (val) => {
    console.log("search:", val);
    setsearchKey(val);
  };

  const formatSuggestionList = (list) => {
    let tempList = [];
    list.map((product) => {
      let temp = {
        productId: product.source.productId,
        productName: product.source.name,
      };
      tempList.push(temp);
    });
    return tempList;
    console.log(tempList, "this is the formated suggestion list for products");
  };

  const handleFetchcentralRepo = () => {
    const payload = {
      offset: 0,
      size: 100,
      key: searchKey ? searchKey : "",
      sortBy: "addedDate",
      sortType: "desc",
      isDraft: false,
      archive: false,
      accountType: cookies.get("accountType") && cookies.get("accountType") === "seller" ? "seller" : "",
    };
    fetchCentralRepo(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setproductList(response.data.products);
            setsuggestionList(formatSuggestionList(response.data.products));
            console.log(response.data, "response for central list fetch");
          }
          // else setproductList([]);
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    handleFetchcentralRepo();
  }, [searchKey]);

  return (
    <Select
      showSearch
      allowClear={true}
      showArrow={false}
      style={{ width: 200 }}
      placeholder="Search for product"
      optionFilterProp="children"
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      onClear={handlOnClear}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {suggestionList?.length > 0 &&
        suggestionList.map((product) => {
          return (
            <Option value={product.productId}>{product.productName}</Option>
          );
        })}
    </Select>
  );
};

export default ProductAuctoComplete;
