


import React, { useEffect, useState } from "react";
import { Row, Col, Checkbox, Button, Select, Modal, message, Form } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { CloseCircleOutlined } from "@ant-design/icons";

import "./linkWarehouse.css";
import { fetchUsers } from "../../../ApiAction/AdminApiAction/adminApi";
import {
  linkWarehouseToStore,
  linkWarehouseToWarehouse,
} from "../../../ApiAction/AdminApiAction/linkWarehouseAoi";

import {
  fetchPincodeList,
  fetchDistrictList,
} from "../../../ApiAction/GeneralApiActions/pinCodeAPI";

import {
  districtOptionsFormater,
  pinCodeOptionsFormater,
} from "../../../Helpers/dataFormatter";

import Loading from "../../GeneralComponents/Loading";
import { stateOptions } from "../../../Utils/constData";

const { Option } = Select;

const LinkWarehouseModal = (props) => {
  const [form] = Form.useForm();
  const { userDetails, isWarehouse } = props;

  const [stateList, setStateList] = useState(stateOptions);
  const [districtList, setDistrictList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);

  const datastate = [{ key: "Kerela", value: "Kerela" }];
  const datadistrict = [
    { key: "Kottayam", value: "Kottayam" },
    { key: "Ernakulam", value: "Ernakulam" },
    { key: "Kozhikode", value: "Kozhikode" },
  ];
  const datapincode = [
    { key: "686101", value: "686101" },
    { key: "686102", value: "686102" },
    { key: "686103", value: "686103" },
    { key: "686104", value: "686104" },
  ];

  const [tagList, setTagList] = useState([]);

  const [collapsed, setCollapsed] = useState(false);

  // const [stateList, setstateList] = useState(datastate);
  // const [districtList, setdistrictList] = useState(datadistrict);
  // const [pincodeList, setpincodeList] = useState(datapincode);

  const [state, setstate] = useState();
  const [district, setdistrict] = useState();
  const [pincode, setpincode] = useState();
  const [warehouse, setwarehouse] = useState();
  const [warehouseName, setwarehouseName] = useState();

  const [warehouseList, setwarehouseList] = useState([]);
  const [loading, setloading] = useState(false);
  const [errorMessage, seterrorMessage] = useState();

  const renderDataToLinkWareHouse = [
    {
      field: "State",
      setfield: setstate,
      isDisabled: false,
      options: stateList,
    },
    {
      field: "District",
      setfield: setdistrict,
      isDisabled: !state,
      options: districtList,
    },
    {
      field: "Location_PIN",
      setfield: setpincode,
      isDisabled: !district,
      options: pincodeList,
    },
    {
      field: "warehouse",
      setfield: setwarehouse,
      isDisabled: !pincode,
      options: warehouseList,
    },
  ];

  const formatWarehouselist = (list) => {
    let result = [];
    list.map((data) => {
      if (data.id !== userDetails.id) {
        //should not list the same warehouse for lining to it
        let temp = {
          key: data.id,
          value: data.storeName,
        };

        result.push(temp);
      }
    });

    return result;
  };

  //function to link warehouse to store
  const handleLinkWareHouse = () => {
    if (isWarehouse) {
      const payload = {
        warehouseId: userDetails.id,
        linkedWarehouses: [warehouse],
      };
      setloading(true);
      linkWarehouseToWarehouse(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              props.setVisible(false);
              message.success(response.data.message);
              form.resetFields();
            } else {
              message.error(response.data.message);
              seterrorMessage(response.data.message);
            }
          } else
            message.error("unable to link warehouse please try again later");
        })
        .catch(() => {})
        .finally(() => {
          setloading(false);
          props.reload(1);
        });
    } else {
      const payload = {
        storeId: userDetails.id,
        linkedWarehouse: warehouse,
        linkedWarehouseName: warehouseName,
      };
      setloading(true);
      linkWarehouseToStore(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              props.setVisible(false);
              message.success(response.data.message);
              form.resetFields();
            } else message.error(response.data.message);
          } else
            message.error("unable to link warehouse please try again later");
        })
        .catch(() => {})
        .finally(() => {
          setloading(false);
          props.reload(1);
        });
    }
  };

  const handdleFetchDistrictsByStates = () => {
    fetchDistrictList([state]).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setDistrictList(districtOptionsFormater(response.data.documents));
          console.log(
            response.data.documents[0].source.districts,
            "response for district list"
          );
        }
      }
    });
  };

  const handleFetchPincodesByDistricts = () => {
    fetchPincodeList([district]).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setPincodeList(pinCodeOptionsFormater(response.data.pincode));
          console.log(response, "response for pincode list");
        }
      }
    });
  };

  const handleFetchWarehouseList = () => {
    const payload = {
      key: "",
      sort: "date",
      sortBy: "asc",
      accountType: "warehouse",
      offset: 0,
      size: 10,
      filter: {
        term: [
          {
            accountType: [
              "warehouse"
            ]
          },
          {
            pinCode: [
              pincode.toString()
            ]
          }
        ]
      }
    };
    fetchUsers(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setwarehouseList(formatWarehouselist(response.data.documents));
        }
      }
    });
  };

  //for fetching districtList as the user selects states
  useEffect(() => {
    console.log("updated selected states list: ", state);

    if (state) {
      handdleFetchDistrictsByStates();
    } else setDistrictList([]);
  }, [state]);

  //for fetching pincode list as user selects district
  useEffect(() => {
    console.log("selected district");
    if (district) {
      handleFetchPincodesByDistricts();
    } else setPincodeList([]);
  }, [district]);

  useEffect(() => {
    const tagsData = [
      { key: "data1", value: "data1" },
      { key: "datat2", value: "datat2" },
      { key: "data3", value: "data3" },
      { key: "data11", value: "data11" },
      { key: "datat21", value: "datat21" },
    ];
    setTagList(tagsData);
    if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
      setCollapsed(true);
    } else setCollapsed(false);
  }, []);

  useEffect(() => {
    if (pincode) {
      handleFetchWarehouseList();
    } else setwarehouseList([]);
  }, [pincode]);

  //on resetting the state field clear other fields dependent on that
  useEffect(() => {
    form.resetFields(["District", "Location_PIN", "warehouse"]);
  }, [state]);

  //on resetting the district field clear other fields dependent on that
  useEffect(() => {
    form.resetFields(["Location_PIN", "warehouse"]);
  }, [district]);

  //on resetting the pincode field clear other fields dependent on that
  useEffect(() => {
    form.resetFields(["warehouse"]);
  }, [pincode]);

  return (
    <div className="merchant-link-modal">
      <Modal
        className="link-modal"
        title={"Link Warehouse"}
        footer={null}
        closeIcon={<CloseCircleOutlined />}
        visible={props.visible}
        centered={true}
        width={"40vw"}
        onOk={() => {
          form.resetFields();
          props.setVisible(false);
        }}
        onCancel={() => {
          form.resetFields();
          props.setVisible(false);
        }}
      >
        {userDetails && (
          <div
            className="flex"
            style={{
              borderBottom: "1px solid #f2f2f2",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <div>
              <Avatar icon={"a"} size="large" />
            </div>
            <div style={{ width: "100%", marginLeft: "20px" }}>
              <div>
                <div style={{ fontWeight: "500" }}>{userDetails.userCode} </div>
                <div style={{ color: "#0074be" }}> {userDetails.storeName}</div>
                <div
                  className="flex"
                  style={{ justifyContent: "space-between" }}
                >
                  <div className="address-location-item">
                    {" "}
                    Address: {"tryt"}{" "}
                  </div>
                  <div>
                    <div className="address-location-item">
                      {" "}
                      Email: {userDetails.email}
                    </div>
                    <div className="address-location-item">
                      {" "}
                      Phone: {userDetails.mobile}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div style={{ padding: "20px" }}>
          <Form
            form={form}
            onFinish={handleLinkWareHouse}
            // initialValues={{
            //   State: state,
            //   District: district,
            //   Location_PIN: pincode,
            //   warehouse: warehouse,
            // }}
          >
            <Row justify="space-between">
              {renderDataToLinkWareHouse.map((data) => {
                return (
                  <Col span={12}>
                    <div
                      className={"add-product-tile , link-warehouse-field-each"}
                    >
                      {data.field}
                      <div className="add-location-tile">
                        <Form.Item
                          name={data.field}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            allowClear={true}
                            size="middle"
                            disabled={data.isDisabled}
                            showSearch
                            onSelect={(value, option) => {
                              data.setfield(value);
                              data.field === "warehouse" &&
                                setwarehouseName(option.children);
                            }}
                            style={{ width: "100%" }}
                          >
                            {data.options &&
                              data.options.map((item, index) => {
                                return (
                                  <Option key={item.key} value={item.key}>
                                    {item.value}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}

            <div className={"seller-action-button"}>
              <Form.Item>
                <Button
                  style={{}}
                  // onClick={() => handleLinkWareHouse()}
                  className="button-seller-request-approve"
                  htmlType="submit"
                >
                  {loading ? (
                    <Loading style={{ color: "white" }} />
                  ) : (
                    "Link Warehouses"
                  )}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default LinkWarehouseModal;
