


import React from "react";
import { Card, Typography, Select } from "antd";

const { Text } = Typography;
const StockSummary = (props) => {
  const { summary, availableStock } = props;
  return (
    <Card
      title="Total"
      style={{ width: 300, marginTop: "20px", border: "1px solid  #D3D3D3" }}
      bordered={true}
    >
      {summary?.map((data) => {
        return (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Text strong>{data.orderStatus}</Text>
            <Text strong>{data.quantity}</Text>
          </div>
        );
      })}
      {availableStock >= 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Text strong>{"AVAILIABLITY"}</Text>
          <Text strong>{availableStock}</Text>
        </div>
      )}
    </Card>
  );
};

export default StockSummary;
