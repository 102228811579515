


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, message } from "antd";
import ReportsTable from "../ReportsTable";
import { autoApprovalColumns } from "../Utils/reportHelper";
import {
  listAutoApprovedProducts,
  exportAutoApprovedProducts,
} from "../../../../ApiAction/AdminApiAction/reportsApi";
import { formatAutoApprovalReport } from "../Utils/reportHelper";
const AutoApprovalReport = (props) => {
  const warning = Modal.warning;
  const { vendorId, reportSummary } = props;
  const [dataSource, setdataSource] = useState([]);
  const [isLoadingAutoApprovalReport, setIsLoadingAutoApprovalReport] = useState(false);

  const handleShowWarning = (msg) => {
    warning({
      title: msg,
      className: "create-confirm-modal",
    });
  };

  const handleValidateDateRange = (startDate, endDate) => {
    console.log("startDate: ", startDate, "endDate: ", endDate);

    var date1 = new Date(startDate);
    var date2 = new Date(endDate);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 186) {
      handleShowWarning(
        `Please choose a date range of lesser than or equal to 
        Six months.`
      );
      return false;
    }
    return true;
  };

  const handleExportAutoApprovalReport = async () => {
    const payload = {
      offset: 0,
      size: 10,
      sortBy: "addedDate",
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: props.searchVal,
      vendorId: vendorId ? vendorId : props.vendorFilter,
      brandId: props.brandId ? props.brandId : "",
    };

    if (props.stockPercentage) {
      switch (props.stockPercentage) {
        case "isStockLow":
          payload["isStockLow"] = true;
          break;

        case "isStockZero":
          payload["isStockZero"] = true;
          break;

        default:
          console.log("invalid choice");
      }
    }

    if (props.dateRange) {
      if (
        handleValidateDateRange(
          props.dateRange.startTime,
          props.dateRange.endtime
        )
      ) {
          payload["startTime"] = props.dateRange.startTime;
          payload["endtime"] = props.dateRange.endtime;

          const autoApprovalReport = await exportAutoApprovedProducts(payload)
          .then((response) => {
            console.log(response, "response for voucher code excel sheet");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `auto_approved_report.xlsx`);

            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            message.error("Could not export file please try again");
          });
        }

    } else {
      handleShowWarning(
        `Please Choose a date range maximum of Six months
        to download day book report.`
      );
    }

    return true;
  };
  useEffect(() => {
    const payload = {
      offset: 0,
      size: 10,
      sortBy: "approvedDate",
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: props.searchVal,
      vendorId: vendorId ? vendorId : props.vendorFilter,
      brandId: props.brandId ? props.brandId : "",
    };

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endtime"] = props.dateRange.endtime;
    } 

    setIsLoadingAutoApprovalReport(true);
    listAutoApprovedProducts(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log("reponse for sales report: ", response.data.documents);
            setdataSource(formatAutoApprovalReport(response.data.documents));
          }
        }
      })
      .finally(() => {
        setIsLoadingAutoApprovalReport(false);
      });
  }, [
    props.sort,
    props.dateRange,
    props.searchVal,
    props.vendorFilter,
  ]);
  return (
    <ReportsTable
      download={handleExportAutoApprovalReport}
      columns={autoApprovalColumns}
      dataSource={dataSource}
      type={"Auto Approval"}
      reportSummary={reportSummary}
      summary={
        "Auto approved products with stock are listed below"
      }
      isLoadingReport={isLoadingAutoApprovalReport}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
    dateRange: state.reportReducer.dateRange,
    vendorFilter: state.reportReducer.vendorId,
  };
};

export default connect(mapStateToProps)(AutoApprovalReport);
