import React, { useState, useEffect } from "react";
import { Select, DatePicker, message } from "antd";
import moment from "moment";
import { HiOutlineUpload } from "react-icons/hi";
import { adBannerType, FILE_PATH } from "../../../Utils/constants";
import DummyArea from "./DummyArea";
import { MdVerifiedUser } from "react-icons/md";
import { adTypes } from "../../../Utils/constants";
import {
  fetchVendors,
  getSubCategory,
} from "../../../ApiAction/AdminApiAction/adminApi";
import { getMegamenu } from "../../../ApiAction/CustomerApiActions/homepageAPI";
import { BiCheck, BiX } from "react-icons/bi";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import { fetchBrandList } from "../../../ApiAction/AdminApiAction/brandApi";
import {
  addAdvertisement,
  getVendors,
  updateAds,
} from "../../../ApiAction/AdminApiAction/adminApi";
import { MdEdit } from "react-icons/md";
import { IoIosCloseCircle } from "react-icons/io";

function EditAds(props) {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const { activeTab, adData, allData, setEditAds, primaryKey, reloadAds } =
    props;

  const [cardSelected, setCardSelected] = useState(false);
  const [bannerSelected, setBannerSelected] = useState(false);
  const [vendorTypeLoading, setVendorTypeLoading] = useState(false);
  const [categoryTypeLoading, setCategoryTypeLoading] = useState(false);
  const [categoryListLoading, setCategoryListLoading] = useState(false);
  const [brandListLoading, setBrandListLoading] = useState(false);
  const [entrySectionFilled, setEntrySectionFilled] = useState(false);
  const [makeDefaultImage, setMakeDefaultImage] = useState(false);
  const [allDataFilled, setAllDataFilled] = useState(false);
  const [dataSaving, setDataSaving] = useState(false);

  const [imageSelectionResult, setImageSelectionResult] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [adRate] = useState(23.89);
  const [totalAdCharge, setTotalAdCharge] = useState(0.0);

  const [imageUploadLabel, setImageUploadLabel] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedAdType, setSelectedAdType] = useState("");
  const [selectedAccountName, setSelectedAccountName] = useState("");
  const [selectedAccountType, setSelectedAccountType] = useState("");
  const [selectedVendorDetails, setSelectedVendorDetails] = useState("");
  const [selectedCategoryType, setSelectedCategoryType] = useState("");
  const [selectedSubCategoryType, setSelectedSubCategoryType] = useState("");
  const [selectedAdImage, setSelectedAdImage] = useState("");
  const [selectedTimeRange, setSelectedTimeRange] = useState();
  const [imageURLCards, setImageURLCards] = useState("");
  const [imageURLBanners, setImageURLBanners] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [topGradient, setTopGradient] = useState("#D44000");
  const [bottomGradient, setBottomGradient] = useState("#FF7A00");
  const [selectedImageFile, setSelectedImageFile] = useState();

  const [fetchedAccountDetails, setFetchedAccountDetails] = useState([]);
  const [fetchedCategoryType, setFetchedCategory] = useState([]);
  const [fetchedSubCategory, setFetchedSubCategory] = useState([]);
  const [fetchedBrandList, setFetchedBrandList] = useState([]);
  const [editTimeRange, setEditTimeRange] = useState(false);

  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const [documentId, setDocumentId] = useState("");
  const [advertisementId, setAdvertisementId] = useState("");

  const MAX_STRING_LENGTH = 30;

  const handleTypeChange = (value) => {
    setSelectedAdType(value);
    if (value === "CARD") {
      setCardSelected(true);
      setBannerSelected(false);
      setImageUploadLabel("CARD");
    } else {
      setCardSelected(false);
      setBannerSelected(true);
      setImageUploadLabel("BANNER");
    }
    switchImages();
  };

  const switchImages = () => {
    if (selectedAdImage) {
      if (selectedAdType === "CARD") {
        setImageURLCards();
        setImageURLBanners(imageURLCards);
      } else {
        setImageURLBanners();
        setImageURLCards(imageURLBanners);
      }
    }
  };

  // for checking if all the fields of entry section is filled
  useEffect(() => {
    if (
      selectedAdType !== "" &&
      selectedAccountType !== "" &&
      selectedCategoryType !== "" &&
      selectedVendorDetails !== "" &&
      selectedSubCategoryType !== "" &&
      startDate !== "" &&
      endDate !== ""
    ) {
      if (selectedAdType === "BRANDS" && selectedBrand === "") {
        setEntrySectionFilled(false);
        return;
      }
      setEntrySectionFilled(true);
    } else {
      setEntrySectionFilled(false);
    }
  }, [
    selectedAdType,
    selectedVendorDetails,
    selectedAccountType,
    selectedCategoryType,
    selectedSubCategoryType,
    startDate,
    endDate,
    selectedBrand,
  ]);

  const handleAccountTypeChange = (value) => {
    // only need to search if the account type is selected
    setFetchedAccountDetails([]);
    if (selectedAccountType) {
      setVendorTypeLoading(true);
      const accountPayload = {
        key: value,
        offset: 0,
        size: 1000,
        filter: {
          term: [
            { status: ["APPROVED"] },
            { isArchived: ["false"] },
            { block: ["false"] },
          ],
        },
      };
      switch (selectedAccountType) {
        case "STORES":
          accountPayload["accountType"] = "store";
          break;
        case "SELLERS":
          accountPayload["accountType"] = "seller";
          break;
        case "BRANDS":
          const vendorPayload = {
            key: value,
            size: 10,
            offset: 0,
            sortBy: "name.keyword",
            sortType: "asc",
          };
          getVendors(vendorPayload)
            .then((response) => {
              if (response?.data?.status === "success") {
                console.log("Brand list Brand");
                console.log(response);
                setFetchedAccountDetails(response?.data?.documents);
              } else {
                message.error(response?.data?.message);
              }
            })
            .catch((error) => {
              message.error(error.message);
            })
            .finally(() => {
              setVendorTypeLoading(false);
            });
          return;
        default:
          message.warning("Something went wrong, Please refresh browswer");
      }
      fetchVendors(accountPayload)
        .then((response) => {
          if (response.data.status === "success") {
            if (response?.data?.count > 0) {
              setFetchedAccountDetails(response?.data?.documents);
              console.log(fetchedAccountDetails.length);
            }
          }
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          setVendorTypeLoading(false);
        });
    } else {
      message.info("You need to select Account Type first");
    }
  };

  // function used to disable date that come after today
  const disabledDate = (current) => {
    return current < moment();
  };

  useEffect(() => {
    setCategoryTypeLoading(true);
    let megaMenu = JSON.parse(localStorage.getItem("megamenu"));
    console.log("Mega Menu");
    console.log(megaMenu);
    if (megaMenu) {
      setFetchedCategory(megaMenu);
      setCategoryTypeLoading(false);
    } else {
      getMegamenu()
        .then((response) => {
          if (response?.data?.status === "success") {
            console.log(response.data.documents);
            setFetchedCategory(response?.data?.documents);
          } else {
            message.error(response?.data?.message);
          }
        })
        .catch((error) => {
          message.error(error.message);
        })
        .finally(() => {
          setCategoryTypeLoading(false);
        });
    }
  }, [activeTab]);

  const handleCategoryTypeChange = (categoryId) => {
    setCategoryListLoading(true);
    setSelectedCategoryType(categoryId);
    setSelectedSubCategoryType("");
    getSubCategory(categoryId, 0)
      .then((response) => {
        console.log("Response of sub category listing");
        console.log(response);
        setFetchedSubCategory(response?.data?.category);
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setCategoryListLoading(false);
      });
  };

  const handleDateRange = (dateString) => {
    let START_DATE;
    let END_DATE;
    let totalAdCost = 0;
    console.log("Date range values");
    console.log(dateString);
    if (dateString !== null) {
      setStartDate(new Date(dateString[0]).getTime());
      START_DATE = new Date(dateString[0]).getTime();
      setEndDate(new Date(dateString[1]).getTime());
      END_DATE = new Date(dateString[1]).getTime();
      let TOTAL_DAYS = (END_DATE - START_DATE) / (1000 * 3600 * 24);
      if (START_DATE === END_DATE) {
        setTotalDays(1 + " or less");
      } else {
        setTotalDays(parseInt(TOTAL_DAYS));
      }
      totalAdCost = TOTAL_DAYS * adRate;
      setTotalAdCharge(totalAdCost);
      setSelectedTimeRange([
        moment(dateString[0], "YYYY-MM-DD HH:MM:SS"),
        moment(dateString[1], "YYYY-MM-DD HH:MM:SS"),
      ]);
    }
  };

  const renderImageSelectionResults = () => {
    if (imageSelectionResult !== 0) {
      if (imageSelectionResult === 1) {
        return (
          <p className="ad-image-upload-success">
            Image Selected successfully.
          </p>
        );
      } else {
        return (
          <p className="ad-image-upload-error">
            Resolution mismatch, uploaded image does not meet our criteria.
          </p>
        );
      }
    }
  };

  const _URL = window.URL || window.webkitURL;
  let imageURLs;

  const handleImageSelection = (e) => {
    setImageURLBanners();
    setImageURLCards();
    try {
      imageURLs = _URL.createObjectURL(e.target.files[0]);
      setIsImageUpdated(true);
      setSelectedImageFile(e.target.files[0]);
      if (selectedAdType === "CARD") {
        setImageURLCards(imageURLs);
      } else {
        setImageURLBanners(imageURLs);
      }
      setSelectedAdImage(true);
    } catch (error) {
      setSelectedAdImage(false);
    }
  };

  const clearSelectedImage = () => {
    setSelectedAdImage(false);
    setMakeDefaultImage(false);
  };

  useEffect(() => {
    if (entrySectionFilled && selectedAdImage) {
      setAllDataFilled(true);
      window.scrollTo(0, 160);
    } else {
      setAllDataFilled(false);
    }
    console.log("Data is changing", entrySectionFilled, selectedAdImage);
  }, [entrySectionFilled, selectedAdImage]);

  const handleBrandSearch = (key) => {
    setBrandListLoading(true);
    const payload = {
      offset: 0,
      size: 10,
      key: key,
    };
    fetchBrandList(payload)
      .then((response) => {
        if (response?.data?.status === "success") {
          setFetchedBrandList(response?.data?.documents);
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setBrandListLoading(false);
      });
  };

  const updateAd = async () => {
    const adDatas = new FormData();
    console.log("Saving Value");
    setDataSaving(true);

    if (!makeDefaultImage) {
      adDatas.append("from", startDate);
      adDatas.append("to", endDate);
    } else {
      adDatas.append("from", 0);
      adDatas.append("to", 0);
    }
    adDatas.append("advertisementType", selectedAccountType);
    if (selectedAccountType === "BRANDS") {
      adDatas.append("id", selectedBrand);
    } else {
      adDatas.append("id", selectedVendorDetails);
    }
    adDatas.append("categoryId", selectedCategoryType);
    adDatas.append("subCategoryId", selectedSubCategoryType);
    adDatas.append("orderBy", selectedAccountName);
    adDatas.append("makeDefault", makeDefaultImage);
    adDatas.append("topGradient", topGradient);
    adDatas.append("bottomGradient", bottomGradient);
    adDatas.append("imageType", selectedAdType);
    adDatas.append("files", selectedImageFile);
    adDatas.append("imageChanged", isImageUpdated);
    adDatas.append("documentId", documentId);
    adDatas.append("advertisementId", advertisementId);

    updateAds(adDatas)
      .then((response) => {
        if (response?.data?.status === "success") {
          message.success(response?.data?.message);
          clearFields();
          setEditAds(false);
          reloadAds();
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setDataSaving(false);
      });
  };

  const clearFields = () => {
    setAllDataFilled(false);
    setSelectedAccountType("");
    setSelectedVendorDetails("");
    setSelectedBrand("");
    setFetchedBrandList([]);
    setSelectedAccountName("");
    setFetchedAccountDetails([]);
    setMakeDefaultImage(false);
    setSelectedAdImage(false);
    setImageURLCards("");
    setImageURLBanners("");
    setStartDate("");
    setEndDate("");
    setTotalDays(0);
    setSelectedTimeRange([]);
  };

  // Modifications for Edit Ads
  useEffect(() => {
    console.log("Printing Ads Data");
    console.log(adData);
    console.log(allData);
    setSelectedAccountType(adData?.advertisementType);
    setSelectedVendorDetails(adData?.orderBy);
    setSelectedAdType(adData?.imageType);
    handleTypeChange(adData?.imageType);
    setSelectedCategoryType(allData?.categoryId);
    setSelectedSubCategoryType(allData?.subCategoryId);
    handleEditImage(adData?.images[0]);
    setSelectedAccountName(adData?.orderBy);
    setAllDataFilled(true);
    // setSelectedBrand(adData?.id);
    setStartDate(adData?.dateRange?.from);
    setSelectedTimeRange([
      moment(adData?.dateRange?.from),
      moment(adData?.dateRange?.to),
    ]);
    setEndDate(adData?.dateRange?.to);
    setDocumentId(primaryKey);
    setAdvertisementId(adData?.cardId ? adData?.cardId : adData?.bannerId);
  }, []);

  const handleEditImage = (image) => {
    setImageURLBanners();
    setImageURLCards();
    try {
      imageURLs = FILE_PATH + image;
      setSelectedImageFile(image);
      if (selectedAdType === "") {
        if (adData?.imageType === "CARD") {
          setImageURLCards(imageURLs);
        } else {
          setImageURLBanners(imageURLs);
        }
        setSelectedAdImage(true);
      } else {
        if (selectedAdType === "CARD") {
          setImageURLCards(imageURLs);
        } else {
          setImageURLBanners(imageURLs);
        }
        setSelectedAdImage(true);
      }
    } catch (error) {
      setSelectedAdImage(false);
    }
  };

  return (
    <div className="ads-edit-main-container">
      <div className="newAds-container ads-edit-container">
        <div className="ads-edit-heading-section">
          <h1>Edit your Ads</h1>
          <button onClick={() => setEditAds(false)}>
            close <IoIosCloseCircle />
          </button>
        </div>

        <div className="newAds-section">
          <div
            className={
              entrySectionFilled
                ? "newAds-insert-section newAds-insert-section-filled ads-container ads-container-filled"
                : "newAds-insert-section ads-container"
            }
            data-section-title="Entry"
          >
            <div className="entry-box-ads">
              <p className="entry-box-title">{"Advertisement For"}</p>
              <Select
                className="entry-box-select"
                onSelect={(value) => {
                  setSelectedAccountType(value);
                  setFetchedAccountDetails([]);
                  setSelectedVendorDetails("");
                }}
                value={selectedAccountType}
              >
                {adTypes.map((type, index) => (
                  <Option key={index} value={type}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>

            <div className="entry-box-ads">
              <p className="entry-box-title">{"Vendor Details"}</p>
              <Select
                className="entry-box-select"
                showSearch
                loading={vendorTypeLoading}
                placeholder="Search details here"
                onSearch={(value) => handleAccountTypeChange(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                onSelect={(value, Option) => {
                  setSelectedVendorDetails(Option.key);
                  setSelectedAccountName(Option.text);
                }}
                value={selectedVendorDetails}
              >
                {fetchedAccountDetails?.map((accountDetails) => (
                  <Option
                    key={accountDetails.id}
                    text={
                      accountDetails.name
                        ? accountDetails.name
                        : accountDetails.source.name
                    }
                    value={accountDetails.id}
                  >
                    {accountDetails.name
                      ? accountDetails.name
                      : accountDetails.source.name}
                  </Option>
                ))}
              </Select>
            </div>

            {selectedAccountType === "BRANDS" && (
              <div className="entry-box-ads">
                <p className="entry-box-title">{"Search Brand"}</p>
                <Select
                  className="entry-box-select"
                  showSearch
                  loading={brandListLoading}
                  placeholder="Search Brand here"
                  onSearch={(value) => handleBrandSearch(value)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(value) => {
                    setSelectedBrand(value);
                  }}
                  value={selectedBrand}
                >
                  {fetchedBrandList?.map((brand) => (
                    <Option key={brand.id} value={brand.id}>
                      {brand?.source?.brandName}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            <div className="entry-box-ads">
              <p className="entry-box-title">{"Category Type (Position)"}</p>
              <Select
                className="entry-box-select"
                loading={categoryTypeLoading}
                onSelect={(value) => {
                  handleCategoryTypeChange(value);
                }}
                value={allData?.categoryName}
                disabled
              >
                {fetchedCategoryType?.map((type, index) => (
                  <Option key={index} value={type.id}>
                    {type.categoryName}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="entry-box-ads">
              <p className="entry-box-title">{"Sub Level Category"}</p>
              <Select
                className="entry-box-select"
                loading={categoryListLoading}
                value={allData?.subCategoryName}
                onSelect={(value) => {
                  setSelectedSubCategoryType(value);
                }}
                disabled
              >
                {fetchedSubCategory?.map((category, index) => (
                  <Option value={category.id}>
                    {category?.source?.categoryName}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="entry-box-ads">
              <p className="entry-box-title">{"Type of Advertisement"}</p>
              <Select
                className="entry-box-select"
                onSelect={(value) => handleTypeChange(value)}
                value={selectedAdType}
                disabled
              >
                {adBannerType.map((type, index) => (
                  <Option value={type} key={index}>
                    {type}
                  </Option>
                ))}
              </Select>
            </div>
            <div></div>
            {!makeDefaultImage && (
              <div className="entry-box-ads entry-box-ads-time-range">
                <p className="entry-box-title">
                  {"Time Range of ADs"}
                  <strong>
                    {totalDays === 1 ? " (Only " : totalDays > 0 && " ("}{" "}
                    {totalDays > 0 && `${totalDays} Day`}
                    {totalDays > 1 ? "s)" : totalDays > 0 && ")"}
                  </strong>
                </p>

                <RangePicker
                  showTime
                  onChange={handleDateRange}
                  style={{ minWidth: "100%" }}
                  value={selectedTimeRange}
                />
              </div>
            )}
            <button
              className="ad-submit-clear-all-button"
              onClick={() => clearFields()}
            >
              CLEAR ALL
            </button>
          </div>
          <div
            className="ads-preview-section ads-container"
            data-section-title="preview"
          >
            <DummyArea
              cardSelected={cardSelected}
              bannerSelected={bannerSelected}
              imageURLCards={imageURLCards}
              imageURLBanners={imageURLBanners}
              makeDefault={makeDefaultImage}
              topGradient={topGradient}
              bottomGradient={bottomGradient}
              setTopGradient={setTopGradient}
              setBottomGradient={setBottomGradient}
            />
          </div>
          <div
            className={
              selectedAdImage
                ? "ads-image-upload-section ads-image-upload-section-filled ads-container ads-container-filled"
                : "ads-image-upload-section ads-container"
            }
            data-section-title="upload picture"
          >
            {selectedAdImage ? (
              <label className="ad-image-choose-success">
                <BiCheck style={{ fontSize: "1.2rem" }} /> UPLOADED{" "}
                <BiX
                  className="ads-image-clear-button"
                  onClick={clearSelectedImage}
                />
              </label>
            ) : (
              <input
                type="file"
                className="ad-image-uploader"
                onChange={(e) => handleImageSelection(e)}
                accept="image/*"
              />
            )}
            {renderImageSelectionResults}
            {selectedAdImage && (
              <div className="ad-image-operation-section">
                {/* <button className="ad-image-operation-section-buttons" onClick={switchImages}>
                                <HiOutlineSwitchHorizontal />
                            </button> */}
                {/* <button
                  onClick={() => setMakeDefaultImage(!makeDefaultImage)}
                  className={
                    makeDefaultImage
                      ? "ad-image-operation-section-buttons ad-image-operation-section-default-button"
                      : "ad-image-operation-section-buttons"
                  }
                >
                  {makeDefaultImage ? "Remove Default" : "MAKE DEFAULT"}
                </button> */}
              </div>
            )}
          </div>
        </div>
        {allDataFilled && (
          <div className="ad-banner-confirm-box">
            <MdVerifiedUser className="ad-approved-icons" />
            {makeDefaultImage ? (
              <span>
                <p>Advertisement Summary</p>
                <p>
                  Ad of <strong>{selectedAccountName}</strong> is selected as
                  Default Image.
                </p>
              </span>
            ) : (
              <span>
                <p>Advertisement Summary</p>
                {/* <p>
                  Ad of <strong>{selectedAccountName}</strong> is set to shown
                  from <strong>{moment(startDate).format("DD-MM-YYYY")}</strong>{" "}
                  to <strong>{moment(endDate).format("DD-MM-YYYY")}</strong>,
                  total <strong>{totalDays} Days.</strong> Type of Ad is{" "}
                  <strong>{selectedAdType}</strong>, and a total of{" "}
                  <strong>₹{totalAdCharge.toFixed(2)}</strong> is need to be
                  remitted.
                </p> */}
                <p>
                  New data will be applied immediately to Advertisement after
                  saving.{" "}
                </p>
              </span>
            )}
            <button
              className="ad-summary-discard-button"
              onClick={() => setEditAds(false)}
            >
              <HiArrowNarrowLeft className="ad-summary-confirm-icon" />
              Discard
            </button>
            <button
              className="ad-summary-confirm-button"
              onClick={() => updateAd()}
            >
              I confirm{" "}
              <HiArrowNarrowRight className="ad-summary-confirm-icon" />
            </button>
          </div>
        )}

        {dataSaving && (
          <div className="ad-waiting-main-container">
            <p>Please wait while we setup Ads</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default EditAds;
