


export const districtDataFormater = (rawData) => {
  let result = [];
  rawData.map((data) => {
    result = result.concat(data.source.districts);
  });
  return result;
};

export const districtOptionsFormater = (rawData) => {
  let result = [];
  rawData.map((data) => {
    result = result.concat(data.source.districts);
  });
  let formatedData = result.map((data, index) => {
    return { key: data, value: data };
  });

  return formatedData;
};

export const pinCodeDataFormater = (rawData) => {
  // console.log(rawData, ":raw pincodes");
  let result = [];
  rawData.map((data,index) => {
    if(index < 20000) {
      result.push(data.key.toString());
    }
  });
  return result;
};

export const pinCodeOptionsFormater = (rawData) => {
  console.log(rawData, ":raw pincodes");
  let result = [];
  rawData.map((data) => {
    result.push(data.key);
  });
  // let unique = result.filter((item, i, ar) => ar.indexOf(item) === i);
  let formatedData = result.map((data, index) => {
    return { key: data, value: data };
  });
  return formatedData;
};
