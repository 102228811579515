


import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Modal,
  Divider,
  Typography,
  Input,
  Row,
  Col,
  Image,
  Button,
} from "antd";
import locationIcon from "../../../../Assets/Images/Icons/Seller/location.svg";

import onlineIcon from "../../../../Assets/Images/Icons/Seller/online-shopping.svg";
import calendarIcon from "../../../../Assets/Images/Icons/Seller/calendar.svg";
import { FILE_PATH } from "../../../../Utils/constants";
const { Text } = Typography;
const { TextArea } = Input;

const getValueWithFormat = (data) => {
  if (data) {
    const filterArray = [
      // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
      data ? data.addressLine1 : null,
      data ? data.addressLine2 : null,
      data ? data.buildingName : null,
      data ? data.district : null,
      data ? data.pinCode : null,
    ];
    console.log("data??", filterArray);

    const filtered = filterArray.filter(function (el) {
      return el != null;
    });
    const filteredNullStringValues = filtered.filter(Boolean);
    return (
      filteredNullStringValues &&
      filteredNullStringValues.toString().split(",").join(", ")
    );
  }
};

const RequestDetails = (props) => {
  const filterArray = [
    // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
    props.data ? props.data.source.shippedAddress.ownerName : null,
    props.data ? props.data.source.shippedAddress.addressLine1 : null,
    props.data ? props.data.source.shippedAddress.buildingName : null,
  ];
  console.log("data??", filterArray);

  const filtered = filterArray.filter(function (el) {
    return el != null;
  });

  useEffect(() => {
    console.log("data for return details: ", props?.data);
  }, [props.data]);

  return (
    <Modal
      visible={true}
      onOk={() => props.setCancel(false)}
      onCancel={() => {
        props.setCancel(false);
      }}
      className="manage_dlt_pop"
      width={950}
      footer={null}
    >
      <div
        className="view-invoice-layout"
        style={{ padding: "10px 10px 0", borderBottom: "0px" }}
      >
        <div className="justify-space-between pop-return-list">
          <div className="flex custr_mng_rtrn">
            <div className="return_pdt_img">
              <img
                className="manage-products-draft-product-card-image"
                src={FILE_PATH + props.data.source.image}
                alt={props.data.source.productName}
              />
            </div>
            <div className="return-data-card-seller custr_mng_rtrn">
              <div className="seller-return-product-name">
                {props.data.source.productName}
              </div>
              <div className="flex">
                <div
                  className="seller-return-product-price"
                  style={{ marginRight: "25px" }}
                >
                  ₹ {props.data.source.itemCurrentPrice.sellingPrice}
                </div>
                <div
                  className="seller-return-name"
                  style={{ paddingBottom: "8px" }}
                >
                  {props.data.source.itemCurrentPrice.quantity +
                    " " +
                    props.data.source.itemCurrentPrice.unit}
                </div>
              </div>
              <div className=" flex-align-center date_return rtn-mob">
                <img
                  src={calendarIcon}
                  style={{ width: "16px" }}
                  className="verified-icon"
                  alt="requested_date"
                />
                {moment(props.data.source.requestedDate).format(
                  " MMM DD, hh:mm A"
                )}
              </div>
              <div className=" flex order-item-active ord_typ rtn-mob">
                <img
                  src={onlineIcon}
                  style={{ width: "20px" }}
                  className="verified-icon"
                  alt="payment_mode"
                />
                {" " + props.data.source.paymentMode}
              </div>
              <div> ORDER ID{" : " + props.data.source.groupOrderId}</div>
            </div>
          </div>
          <div className="mob_visible_rtn_data custr_mng_rtrn justify-content-between">
            <div className="frm_ip_small">
              <div className=" flex-align-center date_return">
                <img
                  src={calendarIcon}
                  style={{ width: "16px" }}
                  className="verified-icon"
                  alt="requested_date"
                />
                {moment(props.data.source.requestedDate).format(
                  " MMM DD, hh:mm A"
                )}
              </div>
              <div className=" flex order-item-active ord_typ">
                <img
                  src={onlineIcon}
                  style={{ width: "20px" }}
                  className="verified-icon"
                  alt="payment_mode"
                />
                {" " + props.data.source.paymentMode}
              </div>
            </div>
            <div
              className="order-item-active rqst_status"
              dataStatus={"request-" + props.data.source.status.toLowerCase()}
            >
              REQUEST{" " + props.data.source.status}
            </div>
          </div>
          <div>
            <div
              className="order-item-active rqst_status rtn-mob"
              dataStatus={"request-" + props.data.source.status.toLowerCase()}
            >
              REQUEST{" " + props.data.source.status}
            </div>
            <div className="address-row-order" style={{ marginTop: "10px" }}>
              <img
                src={locationIcon}
                style={{ width: "25px" }}
                className="verified-icon"
              />
              <div className="line-break-location">
                {filtered.map((i, index, { length }) => (
                  <li>
                    {i}
                    {index !== length - 1 && ", "}
                  </li>
                ))}
                <li>
                  Pincode :
                  {props.data ? props.data.source.shippedAddress.pinCode : null}
                </li>
                {/* {props.data.source.shippedAddress.ownerName}<br />{props.data.source.shippedAddress.addressLine1 + ","}
                {props.data.source.shippedAddress.buildingName}<br />{props.data.source.shippedAddress.pinCode} */}
              </div>
            </div>
            {(props.data.source.status === "ACCEPTED" || "PENDING") && (
              <div
                className="flex-align-center agn-cnt"
                style={{ marginTop: "15px" }}
              >
                <InfoCircleOutlined
                  style={{ marginRight: "6px", color: "#003c80" }}
                />
                {props?.data?.source?.vendorType === "SELLER"
                  ? props?.data?.source?.status === "PENDING"
                    ? "Awaiting for seller response"
                    : "update details to further procced your returns"
                  : " Our agent will contact you soon"}
              </div>
            )}
          </div>
        </div>
        <Divider style={{ margin: "10px 0" }} />
        {props.data.source.status !== "PENDING" && (
          <Fragment>
            <div style={{ fontWeight: "500" }}>Comments from seller</div>
            <div className="return-message">
              {" "}
              {props.data.source.replyToTheRequest}
            </div>
          </Fragment>
        )}
        <div className="seller-action-button">
          <Button
            style={{ width: "200px" }}
            onClick={() => props.setCancel(false)}
            className="button-seller-request-approve"
          >
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default RequestDetails;
