


import {
  SET_FILTER_BY_BRAND,
  SET_FILTER_BY_VENDOR,
  SET_FILTER_BY_DATE_RANGE,
  SET_FITER_BY_STOCK_PERCENTAGE,
  SET_FILTER_BY_SCREEN,
} from "../action/types";

export default function reportReducer(
  state = {
    brandId: null,
    dateRange: null,
    stockPercentage: null,
    screenFilter: null,
    vendorId: "",
  },
  action
) {
  switch (action.type) {
    case SET_FILTER_BY_BRAND:
      state = { ...state, brandId: action.data };
      break;
    case SET_FILTER_BY_DATE_RANGE:
      state = { ...state, dateRange: action.data };
      break;

    case SET_FITER_BY_STOCK_PERCENTAGE:
      state = { ...state, stockPercentage: action.data };
      break;

    case SET_FILTER_BY_VENDOR:
      state = { ...state, vendorId: action.data };
      break;

    case SET_FILTER_BY_SCREEN:
      state = { ...state, screenFilter: action.data };
      break;

    default:
      state = { ...state};
  }
  return state;
}
