import React, { useState } from 'react';
import './LogHome.scss';
import { message } from 'antd';
import { IoMdCart } from "react-icons/io";
import { BiSearchAlt2 } from "react-icons/bi";
import { HiOutlineDownload } from "react-icons/hi";
import { CgArrowsExchange } from "react-icons/cg";
import { ENV_TYPE, DOMAIN } from '../../../../Utils/constants';
import { RiSettings4Line } from "react-icons/ri";
import Cookies from 'universal-cookie';
import LogFilter from './LogFilter';
import LogTable from './LogTable';
import { log_get_export } from '../../../../ApiAction/AdminApiAction/logApi';

function LogHome(props) {

    const cookies = new Cookies();
    const [ downloadReady, setDownloadReady ] = useState(false);
    const [ downloadLoading, setDownloadLoading ] = useState(false);

    const [ action, setActions ] = useState('');
    const [ screen, setScreen ] = useState('');
    const [ status , setStatus ] = useState('');
    const [ startDate, setStartDate ] = useState('');
    const [ endDate, setEndDate ] = useState('');
    const [ searchKey, setSearchKey ] = useState('');
    const [ offSet, setOffSet] = useState(0);
    const [ size ] = useState(20);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ count, setCount ] = useState(0);


    const logOff = () => {
        if(localStorage.getItem("authToken")) {
            localStorage.removeItem("authToken")
        }
        if(ENV_TYPE === "dev") {
            cookies.remove("accountType", "logger", {
                path: "/",
            });
            props.history.push("/logger-admin")
        }else {
            cookies.remove("accountType", "logger", {
                path: "/",
                domain: `${DOMAIN}`
            });
            props.history.push(`/`)
        }
        message.info("See you soon 🤘") 
    }

    const downloadDocument = () => {
        if(startDate && endDate !== '') {
            setDownloadLoading(true)
            const payload = {
                searchKey : searchKey,
                screenName : screen,
                actionName : action,
                statusKey : status,
                startTime : startDate,
                endTime : endDate,
                sortBy: 'timestamp',
                sortType: 'desc',
            }
            log_get_export(payload).then((response)=> {
                console.log("Export response")
                console.log(response)
                const link = window.URL.createObjectURL(response.data);
                const downloadURL = document.createElement('a')
                downloadURL.href = link;
                downloadURL.setAttribute('download', 'LogReport.xlsx');
                document.body.append(downloadURL)
                downloadURL.click();
            }).catch((error)=> {
                message.error(error.message)
            }).finally(()=> {
                setDownloadLoading(false)
            })
        }else {
            message.warning("Please select a date range")
        }
    }

    return (
        <div className="logHome-main-container">
            <div className="logHome-top-container">
                <span className="logHome-name-box">
                    <IoMdCart className="logHome-name-box-icon" /> {"Audit Logs"}
                </span>
                <div className="logHome-top-right-container">
                    <span className="logHome-search-container logHome-search-except-mobile">
                        <input type="text" className="logHome-search-input" required onChange={(e)=> {
                            setSearchKey(e.target.value)
                            setOffSet(0)
                            setCurrentPage(1)
                        }} />
                        <BiSearchAlt2 className="logHome-search-icon" style={{ pointerEvents: "none"}} />
                    </span>
                    <span className="logHome-search-container">
                        <RiSettings4Line className="logHome-search-icon logHome-search-icon-buttons" />
                    </span> 
                    <div className="logHome-search-container" onClick={downloadDocument}>
                        <HiOutlineDownload className={ downloadReady ? (
                                "logHome-search-icon logHome-search-icon-buttons logHome-ready-to-download-button"
                            ) : (
                                "logHome-search-icon logHome-search-icon-buttons"
                            )}/>
                        { downloadLoading && (<span className="logHome-download-loading"></span>)}    
                    </div>                                        
                    <span className="logHome-search-container" onClick={logOff}>
                        <CgArrowsExchange className="logHome-search-icon logHome-search-icon-buttons" />
                    </span>
                    {/* <span className="logHome-name-user-info-box">
                        🖐 Hi, Superadmin
                    </span> */}
                </div>
            </div>

            <LogFilter
                setActions={setActions}
                setScreen={setScreen}
                setStatus={setStatus}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setDownloadReady={setDownloadReady}
                setOffSet={setOffSet}
                setCurrentPage={setCurrentPage} />

            <div className="logHome-search-mobile-only">
                <input type="text" onChange={(e)=> {
                    setSearchKey(e.target.value);
                    setCurrentPage(1);
                    setOffSet(0)
                    }} className="logHome-search-mobile-only-input" placeholder="Search anything here" />
                <BiSearchAlt2 className="logHome-search-icon-mobile-only" />
            </div>
                
            <LogTable
                action={action}
                screen={screen}
                status={status}
                startDate={startDate}
                endDate={endDate}
                searchKey={searchKey}
                offSet={offSet}
                size={size}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setOffSet={setOffSet}
                setCount={setCount}
                count={count}
                 />

        </div>
    )
}

export default LogHome
