

import React, { useEffect, useState, Fragment } from "react";
import Avatar from "antd/lib/avatar/avatar";
import { Button, message, Modal } from "antd";
import Cookies from "universal-cookie";
import LinkIcon from "../../../Assets/Images/Icons/link.svg";
import LinkedIcon from "../../../Assets/Images/Icons/linked.svg";
import DeleteIcon from "../../../Assets/Images/Icons/delete-light.svg";

import { removeLiinkWarehouseToStore } from "../../../ApiAction/AdminApiAction/linkWarehouseAoi";

import LinkedWarehousesModal from "./Components/linkedWarehousesModal";
import { ENV_TYPE, DOMAIN } from "../../../Utils/constants";

const confirm = Modal.confirm;

const StoreDetailsModal = (props) => {
  const cookies = new Cookies();
  const [userDetails, setUserDetails] = useState(props.details);
  const [linkedWarehouseListModal, setlinkedWarehouseListModal] =
    useState(false);
  const [linkedStore, setlinkedStore] = useState(false);

  //dynamicaly render link icon
  const getLinkIcon = (data) => {
    if (
      data?.linkedWarehouses?.length > 0 ||
      data?.linkedStores?.length > 0 ||
      data.linkedWarehouse
    ) {
      return LinkedIcon;
    } else return LinkIcon;
  };

  const handleOpenLinkedWarehouseOrStoreModal = (type) => {
    setlinkedWarehouseListModal(true);
    type === "store" ? setlinkedStore(true) : setlinkedStore(false);
  };

  const handleremoveLinkConfirm = () => {
    confirm({
      title: `Do you want to unlink warehouse from this store!!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        handleRemoveLinkedWareHouse();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleRemoveLinkedWareHouse = () => {
    const payload = {
      storeId: userDetails.id,
      linkedWarehouse: "",
    };
    removeLiinkWarehouseToStore(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          console.log("reponse for unlink warehouse:", response);
          message.success(response.data.message);
          props.reload(1);
        } else message.error(response.data.message);
      } else {
        message.error("unable to unlink warehouse please try again later.");
      }
    });
  };
  useEffect(() => {
    setUserDetails(props.details);
  }, [props]);

  return (
    <div className="admin-manage-customer-details">
      <div>
        <Avatar
          icon={userDetails && userDetails.name && userDetails.name.charAt(0)}
        />
      </div>
      {userDetails && (
        <div className="customer-address-content">
          <div className="store-detail-header">
            <div>
              <div style={{ fontWeight: "500" }}>{userDetails.storeName} </div>
              <div style={{ fontSize: "10px" }}> {userDetails.userCode}</div>
            </div>
            <img
              src={getLinkIcon(userDetails)}
              style={{ width: "25px", marginRight: "10px" }}
            />
          </div>
          <div className="address-location"> Email: {userDetails.email}</div>
          <div className="address-location-item">
            {" "}
            Phone: {userDetails.mobile}
          </div>
          <div className="address-location-item">
            {" "}
            Address: {userDetails.location} (
            <span style={{ color: "#0074be" }}>{userDetails.pinCode}</span>){" "}
          </div>

          {userDetails.linkedWarehouse ? (
            <div className="address-location-item">
              {" "}
              Warehouse :
              <span style={{ color: "#0074be" }}>
                {" "}
                {userDetails.linkedWarehouseName}{" "}
                {!props.isLinked && (
                  <img
                    src={DeleteIcon}
                    style={{
                      width: "15px",
                      marginLeft: "10px",
                      float: "right",
                      cursor: "pointer",
                    }}
                    onClick={() => handleremoveLinkConfirm()}
                  />
                )}
              </span>
            </div>
          ) : (
            !props.isLinked && (
              <div className="address-location">
                {props.isWarehouse ? (
                  <Fragment>
                    <div className="address-location">
                      <Button
                        className="button-seller-request"
                        onClick={() =>
                          handleOpenLinkedWarehouseOrStoreModal("warehouse")
                        }
                      >
                        Linked Warehouses
                      </Button>
                    </div>
                    <div className="address-location">
                      <Button
                        className="button-seller-request"
                        onClick={() =>
                          handleOpenLinkedWarehouseOrStoreModal("store")
                        }
                      >
                        Linked Stores
                      </Button>
                    </div>
                  </Fragment>
                ) : (
                  <Button
                    className="button-seller-request"
                    onClick={() => props.linkWarehouse(userDetails)}
                  >
                    Link Warehouse
                  </Button>
                )}
              </div>
            )
          )}

          {!props.isLinked && (
            <React.Fragment>
              <div className="address-location">
                <Button
                  className="button-seller-request"
                  onClick={() => props.viewLocations(userDetails)}
                >
                  View Service Locations
                </Button>
              </div>

              <div className="address-location">
                <Button
                  onClick={() => {
                    if (ENV_TYPE == "dev") {
                      cookies.set("switchuser", "true", {
                        path: "/",
                      });
                    } else
                      cookies.set("switchuser", "true", {
                        path: "/",
                        domain: `.${DOMAIN}`,
                      });
                    props.switchToSeller(userDetails.id);
                  }}
                  className="button-seller-view"
                >
                  {props.isWarehouse
                    ? "Switch To Warehouse View"
                    : "Switch To Store View"}
                </Button>
              </div>
              <div className="address-location">
                <Button
                  onClick={() => {
                    props.stockUpload(props.details);
                  }}
                  className="button-seller-view"
                >
                  Bulk Upload stocks
                </Button>
              </div>
            </React.Fragment>
          )}
          {props.requests && (
            <div className="address-location">
              <Button
                className="button-seller-request"
                onClick={() => props.viewRequests()}
              >
                See Request
              </Button>
            </div>
          )}
        </div>
      )}

      <LinkedWarehousesModal
        visible={linkedWarehouseListModal}
        setvisible={setlinkedWarehouseListModal}
        warehouseDetails={userDetails}
        linkWarehouse={props.linkWarehouse}
        isLinkedStores={linkedStore}
        reload={props.reload}
      />
    </div>
  );
};
export default StoreDetailsModal;
