


import React, { useEffect, useState, useContext } from "react";
import "./footer.scss";

import { Link } from "react-router-dom";
import AccountTypeContext from "../../components/Admin/accountTypeContext"
import FooterListColumn3 from "./Components/FooterList";
import ContactUs from "./Components/ContactUs";
import SocialMedia from "./Components/SocialMedia";
import PaymentOptions from "./Components/PaymentOptions";
import {
    otherLinks,


    domainLink,
} from "./FooterListJson";
import { Row, Col } from "antd";

const openInNewTab = (url) => {

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};

const fetchUrl = (url, accountTypeContext) => {


    // eslint-disable-next-line default-case
    switch (url) {
        case "about": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/aboutUs`;
        }
        case "sell": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/aboutUs`;
        }
        case "returnP": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/returnPolicy`;
        }
        case "terms": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/termsAndCondition`;
        }
        case "privacy": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/privacyPolicy`;
        }
        case "cookies": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/cookiesPolicy`;
        }
        // case "privacy": {
        //     return "AbortController";
        // }
        // case "privacy": {
        //     return "AbortController";
        // }
        default: {
            return ""
        }
    }
};




const FooterLinks = () => {
    const accountTypeContext = useContext(AccountTypeContext);
    return (
        <div style={{ borderTop: "1px solid #f1f1f1" }}>
            <Row className="footer-btn">
                <div className="footer-col-left">
                {otherLinks && otherLinks.map((i, index) => <div className="footer-btm-lft" key={index}>
                    {/* {i.link === "program" ? <div className="col" style={{'cursor':"pointer"}} onClick={() => openInNewTab("https://www.a.net/")}>
                        {i.icon}   {i.text}
                    </div> : 
                    <div className="col">
                        {i.icon} <Link to={i.link}> {i.text}</Link>
                    </div>} */}
                    <div className="col" style={{'cursor':"pointer"}} onClick={() => openInNewTab(i.link)}>
                        {i.icon}   {i.text}
                    </div>
                </div>)}
                </div>
                <div className="footer-col-right">
                <div className="footer-btm-rgt">
                    <div className="col">
                        <PaymentOptions />
                    </div>
                </div>
                </div>




            </Row>

        </div >


    );
};

export default FooterLinks;
