
import { RequestConstants } from "../constants";

import MyProfileIcon from "../../Assets/Images/Icons/Seller/myProfile.svg";
import MyProfileIconActive from "../../Assets/Images/Icons/Seller/myProfileActive.svg";

import ManageOrderIcon from "../../Assets/Images/Icons/Seller/order.svg";
import ManageOrderIconActive from "../../Assets/Images/Icons/Seller/orderActive.svg";

import ManageReturnsIcon from "../../Assets/Images/Icons/Seller/returns.svg";
import ManageReturnsIconActive from "../../Assets/Images/Icons/Seller/returnsActive.svg";

import CheckAvailiablityActiveIcon from "../../Assets/Images/Icons/sideBar/check_availibliaty_active.svg";
import CheckAvailiablityInActiveIcon from "../../Assets/Images/Icons/sideBar/check_availibliaty_inactive.svg";

import ManageCustomerIcon from "../../Assets/Images/Icons/sideBar/icons_Manage Customer.svg";
import ManageCustomerActiveIcon from "../../Assets/Images/Icons/sideBar/icons_Manage Customer _Active.svg";

import ManageProductIcon from "../../Assets/Images/Icons/sideBar/icons_Manage Product.svg";
import ManageProductActiveIcon from "../../Assets/Images/Icons/sideBar/icons_Manage Product _Active.svg";

import ManageSellerIcon from "../../Assets/Images/Icons/sideBar/icons_Manage Seller.svg";
import ManageSellerActiveIcon from "../../Assets/Images/Icons/sideBar/icons_Manage Seller _Active.svg";

import ManageStoreIcon from "../../Assets/Images/Icons/sideBar/icons_Store.svg";
import ManageStoreActiveIcon from "../../Assets/Images/Icons/sideBar/icons_Store _Active.svg";

import ManageWarehouseIcon from "../../Assets/Images/Icons/sideBar/icons_Warehouse.svg";
import ManageWarehouseActiveIcon from "../../Assets/Images/Icons/sideBar/icons_Warehouse _Active.svg";

import ManageCategoryIcon from "../../Assets/Images/Icons/sideBar/icons_Category.svg";
import ManageCategoryActiveIcon from "../../Assets/Images/Icons/sideBar/icons_Category _Active.svg";

import ManageBrandIcon from "../../Assets/Images/Icons/sideBar/icons_Brand.svg";
import ManageBrandActiveIcon from "../../Assets/Images/Icons/sideBar/icons_Brand_Active.svg";

import ManageCentralRepoIcon from "../../Assets/Images/Icons/sideBar/icons_Central Repo.svg";
import ManageCentralRepoActiveIcon from "../../Assets/Images/Icons/sideBar/icons_Central Repo_Active.svg";
import { ENV_TYPE } from "../constants";

import QuickCheckoutIcon from "../../Assets/Images/Icons/sideBar/Quickcheckout_inactive.svg";
import QuickCheckoutActiveIcon from "../../Assets/Images/Icons/sideBar/Quickcheckout_active.svg";

import VerifiedIcon from "../../Assets/Images/Icons/sideBar/icons_Verified.svg";
import VerifiedIconActive from "../../Assets/Images/Icons/sideBar/icons_Verified_Active.svg";

export const sideBarData = [
  {
    key: "profile",
    label: "My Profile",
    url: "/seller/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "manage-products",
    label: "Manage Products",
    url: "/seller/manage-products",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "central-repo",
    label: "Central repo ",
    url: "/seller/central-repo",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "replaced-products",
    label: "Replaced Products ",
    url: "/seller/replaced-products",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "verified-products",
    label: "Verified Products ",
    url: "/seller/verified-products",
    icon: VerifiedIcon,
    activeIcon: VerifiedIconActive,
    newModule: "New"
  },
  {
    key: "manage-stocks",
    label: "Manage Stocks",
    url: "/seller/manage-stocks",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "request-payment",
    label: "Request Payment",
    url: "/seller/request-payment",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "manage-category",
    label: "Platform Charge %",
    url: "/seller/manage-category",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "platform-recovery",
    label: "Platform Charge",
    url: "/seller/platform-recovery",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "barcode-search",
    label: "Search Barcode",
    url: "/seller/barcode-search",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "discount-products",
    label: "Discounted Products",
    url: "/seller/discount-products",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
    newModule: "New",
  },
  {
    key: "manage-delivery-charge",
    label: "Manage Delivery Charge",
    url: "/seller/manage-delivery-charge",
    icon: CheckAvailiablityInActiveIcon,
    activeIcon: CheckAvailiablityActiveIcon,
  },
  {
    key: "manage-orders",
    label: "Manage Orders",
    url: "/seller/manage-orders",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "manage-returns",
    label: "Manage Returns",
    url: "/seller/manage-returns",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  // {
  //   key: "online-payment",
  //   label: "Online Payment",
  //   url: "/seller/online-payment",
  //   icon: ManageReturnsIcon,
  //   activeIcon: ManageReturnsIconActive,
  // },
];

export const sideBarDataForNewSeller = [
  {
    key: "profile",
    label: "My Profile",
    url: "/seller/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
];

export const sideBarDataForPlatformBlockedVendor = [
  {
    key: "profile",
    label: "My Profile",
    url: "/store/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "central-repo",
    label: "Central repo ",
    url: "/store/central-repo",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "replaced-products",
    label: "Replaced Products ",
    url: "/store/replaced-products",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "verified-products",
    label: "Verified Products ",
    url: "/store/verified-products",
    icon: VerifiedIcon,
    activeIcon: VerifiedIconActive,
    newModule: "New"
  },
  {
    key: "manage-products",
    label: "Manage Products",
    url: "/store/manage-products",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "manage-stocks",
    label: "Manage Stocks",
    url: "/store/manage-stocks",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "reports",
    label: "Reports",
    url: "/store/reports",
  },
  {
    key: "manage-returns",
    label: "Manage Returns",
    url: "/store/manage-returns",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "check-availiablity",
    label: "Check Availability",
    url: "/store/check-availiablity",
    icon: CheckAvailiablityInActiveIcon,
    activeIcon: CheckAvailiablityActiveIcon,
  },
  {
    key: "manage-category",
    label: "Platform Charge %",
    url: "/store/manage-category",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "platform-recovery",
    label: "Platform Charge",
    url: "/store/platform-recovery",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "request-payment",
    label: "Request Payment",
    url: "/store/request-payment",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "barcode-search",
    label: "Search Barcode",
    url: "/store/barcode-search",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "discount-products",
    label: "Discounted Products",
    url: "/store/discount-products",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
    newModule: "New",
  },
  {
    key: "cart",
    label: "My Cart",
    url: "/store/cart",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "my-orders",
    label: "My Orders",
    url: "/store/my-orders",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "my-returns",
    label: "My Returns",
    url: "/store/my-returns",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  }
];

export const sideBarDataCustomer = [
  {
    key: "profile",
    label: "My Profile",
    url: ENV_TYPE === "production" ? `/profile` : "/customer/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "manage-orders",
    label: "My Orders",
    url:
      ENV_TYPE === "production"
        ? `/profile/manage-orders`
        : "/customer/profile/manage-orders",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "manage-returns",
    label: "My Returns",
    url:
      ENV_TYPE === "production"
        ? `/profile/manage-returns`
        : "/customer/profile/manage-returns",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "manage-address",
    label: "Manage Address",
    url:
      ENV_TYPE === "production"
        ? `/profile/manage-address`
        : "/customer/profile/manage-address",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
];

//Sidebar data for Store
export const sideBarDataStore = [
  {
    key: "profile",
    label: "My Profile",
    url: "/store/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "central-repo",
    label: "Central repo ",
    url: "/store/central-repo",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "replaced-products",
    label: "Replaced Products ",
    url: "/store/replaced-products",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "verified-products",
    label: "Verified Products",
    url: "/store/verified-products",
    icon: VerifiedIcon,
    activeIcon: VerifiedIconActive,
    newModule: "New"
  },
  {
    key: "manage-products",
    label: "Manage Products",
    url: "/store/manage-products",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "manage-stocks",
    label: "Manage Stocks",
    url: "/store/manage-stocks",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "quick-checkout",
    label: "Quick Checkout",
    url: "/store/quick-checkout",
    icon: QuickCheckoutIcon,
    activeIcon: QuickCheckoutActiveIcon,
  },
  {
    key: "reports",
    label: "Reports",
    url: "/store/reports",
  },
  {
    key: "manage-orders",
    label: "Manage Orders",
    url: "/store/manage-orders",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "manage-returns",
    label: "Manage Returns",
    url: "/store/manage-returns",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "check-availiablity",
    label: "Check Availability",
    url: "/store/check-availiablity",
    icon: CheckAvailiablityInActiveIcon,
    activeIcon: CheckAvailiablityActiveIcon,
  },
  {
    key: "manage-category",
    label: "Platform Charge %",
    url: "/store/manage-category",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "platform-recovery",
    label: "Platform Charge",
    url: "/store/platform-recovery",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "request-payment",
    label: "Request Payment",
    url: "/store/request-payment",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "barcode-search",
    label: "Search Barcode",
    url: "/store/barcode-search",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "discount-products",
    label: "Discounted Products",
    url: "/store/discount-products",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
    newModule: "New",
  },
  {
    key: "cart",
    label: "My Cart",
    url: "/store/cart",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "my-orders",
    label: "My Orders",
    url: "/store/my-orders",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "my-returns",
    label: "My Returns",
    url: "/store/my-returns",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  // {
  //   key: "linked-warehouse",
  //   label: "Linked Warehouse",
  //   url: "/store/linked-warehouse",
  //   icon: ManageWarehouseIcon,
  //   activeIcon: ManageWarehouseActiveIcon,
  // },

  // {
  //   key: "sales-report",
  //   label: "Sales Reports",
  //   url: "/store/sales-report",
  //   icon: ManageReturnsIcon,
  //   activeIcon: ManageReturnsIconActive,
  // },
  // {
  //   key: "stocks-report",
  //   label: "Stocks Reports",
  //   url: "/store/stocks-report",
  //   icon: ManageReturnsIcon,
  //   activeIcon: ManageReturnsIconActive,
  // },
  // {
  //   key: "expiry-report",
  //   label: "Expiry Reports",
  //   url: "/store/expiry-report",
  //   icon: ManageReturnsIcon,
  //   activeIcon: ManageReturnsIconActive,
  // },
  // {
  //   key: "online-payment",
  //   label: "Online Payment",
  //   url: "/store/online-payment",
  //   icon: ManageReturnsIcon,
  //   activeIcon: ManageReturnsIconActive,
  // },
];

//sidebar data for warehouse
export const sideBarDataWarehouse = [
  {
    key: "profile",
    label: "My Profile",
    url: "/warehouse/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "manage-category",
    label: "Manage Categories",
    url: "/admin/manage-category",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "central-repo",
    label: "Central repo ",
    url: "/warehouse/central-repo",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "manage-products",
    label: "Manage Products",
    url: "/warehouse/manage-products",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "manage-stocks",
    label: "Manage Stocks",
    url: "/warehouse/manage-stocks",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "manage-orders",
    label: "Manage Orders",
    url: "/warehouse/manage-orders",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "manage-returns",
    label: "Manage Returns",
    url: "/warehouse/manage-returns",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "check-availiablity",
    label: "Check Availability",
    url: "/warehouse/check-availiablity",
    icon: CheckAvailiablityInActiveIcon,
    activeIcon: CheckAvailiablityActiveIcon,
  },
  {
    key: "cart",
    label: "My Cart",
    url: "/warehouse/cart",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "my-orders",
    label: "My Orders",
    url: "/warehouse/my-orders",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
  },
  {
    key: "my-returns",
    label: "My Returns",
    url: "/warehouse/my-returns",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "linked-warehouse",
    label: "Linked Warehouse",
    url: "/warehouse/linked-warehouse",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "linked-stores",
    label: "Linked Stores",
    url: "/warehouse/linked-stores",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  // {
  //   key: "reports",
  //   label: "Reports",
  //   url: "#",
  //   icon: MyProfileIcon,
  //   activeIcon: MyProfileIconActive,
  // },
  {
    key: "sales-report",
    label: "Sales Reports",
    url: "/warehouse/sales-report",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "stocks-report",
    label: "Stocks Reports",
    url: "/warehouse/stocks-report",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "expiry-report",
    label: "Expiry Reports",
    url: "/warehouse/expiry-report",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
];

export const sideBarDataAdmin = [
  {
    key: "profile",
    label: "My Profile",
    url: "/admin/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "manage-customer",
    label: "Manage Customer",
    url: "/admin/manage-customer",
    icon: ManageCustomerIcon,
    activeIcon: ManageCustomerActiveIcon,
  },
  {
    key: "manage-affiliate",
    label: "Manage Affiliate",
    url: "/admin/manage-affiliate",
    icon: ManageSellerIcon,
    activeIcon: ManageSellerActiveIcon,
  },
  {
    key: "manage-seller",
    label: "Manage Seller",
    url: "/admin/manage-seller",
    icon: ManageSellerIcon,
    activeIcon: ManageSellerActiveIcon,
  },
  {
    key: "manage-store",
    label: "Manage Store",
    url: "/admin/manage-store",
    icon: ManageStoreIcon,
    activeIcon: ManageStoreActiveIcon,
  },
  {
    key: "manage-warehouse",
    label: "Manage Warehouse",
    url: "/admin/manage-warehouse",
    icon: ManageWarehouseIcon,
    activeIcon: ManageWarehouseActiveIcon,
  },
  {
    key: "manage-accounts",
    label: "Manage Accounts",
    url: "/admin/manage-accounts",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "barcode-search",
    label: "Search Barcode",
    url: "/admin/barcode-search",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "manage-platform-sellers",
    label: "Platform Charge Sellers",
    url: "/admin/manage-platform-sellers",
    icon: ManageBrandIcon,
    activeIcon: ManageBrandActiveIcon,
  },
  {
    key: "discount-products",
    label: "Discounted Products",
    url: "/admin/discount-products",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
    newModule: "New",
  },
  {
    key: "manage-affiliate-charge",
    label: "Affiliate Charge",
    url: "/admin/manage-affiliate-charge",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "manage-platform-stores",
    label: "Platform Charge Stores",
    url: "/admin/manage-platform-stores",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "manage-platform-summary",
    label: "Platform Charge Summary",
    url: "/admin/manage-platform-summary",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "request-payment",
    label: "Request Payment",
    url: "/admin/request-payment",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
    newModule: "New",
  },
  {
    key: "manage-brand",
    label: "Manage Brands",
    url: "/admin/manage-brand",
    icon: ManageBrandIcon,
    activeIcon: ManageBrandActiveIcon,
  },
  {
    key: "manage-category",
    label: "Manage Categories",
    url: "/admin/manage-category",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "manage-ads",
    label: "Manage Advertisement",
    url: "/admin/manage-ads",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "central-repo",
    label: "Central Repo",
    url: "/admin/central-repo",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "replaced-products",
    label: "Replaced Products ",
    url: "/admin/replaced-products",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
  {
    key: "verified-products",
    label: "Verified Products ",
    url: "/admin/verified-products",
    icon: VerifiedIcon,
    activeIcon: VerifiedIconActive,
    newModule: "New"
  },
  {
    key: "manage-products",
    label: "Manage Products",
    url: "/admin/manage-products",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "auto-approved-products",
    label: "Auto Approval",
    url: "/admin/auto-approval",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
  {
    key: "manage-sections",
    label: "Manage Sections",
    url: "/admin/manage-sections",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "manage-all-order",
    label: "Manage Orders",
    url: "/admin/manage-all-order",
    icon: ManageOrderIcon,
    activeIcon: ManageOrderIconActive,
    newModule: "New",
  },
  {
    key: "invoice-search",
    label: "Invoice Search",
    url: "/admin/invoice-search",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "configure",
    label: "Configure",
    url: "/admin/configure",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  {
    key: "manage-content",
    label: "Manage Content",
    url: "/admin/manage-content",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  },
  // {
  //   key: "sales-report",
  //   label: "Reports",
  //   url: "/admin/sales-report",
  //   icon: MyProfileIcon,
  //   activeIcon: MyProfileIconActive,
  // },
];

export const changeOrderStatusAuthenticatedUsers = [
  "30775a3cb587a62cb3eb7b9f286c0d3c",
  "0fb87dc7fcc968fe91af40f483fe8df4",
  "75d23af433e0cea4c0e45a56dba18b30",
  "2f5cf59ce235d9c7fc2cda58f24300a2"
];

export const designerUserIds = [
  "d3a6dbddd746caecc262041b3c09298a",
  "d5279daaf8159fa00c00a971ed82280d",
  "888a6b018b51577dbf51b72207990dd4",
  "c3288e22ee5f85ff348221cc8ea5809a",
  "0884b2fb089a629b8ffaa80c4bfe8839"
];

export const designerSideBar = [
  {
    key: "profile",
    label: "My Profile",
    url: "/admin/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "verified-products",
    label: "Verified Products ",
    url: "/admin/verified-products",
    icon: VerifiedIcon,
    activeIcon: VerifiedIconActive,
    newModule: "New"
  },
  {
    key: "central-repo",
    label: "Central Repo",
    url: "/admin/central-repo",
    icon: ManageCentralRepoIcon,
    activeIcon: ManageCentralRepoActiveIcon,
  },
];

export const sideBarDataWorkReport = [
  {
    key: "profile",
    label: "Download daily reports",
    url: "/work-report/daily-report",
    icon: ManageReturnsIcon,
    activeIcon: ManageReturnsIconActive,
  }
].concat(
  (JSON.parse(JSON.stringify(sideBarDataAdmin)).map(
    path => {
      path.url = path.url.replace("/admin", "/work-report");
      return path
  }))
)

export const sideBarDataAffiliate = [
  {
    key: "profile",
    label: "My Profile",
    url: "/affiliate/profile",
    icon: MyProfileIcon,
    activeIcon: MyProfileIconActive,
  },
  {
    key: "invoice-search",
    label: "Invoice Search",
    url: "/affiliate/invoice-search",
    icon: ManageCategoryIcon,
    activeIcon: ManageCategoryActiveIcon,
  },
  {
    key: "manage-affiliate-charge",
    label: "Affiliate Charge",
    url: "/affiliate/manage-affiliate-charge",
    icon: ManageProductIcon,
    activeIcon: ManageProductActiveIcon,
  },
];

export const responsive = {
  laptop: {
    breakpoint: { max: 1500, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  laptop15Inch: {
    breakpoint: { max: 1800, min: 1501 },
    items: 1,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1801 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const popularcategoryresponsive = {
  laptop: {
    breakpoint: { max: 1500, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  laptop15Inch: {
    breakpoint: { max: 1800, min: 1501 },
    items: 4,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1801 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export const bannersresponsive = {
  laptop: {
    breakpoint: { max: 1500, min: 1024 },
    items: 4,
    slidesToSlide: 1,
  },
  laptop15Inch: {
    breakpoint: { max: 1800, min: 1501 },
    items: 4,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1801 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};
