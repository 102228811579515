import React, { useEffect, useState} from 'react'
import LogDropDown from './LogDropDown'
import LogDateFilter from './LogDateFilter';
import { log_get_screens, log_get_actions } from '../../../../ApiAction/AdminApiAction/logApi';
import { LOG_STATUS } from '../../../../Utils/constants'

function LogFilter(props) {

    const [ dropLists ] = useState(["BLOCKED","UNBLOCKED","CREATED","DELETED"]);
    const [ screenData, setScreenData ] = useState([])
    const [ actionData, setActionData ] = useState([])
    const { setActions, setScreen, setStatus, setStartDate, setEndDate, setDownloadReady, setOffSet, setCurrentPage} = props;

    useEffect(()=> {
        //to fetch all screen name
        fetchScreenName()
        fetchActionName()
    },[])

    const fetchScreenName = () => {
        log_get_screens().then((response)=> {
            setScreenData(response?.data?.screens)
        }).catch((error)=> {
            console.log("Log Screen response error ::", error.message)
        })
    }

    const fetchActionName = () => {
        log_get_actions().then((response)=> {
            setActionData(response?.data?.actions)
        }).catch((error)=> {
            console.log("Log Action response error ::", error.message)
        });
    }


    return (
        <div className="LogFilter-main-container">
            <LogDropDown title={"ACTIONS"} data={actionData} functionName={setActions} setOffSet={setOffSet} setCurrentPage={setCurrentPage} />
            <LogDropDown title={"SCREENS"} data={screenData} functionName={setScreen} setOffSet={setOffSet} setCurrentPage={setCurrentPage} />
            <LogDropDown title={"STATUS"} data={LOG_STATUS} functionName={setStatus} setOffSet={setOffSet} setCurrentPage={setCurrentPage} />
            <LogDateFilter setStartDate={setStartDate} setEndDate={setEndDate} downloadReady={setDownloadReady} setOffSet={setOffSet} setCurrentPage={setCurrentPage}  />
        </div>
    )
}

export default LogFilter
