


import React from "react";

import "antd/dist/antd.css";
import { Modal } from "antd";

import { Progress } from "antd";
import LoadingGif from "../../../../Assets/Images/Icons/loading.gif";

const Loader = (props) => {
  const [percent, setPercentage] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(
      () => {
        if (props.isLoadingDownload === false) {
          setPercentage(100);
        }
        setPercentage((prevCount) => prevCount + 4);
      },
      props.isLoadingDownload === true ? 100 : 3000
    );
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div>
      <Modal title="" footer={null} centered closable={false} visible={true}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img style={{ width: "200px" }} src={LoadingGif} alt="loading_gif" />
          {props.quickCheckout ? (
            <h4 style={{ color: "gray" }}>Loading</h4>
          ) : (
            <h4 style={{ color: "gray" }}>
              Generating downloading report may take longer depend on data volume.
            </h4>
          )}
        </div>
        {/* <Progress percent={percent} /> */}
      </Modal>
    </div>
  );
};

export default Loader;
