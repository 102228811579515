

import React, { useEffect, useState } from "react";
import { Row, Col, Select, Input, Modal, Typography, message } from "antd";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  getCategory,
  getSubCategory,
  getCategoryBrand,
} from "../../../../ApiAction/AdminApiAction/adminApi";
import AddNewSpecification from "./addNewSpecification";
const { Option } = Select;
const { Text } = Typography;
const AddProductCategory = (props) => {
  const confirm = Modal.confirm;
  const {
    centralRepo,
    disableEditCentralData,
    setminCategoryDiscount,
    setcategoryCommission,
    formItem
  } = props;
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListLevel0, setCategoryListLevel0] = useState([]);
  const [categoryListLevel1, setCategoryListLevel1] = useState([]);
  const [categoryListLevel2, setCategoryListLevel2] = useState([]);
  const [categoryListLevel3, setCategoryListLevel3] = useState([]);
  const [specificationsList, setSpecificationsList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [specificationModal, setSpecificationModal] = useState(false);

  const [isEditSecifications, setisEditSecifications] = useState(false);
  const [selectedSpecification, setselectedSpecification] = useState();

  useEffect(() => {
    const payload = {
      key: "",
      offset: 0,
      size: 20,
      level: -1,
    };
    getCategory(payload)
      .then((response) => {
        setCategoryList(response.data.category);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    setSpecificationsList(props.specificationsList);
  }, [props.specificationsList]);

  const handleSetCategoryData = (categorylist, categoryId) => {
    categorylist.map((data) => {
      if (data.id === categoryId) {
        data?.source?.categoryCommission &&
          setcategoryCommission(data.source.categoryCommission);
        data?.source?.minCategoryDiscount &&
          setminCategoryDiscount(data.source.minCategoryDiscount);
      }
    });
  };
  const getSubCategoryList = (id, level) => {
    getSubCategory(id, level)
      .then((response) => {
        if (level === 0) setCategoryListLevel0(response.data.category);
        if (level === 1) {
          setCategoryListLevel1(response.data.category);
        }

        if (level === 2) setCategoryListLevel2(response.data.category);
        if (level === 3) setCategoryListLevel3(response.data.category);

        const feturedObject =
          level === 2
            ? categoryListLevel1.find((item) => item.id === id)
            : categoryListLevel2.find((item) => item.id === id);
        const features = feturedObject.source.categoryFeature.map((item) => {
          const subFeature = item.subFeature.map((item) => {
            const singleItem = {
              key: item.feature,
              value: "",
            };
            return singleItem;
          });
          return subFeature;
        });
        const result = features.reduce((r, e) => (r.push(...e), r), []);
        setSpecificationsList(result);
      })
      .catch(() => {});
    getBrand(id);
  };

  const setSuccess = (value) => {
    if (specificationsList.find((data) => data.key === value.key)) {
      message.warning("Duplicate specifications not allowed");
      return false;
    } else {
      setSpecificationModal(false);
      setSpecificationsList([...specificationsList, value]);
      props.setSpecificationsList([...specificationsList, value]);
      return true;
    }
  };
  const getBrand = (id) => {
    getCategoryBrand(id)
      .then((response) => {
        setBrandList(response.data.brand);
      })
      .catch(() => {});
  };

  const handleTrigerEditSpecification = (data) => {
    setisEditSecifications(true);
    setselectedSpecification(data);
    setSpecificationModal(true);
  };

  const handleEditSpecification = (initialData, updatedData) => {
    props.setSpecificationsList(
      specificationsList.map((item) => {
        if (item.key === initialData.key)
          return { key: updatedData.key, value: updatedData.value };
        else return item;
      })
    );
    setSpecificationModal(false);
  };

  const handleDeleteSpecification = (data) => {
    confirm({
      title: "Do you want to delete this specification!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        props.setSpecificationsList(
          specificationsList.filter((item) => {
            return item.key !== data.key;
          })
        );
      },
    });
  };

  const updateKey = (key, value) => {
    props.setSpecificationsList(
      specificationsList.map((item) =>
        item.key === key ? { ...item, value: value } : item
      )
    );
  };
  return (
    <React.Fragment>
      <div className="add-product-section-content">
        <div className="add-product-section-title">Category & Subcategory</div>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="add-image-col"
          >
            <div className="add-product-tile">
              ** Type
              <Select
                disabled={disableEditCentralData}
                onSelect={(value, option, info) => {
                  props.setCategoryId(option.key);
                  props.setCategoryName(option.value);
                  getSubCategoryList(option.key, 0);
                  handleSetCategoryData(categoryList, option.key);

                  props.setSubCategory0Id("");
                  props.setSubCategory0Name("");
                  props.setSubCategory1Id("");
                  props.setSubCategory1Name("");
                  props.setSubCategory2Id("");
                  props.setSubCategory2Name("");
                  props.setSubCategory3Id("");
                  props.setSubCategory3Name("");
                  props.setBrandId("");
                  props.setBrandName("");
                }}
                onChange={(value, info) => console.log(info)}
                value={props.categoryName}
                style={{ width: "100%" }}
              >
                {categoryList &&
                  categoryList.map((item, index) => {
                    return (
                      <Option
                        key={item.source.id}
                        value={item.source.categoryName}
                        defaultValue={props.categoryId}
                      >
                        {item.source.categoryName}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="add-image-col"
          >
            <div className="add-product-tile">
              ** Category
              <Select
                disabled={disableEditCentralData}
                onSelect={(value, option, info) => {
                  props.setSubCategory0Id(option.key);
                  props.setSubCategory0Name(option.value);
                  getSubCategoryList(option.key, 1);
                  handleSetCategoryData(categoryList, option.key);

                  props.setSubCategory1Id("");
                  props.setSubCategory1Name("");
                  props.setSubCategory2Id("");
                  props.setSubCategory2Name("");
                  props.setSubCategory3Id("");
                  props.setSubCategory3Name("");
                  props.setBrandId("");
                  props.setBrandName("");
                }}
                onChange={(value, info) => console.log(info)}
                value={props.subCategory0Name}
                style={{ width: "100%" }}
              >
                {categoryListLevel0 &&
                  categoryListLevel0.map((item, index) => {
                    return (
                      <Option
                        key={item.source.id}
                        value={item.source.categoryName}
                        defaultValue={props.categoryId}
                      >
                        {item.source.categoryName}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="add-image-col"
          >
            <div className="add-product-tile">
              ** SubCategory
              <Select
                disabled={disableEditCentralData}
                onSelect={(value, option) => {
                  props.setSubCategory1Id(option.key);
                  props.setSubCategory1Name(option.value);
                  getSubCategoryList(option.key, 2);
                  handleSetCategoryData(categoryListLevel1, option.key);

                  props.setSubCategory2Id("");
                  props.setSubCategory2Name("");
                  props.setSubCategory3Id("");
                  props.setSubCategory3Name("");
                  props.setBrandId("");
                  props.setBrandName("");
                }}
                value={props.subCategory1Name}
                style={{ width: "100%" }}
              >
                {categoryListLevel1 &&
                  categoryListLevel1.map((item, index) => {
                    return (
                      <Option
                        key={item.source.id}
                        value={item.source.categoryName}
                      >
                        {item.source.categoryName}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </Col>
        </Row>
        {(categoryListLevel2.length > 0 || props.subCategory2Name) && (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="add-image-col"
            >
              <div className="add-product-tile">
                SubCategory
                <Select
                  disabled={disableEditCentralData}
                  onSelect={(value, option) => {
                    props.setSubCategory2Id(option.key);
                    props.setSubCategory2Name(option.value);
                    getSubCategoryList(option.key, 3);
                    handleSetCategoryData(categoryListLevel2, option.key);

                    props.setSubCategory3Id("");
                    props.setSubCategory3Name("");
                    props.setBrandId("");
                    props.setBrandName("");
                  }}
                  value={props.subCategory2Name}
                  style={{ width: "100%" }}
                >
                  {categoryListLevel2 &&
                    categoryListLevel2.map((item, index) => {
                      return (
                        <Option
                          key={item.source.id}
                          value={item.source.categoryName}
                        >
                          {item.source.categoryName}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </Col>
            {(categoryListLevel3.length > 0 || props.subCategory3Name) && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="add-image-col"
              >
                <div className="add-product-tile">
                  SubCategory
                  <Select
                    disabled={disableEditCentralData}
                    onSelect={(value, option) => {
                      props.setSubCategory3Id(option.key);
                      props.setSubCategory3Name(option.value);
                      getBrand(option.key);
                      handleSetCategoryData(categoryListLevel3, option.key);
                    }}
                    value={props.subCategory3Name}
                    style={{ width: "100%" }}
                  >
                    {categoryListLevel3 &&
                      categoryListLevel3.map((item, index) => {
                        return (
                          <Option
                            key={item.source.id}
                            value={item.source.categoryName}
                          >
                            {item.source.categoryName}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </Col>
            )}
          </Row>
        )}

        <Row>
          <Col span={24} className="add-image-col">
            <div className="add-product-tile">
              Select Brand
              <Select
                showSearch
                disabled={disableEditCentralData}
                onSelect={(value, option) => {
                  props.setBrandId(option.key);
                  props.setBrandName(option.value);
                }}
                value={props.brandName}
                style={{ width: "100%" }}
              >
                {brandList &&
                  brandList.map((item, index) => {
                    return (
                      <Option
                        key={item.source.brandId}
                        value={item.source.brandName}
                      >
                        {item.source.brandName}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </Col>
        </Row>
      </div>
      {!centralRepo && (
        <div className="add-product-section-content">
          <div className="add-product-section-title">Specifications</div>
          <Row>
            {specificationsList?.map((item) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="add-image-col"
              >
                <div className="add-product-tile">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="specs_listing_induvidual">
                      <Text strong>{item.key}</Text> {" : "} {item.value}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "50px",
                      }}
                    >
                      <EditFilled
                        className="edit_icon_spec"
                        onClick={() => handleTrigerEditSpecification(item)}
                      />
                      <DeleteFilled
                        className="delete_icon_spec"
                        onClick={() => handleDeleteSpecification(item)}
                      />
                    </div>

                    {/* <div style={{cursor:"pointer"}} 
    // onClick={() => setServiceModal(true)}
    >plus</div> */}
                  </div>
                  {/* <Input
                    value={item.value}
                    onChange={(e) => updateKey(item.key, e.target.value)}
                  /> */}
                </div>
              </Col>
            ))}
          </Row>
          <div
            className="minimum-tags"
            onClick={(e) => {
              setSpecificationModal(true);
              setisEditSecifications(false);
            }}
          >
            +Add new specification
          </div>
        </div>
      )}

      <AddNewSpecification
        visible={specificationModal}
        setCancel={setSpecificationModal}
        setSuccess={setSuccess}
        data={selectedSpecification}
        isEdit={isEditSecifications}
        handleEditSpecification={handleEditSpecification}
      />
    </React.Fragment>
  );
};
export default AddProductCategory;
