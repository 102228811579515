

import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { AiOutlineCreditCard } from "react-icons/ai";
import {
  Typography,
  Pagination,
  Row,
  Col,
  Collapse,
  Button,
  message,
  Checkbox,
  Tooltip,
  Modal,
  Card,
  Divider,
} from "antd";
import { connect } from "react-redux";
import "./manageOrder.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  CaretRightOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import onlineIcon from "../../../Assets/Images/Icons/Seller/online-shopping.svg";

import OnlineIcon from "../../../Assets/Images/Icons/online_mode.svg";
import CODIcon from "../../../Assets/Images/Icons/cod_mode.svg";
import calendarIcon from "../../../Assets/Images/Icons/Seller/calendar.svg";
import locationIcon from "../../../Assets/Images/Icons/Seller/location.svg";
import {
  getCustomerOrders,
  getProducts,
  updateOrderStatus,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import Cookies from "universal-cookie";
import OrderData from "./orderData";
import TrackingDetails from "./trackingDetails";
import SellerInvoice from "./sellerInvoice";
import Loading from "../../GeneralComponents/Loading";
import { CancelOrderItem } from "../../../ApiAction/CustomerApiActions/orderAPI";
import GenerateEwayBill from "./GenerateEwayBill";
import { excludeDeliveryCharge } from "../../../ApiAction/SellerApiActions/deliveryChargeAPI";
import {
  cancelOrderGroup,
  confirmOrderGroup,
  downloadOrderPickList,
  editVendorOrder,
  changeStatusOfOrderGroup
} from "../../../ApiAction/SellerApiActions/vendoOrderAPI";
import {
  clearNewItemAllOrder,
  clearNewItemAPI,
} from "../../../ApiAction/SellerApiActions/clearNewItemAPI";
import EmptyList from "../../GeneralComponents/emptyList";
import CancelOrderRequest from "../../Customer/Components/ManageOrders/Components/cancelOrderRequest";
import ThermalIndex from "../../Customer/Components/ManageOrders/Components/ThermalPrinter/ThermalIndex";
import { fetchAllOrdersAdmin } from "../../../ApiAction/AdminApiAction/adminApi";
import { MasterCard } from "../../../IconList/IconList";
import { BiRadio } from "react-icons/bi";
import TaxInvoice from "../../Customer/Components/ManageOrders/Components/TaxInvoice";
import { formatOrderSummary } from "../../Customer/Components/ManageOrders/manageOrdersHelper";
import { changeOrderStatusAuthenticatedUsers } from "../../../Utils/Seller/Data";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const ManageAllOrderGroups = (props) => {
  const cookies = new Cookies();
  const { activeTab } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentpage, setCurrentPage] = useState(1);

  const [cancelOrderModal, setCancelOrderVisible] = useState(false);
  const [confirmOrderModal, setConfirmOrderVisible] = useState(false);
  const [generateEwayBillVisible, setgenerateEwayBillVisible] = useState(false);
  const [activeOrder, setactiveOrder] = useState();

  const [isActivePanel, setActive] = useState("");
  const [trackingModal, setTrackingModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [thermalModal, setThermalModal] = useState(false);
  const [invoiceItem, setInvoiceItem] = useState();
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isloadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [supplier, setSupplier] = useState([]);

  const [orderGroupId, setOrderGroupId] = useState("");
  const [confirmOrderGroupId, setConfirmOrderGroupId] = useState("");
  const [orderedItemsOfVendor, setOrderedItemsOfVendor] = useState("");
  const [itemCount, setItemCount] = useState("");
  const [accountType, setAccountType] = useState("");
  const [oderId, setOrderId] = useState("");
  const [selectionType, setSelectionType] = useState("");
  const [isSelectallChecked, setisSelectallChecked] = useState(false);

  const [isloadingExcludeDeliveryCharge, setIsloadingExcludeDeliveryCharge] =
    useState(false);

  const pickOrderByHandStates = ["PENDING", "INPROGRESS"];
  useEffect(() => {
    getProductsList(props.searchVal, 1);
  }, [props]);

  //checking
  useEffect(() => {
    console.log("PROD LENG:::" + productList?.length);
  }, [productList]);
  useEffect(() => {
    getProductsList(props.searchVal, 1);
  }, [activeTab]);

  const getValue = (value, last) => {
    if (value !== null) return value + ", ";
    else return "";
  };

  const calculateTotal = (total, number) => {
    return total + number?.totalAmount;
  };

  const calculateTotalDiscount = (total, number) => {
    return (
      total +
      (number.itemCurrentPrice?.price - number.itemCurrentPrice?.sellingPrice)
    );
  };

  //to get the sellers and stores
  const getStores = (getStore, allData) => {
    let vendorName = [];
    getStore.forEach((agency) => {
      let letsInclude = vendorName.filter((data) => {
        if (data.vendorName === agency.vendor) {
          return data;
        } else {
          return false;
        }
      });
      if (letsInclude.length === 0) {
        let items = getStore?.filter((item) => item.vendor === agency.vendor && item.itemOrderStatus?.currentStatus !== 'CANCELLED');
        let tempData = {
          accountType: allData?.accountType,
          affiliateId: "",
          allItemShipped: allData?.orderStatus === "COMPLETED" ? true : false,
          appType: "",
          bagTotal: allData?.bagTotal,
          cartId: "",
          customerCode: allData?.customerCode,
          customerEmail: allData?.customerEmail,
          customerId: allData?.customerId,
          customerName: allData?.customerName,
          deliveryCharge: agency?.vendorDeliveryCharge,
          ewayBillNumber: allData?.ewayBillNumber,
          gstNumber: allData?.gstNumber,
          orderGroupId: allData?.orderGroupId,
          orderStatus: allData?.orderStatus,
          orderViewed: allData?.orderViewed,
          orderedDate: allData?.orderedDate,
          orderedItemsOfVendor: items,
          overallOrderStatus: allData?.overallOrderStatus,
          paymentMode: allData?.paymentMode,
          paymentStatus: allData?.paymentStatus,
          razorPayCustomerId: allData?.razorPayCustomerId,
          razorPayOrderId: allData?.razorPayOrderId,
          razorPayPaymentId: allData?.razorPayPaymentId,
          referralId: allData?.referralId,
          shippingAddress: allData?.shippingAddress,
          subTotal: allData?.subTotal,
          taxPerCent: allData?.taxPerCent,
          // taxSummaries: allData?.taxSummaries,
          totalDiscount: items.reduce(calculateTotalDiscount, 0),
          totalPrice:
            items.reduce(calculateTotal, 0) + agency?.vendorDeliveryCharge,
          totalTax: allData?.totalTax,
          vendor: agency?.vendor,
          vendorAddress: agency?.vendorAddress,
          vendorDeliveryChargeMap: allData?.vendorDeliveryChargeMap,
          vendorGstNumber: agency?.vendorGstNumber,
          vendorIdAddressMap: allData?.vendorIdAddressMap,
          vendorOrderStatuses: allData?.vendorOrderStatuses,
          vendorType: agency?.vendorType,
          windowType: agency?.windowType,
        };
        let tempObj = {
          vendorName: agency.vendor,
          data: tempData,
          orderStatus: items[0]?.itemOrderStatus?.currentStatus,
        };
        vendorName.push(tempObj);
      }
    });
    return vendorName.map((vendor) => (
      <>
        <Text
          onClick={() => {
            if (vendor?.orderStatus === "CANCELLED") {
              message.error("Sorry this invoice is cancelled...");
            } else {
              setInvoiceItem(formatOrderSummary(vendor?.data));
              setInvoiceModal(true);
            }
          }}
          className="all-vendor-names"
        >
          {"-> " + vendor?.vendorName}
        </Text>
        <br />
      </>
    ));
  };

  const getValueWithFormat = (data) => {
    if (data) {
      const filterArray = [
        // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
        data.ownerName,
        data.addressLine1,
        data.buildingName,
        data.pinCode,
      ];
      console.log("data??", filterArray);

      const filtered = filterArray.filter(function (el) {
        return el != null;
      });
      const filteredNullStringValues = filtered.filter(Boolean);

      return (
        filteredNullStringValues &&
        filteredNullStringValues.toString().split(",").join(", ")
      );
    }
  };

  const activeCustomer = JSON.parse(localStorage.getItem("activeCustomer"));
  const activeCustomerType = activeCustomer.accountType;

  const handleSelectAllProducts = async (e, orderData) => {
    const currentStatus = orderData.source.orderedItemsOfVendor.find(
      (product) => {
        return (
          product.itemOrderStatus?.currentStatus !== "CANCELLED" &&
          product.itemOrderStatus?.currentStatus !== "DELIVERED"
        );
      }
    );
    console.log("current status:", currentStatus);
    const crossCheckStatus = await orderData.source.orderedItemsOfVendor.find(
      (product) => {
        return (
          product.itemOrderStatus?.currentStatus !==
            currentStatus?.itemOrderStatus?.currentStatus &&
          product.itemOrderStatus?.currentStatus !== "CANCELLED"
        );
      }
    );
    if (crossCheckStatus) {
      setisSelectallChecked(false);
      message.error(
        "Some Products in the order are ahead of status, therefore you wont be able to sellect all."
      );
    } else {
      if (currentStatus === "DELIVERED") {
        message.warning("The order has been delivered already");
      } else if (currentStatus === "CANCELLED") {
        message.warning("The order has been canceled already");
      } else if (currentStatus === "RETURN_REQUESTED") {
        message.warning("The order has been requested for return already");
      } else {
        setisSelectallChecked(e.target.checked);
        if (e.target.checked) {
          const data = orderData.source.orderedItemsOfVendor.filter((item) => {
            return item.itemOrderStatus.currentStatus !== "CANCELLED";
          });

          console.log(data, ": selected Items");
          setSelectedItems(data);
          setOrderId(orderData.id);
          setOrderGroupId(orderData.source.orderGroupId);
          console.log(orderData, ": order data");
        } else {
          setSelectedItems([]);
        }
      }
    }
  };

  const getProductsList = (
    search = props.searchVal,
    page = 1,
    isFromUpdate = false
  ) => {
    setCurrentPage(page);
    setIsLoading(true);
    const term = [];
    term.push({
      vendorId: [cookies.get("vendorId")],
    });
    // console.log("ENTERD THE PAY MODE :::" + props.sort.paymentMode);
    // console.log("ENTERD THE STATUS :::" + props.sort.itemOrderStatus);

    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      userId: cookies.get("vendorId"),
      key: search,
      paymentMode:
        props.sort.paymentMode === undefined ? "ALL" : props.sort.paymentMode,
      sortBy:
        props.sort.sortBy === undefined ? "orderedDate" : props.sort.sortBy,
      sortType:
        props.sort.sortType === undefined ? "desc" : props.sort.sortType,
      status: activeTab,
      vendorId: props.vendorId,
    };
    if (props.sort) {
      if (props.sort.paymentMode !== "ALL") {
        payload.paymentMode = props.sort.paymentMode;
        payload.accountType = props.sort.orderFrom;
      }
    }

    if (props.delivered) {
      payload.itemOrderStatus = "DELIVERED";
    } else if (props.sort && props.sort.itemOrderStatus != "All") {
      payload.itemOrderStatus = props.sort.itemOrderStatus;
    }
    if (props.sort && props.sort.endTime) {
      payload.endTime = props.sort.endTime;
    }
    if (props.sort && props.sort.startTime) {
      payload.startTime = props.sort.startTime;
    }
    fetchAllOrdersAdmin(payload)
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.status == "success") {
          console.log("CALLING");
          console.log("COUNT IS:::" + response?.data?.count);
          setProductList(response?.data?.orders);

          if (isFromUpdate) {
            setActive(isActivePanel);
          } else {
            if (response.data.orders && response.data.orders.length > 0) {
              //    setActive(response.data.orders[0].id);
            }
          }
          setTotal(Math.ceil(response.data.count / 10) * 10);
        } else {
          setProductList([]);
          setTotal(0);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getProductsListAfterAccept = (search = "", page = 1, currentVal) => {
    setCurrentPage(page);
    // setIsLoading(true);
    const term = [];
    term.push({
      vendorId: [cookies.get("vendorId")],
    });

    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      userId: cookies.get("vendorId"),
      key: search,
      paymentMode:
        props.sort.paymentMode === undefined ? "ALL" : props.sort.paymentMode,
      sortBy:
        props.sort.sortBy === undefined ? "orderedDate" : props.sort.sortBy,
      sortType:
        props.sort.sortType === undefined ? "desc" : props.sort.sortType,
      status: activeTab,
      vendorId: props.vendorId,
    };
    if (props.sort) {
      if (props.sort.paymentMode != "ALL") {
        console.log("ENTERD THE PAY MODE :::" + props.sort.paymentMode);
        console.log("ENTERD THE STATUS :::" + props.sort.itemOrderStatus);
        payload.paymentMode = props.sort.paymentMode;
        payload.accountType = props.sort.orderFrom;
      } else if (
        props.sort.paymentMode === undefined &&
        props.sort.itemOrderStatus === "NOT_CONFIRMED"
      ) {
        payload.paymentMode = "ONLINE";
      }
    }

    if (props.delivered) {
      payload.itemOrderStatus = "DELIVERED";
    } else if (props.sort && props.sort.itemOrderStatus != "ALL") {
      payload.itemOrderStatus = props.sort.itemOrderStatus;
    }
    if (props.sort && props.sort.endTime) {
      payload.endTime = props.sort.endTime;
    }
    if (props.sort && props.sort.startTime) {
      payload.startTime = props.sort.startTime;
    }
    fetchAllOrdersAdmin(payload)
      .then((response) => {
        // setIsLoading(false);
        if (response.data.status == "success") {
          setProductList(response.data.orders);
          console.log("PAYMENT TYPE::: AFTER::::" + payload.paymentMode);
          // setActive(currentVal);
          setTotal(Math.ceil(response.data.count / 10) * 10);
        } else {
          setProductList([]);
          setTotal(0);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  // const updateStatus = (item, id, groupId, accountType) => {
  //   setSelectedItems([item]);
  //   setOrderId(id);
  //   setOrderGroupId(groupId);
  //   setAccountType(accountType);
  //   setTrackingModal(true);
  //   setSelectionType("single");
  // };
  // const setCancel = (value) => {
  //   setTrackingModal(value);
  //   if (selectionType === "single") {
  //     setSelectedItems([]);
  //   }
  // };
  // const checkBoxSelection = (value, item, id, groupId) => {
  //   setOrderId(id);
  //   setOrderGroupId(groupId);
  //   if (value) {
  //     setSelectedItems([...selectedItems, item]);
  //   } else {
  //     if (item)
  //       setSelectedItems(selectedItems.filter((data) => data.id != item.id));
  //   }
  // };
  // const setCancelOrder = (payload) => {
  //   CancelOrderItem(payload)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         if (response.data.status === "success") {
  //           message.success("Item canceled succefully");
  //           setProductList([]);
  //           getProductsList(
  //             props?.searchVal ? props.searchVal : "",
  //             currentpage,
  //             true
  //           );
  //         }
  //       }
  //     })
  //     .catch((error) => {})
  //     .finally(() => {});
  // };

  // const handleEditVendorOrder = (payload) => {
  //   editVendorOrder(payload).then((response) => {
  //     if (response.status === 200) {
  //       if (response.data.status === "success") {
  //         message.success(response.data.message);
  //         console.log(response, ":response for edit order");
  //         setProductList([]);
  //         getProductsList("", currentpage, true);
  //       } else {
  //         message.error(response.data.message);
  //       }
  //     } else message.error("Some thing went wrong please try again later.");
  //   });
  // };

  // const handleExcludeDeliveryCharge = (orderGroupId) => {
  //   setIsloadingExcludeDeliveryCharge(true);
  //   excludeDeliveryCharge(orderGroupId)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         if (response.data.status === "success") {
  //           message.success("Delivery Charge excluded Successfully...");
  //           getProductsList();
  //         } else {
  //           message.error(response.data.message);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       message.error("Sorry! Something went wrong. Please try again later!");
  //     })
  //     .finally(() => {
  //       setIsloadingExcludeDeliveryCharge(false);
  //     });
  // };

  // const handleDownload = (orderGroupId) => {
  //   const payload = {
  //     orderGroupId,
  //   };
  //   setIsLoadingDownload(true);
  //   downloadOrderPickList(payload)
  //     .then((response) => {
  //       console.log(response, "response for order pick-list excel sheet");
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `order_pickList.xlsx`);
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch((error) =>
  //       message.error("Sorry! Something went wrong. Please try again later!")
  //     )
  //     .finally(() => {
  //       setIsLoadingDownload(false);
  //     });
  // };

  const handleCancelOrder = (reason = "") => {
    setIsLoadingCancel(true);
    const orderIds = orderedItemsOfVendor.map((item) => item.orderId);
    console.log("Order Id Array : ", orderIds);
    const payload = {
      orderGroupId,
      orderIds,
      reasonOfCancellation: reason,
    };
    console.log("Cancel Payload : ", payload);
    cancelOrderGroup(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success("Order has been cancelled");
            getProductsList();
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        message.error("Sorry! Something went wrong. Please try again later!");
      })
      .finally(() => {
        setIsLoadingCancel(false);
      });
  };

  const renderConfirmCancelOrderModal = (reason = "") => {
    setCancelOrderVisible(false);
    Modal.confirm({
      title: "Confirm",
      width: 425,
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to cancel the whole order ? `,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleCancelOrder(reason),
      onCancel: () => {
        console.log("Cancel Order Group Discared!");
      },
    });
  };

  //api confirm order
  const handleConfirmOrder = (orderGroupId) => {
    setIsLoadingConfirm(true);
    console.log("GROUP ID::" + orderGroupId);
    const payload = {
      orderGroupId,
    };
    console.log("Confirm Payload : ", payload);
    confirmOrderGroup(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success("Order has been approved");
            getProductsList();
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        message.error("Sorry! Something went wrong. Please try again later!");
      })
      .finally(() => {
        setIsLoadingConfirm(false);
      });
  };

  //render confirm the order approve
  const renderConfirmOrderModal = (orderGroupId) => {
    console.log("ORDER ID INSIDE MODAL:::" + orderGroupId);
    // setConfirmOrderVisible(false);
    Modal.confirm({
      title: "Confirm",
      width: 425,
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to confirm the whole order ? `,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleConfirmOrder(orderGroupId),
      onCancel: () => {
        console.log("Confirm Order Group Discared!");
      },
    });
  };

  const handleCancelOrderGroup = (orderGroupId, orderedItemsOfVendor) => {
    setOrderGroupId(orderGroupId);
    setOrderedItemsOfVendor(orderedItemsOfVendor);
    setCancelOrderVisible(true);
  };

  //confirm order to store
  const handleConfirmOrderGroup = (orderGroupId) => {
    setConfirmOrderGroupId(orderGroupId);
    renderConfirmOrderModal(orderGroupId);
  };

  const changeOrderStatus = (orderGroupId) => {
    const payload = {
      orderGroupId
    }
    changeStatusOfOrderGroup(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success("Status of order has changed");
            getProductsList();
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        message.error("Sorry! Something went wrong. Please try again later!");
      })
      .finally(() => {
        setIsLoadingConfirm(false);
      });
  }

  const handleChangeOrderStatus = (source) => {
    console.log(source);
    setOrderGroupId(source.orderGroupId)
    Modal.confirm({
      title: "Confirm",
      width: 425,
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to change the status of order ? `,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => changeOrderStatus(source.orderGroupId),
      onCancel: () => {
        console.log("Change Status Of Order Discarded!");
      },
    });
  }

  // const renderConfirmModal = (orderGroupId) => {
  //   Modal.confirm({
  //     title: "Confirm",
  //     width: 480,
  //     className: "manage-category-confirm-modal",
  //     icon: <ExclamationCircleOutlined />,
  //     content: `Are you sure you want to opt out of delivery for this order? Note** This action will be irreversible!`,
  //     okText: "Confirm",
  //     cancelText: "Cancel",
  //     onOk: () => handleExcludeDeliveryCharge(orderGroupId),
  //     onCancel: () => {
  //       console.log("Exclude Delivery Cancelled!");
  //     },
  //   });
  // };

  // const setSuccess = (
  //   prdId,
  //   status,
  //   accountUserType,
  //   trackId,
  //   trackLink,
  //   mobile,
  //   name,
  //   regNo
  // ) => {
  //   setTrackingModal(false);
  //   const payload = {
  //     orderGroupId: orderGroupId,
  //     orderIds: prdId,
  //     itemOrderStatus: status,
  //     date: new Date().getTime(),
  //   };
  //   const payloadShipped = {
  //     orderGroupId: orderGroupId,
  //     orderIds: prdId,
  //     itemOrderStatus: status,
  //     date: new Date().getTime(),
  //     // trackingId: trackId,
  //     // trackingLink: trackLink
  //   };
  //   if (accountUserType == null || accountUserType == "customer") {
  //     payloadShipped["trackingId"] = trackId;
  //     payloadShipped["trackingLink"] = trackLink;
  //   } else {
  //     payloadShipped["driverNumber"] = mobile;
  //     payloadShipped["driverName"] = name;
  //     payloadShipped["vehicleNumber"] = regNo;
  //   }

  //   updateOrderStatus(status === "SHIPPED" ? payloadShipped : payload)
  //     .then((response) => {
  //       if (response.data.status == "success") {
  //         setProductList([]);

  //         getProductsList(
  //           props?.searchVal ? props.searchVal : "",
  //           currentpage,
  //           true
  //         );
  //         setSelectedItems([]);
  //         setisSelectallChecked(false);
  //         // setProductList(productList.map(item=>
  //         //   item.source.orderGroupId==orderGroupId?{...item,source:response.data.order}:item))
  //       }
  //     })
  //     .catch(() => {});
  // };

  const clearNewItemsAdminAllOrder = (orderId, isAlreadyViewed, activeId) => {
    console.log("orderId", orderId);

    if (!isAlreadyViewed) {
      const payload = {
        orderGroupId: orderId,
      };
      clearNewItemAllOrder(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            if (response.data.message) {
              message.success(response.data.message);
            }

            console.log(response, ":response for edit order");
            // setProductList([]);
            getProductsListAfterAccept(
              props?.searchVal ? props.searchVal : "",
              currentpage,
              response.data.id
            );
            //  setActive(activeId?activeId:"")
          } else {
            if (response.data.message) {
              message.error(response.data.message);
            }
          }
        } else message.error("Some thing went wrong please try again later.");
      });
    }
  };

  return (
    <div className="manage-products-container">
      {" "}
      {/* <div className="manage-products-header">
        {" "} <Title level={4}> Orders</Title>

      </div> */}
      {isLoading ? (
        <Loading />
      ) : productList?.length === 0 ? (
        <Title level={4} className="order-shipped-date">
          {" "}
          <EmptyList msg={"No Orders found"} />
        </Title>
      ) : (
        productList?.map((item) => (
          <Collapse
            bordered={false}
            activeKey={[isActivePanel]}
            accordion
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                rotate={isActive ? 90 : 0}
                onClick={(event) => {
                  console.log("IDDDD:::" + item?.id);
                  console.log("ACTIVE PANEL:::" + isActivePanel);
                  // event.preventDefault();
                  clearNewItemsAdminAllOrder(
                    item.source.orderGroupId,
                    item.source.overallOrderStatus,
                    item.id
                  );
                }}
              />
            )}
            className="stock-collapse"
            onChange={() => {
              setItemCount(item?.source?.orderedItems?.length);
              // console.log("Expanded : ", itemCount);
              setSelectedItems([]);
              setChecked(!checked);
              setisSelectallChecked(false);
              isActivePanel === item.id ? setActive() : setActive(item?.id);
            }}
            style={{ marginTop: "20px", background: "#f2f2f2" }}
          >
            <Panel
              className="site-collapse-custom-panel"
              key={item.id}
              onClick={(event) => {
                // event.preventDefault();
                clearNewItemsAdminAllOrder(
                  item.source.orderGroupId,
                  item.source.overallOrderStatus,
                  item.id
                );
              }}
              style={{
                backgroundColor:
                  item.source.overallOrderStatus === null
                    ? "#fff"
                    : item.source.overallOrderStatus === false
                    ? "#e4fff0"
                    : "#FFF",
                boxShadow:
                  item.source.overallOrderStatus === null
                    ? ""
                    : item.source.overallOrderStatus === false
                    ? "3px 7px 13px 2px rgba(0, 0, 0 ,18%)"
                    : "",
                // backgroundColor: "#e4fff0"
              }}
              header={
                <Row
                  onClick={(event) => {
                    // event.preventDefault();
                    clearNewItemsAdminAllOrder(
                      item.source.orderGroupId,
                      item.source.overallOrderStatus,
                      item.id
                    );
                  }}
                >
                  {/* <Col xs={12} sm={12} md={4} lg={4} xl={4} className="add-image-col">

                    <div className="">
                      REF_ID:
                     </div>

                  </Col> */}

                  {/* <div className="order_basic_each clmn-1">
                    ORDER ID : <Text strong> {item.source.orderGroupId}</Text>{" "}
                  </div> */}
                  <Col
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    xl={7}
                    className="add-image-col"
                  >
                    <div className="order_basic_each clmn-1">
                      ORDER ID :{" "}
                      <Text strong> {item?.source?.orderGroupId}</Text>{" "}
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    className="add-image-col"
                  >
                    <div className=" flex">
                      <img
                        src={calendarIcon}
                        style={{ width: "20px" }}
                        className="verified-icon"
                      />
                      {moment(item.source.orderedDate).format(" MMM DD, hh:mm A")}
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    xl={5}
                    className="add-image-col"
                  >
                    <div className=" flex order-item-active">
                      {" "}
                      <img
                        src={
                          item.source.paymentMode === "ONLINE"
                            ? OnlineIcon
                            : CODIcon
                        }
                        style={{ width: "25px" }}
                        className="verified-icon"
                      />
                      {item.source.paymentMode}{" "}
                    </div>
                  </Col>
                  {/* <Col
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    xl={7}
                    className="add-image-col"
                  >
                    <div className="address-row-order">
                      <img
                        src={locationIcon}
                        style={{ width: "25px" }}
                        className="verified-icon"
                      />
                      <div className="line-break-location">
                        {item &&
                          item.source &&
                          item.source.shippingAddress &&
                          getValueWithFormat(item.source.shippingAddress)}
                      </div>
                    </div>
                  </Col> */}
                  <Col
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    className="add-image-col"
                  >
                    <div className="item-count">
                      {item?.source?.orderedItems?.filter(o => item.source.vendorOrderStatuses?.filter(o =>
                        props.orderStatus == "NOT_CONFIRMED"? o.orderStatus == "NOT_CONFIRMED": o.orderStatus != "NOT_CONFIRMED")
                        .map(o => o.vendorId).includes(o.vendorId)).length + " ITEMS"}{" "}
                    </div>
                  </Col>
                  <Divider />

                  <Col
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    xl={7}
                    className="add-image-col"
                  >
                    <div className="order_basic_each clmn-1">
                      {item?.source?.phonePeTransactionId? "PhonePe ID:" :
                        item?.source?.ccAvenueOrderId? "CCAvenue ID:":
                        "Payment ID:"}{" "}
                      <Text strong>
                        {" "}
                        {item?.source?.phonePeTransactionId
                          ? item?.source?.phonePeTransactionId
                          : item?.source?.ccAvenueOrderId
                          ? item?.source?.ccAvenueOrderId :
                          "NIL"}
                      </Text>{" "}
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    className="add-image-col"
                  >
                    <div className=" order_basic_each clmn-1">
                      TOTAL:{" "}
                      <Text strong className="cart-price">
                        ₹{item?.source?.orderedItems?.filter(o => o.itemOrderStatus?.currentStatus !== "CANCELLED" && item.source.vendorOrderStatuses?.filter(o =>
                        props.orderStatus == "NOT_CONFIRMED"? o.orderStatus == "NOT_CONFIRMED": o.orderStatus != "NOT_CONFIRMED")
                        .map(o => o.vendorId).includes(o.vendorId)).reduce((prev, cur) => prev + cur.totalAmount, 0) + item.source.vendorOrderStatuses?.filter(o =>
                          props.orderStatus == "NOT_CONFIRMED"? o.orderStatus == "NOT_CONFIRMED": o.orderStatus != "NOT_CONFIRMED")
                          .map(o => o.vendorId).reduce((prev, cur) => prev + (item?.source?.vendorDeliveryChargeMap?.[cur]? item?.source.vendorDeliveryChargeMap[cur] : 0), 0)}
                      </Text>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    xl={7}
                    className="add-image-col"
                  >
                    <div className="order_basic_each clmn-1">
                      Vendors: <br />
                      {getStores(item?.source?.orderedItems?.filter(o => item.source.vendorOrderStatuses?.filter(o =>
                        props.orderStatus == "NOT_CONFIRMED"? o.orderStatus == "NOT_CONFIRMED": o.orderStatus != "NOT_CONFIRMED")
                        .map(o => o.vendorId).includes(o.vendorId)), item?.source)}
                    </div>
                  </Col>
                </Row>
              }
            >
              <div className="manage-order-btn-layout-container view-invoice-layout">
              <div className="cancel-order-btn-wrapper">
                  {(item.source.orderStatus === "DELIVERED" || item.source.orderStatus === "INPROGRESS") &&
                   changeOrderStatusAuthenticatedUsers.includes(JSON.parse(localStorage.getItem("activeCustomer"))?.userId)? (
                    <Button
                      danger
                      type="primary"
                      className="cancel-order-btn"
                      onClick={() => {
                        handleChangeOrderStatus(item.source);
                      }}
                    >
                      {isLoadingCancel ? (
                        <Loading style={{ color: "white" }} />
                      ) : (
                        "Change Status"
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="cancel-order-btn-wrapper">
                  {item.source.orderStatus === "NOT_CONFIRMED" ? (
                    <Button
                      danger
                      type="primary"
                      className="cancel-order-btn"
                      // onClick={() =>
                      //   renderConfirmCancelOrderModal(
                      //     item.source.orderGroupId,
                      //     item.source.orderedItemsOfVendor
                      //   )
                      // }
                      onClick={() => {
                        handleCancelOrderGroup(
                          item.source.orderGroupId,
                          item.source.orderedItems
                        );
                      }}
                    >
                      {isLoadingCancel ? (
                        <Loading style={{ color: "white" }} />
                      ) : (
                        "Cancel Order"
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="manage-orders-button-layout">
                  {props.orderStatus == "NOT_CONFIRMED" && item.source.isSellerUnconfirmed? (
                    <Button
                      className="confirm-order-btn"
                      onClick={() => {
                        console.log("ORDER ID:::" + item.source.orderGroupId);
                        handleConfirmOrderGroup(item.source.orderGroupId);
                      }}
                    >
                      {isLoadingConfirm ? (
                        <Loading style={{ color: "white" }} />
                      ) : (
                        "Confirm Order"
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="manage-orders-button-layout">
                  {/* {props.isWarehouse ? (
                    item.source.ewayBillNumber ? (
                      <div className="generate-eway-bill-clip">
                        <span className="eway-bill-no-fnt">{`Eway-Bill No: ${item.source.ewayBillNumber}`}</span>
                        <CopyToClipboard
                          text={item.source.ewayBillNumber}
                          onCopy={(text, result) =>
                            message.success(`copied ${text} to clipboard`)
                          }
                        >
                          <Tooltip
                            placement="bottom"
                            title={"Copy to Clipboard"}
                          >
                            <CopyOutlined className="generate-eway-bill-clip-icon" />
                          </Tooltip>
                        </CopyToClipboard>
                      </div>
                    ) : (
                      item.source.orderStatus !== "CANCELLED" && (
                        <Button
                          onClick={() => {
                            setgenerateEwayBillVisible(true);
                            setactiveOrder(item.source);
                          }}
                          className="view-invoice-btn generate-eway-bill-btn"
                        >
                          Generate Eway-Bill
                        </Button>
                      )
                    )
                  ) : (
                    <></>
                  )} */}
                  {/* {console.log("Order ID Array : ",item.source.orderedItemsOfVendor[0].orderId)} */}
                  {/* {!props.isWarehouse &&
                  pickOrderByHandStates.includes(item.source.orderStatus) &&
                  !item?.source?.allItemsShipped &&
                  item.source.deliveryCharge >= 0 ? (
                    <Button
                      onClick={() => {
                        renderConfirmModal(item.source.orderGroupId);
                      }}
                      className="exclude-delivery-btn"
                    >
                      {isloadingExcludeDeliveryCharge ? (
                        <Loading />
                      ) : (
                        "Pick Up Order By Hand"
                      )}
                    </Button>
                  ) : (
                    <></>
                  )} */}
                  {/* {item.source.orderStatus !== "CANCELLED" ? (
                    <Fragment>
                      <Button
                        onClick={() => {
                          setInvoiceModal(true);
                          setInvoiceItem(formatOrderSummary(item.source));
                        }}
                        className="view-invoice-btn"
                      >
                        View Invoice
                      </Button>
                      <Button
                        onClick={() => {
                          setThermalModal(true);
                          setInvoiceItem(formatOrderSummary(item.source));
                        }}
                        className="view-invoice-btn"
                      >
                        Thermal Print
                      </Button>
                    </Fragment>
                  ) : (
                    <></>
                  )} */}
                  {/* {pickOrderByHandStates.includes(item.source.orderStatus) &&
                    !item?.source?.allItemsShipped && (
                      <Button
                        onClick={() => handleDownload(item.source.orderGroupId)}
                        className="print-pickList-btn"
                      >
                        {isloadingDownload ? (
                          <Loading />
                        ) : (
                          <div>
                            <span>Print PickList</span>{" "}
                            <PrinterOutlined className="print-icon-antd" />
                          </div>
                        )}
                      </Button>
                    )} */}

                  {/* [Send to MLM] Button renders only for store view by admin */}
                  {/* {props.isStore ? (
                  activeCustomerType === "admin" ? (
                    <Button type="primary" ghost className="send-to-MLM-btn">
                      Send to MLM
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )} */}
                </div>
              </div>

              {/* {item.source.orderedItemsOfVendor.length > 1 && (
                <div className={"selectall-section-cont"}>
                  {" "}
                  <div className="selectall-checkbox">
                    <Checkbox
                      className="manage-order-checkbox"
                      checked={isSelectallChecked}
                      onChange={(e) => handleSelectAllProducts(e, item)}
                    />
                    Select All Products
                  </div>
                  <div className="seller-action-button">
                    {selectedItems?.length > 1 && (
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                          setSelectionType("multiple");
                          setTrackingModal(true);
                        }}
                        className=""
                      >
                        Update Tracking Details
                      </Button>
                    )}
                  </div>
                </div>
              )} */}

              {props.orderStatus != "NOT_CONFIRMED" && item?.source?.orderedItems?.filter(o =>
                item.source.vendorOrderStatuses?.filter(o => o.orderStatus != "NOT_CONFIRMED").map(o => o.vendorId).includes(o.vendorId)).map((orderdata) => (
                <OrderData
                  // updateStatus={updateStatus}
                  // checkBoxSelection={checkBoxSelection}
                  orderData={orderdata}
                  orderId={item.id}
                  // checked={checked}
                  paymentMode={item.source.paymentMode}
                  // handleEditVendorOrder={handleEditVendorOrder}
                  orderGroupId={item.source.orderGroupId}
                  vendorId={cookies.get("vendorId")}
                  accountType={item.source.accountType}
                  // setCancelOrder={setCancelOrder}
                  selectedItems={selectedItems}
                  itemCount={itemCount}
                />
              ))}
              {props.orderStatus == "NOT_CONFIRMED" && item.source.isSellerUnconfirmed && item?.source?.orderedItems?.filter(o =>
                item.source.vendorOrderStatuses?.filter(o => o.orderStatus == "NOT_CONFIRMED").map(o => o.vendorId).includes(o.vendorId)).map((orderdata) => (
                <OrderData
                  // updateStatus={updateStatus}
                  // checkBoxSelection={checkBoxSelection}
                  orderData={orderdata}
                  orderId={item.id}
                  // checked={checked}
                  paymentMode={item.source.paymentMode}
                  // handleEditVendorOrder={handleEditVendorOrder}
                  orderGroupId={item.source.orderGroupId}
                  vendorId={cookies.get("vendorId")}
                  accountType={item.source.accountType}
                  // setCancelOrder={setCancelOrder}
                  selectedItems={selectedItems}
                  itemCount={itemCount}
                />
              ))}
            </Panel>
          </Collapse>
        ))
      )}
      {trackingModal && (
        <TrackingDetails
          visible={trackingModal}
          selectedItems={selectedItems}
          // setCancel={setCancel}
          groupId={orderGroupId}
          accountType={accountType}
          // saveChanges={setSuccess}
        />
      )}
      {invoiceModal && (
        <TaxInvoice
          visible={invoiceModal}
          setvisible={setInvoiceModal}
          orderSummary={invoiceItem}
        />
      )}
      {thermalModal && (
        <ThermalIndex setvisible={setThermalModal} orderSummary={invoiceItem} />
      )}
      {total > 10 && (
        <div className="admin-manage-customer">
          <Pagination
            defaultCurrent={1}
            current={currentpage}
            showSizeChanger={false}
            total={total}
            className="customer-pagination"
            onChange={(page) => {
              getProductsList(props.searchVal, page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}
      {/* <GenerateEwayBill
        visible={generateEwayBillVisible}
        setvisible={setgenerateEwayBillVisible}
        orderDetails={activeOrder}
        getProductsList={getProductsList}
      /> */}
      {cancelOrderModal === true && (
        <CancelOrderRequest
          visible={cancelOrderModal}
          setVisible={setCancelOrderVisible}
          setSuccess={renderConfirmCancelOrderModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.manageOrderReducer,
  };
};
export default withRouter(connect(mapStateToProps)(ManageAllOrderGroups));
