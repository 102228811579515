


import React, { useState } from "react";
import "./checkAvailiablity.scss";

import SearchAvailiableProducts from "./SearchAvailiableProducts";

import { Divider, Typography, message } from "antd";
import AvailableProductList from "./availableProductList";

const { Text } = Typography;
const CheckAvailablity = (props) => {
  const { filterBy } = props;
  const [searchKey, setsearchKey] = useState();
  const handleOnSearch = (value) => {
    var spclOnlyRegex = new RegExp("^[^a-zA-Z0-9]+$");
    if (spclOnlyRegex.test(value)) {
      message.warning("Invalid search key!");
    } else setsearchKey(value);
  };
  return (
    <div className="checkavailiablity_conatiner">
      <h1 className="sub-title-tags">Products</h1>
      <Divider />
      <SearchAvailiableProducts onSearch={handleOnSearch} />
      <Divider />
      {searchKey && <Text>Results for Keyword {searchKey}</Text>}
      <AvailableProductList filterBy={filterBy} searchKey={searchKey} />
    </div>
  );
};

export default CheckAvailablity;
