

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { Layout, Typography, Pagination, message } from "antd";

import "./productListing.scss";

import * as CustomerProduct from "../../../../ApiAction/CustomerApiActions/productApi";
import * as ProductListHelper from "./productListingHelper";

import BreadCrumb from "./breadCrumb";
import SortBy from "./components/sortBy";
import CatogeryFilter from "./components/Filters/catogeryfilter";
import PriceFilter from "./components/Filters/priceFilter";
import BrandFilter from "./components/Filters/brandFilter";
import ProductList from "./components/Productlist";
import MobileFilter from "./components/Filters/mobileFilter";
import EmptyList from "../../../GeneralComponents/emptyList";

import SortIcon from "../../../../Assets/Images/Icons/Seller/Sort2.svg";
import FilterIcon from "../../../../Assets/Images/Icons/filter.svg";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const { Content, Sider } = Layout;
const { Text } = Typography;

const ProductLsiting = (props) => {
  const [mobileView, setmobileView] = useState();
  const [pageHeader, setpageHeader] = useState();
  const [mobileFilterVisible, setmobileFilterVisible] = useState(false);
  const [filterType, setfilterType] = useState();
  const [product, setproduct] = useState();

  const [breadCrumbData, setbreadCrumbData] = useState();
  const [sort, setsort] = useState("");
  const [sortBy, setsortBy] = useState("");
  const [isFilterApplied, setisFilterApplied] = useState(false);

  const [productList, setproductList] = useState([]);
  const [productListEmpty, setproductListEmpty] = useState(false);
  const [filterBrands, setfilterBrands] = useState([]);
  const [filterCategories, setfilterCategories] = useState([]);
  const [loading, setloading] = useState(false);
  const [itemCount, setitemCount] = useState(); // api response count (actual count)
  const [totalCount, settotalCount] = useState(0); // for pagination (rounded up for pagination)
  const [pageNo, setpageNo] = useState(1);

  const handleOpenMobileFilter = (type) => {
    setmobileFilterVisible(true);
    setfilterType(type);
  };

  const handleAddToCart = ({ visible, productDetails }) => {
    setmobileFilterVisible(visible);
    setfilterType("Add To Cart");
    setproduct(productDetails);
  };

  //function to handle switch productListing

  const handleSwitchProductListing = (page = 1) => {
    setpageNo(page);
    const term = [];
    const range = [];

    if (props.categoryId.length > 0) {
      term.push({ categoryId: props.categoryId });
    }
    if (props.subCategory0Id.length > 0) {
      term.push({ subCategory0Id: props.subCategory0Id });
    }
    if (props.subCategory1Id.length > 0) {
      term.push({ subCategory1Id: props.subCategory1Id });
    }
    if (props.subCategory2Id.length > 0) {
      term.push({ subCategory2Id: props.subCategory2Id });
    }
    if (props.subCategory3Id.length > 0) {
      term.push({ subCategory3Id: props.subCategory3Id });
    }
    if (props.brandId.length > 0) {
      term.push({ brandId: props.brandId });
    }
    if (props.sellerId.length > 0) {
      term.push({ vendorId: props.sellerId });
    }
    if (props.storeId.length > 0) {
      term.push({ vendorId: props.storeId });
    }

    if(props.location.seller) {
      term.push({vendorType: ["SELLER"]});
      term.push({serviceLocations: ["All India"]});
    }

    if (props.pinCode) {
      const pinCode = props.pinCode;
      term.push({ serviceLocations: [pinCode, "All India"] });
    }

    if (props.priceRanges.gte || props.priceRanges.lte) {
      // if (sortBy === "price") {
      //   if (sort === "asc") {
      //     range.push({ minPrice: props.priceRanges });
      //   } else {
      //     range.push({ maxPrice: props.priceRanges });
      //   }
      // } else {
      range.push({ priceRanges: props.priceRanges });
      // }
    }
    range.push({
      outOfStock: {
        gte: "1",
      },
    });

    const payload = {
      offset: (page - 1) * 30,
      size: 30,
      filter: {
        term: term,
        range: range,
      },
      sortBy: sortBy ? sortBy : "",
      sortType: sort ? sort : "",
      hasRangeAndSort:
        sortBy && (props.priceRanges.gte || props.priceRanges.lte)
          ? true
          : false,
    };

    if (props.match.path.split("/").includes(":sectionName")) {
      handleFetcSectionData(payload);
      // console.log("section name api caled");
    } else handleFetchProductListing(payload);
  };

  const handleFetchProductListing = (payload) => {
    if (props.match.params.key) {
      // console.log("search report payload passed");

      payload["userSearch"] = {
        key: props.match.params.key,
        pinCode: props.pinCode ? props.pinCode : "",
      };
    }

    if(props.location.state) {
      payload["fmcgCategoryId"] = props.location.state?.categoryId;
    }

    payload["key"] = props.match.params.key;

    // console.log(
    //   "function for fetching product listing of categories/ search.."
    // );
    setloading(true);
    const payloadWithSort = { ...payload };
    payloadWithSort["sortBy"] = sortBy ? sortBy : "";
    payloadWithSort["sortType"] = sort ? sort :  "";
    (props.location.state && !payloadWithSort?.filter?.term?.find(o => 'brandId' in o) && 
    payloadWithSort?.filter?.term?.find(o => 'subCategory0Id' in o)? 
    CustomerProduct.searchFMCGProducts(payloadWithSort): CustomerProduct.searchProducts(payloadWithSort))
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            settotalCount(Math.ceil(response.data.count / 30) * 30);
            setitemCount(response.data.count);
            setproductListEmpty(!response.data.count);
            if(!response.data.count) {
              getPlaceHolderProducts();
            }
            setproductList(
              ProductListHelper.formatProductData(response.data.products)
            );
            setbreadCrumbData(
              response.data?.breadCrumb
                ? response.data.breadCrumb
                : {
                    search: { key: props.match.params.key },

                    type: "search",
                  }
            );
            console.log("PRODUCT COUNT ::::: ", response.data.products.length)
          }
        }
        // console.log(response, ": products listing response");
      })
      .catch((error) => {
        if (error?.response?.message) message.error(error.response.message);
      })
      .finally(() => {
        setloading(false);
      });

    //side filter
    {
      !isFilterApplied &&
        CustomerProduct.getFilterOptions({
          ...payload,
          type: "search-filter",
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                setfilterBrands(response.data.brand);
                setfilterCategories(response.data.category);
              } else {
                setfilterBrands([]);
                setfilterCategories([]);
              }
            }
            // console.log("response for filter menu :", response);
          })
          .catch((error) => {
            if (error?.response?.message) message.error(error.reaponse.message);
          });
    }

    setisFilterApplied(false);
  };

  const handleFetcSectionData = (payload) => {
    // delete payload.filter;
    payload["sectionId"] = props.match.params.sectionId;

    if(props.location.state) {
      payload["fmcgCategoryId"] = props.location.state?.categoryId;
    }

    setloading(true);
    const payloadWithSort = { ...payload };
    payloadWithSort["sortBy"] = sortBy ? sortBy : props.location.state? "": "highestDiscount.discount";
    payloadWithSort["sortType"] = sort ? sort :  props.location.state? "": "desc";
    (props.location.state && !payloadWithSort?.filter?.term?.find(o => 'brandId' in o) && 
    !payloadWithSort?.filter?.term?.find(o => !('subCategory0Id' in o))? 
      CustomerProduct.searchFMCGProducts(payloadWithSort): CustomerProduct.fetchSectionProducts(payloadWithSort))
      .then((response) => {
        console.log("response for section product list: ", response);
        if (response.status === 200) {
          if (response.data.status === "success") {
            settotalCount(Math.ceil(response.data.count / 30) * 30);
            setitemCount(response.data.count);
            setproductListEmpty(!response.data.count);
            if(!response.data.count) {
              getPlaceHolderProducts();
            }
            setproductList(
              ProductListHelper.formatProductData(response.data.products)
            );
            setbreadCrumbData(
              response.data?.breadCrumb
                ? response.data.breadCrumb
                : {
                    search: { key: props.match.params.key },

                    type: "search",
                  }
            );
            console.log("PRODUCT COUNT ::::: ", response.data.products.length)
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });

    //side filter
    //same serviceLocation of filter
    {
      !isFilterApplied &&
        CustomerProduct.getFilterOptions({
          ...payload,
          type: "section",
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                setfilterBrands(response.data.brand);
                setfilterCategories(response.data.category);
              } else {
                setfilterBrands([]);
                setfilterCategories([]);
              }
            }
            console.log("response for filter menu :", response);
          })
          .catch((error) => {
            if (error?.response?.message) message.error(error.reaponse.message);
          });
    }
    setisFilterApplied(false);
  };

  function getPlaceHolderProducts() {
    let payload = {
      offset:0,
      size:10,
      filter:{ term:[], range:[{outOfStock:{gte:1}}]}
    }
    if(props.match.params.key) {
      payload.key = props.match.params.key?.[0];
    }
    CustomerProduct.searchProducts(payload)
    .then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setproductList(
            ProductListHelper.formatProductData(response.data.products)
          );
        }
      }
    });
  }

  useEffect(() => {
    navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)
      ? setmobileView(false)
      : setmobileView(false);
  }, []);

  //to scroll to top when the page loads
  useEffect(() => {
    // window.scroll(0, 0);
    // console.log("store props: ", props);
  }, []);

  useEffect(() => {
    handleSwitchProductListing();
    // console.log("Sort By is :", sortBy);
    // console.log("Sort type is :", sort);
  }, [
    props.match.params.key,
    props.brandId,
    props.categoryId,
    props.subCategory0Id,
    props.subCategory1Id,
    props.subCategory2Id,
    props.subCategory3Id,
    props.priceRanges,
    // pageNo, this is commented to prevent multipe api calls and to fix pagination issues
    props.location,
    props.pinCode,
    sort,
    sortBy,
  ]);

  useEffect(() => {
    let breadcrumb = JSON.parse(localStorage.getItem("breadCrumbData"));
    // console.log(breadcrumb[breadcrumb.length - 1], ":current page");
    window.scroll(0, 0); // scrolls to top on clicking any category from mega-menu

    setpageHeader(breadcrumb[breadcrumb.length - 1].label);
  }, [localStorage.getItem("breadCrumbData")]);

  return (
    <div className="customer-product-listing-page-conatiner">
      <div className="container">
        <Layout className="customer-product-listing-main-layout">
          <div className="product-listing-breadcrumb-sort-by">
            <BreadCrumb breadCrumbData={breadCrumbData} />
            <div class="hr_sort_order">
              <Text style={{ marginRight: "30px" }}>
                {itemCount ? itemCount : 0} results found
              </Text>
              <SortBy
                setsort={setsort}
                setsortBy={setsortBy}
                setisFilterApplied={setisFilterApplied}
              />
            </div>
          </div>

          <div className="category-list-pdt">
            <div style={{ marginTop: "20px" }} className="group_category_title">
              <Text className="pdt_filter_category_title">
                {pageHeader ? pageHeader : "Loading.."}
              </Text>
            </div>
            <div className="customer-product-listing-mobile-sortby-filter">
              <Text
                className="customer-product-listing-mobile-sortBy mob_filter_sticky_btn"
                onClick={() => handleOpenMobileFilter("Sort By")}
              >
                <span>
                  <img
                    className="customer-product-listing-mobile-sortby-filter-icon"
                    src={SortIcon}
                    alt="delete-icon"
                  />
                </span>
                Sort
              </Text>
              <Text
                className="mob_filter_sticky_btn"
                onClick={() => handleOpenMobileFilter("Filter By")}
              >
                <span>
                  <img
                    className="customer-product-listing-mobile-sortby-filter-icon"
                    src={FilterIcon}
                    alt="delete-icon"
                  />
                </span>
                Filter
              </Text>

              <MobileFilter
                mobileFilterVisible={mobileFilterVisible}
                setmobileFilterVisible={setmobileFilterVisible}
                type={filterType}
                product={product}
                filterCategories={filterCategories}
                filterBrands={filterBrands}
                setisFilterApplied={setisFilterApplied}
              />
            </div>
          </div>

          <Content>
            <Layout className="hr_product_list_group">
              {!mobileView && (
                <Sider
                  theme={"light"}
                  className="customer-product-listing-sider-layout hr_product_listed_aside"
                >
                  <CatogeryFilter
                    filterCategories={filterCategories}
                    setisFilterApplied={setisFilterApplied}
                  />
                  <div style={{ padding: "15px" }}></div>
                  <Text className="filter_base_title">Filters</Text>
                  <div style={{ padding: "10px" }}></div>
                  <PriceFilter setisFilterApplied={setisFilterApplied} />
                  <div style={{ padding: "15px" }}></div>
                  <BrandFilter
                    filterBrands={filterBrands}
                    setisFilterApplied={setisFilterApplied}
                  />
                </Sider>
              )}
              <Content className="customer-product-listing-content-layout hr_product_listed_main">
                {(!productListEmpty || loading) && (
                  <ProductList
                    // productData={productData}
                    handleAddToCart={handleAddToCart}
                    productData={
                      loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : productList
                    }
                    loading={loading}
                  />
                )}
                 {productListEmpty && (
                  <>
                  <EmptyList
                    placeHolderProducts
                    productList={productList}
                    msg={
                      "Sorry! No product found for " + props.match.params.key
                    }
                  />
                  <ProductList
                    // productData={productData}
                    handleAddToCart={handleAddToCart}
                    productData={
                      loading ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : productList
                    }
                    loading={loading}
                  />
                  </>
                )}
                {totalCount > 30 && (
                  <Pagination
                    size="small"
                    total={totalCount}
                    defaultPageSize={30}
                    current={pageNo}
                    defaultCurrent={1}
                    showSizeChanger={false}
                    className="customer-pagination"
                    onChange={(page) => {
                      // setpageNo(page);
                      handleSwitchProductListing(page);
                      window.scrollTo(0, 0);
                    }}
                  />
                )}
              </Content>
            </Layout>
          </Content>
        </Layout>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryId: state.productReducer.categoryId,
    subCategory0Id: state.productReducer.subCategory0Id,
    subCategory1Id: state.productReducer.subCategory1Id,
    subCategory2Id: state.productReducer.subCategory2Id,
    subCategory3Id: state.productReducer.subCategory3Id,
    searchKey: state.productReducer.searchKey,
    brandId: state.productReducer.brandId,
    sellerId: state.productReducer.sellerId,
    storeId: state.productReducer.storeId,
    priceRanges: state.productReducer.priceRanges,
    pinCode: state.productReducer.pinCode,
  };
};

export default connect(mapStateToProps, null)(ProductLsiting);
