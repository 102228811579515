

import React, { useState, Fragment, useEffect } from "react";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import "./categoryList.css";

import * as ManageCategoryAPI from "../../../../ApiAction/AdminApiAction/categoryApi";

import ManageCategoryModal from "./manageCategoryModal";

import DeactivateIcon from "../../../../Assets/Images/Icons/deactivate-light.svg";
import DeleteIcon from "../../../../Assets/Images/Icons/delete-light.svg";
import { MdModeEdit, MdDelete } from "react-icons/md";
import Text from "antd/lib/typography/Text";
// import TopIcon from "../../../../Assets/Images/Icons/top.svg";
import Cookies from "universal-cookie";

const confirm = Modal.confirm;
const CategoryList = (props) => {
  const {
    categories,
    level,
    parentCategoryId,
    setCategoryLevel,
    selectedCategory,
    refetch,
    setrefetch,
    isView,
  } = props;

  const cookies = new Cookies();

  const [categoryList, setcategoryList] = useState([]);
  const [modalVisible, setmodalVisible] = useState(false);
  const [categoryData, setcategoryData] = useState(); //data for edit modal
  const [accountType] = useState(cookies?.get("accountType"));

  const handleOrderChange = (updatedList) => {
    if (level === -1) {
      var updatedCategoryOrder = [];

      updatedList.map((category, index) => {
        let temp = {
          id: category.categoryId,
          category: {
            displayOrder: index + 1,
          },
        };

        updatedCategoryOrder.push(temp);
      });
      const payload = {
        categories: updatedCategoryOrder,
      };
      ManageCategoryAPI.reorderCategory(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              setcategoryList(updatedList);
            }
          }
        })
        .catch(() => {});
    }
  };

  const handleOpenEditModal = (data) => {
    setcategoryData(data);
    setmodalVisible(true);
  };

  const renderConfirmModal = (categoryData, actionType) => {
    Modal.confirm({
      title: "Confirm",
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to ${actionType} ${categoryData.categoryName} category ? (ALL PRODUCTS LINKED TO THIS CATEGORY WILL BE PERMANENTLY DELETED) `,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleDeleteCategory(categoryData.categoryId),
    });
  };

  const handleDeleteCategory = (categoryId) => {
    ManageCategoryAPI.deleteCategory(categoryId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(response.data.message);
            setrefetch(!refetch);
          } else message.error(response.data.message);
        } else message.error("Unable to delete category please try again");
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    setcategoryList(categories);
    console.log("Selected Categories :: ", categories);
  }, [props]);

  const itemRenderer = (item, index) => {
    console.log("IIIIIIIIIIIIIIIIIII");
    console.log(item);
    return (
      <div
        className="category-name-box"
        onClick={() => {
          if(accountType === "admin"){
            setCategoryLevel(item?.categoryId);
          }else if (item?.categoryId !== "da4b7ea99c513e76ac6bc679b2f739cb") {
            setCategoryLevel(item.categoryId);
          }
        }}
      >
        <p
          className={
            selectedCategory === item.categoryId
              ? "each-category-name selected-category-item"
              : "each-category-name"
          }
        >
          {item.categoryName}
          {item?.subCategories?.length > 0 ? (
            ""
          ) : (
            <>
              {item?.categoryCommission && (
                <Text strong style={{ color: "#3e6df3", paddingLeft: "0.5em" }}>
                  (P.C {item?.categoryCommission}%)
                </Text>
              )}
            </>
          )}
        </p>
        {!isView && (
          <>
            <span className="category-del-update-box">
              <MdModeEdit
                onClick={() => handleOpenEditModal(item)}
                className="cat-icon"
                title="Edit Category"
                alt="cat-deact-icon"
              />
              <MdDelete
                onClick={() => renderConfirmModal(item, "delete")}
                className="cat-icon"
                title="Delete Category"
                alt="cat-del-icon"
              />
            </span>
          </>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <div className="category-list-container">
        <RLDD
          className="example"
          items={categoryList}
          itemRenderer={itemRenderer}
          onChange={!isView ? handleOrderChange : () => {}}
        />
      </div>

      <ManageCategoryModal
        visible={modalVisible}
        level={level}
        setvisible={setmodalVisible}
        categoryData={categoryData}
        categoryName={categoryData?.categoryName}
        parentCategoryId={parentCategoryId}
        type={"Edit Category"}
        refetch={refetch}
        setrefetch={setrefetch}
      />
    </Fragment>
  );
};

export default CategoryList;
