


import { REFTECH_ADDRESS_LIST } from "../action/types";

export default function customerAddressReducer(
  state = { refetchAddressList: false, addresslist: [] },
  action
) {
  switch (action.type) {
    case REFTECH_ADDRESS_LIST:
      state = { ...state, refetchAddressList: !state.refetchAddressList };
      break;

    default:
      // console.log("no changes ");
  }

  return state;
}
