


import React, { useState, useEffect } from "react";
import { Tabs, Typography, Input } from "antd";
import TableContent from "./tableContent";
import OrderSummary from "./orderSummary";
import SearchBar from "../../SearchBar";
import SearchBarDropDown from "./searchBarDropDown";
import SearchCustomerId from "./searchCustomerId";
import ShippingInvoice from "./shippingInvoice";

const { Text } = Typography;
const TabContent = (props) => {
  const [customerId, setcustomerId] = useState("");
  const [deliveryCharge, setdeliveryCharge] = useState(0);
  const [address, setaddress] = useState("");
  const [shippingRequired, setshippingRequired] = useState(false);
  const [pinCode, setpincode] = useState(0);
  const [isLoading, setisLoading] = useState(false);
  const [outOfStockList, setOutOfStockList] = useState([]);
  const [lowStockList, setLowStockList] = useState([]);
  return (
    <div>
      <div className="tab-container">
        <div className="manage-products-header">
          <SearchBarDropDown
            setdata={props.setdata}
            data={props.data}
            setisLoading={setisLoading}
          />
          <SearchCustomerId
            setdata={props.setdata}
            data={props.data}
            address={address}
            setaddress={setaddress}
            setcustomerId={setcustomerId}
            setisLoading={setisLoading}
          />
        </div>

        <TableContent
          data={props.data}
          setdata={props.setdata}
          outOfStockList={outOfStockList}
          lowStockList={lowStockList}
          isLoading={isLoading}
        />
        <OrderSummary
          pinCode={pinCode}
          data={props.data}
          setshippingRequired={setshippingRequired}
          setdeliveryCharge={setdeliveryCharge}
        />
      </div>
      <ShippingInvoice
        shippingRequired={shippingRequired}
        data={props.data}
        deliveryCharge={deliveryCharge}
        setaddress={setaddress}
        setOutOfStockList={setOutOfStockList}
        setLowStockList={setLowStockList}
        customerId={customerId}
        setpincode={setpincode}
        handleRemove={props.handleRemove}
        pane={props.pane}
      />
    </div>
  );
};

export default TabContent;
