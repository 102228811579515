


import React, { useState, useEffect } from "react";

import { Col, Row, Typography, Popover } from "antd";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import {
  getLinkedWarehouseProducts,
  getProducts,
} from "../../ApiAction/SellerApiActions/sellerApi";
import ManageProductList from "../Seller/ManageProducts/Components/manageProductList";
import ProductCard from "../ProductCard";
import StoreDetailsModal from "../Admin/ManageStore/storeDetailsModal";

import { formatProductData } from "../Customer/Components/ProductListing/productListingHelper";
import EmptyList from "../GeneralComponents/emptyList";

const { Title } = Typography;

const WarehouseProducts = (props) => {
  const [isMobileView, setisMobileView] = useState(false);
  const [type, setType] = useState("listed");
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(0);
  const [warehouseName, setWarehouseName] = useState();

  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    nextpage(1);
  }, [props]);

  const nextpage = (page = 1) => {
    const term = [];

    term.push({
      vendorId: [props.match.params.warehouseId],
    });
    //   term.push({
    //     isDraft: ["false"],
    //   });
    const payload = {
      offset: 10 * (page - 1),
      size: 10,

      key: "",
      sortBy: "lastModifiedDate",
      sortType: props.sort ? props.sort.sortBy : "asc",
      filter: {
        term: term,
      },
    };
    getLinkedWarehouseProducts(payload, props.match.params.warehouseId)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(formatProductData(response.data.products));
          setPage(Math.ceil(response.data.count / 10) * 10);
          setWarehouseName(response.data.warehouse);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        <h1 level={4} className="sub-title-tags">
          {" "}
          Products:
          {warehouseName && (
            <Popover
              content={<StoreDetailsModal details={warehouseName} isLinked />}
              placement="bottomLeft"
              trigger="click"

              // getPopupContainer={trigger => trigger.parentElement}
              // onVisibleChange={()=>setShowModal(!showModal)}
            >
              <span
                style={{
                  color: "#3e6df3",
                  fontWeight: "500",
                  cursor: "pointer",
                }}
              >
                {warehouseName && warehouseName.storeName}
              </span>
            </Popover>
          )}
        </h1>
      </div>
     { productList.length==0?
          <EmptyList msg={"No products  found"} />
        :
      <Row gutter={[0, 0]}>
        {productList.map((data) => {
          return (
            <Col className="gutter-row product_col" key={data.productId}>
              <ProductCard product={data} linkedWarehouse loading={false} />
            </Col>
          );
        })}
      </Row>
}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default withRouter(connect(mapStateToProps)(WarehouseProducts));
