


import React, { useState ,useEffect} from "react";
import { Layout, Dropdown, Button, Menu, Typography } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { fetchSort ,fetchSortBy} from "../../../ReduxStore/action"
import { connect } from "react-redux";
const { Text } = Typography;
const FilterbyStatus= (props) => {
  const [sortValue, setSortValue] = useState("Approved");
  
  useEffect(() => {


    

    
  }, [])
  const renderSortStatus = () => {
    return (
      <Menu>
      
        <Menu.Item
          onClick={() => {
            setSortValue("Approved");
          
            const payload={
              sortType:props.sort.sortType,
              sortBy:props.sort.sortBy,
              block: true,
              archive:false,
              all:false,
              status:"APPROVED",
              filterType:props.sort.filterType,
            }
            props.fetchSort(payload)
            // this.handleSelectChange("open", "projectStatus");
            // this.props.searchFilterStore.projectStatusFilterChange("open");
          }}
        >
          Approved
          {sortValue === "Approved" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>


        <Menu.Item
          onClick={() => {
            setSortValue("Pending");
            const payload={
                sortType:props.sort.sortType,
                sortBy:props.sort.sortBy,
                block: false,
                archive:false,
                all:false,
                status:"PENDING",
                filterType:props.sort.filterType,
              }
            props.fetchSort(payload)
          }}
        >
          Pending
          {sortValue === "Pending"  && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            setSortValue("Rejected");
            const payload={
                sortType:props.sort.sortType,
                sortBy:props.sort.sortBy,
                block: false,
                archive:false,
                all:false,
                status:"REJECTED",
                filterType:props.sort.filterType,
              }
            props.fetchSort(payload)
          }}
        >
          Rejected
          {sortValue === "Rejected"  && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
  
       
      </Menu>
    );
  };
  return (
    <Dropdown
      overlay={() => renderSortStatus()}
      placement="bottomCenter"
      trigger={["click"]}
      className="sort-dropdown-admin"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Button style={{height:"40px"}}>
        <span style={{ color: "#827e7e"}}>Filter By: </span>
        <span>
          {" "}
          
          <Text strong style={{ color: "red" }}>
          &nbsp;
            {sortValue}
          </Text>
        </span>
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
}
const mapDispatchToProps = {
  fetchSort,fetchSortBy
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterbyStatus);
