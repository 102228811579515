import { connect } from "react-redux";
import { fetchSort } from "../../ReduxStore/action";
import { Button, Layout } from "antd";
import FooterLayout from "../../Layouts/FooterLayout";
import React, { useEffect, useState } from "react";
import HeaderLayout from "../../Layouts/HeaderLayout";
import { DOMAIN, ENV_TYPE } from "../../Utils/constants";
import Cookies from "universal-cookie";
import GroupByReport from "../Seller/SortBy/groupByReport";
import { PlusOutlined } from "@ant-design/icons";
import SearchBarInput from "../SearchBar/SearchBarInput";
import ReportsFilter from "../Admin/Reports/Filter/reportsFilter";
import SortBy from "../Customer/Components/ProductListing/components/sortBy";
import FilterAndSortOrder from "../Admin/ManageAllOrders/filterAndSortOrder";
import FilterBlockArchive from "../Seller/SortBy/filterBlockArchive";
import AccountTypeContext from "../Admin/accountTypeContext";
import AffiliateMenu from "./affiliateMenu";
import { Redirect, Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import SalesReport from "../Admin/Reports/SalesReport";
import InvoiceSearch from "../Admin/InvoiceSearch/InvoiceSearch";
import ManageProfile from "../Admin/ManageProfile/manageProfile";
import ManagePlatformIndex from "../Admin/ManagePlatform/ManagePlatformIndex";
import AffiliateChargeReport from "../Admin/Reports/AffiliateChargeReport";

const { Content, Sider } = Layout;
const Affiliate = (props) => {
  const cookies = new Cookies();
  const [urlPath, setUrlPath] = useState("");
  const [activeTab, setactiveTab] = useState();
  const [searchVal, setSearchval] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [reportSummary, setreportSummary] = useState("");
  const activeCustomer = JSON.parse(localStorage.getItem("activeCustomer"));

  const urlPathswithExtraHeader = [
    "/affiliate/sales-report",
    "/affiliate/affiliate-charge-report",
  ];

  useEffect(() => {
    if (ENV_TYPE == "dev") {
      cookies.set("userId", cookies.get("adminId"), {
        path: "/",
      });

      cookies.set("accountType", "affiliate", {
        path: "/",
      });
    } else {
      cookies.set("userId", cookies.get("adminId"), {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      cookies.set("accountType", "affiliate", {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    }
  }, []);

  useEffect(() => {
    setUrlPath(props.location.pathname);
    setactiveTab(props.location.pathname.split("/").pop());
    setSearchval("");
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  return (
    <Layout>
      <HeaderLayout pathname={urlPath} isAffliate/>
      {urlPath === "/affiliate/profile" ||
      urlPath === "/affiliate/invoice-search" ||
      urlPath === "/affiliate/barcode-search"? (
        <div style={{ marginTop: "70px" }}></div>
      ) : (
        <div className="sort-breadcrumb">
        <div className="admin-breadcrumb" style={{ width: "325px" }}></div>
        {urlPathswithExtraHeader.includes(urlPath) && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "2%",
            }}
          >
            {<SearchBarInput searchValue={setSearchval} urlPath={urlPath} />}
            {/* {urlPath === "/affiliate/manage-products" && <FilterbyDomin />} */}
            {/* {urlPath === "/affiliate/manage-products" && <FilterbyStatus />} */}
            {
              // urlPath === "/affiliate/stocks-report" ||
              // urlPath === "/affiliate/search-report" ||
              // urlPath === "/affiliate/expiry-report" ||
              (urlPath === "/affiliate/sales-report" ||
                urlPath === "/affiliate/manage-products" ||
                urlPath === "/affiliate/affiliate-charge-report" ||
                urlPath === "/affiliate/report") && (
                <ReportsFilter
                  activePath={activeTab}
                  setreportSummary={setreportSummary}
                  isVendor
                />
              )
            }
            {urlPath === "/affiliate/sales-report" && <GroupByReport />}
            {urlPath !== "/affiliate/affiliate-charge-report" &&
              urlPath != "/affiliate/sales-report" &&
              urlPath != "/affiliate/report" && (
                <SortBy activePath={activeTab} />
              )}
            {
               urlPath === "/affiliate/manage-all-order" ?
               <FilterAndSortOrder/> :

              urlPath != "/affiliate/manage-products" &&
              urlPath != "/affiliate/stocks-report" &&
              urlPath != "/affiliate/sales-report" &&
              urlPath != "/affiliate/affiliate-charge-report" &&
              urlPath != "/affiliate/search-report" &&
              urlPath != "/affiliate/expiry-report" &&
              urlPath !== "/affiliate/central-repo" &&
              urlPath != "/affiliate/report" && <FilterBlockArchive />}

          </div>
        )}
      </div>)}
      <Layout className="nav-side-content-admin" style={{ padding: "3%" }}>
        <div className="profile_column_data">
          {!collapsed && (
            <Sider
              className="nav-side-bar-admin"
              trigger={null}
              collapsed={collapsed}
            >
              <AffiliateMenu activeTab={activeTab} setSearchval={setSearchval} />
            </Sider>
          )}
          <Layout className="admin-layout-content right_profile_layout">
            <Content>
              <Switch>

                <Route
                  exact
                  path="/affiliate"
                  render={(props) => {
                    return <Redirect to="/affiliate/profile" />;
                  }}
                />

                <Route
                  exact
                  path="/affiliate/profile"
                  render={(props) => <ManageProfile {...props} isAffiliate />}
                />


                <Route
                  exact
                  path="/affiliate/invoice-search"
                  render={(props) => {
                    return <InvoiceSearch {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/affiliate/manage-affiliate-charge"
                  render={(props) => <ManagePlatformIndex vendorType="AFFILIATE" />}
                />

                <Route
                  exact
                  path="/affiliate/sales-report"
                  render={(props) => {
                    return (
                      <SalesReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/affiliate/affiliate-charge-report"
                  render={(props) => {
                    return (
                      <AffiliateChargeReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                        vendorId={activeCustomer?.userCode?.substring(3)}
                      />
                    );
                  }}
                />

                <Route
                  render={(props) => {
                    return <Redirect to="/404" />;
                  }}
                />

              </Switch>
            </Content>
          </Layout>
        </div>
      </Layout>
      <AccountTypeContext.Provider value="affiliate">
        <FooterLayout />
      </AccountTypeContext.Provider>
    </Layout>
  );
};

const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
    dateRange: state.reportReducer.dateRange,
    screenFilter: state.reportReducer.screenFilter
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Affiliate);