

import React, { Fragment, useState, useEffect } from "react";

import {
  Modal,
  Typography,
  Form,
  Input,
  Upload,
  Button,
  message,
  Select,
  Carousel,
  Checkbox,
} from "antd";
import "./manageCategoryModal.css";
import { responsive } from "../../../../Utils/Seller/Data";
import AddBrand from "./addBrand";
import Loading from "../../../GeneralComponents/Loading";
import * as ManageCategoryAPI from "../../../../ApiAction/AdminApiAction/categoryApi";
import { formatBrandList } from "../manageCategoryHelper";
import AddSpecifications from "./addSpecifications";
import DeleteIcon from "../../../../Assets/Images/Icons/Seller/delete.svg";
import { FILE_PATH } from "../../../../Utils/constants";
import { addTypeCategory } from "../../../../ApiAction/AdminApiAction/categoryApi";
import { addTypeImage } from "../../../../ApiAction/AdminApiAction/categoryApi";
import { updateCategoryType } from "../../../../ApiAction/AdminApiAction/categoryApi";
import { getLogAttributes } from "../../Logger/notificationBar/LogHelper";
import AddSalesIncentiveRanges from "./addSalesIncentiveRanges";

const { Text } = Typography;
const ManageCategoryModal = (props) => {
  const {
    visible,
    setvisible,
    type,
    categoryData,
    parentCategoryId,
    level,
    refetch,
    setrefetch,
    categoryName,
    categoryList,
  } = props;
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const { Option } = Select;

  const [loading, setloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [brandIds, setbrandIds] = useState([]);
  const [topBrandIds, settopBrandIds] = useState([]);
  const [draftList, setdraftList] = useState([]);
  const [specificationsDraftList, setspecificationsDraftList] = useState([]);
  const [salesIncentiveRangesDraftList, setSalesIncentiveRangesDraftList] = useState([]);
  const [initialItem, setInitialItems] = useState();
  const [uploading, setUploading] = useState(false);
  const [newImgUrl, setNewImgUrl] = useState();
  const confirm = Modal.confirm;
  const [levelZeroList, setLevelZeroList] = useState([]);
  const [isSellerEnabled, setIsSellerEnabled]= useState(false)

  function onChange(checkedValues) {}

  const onImageChange = {
    accept:
      "image/png, image/jpeg,image/webp, image/jpg, video/mp4, video/mp3, video/mov, video/avi",
    name: "file",
    multiple: false,
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload(info) {
      const isLt2M = info.size / 1024 / 1024 < 4;
      const isLessThan = info.size / 1000;
      // if (isLessThan < 100) {
      //   message.error("File size should be greater than 100 KB");
      //   setUploading(false);
      //   return false;
      // }
      // if (false) {
      //   message.error("File size should be less than 4 MB");
      //   setUploading(false);
      //   return false;
      // } else
      if (
        !(
          info.type === "image/png" ||
          info.type === "image/jpeg" ||
          info.type === "image/jpg" ||
          info.type === "image/webp"
        )
      ) {
        message.error("Please Upload a valid Image format(png,jpeg,jpg,webp)");
        setUploading(false);
        return false;
      } else if (newImgUrl) {
        message.error("Image already Uploaded");
        setUploading(false);
        return false;
      }
    },
    onChange(info) {
      if (info.file.status === "done") {
        setUploading(false);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setNewImgUrl({
            url: reader.result,
            file: info.file.originFileObj,
          });
        });
        reader.readAsDataURL(info.file.originFileObj);
        return;
      }
    },
  };

  const handleDeleteImage = (item) => {
    confirm({
      title: "Do you want to delete this Category image!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        setNewImgUrl();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSubmitForm = (values) => {
    if (type === "Add Category") {
      if (level === -1) {
        const payload = {
          categoryName: values.categoryName,
          level: -1,
        };
        addTypeCategory(payload)
          .then((response) => {
            if (response?.data.status === "success") {
              const formData = {
                id: response?.data.id,
                files: newImgUrl.file,
              };
              console.log("Form Data :: ", formData)
              addTypeImage(formData)
                .then((response) => {})
                .catch((error) => {
                  console.log("Error in Uploading Image :: " + error.message);
                })
                .finally(() => {
                  setNewImgUrl();
                });
            }
          })
          .catch((error) => {
            console.log("Error in uploading Type category :: " + error.message);
          })
          .finally(() => {
            setvisible(false);
            setrefetch(!refetch);
          });
      } else {
        let payload = {
          categoryName: values.categoryName,
          categoryCommission: Number(values.categoryCommission),
          minCategoryDiscount: Number(values.minCategoryDiscount),
          categoryFeature: specificationsDraftList,
          brandIds: brandIds,
          topBrandIds: topBrandIds,
          level: level,
          isSellerEnabled: isSellerEnabled,
        };
        payload = getLogAttributes(payload, "Manage Categories")
        if (level >= 0) {
          payload["parentCategoryId"] = parentCategoryId;
        }

        if (brandIds.length > 0) {
          payload["brandIds"] = brandIds;
        }

        if (topBrandIds.length > 0) {
          payload["topBrandIds"] = topBrandIds;
        }

        setloading(true);

        ManageCategoryAPI.addCategory(payload)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                message.success(
                  `Category ${values.categoryName} added successfully.`
                );
                setrefetch(!refetch);
                setvisible(false);
                form.resetFields();
              } else {
                setErrorMessage(response.data.message);
                message.error(response.data.message);
              }
            } else {
              message.error("Unable to add Category please try again");
              setErrorMessage("Unable to add Category please try again");
            }
          })
          .catch((error) => {
            setErrorMessage(error.response.data.message);
          })
          .finally(() => {
            setloading(false);
          });
      }
    } else if (type === "Edit Category") {
      if(level === -1) {
        const payload = {
          category : {
            categoryName: values.categoryName,
            id: categoryData?.categoryId,
          },
          version: categoryData?.version
        }
        setloading(true)
        updateCategoryType(payload).then((response) => {
          if(response?.data?.status === 'success') {
            message.success(
              `Category Type updated successfully.`
            );
            if(newImgUrl?.file) {
              const formData = {
                id: categoryData?.categoryId,
                files: newImgUrl.file,
              };
              addTypeImage(formData)
                .then((response) => {
                  if(response?.data?.status === 'success'){
                    message.success("Category Image updated Successfully...");
                  }else {
                    message.error("Category Image Update failed, Please try again.");
                  }
                })
                .catch((error) => {
                  console.log("Error in Uploading Image :: " + error.message);
                  message.error("Category Image Update failed, Please try again.");
                })
                .finally(() => {
                  setNewImgUrl();
                });
            }else {
              console.log("No need to update image")
            }
          }
        }).catch((error)=> {
          console.log("Error while Updating Category Type :: "+error.message);
          message.error("Oops!! Something went wrong, Please Try again.");
        }).finally(()=> {
          setvisible(false);
          setloading(false);
          setrefetch(!refetch)
        })
      }else {
      const payload = {
        category: {
          id: categoryData.categoryId,
          brandIds: brandIds,
          topBrandIds: topBrandIds,
          categoryFeature: specificationsDraftList,
          isSellerEnabled: isSellerEnabled,
        },
        version: categoryData.version,
      };

      if(level > 1 && categoryData?.salesIncentiveRanges !== salesIncentiveRangesDraftList) {
        payload["category"]["salesIncentiveRanges"] = salesIncentiveRangesDraftList;
      }
      if (values.categoryName !== categoryData.categoryName) {
        payload["category"]["categoryName"] = values.categoryName;
      }
      if (values.minCategoryDiscount !== categoryData.minCategoryDiscount) {
        payload["category"]["minCategoryDiscount"] = Number(
          values.minCategoryDiscount
        );
      }
      if (values.categoryCommission !== categoryData.categoryCommission) {
        payload["category"]["categoryCommission"] = Number(
          values.categoryCommission
        );
      }

      setloading(true);
      ManageCategoryAPI.updateCategory(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              message.success(response.data.message);
              setrefetch(!refetch);
            } else {
              setErrorMessage(response.data.message);
              message.error(response.data.message);
            }
          } else {
            message.error("Unable to edit Category please try again");
            setErrorMessage("Unable to edit Category please try again");
          }
        })
        .catch((error) => {
          if (error?.response) {
            setErrorMessage(error.response.data.message);
          }
        })
        .finally(() => {
          setloading(false);
          setvisible(false);

          form.resetFields();
        });
      }
    }
  };

  useEffect(() => {
    form.resetFields();

    if (type === "Edit Category" && categoryData) {
      const initialValues = {
        categoryName: categoryData.categoryName,
        minCategoryDiscount: categoryData.minCategoryDiscount,
        categoryCommission: categoryData.categoryCommission,
      };
      setInitialItems(initialValues);
      setIsSellerEnabled(categoryData?.isSellerEnabled)
      setdraftList(formatBrandList(categoryData.brands));
      setspecificationsDraftList(
        categoryData?.categoryFeature ? categoryData.categoryFeature : []
      );
      setSalesIncentiveRangesDraftList(
        categoryData?.salesIncentiveRanges ? categoryData?.salesIncentiveRanges : []
      );
      categoryData.brandIds && setbrandIds(categoryData.brandIds);
      categoryData.topBrandIds && settopBrandIds(categoryData.topBrandIds);
      setNewImgUrl({
        url: FILE_PATH + categoryData?.typeImage
      })
    }

  }, [props]);

  useEffect(() => {}, [initialItem]);


  return (
    <Modal
      title={<h1 className="sub-title-modal-tags">{type}</h1>}
      visible={visible}
      onOk={() => {
        setvisible(false);
        form.resetFields();
      }}
      onCancel={() => {
        setvisible(false);
        form.resetFields();
      }}
      footer={null}
      className="add-category-modal"
    >
      <Fragment>
        <div className="manage-cat-modal-breadcrumb">
          <Text strong>Category & Subcategory</Text>
        </div>

        <Form
          form={form}
          className="manage-category-modal-form"
          initialValues={
            type === "Edit Category"
              ? { ...initialItem, categoryName: categoryName }
              : {}
          }
          onFinish={handleSubmitForm}
        >
          <Text>Category Name **</Text>
          <Form.Item name="categoryName" rules={[{ required: true }]}>
            <Input size="large" />
          </Form.Item>

          {/* Checking whether the selected Modal is Not for adding type */}
          {level !== -1 && (
            <Fragment>
              <Text>Platform charges (%)</Text>
              <Form.Item name="categoryCommission" rules={[{ required: true }]}>
                <Input size="large" />
              </Form.Item>
              <Text>Minimum Category Discount (%)</Text>
              <Form.Item
                name="minCategoryDiscount"
                rules={[{ required: true }]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="isSellerEnabled"
              >
                <Checkbox
                checked={isSellerEnabled}
                onChange={()=>setIsSellerEnabled(!isSellerEnabled)}>
                  Allow Seller
                </Checkbox>
              </Form.Item>
              <div className="manage-category-modal-section-title">
                <Text>Specifications</Text>
                {/* <img src={AddIcon} alt="add-color-icon" className="add-spec-icon" /> */}
              </div>
              <AddSpecifications
                specificationsDraftList={specificationsDraftList}
                setspecificationsDraftList={setspecificationsDraftList}
              />
              {/* {level > 1 && (
                <Fragment>
                  <div className="manage-category-modal-section-title">
                    <Text>Sales Incentive Ranges</Text>
                  </div>
                  <AddSalesIncentiveRanges
                    salesIncentivesDraftList={salesIncentiveRangesDraftList}
                    setSalesIncentivesDraftList={setSalesIncentiveRangesDraftList}
                  />
                </Fragment>
              )} */}

              {/* <Fragment>
            <Checkbox.Group
              style={{ width: "100%" }}
              onChange={onChange}
              className="specs-checkbox"
            >
              <Row>
                <Col span={8} className="manage-category-each-specs">
                  <Checkbox value="A">
                    <Text>Specifications</Text>
                  </Checkbox>
                  <div className="cat-icon-main">
                    <img
                      src={DeactivateIcon}
                      className="cat-icon"
                      alt="cat-deact-icon"
                    />
                    <img
                      src={DeleteIcon}
                      className="cat-icon"
                      alt="cat-del-icon"
                    />
                  </div>
                </Col>
                <Col span={8} className="manage-category-each-specs">
                  <Checkbox value="B">Specifications</Checkbox>
                  <div className="cat-icon-main">
                    <img
                      src={DeactivateIcon}
                      className="cat-icon"
                      alt="cat-deact-icon"
                    />
                    <img
                      src={DeleteIcon}
                      className="cat-icon"
                      alt="cat-del-icon"
                    />
                  </div>
                </Col>
                <Col span={8} className="manage-category-each-specs">
                  <Checkbox value="C">Specifications</Checkbox>
                  <div className="cat-icon-main">
                    <img
                      src={DeactivateIcon}
                      className="cat-icon"
                      alt="cat-deact-icon"
                    />
                    <img
                      src={DeleteIcon}
                      className="cat-icon"
                      alt="cat-del-icon"
                    />
                  </div>
                </Col>
                <Col span={8} className="manage-category-each-specs">
                  <Checkbox value="D">Specifications</Checkbox>
                  <div className="cat-icon-main">
                    <img
                      src={DeactivateIcon}
                      className="cat-icon"
                      alt="cat-deact-icon"
                    />
                    <img
                      src={DeleteIcon}
                      className="cat-icon"
                      alt="cat-del-icon"
                    />
                  </div>
                </Col>
                <Col span={8} className="manage-category-each-specs">
                  <Checkbox value="E">Specifications</Checkbox>
                  <div className="cat-icon-main">
                    <img
                      src={DeactivateIcon}
                      className="cat-icon"
                      alt="cat-deact-icon"
                    />
                    <img
                      src={DeleteIcon}
                      className="cat-icon"
                      alt="cat-del-icon"
                    />
                  </div>
                </Col>
              </Row>
            </Checkbox.Group>
          </Fragment> */}

              <div className="manage-category-modal-section-title">
                <Text>Brands</Text>
              </div>
              <AddBrand
                brandIds={brandIds}
                topBrandIds={topBrandIds}
                setbrandIds={setbrandIds}
                settopBrandIds={settopBrandIds}
                draftList={draftList}
                setdraftList={setdraftList}
              />
            </Fragment>
          )}

          {level === -1 && (
            <Fragment>
              {/* to select subcategory from the list */}
              <div className="manage-category-modal-section-title">
                <Text>Type Image</Text>
              </div>
              <Dragger
                {...onImageChange}
                className="dragger-add-product"
                showUploadList={false}
                style={{ width: "100%" }}
              >
                {uploading ? (
                  <Loading />
                ) : (
                  <div>
                    <h5>Drag and drop files here</h5>
                    <Button
                      type="primary"
                      disabled={newImgUrl ? true : false}
                      title={newImgUrl && "Remove added image to Select New"}
                    >
                      Browse
                    </Button>
                  </div>
                )}
              </Dragger>

              {newImgUrl && (
                <div className="add-type-preview">
                  <Carousel className="carousel-container-addimage">
                    {newImgUrl ? (
                      <div className="add-product-image">
                        <img
                          src={newImgUrl.url}
                          alt="loremIpsum"
                          className="image-add-type"
                        />
                        <img
                          src={DeleteIcon}
                          alt="loremIpsum"
                          onClick={() => {
                            if (newImgUrl.file === "") {
                              // this will be true when we update the image
                              handleDeleteImage(newImgUrl.url);
                            } else {
                              // this will be true when we add a new image and remove that image
                              confirm({
                                title:
                                  "Do you want to delete this Category image!!",
                                className: "create-confirm-modal",
                                centered: true,
                                cancelText: "No",
                                okText: "Yes",
                                onOk: () => {
                                  setNewImgUrl();
                                  message.success(
                                    "Image Removed Successfully..."
                                  );
                                },
                              });
                            }
                          }}
                          className="add-image-delete-category"
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </Carousel>
                </div>
              )}
            </Fragment>
          )}

          <div className="manage-category-error-message">
            <Text style={{ color: "red" }}>
              {errorMessage ? errorMessage : ""}
            </Text>
          </div>

          <div className="manage-category-action">
            <Form.Item>
            </Form.Item>
            <Button
              className="manage-category-action-cancel-btn"
              type="primary"
              onClick={() => setvisible(false)}
            >
              Cancel
            </Button>
            <Button
              className="manage-category-action-ok-btn"
              type="primary"
              htmlType="submit"
            >
              {loading ? <Loading style={{ color: "white" }} /> : "Save"}
            </Button>
          </div>
        </Form>
      </Fragment>
    </Modal>
  );
};

export default ManageCategoryModal;
