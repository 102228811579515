


import { API_SERV_ADDR } from "../../Utils/constants";
import Axios from "../axios";

import Cookies from "universal-cookie";
const cookies = new Cookies();

export const addServicableLocations = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/store-warehouse/add-serviceable-locations`,
    method: "POST",
    data: payload,
  });
};
