import React, { useState } from 'react'
import './requestPayment.scss'
import RequestPaymentPage from "./RequestPaymentPage"
import { Tabs, Divider,Popover } from 'antd';
import { SafetyCertificateFilled,InfoCircleOutlined } from '@ant-design/icons'
import RequestStatusPage from './RequestStatusPage';
import Cookies from 'universal-cookie';
import animGif from '../../../Assets/Images/Vectors/requestPayment.gif'

function RequestPayment() {
    const {TabPane} = Tabs;
    const cookies = new Cookies()
    const [vendorId] = useState(cookies.get("vendorId"))
    const [activeTab, setActiveTab] = useState(1)

    const handleActiveTab = (activeTab) => {
        setActiveTab(activeTab)
    }
    
    // Content of Informations will be stored inside this function
    const inform = (
        <div>
            <img src={animGif} alt="Instruction" />
        </div>
      );

    return (
        <div className="request-payment">
            <div className="request-top-part">
                <h2 style={{ fontWeight: "bold" }}><SafetyCertificateFilled /> Request Payment</h2>
                {/* <h4 style={{color: "rgba(0,0,0,0.5)"}}>Some detailed text about this service</h4> */}
                {/* This Popover will display information about this Service */}
                <Popover placement="leftTop" className="pop-info" content={inform}  title="Instruction" trigger="click">
                    <p className="info-button"><InfoCircleOutlined /> info</p>
                </Popover>
            </div>
            <Divider />
            <Tabs 
             defaultActiveKey={activeTab}
             onChange={handleActiveTab} >
                {/* Default Tab this will display all the Payments of Venter and they can select
                from from the list for payment */}
                <TabPane tab="Request Payment" key="1">
                    <RequestPaymentPage
                        vendorId={vendorId}
                        activeTab={activeTab}
                    />
                </TabPane>
                {/* This Tab this will display all the Payments Status */}
                <TabPane tab="Request Status" key="2">
                    <RequestStatusPage 
                        vendorId={vendorId}
                        activeTab={activeTab}
                    />
                </TabPane>
            </Tabs>

        </div>
    )
}

export default RequestPayment
