


import React, { useEffect, useState } from "react";

import { Tabs, Typography, Pagination } from "antd";
import { connect } from "react-redux";

import "./manageOrders.scss";

import OrderList from "./Components/orderList";
import EmptyList from "../../../GeneralComponents/emptyList";

import {
  fetchOrderList,
  fetchSubscribeList,
  cancelSubscribeList,
} from "../../../../ApiAction/CustomerApiActions/orderAPI";
import SubscribeList from "./SubscribeList";

const { TabPane } = Tabs;
const { Text } = Typography;

const ManageMyOrders = (props) => {
  const [loading, setloading] = useState(false);
  const [orderList, setorderList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [filter, setfilter] = useState("ACTIVE");
  const [pageNo, setpageNo] = useState(1);
  const [totalCount, settotalCount] = useState(0);

  const tabOptions = [
    {
      key: "ACTIVE",
      label: "Active",
    },
    {
      key: "PAST",
      label: "Past",
    },
  ];

  const subsribeobj = {
    key: "SUBSCRIPTION",
    label: "Subscription",
  };
  if (props.isCustomer) tabOptions.push(subsribeobj);

  const tabChangeHandler = (key) => {
    if (key == "SUBSCRIPTION") getSubscription();
    else setfilter(key);
  };

  const getSubscription = (page = 1) => {
    setpageNo(page);
    let payload = {
      // userId:cookies.get("userId"),
      // key: "",
      offset: (page - 1) * 20,
      size: 20,

      sortBy: "subscribedDate",
      sortType: "asc",
    };
    setloading(true);
    fetchSubscribeList(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setProductList(response.data.documents);
            settotalCount(Math.ceil(response.data.count / 10) * 10);
          }
        }
      })
      .catch((error) => {})
      .finally(() => {
        setloading(false);
      });
  };

  const fetchOrderListHandler = (search = props.searchVal,page = 1) => {
    setpageNo(page);
    let payload = {
      key: search,
      offset: (page - 1) * 10,
      size: 10,
      filter: filter,
      sortBy: "orderedDate",
      sortType: "desc",
    };

    if (props.sort?.sortBy) {
      payload["sortType"] = props.sort.sortBy;
    }
    setloading(true);
    fetchOrderList(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setorderList(response.data.orders);
            settotalCount(Math.ceil(response.data.count / 10) * 10);
          }
        }
        console.log("response for fetch order list: ", response);
      })
      .catch((error) => {})
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (filter == "SUBSCRIPTION") getSubscription();
    else fetchOrderListHandler(props.searchVal,1);
  }, [props.refetchOrderList, props.sort,props.searchVal]);

  useEffect(() => {
    fetchOrderListHandler(props.searchVal,1);
  }, [filter]);

  const cancelSubscribe = (id) => {
    const payload = {
      subscriptionId: id,
    };
    cancelSubscribeList(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            getSubscription();
          }
        }
      })
      .catch((error) => {})
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <div
      className="manage_orders_container_card admin-manage-customer cust-ordr-only"
      style={{ paddingTop: "0" }}
    >
      <h1 strong className="manae_orders_title sub-title-tags">
        Orders
      </h1>

      <Tabs
        defaultActiveKey="ACTIVE"
        onChange={tabChangeHandler}
        className="fixed_tab_ds"
      >
        {tabOptions.map((tab) => {
          return (
            <TabPane tab={tab.label} key={tab.key} forceRender={true}>
              {tab.key == "SUBSCRIPTION" ? (
                <SubscribeList
                  productList={productList}
                  loading={loading}
                  cancelSubscribe={cancelSubscribe}
                />
              ) : orderList.length === 0 && !loading ? (
                <EmptyList msg={"No " + tab.label + " orders found"} /> //case when orders list is empty
              ) : (
                <OrderList orderList={orderList} loading={loading} />
              )}
            </TabPane>
          );
        })}
      </Tabs>
      {totalCount > 10 && (
        <Pagination
          size="small"
          total={totalCount}
          defaultPageSize={10}
          current={pageNo}
          defaultCurrent={1}
          showSizeChanger={false}
          className="customer-pagination"
          onChange={(page) => {
            // setpageNo(page);
            if (filter !== "SUBSCRIPTION") {
              fetchOrderListHandler(props.searchVal,page);
            } else {
              getSubscription(page);
            }
            window.scrollTo(0, 0);
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    refetchOrderList: state.customerOrderReducer.refetchOrderList,
    sort: state.customerData,
  };
};

export default connect(mapStateToProps, null)(ManageMyOrders);
