


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Typography } from "antd";
import "./manageBrand.css";

import { fetchBrandList } from "../../../ApiAction/AdminApiAction/brandApi";
import { formatBrandData } from "./manageBrandHelper";

import ManageStoreTable from "../ManageStore/manageStoreTable";
import AddEditBrandModal from "./Components/addEditBrandModal";

const { Title } = Typography;
const BrandData = [
  {
    id: 12340,
    name: "Nike",
    top: true,
    active: true,
    archive: true,
  },
  {
    id: 12341,
    name: "Puma",
    top: true,
    active: true,
    archive: true,
  },
];

const ManageBrand = (props) => {
  const { modalVisible, setModalVisible } = props;
  const [brandList, setbrandList] = useState([]);
  const [editItem, seteditItem] = useState();
  const [isEdit, setisEdit] = useState(false);
  const [refetchBrandList, setrefetchBrandList] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingBrand, setisLoadingBrand] = useState(false);

  const handlEditbrand = (brand) => {
    seteditItem(brand);
    setModalVisible(true);
    setisEdit(true);
  };

  const getBrandList = (page, search = props.searchVal) => {
    setCurrentPage(page)
    let filterBy;
    if (props.sort && props.sort.all === false) {
      filterBy = {
        isArchived: props.sort ? (props.sort.archive ? true : false) : false,
        block: props.sort ? (props.sort.block ? true : false) : false,
      };
    }
    const payload = {
      key: search,
      offset: 10 * (page - 1),
      size: 10,
      isArchived: filterBy?.isArchived,
      block: filterBy?.block,
    };

    //this format is to be used in all cases (list user api also )
    if (props.sort?.sortType) {
      payload["sortBy"] =
        props.sort.sortType === "name" ? "brandName" : "lastModifiedDate";
    }

    if (props.sort?.sortBy) {
      payload["sortType"] = props.sort.sortBy;
    }

    setisLoadingBrand(true);
    fetchBrandList(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setbrandList(formatBrandData(response.data.documents));
            setPage(Math.ceil(response.data.count / 10) * 10);
          }
        }
      })
      .finally(() => {
        setisLoadingBrand(false);
      });
  };

  useEffect(() => {
    getBrandList(1, props.searchVal);
  }, [refetchBrandList, props]);

  return (
    <div className="admin-manage-customer">
      <h1 className="content-name-header sub-title-tags" level={4}>
        {" "}
        Brand
      </h1>
      <ManageStoreTable
        userData={brandList}
        isBrand
        isLoadingBrand={isLoadingBrand}
        editUser={handlEditbrand}
        total={page}
        currentPage={currentPage}
        onPageChange={getBrandList}
      />
      <AddEditBrandModal
        visible={modalVisible}
        setvisible={setModalVisible}
        editItem={editItem}
        isEdit={isEdit}
        setisEdit={setisEdit}
        refetchBrandList={refetchBrandList}
        setrefetchBrandList={setrefetchBrandList}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};

export default connect(mapStateToProps)(ManageBrand);
