

import React, { useState, useEffect, Fragment, useRef } from "react";
import { Button, Col, Row, Radio, message, Modal } from "antd";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import { PlusSquareOutlined } from "@ant-design/icons";
import CartAddressList from "./cartAddressList";
import AddAddress from "./addAddress";
import LoadingGif from "../../../../Assets/Images/Icons/loading.gif";
import {
  fetchUserAddressList,
  setasDefaultAddress,
} from "../../../../ApiAction/CustomerApiActions/addressAPI";
import { cartGet } from "../../../../ApiAction/SellerApiActions/sellerApi";
import { calculateDeliveryCharge } from "../../../../ApiAction/SellerApiActions/deliveryChargeAPI";
import {
  paymentFailed,
  placeOrder,
  verifyPayment,
} from "../../../../ApiAction/CustomerApiActions/orderAPI";
import {
  fetchOrderResponse,
  fetchOrderFailure,
} from "../../../../ReduxStore/action/customerOrderAction";
import {
  fetchCartCount,
  fetchCartItems,
} from "../../../../ReduxStore/action/index";
import Loading from "../../../GeneralComponents/Loading";
import {
  DOMAIN,
  ENV_TYPE
} from "../../../../Utils/constants";
import Text from "antd/lib/typography/Text";
import cart from "./cart";
const confirm = Modal.confirm;
const Cartpayment = (props) => {
  const cookies = new Cookies();
  const accountType = cookies.get("accountType");

  const [isAddress, setIsAddress] = useState(
    ["warehouse", "store"].includes(accountType) ? false : true
  );
  const [addAddressVisible, setAddAddressVisible] = useState(false);
  const [editItem, setEditItem] = useState();
  const [addressList, setaddressList] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState();

  const [totalPrice, setTotalPrice] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [totalMrp, setTotalMrp] = useState("");
  const [deliveryCharge, setdeliveryCharge] = useState(0);
  const [cartTotal, setcartTotal] = useState(0);
  const [vendorDeliveryChargeMap, setvendorDeliveryChargeMap] = useState();

  const [paymentMode, setpaymentMode] = useState("ONLINE");
  const [paymentGateway, setPaymentGateway] = useState("CCAVENUE");
  const [cartId, setcartId] = useState();
  const [loading, setloading] = useState(false);
  const [isDeliverable, setisDeliverable] = useState(true);
  const [canProceed, setcanProceed] = useState(true);
  const [loadingDeliveryCharge, setloadingDeliveryCharge] = useState(false);
  const [deliveryChargeError, setdeliveryChargeError] = useState(false);

  const [isSellerItemFoundInCart, setIsSellerItemFoundInCart] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [paymentGatewayModalVisible, setPaymentGatewayModal] = useState(false);
  const [ccavenueRequest, setCcavenueRequest] = useState();
  const CCAvenuePaymentForm = useRef();

  const getValue = (value) => {
    if (value !== null) return value;
    // if (value !== null) return value + ",";
    else return "";
  };

  useEffect(() => {
    if (deliveryAddress?.pinCode && cookies.get("accountType") === "customer") {
      console.log("deliveryAddress: ", deliveryAddress?.pinCode);
      handleGetDeliveryCharge(deliveryAddress.pinCode);
    }
  }, [deliveryAddress]);

  const handleGetDeliveryCharge = (pincode) => {
    let payload = {
      cartId: cartId,
      pinCode: pincode,
    };
    setcanProceed(false);
    setloadingDeliveryCharge(true);
    calculateDeliveryCharge(payload)
      .then((response) => {
        console.log(response.data, ":response for delivery charge calulation");
        if (response.status === 200) {
          if (response.data.status === "success") {           
            setdeliveryChargeError(false);
            setisDeliverable(true);
            console.log(response, ":reponse for calculating delivery charge");
            setdeliveryCharge(response.data?.priceSummary?.deliveryCharge);
            setcartTotal(response.data?.priceSummary?.totalPrice);
            setvendorDeliveryChargeMap(response.data?.vendorDeliveryChargeMap);
          } else if (response?.data?.cartItemIds) {
            let data = {
              message: response.data.message,
              cartItemIds: response.data.cartItemIds,
              lowStockIds: response.data.lowStockIds,
            };
            handleOrderFailure(data, response.data.failureType);
          } else {
            message.error(response?.data?.message);
            handleDeliveryChargeCalculationError();
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setcanProceed(true);
        setloadingDeliveryCharge(false);
      });
  };

  const handleRedirectToOrderResponse = (orderStatus) => {
    switch (accountType) {
      case "customer":
        props.history.push(
          ENV_TYPE === "production"
            ? `/order-response/${orderStatus}`
            : `/customer/order-response/${orderStatus}`
        );
        break;
      case "warehouse":
        props.history.push(`/warehouse/order-response/${orderStatus}`);
        break;
      case "store":
        props.history.push(`/store/order-response/${orderStatus}`);
        break;
      default:
        console.log("invalid userType");
    }
    window.scrollTo(0, 0);
  };

  const handleRedirectToCartPage = () => {
    switch (accountType) {
      case "customer":
        props.history.push(
          ENV_TYPE === "production" ? `/cart` : `/customer/cart`
        );
        break;
      case "warehouse":
        props.history.push(`/warehouse/cart`);
        break;
      case "store":
        props.history.push(`/store/cart`);
        break;
      default:
        console.log("invalid userType");
    }
  };

  const handleRedirectToCartFailureCase = () => {
    switch (accountType) {
      case "customer":
        props.history.push(
          ENV_TYPE === "production"
            ? `/cart/remove-items`
            : `/customer/cart/remove-items`
        );
        break;
      case "store":
        props.history.push(`/warehouse/cart/remove-items`);
        break;
      case "store":
        props.history.push(`/store/cart/remove-items`);
        break;
      default:
        console.log("invalid choice");
    }
  };

  const handleDeliveryChargeCalculationError = () => {
    confirm({
      width: 800,
      title: "Some thing went wrong please try again",
      className: "create-confirm-modal",
      centered: true,
      okText: "Retry",
      cancelText: null,
      onOk: () => {
        handleGetDeliveryCharge(deliveryAddress.pinCode);
      },
      onCancel: () => {
        setdeliveryChargeError(true);
      },
    });
  };
  const handleOrderFailure = (data, type) => {
    confirm({
      width: 800,
      title: `Some Items in the cart  ${data.message}, do you want to remove them from cart ?`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: type === "STOCK" ? "Cancel" : `Change ${type}`,
      okText: "Yes",
      onOk: () => {
        props.fetchOrderFailure(data);
        handleRedirectToCartFailureCase();
      },
      onCancel() {
        if (type == "ADDRESS") {
          setIsAddress(true);
        } else {
          console.log("cancel");
        }
      },
    });
  };

  const paymentHandler = async (data) => {
    setRedirect(true);
    setPaymentGatewayModal(false);
    if(data.paymentGateway === "PHONEPE") {
      if(data.redirectInfo) {
        setTimeout(() => {
          window.location.replace(data.redirectInfo.url);
        }, 1000);
      } else message.error("Order creation failed");
    } else {
      if(data.ccavenuePaymentRequest) {
        setCcavenueRequest(data.ccavenuePaymentRequest);
      }
    }
  };

  const handleContinueWithSelectedAddress = () => {
    if (deliveryChargeError) {
      handleGetDeliveryCharge(deliveryAddress.pinCode);
    } else if (deliveryAddress) {
      setIsAddress(false);
      window.scrollTo(0, 0);
    } else message.warning("Select a shipping address to continue");
  };

  const handleCartRedirection = () => {
    switch (cookies.get("accountType")) {
      case "warehouse":
        props.history.push("/warehouse/cart");
        break;
      case "store":
        props.history.push("/store/cart");
        break;
      case "customer":
        props.history.push(
          ENV_TYPE === "production" ? `/cart` : `/customer/cart`
        );
        break;
      default:
        props.openLoginModal("customer");
        console.log("invalid choice");
    }
  };

  const handlePlaceOrder = () => {

    if(paymentMode === "ONLINE") {
      setPaymentGatewayModal(true);
    } else {
      placeOrderApiCall();
    }
    
  };

  const placeOrderApiCall = () => {

    const payload = {
      accountType: accountType,
      paymentMode: paymentMode,
      paymentGateway: paymentMode == "ONLINE"? paymentGateway: null, 
      cartId: cartId,
      totalPrice: cartTotal ? cartTotal : totalPrice,
      subTotal: totalPrice
    };
    //addressId, deliveryCharge and vendorDeliveryChargeMap is only required for the case of customer
    if (accountType === "customer") {
      payload["pinCode"] = deliveryAddress.pinCode;
    }
    
    if(accountType === "store") {
      payload["pinCode"] = cookies.get("activeUserPincode")
    }
    
    if (deliveryAddress?.id && accountType === "customer") {
      payload["addressId"] = deliveryAddress.id;
    }

    if (deliveryCharge) payload["deliveryCharge"] = deliveryCharge;
    if (vendorDeliveryChargeMap)
      payload["vendorDeliveryChargeMap"] = vendorDeliveryChargeMap;

    if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
      payload["appType"] = "mobile";
    } else {
      payload["appType"] = "system";
    }
    setloading(true);
    placeOrder(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            if (paymentMode == "ONLINE") {
              paymentHandler(response.data.order);
            } else {
              message.success("order placed successfully ");
              props.fetchOrderResponse(response.data.order);
              props.fetchCartCount(0);
              props.fetchCartItems([]);
              handleRedirectToOrderResponse("success");
              window.scrollTo(0, 0);
            }
          }
          // else if (response?.data?.cartItemIds) {
          //   let data = {
          //     message: response.data.message,
          //     cartItemIds: response.data.cartItemIds,
          //     lowStockIds: response.data.lowStockIds,
          //   };
          //   handleOrderFailure(data, response.data.failureType);
          // }
          else if (response.data.message === "Order could not be placed") {
            // handleRedirectToOrderResponse("failed");
            message.error(response?.data?.message);
          } else {
            message.error(response?.data?.message);
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  }

  const setSuccesRegister = () => {
    setAddAddressVisible(false);
    fetchAddress();
  };

  const fetchAddress = () => {
    if (accountType === "customer") {
      fetchUserAddressList()
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              setaddressList(response.data.address);
              setDeliveryAddress(
                response.data.address.find((item) => item.primary === true)
              );
            }
          }
          console.log("fetch user address list response: ", response);
        })
        .catch(() => {});
    }
  };
  const selectMaster = (id) => {
    setaddressList(
      addressList.map((item) =>
        item.id === id
          ? { ...item, primary: true }
          : { ...item, primary: false }
      )
    );
    setDeliveryAddress(addressList.find((item) => item.id === id));
  };

  useEffect(() => {
    fetchAddress();
    const paylaod = {
      userId: cookies.get("userId"),
      accountType: accountType,
    };
    cartGet(paylaod)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setcartId(response.data.cart.cartId);
            setTotalDiscount(response.data.cart.totalDiscount);
            setTotalPrice(response.data.cart.totalPrice);
            setTotalMrp(response.data.cart.bagTotal);
            findForSeller(response?.data?.cart?.cartItems)
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  // this function is used to find out wheather there is any seller's item
  // found inside the user cart.Sellers are not accepting cod right now,
  // so we need to restrict them from doing cod

  useEffect(() => {
    if(CCAvenuePaymentForm.current)
      CCAvenuePaymentForm.current.submit();
  }, [ccavenueRequest]);

  const findForSeller = (cartItems) => {
    let sellerFlag = false
    let returnValues = cartItems?.map((item)=> {
      if(item.vendorType === 'SELLER') {
        sellerFlag = true;
        return item.vendorType
      }
    })
    if(sellerFlag) {
      setIsSellerItemFoundInCart(true)
    }else {
      setIsSellerItemFoundInCart(false)
    }
  }

  const handleSetDefaultAddress = (id) => {
    const payload = {
      addressId: id,
    };

    setasDefaultAddress(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setaddressList(
              addressList.map((item) =>
                item.id === id
                  ? { ...item, primary: true }
                  : { ...item, primary: false }
              )
            );
            setDeliveryAddress(addressList.find((item) => item.id === id));
          } else message.error(response.data.message);
        }
      })
      .catch(() => {});
  };

  const getValueWithFormat = (data) => {
    if (data) {
      const filterArray = [
        // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
        deliveryAddress?.addressLine1 ? deliveryAddress?.addressLine1 : null,
        deliveryAddress?.addressLine2 ? deliveryAddress?.addressLine2 : null,
        deliveryAddress?.buildingName ? deliveryAddress?.buildingName : null,
        deliveryAddress?.district ? deliveryAddress?.district : null,
      ];
      console.log("data??", filterArray);

      const filtered = filterArray.filter(function (el) {
        return el != null;
      });
      const filteredNullStringValues = filtered.filter(Boolean);

      return (
        filteredNullStringValues &&
        filteredNullStringValues.toString().split(",").join(", ")
      );
    }
  };

  return (
    <div className="customer-product-listing-page-conatiner">
      <div className="cart-payment-layout container">
        <div>
          <Row style={{ width: "100%" }} className="pay_row_box">
            <Col span={14} className="addrd_colm">
              {isAddress ? (
                <div
                  className="cart-address-list"
                  style={{ marginRight: "15px" }}
                >
                  {addressList.map((item) => (
                    <CartAddressList
                      address={item}
                      selectMaster={selectMaster}
                      handleSetDefaultAddress={handleSetDefaultAddress}
                    />
                  ))}
                  <div
                    className="cart-add-new-address "
                    style={{ cursor: "pointer" }}
                    onClick={() => setAddAddressVisible(true)}
                  >
                    <PlusSquareOutlined style={{ fontSize: "30px" }} />
                    <div
                      style={{
                        color: "#4015da",
                        fontWeight: "500",
                        fontSize: "14px",
                        marginLeft: "20px",
                      }}
                    >
                      Deliver to another address
                    </div>
                  </div>
                </div>
              ) : (
                <div className="cart-payment-options ">
                  <div
                    className="cart-total-price-border"
                    style={{ paddingBottom: "20px" }}
                  >
                    <div
                      className="cart-total-mrp"
                      style={{ fontSize: "15px", fontWeight: "500" }}
                    >
                      Payment Options
                    </div>
                  </div>
                  <Radio.Group
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      padding: "0 0px",
                    }}
                    onChange={(e) => setpaymentMode(e.target.value)}
                    value={paymentMode}
                  >
                    {(accountType === "customer" || accountType === "store") && (
                      <Radio
                        value={"ONLINE"}
                        className="payment-radio"
                        style={{ borderBottom: "1px solid #f0f0f0" }}
                      >
                        Online Pay
                      </Radio>
                    )}
                    {!isSellerItemFoundInCart && (
                    <Radio value={"COD"} className="payment-radio">
                      Cash On Delivery
                    </Radio>
                    )}
                  </Radio.Group>
                </div>
              )}
            </Col>
            <Col span={10} className="prc_colm">
              <div className="cart-payment-price">
                <div className="cart-total-price-border">
                  <div className="cart-total-mrp">Cart Items</div>
                  <div
                    onClick={() => handleRedirectToCartPage()}
                    style={{
                      color: "#4015da",
                      fontWeight: "500",
                      fontSize: "13px",
                      cursor: "pointer",
                      textTransform: "uppercase",
                      lineHeight: "normal",
                      alignSelf: "center",
                    }}
                  >
                    Edit cart
                  </div>
                </div>
                <div className="cart-total-price">
                  <div className="cart-total-mrp">Total MRP</div>
                  <div className="cart-total-mrp">
                    ₹{" "}
                    <span style={{ color: "#3e6df3" }}>
                      {Number(totalMrp).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="cart-total-price">
                  <div className="cart-total-mrp">Discounts</div>
                  <div className="cart-total-mrp">
                    ₹{" "}
                    <span style={{ color: "#3e6df3" }}>
                      {Number(totalDiscount).toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="cart-total-price">
                  <div className="cart-total-mrp">Total DLP</div>
                  <div className="cart-total-mrp">
                    ₹{" "}
                    <span style={{ color: "#3e6df3" }}>
                      {Number(totalPrice).toFixed(2)}
                    </span>
                  </div>
                </div>

                <div className="cart-total-price">
                  <div className="cart-total-mrp">Delivery Charges</div>
                  {loadingDeliveryCharge ? (
                    <Loading />
                  ) : (
                    <div className="cart-total-mrp">
                      ₹{" "}
                      <span style={{ color: "#3e6df3" }}>
                        {Number(deliveryCharge).toFixed(2)}
                      </span>
                    </div>
                  )}
                </div>

                <div
                  className="cart-total-price"
                  style={{ margin: "20px 0px" }}
                >
                  <div className="cart-total-mrp">Total</div>
                  <div className="cart-total-mrp">
                    ₹{" "}
                    <span style={{ color: "#3e6df3" }}>
                      {cartTotal
                        ? Number(cartTotal).toFixed(2)
                        : Number(totalPrice).toFixed(2)}
                    </span>
                  </div>
                </div>

                {isAddress ? (
                  <Button
                    style={{ margin: "0px", width: "100%" }}
                    onClick={() => handleContinueWithSelectedAddress()}
                    // className="button-seller-request-approve final_checkout"
                    className={
                      isDeliverable
                        ? "button-seller-request-approve final_checkout"
                        : "button-seller-request-approve final_checkout disabled"
                    }
                    disabled={!isDeliverable || !canProceed}
                  >
                    Continue
                  </Button>
                ) : (
                  <div>
                    <div className="cart-total-price-border">
                      {accountType === "customer" && (
                        <Fragment>
                          <div className="cart-total-mrp">Deliver To</div>

                          <div
                            onClick={() => setIsAddress(true)}
                            style={{
                              color: "#4015da",
                              fontWeight: "500",
                              fontSize: "13px",
                              cursor: "pointer",
                              textTransform: "uppercase",
                            }}
                            className="chg_dlvy_addr"
                          >
                            Change delivery address
                          </div>
                        </Fragment>
                      )}
                    </div>
                    {deliveryAddress && (
                      <React.Fragment>
                        <div
                          className="cart-address-phone"
                          style={{
                            fontWeight: "500",
                            fontSize: "16px",
                            marginBottom: "10px",
                          }}
                        >
                          {getValue(deliveryAddress?.ownerName)},
                        </div>
                        <div
                          className="cart-address-phone"
                          style={{ marginBottom: "15px" }}
                        >
                          {/* {getValue(deliveryAddress?.addressLine1) +
                            getValue(deliveryAddress?.addressLine2)}
                          <br />
                          {getValue(deliveryAddress?.buildingName) +
                            getValue(deliveryAddress?.district)} */}{" "}
                          {getValueWithFormat(deliveryAddress)}
                          <br />
                          {"Pincode: " + getValue(deliveryAddress?.pinCode)}
                          <br />
                          {"Ph: " + getValue(deliveryAddress?.phoneNo)}
                        </div>

                        {/* <div
                          className="cart-address-phone"
                          style={{ margin: "10px 0px" }}
                        >
                          {"Ph: " + getValue(deliveryAddress?.phoneNo)}
                        </div> */}
                      </React.Fragment>
                    )}

                    <Button
                      disabled={loading}
                      style={{ margin: "0px", width: "100%" }}
                      onClick={() => handlePlaceOrder()}
                      className="button-seller-request-approve place-order-btn"
                    >
                      {loading ? <Loading /> : " Place Order"}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <AddAddress
        visible={addAddressVisible}
        editItem={editItem}
        setVisible={setAddAddressVisible}
        isCart
        setSuccess={setSuccesRegister}
      />

      <Modal
        visible={redirect}
        closable={false}
        footer={null}
        centered={true}
      >
        <div style={{textAlign: "center"}}>
          <Text style={{fontSize: "25px"}} strong>Redirecting to {paymentGateway}</Text>
          <div>
            <img style={{width: "150px"}} src={LoadingGif}/>
          </div>
        </div>
      </Modal>

      <Modal
        title="Select payment gateway"
        centered={true}
        okText={"Pay"}
        cancelText={null}
        visible={paymentGatewayModalVisible}
        onOk={() => placeOrderApiCall()}
        onCancel={() => setPaymentGatewayModal(false)}
      >

        <Radio.Group
          style={{
            width: "100%",
            // marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            padding: "0 0px",
          }}
          onChange={e => setPaymentGateway(e.target.value)}
          value={paymentGateway}
          confirmLoading={loading}
        > 
          <Radio
            value={"CCAVENUE"}
            className="payment-radio"
            defaultChecked={true}
            style={{ borderBottom: "1px solid #f0f0f0" }}
          >
            CCAVENUE
          </Radio>
          <Radio value={"PHONEPE"} className="payment-radio">
            PHONEPE
          </Radio>
        
        </Radio.Group>
      
      </Modal>

      {ccavenueRequest && (
        <form id="nonseamless" method="post" name="redirect" action={ccavenueRequest.paymentLink} ref={CCAvenuePaymentForm}>
          <input type="hidden" id="encRequest" name="encRequest" value={ccavenueRequest.encryptedRequest} />
          <input type="hidden" name="access_code" id="access_code" value={ccavenueRequest.accessCode} />
        </form>
      )}

    </div>
  );
};

const mapDispatchtoProps = {
  fetchOrderResponse,
  fetchCartCount,
  fetchCartItems,
  fetchOrderFailure,
};
export default connect(null, mapDispatchtoProps)(Cartpayment);
