


import React, { useState, useEffect, useRef } from "react";

import { Button, Typography, Form, Input, message } from "antd";
import { connect } from "react-redux";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import "../Login/login.scss";
import {
  openLoginModal,
  openSignUpModal,
  closeAuthModal,
} from "../../../ReduxStore/action/authModalActions";
import { registerCustomer } from "../../../ApiAction/CustomerApiActions/customerApi";

import SignupSuccessModal from "../SIgnupSuccessModal";

import Loading from "../Loading";
import { fetchCompanyDetails } from "../../../ReduxStore/action";

const { Title } = Typography;
const SignupForm = (props) => {
  const { companyDetails } = props;
  const [visible, setvisible] = useState(false);
  const [userType, setuserType] = useState();
  const [successModal, setsuccessModal] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const passwordRef = useRef();

  const PasswordValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 8) {
      callback("Password should consists of minimun of 8 chartcters");
    } else {
      callback();
    }
  };

  const successModalClick = (value) => {
    setsuccessModal(value);
    HandleOpenLoginMOdal();
  };

  const compareToFirstPassword = (rule, value, callback) => {
    console.log("hello_validaton password", passwordRef.current.props.value);
    const password = passwordRef.current.props.value;

    if (value && value !== password) {
      callback("Passwords mismatch. Please re-enter");
    } else {
      callback();
    }
  };

  const PhoneValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 12) {
      callback("Enter valid phone number");
    } else {
      callback();
    }
  };

  const customerFormFields = [
    {
      name: "fullName",
      placeHolder: "Full Name*",
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z ]+$"),
          message: "Enter a valid User Name",
        },
        { required: true, message: "This field is required !" },
      ],
    },
    {
      name: "emailId",
      placeHolder: "Email ID*",
      ruleType: "email",
      message: "Enter a valid email Id",
    },
    {
      name: "phoneNumber",
      placeHolder: "Phone Number*",
      validator: PhoneValidator,
    },
    {
      name: "password",
      placeHolder: "Password*",
      validator: PasswordValidator,
      ref: passwordRef,
      type: "password",
    },
    {
      name: "confirmPassword",
      placeHolder: "Confirm password*",
      validator: compareToFirstPassword,
      type: "password",
    },
    { name: "affiliateId", placeHolder: "Affliate ID", referalId: true },
    {
      name: "pinCode",
      placeHolder: "PIN Code*",
      rules: [
        {
          pattern: new RegExp("^[1-9][0-9]{5}$"),
          message: "Enter a valid pincode",
        },
        { required: true, message: "This field is required !" },
      ],
    },
  ];

  const sellerFormFields = [
    {
      name: "fullName",
      placeHolder: "Display Name*",
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z ]+$"),
          message: "Enter a valid User Name",
        },
        { required: true, message: "This field is required !" },
      ],
    },
    {
      name: "emailId",
      placeHolder: "Email ID*",
      ruleType: "email",
      message: "Enter a valid email Id",
    },
    {
      name: "communicationEmailId",
      placeHolder: "Communication Email ID*",
      ruleType: "email",
      message: "Enter a valid email Id",
    },
    {
      name: "phoneNumber",
      placeHolder: "Phone Number*",
      validator: PhoneValidator,
    },
    {
      name: "password",
      placeHolder: "Password*",
      validator: PasswordValidator,
      ref: passwordRef,
      type: "password",
    },
    {
      name: "confirmPassword",
      placeHolder: "Confirm password*",
      validator: compareToFirstPassword,
      type: "password",
    },
    { name: "affiliateId", placeHolder: "Referal ID", referalId: true }, //sponsers referralId
    {
      name: "pinCode",
      placeHolder: "PIN Code*",
      rules: [
        {
          pattern: new RegExp("^[1-9][0-9]{5}$"),
          message: "Enter a valid pincode",
        },
        { required: true, message: "This field is required !" },
      ],
    },
  ];

  const formFields = props.isSeller ? sellerFormFields : customerFormFields;

  const HandleOpenLoginMOdal = () => {
    if (props.isSeller) {
      props.openLoginModal("seller");
    } else props.openLoginModal("customer");
  };

  //related to form starts here
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    const paylod = {
      name: values.fullName,
      email: values.emailId,
      mobile: values.phoneNumber,
      pinCode: values.pinCode,
      affiliateId: values.affiliateId,
      password: values.password,
      accountType: props.isAffiliate? "affiliate" :
        props.isSeller ? "seller" : "customer",
    };
    if (props.isSeller) {
      paylod["communicationEmail"] = values.communicationEmailId;
    }

    if (props.referralId) {
      console.log("referal id for the customer: ", props.referralId);
      paylod["referralId"] = props.referralId; //owners referralId
    }

    if (props.affliateId) {
      paylod["affiliateId"] = props.affliateId; //sponsers Id
    }
    console.log(paylod, ":payload for new signup");
    setLoading(true);
    registerCustomer(paylod)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setsuccessMessage(response.data.message);
            message.success(response.data.message);
            // setsuccessModal(true);
            HandleOpenLoginMOdal();
            props.setvisible(false);
          } else {
            setErrorMessage(response.data.message);
            console.log("signup response", response);
          }
        } else {
          setErrorMessage(
            "Unable to complete signup, please try again later. "
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  //related to form ends here

  useEffect(() => {
    console.log(
      props.authModalUserType,
      props.authModalType,
      props.referralId,
      "updated signup"
    );
    props.authModalType === "signup" ? setvisible(true) : setvisible(false);

    props.authModalUserType && setuserType(props.authModalUserType);
  }, [props]);

  return (
    <>
      <div className="login-modal-form form_data_group">
        <Title level={3}>Sign Up </Title>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          {formFields.map((data) => {
            return (
              <Form.Item
                hasFeedback
                name={data.name}
                rules={
                  data.rules
                    ? data.rules
                    : [
                        {
                          type: data.ruleType ? data.ruleType : null,
                          message: data.message ? data.message : null,
                        },
                        {
                          required: data.referalId ? false : true,
                          message: "This field is required !",
                        },
                        {
                          validator: data.validator ? data.validator : "",
                        },
                      ]
                }
              >
                {data.type && data.type === "password" ? (
                  <Input.Password
                    ref={data.ref ? data.ref : null}
                    placeholder={data.placeHolder}
                  />
                ) : data.name === "phoneNumber" ? (
                  <PhoneInput
                    country={"in"}
                    className="phone_number_inpt"
                    countryCodeEditable={false}
                    // value={this.state.phone}
                    onChange={(phone) => console.log(phone)}
                    enableAreaCodeStretch
                  />
                ) : (
                  <Input
                    ref={data.ref ? data.ref : null}
                    type={data.type ? data.type : null}
                    maxLength={data.name === "pinCode" ? 6 : null}
                    placeholder={data.placeHolder}
                  />
                )}
              </Form.Item>
            );
          })}

          <Form.Item className="button_submit">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ fontWeight: "600" }}
            >
              {loading ? <Loading style={{ color: "white" }} /> : "SIGN UP"}
            </Button>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            <br />
            <p className="go_to_register">
              {" "}
              Already in{" "}
              <span style={{ color: "#3e6df3", fontSize: "15px" }}>
                {" "}
                {companyDetails?.companyName}?
              </span>{" "}
              <a
                onClick={() => HandleOpenLoginMOdal()}
                style={{ fontWeight: "600" }}
              >
                LOGIN
              </a>
            </p>
          </Form.Item>
        </Form>

        <SignupSuccessModal
          visible={successModal}
          setvisible={successModalClick}
          message={successMessage}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = { openLoginModal, openSignUpModal, closeAuthModal, fetchCompanyDetails };

const mapStateToProps = (state) => {console.log("AUTH STATE", state)
  return {
    authModalUserType: state.authModalReducer.userType,
    authModalType: state.authModalReducer.modalType,
    referralId: state.authModalReducer.referralId,
    affliateId: state.authModalReducer.affliateId,
    companyDetails: state.homePageReducer.companyDetails
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
