


import { MANAGE_ORDER } from "../action/types";

export default function manageOrderReducer(state = 0, action) {
  switch (action.type) {
    case MANAGE_ORDER:
      return action.data;
     
    default:
      return state;
  }
}
