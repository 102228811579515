


import React, { useState, useEffect,useRef } from "react";
import { Modal, Button, Typography, Form, Input,message } from "antd";
import CompanyLogo from "../../Assets/Images/Logo/DL_Logo.png";
import OtpLogo from "../../Assets/Images/Icons/otpsuccess.png";
import { ReactComponent as Phone} from "../../Assets/Images/Icons/Seller/phone.svg"
import { ReactComponent as Mail} from "../../Assets/Images/Icons/Seller/mail.svg"
import Icon from '@ant-design/icons';
import { CloseCircleOutlined } from "@ant-design/icons";
import { resetPasswordApi } from "../../ApiAction/CustomerApiActions/customerApi";
const ForgotSuccessModal = (props) => {
    const [forgotMessage, setForgotMessage] = useState("");


    return (


        <Modal
          className="circle_drop"
            visible={props.visible}
            onOk={() =>  props.setVisible()}
        onCancel={() => {
            props.setVisible()
        }}
            width={600}
            footer={null}
          >
              <img
                style={{width:"150px"}}
                src={CompanyLogo}
                alt={"company logo"}
              />
             <div style={{ display: "flex", flexDirection:"column",alignItems:"center",padding:"15%" }}>
             <img
                style={{width:"150px"}}
                src={OtpLogo}
                alt={"company logo"}
              />
             <div style={{fontWeight:"600", fontSize:"20px",marginBottom:"20px",textAlign:"center",color:"#729106"}}>
                 Your password has been reset Successfully!
                 </div>
                 <div style={{fontWeight:"500"}}>
                     {/* Enter the email address associated with your account  */}
                 </div>
            <Form.Item className="button_submit">
              <Button
                type="primary"
                style={{height:"50px", fontWeight: "600"}}
                htmlType="submit"
                className="login-form-button"
                onClick={() =>  props.setVisible()}
              >
                CONTINUE TO LOGIN
              </Button>
            </Form.Item>



      </div>
          </Modal>

      );
}
export default ForgotSuccessModal;