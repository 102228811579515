


import React, { useState } from "react";

import { Card, Row, Col, Input, Popconfirm } from "antd";

import "./addBrand.css";

import DeleteIcon from "../../../../Assets/Images/Icons/delete-light.svg";
import AddIcon from "../../../../Assets/Images/Icons/add-color.svg";

const AddSpecifications = (props) => {
  const { specificationsDraftList, setspecificationsDraftList } = props;

  const [currentValue, setcurrentValue] = useState("");

  const handleChangeCurrentValue = (e) => {
    setcurrentValue(e.target.value);
  };

  const handleAddSpecification = async (e) => {
    // const temp = {
    //   feature: e?.target?.value ? e.target.value : currentValue,
    // };

    const temp = {
      key: e?.target?.value ? e.target.value : currentValue,
      value: "",
    };
    await setspecificationsDraftList([...specificationsDraftList, temp]);
    setcurrentValue("");
  };

  const handleRemoveFromDraft = (feature) => {
    setspecificationsDraftList(
      specificationsDraftList.filter((data) => {
        return data.feature !== feature;
      })
    );
  };

  return (
    <Card className="manage_category_link_brand">
      <Row gutter={[16, 24]}>
        {specificationsDraftList.length > 0 &&
          specificationsDraftList.map((data) => {
            return (
              <Col className="gutter-row" span={8}>
                <div className="linked_brand_each">
                  {" "}
                  <p className="linked_brand_name"> {data.key}</p>
                  <Popconfirm
                    title="Are you sure to delete this Brand?"
                    onConfirm={() => handleRemoveFromDraft(data.feature)}
                    okText="Remove"
                    cancelText="Never"
                  >
                  <div className="linked_brand_icon linked_brand_delete_icon">
                    <img
                      src={DeleteIcon}
                      className="delete_icon"
                      style={{ width: "15px" }}
                    />
                  </div>
                  </Popconfirm>
                </div>
              </Col>
            );
          })}
        <Input
          size="large"
          value={currentValue}
          placeholder="+ Add Specification"
          style={{ width: 290, marginTop: "10px", marginLeft: "10px" }}
          onPressEnter={handleAddSpecification}
          onChange={handleChangeCurrentValue}
        />
        <img
          src={AddIcon}
          alt="add-color-icon"
          className="add-spec-icon"
          style={{ marginLeft: "20px", alignSelf: "center", cursor: "pointer" }}
          onClick={handleAddSpecification}
        />
      </Row>
    </Card>
  );
};

export default AddSpecifications;
