


import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import ManageStoreTable from "../Admin/ManageStore/manageStoreTable";
import {
  getLinkedStores,
  getWarehouseLinkedStores,
} from "../../ApiAction/AdminApiAction/linkWarehouseAoi";

const { Title } = Typography;
const WarehouseLinkedStore = (props) => {
  const cookies = new Cookies();
  const [customerData, setCustmerData] = useState([]);
  const [isLoadingStore, setisLoadingStore] = useState(false);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchStores(1, props.searchVal);
  }, [props]);

  const fetchStores = (page, search = props.searchVal) => {
    setCurrentPage(page);
    // const payload = {
    //     linkedStores: cookies.get("linkedStores"),
    //   };
    const payload = {
      key: search,
      sortBy: props.sort ? props.sort.sortType : "date",
      sortType: props.sort ? props.sort.sortBy : "asc",

      offset: 20 * (page - 1),
      size: 20,
    };
    setisLoadingStore(true);
    getWarehouseLinkedStores(cookies.get("vendorId"), payload)
      .then((response) => {
        setCustmerData(response.data.linkedStores);
        setPage(Math.ceil(response.data.count / 10) * 10);
      })
      .catch(() => {})
      .finally(() => {
        setisLoadingStore(false);
      });
  };

  return (
    <div className="admin-manage-customer">
      <h1 className="content-name-header sub-title-tags" level={4}>
        {" "}
        {props.isWarehouse ? "Warehouse" : "Store"}
      </h1>

      <ManageStoreTable
        userData={customerData}
        total={page}
        currentPage={currentPage}
        warehouseLinkedStore
        onPageChange={fetchStores}
        isLoadingStore={isLoadingStore}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps)(WarehouseLinkedStore);
