


import React, { useState } from "react";
import { Card, Checkbox, Row, Col, Typography } from "antd";
import { connect } from "react-redux";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { addBrandFilter } from "../../../../../../ReduxStore/action/productActions";

import "./filters.scss";
const { Text } = Typography;
const CheckboxGroup = Checkbox.Group;
const BrandFilter = (props) => {
  const { width, filterBrands, setisFilterApplied } = props;
  const [hide, sethide] = useState(true);
  const [checkedBrandList, setcheckedBrandList] = useState([]);

  const brandList = ["Brand1", "Brand2", "Brand3", "Brand4", "Brand5"];

  const handleBrandFilterChange = (checkedList) => {
    console.log(checkedList, ":updated checklist");
    setcheckedBrandList(checkedList);
    setisFilterApplied(true);
    props.addBrandFilter(checkedList);
  };

  const handleClearBrandFilter = () => {
    setcheckedBrandList([]);
    props.addBrandFilter([]);
    setisFilterApplied(true);
  };

  const extra = () => {
    return (
      <div onClick={() => sethide(!hide)}>
        {hide ? <DownOutlined /> : <UpOutlined />}
      </div>
    );
  };

  const title = () => {
    return (
      <Text onClick={() => sethide(!hide)} style={{ cursor: "pointer" }}>
        Brand
      </Text>
    );
  };

  return (
    <Card
      title={title()}
      extra={extra()}
      style={{ width: width ? width : 310 }}
      className="filter-by-conatiner"
    >
      {!hide && (
        <div className="filter-by-options filter_menu_list">
          <CheckboxGroup
            value={checkedBrandList}
            onChange={handleBrandFilterChange}
            
          >
            <Row>
              {filterBrands.map((data) => {
                return (
                  <Col span={24} key={data.brandId}>
                    {" "}
                    <Checkbox value={data.brandId}>
                      <Text className="brand_title_filter">
                        {data.brandName}
                      </Text>
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </CheckboxGroup>
          <div className="filter-by-clear-all">
            <Text
              onClick={() => {
                handleClearBrandFilter();
                window.scrollTo(0, 0);
              }}
            >
              {" "}
              Clear All
            </Text>
          </div>
        </div>
      )}
    </Card>
  );
};

const mapDispatchToProps = {
  addBrandFilter,
};

export default connect(null, mapDispatchToProps)(BrandFilter);
