


import React, { useState, useEffect } from "react";
import { Modal, Button, Typography, Form, Input, message } from "antd";
import CompanyLogo from "../../Assets/Images/Logo/DL_Logo.png";
import { ReactComponent as Phone } from "../../Assets/Images/Icons/Seller/phone.svg";
import { ReactComponent as Mail } from "../../Assets/Images/Icons/Seller/mail.svg";
import Icon from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import { sendEnquiry } from "../../ApiAction/SellerApiActions/sellerApi";

import "./contactUs.scss";
import Loading from "./Loading";
const ContactUsForStore = (props) => {
  const [isLoading, setisLoading] = useState(false);
  const PhoneValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 12) {
      callback("Enter valid phone number");
    } else {
      callback();
    }
  };
  const formFields = [
    { name: "firstName", placeHolder: "First Name*" },
    { name: "lastName", placeHolder: "Last Name", notRequired: true },
    {
      name: "emailId",
      placeHolder: "Email ID*",
      ruleType: "email",
      message: "Enter a vallid email Id",
    },
    {
      name: "phoneNumber",
      placeHolder: "Phone Number",
      validator: PhoneValidator,
    },

    {
      name: "pinCode",
      placeHolder: "PIN Code*",
      maxLength: 6,
      rules: [
        {
          pattern: new RegExp("^[1-9][0-9]{5}$"),
          message: "Enter a valid pincode",
        },
        { required: true, message: "This field is required !" },
      ],
    },
  ];

  const handleSendEnquiry = (values) => {
    console.log("Form Values", values);
    const payload = {
      name: `${values.firstName} ${values?.lastName ? values.lastName : ""}`,
      email: values.emailId,
      phoneNumber: values.phoneNumber,
      pincode: values.pinCode,
    };
    setisLoading(true);
    sendEnquiry(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(response.data.message);
            props.setSuccess();
          } else {
            message.warning(response.data.message);
          }
        } else message.warning("sorry something went wrong please try again");
      })
      .catch(() => {})
      .finally(() => {
        setisLoading(false);
      });
  };
  const onFinish = (values) => {
    handleSendEnquiry(values);
  };
  return (
    <Modal
      visible={props.visible}
      onOk={() => props.setSuccess()}
      onCancel={() => {
        props.setSuccess();
      }}
      width={500}
      footer={null}
      className={"contact-us-form"}
    >
      <img
        className={"company_logo"}
        src={CompanyLogo}
        alt={"company logo"}
      />
      <div className="contact-modal-form">
        <Form
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          {formFields.map((data, index) => {
            return (
              <Form.Item
                key={index}
                hasFeedback
                name={data.name}
                rules={
                  data.rules
                    ? data.rules
                    : [
                        {
                          type: data.ruleType ? data.ruleType : null,
                          message: data.message ? data.message : null,
                        },
                        {
                          required:
                            data.referalId || data.notRequired ? false : true,
                          message: "This field is required !",
                        },
                        {
                          validator: data.validator ? data.validator : "",
                        },
                      ]
                }
              >
                {data.name === "phoneNumber" ? (
                  <PhoneInput
                    country={"in"}
                    // style={{ border: "1px solid #d9d9d9", width: "100%" }}
                    className="phone_number_inpt"
                    countryCodeEditable={false}
                    // value={this.state.phone}
                    onChange={(phone) => console.log(phone)}
                  />
                ) : (
                  <Input
                    ref={data.ref ? data.ref : null}
                    type={data.type ? data.type : null}
                    maxLength={data.maxLength ? data.maxLength : null}
                    placeholder={data.placeHolder}
                  />
                )}
              </Form.Item>
            );
          })}

          <Form.Item>
            <Button
              disabled={isLoading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {isLoading ? <Loading style={{ color: "white" }} /> : " SUBMIT"}
            </Button>
            <br />
          </Form.Item>
        </Form>
        <span style={{ color: "#000", marginTop: "15px" }}>
          {" "}
          Want to know more about setting up a{" "}
          <span>{props.isStore ? " store" : " warehouse"}</span> in
          <span style={{ color: "#000", fontWeight: "500" }}>Dream Life?</span>
        </span>{" "}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            color: "#0074be",
            fontWeight: "500",
            marginTop: "10px",
          }}
        >
          <div>
            <Icon component={Mail} style={{ fontSize: "20px" }} />{" "}
            info@dlmbiz.com.in
          </div>
          <div>
            <Icon component={Phone} style={{ fontSize: "20px" }} />{" "}
            +919944338134
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ContactUsForStore;
