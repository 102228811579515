


import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Checkbox, Button, Select, Modal, Form } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { stateSuggestions } from "../../../../Utils/constData";
import {
  fetchDistrictList,
  fetchPincodeList,
} from "../../../../ApiAction/GeneralApiActions/pinCodeAPI";
import {
  districtDataFormater,
  pinCodeDataFormater,
} from "../../../../Helpers/dataFormatter";
import { addServicableLocations } from "../../../../ApiAction/SellerApiActions/serviceLocationsAPI";

const { Option } = Select;
const ServiceLocationModal = (props) => {
  const {
    serviceLocations,
    serviceLocationsObject,
    isAdmin,
    userDetails,
    isForVendortype,
    reload,
    submit,
    setisEdit,
  } = props;

  const [form] = Form.useForm();
  const [nationList, setNationList] = useState([]);
  const [stateList, setStateList] = useState(stateSuggestions);
  const [districtList, setDistrictList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);

  const [tagValues, setTagValues] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [selectedPincode, setSelectedPincode] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedNation, setSelectedNation] = useState("India");
  const [serviceObject, setServiceObject] = useState([]);
  const [allIndia, setAllIndia] = useState(false);

  const parseIntToStringList = (list) => {
    let result = [];
    list.map((data) => {
      result.push(data.toString());
    });
    return result;
  };

  const handleAddServicableLocations = () => {
    const obj = {
      country: selectedNation,
      state: selectedState,
      district: selectedDistrict,
      pincode: allIndia ? ['All India'] : parseIntToStringList(selectedPincode),
    };
    props.setServiceLocations(allIndia ? ['All India'] : parseIntToStringList(selectedPincode));
    props.setServiceLocationsObject(obj);

    if (isAdmin) handleAddServiceLocationsByAdmin(obj);
    else {
      if (submit) submit(allIndia ? ['All India'] : parseIntToStringList(selectedPincode), obj);
      handleCloseModal();
    }
  };

  //triggered only if admin tries to add service locations for store / warehouse
  const handleAddServiceLocationsByAdmin = (obj) => {
    const payload = {
      userId: userDetails.id,
      accountType: isForVendortype,
      serviceablePinCodes: parseIntToStringList(selectedPincode),
      serviceablePinCodesRequest: obj,
    };
    addServicableLocations(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        reload(1);
        props.setVisible(false);
        form.resetFields();
      });
  };

  const handdleFetchDistrictsByStates = () => {
    fetchDistrictList(selectedState).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setDistrictList(districtDataFormater(response.data.documents));
        }
      }
    });
  };

  const handleFetchPincodesByDistricts = () => {
    if(!allIndia) {
      fetchPincodeList(selectedDistrict).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setPincodeList(pinCodeDataFormater(response?.data?.pincode ? response?.data?.pincode : []));
          }
        }
      });
    }
  };

  useEffect(() => {
    const tagsData = ["India"];
    setNationList(tagsData);
    if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
      setCollapsed(true);
    } else setCollapsed(false);
  }, []);

  //for fetching districtList as the user selects states
  useEffect(() => {

    if (selectedState) {
      handdleFetchDistrictsByStates();
    }
    //  else setDistrictList([]);
  }, [selectedState]);

  //for fetching pincode list as user selects district
  useEffect(() => {
    if (selectedDistrict) {
      handleFetchPincodesByDistricts();
    }
    // else setPincodeList([]);
  }, [selectedDistrict]);

  //for updating Selected District w.r.t selected state
  useEffect(() => {
    const newValidDistricts = selectedDistrict?.filter((district) =>
      districtList.includes(district)
    );

    setSelectedDistrict(newValidDistricts);
    form.setFieldsValue({ district: newValidDistricts });
  }, [districtList]);

  //for updating Selected Pincodes w.r.t selected District
  useEffect(() => {
    const newValidPincodes = selectedPincode.filter((pincode) =>
      pincodeList.includes(pincode)
    );

    setSelectedPincode(newValidPincodes);
    form.setFieldsValue({ pincode: newValidPincodes });
  }, [pincodeList]);

  //for initialising fields in case of edit
  useEffect(() => {
    if (serviceLocationsObject) {
      form.setFieldsValue({
        nation: serviceLocationsObject?.country,
        state: serviceLocationsObject?.state,
        district: serviceLocationsObject?.district,
        pincode: serviceLocations ? serviceLocations : [],
      });
      setSelectedNation(serviceLocationsObject.country);
      setStateList(stateSuggestions);
      setSelectedState(serviceLocationsObject.state);
      setSelectedDistrict(serviceLocationsObject.district);
      setSelectedPincode(serviceLocations);
    }
  }, [serviceLocations, serviceLocationsObject]);

  const handleCloseModal = () => {
    // setSelectedNation();
    // setStateList([]);
    // setSelectedState([]);
    // setSelectedDistrict([]);
    // setSelectedPincode([]);
    if (serviceLocationsObject) {
      form.setFieldsValue({
        nation: serviceLocationsObject?.country,
        state: serviceLocationsObject?.state,
        district: serviceLocationsObject?.district,
        pincode: serviceLocations ? serviceLocations : [],
      });
      setSelectedNation(serviceLocationsObject.country);
      setStateList(stateSuggestions);
      setSelectedState(serviceLocationsObject.state);
      setSelectedDistrict(serviceLocationsObject.district);
      setSelectedPincode(serviceLocations);
    }
    props.setVisible(false);
    // form.resetFields();
    setisEdit && setisEdit(false);
  };

  const handleSelectAllDistricts = (value) => {
    console.log(selectedState.length, ' - ', stateList.length)
    if (value === "Select All") {
        setSelectedDistrict(districtList);
        form.setFieldsValue({ district: districtList });
        if(selectedState.length === stateList.length) {
          setAllIndia(true)
        }
    }else if (value === "Uncheck All") {
      setSelectedDistrict([]);
      form.setFieldsValue({ district: [] });
      setAllIndia(false)
    }
  };

  const handleSelectAllPincodes = (value) => {
    if (value === "Select All") {
      if (selectedPincode.length === pincodeList.length) {
        setSelectedPincode([]);
        form.setFieldsValue({ pincode: [] });
      } else {
        setSelectedPincode(pincodeList);
        form.setFieldsValue({ pincode: pincodeList });
      }
    }
  };

  const handleSelectAllStates = (value) => {
    if (value === "Select All") {
        setSelectedState(stateList);
        form.setFieldsValue({ state: stateList });
        setAllIndia(true)
    }else if(value === 'Uncheck All') {
      setSelectedState([]);
      form.setFieldsValue({ state: [] });
      setAllIndia(false)
    }
  };

  const handleSelectState = (value) => {
    setAllIndia(false)
    if (value.length > 0 && !value.includes("Select All")) {
      setSelectedState(value);
    } else {
      setSelectedState([]);
      setSelectedDistrict([]);
      setSelectedPincode([]);
      setDistrictList([]);
      setPincodeList([]);
      form.setFieldsValue({ district: [] });
      form.setFieldsValue({ pincode: [] });
    }
  };

  const handleSelectDistrict = (value) => {
    if (value.length > 0 && !value.includes("Select All")) {
      setSelectedDistrict(value);
      setAllIndia(false)
    } else {
        setSelectedDistrict([]);
        setSelectedPincode([]);
        setPincodeList([]);
        form.setFieldsValue({ pincode: [] });
    }
  };

  const handleSelectPincode = (value) => {
    if (!value.includes("Select All")) {
      setSelectedPincode(value);
    }
  };

  return (
    <div className="merchant-request-modal">
      <Modal
        className="service-modal"
        title={null}
        footer={null}
        closeIcon={<CloseCircleOutlined />}
        visible={props.visible}
        centered={true}
        width={"60vw"}
        onOk={() => {
          handleCloseModal();
        }}
        onCancel={() => {
          handleCloseModal();
        }}
      >
        <Form
          form={form}
          name="service_locations"
          initialValues={{
            nation: serviceLocationsObject?.country,
            state: serviceLocationsObject?.state,
            district: serviceLocationsObject?.district,
            pincode: serviceLocations,
          }}
          onFinish={handleAddServicableLocations}
        >
          <div className="add-product-tile">
            Delivery Locations
            <div className="add-product-tile">
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="add-image-col"
                >
                  <Form.Item name="nation">
                    <Select
                      onSelect={(value, option) => {
                        setStateList(stateSuggestions);
                        setSelectedNation(option.value);
                      }}
                      defaultValue={"India"}
                      style={{ width: "100%" }}
                    >
                      {nationList &&
                        nationList.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>

          <div className="add-product-tile">
            Include or exclude locations
            <div
              style={{
                background: "#ffffff",
                padding: "15px 0px 15px 15px",
                height: "380px",
                overflowY: "scroll",
              }}
            >
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  className="add-image-col"
                >
                  <div className="add-product-tile">
                    State
                    <Form.Item
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "This field is required !",
                        },
                      ]}
                    >
                      <Select
                        onSelect={(value) => handleSelectAllStates(value)}
                        style={{ width: "100%" }}
                        // open={!collapsed ? true: undefined}
                        mode="multiple"
                        listHeight={280}
                        onChange={(value) => handleSelectState(value)}
                        className="state-select"
                        dropdownClassName="state-dropdown"
                        getPopupContainer={(trigger) => trigger.parentElement}
                      >
                        {stateList.length > 0 && (
                          <Fragment>
                            {stateList.length === selectedState?.length ? (
                              <Option value="Uncheck All">
                                <Checkbox
                                  className="state-select-check"
                                  checked={
                                    selectedState?.length === stateList?.length
                                      ? true
                                      : false
                                  }
                                  style={{ marginRight: "10px" }}
                                />{" "}
                                Uncheck All
                              </Option>
                            ) : (
                              <Option value = "Select All">
                              <Checkbox
                                className = "state-select-check"
                                checked = {
                                  selectedState?.length === stateList?.length
                            ? true
                            : false
                                }
                            style={{ marginRight: "10px" }}
                              />{" "}
                            Select All
                          </Option>
                            )}

                          </Fragment>
                        )}

                        {stateList &&
                          stateList.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                <div
                                  style={{
                                    color: selectedState?.includes(item)
                                      ? "green"
                                      : "red",
                                    fontWeight: "300",
                                  }}
                                >
                                  <Checkbox
                                    className="state-select-check"
                                    checked={
                                      selectedState?.includes(item)
                                        ? true
                                        : false
                                    }
                                    style={{ marginRight: "10px" }}
                                  />
                                  {item}
                                </div>
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  className="add-image-col"
                >
                  <div className="add-product-tile">
                    District
                    <Form.Item
                      name="district"
                      rules={[
                        {
                          required: true,
                          message: "This field is required !",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: "100%" }}
                        // open={!collapsed ? true: undefined}
                        mode="multiple"
                        listHeight={280}
                        onChange={(value) => handleSelectDistrict(value)}
                        className="state-select"
                        dropdownClassName="state-dropdown"
                        getPopupContainer={(trigger) => trigger.parentElement}
                        onSelect={(value) => handleSelectAllDistricts(value)}
                      >
                        {districtList?.length > 0 && (
                          <Fragment>
                            {selectedDistrict?.length === districtList?.length ? (
                              <Option value="Uncheck All">
                                <Checkbox
                                  className="state-select-check"
                                  checked={
                                    selectedDistrict?.length ===
                                      districtList?.length
                                      ? true
                                      : false
                                  }
                                  style={{ marginRight: "10px" }}
                                />{" "}
                                Uncheck All
                              </Option>
                            ) : (
                              <Option value = "Select All">
                                <Checkbox
                                  className = "state-select-check"
                                  checked = {
                                    selectedDistrict?.length ===
                                districtList?.length
                                ? true
                                : false
                                  }
                                style={{ marginRight: "10px" }}
                                />{" "}
                                Select All
                          </Option>
                            )}

                        </Fragment>
                        )}

                        {districtList &&
                          districtList.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                <div
                                  style={{
                                    color: selectedDistrict?.includes(item)
                                      ? "green"
                                      : "red",
                                    fontWeight: "300",
                                  }}
                                >
                                  <Checkbox
                                    className="state-select-check"
                                    checked={
                                      selectedDistrict?.includes(item)
                                        ? true
                                        : false
                                    }
                                    style={{ marginRight: "10px" }}
                                  />
                                  {item}
                                </div>
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={8}
                  lg={8}
                  xl={8}
                  className="add-image-col"
                >
                  <div className="add-product-tile">
                    Pincode
                    <Form.Item
                      name="pincode"
                      rules={[
                        {
                          required: allIndia ? false: true,
                          message: "This field is required !",
                        },
                      ]}
                    >
                      <Select
                        onSelect={(value) => handleSelectAllPincodes(value)}
                        style={{ width: "100%" }}
                        // open={!collapsed ? true: undefined}
                        mode="multiple"
                        onChange={(value) => handleSelectPincode(value)}
                        listHeight={280}
                        getPopupContainer={(trigger) => trigger.parentElement}
                        dropdownClassName="state-dropdown"
                        className="state-select"
                        disabled={allIndia}
                      >
                        {pincodeList.length > 0 && (
                          <Option value="Select All">
                            <Checkbox
                              className="state-select-check"
                              checked={
                                selectedPincode.length === pincodeList.length
                                  ? true
                                  : false
                              }
                              style={{ marginRight: "10px" }}
                            />{" "}
                            Select All
                          </Option>
                        )}

                        {pincodeList &&
                          pincodeList.map((item, index) => {
                            return (
                              <Option key={index} value={item}>
                                <div
                                  style={{
                                    color: selectedPincode?.includes(item)
                                      ? "green"
                                      : "red",
                                    fontWeight: "300",
                                  }}
                                >
                                  <Checkbox
                                    className="state-select-check"
                                    checked={
                                      selectedPincode?.includes(item)
                                        ? true
                                        : false
                                    }
                                    style={{ marginRight: "10px" }}
                                  />
                                  {item}
                                </div>
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="seller-action-button">
            <Button
              style={{ width: "200px" }}
              onClick={() => {
                handleCloseModal();
              }}
              className="cancel_btn"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              style={{ width: "200px" }}
              // onClick={() => {
              //   handleAddServicableLocations();
              // }}
              className="button-seller-request-approve"
            >
              Save Changes
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ServiceLocationModal;
