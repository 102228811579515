


import React from "react";
import "./orderList.scss";

import OrderGroup from "./orderGroup";

const OrderList = (props) => {
  const { orderList, loading } = props;
  const loadingList = Array.from(Array(5).keys());

  return (
    <div className="customer_order_list_conatiner">
      {loading
        ? loadingList.map((order) => {
            return <OrderGroup key={order} loading={loading} />;
          })
        : orderList.map((order) => {
            return (
              <OrderGroup
                key={order.id}
                order={order.source}
                loading={loading}
              />
            );
          })}
    </div>
  );
};

export default OrderList;
