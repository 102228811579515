


import { FETCH_COMPANY_DETAILS, FETCH_HOMEPAGE_DATA } from "../action/types";

export default function homePageReducer(state = { homePageData: [] }, action) {
  switch (action.type) {
    case FETCH_HOMEPAGE_DATA:
      state = { ...state, homePageData: action.data };
      console.log("state for homepagereducer:", state);
      break;

    case FETCH_COMPANY_DETAILS:
      state = { ...state, companyDetails: action.data };
      console.log("state for homepagereducer:", state);
      break;

    default:
    // console.log("invalid choice");
  }

  return state;
}
