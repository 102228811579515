


import Axios from "../axios";

export const Authenticate = () => {
  const data = {
    email: "it@loremIpsum.in",
    userName: "05AAACH6188F1ZM",
    password: "abc123@@",
    gstin: "05AAACH6188F1ZM",
    ipAddress: "43.229.89.145",
    clientSecret: "7482d13c-88e1-4547-a947-269a28474f56",
    clientId: "d869410f-3d5b-407a-892a-4c82e663c361",
  };
  return Axios({
    url: `https://api.mastergst.com/ewaybillapi/v1.03/authenticate?email=${data.email}&username=${data.userName}&password=${data.password}`,
    headers: {
      ip_address: data.ipAddress,
      client_id: data.clientId,
      client_secret: data.clientSecret,
      gstin: data.gstin,
    },
  });
};
