


import React, { useState, useEffect } from "react";

import { Button, Modal, Typography } from "antd";
import { connect } from "react-redux";

import "./login.scss";
import {
  openLoginModal,
  openSignUpModal,
  closeAuthModal,
  openContactModal,
} from "../../../ReduxStore/action/authModalActions";
import LoginForm from "./loginForm";
import { withRouter } from "react-router-dom";
import CustomerLoginVector from "../../../Assets/Images/Vectors/DL_Customer.png";
import CompanyLogo from "../../../Assets/Images/Logo/DL_Logo.png";
import SellerLoginVector from "../../../Assets/Images/Vectors/DL_Seller-20.svg";
import StoreLoginVector from "../../../Assets/Images/Vectors/store-logo.svg";
import ProfileIcon from "../../../Assets/Images/Icons/HR_profile.svg";
import ContactUsForStore from "../contactUsForStore";
import ForgotPassword from "../forgotPassword";
import ResetFromOtp from "../resetFromOtp";
import ForgotSuccessModal from "../forgotSuccesModal";
import UpdateEmailModal from "../updateEmailModal";
import AddStoreModal from "../../Admin/ManageStore/addStoreModal";
import SignupSuccessModal from "../SIgnupSuccessModal";
import { getSignUpPaymentAmount } from "../../../ApiAction/AdminApiAction/adminApi";

const { Title } = Typography;
const Login = (props) => {
  const [visible, setvisible] = useState(false);
  const [signUpVisible, setSignUpVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);
  const [forgotVisible, setForgotVisible] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [otpSuccessVisible, setOtpSuccessVisible] = useState(false);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [signUpResponse, setSignUpResponse] = useState();
  const [signUpAmount, setSignUpAmount] = useState();
  const [updateEmailModalVisible, setupdateEmailModalVisible] = useState(false);
  const [initialUserData, setinitialUserData] = useState();

  const [userType, setuserType] = useState();
  const [vectorImage, setvectorImage] = useState();

  const showModal = () => {
    props.openLoginModal("customer");
  };

  const handleOk = () => {
    props.isCustomer && setvisible(false);
  };

  const setContactSuccess = () => {
    setContactVisible(false);
    setvisible(true);
    props.openLoginModal("seller");
  };

  const setOnSignUpSuccess = (data) => {
    setSignUpResponse(data);
    setSignUpVisible(false);
    setSignUpSuccess(true);
    props.openLoginModal("seller");
  };

  const setOtpSuccess = () => {
    setOtpVisible(false);
    setOtpSuccessVisible(true);
  };

  const setOtpCancel = () => {
    setOtpVisible(false);
    setvisible(true);
    props.openLoginModal("seller");
  };

  const setOtpSuccessCancel = () => {
    setOtpSuccessVisible(false);
    setvisible(true);
    props.openLoginModal("seller");
  };

  const setForgotSuccess = () => {
    setForgotVisible(false);
    setOtpVisible(true);
  };

  const setForgotCancel = () => {
    setForgotVisible(false);
    setvisible(true);
    props.openLoginModal("seller");
  };

  const handleCancel = () => {
    if (props.isCustomer) {
      setvisible(false);
      props.closeAuthModal();
      console.log("closing login modal");
    }
  };

  const setContact = () => {
    setContactVisible(true);
    setvisible(false);
  };

  const setRegisterStore = () => {
    setSignUpVisible(true);
    setvisible(false);
  };

  const setContactForModal = (bool) => {
    setSignUpVisible(bool);
    setvisible(!bool);
  };

  const setForgot = () => {
    setForgotVisible(true);

    setvisible(false);
  };

  const handleOpenUpdateEmailModal = (data) => {
    setinitialUserData(data);

    setupdateEmailModalVisible(true);
    props.closeAuthModal();
  };

  useEffect(() => {
    if (props.isAdmin) {
      setvisible(true);
      props.openLoginModal("admin");
    }
    if (props.isSeller) {
      props.openLoginModal("seller");
    }
    if (props.isStore || props.isWarehouse) {
      props.openLoginModal("seller");
      getSignUpPaymentAmount().then(response => {
        if(response.status == 200) {
          if(response.data.status == "success") {
            setSignUpAmount(response.data.signupPaymentAmount);
          }
        }
      })
    }
    if(props.isWorkAdmin) {
      setvisible(true);
      props.openLoginModal("workAdmin");
    }
    if(props.isAffiliate) {
      setvisible(true);
      props.openLoginModal("affiliate");
    }
  }, []);

  const getLoginBtton = () => {
    if (window.innerWidth <= 760) {
      return (
        <img
          className="header-logo profile-icon"
          onClick={() => showModal()}
          src={ProfileIcon}
          alt="profile-icon"
        />
      );
    } else
      return (
        <Button
          className="login-button"
          type="primary"
          onClick={() => showModal()}
        >
          Login
        </Button>
      );
  };

  const getWebViewVector = () => {
    if (window.innerWidth <= 760) {
      return;
    } else
      return (
        <div className="login-modal-media">
          <Title level={3}>
            <span>Welcome to </span>
            <span>
              {" "}
              <img
                className="login-company-logo"
                src={CompanyLogo}
                alt={"company logo"}
              />
            </span>
          </Title>{" "}
          <img
            className="login-company-customer-vector"
            src={vectorImage}
            alt={"company logo"}
          />
        </div>
      );
  };

  useEffect(() => {
    console.log(props.authModalUserType, props.authModalType, "updated login");

    props.authModalType === "login" ? setvisible(true) : setvisible(false);

    props.authModalUserType && setuserType(props.authModalUserType);

    // props.authModalUserType && props.authModalUserType === "customer"
    //   ? setvectorImage(CutomerLoginVector)
    //   : setvectorImage(SellerLoginVector);
    props.isStore || props.isWarehouse
      ? setvectorImage(StoreLoginVector)
      : props.isSeller
      ? setvectorImage(SellerLoginVector)
      : setvectorImage(CustomerLoginVector);
  }, [props]);

  // useEffect(() => {
  //   if(!signUpSuccess)
  //     setvisible(true)
  // }, [signUpSuccess]);

  return (
    <div>
      {/* {!props.isStore && !props.isAdmin && !props.isSeller && !props.isWarehouse
        && getLoginBtton()} */}
      <SignupSuccessModal
          visible={signUpSuccess}
          setvisible={setSignUpSuccess}
          message={"Successfully Registered"}
          signUpResponse={signUpResponse}
        />
      <Modal
        className="circle_drop for_login_state"
        closable={
          props.isWarehouse || props.isStore || props.isAdmin || props.isSeller || props.isWorkAdmin
            ? false
            : true
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <div className="login-modal-container">
          {getWebViewVector()}
          <LoginForm
            isAdmin={props.isAdmin}
            isStore={props.isStore}
            isWorkAdmin={props.isWorkAdmin}
            isAffiliate={props.isAffiliate}
            setVisible={handleCancel}
            isSeller={props.isSeller}
            isCustomer={props.isCustomer}
            isWarehouse={props.isWarehouse}
            setContact={setContact}
            setRegisterStore={setRegisterStore}
            setForgot={setForgot}
            handleOpenUpdateEmailModal={handleOpenUpdateEmailModal}
          />
        </div>
      </Modal>
      {/* <ContactUsForStore
        visible={signUpVisible}
        isStore={props.isStore}
        setSuccess={setSignUpSuccess}
      /> */}
      <AddStoreModal
        visible={signUpVisible}
        isWarehouse={props.isWarehouse}
        isEdit={false}
        setVisible={setContactForModal}
        setSuccess={setOnSignUpSuccess}
        signUpAmount={signUpAmount}
      />
      <ContactUsForStore
        visible={contactVisible}
        isStore={props.isStore}
        setSuccess={setContactSuccess}
      />
      <ForgotPassword
        visible={forgotVisible}
        setForgotVisible={setForgotVisible}
        isAdmin={props.isAdmin}
        isStore={props.isStore}
        setVisible={setForgotCancel}
        isSeller={props.isSeller}
        isCustomer={props.isCustomer}
        isWarehouse={props.isWarehouse}
        setSuccess={setForgotSuccess}
      />

      <UpdateEmailModal
        visible={updateEmailModalVisible}
        setForgotVisible={setupdateEmailModalVisible}
        setVisible={setupdateEmailModalVisible}
        setSuccess={setForgotSuccess}
        initialUserData={initialUserData}
      />
      <ResetFromOtp
        visible={otpVisible}
        isAdmin={props.isAdmin}
        isStore={props.isStore}
        setVisible={setOtpCancel}
        isSeller={props.isSeller}
        isCustomer={props.isCustomer}
        isWarehouse={props.isWarehouse}
        setSuccess={setOtpSuccess}
      />

      <ForgotSuccessModal
        visible={otpSuccessVisible}
        isAdmin={props.isAdmin}
        isStore={props.isStore}
        setVisible={setOtpSuccessCancel}
        isSeller={props.isSeller}
        isCustomer={props.isCustomer}
        isWarehouse={props.isWarehouse}
      />
    </div>
  );
};
const mapDispatchToProps = {
  openLoginModal,
  openSignUpModal,
  closeAuthModal,
  openContactModal,
};

const mapStateToProps = (state) => {
  return {
    authModalUserType: state.authModalReducer.userType,
    authModalType: state.authModalReducer.modalType,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
