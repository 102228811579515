


import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { message, Pagination, Modal } from "antd";
import ReportsTable from "../ReportsTable";
import { dailyWorkReportColumns, formatDailyWorkReport, workReportColumns } from "../Utils/reportHelper";
import { fetchSort } from "../../../../ReduxStore/action";
import { exportWorkReport } from "../../../../ApiAction/AdminApiAction/reportsApi";
import { formatWorkReport } from "../../Reports/Utils/reportHelper";
import { log_get_all } from "../../../../ApiAction/AdminApiAction/logApi";

const WorkLogReport = (props) => {

  const cookies = new Cookies();
  const warning = Modal.warning;
  const { reportSummary, isStore, dataEntryUsers, isDailyReport } = props;
  const [dataSource, setdataSource] = useState([]);
  const [isLoadingWorkReport, setIsLoadingWorkReport] = useState(false);
  const [totalSize, settotalSize] = useState();
  const [columnData, setcolumnData] = useState();
  const [pageNo, setpageNo] = useState(1);
  const [ action, setActions ] = useState('');
  const [ screen, setScreen ] = useState('');
  const [ status , setStatus ] = useState('');
  const [ startDate, setStartDate ] = useState('');
  const [ endDate, setEndDate ] = useState('');
  const [ searchKey, setSearchKey ] = useState('');
  const [ offSet, setOffSet] = useState(0);
  const [ size ] = useState(20);
  const [defaultUserId, setDefaultUserId] = useState('');

  const handleShowWarning = (msg) => {
    warning({
      title: msg,
      className: "create-confirm-modal",
    });
  };

  //handling date range part starts here

  const handleValidateDateRange = (startDate, endDate) => {
    console.log("startDate: ", startDate, "endDate: ", endDate);

    var date1 = new Date(startDate);
    var date2 = new Date(endDate);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 186) {
      handleShowWarning(
        `Please choose a date range of lesser than or equal to ${
          isStore ? "Six" : "One"
        } month.`
      );
      return false;
    } else {
      return true;
    }
    console.log(Difference_In_Days, ":difference in days");
  };

  const handleExportWorkReport = async () => {
    const payload = {
      searchKey : searchKey,
      userId: props.location.userId || defaultUserId,
      statusKey : status,
      startTime : startDate,
      endTime : endDate,
      sortBy: 'timestamp',
      sortType: 'desc'
    };

     if(props.screenFilter) {
      payload["screenName"] = props.screenFilter;
    }

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endTime"] = props.dateRange.endtime;
    }

    if (props?.dateRange) {
      if (
        handleValidateDateRange(
          props.dateRange.startTime,
          props.dateRange.endtime
        )
      ) {
        setIsLoadingWorkReport(true);
        exportWorkReport(payload)
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.status === "failed") {
                handleShowWarning(
                  "Couldn't download work report. Please try again later"
                );
              } else {
                console.log(response, "response for voucher code excel sheet");
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `work_report.xlsx`);
                document.body.appendChild(link);
                link.click();
              }
            } else {
              handleShowWarning(
                "Couldn't download work report. Please try again later"
              );
            }
          })
          .catch((error) => {
            message.error("Could not export file please try again");
          })
          .finally(() => {
            setIsLoadingWorkReport(false);
          });
        return true;
      } else {
        return true;
      }
    } else {
      handleShowWarning(
        `Please Choose a date range maximum of ${
          isStore ? "Six months" : "One month"
        }  to download work report.`
      );
      return true;
    }
  };

  const handleOnPageChange = (page, pageSize) => {
    console.log(page, pageSize);
    setpageNo(page);
    setOffSet(page * pageSize);
    window.scrollTo(0, 0);
  };

  const handleFetchWorkReport = (pageNo) => {
    
    const payload = {
      searchKey : searchKey,
      userId: props.location.userId || dataEntryUsers?.[0]?.id,
      screenName : screen,
      actionName : action,
      statusKey : status,
      startTime : startDate,
      endTime : endDate,
      sortBy: 'timestamp',
      sortType: 'desc',
      size: size,
      offset: offSet
    };

    if(props.screenFilter) {
      payload["screenName"] = props.screenFilter;
    }

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endTime"] = props.dateRange.endtime;
    }
    setcolumnData(workReportColumns);
    if(!dataEntryUsers.length) {
      return
    }
    log_get_all(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log("reponse for work report: ", response.data.documents);
            settotalSize(response.data.count);
            setdataSource(
              formatWorkReport(
                response.data.documents,
                response?.data?.count ? (pageNo - 1) * 10 : 0
              )
            );
          } else {
            setdataSource([])
          }
        }
      })
      .catch((error) => {
        console.log("Error in fetch log :: ", error.message)})
      .finally(() => {
        setIsLoadingWorkReport(false);
      });
  };

  const dailyWorkReport = () => {
    settotalSize(dataEntryUsers.length);
    setcolumnData(dailyWorkReportColumns);
    setdataSource(
      formatDailyWorkReport(
        dataEntryUsers.slice((pageNo - 1) * 15 , (pageNo - 1) * 15 + 15),
        dataEntryUsers.length? (pageNo - 1) * 15 : 0
      )
    )
    if(dataEntryUsers.length) {
      setIsLoadingWorkReport(false);
    }
  };

  useEffect(() => {
    setIsLoadingWorkReport(true);
    if(isDailyReport) {
      dailyWorkReport();
    } else {
      handleFetchWorkReport(pageNo);
    }
  }, [
    props.location.userId,
    props.dateRange,
    props.screenFilter,
    isDailyReport,
    dataEntryUsers,
    pageNo,
  ]);

  return (
    <Fragment>
      <ReportsTable
        download={handleExportWorkReport}
        columns={columnData}
        dataSource={dataSource}
        type={`${isDailyReport? "Daily" : (props.location?.name || dataEntryUsers?.[0]?.name) ? 
          ((props.location?.name || dataEntryUsers?.[0]?.name) + "'s Work"): "Loading"}`
        }
        reportSummary={reportSummary}
        isLoadingReport={isLoadingWorkReport}
      />
      { totalSize > 15 && (
        <Pagination
          className="customer-pagination"
          current={pageNo}
          total={totalSize > 10000 ? 10000 : totalSize}
          showSizeChanger={false}
          onChange={handleOnPageChange}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    dateRange: state.reportReducer.dateRange,
    screenFilter: state.reportReducer.screenFilter
  };
};
const mapDispatchToProps = {
  fetchSort,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkLogReport);
