


import React, { useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import ManageReturnsIcon from "../../../Assets/Images/Icons/Seller/returns.svg";
import ManageReturnsIconActive from "../../../Assets/Images/Icons/Seller/returnsActive.svg";

export default function SideBarMenu({
  sideBarData,
  isNav,
  setNavVisible,
  isCustomer,
  activeTab,
  setSearchval,
  sellerStatus
}) {
  const [selectedMenu, setselectedMenu] = useState();
  const { SubMenu } = Menu;

  const handleMenuChange = (data) => {
    setSearchval && setSearchval("");
    setselectedMenu(data.key);
    if (isNav) {
      setNavVisible(false);
    }
  };

  return (
    <Menu
      className="side-nav-menu"
      mode="inline"
      defaultSelectedKeys={[activeTab]}
      selectedKeys={[activeTab]} //activeTab refers to current active tab
      onClick={handleMenuChange}
      style={{ borderRadius: "1em" }}
    >
      {sideBarData.map((data) => {
        return (
          <Menu.Item
            key={data.key}
            // disabled={data.key !== "manage-products" ? true : false}
          >
            <NavLink to={data.url}>
              <span class="menu-icn">
                {" "}
                <img
                  src={data.key === activeTab ? data.activeIcon : data.icon}
                />
              </span>
              <span className="side-menu-link-content">
                <span>{data.label}</span>
                {data.newModule && (
                  <span className="new-label">{data.newModule}</span>
                )}
              </span>
            </NavLink>
          </Menu.Item>
        );
      })}

      {sellerStatus === "APPROVED" && (
        <SubMenu
          key="sales-report"
          title={
            <Fragment>
              <span>
                <img
                  alt="Reports"
                  src={
                    "sales-report" === activeTab
                      ? ManageReturnsIconActive
                      : ManageReturnsIcon
                  }
                  style={{ width: "20px", marginRight: "10px" }}
                />
              </span>
              <span
                className="side-menu-link-content"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "normal",
                }}
              >
                <span>Reports</span>
              </span>
            </Fragment>
          }
        >
          <Menu.Item key="sales-report">
            <NavLink to={"/seller/sales-report"}>
              <span className="side-menu-link-content">Sales Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="stocks-report">
            <NavLink to={"/seller/stocks-report"}>
              <span className="side-menu-link-content">Stocks Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="stocks-report">
            <NavLink to={"/seller/detailed-report"}>
              <span className="side-menu-link-content">Stock Value</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="expiry-report">
            <NavLink to={"/seller/expiry-report"}>
              <span className="side-menu-link-content">Expiry Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="day-book-report">
            <NavLink to={"/seller/day-book-report"}>
              <span className="side-menu-link-content">Day Book Report</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      )}
    </Menu>
  );
}
