


import React, { useState, Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Divider, Typography, Button } from "antd";

import OrderProduct from "./orderProduct";

import { fetchCartCount } from "../../../../../ReduxStore/action/";
import TaxInvoice from "./TaxInvoice";
import { formatOrderSummary } from "../manageOrdersHelper";

const { Text } = Typography;
const OrderByVendor = (props) => {
  const { vendor, groupId, order } = props;
  const [invoiceVisible, setinvoiceVisible] = useState(false);
  const [orderSummary, setorderSummary] = useState({});

  // console.log("this is vendor props: ", props);

  useEffect(() => {
    console.log(vendor, order, ": vendor items");
  }, [vendor]);

  useEffect(() => {
    setorderSummary(formatOrderSummary(order, vendor));
  }, [vendor, order]);

  return (
    <Fragment>
      <div className="group_seperator">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="sold_data">
            <Text type="secondary">Sold By: </Text>
            <Text className="them-clr"> &nbsp; {vendor?.vendor}</Text>
          </div>
          {vendor?.orderStatus !== "CANCELLED" && (
            <Button
              className="multiple_invoice_btn"
              style={{ color: "#030c45" }}
              onClick={() => setinvoiceVisible(!invoiceVisible)}
            >
              View Invoice
            </Button>
          )}
        </div>

        <Divider style={{ margin: "25px 0 25px" }} />
        {vendor?.orderedItemsOfVendor.map((product) => {
          return (
            <OrderProduct
              key={product.id}
              product={product}
              groupId={groupId}
              isStore={vendor.vendorType === "STORE"}
            />
          );
        })}
      </div>
      <TaxInvoice
        visible={invoiceVisible}
        setvisible={setinvoiceVisible}
        orderSummary={orderSummary}
      />
    </Fragment>
  );
};

const mapDispatchToProps = { fetchCartCount };
const mapStateToProps = (state) => {
  return {
    cartCountStore: state.cartCountReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderByVendor)
);
