


import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Form,
  Card,
  Input,
  Typography,
  InputNumber,
  message,
  Button,
} from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { addPriceRangeFilter } from "../../../../../../ReduxStore/action/productActions";

import "./filters.scss";
const { Text } = Typography;

const PriceFilter = (props) => {
  const [form] = Form.useForm();
  const { width, setisFilterApplied } = props;
  const [hide, sethide] = useState(true);

  const [minPrice, setminPrice] = useState();
  const [maxPrice, setmaxPrice] = useState();

  const handleClearPriceFilter = () => {
    setisFilterApplied(true);
    form.resetFields();
    props.addPriceRangeFilter({});
    // setminPrice();
    // setmaxPrice();
  };

  const extra = () => {
    return (
      <div onClick={() => sethide(!hide)}>
        {hide ? <DownOutlined /> : <UpOutlined />}
      </div>
    );
  };
  const title = () => {
    return (
      <Text onClick={() => sethide(!hide)} style={{ cursor: "pointer" }}>
        Price
      </Text>
    );
  };

  // useEffect(() => {
  //   let data = {};
  //   if (minPrice) data["gte"] = minPrice;
  //   if (maxPrice) data["lte"] = maxPrice;
  //   if (minPrice && maxPrice) {
  //     if (minPrice < maxPrice) {
  //       // console.log("");
  //       props.addPriceRangeFilter(data);
  //     } else {
  //       message.warning("MinPrice should be less than MaxPrice");
  //     }
  //   } else {
  //     props.addPriceRangeFilter(data);
  //   }
  //   // if (minPrice || maxPrice) {
  //   //   setisFilterApplied(true);
  //   // } else {
  //   //   setisFilterApplied(false);
  //   // }
  //   setisFilterApplied(true);
  // }, [minPrice, maxPrice]);

  const handleApplyPriceFilter = () => {
    let data = {};
    if (minPrice) data["gte"] = minPrice;
    if (maxPrice) data["lte"] = maxPrice;
    if (minPrice && maxPrice) {
      if (minPrice < maxPrice) {
        // console.log("");
        props.addPriceRangeFilter(data);
      } else {
        message.warning("MinPrice should be less than MaxPrice");
      }
    } else {
      props.addPriceRangeFilter(data);
    }
    // if (minPrice || maxPrice) {
    //   setisFilterApplied(true);
    // } else {
    //   setisFilterApplied(false);
    // }
    setisFilterApplied(true);
  };

  return (
    <Card
      title={title()}
      extra={extra()}
      style={{ width: width ? width : 310 }}
      className="filter-by-conatiner"
    >
      {!hide && (
        <div className="filter-by-options filter_menu_list">
          <Form form={form} name="price-filter-form">
            <div className="price-filter-input-container">
              <Form.Item
                name="minPrice"
                rules={[
                  {
                    pattern: new RegExp("^[0-9.]*$"),
                    message: "Invalid Price !",
                  },
                ]}
              >
                <InputNumber
                  placeholder="min"
                  type="number"
                  value={minPrice}
                  onChange={(value) => {
                    if (value >= 0) setminPrice(Math.floor(value));
                    else setminPrice();
                  }}
                  style={{ marginRight: "10px" }}
                  className="price-input"
                />
              </Form.Item>
              <Form.Item
                name="maxPrice"
                rules={[
                  {
                    pattern: new RegExp("^[0-9.]*$"),
                    message: "Invalid Price !",
                  },
                ]}
              >
                <InputNumber
                  placeholder="max"
                  type="number"
                  value={maxPrice}
                  onChange={(value) => {
                    if (value > 0) setmaxPrice(Math.floor(value));
                    else setmaxPrice();
                  }}
                  className="price-input"
                />
              </Form.Item>
            </div>

            <div className="filter-by-clear-all">
              <Button
                type="text"
                onClick={() => {
                  handleClearPriceFilter();
                  window.scrollTo(0, 0);
                }}
              >
                {" "}
                Clear All
              </Button>
              <Button type="text" onClick={() => handleApplyPriceFilter()}>
                Apply
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Card>
  );
};

const mapDispatchToProps = { addPriceRangeFilter };

export default connect(null, mapDispatchToProps)(PriceFilter);
