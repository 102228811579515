


import { CART_COUNT } from "../action/types";

export default function cartCountReducer(state = 0, action) {
  switch (action.type) {
    case CART_COUNT:
      // console.log("reducer: ", action.data);
      state = action.data;

    default:
      // console.log("invalid choice");
  }
  return state;
}
