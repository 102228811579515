


import React, { useEffect, useState } from "react";
import { Carousel } from "antd";

import "./Banner.css";
import { ENV_TYPE, FILE_PATH } from "../../Utils/constants";
import PlaceHolderBanner from "../../Assets/Images/PlaceHolderImages/placeholder.gif";
import { history } from "../../App";

export default function Banner(props) {
  const { banners } = props;
  const [imageLoadError, setimageLoadError] = useState(false);

  // function onChange(a, b, c) {
  //   console.log(a, b, c);
  // }

  useEffect(() => {
    setimageLoadError(
      banners.map((banner, index) => {
        return false;
      })
    );
  }, [banners]);

  return (
    <Carousel
      //  afterChange={onChange}
      autoplay={true}
    >
      {banners?.map((banner, index) => {
        return (
          <div key={index}>
            {banner?.image && !imageLoadError[index] ? (
              <img
                style={{ width: "100%" }}
                src={`${FILE_PATH}${banner.image}`}
                alt="banner_img"
                onError={() => {
                  setimageLoadError((prevState) => {
                    return [...prevState, (prevState[index] = true)];
                  });
                }}
                onClick={() => {
                  if(banner.bannerConditions?.filter?.term?.[0]?.productId) {
                    history.push((ENV_TYPE == "production"? "/product-detail/": "/customer/product-detail/") 
                    + banner.bannerConditions.filter.term[0].productId)
                  }
                }}
              />
            ) : (
              <img
                style={{ width: "100%" }}
                src={`${FILE_PATH}${PlaceHolderBanner}`}
                alt="banner_placeholder_img"
              />
            )}
          </div>
        );
      })}
    </Carousel>
  );
}
