


import React from "react";
import { Button, Modal, Typography } from "antd";

import "./signupSuccessModal.css";

import CompanyLogo from "../../../Assets/Images/Logo/DL_Logo.png";
import SuccessIcon from "../../../Assets/Images/Icons/success.svg";

const { Title, Text } = Typography;
const SignupSuccessModal = ({ visible, setvisible, message, signUpResponse }) => {
  const handleOk = () => {
    setvisible(false);
  };

  const handleCancel = () => {
    setvisible(false);
  };

  return (
    <Modal
      closable={true}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      footer={null}
    >
      <div className="success-modal-container">
        <div>
          <Title level={3}>
            Welcome to{" "}
            <span>
              {" "}
              <img
                className="login-company-logo"
                src={CompanyLogo}
                alt={"company logo"}
              />
            </span>
          </Title>
        </div>

        <div className="success-modal-content">
          <img src={SuccessIcon} className="success-icon" alt="success-icon" />
          <Title level={3}>Successfully Registered</Title>
          <div>
            <Text type="secondary" strong>
              {message}
            </Text>
          </div>
         {signUpResponse && (
            <div style={{paddingTop: "10px"}}>
              <div>
                <Text type="secondary" strong>
                  Payment ID: {signUpResponse.orderGroupId}
                </Text>
              </div>
              <div>
                {signUpResponse?.phonePeTransactionId && (
                    <Text type="secondary" strong>
                      PhonePe TransactionID: {signUpResponse?.phonePeTransactionId}
                    </Text>
                )}
                {signUpResponse?.ccAvenueOrderId && (
                  <Text type="secondary" strong>
                    CCAvenue OrderID: {signUpResponse?.ccAvenueOrderId}
                  </Text>
                )}
              </div>
            </div>
          )}

          <Button
            type="primary"
            className="success-modal-button"
            onClick={() => setvisible(false)}
          >
            {" "}
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SignupSuccessModal;
