

import React, { useState, useEffect } from "react";
import $, { type } from "jquery";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import {
  Select,
  notification,
  Input,
  Typography,
  AutoComplete,
  Radio,
} from "antd";
import "../../SearchBar/Searchbar.css";
import useDebounce from "../../../Hooks/useDebounce";
import Cookies from "universal-cookie";
import {
  addSearchSelection,
  fetchCheckoutSearchSuggestions,
} from "./quickCheckoutAPI";
import SearchIcon from "../../../Assets/Images/Icons/search.svg";

const { Option } = AutoComplete;
const { Text } = Typography;

const SearchBarDropDown = (props) => {
  const cookies = new Cookies();

  const [searchKey, setsearchKey] = useState("");
  const [list, setlist] = useState([]);
  const [cart, setcart] = useState([]);
  const debouncedSearchTerm = useDebounce(searchKey, 400);
  const [isLoading, setisLoading] = useState("");
  const [barcodeEnabled, setBarcodeEnabled] = useState(true);

  useEffect(() => {
    handleFocus();
    if (debouncedSearchTerm && debouncedSearchTerm.length > 3) {
      handleFetchSearchSuggestions(debouncedSearchTerm);
    } else {
      handleFetchSearchSuggestions("");
    }
  }, [debouncedSearchTerm]);

  const handleFetchSearchSuggestions = (key) => {
    const payload = {
      offset: 0,
      size: 40,
      key: key,
      vendorId: cookies.get("vendorId"),
      isBarcodeSearch: barcodeEnabled,
    };
    if (key) {
      setlist([]);
      fetchCheckoutSearchSuggestions(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              if (response?.data?.documents?.length === 1 && barcodeEnabled) {
                handleProductChange(response?.data?.documents[0]);
              }
              setlist(response.data.documents);
            } else if (response.data.status === "failed") {
              if (response?.data?.message === "No product with this name") {
                setlist([]);
              }
              if (response?.data?.error !== "EXCEPTION") {
                notification["error"]({
                  message: "Failed",
                  description: response.data.message,
                });
              }
            }
          }
        })
        .finally(() => {
          setisLoading("");
        });
    } else setlist([]);
  };

  const handleproductSearch = (key) => {
    if (key) {
      setisLoading("Loading...");
    } else {
      setisLoading("");
    }
    setsearchKey(key);
  };

  const handleFocus = () => {
    $("#quick-scrollbar")
      .on("focus", function () {
        $("body").addClass("scroll-disabled");
      })
      .on("blur", function () {
        $("body").removeClass("scroll-disabled");
      });
  };

  const handleProductChange = (value) => {
    setsearchKey(value.name);

    const payload = {
      productId: value.productId,
      checkoutCartId: props.data.checkoutCartId,
      variant: value.variant,
      sellingPrice: value.sellingPrice,
    };

    if (value) {
      props.setisLoading(true);

      addSearchSelection(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              props.setdata(response.data.quickCheckoutCart);

              notification["success"]({
                message: "Success",
                description: "Product Added to Cart",
              });
            }
            if (response.data.status === "failed") {
              notification["error"]({
                message: "Failed",
                description: response.data.message,
              });
            }
          }
        })
        .finally(() => {
          props.setisLoading(false);
        });
    }
    setsearchKey("");
  };

  const handleSelectProduct = (value, option) => {
    handleProductChange(option?.data);
  };

  useEffect(() => {
    console.log("updated list: ", list);
  }, [list]);

  // useEffect(() => {
  //   setlist([]);
  // }, [props]);

  return (
    <div className="product-search-box">
      <div className="product-search">
        <h3> Search for Products</h3>
        <AutoComplete
          size={"large"}
          value={searchKey}
          className="header-search-bar search"
          showSearch
          showArrow={false}
          defaultActiveFirstOption={true}
          placeholder="Search Products"
          onSelect={(value, option) => handleProductChange(option?.data)}
          //onSelect={handleSelectProduct}
          onSearch={handleproductSearch}
          onFocus={handleFocus}
          autoFocus={true}
          id="scrollbar"
          notFoundContent={isLoading ? isLoading : "No Product Found"}
        >
          {list.map((data, index) => {
            return (
              // <React.Fragment>
              <Option
                //value={data?.name + data?.variant}
                key={index + 1}
                data={data}
              >
                <div className="quick-checkout-search-option">
                  <div>
                    <Text strong>{data.name}</Text>
                    <br />
                    {data.variant && (
                      <Text strong style={{ marginRight: "15px" }}>
                        {" "}
                        {data.variant}
                      </Text>
                    )}

                    {data.sellingPrice && (
                      <>
                      <Text style={{ marginRight: "6px" }}>DLP</Text>
                      <Text strong style={{ color: "#3e6df3" }}>
                        ₹{Number(data.sellingPrice).toFixed(2)}
                      </Text>
                      </>
                    )}

                    {data.price && (
                      <>
                      <Text style={{ marginRight: "6px", marginLeft: "15px" }}>MRP</Text>
                      <Text strong style={{ color: "#3e6df3" }}>
                        ₹{Number(data.price).toFixed(2)}
                      </Text>
                      </>
                    )}

                    {data.stock && (
                      <>
                      <Text style={{ marginRight: "6px", marginLeft: "15px" }}>Stock</Text>
                      <Text strong style={{ color: "#3e6df3" }}>
                        {Number(data.stock)}
                      </Text>
                      </>
                    )}
                  </div>

                  <button
                    className="quantity-input__modifier quantity-input__modifier--right cart-quantity-controller "
                    style={{
                      fontWeight: "500",
                      backgroundColor: "#3e6df3",
                    }}
                    // onClick={() => handleProductChange(data)}
                  >
                    &#xff0b;
                  </button>
                </div>
              </Option>
              // </React.Fragment>
            );
          })}
        </AutoComplete>
      </div>
      <div className="barcode-group">
        <h4>BARCODE</h4>
        <Radio.Group
          defaultValue={barcodeEnabled}
          onChange={(e) => {
            setBarcodeEnabled(e.target.value);
            document.getElementById("scrollbar").focus();
          }}
          buttonStyle="solid"
          value={barcodeEnabled}
        >
          <Radio.Button value={true}>ON</Radio.Button>
          <Radio.Button value={false}>OFF</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  );
};

export default SearchBarDropDown;
