


import {
  CART_COUNT,
  CUSTOMER,
  SORTBY,
  MANAGE_ORDER,
  FETCH_CART_ITEMS,
  FETCH_COMPANY_DETAILS,
} from "./types";

export const fetchSort = (data) => {
  return {
    type: CUSTOMER,
    data,
  };
};

export const fetchSortBy = (data) => {
  return {
    type: SORTBY,
    data,
  };
};

export const fetchCartCount = (data) => {
  return {
    type: CART_COUNT,
    data,
  };
};
export const fetchManageOrderFilter = (data = 0) => {
  return {
    type: MANAGE_ORDER,
    data,
  };
};

//fetch cart Items
export const fetchCartItems = (data) => {
  return {
    type: FETCH_CART_ITEMS,
    data,
  };
};

export const fetchCompanyDetails = (data) => {
  return {
    type: FETCH_COMPANY_DETAILS,
    data,
  }
}
