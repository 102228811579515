


import React, { useState, useEffect } from "react";

import { Tabs, Button, Typography, Dropdown, Menu, message } from "antd";
import { connect } from "react-redux";
import "../../Seller/ManageProducts/manageProducts.css";
import "./centralRepo.scss";
import SortIcon from "../../../Assets/Images/Icons/Seller/Sort2.svg";
import { withRouter } from "react-router-dom";

import ManageProductList from "../../Seller/ManageProducts/Components/manageProductList";

import BulkUploadModal from "../../Seller/ManageProducts/Components/bulkUpload";
import { getProducts } from "../../../ApiAction/SellerApiActions/sellerApi";
import Cookies from "universal-cookie";
import {
  fetchCentralRepo,
  exportCentralRepo,
} from "../../../ApiAction/AdminApiAction/centralRepoApi";
import Loading from "../../GeneralComponents/Loading";
import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";
import EmptyList from "../../GeneralComponents/emptyList";
import { MdCenterFocusStrong } from 'react-icons/md';

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const CentralRepo = (props) => {
  const { searchVal, isVendor } = props;
  const cookies = new Cookies();
  const [isMobileView, setisMobileView] = useState(false);
  const [type, setType] = useState("active");
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);
  const [isLoadingExport, setisLoadingExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reload,setReload] = useState(false)
  const handleExportProducts = () => {
    setisLoadingExport(true);
    exportCentralRepo({})
      .then((response) => {
        if (response.status === 200) {
          message.success("Successfully exported products data.");
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `products.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        message.error("Could not export file please try again");
      })
      .finally(() => {
        setisLoadingExport(false);
      });
  };

  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    handleFetchProductList(1);
  }, [props,reload]);

  const handleFetchProductList = (pageNo, activeTab) => {
    setCurrentPage(pageNo)
    setIsLoading(true);
    let offSet = (pageNo - 1) *
      (cookies.get("accountType") == "admin"? 30 : 10);
    const fieldValue = activeTab
      ? activeTab === "active"
        ? false
        : true
      : type === "active"
      ? false
      : true;
    const payload = {
      offset: offSet,
      size: cookies.get("accountType") == "admin"? 30 : 10,
      key: searchVal ? searchVal : "",
      sortBy: "addedDate",
      sortType: props.sort ? props.sort.sortBy : "asc",
      isDraft: fieldValue,
      archive: false,
      accountType: cookies.get("accountType") && cookies.get("accountType") === "seller" ? "seller" : ''
    };
    fetchCentralRepo(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setProductList(response.data.products);
            setPage(Math.ceil(response.data.count / 10) * 10);
            console.log(response.data, "response for central list fetch");
            if(response.data.count === 0) {
              setProductList([])
            }
          } else setProductList([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleChangetab = async (activeKey) => {
    await setType(activeKey);
    console.log(activeKey, ":active key");
    setProductList([]);
    handleFetchProductList(1, activeKey);
  };
  const renderAddProduct = () => {
    return (
      <Menu>
        {/* <Menu.Item
          onClick={() => {
            setBulkUploadModalVisible(true);
          }}
        >
          Bulk Upload
        </Menu.Item> */}
        <Menu.Item
          onClick={() => {
            props.history.push("/admin/central-repo/add-product");
          }}
        >
          Add Product
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        {" "}
        {!isMobileView ? (
          <h1 level={4} className="sub-title-tags">
            {" "}
            <MdCenterFocusStrong /> Central Repo Products
          </h1>
        ) : (
          <Text>
            {" "}
            <span>
              {" "}
              <img
                src={SortIcon}
                alt="delete-icon"
                style={{ width: "25px", paddingRight: "5px" }}
              />
            </span>
            Sort
          </Text>
        )}
        {!isVendor && (
          <div className="manage-products-action">
              <Button
                size={isMobileView ? "small" : ""}
                type="primary"
                className="manage-products-action-button"
                onClick={() => props.history.push("/admin/central-repo/add-product")}
              >
                Add Product
              </Button>
            <Button
              onClick={() => handleExportProducts()}
              size={isMobileView ? "small" : ""}
              type="primary"
              className="manage-products-action-button exprt-btn"
            >
              {isLoadingExport ? (
                <Loading style={{ color: "white" }} />
              ) : (
                "Export Products"
              )}
            </Button>
          </div>
        )}
      </div>
      {isVendor ? (
        !isLoading && productList.length === 0 ? (<EmptyList msg={"Product Currently Unavailable"} />) : (
          <ManageProductList
            type={type}
            productList={productList}
            total={page}
            currentPage={currentPage}
            nextpage={handleFetchProductList}
            isCentralRepo
            isVendor={isVendor}
            isActive={true}
            isLoading={isLoading}
          />
        )
      ) : (
        <Tabs
          defaultActiveKey="active"
          onChange={handleChangetab}
          size={"large"}
        >
          <TabPane tab="Active" key="active">
              {!isLoading && productList.length === 0 ? (<EmptyList msg={"Product Currently Unavailable"} />) :
              (
                <ManageProductList
                  type={type}
                  productList={productList}
                  total={page}
                  currentPage={currentPage}
                  nextpage={handleFetchProductList}
                  isCentralRepo
                  setReload={setReload}
                  isVendor={isVendor}
                  isActive={type === "active" ? true : false}
                  isLoading={isLoading}
                />
              )
            }
          </TabPane>

          <TabPane tab="Draft" key="draft">
            {!isLoading && productList.length === 0 ? (<EmptyList msg={"Product Currently Unavailable"} />) :
             (
                <ManageProductList
                  type={type}
                  productList={productList}
                  total={page}
                  currentPage={currentPage}
                  nextpage={handleFetchProductList}
                  isCentralRepo
                  isActive={type === "active" ? true : false}
                  isLoading={isLoading}
                />
              )
            }
          </TabPane>
        </Tabs>
      )}
      <BulkUploadModal
        isCentralRepo
        visible={bulkUploadModalVisible}
        setVisiblity={setBulkUploadModalVisible}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default withRouter(connect(mapStateToProps)(CentralRepo));
