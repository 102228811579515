

import React, { useEffect, useState } from "react";
import { Layout, Button } from "antd";
import { Route, Redirect, Router, Switch } from "react-router-dom";
import Cookies from "universal-cookie";
import { PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./admin.scss";
import ManageCustomer from "./ManageCustomer/manageCustomer";
import ManageCatogery from "./ManageCategory";
import ManageAccounts from "./ManageAccounts/index";
import ManageBrand from "./ManageBrand";
import { fetchSort } from "../../ReduxStore/action";
import { connect } from "react-redux";
import ManageSeller from "./ManageSeller/manageSeller";
import FooterLayout from "../../Layouts/FooterLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import AdminMenu from "./adminMenu";
import SortBy from "../Seller/SortBy";
import Searchbar from "../SearchBar";
import ManageProfile from "./ManageProfile/manageProfile";
import ManageStore from "./ManageStore/manageStore";
import ManageWarehouse from "./ManageWarehouse/manageWarehouse";
import SearchBarInput from "../SearchBar/SearchBarInput";
import FilterBlockArchive from "../Seller/SortBy/filterBlockArchive";
import ManageProductAdmin from "./ManageProduct/manageProductAdmin";
import AddProduct from "../Seller/ManageProducts/Components/addProduct";
import FilterbyDomin from "../Seller/SortBy/filterbyDomin";
import FilterbyStatus from "../Seller/SortBy/filterbyStatus";
import ManageSections from "./ManageSections/manageSections";
import InvoiceSearch from "./InvoiceSearch/InvoiceSearch";
import SalesReport from "./Reports/SalesReport";
import StocksReport from "./Reports/StocksReport";
import SearchReport from "./Reports/SearchReport";
import ExpiryReport from "./Reports/ExpiryReport";
import ReportsFilter from "./Reports/Filter/reportsFilter";
import { configure } from "@testing-library/react";
import Configure from "./Configure/configure";
import ManageContent from "./ManageContent/manageContent";
import ContentSection from "./ManageContent/contentSection";
import AccountTypeContext from "./accountTypeContext";
import CentralRepo from "./CentralRepo";
import { ENV_TYPE, DOMAIN } from "../../Utils/constants";
import GroupByReport from "../Seller/SortBy/groupByReport";
import ManageAdvertisement from "./ManageAdvertisement/AdIndex";
import ManagePlatformIndex from "./ManagePlatform/ManagePlatformIndex";
import BarcodeSearch from "./BarcodeSearch/BarcodeSearch";
import DiscoutProducts from "./DiscountProducts/DiscoutProducts";
import ManageAllOrders from "./ManageAllOrders/manageAllOrders";
import FilterAndSortOrder from "./ManageAllOrders/filterAndSortOrder";
import RequestPayment from "./RequestPayment";
import ReplacedProducts from "./CentralRepo/replacedProducts";
import DayBookReport from "./Reports/DayBookReport";
import VerifiedProducts from "./CentralRepo/verifiedProducts";
import ManageProductStock from "../Seller/ManageStocks/manageProductStock";
import AutoApprovalReport from "./Reports/AutoApprovalReport";
import ManageAffiliate from "./ManageAffiliate/manageAffiliate";
import AffiliateChargeReport from "./Reports/AffiliateChargeReport";
// import FilterAndSortOrder from "../Seller/ManageOrder/filterAndSortOrder";
const { Content, Sider } = Layout;
const Admin = (props) => {
  const cookies = new Cookies();
  const [urlPath, setUrlPath] = useState("");
  const [activeTab, setactiveTab] = useState();
  const [searchVal, setSearchval] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [openAddWarehouse, setOpenAddWarehouse] = useState(false);
  const [openAddStore, setOpenAddStore] = useState(false);

  const [openAddBrand, setopenAddBrand] = useState(false);

  const [reportSummary, setreportSummary] = useState("");

  const urlPathswithExtraHeader = [
    "/admin/manage-customer",
    "/admin/manage-seller",
    "/admin/manage-store",
    "/admin/manage-warehouse",
    "/admin/manage-brand",
    "/admin/manage-products",
    "/admin/auto-approval",
    "/admin/manage-all-order",
    "/admin/sales-report",
    "/admin/day-book-report",
    "/admin/stocks-report",
    "/admin/auto-approval-report",
    "/admin/affiliate-charge-report",
    "/admin/search-report",
    "/admin/expiry-report",
    "/admin/central-repo",
    "/admin/verified-products",
  ];

  useEffect(() => {
    // if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
    //   setCollapsed(true);
    // } else setCollapsed(false);

    if (ENV_TYPE == "dev") {
      cookies.set("userId", cookies.get("adminId"), {
        path: "/",
      });

      cookies.set("accountType", "admin", {
        path: "/",
      });
    } else {
      cookies.set("userId", cookies.get("adminId"), {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      cookies.set("accountType", "admin", {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    }
  }, []);

  useEffect(() => {
    setUrlPath(props.location.pathname);
    setactiveTab(props.location.pathname.split("/").pop());
    setSearchval("");
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <Layout>
      <HeaderLayout pathname={urlPath} isAdmin />
      {urlPath == "/admin/profile" ||
      urlPath == "/admin/manage-sections" ||
      urlPath == "/admin/manage-content" ||
      urlPath == "/admin/configure" ||
      urlPath == "/admin/manage-category" ||
      urlPath == "/admin/manage-accounts" ||
      urlPath == "/admin/manage-platform" ||
      urlPath == "/admin/manage-platform-sellers" ||
      urlPath == "/admin/manage-platform-stores" ||
      urlPath == "/admin/manage-platform-summary" ||
      // urlPath == "/admin/manage-all-order" ||
      urlPath == "/admin/invoice-search" ||
      urlPath == "/admin/barcode-search" ||
      urlPath == "/admin/manage-ads" ? (
        <div style={{ marginTop: "70px" }}></div>
      ) : (
        <div className="sort-breadcrumb">
          <div className="admin-breadcrumb" style={{ width: "325px" }}></div>
          {urlPathswithExtraHeader.includes(urlPath) && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginLeft: "2%",
              }}
            >
              {<SearchBarInput searchValue={setSearchval} urlPath={urlPath} />}
              {(urlPath === "/admin/manage-store" ||
                urlPath === "/admin/manage-warehouse" ||
                urlPath === "/admin/manage-brand") && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size={"large"}
                  onClick={() => {
                    if (urlPath === "/admin/manage-warehouse")
                      setOpenAddWarehouse(true);
                    else if (urlPath === "/admin/manage-store")
                      setOpenAddStore(true);
                    else
                      setopenAddBrand(true);
                  }}
                >
                  {urlPath === "/admin/manage-warehouse"
                    ? "Add Warehouse"
                    : urlPath === "/admin/manage-store"
                    ? "Add Store"
                    : "Add Brand"}
                </Button>
              )}
              {/* {urlPath === "/admin/manage-products" && <FilterbyDomin />} */}
              {/* {urlPath === "/admin/manage-products" && <FilterbyStatus />} */}
              {
                // urlPath === "/admin/stocks-report" ||
                // urlPath === "/admin/search-report" ||
                // urlPath === "/admin/expiry-report" ||
                (urlPath === "/admin/sales-report" ||
                 urlPath === "/admin/day-book-report" ||
                 urlPath === "/admin/auto-approval" ||
                 urlPath === "/admin/auto-approval-report" ||
                 urlPath === "/admin/affiliate-charge-report" ||
                  urlPath === "/admin/manage-products") && (
                  <ReportsFilter
                    activePath={activeTab}
                    setreportSummary={setreportSummary}
                  />
                )
              }
              {urlPath === "/admin/sales-report" && <GroupByReport />}
              {
              urlPath !== "/admin/expiry-report" &&
                urlPath != "/admin/stocks-report" &&
                urlPath != "/admin/sales-report" &&
                urlPath != "/admin/day-book-report" &&
                urlPath != "/admin/affiliate-charge-report" &&
                urlPath != "/admin/manage-all-order" &&
                urlPath != "/admin/search-report" && (
                  <SortBy activePath={activeTab} />
                )}
              {
                 urlPath === "/admin/manage-all-order" ?
                 <FilterAndSortOrder/> :

                urlPath != "/admin/manage-products" &&
                urlPath != "/admin/auto-approval" &&
                urlPath != "/admin/stocks-report" &&
                urlPath != "/admin/sales-report" &&
                urlPath != "/admin/day-book-report" &&
                urlPath != "/admin/auto-approval-report" &&
                urlPath != "/admin/affiliate-charge-report" &&
                urlPath != "/admin/search-report" &&
                urlPath != "/admin/expiry-report" &&
                urlPath != "/admin/verified-products" &&
                urlPath !== "/admin/central-repo" && <FilterBlockArchive />}

            </div>
          )}
        </div>
      )}

      <Layout className="nav-side-content-admin" style={{ padding: "3%" }}>
        <div className="profile_column_data">
          {!collapsed && (
            <Sider
              className="nav-side-bar-admin"
              trigger={null}
              collapsed={collapsed}
            >
              <AdminMenu activeTab={activeTab} setSearchval={setSearchval} />
            </Sider>
          )}
          <Layout className="admin-layout-content right_profile_layout">
            <Content>
              <Switch>
                <Route
                  exact
                  path="/admin"
                  render={(props) => {
                    return <Redirect to="/admin/profile" />;
                  }}
                />

                <Route
                  exact
                  path="/admin/profile"
                  render={(props) => <ManageProfile {...props} isAdmin />}
                />
                <Route
                  exact
                  path="/admin/manage-customer"
                  render={(props) => (
                    <ManageCustomer {...props} searchVal={searchVal} />
                  )}
                />
                <Route
                  exact
                  path="/admin/manage-seller"
                  render={(props) => (
                    <ManageSeller {...props} isSeller searchVal={searchVal} />
                  )}
                />
                <Route
                  exact
                  path="/admin/manage-store"
                  render={(props) => (
                    <ManageStore
                      {...props}
                      openAdd={openAddStore}
                      addModal={setOpenAddStore}
                      isAdmin={true}
                      searchVal={searchVal}
                    />
                  )}
                />

                <Route
                  exact
                  path="/admin/manage-warehouse"
                  render={(props) => (
                    <ManageWarehouse
                      {...props}
                      openAdd={openAddWarehouse}
                      isWarehouse
                      addModal={setOpenAddWarehouse}
                      searchVal={searchVal}
                    />
                  )}
                />

                <Route
                  exact
                  path="/admin/manage-affiliate"
                  render={(props) => (
                    <ManageAffiliate
                      {...props}
                      searchVal={searchVal}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/manage-accounts"
                  render={(props) => <ManageAccounts isAdmin />}
                />

                <Route
                  exact
                  path="/admin/manage-platform-sellers"
                  render={(props) => (
                    <ManagePlatformIndex vendorType="SELLER" />
                  )}
                />

                <Route
                  exact
                  path="/admin/manage-platform-stores"
                  render={(props) => <ManagePlatformIndex vendorType="STORE" />}
                />

                <Route
                  exact
                  path="/admin/manage-affiliate-charge"
                  render={(props) => <ManagePlatformIndex vendorType="AFFILIATE" />}
                />

               <Route
                  exact
                  path="/admin/discount-products"
                  render={(props) => <DiscoutProducts vendorType="STORE" />}
                />
                <Route
                  exact
                  path="/admin/manage-ads"
                  render={(props) => <ManageAdvertisement isAdmin />}
                />
                <Route
                  exact
                  path="/admin/manage-platform-summary"
                  render={(props) => (
                    <ManagePlatformIndex isAdmin type="summary" />
                  )}
                />

                <Route
                  exact
                  path="/admin/manage-brand"
                  render={(props) => (
                    <ManageBrand
                      modalVisible={openAddBrand}
                      setModalVisible={setopenAddBrand}
                      searchVal={searchVal}
                      {...props}
                    />
                  )}
                />

                <Route
                  exact
                  path="/admin/manage-category"
                  render={(props) => <ManageCatogery {...props} />}
                />
                <Route
                  exact
                  path="/admin/manage-products"
                  render={(props) => (
                    <ManageProductAdmin
                      searchVal={searchVal}
                      {...props}
                      summary={reportSummary}
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/auto-approval"
                  render={(props) => (
                    <ManageProductAdmin
                      searchVal={searchVal}
                      {...props}
                      summary={reportSummary}
                      isAutoApproval
                    />
                  )}
                />
                <Route
                  exact
                  path="/admin/manage-price-stock/:productId"
                  render={(props) => {
                    return <ManageProductStock {...props} isAdmin />;
                  }}
                />
                <Route
                  exact
                  path="/admin/edit-product/:productId"
                  render={(props) => {
                    return <AddProduct {...props} isEdit isAdmin />;
                  }}
                />

                <Route
                  exact
                  path="/admin/manage-sections"
                  render={(props) => {
                    return <ManageSections {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/admin/manage-all-order"
                  render={(props) => {
                    return <ManageAllOrders searchVal={searchVal} {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/admin/invoice-search"
                  render={(props) => {
                    return <InvoiceSearch {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/admin/configure"
                  render={(props) => {
                    return <Configure {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/admin/manage-content"
                  render={(props) => {
                    return <ManageContent {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/admin/content/:type"
                  render={(props) => {
                    return <ContentSection {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/admin/barcode-search"
                  render={(props) => {
                    return <BarcodeSearch {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/admin/sales-report"
                  render={(props) => {
                    return (
                      <SalesReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/admin/stocks-report"
                  render={(props) => {
                    return (
                      <StocksReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/admin/auto-approval-report"
                  render={(props) => {
                    return (
                      <AutoApprovalReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/admin/affiliate-charge-report"
                  render={(props) => {
                    return (
                      <AffiliateChargeReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/admin/search-report"
                  render={(props) => {
                    return (
                      <SearchReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/admin/expiry-report"
                  render={(props) => {
                    return (
                      <ExpiryReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/admin/day-book-report"
                  render={(props) => {
                    return (
                      <DayBookReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/admin/central-repo"
                  render={(props) => {
                    return <CentralRepo searchVal={searchVal} />;
                  }}
                />
                <Route
                  exact
                  path="/admin/replaced-products"
                  render={(props) => {
                    return <ReplacedProducts searchVal={searchVal} />;
                  }}
                />
                 <Route
                  exact
                  path="/admin/verified-products"
                  render={(props) => {
                    return <VerifiedProducts />;
                  }}
                />
                <Route
                  exact
                  path="/admin/central-repo/edit-product/:productId"
                  render={(props) => {
                    return (
                      <AddProduct {...props} isEdit isAdmin isCentralRepo />
                    );
                  }}
                />
                <Route
                  exact
                  path="/admin/central-repo/add-product"
                  render={(props) => {
                    return <AddProduct {...props} isAdmin isCentralRepo />;
                  }}
                />
                 <Route
                  exact
                  path="/admin/request-payment"
                  render={(props) => (
                    <RequestPayment />
                  )}
                />
                <Route
                  render={(props) => {
                    return <Redirect to="/404" />;
                  }}
                />
              </Switch>
            </Content>
          </Layout>
        </div>
      </Layout>
      <AccountTypeContext.Provider value="admin">
        <FooterLayout />
      </AccountTypeContext.Provider>
    </Layout>
  );
};
const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
