


import React, { useState,useEffect } from "react";
import moment from "moment";

import { Modal, Divider, Typography, Input, Row, Col, Image } from "antd";
import AddNewSections from "./addNewSection";


const { Text, Title } = Typography;
const UpdateBanner = (props) => {
  const [isClear, setIsClear] = useState(false);
      
  useEffect(() => {
   setIsClear(!isClear)
       
}, [props]);

  return (

    <Modal

      visible={props.visible}
      onOk={() => {
        setIsClear(!isClear)
        props.setCancel(false)}}
      onCancel={() => {
        setIsClear(!isClear)
        props.setCancel(false)
      }}
       width={750}
      footer={null}
    >

      <div className="view-invoice-layout">
        <div className="detail-modal-heading">Add New</div>
       

      </div>

      <AddNewSections isUpdate type={props.type}
      sectionId={props.sectionId}
      setCancel={props.setCancel}
      isClear={isClear}
      updateSection={props.updateSection}/>
    
    
      


    

      
    </Modal>

  );
}
export default UpdateBanner;