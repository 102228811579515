

import React, { useEffect, useState, useContext } from "react";

import {
  Badge,
  Button,
  Menu,
  Drawer,
  Popover,
  Typography,
  message,
  Dropdown,
  Divider,
} from "antd";
import { AppContext } from "../../components/GeneralComponents/appContext";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import { openLoginModal } from "../../ReduxStore/action/authModalActions";

import { clearProductListingFilter } from "../../ReduxStore/action/productActions";

import { fetchCartCount, fetchCartItems } from "../../ReduxStore/action/index";
import { setPincode } from "../../ReduxStore/action/productActions";
import { fetchUserCartCount } from "../../ApiAction/CustomerApiActions/orderAPI";
import { BsFillMicFill, BsMic } from "react-icons/bs";

import "./HeaderView.scss";
import CompanyLogo from "../../Assets/Images/Logo/C_Logo.png";
import LocationIcon from "../../Assets/Images/Icons/HR_location.svg";
import GlobalIcon from "../../Assets/Images/Icons/global.png";
import CartIcon from "../../Assets/Images/Icons/HR_cart.svg";
import ProfileIcon from "../../Assets/Images/Icons/HR_profile.svg";

import SearchBar from "../../components/SearchBar";
import Megamenu from "../../components/Megamenu";
import MobileMegamenu from "../../components/MobileMegamenu";

import MobileMenu from "../../Assets/Images/Icons/open-menu.svg";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import SideBarMenu from "../../components/Seller/SideBarMenu";
import { sideBarData } from "../../Utils/Seller/Data";

import { megamenuDataResponse } from "../../DummyData/MegamenuData";

import Login from "../..//components/GeneralComponents/Login";
import Signup from "../../components/GeneralComponents/Signup";
import AdminMenu from "../../components/Admin/adminMenu";
import ManagePincode from "./components/managePincode";
import StoreMenu from "../../components/Store/storeMenu";
import {
  userLogout,
} from "../../ApiAction/AdminApiAction/adminApi";
import { withRouter } from "react-router-dom";
import Title from "antd/lib/skeleton/Title";
import adminMenu from "../../components/Admin/adminMenu";

import EmailVerificationModal from "../../components/GeneralComponents/EmailVerification";
import { cartGet } from "../../ApiAction/SellerApiActions/sellerApi";
import { ENV_TYPE, DOMAIN } from "../../Utils/constants";
import { getMegamenu } from "../../ApiAction/CustomerApiActions/homepageAPI";
import { FaExchangeAlt } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { MdRoundedCorner } from "react-icons/md";


const { Text } = Typography;

const HeaderView = (props) => {
  const { sellerView, isCustomer } = props;
  const cookies = new Cookies();
  const [mobileMenuVisible, setmobileMenuVisible] = useState(false);
  const [customerView, setcustomerView] = useState();
  const [adminMenuView, setAdminMenu] = useState(false);
  const [visible, setVisible] = useState(false);
  const [userCartCount, setuserCartCount] = useState(0);
  const [notAtHome, setNotAtHome] = useState(true);


  const [pinNo, setPinNo] = useState(props.pinCode || "");

  const [activeCustomer, setactiveCustomer] = useState(
    JSON.parse(localStorage.getItem("activeCustomer"))
  );
  const [sellerMenuview, setsellerMenuView] = useState(false);

  const [verificationVisible, setverificationVisible] = useState(false);
  const [popOverVisible, setpopOverVisible] = useState(false);
  const [categoriesData, setcategoriesData] = useState(
    JSON.parse(localStorage.getItem("megamenu")) || []
  );

  // const [showMegaMenu, setShowMegaMenu] = useState(false)
  // const [atHome, setAtHome] = useState(false)

  // useEffect(() => {
  //   if (!cookies.get("activeUserPincode")) {
  //     setVisible(true);
  //   } else {
  //     setVisible(false);
  //   }
  // }, []);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();
  const contextHook = props.isCustomer? useContext : () => [null, null];
  const ProviderProps = contextHook(AppContext);
  useEffect(() => {
    if (
      window.location.pathname === "/customer/home-page" ||
      window.location.pathname === "/"
    ) {
      setNotAtHome(false);
      console.log("AT HOME PAGE", notAtHome);
    } else {
      setNotAtHome(true);
      console.log("NOT AT HOME PAGE", notAtHome);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if(ProviderProps.showPinCodeModal) {
      setVisible(true);
    }
  }, [ProviderProps.showPinCodeModal]);

  const customerMenuOptions = [
    {
      label: "My Profile",
      url: ENV_TYPE === "production" ? `/profile` : "/customer/profile",
    },
    {
      label: "My Orders",
      url:
        ENV_TYPE === "production"
          ? `/profile/manage-orders`
          : "/customer/profile/manage-orders",
    },
    {
      label: "My Returns",
      url:
        ENV_TYPE === "production"
          ? `/profile/manage-returns`
          : "/customer/profile/manage-returns",
    },
    {
      label: "Manage Address",
      url:
        ENV_TYPE === "production"
          ? `/profile/manage-address`
          : "/customer/profile/manage-address",
    },
  ];

  const activeCustomerMenu = (
    <Menu className="header_dropdown_item">
      {customerMenuOptions.map((data, index) => {
        return (
          <Menu.Item key={index}>
            <a
              onClick={() => {
                props.history.push(data.url);
                window.scrollTo(0, 0); // scroll to top on clicking each item in dropdown menu
              }}
            >
              {data.label}
            </a>
          </Menu.Item>
        );
      })}

      <Menu.Item>
        <a
          onClick={() => {
            logoutUser();
            window.scrollTo(0, 0); // scroll to top on logout
          }}
        >
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  // const closePinCodePopover = () => {
  //   setVisible(false);
  // };

  const handleVisibileChange = (visible) => {
    setVisible(visible);
  };

  const showModal = () => {
    props.openLoginModal("customer");
  };

  const handlemobileMegamenu = (value) => {
    setmobileMenuVisible(!mobileMenuVisible);
  };
  useEffect(() => {}, [mobileMenuVisible]);

  // useEffect(() => {
  //   if (window.location.href.includes("activate")) {
  //     message.success("account activated");
  //     setverificationVisible(true);
  //   }
  // }, []);

  useEffect(() => {
    //for fetching megamenu reponse
    getMegamenu()
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setcategoriesData(response.data.documents);
            localStorage.setItem(
              "megamenu",
              JSON.stringify(response.data.documents)
            );
          }
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (window.location.href.indexOf("/customer") > -1) {
      setcustomerView(true);
    } else setcustomerView(false);

    if (
      !props.sellerView &&
      !props.isAdmin &&
      !props.isStore &&
      !props.isWarehouse &&
      !props.isWorkAdmin &&
      !props.isAffliate
    ) {
      props.fetchCartCount(0);
      props.fetchCartItems([]);
      setPinNo();
      if (activeCustomer) {
        const paylaod = {
          userId: cookies.get("userId"),
        };
        cookies.get("activeUserPincode") && setPinNo(props.pinCode);
        cartGet(paylaod)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                props.fetchCartCount(response.data.cart.cartItems.length);
              }
            }
          })
          .catch(() => {})
          .finally(() => {});
      }
    }
  }, []);

  // useEffect(() => {
  //   setactiveCustomer(JSON.parse(localStorage.getItem("activeCustomer")));
  //   props.fetchCartCount(0);
  //   props.fetchCartItems([]);
  // }, [localStorage.getItem("activeCustomer")]);

  useEffect(() => {
    if (cookies.get("userId")) {
      fetchUserCartCount()
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              // props.fetchCartCount(response.data.cartCount);
              setuserCartCount(response.data.cartCount);
              props.fetchCartItems(response?.data?.cartItems);
            }
          }
        })
        .catch(() => {
          setuserCartCount(0);
        });
    } else {
      //this part is case for guest cart
      if (props.cartCount) {
        setuserCartCount(props.cartCount);
      } else if (localStorage.getItem("guestCart")) {
        const guestCart = JSON.parse(localStorage.getItem("guestCart"));
        const cartCount = guestCart.reduce((prev, cur) => {
          return prev + cur.quantity;
        }, 0);
        setuserCartCount(cartCount);
      }
    }
  }, [props.cartCount, localStorage.getItem("guestCart")]);

  const logoutUser = () => {
    userLogout()
      .then((response) => {
        if (response.status === 200) {
          // if (response.data.status === "success") {
            // for customer
            props.fetchCartCount(0);
            props.fetchCartItems([]);
            localStorage.removeItem("activeCustomer");
            setactiveCustomer();
            setPinNo();
            // handleVisibileChange(true);
            if (ENV_TYPE === "production") {
              localStorage.removeItem("activeUser");
              localStorage.removeItem("remember");
              localStorage.removeItem("authToken", {
                path: "/",
                domain: `.${DOMAIN}`,
              });
              sessionStorage.removeItem("authToken", {
                path: "/",
                domain: `.${DOMAIN}`,
              });
              // cookies.remove("authToken", {
              //   path: "/",
              //   domain: `.${DOMAIN}`,
              // });
              cookies.remove("vendorId", { path: "/", domain: `.${DOMAIN}` });
              cookies.remove("userId", { path: "/", domain: `.${DOMAIN}` });
              cookies.remove("accountType", {
                path: "/",
                domain: `.${DOMAIN}`,
              });
              cookies.remove("activeUserPincode", {
                path: "/",
                domain: `.${DOMAIN}`,
              });
              cookies.remove("needToShow", {
                path: "/",
                domain: `.${DOMAIN}`,
              });
              cookies.remove("needToShowIncomingOrders", {
                path: "/",
                domain: `.${DOMAIN}`,
              });
            } else {
              localStorage.removeItem("activeUser");
              localStorage.removeItem("remember");
              localStorage.removeItem("authToken", { path: "/" });
              sessionStorage.removeItem("authToken", { path: "/" });
              // cookies.remove("authToken", { path: "/" });
              cookies.remove("vendorId", { path: "/" });
              cookies.remove("userId", { path: "/" });
              cookies.remove("accountType", { path: "/" });
              cookies.remove("activeUserPincode", { path: "/" });
              cookies.remove("needToShow", { path: "/" });
              cookies.remove("needToShowIncomingOrders", { path: "/" });
            }

            if (ENV_TYPE == "production") props.history.push("/");
            else {
              if (props.sellerView) props.history.push(`/login-seller`);
              else if (props.isStore) props.history.push(`/login-store`);
              else if (props.isAdmin) props.history.push(`/login-admin`);
              else if (props.isCustomer) props.history.push(`/customer`);
              else if (props.isWarehouse) props.history.push(`/login-warehouse`);
              else if (props.isLogger) props.history.push(`/logger-admin`);
              else if (props.isWorkAdmin) props.history.push('/login-work-report');
              else if (props.isAffliate) props.history.push('/login-affiliate');
            }
          // }
        }
      })
      .catch(() => {});
  };

  const handleVisibleChange = (visible) => {
    setpopOverVisible(visible);
  };

  const handleHomePgaeRedirection = () => {
    if (isCustomer) {
      props.clearProductListingFilter();
      ENV_TYPE === "production"
        ? props.history.push("/")
        : props.history.push("/customer/home-page");
    } else return;
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleCartRedirection = () => {
    switch (cookies.get("accountType")) {
      case "warehouse":
        props.history.push("/warehouse/cart");
        break;
      case "store":
        props.history.push("/store/cart");
        break;
      case "customer":
        props.history.push(
          ENV_TYPE === "production" ? `/cart` : `/customer/cart`
        );
        break;
      default:
        props.history.push(
          ENV_TYPE === "production" ? `/cart` : `/customer/cart`
        );
      // props.openLoginModal("customer");
    }
  };

  const renderRedirectToDotNet = (
    <Button className="login-button btn_theme ucase" type="primary">
      Become an affiliate
    </Button>
  );



  const renderCartIcon = (
    <div className="cart-icon">
      <Badge count={userCartCount} className="cart-badge">
        <div
          className="header-logo"
          alt="cart icon"
          onClick={() => handleCartRedirection()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            width="34.285px"
            height="32.762px"
            viewBox="0 0 34.285 32.762"
            enableBackground="new 0 0 34.285 32.762"
          >
            <g>
              <g>
                <g>
                  <path d="M12.875,32.762c-1.555,0-2.818-1.264-2.818-2.817c0-1.553,1.264-2.817,2.818-2.817     c1.554,0,2.817,1.264,2.817,2.817C15.692,31.498,14.429,32.762,12.875,32.762z" />
                </g>
                <g>
                  <path d="M28.523,32.762c-1.553,0-2.817-1.264-2.817-2.817c0-1.553,1.265-2.817,2.817-2.817     s2.816,1.264,2.816,2.817C31.34,31.498,30.076,32.762,28.523,32.762z" />
                </g>
                <g>
                  <path d="M13.761,24.327c-2.042,0-3.823-1.458-4.229-3.495L7.158,8.974C7.146,8.926,7.136,8.876,7.129,8.827     L5.96,2.987H1.495C0.668,2.987,0,2.318,0,1.493C0,0.669,0.668,0,1.495,0h5.689C7.896,0,8.51,0.503,8.648,1.201l1.184,5.911     h22.959c0.445,0,0.868,0.199,1.15,0.542c0.285,0.343,0.4,0.795,0.316,1.232l-2.278,11.935c-0.413,2.078-2.295,3.483-4.34,3.504     H13.842C13.815,24.327,13.789,24.327,13.761,24.327z M10.431,10.1l2.031,10.149c0.128,0.644,0.736,1.066,1.352,1.09h13.854     c0.01,0,0.02,0,0.027,0c0.675,0,1.228-0.454,1.354-1.087L30.987,10.1H10.431z" />
                </g>
              </g>
            </g>
          </svg>
        </div>
      </Badge>
    </div>
  );

  // window.addEventListener('scroll', ()=> {
  //   if(atHome) {
  //     if (window.pageYOffset > 200) {
  //       setShowMegaMenu(true)
  //     } else {
  //       setShowMegaMenu(false)
  //     }
  //   }else {
  //     setShowMegaMenu(true)
  //   }
  // })

  return (
    <>
      <div className="header-container">
        {isCustomer && (
          <MobileMegamenu
            visible={mobileMenuVisible}
            data={categoriesData}
            setmobileMenuVisible={setmobileMenuVisible}
          />
        )}

        {mobileMenuVisible && !isCustomer && (
          <Drawer
            title={
              <img
                className="header-company-logo"
                src={CompanyLogo}
                alt={"company logo"}
              />
            }
            placement={"left"}
            closable={false}
            onClose={() => setAdminMenu(false)}
            visible={adminMenuView}
            key={"left"}
            className="nav-side-bar-admin"
          >
            {props.isAdmin && (
              <AdminMenu
                pathname={props.pathname}
                isNav
                setNavVisible={setAdminMenu}
              />
            )}
            {props.isStore && (
              <StoreMenu
                pathname={props.pathname}
                isNav
                setNavVisible={setAdminMenu}
              />
            )}
            {props.sellerView && (
              <SideBarMenu
                isNav
                setNavVisible={setAdminMenu}
                sideBarData={sideBarData}
              />
            )}
          </Drawer>
        )}

        <div className="header-left">
          {navigator.userAgent.match(
            /Android|webOS|iPhone|iPod|Blackberry/i
          ) && (
            <div
              onClick={() => {
                handlemobileMegamenu(true);
                setAdminMenu(true);
              }}
              className={
                mobileMenuVisible
                  ? "menu_bar mobile-menu-toggle mob-menu-open" //case when the menu is triggered
                  : "menu_bar mobile-menu-toggle" // case when the menu is closed
              }
              //className="menu_bar mobile-menu-toggle"
              //src={MobileMenu}
            >
              <span class="menu">
                <span></span>{" "}
              </span>
            </div>
            // <img
            //   onClick={() => {
            //     handlemobileMegamenu(true);
            //     setAdminMenu(true);
            //   }}
            //   className="header-mobile-menu"
            //   src={MobileMenu}
            //   alt={"company-logo"}
            // />
          )}
          <div className="main_logo">
            <img
              onClick={() => (handleHomePgaeRedirection(), window.scroll(0, 0))} // scroll to top on clicking logo
              className="header-company-logo"
              src={CompanyLogo}
              alt={"company logo"}
              height={"60px"}
            />
          </div>
          {props.isCustomer && (
            <span className="search-bar-web header_search_box">
              <SearchBar searchProduct={true} speechText={transcript} />
              {/* {listening ? (
                <BsFillMicFill
                  className="search-bar-mic search-bar-mic-listening"
                  onClick={SpeechRecognition.stopListening}
                />
              ) : (
                <BsMic
                  className="search-bar-mic"
                  onClick={() => {
                    SpeechRecognition.startListening({
                      language: "en-IN",
                    });
                  }}
                />
              )} */}
            </span>
          )}
        </div>

        <div className="header-right">
          {props.isCustomer ? (
            <>
              {/* <Button
                className="redirect-btn btn_theme ucase affilitae_btn"
                type="primary"
                onClick={() => openInNewTab("https://www.a.net/")}
              >
                Become an affiliate
              </Button> */}
              <div onClick= {() => {
                    setPinNo("");
                    props.setPincode("");
                    ProviderProps.setPincodeModalVisible(false);
                      if (ENV_TYPE == "dev") {
                      cookies.remove("activeUserPincode", {
                        path: "/",
                      });
                    } else
                      cookies.remove("activeUserPincode", {
                        path: "/",
                        domain: `.${DOMAIN}`,
                      });

                    if(ENV_TYPE == "production")
                      props.history.push("/")
                    else
                      props.history.push("/customer/home-page")
                    }
                  }
                >
                <img
                    className="header-logo pincode-logo"
                    src={GlobalIcon}
                    alt="pincode-icon"
                    style={{"width": "20px"}}
                  />
                  <div className="pincode_num_data" style={{"color": "#cd165e"}}>
                    Global<p className="mob-hide">Products</p>
                  </div>
              </div>

              <Popover
                overlayClassName="popover-custom"
                placement="bottom"
                content={
                  <ManagePincode
                    pinNo={pinNo}
                    setVisible={setVisible}
                    setPinNo={setPinNo}
                  />
                }
                visible={visible}
                // onVisibleChange={handleVisibileChange}
                trigger="manual"
                onClick={() => {
                  handleVisibileChange(true);
                }}
              >
                <img
                  className="header-logo pincode-logo"
                  src={LocationIcon}
                  alt="pincode-icon"
                />
                {pinNo ? (
                  <p className="pincode_num_data">{pinNo}</p>
                ) : (
                  <div className="pincode_num_data">
                    <p className="mob-hide">Add</p> Pincode
                  </div>
                )}
              </Popover>

              {/* //for viewing user name on header for loged in customer// */}
              {renderCartIcon}
              {/* //name need not be shown in mobile view */}

              <Dropdown
                overlay={activeCustomerMenu}
                disabled={!activeCustomer}
                placement="bottomCenter"
              >
                <div className="user-icon">
                  <div
                    className="header-logo profile-icon"
                    // onClick={() => props.history.push(`/customer/profile`)}
                    onClick={() => {
                      handleVisibileChange(false);
                      !activeCustomer && showModal();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      id="Layer_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 29.787 33.077"
                      enableBackground="new 0 0 29.787 33.077"
                    >
                      {" "}
                      <g>
                        {" "}
                        <g>
                          {" "}
                          <path d="M28.059,33.077c-0.954,0-1.728-0.773-1.728-1.728v-3.291c0-2.678-2.179-4.856-4.855-4.856H8.309 c-2.675,0-4.853,2.178-4.853,4.856v3.291c0,0.955-0.773,1.728-1.728,1.728C0.774,33.077,0,32.304,0,31.349v-3.291 c0-4.583,3.727-8.312,8.309-8.312h13.167c4.583,0,8.311,3.729,8.311,8.312v3.291C29.787,32.304,29.013,33.077,28.059,33.077z" />{" "}
                        </g>{" "}
                        <g>
                          {" "}
                          <path d="M14.894,16.62c-4.584,0-8.311-3.728-8.311-8.31S10.31,0,14.894,0c4.582,0,8.311,3.728,8.311,8.31 S19.476,16.62,14.894,16.62z M14.894,3.456c-2.677,0-4.855,2.178-4.855,4.854c0,2.677,2.179,4.854,4.855,4.854 s4.854-2.178,4.854-4.854C19.748,5.634,17.57,3.456,14.894,3.456z" />{" "}
                        </g>{" "}
                      </g>{" "}
                    </svg>
                  </div>
                  {/* {activeCustomer?.name && <Text>{activeCustomer.name}</Text>} */}
                </div>
              </Dropdown>

              {/* //removed login button for customer */}
              {/* {!props.isAdmin && !activeCustomer && (
                <Button
                  className="login-button btn_theme ucase"
                  type="primary"
                  onClick={() => showModal()}
                >
                  Login
                </Button>
              )} */}
              {!props.isAdmin && !activeCustomer && <Login {...props} />}

              {/* //removed logout button for customer // */}
              {/* {activeCustomer && (
                <Button
                  className="login-button btn_theme ucase"
                  type="primary"
                  onClick={() => logoutUser()}
                >
                  Logout
                </Button>
              )} */}
              <Signup {...props} />
            </>
          ) : (
            <>
              {cookies.get("switchuser") === "true" &&
                !window.location.href.includes("/admin/") && (
                  <Button
                    className="btn_theme ucase switch-account-button"
                    type="primary"
                    onClick={() => {
                      if (ENV_TYPE == "dev") {
                        cookies.set("switchuser", "false", {
                          path: "/",
                        });
                      } else
                        cookies.set("switchuser", "false", {
                          path: "/",
                          domain: `.${DOMAIN}`,
                        });
                      if(localStorage.getItem("activeCustomer")) {
                        if(JSON.parse(localStorage.getItem("activeCustomer")).accountType === "workAdmin") {
                          props.history.push(`/work-report`);
                          return
                        }
                      }
                      props.history.push(`/admin`);
                    }}
                  >
                    ADMIN <FaExchangeAlt /> {cookies.get("accountType")}
                  </Button>
                )}
              {["warehouse", "store"].includes(cookies.get("accountType")) &&
                renderCartIcon}

              {activeCustomer && (
                <>
                  {/* //name need not be shown in mobile view */}
                  {!navigator.userAgent.match(
                    /Android|webOS|iPhone|iPod|Blackberry/i
                  ) && (
                    <div className="user-icon" style={{ marginRight: "15px" }}>
                      <img
                        className="header-logo profile-icon"
                        src={ProfileIcon}
                        alt="profile icon"
                      />
                      <Text
                        style={{
                          color: "#0074be",
                          maxHeight: "40px",
                        }}
                      >
                        {activeCustomer.accountType == "affiliate" ? activeCustomer.userCode : activeCustomer.name}
                      </Text>
                    </div>
                  )}
                </>
              )}
              <Button
                className="btn_theme ucase switch-account-button"
                type="primary"
                onClick={() => logoutUser()}
              >
                Logout
              </Button>
            </>
          )}
        </div>
      </div>

      {(customerView || isCustomer) && notAtHome && (
        <div className="megamenu-web header_category_menu">
          <Megamenu catogeries={categoriesData} />
        </div>
      )}


      {!props.isAdmin && !props.sellerView && !props.isStore && (
        <div className="search-bar-mobile">
          <SearchBar
            searchProduct={true}
            speechText={transcript}
            style={{ width: "70%" }}
          />
          {/* {listening ? (
            <BsFillMicFill
              className="search-bar-mic-small search-bar-mic-listening"
              onClick={SpeechRecognition.stopListening}
            />
          ) : (
            <BsMic
              className="search-bar-mic-small"
              onClick={() => {
                SpeechRecognition.startListening({
                  language: "en-IN",
                });
              }}
            />
          )} */}
        </div>
      )}

      {/* <EmailVerificationModal
        visible={verificationVisible}
        setvisible={setverificationVisible}
      /> */}
    </>
  );
};

const mapDispatchToProps = {
  openLoginModal,
  fetchCartCount,
  fetchCartItems,
  clearProductListingFilter,
  setPincode
};
const mapStateToProps = (state) => {
  return {
    cartCount: state.cartCountReducer,
    pinCode: state.productReducer.pinCode,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderView)
);
