


import React from "react";
import { Typography } from "antd";

import EmptyImage from "../../Assets/Images/Vectors/empty.svg";

const { Text } = Typography;
const EmptyList = (props) => {
  const { msg, placeHolderProducts, productList } = props;
  return (
    <div>
      <div
        style={placeHolderProducts? {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "40vh"
        } : {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <img src={EmptyImage} style={{ width: "300px", marginBottom: "20px" }} alt="company"/>

        <Text strong> {msg} </Text>
      </div>
        {productList?.length > 0 && (
          <p style={{ fontSize: "20px", padding: "20px", fontWeight: "500", color: "black" }} strong> You may be interested in … </p>
        )}
    </div>
  );
};
export default EmptyList;
