


import React, { useState, useEffect } from "react";
import { Tabs, Button, Input, Spin, Modal, notification, Form } from "antd";
import "./quickCheckout.scss";
import TabContent from "./tabContent";
import ShippingInvoice from "./shippingInvoice";
import {
  addCart,
  checkAvailability,
  getCart,
  removeCart,
  fetchQuickCheckoutOrders,
} from "./quickCheckoutAPI";
import Cookies from "universal-cookie";
import useDebounce from "../../../Hooks/useDebounce";
import AddCustomerModal from "./addCustomerModal";
import Loader from "../../Admin/Reports/ReportsTable/Loader";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import EmptyList from "../../GeneralComponents/emptyList";
import { formatQUickCheckoutTabData } from "./quickCheckoutHelper";
const confirm = Modal.confirm;

// max no of tabs set
const { Search } = Input;
const { TabPane } = Tabs;

const QuickCheckout = () => {
  const [form] = Form.useForm();
  const cookies = new Cookies();
  const [searchVal, setSearchval] = useState("");
  const [newTabIndex, setnewTabIndex] = useState(0);
  const [panes, setpanes] = useState("");
  const [activeKey, setactiveKey] = useState(0);
  // const [shippingRequired, setshippingRequired] = useState(false);
  const [data, setdata] = useState([]);
  const debouncedSearchTerm = useDebounce(searchVal, 500);
  const [pinCode, setpincode] = useState(0);
  // const [deliveryCharge, setdeliveryCharge] = useState(0);
  // const [address, setaddress] = useState("");
  // const [customerId, setcustomerId] = useState("");
  const [modalVisible, setmodalVisible] = useState(false);

  const [addCustomerModalVisible, setaddCustomerModalVisible] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  // useEffect(() => {
  //   if (debouncedSearchTerm) {
  //     handleavailabilitySearch(debouncedSearchTerm);
  //   } else {
  //     handleavailabilitySearch("");
  //   }
  // }, [debouncedSearchTerm]);

  useEffect(() => {
    handlegetCart();
    handleFetchAllOrders();
  }, []);

  useEffect(() => {
    if (panes?.length && !activeKey) {
      setactiveKey(panes[0].key);
    }
  }, [panes]);

  const handleFetchAllOrders = () => {
    setisLoading(true);
    fetchQuickCheckoutOrders()
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log(
              response.data.quickCheckoutCarts,
              ": reponse for quickcheckout"
            );

            console.log(
              formatQUickCheckoutTabData(response.data.quickCheckoutCarts),
              ": formated data"
            );
            setpanes(
              formatQUickCheckoutTabData(response.data.quickCheckoutCarts)
            );
          }
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const handlegetCart = (key) => {
    const payload = {
      checkoutCartId: key ? key : data?.checkoutCartId,
    };

    getCart(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setdata(response.data.quickCheckoutCart);

          setpanes(
            panes.map((data) => {
              if (data.key === response.data.quickCheckoutCart.checkoutCartId)
                return {
                  title: `Order: ${response.data.quickCheckoutCart.checkoutCartId}`,
                  content: "New Tab Pane",
                  key: response.data.quickCheckoutCart.checkoutCartId,
                  data: response.data.quickCheckoutCart,
                };
              else return data;
            })
          );
        }
      }
    });
  };

  const handleSetData = (cart) => {
    setpanes(
      panes.map((data) => {
        if (data.key === cart.checkoutCartId)
          return {
            title: `Order: ${cart.checkoutCartId}`,
            content: "New Tab Pane",
            key: cart.checkoutCartId,
            data: cart,
          };
        else return data;
      })
    );
  };

  const handleavailabilitySearch = (key) => {
    var re = new RegExp("^[1-9][0-9]{5}$");
    if (re.test(key)) {
      const payload = {
        pinCode: key,
        vendorId: cookies.get("vendorId"),
      };

      setpincode(key);
      checkAvailability(payload).then((response) => {
        if (response?.status === 200) {
          if (response.data.status === "success") {
            notification["success"]({
              message: "Success",
              description: response.data.message,
            });
          }
          if (response.data.status === "failed") {
            notification["error"]({
              message: "Failed",
              description: response.data.message,
            });
          }
        }
      });
    } else {
      notification["error"]({
        message: "Failed",
        description: "Enter valid Pincode",
      });
    }
  };

  const handleproductSearch = (key) => {
    setSearchval(key);
  };

  const handleAddNewTab = () => {
    // var newActiveKey = newTabIndex;
    // newActiveKey = `${++newActiveKey}`;
    // setnewTabIndex(newActiveKey);
    setisLoading(true);
    var newPanes = [];
    if (panes) {
      var newPanes = panes;
    }

    const payload = {
      vendorId: cookies.get("vendorId"),
    };

    addCart(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            newPanes.push({
              title: `Order: ${response.data.quickCheckoutCart.checkoutCartId}`,
              content: "New Tab Pane",
              key: response.data.quickCheckoutCart.checkoutCartId,
              data: response.data.quickCheckoutCart,
            });

            setdata(response.data.quickCheckoutCart);
            setpanes(newPanes);
            setactiveKey(response.data.quickCheckoutCart.checkoutCartId);
          }
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const handleOnChange = (activeKey) => {
    setactiveKey(activeKey);
    // handlegetCart(activeKey);
  };

  const handleOnEdit = (targetKey, action) => {
    if (action == "remove") {
      renderConfirmModal(targetKey);
    }
  };

  const handleRemove = (targetKey) => {
    let newActiveKey = activeKey;
    let lastIndex;
    var newPanes = panes ? panes : [];
    newPanes.forEach((pane, i) => {
      if (pane.key == targetKey) {
        lastIndex = i - 1;
      }
    });
    newPanes = newPanes.filter((pane) => pane.key != targetKey);
    if (newPanes.length && activeKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = panes[lastIndex].key;
      } else {
        newActiveKey = panes[1].key;
      }
    }
    setisLoading(true);
    removeCart(targetKey)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setpanes(newPanes);
            setactiveKey(newActiveKey);
            // handlegetCart(newActiveKey);
          }
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  //add new customer
  const handleAddNewCustomer = () => {
    setaddCustomerModalVisible(true);
  };

  const renderConfirmModal = (targetKey) => {
    Modal.confirm({
      title: "Confirm",
      id: "genrate-invoice-modal",
      icon: <ExclamationCircleOutlined />,
      content: " Are you sure you want to delete this cart?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleRemove(targetKey),
    });
  };

  return (
    <div className="quick-checkout">
      <div>
        <div className="manage-products-header">
          <Form form={form}>
            <Form.Item
              name="pinCode"
              rules={[
                {
                  pattern: new RegExp("^[1-9][0-9]{5}$"),
                  message: "Enter a valid pincode",
                },
              ]}
            >
              <Search
                enterButton="Check Availability"
                className="search"
                placeholder="PINCODE"
                size="default"
                onSearch={handleavailabilitySearch}
                onChange={handleproductSearch}
                maxLength="6"
              />
            </Form.Item>
          </Form>

          <div className="manage-products-action">
            <Button
              type="primary"
              className="add_customer_btn"
              onClick={handleAddNewCustomer}
            >
              Add New Customer
            </Button>
            <Button type="primary" onClick={handleAddNewTab}>
              New Invoice
            </Button>
          </div>
        </div>
        <Tabs
          hideAdd
          onChange={handleOnChange}
          activeKey={activeKey}
          type="editable-card"
          onEdit={handleOnEdit}
          // defaultActiveKey="1"
          className="tabs"
        >
          {panes &&
            panes.map((pane) => (
              <TabPane tab={pane.title} key={pane.key}>
                <Spin spinning={isLoading}>
                  <TabContent
                    // setshippingRequired={setshippingRequired}
                    handleRemove={handleRemove}
                    pane={pane.key}
                    data={pane.data}
                    setdata={handleSetData}
                    pinCode={pinCode}
                  />
                </Spin>
              </TabPane>
            ))}
        </Tabs>
        {!panes?.length && (
          <div className="no-invoice">
            {!isLoading ? (
              <EmptyList msg={"No Invoices Generated "} />
            ) : (
              <Spin spinning={isLoading}>
                <EmptyList msg={"Generating invoice"} />
              </Spin>
            )}
          </div>
        )}
      </div>

      <AddCustomerModal
        isModalVisible={addCustomerModalVisible}
        setisModalVisible={setaddCustomerModalVisible}
      />
    </div>
  );
};

export default QuickCheckout;

// const panes1 = [
//   { title: "Customer : xxx", content: "Content of Tab Pane 1", key: "1" },
//   { title: "Customer : xxx", content: "Content of Tab Pane 2", key: "2" },
// ];

// useEffect(() => {
//   setpanes(panes1);
//   setactiveKey(panes[0]?.key);
//   setnewTabIndex(panes1?.length);
// }, [activeKey]);
