


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { message, Tabs } from "antd";

import ReportsTable from "../ReportsTable";
import {
  fetchSearchReport,
  fetchSearchReportByProduct,
  exportSearchReport,
  exportSearchReportByProduct,
} from "../../../../ApiAction/AdminApiAction/reportsApi";
import {
  searchColumns,
  FormatSearchReport,
  searchByViewColumns,
  FormatSearchReportByView,
} from "../Utils/reportHelper";

const { TabPane } = Tabs;
const SearchReport = (props) => {
  const { reportSummary, searchVal } = props;

  const [keyDataSource, setkeyDataSource] = useState([]);
  const [viewDataSource, setviewDataSource] = useState([]);
  const [type, settype] = useState("keyword");
  const [isLoadingSearchReport, setIsLoadingSearchReport] = useState(false);

  const tabChangeHandler = (key) => {
    console.log(key);
    settype(key);
  };

  const handleExportSearchReport = async () => {
    const payload = {
      size: 10,
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: searchVal,
    };

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endtime"] = props.dateRange.endtime;
    }

    const searchReport = await exportSearchReport(payload)
      .then((response) => {
        console.log(response, "response for voucher code excel sheet");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `search_report.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        message.error("Could not export file please try again");
      });
    return true;
  };

  const handleExportSearchReportByView = async () => {
    const payload = {
      size: 10,
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: searchVal,
    };

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endtime"] = props.dateRange.endtime;
    }

    const searchReport = await exportSearchReportByProduct(payload)
      .then((response) => {
        console.log(response, "response for voucher code excel sheet");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `search_report.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        message.error("Could not export file please try again");
      });
    return true;
  };

  const handleFetchSearchReportByView = () => {
    const payload = {
      size: 10,
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: searchVal,
    };

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endtime"] = props.dateRange.endtime;
    }
    setIsLoadingSearchReport(true);
    fetchSearchReportByProduct(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log(
              "reponse for search by view report: ",
              response.data.documents
            );
            setviewDataSource(
              FormatSearchReportByView(response.data.documents)
            );
          }
        }
      })
      .finally(() => {
        setIsLoadingSearchReport(false);
      });
  };

  const handleFetchSearchReportByKey = () => {
    const payload = {
      size: 10,
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: searchVal,
    };

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endtime"] = props.dateRange.endtime;
    }
    setIsLoadingSearchReport(true);
    fetchSearchReport(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log(
              "reponse for search by key report: ",
              response.data.documents
            );
            setkeyDataSource(FormatSearchReport(response.data.documents));
          }
        }
      })
      .finally(() => {
        setIsLoadingSearchReport(false);
      });
  };

  const tabOptions = [
    {
      key: "keyword",
      label: "Search Report by keyword",
      dataSource: keyDataSource,
      download: handleExportSearchReport,
      columns: searchColumns,
    },
    {
      key: "view",
      label: "Search report by view",
      dataSource: viewDataSource,
      download: handleExportSearchReportByView,
      columns: searchByViewColumns,
    },
  ];

  useEffect(() => {
    handleFetchSearchReportByKey();
    handleFetchSearchReportByView();
  }, [searchVal, props.sort, props.dateRange]);

  return (
    <Tabs
      defaultActiveKey="ACTIVE"
      onChange={tabChangeHandler}
      className="fixed_tab_ds"
    >
      {tabOptions.map((tab) => {
        return (
          <TabPane tab={tab.label} key={tab.key} forceRender={true}>
            <ReportsTable
              download={tab.download}
              columns={tab.columns}
              dataSource={tab.dataSource}
              type={"Search"}
              summary={
                "The most searched 10  items are listed below. To get the complete search report, download csv"
              }
              reportSummary={reportSummary}
              isLoadingReport={isLoadingSearchReport}
            />
          </TabPane>
        );
      })}
    </Tabs>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
    brandId: state.reportReducer.brandId,
    dateRange: state.reportReducer.dateRange,
    stockPercentage: state.reportReducer.stockPercentage,
    vendorFilter: state.reportReducer.vendorId,
  };
};

export default connect(mapStateToProps)(SearchReport);
