

import React from "react";

import { Route, Redirect, Switch } from "react-router-dom";
import { Layout } from "antd";

import Homepage from "../../Views/HomePage";
import ProductListing from "./Components/ProductListing";
import ProductDetailPage from "./Components/ProductDetailPage";
import CustomerProfile from "./Components/CustomerProfile";
import Cart from "./Components/Cart/cart";
import Cartpayment from "./Components/Cart/cartpayment";
import OrderResponse from "./Components/Cart/OrderResponseComponents";
import ContentSection from "../Admin/ManageContent/contentSection";
import FmcgListing from "./Components/FMCG/FmcgListing";

import { ENV_TYPE } from "../../Utils/constants";
import PageNotFound from "../GeneralComponents/pageNotFound";
import VerifyPayment from "./Components/Cart/verifyPayment";

const { Content } = Layout;
export default function Customer() {
  return (
    <Layout>
      <Content>
        {ENV_TYPE === "production" ? ( //Routes for production enviornment
          <>
            <Switch>
              {/* <Route
                exact
                path="/customer"
                render={(props) => {
                  return <Redirect to="/customer/home-page" />;
                }}
              /> */}
              <Route
                exact
                path="/"
                render={(props) => {
                  return <Homepage {...props} />;
                }}
              />
              <Route
                exact
                path="/product-listing/:key"
                render={(props) => {
                  return <ProductListing {...props} />;
                }}
              />
              <Route
                exact
                path="/product-listing"
                render={(props) => {
                  return <ProductListing {...props} />;
                }}
              />
              <Route
                exact
                path="/product-listing/:sectionName/:sectionId"
                render={(props) => {
                  return <ProductListing {...props} />;
                }}
              />
              <Route
                exact
                path="/product-detail/:productId"
                render={(props) => {
                  return <ProductDetailPage {...props} />;
                }}
              />
              <Route
                path="/profile"
                render={(props) => {
                  return <CustomerProfile {...props} />;
                }}
              />
              <Route
                exact
                path="/cart"
                render={(props) => {
                  return <Cart {...props} />;
                }}
              />
              <Route
                exact
                path="/cart/remove-items"
                render={(props) => {
                  return <Cart {...props} />;
                }}
              />

              <Route
                exact
                path="/cart-payment"
                render={(props) => {
                  return <Cartpayment {...props} />;
                }}
              />

              <Route
                exact
                path="/verify-payment/:orderGroupId"
                render={(props) => {
                  return <VerifyPayment {...props} />;
                }}
              />

              <Route
                exact
                path="/order-response/:status"
                render={(props) => {
                  return <OrderResponse {...props} />;
                }}
              />
              <Route
                exact
                path="/customer/content/:type"
                render={(props) => {
                  return <ContentSection {...props} />;
                }}
              />
              <Route
                path="/fastmoving-listing/:encryptedCategoryId"
                render={(props) => {
                  return <FmcgListing {...props} />;
                }}
              />
              <Route
                exact
                path="/404"
                render={(props) => {
                  return <PageNotFound />;
                }}
              />
              <Route
                render={(props) => {
                  return <Redirect to="/404" />;
                }}
              />
            </Switch>
          </>
        ) : (
          //Routes for enviornments other than production
          <Switch>
            <Route
              exact
              path="/customer"
              render={(props) => {
                return <Redirect to="/customer/home-page" />;
              }}
            />
            <Route
              exact
              path="/customer/home-page"
              render={(props) => {
                return <Homepage {...props} />;
              }}
            />
            <Route
              exact
              path="/customer/product-listing/:key"
              render={(props) => {
                return <ProductListing {...props} />;
              }}
            />
            <Route
              exact
              path="/customer/product-listing"
              render={(props) => {
                return <ProductListing {...props} />;
              }}
            />
            <Route
              exact
              path="/customer/product-listing/:sectionName/:sectionId"
              render={(props) => {
                return <ProductListing {...props} />;
              }}
            />
            <Route
              exact
              path="/customer/product-detail/:productId"
              render={(props) => {
                return <ProductDetailPage {...props} />;
              }}
            />
            <Route
              path="/customer/profile"
              render={(props) => {
                return <CustomerProfile {...props} />;
              }}
            />
            <Route
              exact
              path="/customer/cart"
              render={(props) => {
                return <Cart {...props} />;
              }}
            />
            <Route
              exact
              path="/customer/cart/remove-items"
              render={(props) => {
                return <Cart {...props} />;
              }}
            />

            <Route
              exact
              path="/customer/cart-payment"
              render={(props) => {
                return <Cartpayment {...props} />;
              }}
            />

            <Route
              exact
              path="/customer/verify-payment/:orderGroupId"
              render={(props) => {
                return <VerifyPayment {...props} />;
              }}
            />

            <Route
              exact
              path="/customer/order-response/:status"
              render={(props) => {
                return <OrderResponse {...props} />;
              }}
            />
            <Route
              exact
              path="/customer/content/:type"
              render={(props) => {
                return <ContentSection {...props} />;
              }}
            />
            <Route
              path="/customer/fastmoving-listing/:encryptedCategoryId"
              render={(props) => {
                return <FmcgListing {...props} />;
              }}
            />
            <Route
              render={(props) => {
                return <Redirect to="/404" />;
              }}
            />
          </Switch>
        )}
      </Content>
    </Layout>
  );
}
