


import React, { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Upload, message, Button, Modal, Typography, Row, Col } from "antd";

import Image from "../../../../Assets/Images/Icons/bulk.svg";
import UploadIcon from "../../../../Assets/Images/Icons/csv.svg";
import TemplateFile from "../../../../Assets/Files/product_template.xls";
import "./bulkupload.css";
import { bulkUpload } from "../../../../ApiAction/SellerApiActions/sellerApi";
import { bulkUploadProductToCentralRepo } from "../../../../ApiAction/AdminApiAction/centralRepoApi";

const { Title, Text } = Typography;
const { Dragger } = Upload;

const BulkUploadModal = (props) => {
  const { isCentralRepo } = props;
  const [file, setFile] = useState();
  const [errors, setErrors] = useState([]);
  const fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "text/csv",
  ];
  const options = {
    name: "file",
    multiple: false,
    accept: ".csv, .xls, .xlsx",

    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onRemove: () => {
      setFile(null);
    },
    beforeUpload(info) {
      // if(file)
      // {
      //     message.warn("you can upload only one file at a time")
      //     return false
      // }
    },
    onChange(info) {
      const { status } = info.file;
      setFile(info.file.originFileObj);
    },
  };

  const handleBulkUploadToCentralRepo = (file) => {
    bulkUploadProductToCentralRepo(file)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(`${file.name} uploaded successfully`);
            props.setVisiblity(false);
          } else if (response.data.status === "failed") {
            setErrors(response?.data?.message || []);
            message.error(`Fix the errors and try again`);
          }
        }
      })
      .catch((error) => {
        error?.response && message.error(error?.response?.message);
      })
      .finally(() => {});
  };

  const handleFileUpload = () => {
    var type = file.type;
    if (fileType.includes(type)) {
      if (isCentralRepo) {
        handleBulkUploadToCentralRepo(file);
      } else
        bulkUpload(file)
          .then((response) => {
            if (response.data.status === "success") {
              message.success(`${file.name} file uploaded successfully.`);
              props.setVisiblity(false);
            } else if (response.data.status === "failed") {
              setErrors(response?.data?.message || []);
              message.error(`Fix the errors and try again`);
            }
          })
          .catch((error) => {
            if (error?.response) {
              message.error(error.response.message);
            }
          })
          .finally(() => {});
    } else {
      message.error(`${file.name} invalid extenstion.`);
    }
  };

  return (
    <div className="bulk-upload-modal-main">
      <Modal
        centered
        visible={props.visible}
        onOk={() => props.setVisiblity(false)}
        onCancel={() => props.setVisiblity(false)}
        footer={false}
        className="bulk-upload-modal"
      >
        <div className="bulk-upload-modal-container">
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="bulk-upload-row"
            >
              <div className="bulk-uploader-header" style={{ height: "100%" }}>
                <Title strong level={4} style={{ fontSize: "18px" }}>
                  Bulk Upload Products
                </Title>
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={Image}
                    style={{ width: "100%", padding: "35px" }}
                    alt="bulkuploadimage"
                    className="image-bulk-logo"
                  />
                </div>
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="bulk-upload-row"
            >
              <div className="bulk-upload-content-description">
                <div className="bulk-upload-content-header">
                  <Title strong level={4} style={{ fontSize: "18px" }}>
                    Upload Guidelines
                  </Title>
                </div>
                <div className="upload-guideline-content">
                  <Text level={4} style={{ fontSize: "14px" }}>
                    1. Login as a seller
                    <br /> 2. Click on Manage Products tab
                    <br /> 3. Front the Add product dropdown choose ‘Bulk
                    Upload’ option
                    <br /> 4. Download the xls template and fill the proper
                    values under each column headings and upload your filled
                    template.
                    <br /> 5. Or browse / drag and drop the file that matches
                    our bulk upload template and upload your file.
                    <br /> 6. If you get an error message prompting the errors
                    in the uploaded file. Correct the file and reupload.
                  </Text>
                </div>
                <div className="bulk-upload-action">
                  <Button
                    href={TemplateFile}
                    fileList={[]}
                    icon={<DownloadOutlined />}
                    type="primary"
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Download Template
                  </Button>
                  <Dragger
                    {...options}
                    className="dragger"
                    showUploadList={false}
                  >
                    <img
                      src={UploadIcon}
                      alt="uploadIcon"
                      className="image-upload"
                    />
                    Browse for file / Drag and Drop file here
                  </Dragger>
                  {file && (
                    <div style={{ color: "black", marginBottom: "10px" }}>
                      {file.name}{" "}
                    </div>
                  )}
                  {file?.type && (
                    <Button
                      size={"large"}
                      type="primary"
                      fileList
                      style={{ width: "100%" }}
                      onClick={() => handleFileUpload()}
                    >
                      Upload Document
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {!!errors?.length && (
            <div>
              <Title strong level={4} style={{ fontSize: "18px" }}>
                Errors
              </Title>
              {Array.isArray(errors)
                ? errors.map((error) => <p style={{ color: "red" }}>{error}</p>)
                : errors}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default BulkUploadModal;
