


import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import ManageStoreTable from "../Admin/ManageStore/manageStoreTable";
import {
  getLinkedStores,
  getLinkedWarehouses,
  getWarehouseLinkedWarehouses,
} from "../../ApiAction/AdminApiAction/linkWarehouseAoi";

const { Title } = Typography;
const WarehouseLinkedWarehouse = (props) => {
  const cookies = new Cookies();
  const [customerData, setCustmerData] = useState([]);

  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchStores(1, props.searchVal);
  }, [props]);

  const fetchStores = (page, search = "") => {
    setCurrentPage(page);
    getWarehouseLinkedWarehouses(cookies.get("vendorId"))
      .then((response) => {
        setCustmerData(response.data.linkedWarehouses);
        setPage(Math.ceil(response.data.count / 10) * 10);
      })
      .catch(() => {});
  };

  return (
    <div className="admin-manage-customer">
      <h1 className="content-name-header sub-title-tags" level={4}>
        {" "}
        {props.isWarehouse ? "Warehouse" : "Store"}
      </h1>

      <ManageStoreTable
        userData={customerData}
        total={page}
        currentPage={currentPage}
        warehouseLinkedWarehouse
        isWarehouse={props.isWarehouse}
        onPageChange={fetchStores}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps)(WarehouseLinkedWarehouse);
