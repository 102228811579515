

import React, { useState, useEffect, Fragment } from "react";

import { Typography, Pagination, Card } from "antd";

import SortIcon from "../../../Assets/Images/Icons/Seller/Sort2.svg";
import NoImage from "../../../Assets/Images/PlaceHolderImages/placeholder-product-card.jpg";

import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";
import EmptyList from "../../GeneralComponents/emptyList";
import Search from "antd/lib/input/Search";
import { fetchVerfiedProducts } from "../../../ApiAction/AdminApiAction/adminApi";
import { FILE_PATH } from "../../../Utils/constants";
import { GoVerified } from "react-icons/go";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const { Text } = Typography;

const VerifiedProducts = (props) => {
  const [isMobileView, setisMobileView] = useState(false);
  const [productList, setProductList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingProd, setisLoadingProd] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    getProductsList(searchVal,1);
  }, []);


  const getProductsList = (searchKey=searchVal, page) => {
    setCurrentPage(page);
    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      key: searchKey,
      sortBy: "verifiedDate",
      sortType: props.sort ? props.sort.sortBy : "asc"
    };
    setisLoadingProd(true);
    fetchVerfiedProducts(payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(response.data.documents);
          setTotal(Math.ceil(response.data.count / 10) * 10);
        }
        else{
            setProductList([]); 
        }
      })
      .catch(() => {})
      .finally(() => {
        setisLoadingProd(false);
      });
  };

  useEffect(() => {
    getProductsList(undefined, 1);
  }, [props]);


  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        {" "}
        {!isMobileView ? (
          <h1 level={4} className="sub-title-tags">
            {" "}
            Verified Products
          </h1>
        ) : (
          <Text>
            {" "}
            <span>
              {" "}
              <img
                src={SortIcon}
                alt="delete-icon"
                style={{ width: "25px", paddingRight: "5px" }}
              />
            </span>
            Sort
          </Text>
        )}
      </div>
      <Search
      placeholder="Search products here."
      size="large"
      // loading={barcodeSearching}
      // value={searchedProduct}
      onChange={(e)=>{
        setSearchVal(e.target.value);
        getProductsList(e.target.value,1);
      }}
      // onSearch={(value) => setSearchVal(value,1)}
    />
      {isLoadingProd ? (
        <LoadingCartItem />
      ) : (
        productList?.length === 0 && <EmptyList msg={"No Products found"} />
      )}
      {productList.map((product) => (
        <Card
            hoverable
            bordered={false}
            height={250}
            style={{ width: "100%", marginBottom: "20px", marginTop: "10px" }}
            className="manage-product-card-container"
            onClick={() => {
              props.history.push("/admin/central-repo/edit-product/" + product.source.productId);
            }}
        >
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {
                    <img
                    className="manage-products-draft-product-card-image"
                    src={
                        product.source.images
                        ? product.source.images.length > 0
                            ? FILE_PATH + product.source.images[0]
                            : NoImage
                        : NoImage
                    }
                    onError={(e) => {
                        e.target.onerror=null;
                        e.target.src=NoImage
                    }}
                    />
                }
                </div>
                <div className="seller-manage-product-card-summary">
                <div
                    style={{
                    maxWidth: "500px",
                    }}
                >
                    {isMobileView ? (
                    <Text style={{ fontSize: "12px" }} strong>
                        {product.source.name}
                    </Text>
                    ) : (
                    <Text style={{ fontSize: "20px" }} strong>
                        {product.source.name}
                    </Text>
                    )}

                    <div className="addedType-container">
                    {product.source?.addedType === "admin" ? (
                        <p className="addedType-tags addedType-admin">ADMIN</p>
                    ) : (
                        <Fragment>
                        {product.source?.addedType === "store" ? (
                            <p className="addedType-tags addedType-store">STORE</p>
                        ) : (
                            <Fragment>
                            {product.source?.addedType === "seller" ? (
                                <p className="addedType-tags addedType-seller">
                                SELLER
                                </p>
                            ) : (
                                <Fragment>
                                {product.source?.addedType ===
                                    "warehouse" && (
                                    <p className="addedType-tags addedType-warehouse">
                                    HUB
                                    </p>
                                )}
                                </Fragment>
                            )}
                            </Fragment>
                        )}
                        </Fragment>
                    )}
                    {product.source?.verified && (
                        <p className="verified-tag">
                        <div style={{paddingRight: "0.2em", paddingTop: "0.1em"}}><GoVerified></GoVerified></div>
                        Verified
                        </p>
                    )}
                    </div>
                    

                    <div style={{ fontSize: "18px"}}>
                        <div strong>
                        Brand Name: {product.source.brandName}
                        </div>
                        <div strong>
                        HSN Code: {product.source.hsnCode}
                        </div>
                        <div strong>
                        Tax Percent: {product.source.taxPerCent}
                        </div>
                    </div>


                    <br style={{ marginBottom: "30px" }} />
                    {isMobileView ? (
                    product.source?.categoryName && (
                        <Text style={{ color: "green", fontSize: "10px" }}>
                         {product.source?.categoryName +
                            (product.source?.subCategory0Name && " / " + product.source?.subCategory0Name) +
                            (product.source?.subCategory1Name && " / " + product.source?.subCategory1Name) +
                            (product.source?.subCategory2Name && " / " + product.source?.subCategory2Name) + 
                            (product.source?.subCategory3Name && " / " + product.source?.subCategory3Name)}
                        </Text>
                    )
                    ) : (
                    <Text style={{ color: "green", fontSize: "18px" }}>
                        {product.source?.categoryName +
                            (product.source?.subCategory0Name && " / " + product.source?.subCategory0Name) +
                            (product.source?.subCategory1Name && " / " + product.source?.subCategory1Name) +
                            (product.source?.subCategory2Name && " / " + product.source?.subCategory2Name) + 
                            (product.source?.subCategory3Name && " / " + product.source?.subCategory3Name)}
                    </Text>
                    )}
                </div>
              </div>
            </div>
        </Card>
      ))}
      {total > 10 && (
        <div className="admin-manage-customer">
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            total={total}
            showSizeChanger={false}
            className="customer-pagination"
            onChange={(page) => {
              getProductsList(searchVal ? searchVal : "", page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default withRouter(connect(mapStateToProps)(VerifiedProducts));


