

import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Checkbox,
  Typography,
  Input,
  Select,
  Button,
  DatePicker,
  message,
  InputNumber,
  Divider,
} from "antd";
import moment from "moment";
import StockVarient from "./stockVarient";
import { getBatchTypeAndVariants } from "../../../ApiAction/AdminApiAction/adminApi";
import Loading from "../../GeneralComponents/Loading";
import "./manageStocks.scss";
import { batch } from "react-redux";
import Cookies from "universal-cookie";
import {
  findBrowser,
  findDeviceModel,
} from "../../Admin/Logger/notificationBar/LogHelper";

const { Title, Text } = Typography;
const { Option } = Select;

const AddBatch = (props) => {
  const {
    categoryCommission,
    minCategoryDiscount,
    isLoading,
    distinctVariant,
    centralRepoId,
  } = props;
  const [batchNumber, setBatchNumber] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [expiryDate, setexpiryDate] = useState();
  const [batchType, setBatchType] = useState();
  const [batchVarient, setBatchVarient] = useState([]);
  const [batchTypeList, setBatchTypeList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [variantList, setVariantList] = useState([]);
  const [weightInkg, setWeightInkg] = useState("");
  const [notificationPeriod, setNotificationPeriod] = useState("");

  const [emptyFieldErrorMsg, setEmptyFieldErrorMsg] = useState("");
  const [updateExistingStock, setupdateExistingStock] = useState(false);
  const [barcode, setBarcode] = useState("");

  const [selectedQuantity, setSelectedQuantity] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [selectedType, setSelectedType] = useState();
  const [editBarcode, setEditBarcode] = useState(true);
  const [newBarcode, setNewBarcode] = useState();
  const [reloadInputs, setReloadInputs] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    const payload = {
      variant: 1,
      quantity: "",
      unit: "",
      price: "",
      discount: "",
      sellingPrice: "",
      stock: "",
      weightInKg: "",
      barcode: "",
    };
    setBatchVarient((batchVarient) => [...batchVarient, payload]);
    getBatchList();
    if (distinctVariant?.length === 0) {
      setEditBarcode(false);
    }
  }, []);

  const handleExpiryDate = (date, dateString) => {
    setexpiryDate(date.format("X") * 1000);
  };

  const getBatchList = () => {
    getBatchTypeAndVariants()
      .then((res) => {
        if (res.data.status == "success") {
          setBatchTypeList(res.data.productBatchConfigurations);
          const type = res.data.productBatchConfigurations.map(
            (item) => item.type
          );
          setTypeList(type);
          if (type.length > 0) {
            setBatchType(res.data.productBatchConfigurations[0].type);
            setVariantList(res.data.productBatchConfigurations[0].variants);
          }
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const updateVarient = async (variant, type, value) => {
    await setBatchVarient(
      batchVarient.map((item) => {
        return item.variant === variant ? { ...item, [type]: value } : item;
      })
    );
  };

  const updateMultipleField = (updatedFields) => {
    setBatchVarient((prevBatchVariant) => {
      console.log("prevBatchVariant: ", prevBatchVariant);
      return prevBatchVariant.map((variant) => {
        if (variant.variant === updatedFields[0].variant) {
          let temp = variant;
          updatedFields.map((data) => {
            temp[data.type] = data.value;
          });
          return temp;
        } else return variant;
      });
    });
  };

  useEffect(() => {
    console.log(batchVarient);
  }, [batchVarient]);

  const addProductBatch = async () => {
    setEmptyFieldErrorMsg("");
    if (batchVarient.length > 0) {
      // console.log(batchVarient);
      if (batchNumber === "") {
        setEmptyFieldErrorMsg("Missing Field: Batch Number");
        return;
      }
      if (notificationPeriod === "") {
        setEmptyFieldErrorMsg("Missing Field: Notification Period");
        return;
      }
      if (!expiryDate) {
        setEmptyFieldErrorMsg("Missing Field: Expiry Date");
        return;
      }
      if (invoiceNumber === "") {
        setEmptyFieldErrorMsg("Missing Field: Invoice Number");
        return;
      }

      // batchVarient.map((batch) => {
      //   if (batchVarient && batch["quantity"] === "") {
      //     setEmptyFieldErrorMsg("Missing Field: Quantity");
      //     return;
      //   }
      //   if (batchVarient && batch["unit"] === "") {
      //     setEmptyFieldErrorMsg("Missing Field: Unit");
      //     return;
      //   }
      //   if (batchVarient && batch["stock"] === "") {
      //     setEmptyFieldErrorMsg("Missing Field: Stock");
      //     return;
      //   }
      //   if (batchVarient && batch["price"] === "") {
      //     setEmptyFieldErrorMsg("Missing Field: MRP");
      //     return;
      //   }
      //   if (batchVarient && batch["discount"] !== "") {
      //     if (
      //       batchVarient && batchVarient[0]["discount"] >= minCategoryDiscount
      //         ? minCategoryDiscount
      //         : 0
      //     ) {
      //       console.log(batchVarient[0]["discount"], ":test here");
      //     } else {
      //       setEmptyFieldErrorMsg(
      //         `There should be minimun discount of ${minCategoryDiscount}% for this product `
      //       );
      //       return;
      //     }
      //   } else {
      //     setEmptyFieldErrorMsg("Missing Field: Discount");
      //     return;
      //   }

      //   if (batchVarient && batch["sellingPrice"] === "") {
      //     setEmptyFieldErrorMsg("Missing Field: Selling Price");
      //     return;
      //   }
      //   if (batchVarient && batch["weightInKg"] === "") {
      //     setEmptyFieldErrorMsg("Missing Field: Weight");
      //     return;
      //   }
      // });

      const isVerificationError = await batchVarient.find((batch) => {
        return (
          !batch.quantity ||
          !batch.unit ||
          // (!batch.stock && batch.stock < 0) ||
          batch.stock === undefined ||
          batch.stock === null ||
          batch.stock === "" ||
          !batch.price ||
          // !batch.discount ||
          !batch.sellingPrice ||
          !batch.weightInKg ||
          !batch.dealerPrice
        );
      });

      const isDiscountNotVerified = await batchVarient.find((batch) => {
        return batch.discount < minCategoryDiscount;
      });

      if (isDiscountNotVerified) {
        setEmptyFieldErrorMsg(
          `There should be minimum discount of ${minCategoryDiscount}% for this product `
        );
        return;
      } else if (!isVerificationError) {
        const adminUser = JSON.parse(localStorage.getItem("activeCustomer"));
        const payload = {
          productId: props.productId,
          batchNumber: batchNumber,
          type: batchType,
          updateExistingStock: updateExistingStock,
          priceAndStocks: batchVarient,
          expiryDate: expiryDate,
          invoiceNumber: invoiceNumber,
          notificationPeriodForToBeExpiredBatch: Number(notificationPeriod),
          adminUser: adminUser.userId,
          pageURL: window.location.href,
          screenName: "Manage Stocks",
          browserInfo: findBrowser(),
          accountType: adminUser.accountType,
          deviceModel: findDeviceModel(),
        };
        console.log(
          "SwitchUser :: ",
          cookies.get("switchuser"),
          cookies.get("switchuser") === "true"
            ? "admin"
            : cookies.get("accountType")
        );
        props.addproductBatch(payload);
      } else if (isVerificationError) {
        if(!isVerificationError.quantity) {
        setEmptyFieldErrorMsg("Quantity was missing");
        } else if(!isVerificationError.unit) {
          setEmptyFieldErrorMsg("Unit was missing");
        } else if(!isVerificationError.stock) {
          setEmptyFieldErrorMsg("Stock was missing");
        } else if(!isVerificationError.price) {
          setEmptyFieldErrorMsg("MRP was missing");
        } else if(!isVerificationError.dealerPrice) {
          setEmptyFieldErrorMsg("Dealer Price was missing");
        } else if(!isVerificationError.sellingPrice) {
          setEmptyFieldErrorMsg("SellingPrice was missing");
        } else if(!isVerificationError.weightInKg) {
          setEmptyFieldErrorMsg("Weight In Kg was missing");
        }
      } else {
        setEmptyFieldErrorMsg("Some fields are missing");
      }
    }
  };

  const addVarient = () => {
    const payload = {
      variant: batchVarient.length + 1,
      quantity: "",
      unit: "",
      price: "",
      discount: "",
      sellingPrice: "",
      stock: "",
      weightInKg: "",
      barcode: "",
    };
    setBatchVarient((batchVarient) => [...batchVarient, payload]);
  };

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  }

  const removeBatch = (selectedVariant) => {
    const tempVariant = batchVarient.filter((data) => data?.variant !== selectedVariant);
    setBatchVarient(tempVariant)
  }

  return (
    <div className="add-batch-section">
      {distinctVariant?.length > 0 && (
        <>
          {editBarcode && (
            <>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="add-image-col"
                >
                  <div className="add-product-tile">
                    Variant
                    <Select
                      onSelect={(value) => {
                        if (value !== "-1") {
                          let tempValue = distinctVariant?.filter((data) => {
                            if (data?.value === value) {
                              setSelectedQuantity(data.quantity);
                              setSelectedUnit(data.unit);
                              setBarcode(data.barcode);
                              setSelectedType(data.type);
                              setBatchType(data.type);
                              setReloadInputs(prev =>{
                                return !prev;
                              })
                            }
                          });
                        } else {
                          setSelectedQuantity("");
                          setSelectedUnit("");
                          setBarcode("");
                          setSelectedType("");
                          setBatchType("");
                        }
                      }}
                      // value={distinctVariant}
                      style={{ width: "100%" }}
                      defaultValue={"-1"}
                    >
                      <Option value="-1">-- SELECT ANY VARIANT --</Option>
                      {distinctVariant?.map((item) => (
                        <Option value={item?.value}>{item?.value}</Option>
                      ))}
                    </Select>
                  </div>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="add-image-col"
                >
                  {editBarcode && (
                    <>
                      Create New Variant if not listed <br />
                      <Button
                        type={editBarcode ? "primary" : "ghost"}
                        onClick={() => {
                          setEditBarcode(false);
                          setSelectedQuantity("");
                          setSelectedUnit("");
                          setBarcode("");
                          setSelectedType("");
                          setBatchType("");
                          setReloadInputs(perv => {
                            return !perv;
                          })
                        }}
                      >
                        {"Add New Variant"}
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
              <Divider />
            </>
          )}
        </>
      )}

      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
          <div className="add-product-tile">
            <span className="required-field-asterisk">*</span>Batch Number
            <Input
              value={batchNumber}
              onChange={(e) => setBatchNumber(e.target.value)}
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
          {/* <Checkbox
          // onChange={(e) => console.log(e.target.checked)}
          >
            Product comes under MRP
          </Checkbox> */}
          <div className="add-product-tile">
            <span className="required-field-asterisk">*</span>Notification
            Period For Batch
            <InputNumber
              className="stock-variant-input"
              type="number"
              min={0}
              value={notificationPeriod}
              onChange={(value) => setNotificationPeriod(value)}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
          <div className="add-product-tile">
            <span className="required-field-asterisk">*</span>Type
            <Select
              onSelect={(value, option, info) => {
                setBatchType(option.value);
                setVariantList(
                  batchTypeList.find((item) => item.type == option.value)
                    .variants
                );
              }}
              // onChange={(value, info) => console.log(info)}
              value={batchType}
              style={{ width: "100%" }}
              disabled={editBarcode}
            >
              {typeList.map((item) => (
                <Option value={item}>{item}</Option>
              ))}
            </Select>
          </div>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
          <div className="add-product-tile">
            <span className="required-field-asterisk">*</span>Expiry Date
            <DatePicker
              disabledDate={disabledDate}
              onChange={handleExpiryDate}
              style={{ width: "100%" }}
            />
          </div>
        </Col>
      </Row>
      {/* Adding Invoice Number Below */}
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
          <div className="add-product-tile">
            <span className="required-field-asterisk">*</span>Invoice Number
            <Input
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="add-image-col"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Checkbox
            onChange={(e) => setupdateExistingStock(e.target.checked)}
            style={{ marginBottom: "10px" }}
          >
            Update price of existing stock
          </Checkbox>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
          {/* <div className="add-product-tile">
            Weight in Kg
            <Input
              value={weightInkg}
              onChange={(e) => setWeightInkg(e.target.value)}
            />
          </div> */}
        </Col>
      </Row>
      {batchVarient.map((item) => (
        <StockVarient
          priceStock={item}
          edit={false}
          noNeedToCheckStock={true}
          variantList={variantList}
          editVarient={updateVarient}
          updateMultipleField={updateMultipleField}
          categoryCommission={categoryCommission}
          selectedQuantity={selectedQuantity}
          selectedUnit={selectedUnit}
          selectedType={selectedType}
          barcode={barcode}
          editBarcode={editBarcode}
          setBarcode={setBarcode}
          acceptBarcodeValues={true}
          newBarcode={newBarcode}
          setNewBarcode={setNewBarcode}
          removeBatch={removeBatch}
          centralRepoId={centralRepoId}
          reloadInputs={reloadInputs}
          setReloadInputs={setReloadInputs}
        />
      ))}

      {/* <div className="minimum-tags" onClick={(e) => addVarient()}>
        +Add another variant
      </div> */}
      {categoryCommission && (
        <div className="price-commision-text">
          <span style={{ color: "#3e6df3" }}>*Selling price </span>includes{" "}
          {/* <span style={{ color: "#3e6df3" }}> {categoryCommission}% </span>{" "} */}
          Dream Life commission
        </div>
      )}
      {/* Adding Missing field warning statement belwo: */}
      {emptyFieldErrorMsg ? (
        <div className="missing-field-text">
          <span>{emptyFieldErrorMsg}</span>
        </div>
      ) : (
        <></>
      )}

      <div className="seller-action-button">
        <Button
          className="button-seller-request-reject"
          onClick={() => props.setAddBatch(false)}
        >
          Discard
        </Button>

        <Button
          onClick={() => {
            addProductBatch();
          }}
          className="button-seller-request-approve"
          disabled={isLoading}
        >
          {isLoading ? <Loading style={{ color: "white" }} /> : " Add Batch"}
        </Button>
      </div>
    </div>
  );
};
export default AddBatch;
