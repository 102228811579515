


import {
  SET_FILTER_BY_BRAND,
  SET_FILTER_BY_VENDOR,
  SET_FILTER_BY_DATE_RANGE,
  SET_FITER_BY_STOCK_PERCENTAGE,
  SET_FILTER_BY_SCREEN
} from "./types";

export const setFilterByBrand = (data) => {
  return {
    type: SET_FILTER_BY_BRAND,
    data,
  };
};
export const setFilterByVendor = (data) => {
  return {
    type: SET_FILTER_BY_VENDOR,
    data,
  };
};

export const setFilterByDateRange = (data) => {
  return {
    type: SET_FILTER_BY_DATE_RANGE,
    data,
  };
};

export const setFilterByStockPercentage = (data) => {
  return {
    type: SET_FITER_BY_STOCK_PERCENTAGE,
    data,
  };
};

export const setFilterByScreen = (data) => {
  return {
    type: SET_FILTER_BY_SCREEN,
    data,
  }
}
