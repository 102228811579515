


import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  message,
  notification,
  Spin,
} from "antd";
import CompanyLogo from "../../Assets/Images/Logo/DL_Logo.png";
import { ReactComponent as Phone } from "../../Assets/Images/Icons/Seller/phone.svg";
import { ReactComponent as Mail } from "../../Assets/Images/Icons/Seller/mail.svg";
import Icon from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import { resetPasswordApi } from "../../ApiAction/CustomerApiActions/customerApi";
const { Title } = Typography;
const ResetFromOtp = (props) => {
  const [form] = Form.useForm();
  const [forgotMessage, setForgotMessage] = useState("");
  const [fields, setfields] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const passwordRef = useRef();

  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };

  const PasswordValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 8) {
      callback("Password should consists of minimun of 8 chartcters");
    } else {
      callback();
    }
  };

  const compareToFirstPassword = (rule, value, callback) => {
    console.log("hello_validaton password", passwordRef.current.props.value);
    const password = passwordRef.current.props.value;

    if (value && value !== password) {
      callback("Passwords mismatch. Please re-enter");
    } else {
      callback();
    }
  };
  const formFields = [
    {
      name: "otp",
      placeHolder: "OTP *",
      rules: [
        {
          pattern: new RegExp("^[0-9]*$"),
          message: "Enter a valid OTP",
        },
        {
          required: true,
          message: "This field is required !",
        },
      ],
    },
    {
      name: "emailId",
      placeHolder: "Email ID *",
      ruleType: "email",
      message: "Enter a vallid email Id",
    },
    {
      name: "password",
      placeHolder: "Password *",
      validator: PasswordValidator,
      ref: passwordRef,
      type: "password",
    },
    {
      name: "confirmPassword",
      placeHolder: "Confirm password *",
      validator: compareToFirstPassword,
      type: "password",
    },
  ];

  const onFinish = (values) => {
    const payload = {
      email: values.emailId,
      confirmPassword: values.confirmPassword,
      newPassword: values.password,
      otp: parseInt(values.otp),
      accountType: props.isCustomer
        ? "customer"
        : props.isSeller
        ? "seller"
        : props.isStore
        ? "store"
        : props.isWarehouse
        ? "warehouse"
        : props.isAdmin
        ? "admin"
        : "customer",
    };
    if (props.isCustomer) {
      payload["referralId"] = values.referralId;
    }
    setisLoading(true);
    resetPasswordApi(payload)
      .then((response) => {
        if (response.data) {
          if (response.data.status == "failed") {
            setForgotMessage(response.data.message);
            openNotificationWithIcon("error", "Failed", response.data.message);
          } else {
            // message.success(response.data.message);
            openNotificationWithIcon(
              "success",
              "Success",
              response.data.message
            );
            form.resetFields();
            props.setVisible();
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setisLoading(false);
      });

    // else{
    //   setForgotMessage("Please enter the email")
    // }
  };

  useEffect(() => {
    props.isCustomer
      ? setfields([
          {
            name: "referralId",
            placeHolder: "Customer ID *",
          },
          ...formFields,
        ])
      : setfields(formFields);
  }, []);
  return (
    <Modal
      className="circle_drop"
      visible={props.visible}
      onOk={() => {
        props.setVisible();
        form.resetFields();
      }}
      onCancel={() => {
        {
          props.setVisible();
          form.resetFields();
        }
      }}
      width={600}
      footer={null}
    >
      <img
        style={{ width: "150px" }}
        src={CompanyLogo}
        alt={"company logo"}
        className="hide_logo_mob"
      />
      <div className="contact-modal-form  login-modal-form form_data_group">
        <Title level={3}> Reset Password </Title>

        <div style={{ fontWeight: "500" }}>
          {/* Enter the email address associated with your account  */}
        </div>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            {fields?.map((data, index) => {
              return (
                <Form.Item
                  key={index}
                  hasFeedback
                  name={data.name}
                  rules={
                    data.rules
                      ? data.rules
                      : [
                          {
                            type: data.ruleType ? data.ruleType : null,
                            message: data.message ? data.message : null,
                          },
                          {
                            required: data.referalId ? false : true,
                            message: "This field is required !",
                          },
                          {
                            validator: data.validator ? data.validator : "",
                          },
                        ]
                  }
                >
                  {data.type && data.type === "password" ? (
                    <Input.Password
                      ref={data.ref ? data.ref : null}
                      placeholder={data.placeHolder}
                    />
                  ) : (
                    <Input
                      ref={data.ref ? data.ref : null}
                      type={data.type ? data.type : null}
                      placeholder={data.placeHolder}
                    />
                  )}
                </Form.Item>
              );
            })}

            <Form.Item className="button_submit">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ fontWeight: "600" }}
              >
                RESET PASSWORD
              </Button>
              <br />
            </Form.Item>
          </Form>
        </Spin>

        {forgotMessage && <div className="error-message">{forgotMessage}</div>}
      </div>
    </Modal>
  );
};
export default ResetFromOtp;
