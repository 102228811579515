


import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input, message, Row, Col } from "antd";
import { PlusSquareOutlined } from "@ant-design/icons";
import {
  fetchUserAddressList,
  setasDefaultAddress,
} from "../../../../../ApiAction/CustomerApiActions/addressAPI";
import CartAddressList from "../../Cart/cartAddressList";
import AddAddress from "../../Cart/addAddress";
import Cookies from "universal-cookie";
import { getDeliveryCharge } from "../../../../../ApiAction/CustomerApiActions/addressAPI";

const SubscribeModal = (props) => {
  const cookies = new Cookies();
  const [addressList, setaddressList] = useState([]);
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [addAddressVisible, setAddAddressVisible] = useState(false);
  const [editItem, setEditItem] = useState();
  

  useEffect(() => {
    if (cookies.get("accountType") === "customer") {
      fetchAddress();
      fetchDeliveryCharge()
    }
  }, [props]);

  const fetchDeliveryCharge = () => {
    console.log(props)
    const payload = {
      totalAmount: props.productData?.sellingPrice * props?.quantity,
      totalWeights: props.productData?.weightInKg * props?.quantity,
    }
    console.log(payload)
    getDeliveryCharge(payload).then((response)=>{
      if(response) {
        console.log("Response of Subscribe Model Delivery Charge")
        console.log(response)
        props.setDeliveryCharge(response.data?.priceSummary?.deliveryCharge)
      }else {
        props.setDeliveryCharge(0)
      }
    }).catch((error) => {
      console.log("Error in Fetching Delivery Charge (Subscribe Model) :: "+error)
    })
  }

  const fetchAddress = () => {
    fetchUserAddressList()
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setaddressList(response.data.address);
            setDeliveryAddress(
              response.data.address.find((item) => item.primary === true)
            );
          }
        }
      })
      .catch(() => {});
  };

  const selectMaster = (id) => {
    setaddressList(
      addressList.map((item) =>
        item.id === id
          ? { ...item, primary: true }
          : { ...item, primary: false }
      )
    );
    setDeliveryAddress(addressList.find((item) => item.id === id));
  };

  const handleSetDefaultAddress = (id) => {
    const payload = {
      addressId: id,
    };

    setasDefaultAddress(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setaddressList(
              addressList.map((item) =>
                item.id === id
                  ? { ...item, primary: true }
                  : { ...item, primary: false }
              )
            );
            setDeliveryAddress(addressList.find((item) => item.id === id));
          } else message.error(response.data.message);
        }
      })
      .catch(() => {});
  };

  const setSuccesRegister = () => {
    setAddAddressVisible(false);
    fetchAddress();
  };
  return (
    <Modal
      visible={props.visible}
      onOk={() => props.setCancel(false)}
      onCancel={() => {
        props.setCancel(false);
      }}
      width={900}
      className="susbscribe-modal"
      footer={null}
    >
      <div className="contact-modal-form">
        <Row style={{ width: "100%" }}>
          <Col
            xs={24}
            sm={24}
            md={15}
            lg={15}
            xl={15}
            className={"add-image-col"}
          >
            <div className="cart-address-list" style={{ marginRight: "15px" }}>
              {addressList.map((item) => (
                <CartAddressList
                  address={item}
                  selectMaster={selectMaster}
                  handleSetDefaultAddress={handleSetDefaultAddress}
                />
              ))}
              <div
                className="cart-add-new-address "
                style={{ cursor: "pointer" }}
                onClick={() => setAddAddressVisible(true)}
              >
                <PlusSquareOutlined style={{ fontSize: "30px" }} />
                <div
                  style={{
                    color: "#4015da",
                    fontWeight: "500",
                    fontSize: "14px",
                    marginLeft: "20px",
                  }}
                >
                  Deliver to another address
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={7}
            lg={7}
            xl={7}
            className={"add-product-mobile-col"}
          >
            {props.productData && (
              <div
                className="cart-address-list"
                style={{ marginRight: "15px" }}
              >
                <div className="cart-total-price">
                  <div className="cart-total-mrp">Total MRP</div>
                  <div className="cart-total-mrp">
                    ₹{" "}
                    <span style={{ color: "#3e6df3" }}>
                      {(props.quantity * props.productData.price)?.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="cart-total-price">
                  <div className="cart-total-mrp">Discounts</div>
                  <div className="cart-total-mrp">
                    ₹{" "}
                    <span style={{ color: "#3e6df3" }}>
                      {(
                        props.quantity *
                        (props.productData.price -
                          props.productData.sellingPrice)
                      )?.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="cart-total-price">
                  <div className="cart-total-mrp">Total DLP</div>
                  <div className="cart-total-mrp">
                    ₹{" "}
                    <span style={{ color: "#3e6df3" }}>
                      {(
                        props.quantity * props.productData.sellingPrice
                      )?.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="cart-total-price">
                  <div className="cart-total-mrp">Delivery Charges</div>
                  <div className="cart-total-mrp">
                    ₹ <span style={{ color: "#3e6df3" }}>{props.deliveryCharge?.toFixed(2)}</span>
                  </div>
                </div>

                <div
                  className="cart-total-price"
                  style={{ margin: "20px 0px" }}
                >
                  <div className="cart-total-mrp">Total</div>
                  <div className="cart-total-mrp">
                    ₹{" "}
                    <span style={{ color: "#3e6df3" }}>
                      {(
                        (props.quantity * props.productData.sellingPrice) + props.deliveryCharge
                      )?.toFixed(2)}
                    </span>
                  </div>
                </div>

                <Button
                  style={{ margin: "0px", width: "100%" }}
                  onClick={() => props.addSubscribeProduct(deliveryAddress)}
                  className="button-seller-request-approve final_checkout"
                >
                  Place Order
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <AddAddress
        visible={addAddressVisible}
        editItem={editItem}
        setVisible={setAddAddressVisible}
        isCart
        setSuccess={setSuccesRegister}
      />
    </Modal>
  );
};
export default SubscribeModal;
