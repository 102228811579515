


import React from "react";

import { fetchSort, fetchSortBy } from "../../../ReduxStore/action";
import { connect } from "react-redux";
import FilterOrder from "./filterOrder";
import SortOrder from "./sortOrder";
import ReportsFilter from "../../Admin/Reports/Filter/reportsFilter";

const FilterAndSortOrder = (props) => {
  return (
    <React.Fragment>
      <FilterOrder />
      <SortOrder />
    </React.Fragment>
  );
};

export default FilterAndSortOrder;
