


import React from "react";
import { Typography, Input } from "antd";

const { Text } = Typography;
const { Search } = Input;

const SearchAvailiableProducts = (props) => {
  const { onSearch } = props;
  return (
    <div className="checkavailiablity_search_container">
      <Text className="search_availiable_products_label" strong>
        Search for Products
      </Text>
      <div className="checkavailiablity_searchbar">
        <Search
          size="large"
          placeholder="Search for products"
          allowClear
          enterButton="Search for products"
          onSearch={onSearch}
        />
      </div>
    </div>
  );
};

export default SearchAvailiableProducts;
