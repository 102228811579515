


import { combineReducers } from "redux";
import customerData from "./Reducer/customerReducer";
import authModalReducer from "./Reducer/authModalReducer";
import customerAddressReducer from "./Reducer/customerAddressReducer";
import productReducer from "./Reducer/productReducer";
import cartCountReducer from "./Reducer/cartCountReducer";
import manageOrderReducer from "./Reducer/manageOrderReducer";
import customerOrderReducer from "./Reducer/customerOrderReducer";
import reportReducer from "./Reducer/reportReducer";
import homePageReducer from "./Reducer/homePageReducer";
import cartItemsReducer from "./Reducer/cartItemsReducer";
import requestPayment from "./Reducer/requestPayment";
import sellerStatus from "./Reducer/sellerStatus";

export default combineReducers({
  customerData,
  authModalReducer,
  customerAddressReducer,
  productReducer,
  cartCountReducer,
  manageOrderReducer,
  customerOrderReducer,
  reportReducer,
  homePageReducer,
  cartItemsReducer,
  requestPayment,
  sellerStatus
});
