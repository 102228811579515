


import React, { useEffect, useState, Fragment } from "react";
import { Button, Modal, message } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import { ReactComponent as pdfIcon } from "../../../Assets/Images/Icons/pdf.svg";
import { downloadSellerAttachment } from "../../../ApiAction/SellerApiActions/sellerApi";
import {
  getViewUser,
  approveRejectUser,
} from "../../../ApiAction/AdminApiAction/adminApi";

const SellerRequest = (props) => {
  const [sellerView, setSellerView] = useState();
  const [requestLoading, setRequestLoading] = useState("");

  useEffect(() => {
    getViewUser(props.selectedId)
      .then((response) => {
        if (response.data.status == "success")
          setSellerView(response.data.payload);
      })
      .catch(() => {});
  }, [props]);

  const approve = (value) => {
    const payload = {
      sellerId: props.selectedId,
      status: value,
      accountType: props.isFromStore ? props.accountType : "seller",
    };
    setRequestLoading(value);
    approveRejectUser(payload)
      .then((response) => {
        if (response.data.status == "success") {
          message.success(`${value} user`);
          props.setSuccess(props.selectedId);
          if(response.data.refundReferenceId)
            message.success("Refund Successful!")
        } else {
          message.error(response.data.message)
        }
      })
      .catch(() => {})
      .finally(() => setRequestLoading(""));
  };

  const getValueWithFormat = (sellerView) => {
    if (sellerView) {
      const filterArray = [
        // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
        sellerView.address.addressLine1
          ? sellerView.address.addressLine1
          : null,
        sellerView.address.addressLine2
          ? sellerView.address.addressLine2
          : null,
        sellerView.address.pinCode ? sellerView.address.pinCode : null,
      ];
      console.log("data??", filterArray);

      const filtered = filterArray.filter(function (el) {
        return el != null;
      });
      const filteredNullStringValues = filtered.filter(Boolean);

      return (
        filteredNullStringValues &&
        filteredNullStringValues.toString().split(",").join(", ")
      );
    }
  };
  const viewSellerAttachement = (url) => {
    const payload = {
      url: url,
    };
    downloadSellerAttachment(payload).then((response) => {
      setUrlAttachment(response.data.url);
    });
  };
  const [urlAttachment, setUrlAttachment] = useState("");
  const [attchementView, setAttachementView] = useState(false);
  return (
    <div className="merchant-request-modal">
      <Modal
        className="sellermodal"
        title={null}
        footer={null}
        closeIcon={<CloseCircleOutlined />}
        visible={props.visible}
        centered={true}
        width={"60vw"}
        style={{ marginTop: "100px" }}
        onOk={() => props.setVisible(false)}
        onCancel={() => props.setVisible(false)}
      >
        {sellerView && (
          <div
            className="seller-request"
            style={{ padding: "20px", color: "#000000" }}
          >
            <div style={{ display: "flex" }}>
              <div>
                <Avatar size="large" icon="H" />
              </div>
              <div className="customer-address-content">
                <div>{sellerView.name} </div>
                <div> {sellerView.userCode}</div>
              </div>
            </div>

            <div className="seller-request-section-title">
              General Information
            </div>
            <div className="merchent-section-content">
              <div>{props.isAffiliate? "Affiliate" : "Seller"} Name: {sellerView.name} </div>
              <div>{props.isAffiliate? "Affiliate" : "Seller"} ID: {sellerView.userCode}</div>
              <div>Phone Number: {sellerView.mobile} </div>
              <div> Email: {sellerView.email}</div>
              <div>
                {" "}
                Address:{" "}
                {sellerView.address
                  ? getValueWithFormat(sellerView)
                  : /* sellerView.address.addressLine1 +
                    ", " +
                    sellerView.address.addressLine2 +
                    ", " +
                    sellerView.address.pinCode */
                    sellerView.pinCode}
              </div>
            </div>
            <div className="seller-request-section-title">Bank Details</div>
            {sellerView.bankAccount && (
              <div className="merchent-section-content">
                <div>Bank Name: {sellerView.bankAccount.bankName} </div>
                <div> Branch: {sellerView.bankAccount.branch}</div>
                <div>
                  Account Number: {sellerView.bankAccount.accountNumber}{" "}
                </div>
                <div>
                  IFSC Code: {sellerView.bankAccount.ifsc}{" "}
                </div>
                <div> PAN No: {sellerView.bankAccount.panNo}</div>
              </div>
            )}

            {!props.isAffiliate && (
              <>
              <div className="seller-request-section-title">Trade Details</div>
              {sellerView.tradeDetails && (
                <div className="merchent-section-content">
                  <div>GST Number: {sellerView.tradeDetails.gstNumber} </div>
                  <div>
                    {" "}
                    Business Name: {sellerView.tradeDetails.businessName}
                  </div>
                  <div>
                    Business Location: {sellerView.tradeDetails.businessLocation}{" "}
                  </div>
                  <div>
                    Business Email: {sellerView.tradeDetails.businessEmail}
                  </div>
                  <div> Business Website: {sellerView.tradeDetails.website}</div>
                  <div>
                    {" "}
                    Type Of Business: {sellerView.tradeDetails.typeOfBusiness}
                  </div>
                </div>
              )}
              </>
            )}

            <div className="seller-request-section-title">
              Attached Documents
            </div>
            {sellerView.tradeDetails &&
              sellerView.tradeDetails.attachmentPaths &&
              sellerView.tradeDetails.attachmentPaths.map((item) => (
                <div
                  className="merchent-section-content"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Icon component={pdfIcon} style={{ fontSize: "35px" }} />
                  <div
                    style={{
                      marginLeft: "10px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      viewSellerAttachement(item);
                      setAttachementView(!attchementView);
                    }}
                  >
                    {item.split("/")[2]}{" "}
                    <Modal
                      title={null}
                      footer={null}
                      closeIcon={<CloseCircleOutlined />}
                      visible={attchementView}
                      onOk={() => setAttachementView(false)}
                      onCancel={() => setAttachementView(false)}
                      centered={true}
                      width={"60vw"}
                      style={{ marginTop: "100px" }}
                    >
                      <img
                        width={"100%"}
                        src={`${urlAttachment}`}
                        alt="attachment"
                      />
                    </Modal>
                  </div>
                </div>
              ))}

            <div className="seller-request-section-title">Personal Details</div>
            <div className="merchent-section-content">
              <div>ID Proof: {sellerView.idProof} </div>
              <div>ID Proof Number: {sellerView.idProofNumber}</div>
            </div>
            {!props.isFromStore && (
              <Fragment>
                <div className="seller-request-section-title">
                  Service Locations
                </div>
                <div className="merchent-section-content">
                  <div>District </div>
                  <div className="seller-pincode-list">
                    {" "}
                    {sellerView.location}
                  </div>
                  <div>PIN Codes </div>
                  <div className="flex" style={{columnGap: "0.5em", flexWrap: "wrap"}}>
                    {sellerView.serviceablePinCodes &&
                      sellerView.serviceablePinCodes.map((item) => (
                        <div className="seller-pincode-list seller-profile-tile">
                          {item} ,
                        </div>
                      ))}
                  </div>
                </div>
              </Fragment>
            )}

            <div className="seller-action-button">
              <Button
                className="button-seller-request-reject"
                onClick={() => approve("REJECTED")}
                loading={requestLoading == "REJECTED"}
              >
                Reject Request
              </Button>

              <Button
                onClick={() => approve("APPROVED")}
                className="button-seller-request-approve"
                loading={requestLoading == "APPROVED"}
              >
                Approve Request
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SellerRequest;
// export default SellerRequest;
