


import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Modal,
  Input,
  Button,
  message,
  Popover,
  Checkbox,
  Select,
} from "antd";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import "./productDetailContent.scss";
import QuantityController from "./QuantityController";
import {
  addToCart,
  addSubscription,
} from "../../../../../ApiAction/SellerApiActions/sellerApi";
import { openLoginModal } from "../../../../../ReduxStore/action/authModalActions";
import { fetchCartCount } from "../../../../../ReduxStore/action/index";

import { ShareAltOutlined } from "@ant-design/icons";
import SubscribeModal from "./subscribeModal";
import ShareContentOnSocialMedia from "../ShareContent";
import isDev from "../../../../../Helpers/devDetect";
import Loading from "../../../../GeneralComponents/Loading";
import CheckAvail from "../../../../../Assets/Images/Icons/check.svg";
import { checkAvailiablity } from "../../../../../ApiAction/CustomerApiActions/productApi";
import { setPincode } from "../../../../../ReduxStore/action/productActions";
import { ENV_TYPE } from "../../../../../Utils/constants";
import { history } from "../../../../../App";
import { AppContext } from "../../../../GeneralComponents/appContext";
const { Text, Title } = Typography;
const { Option } = Select;
const warning = Modal.warning;

const ProductDetailContent = (props) => {
  const { productData } = props;
  const cookies = new Cookies();
  const [activeCustomer, setactiveCustomer] = useState(
    JSON.parse(localStorage.getItem("activeCustomer"))
  );
  const [quantity, setquantity] = useState(1);
  const [subscribeQuantity, setSubscribeQuantity] = useState(1);
  const [seeAvailability, setAvailability] = useState(false);
  const [pinCode, setpinCode] = useState();
  const [checkPincode, setcheckPincode] = useState();
  const [processedPrice, setProcessedPrice] = useState();
  const [price, setPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [salesInsentive, setsalesInsentive] = useState();
  const [batch, setBatch] = useState("");
  const [subscribe, setSubcribe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscribeOrder, setSubscribeOrder] = useState(false);
  const [every, setEvery] = useState(["weekly", 1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [deliveryPeriod, setDeliveryPeriod] = useState("");
  const [deliveryDays, setDeliveryDays] = useState("");
  const [inCart, setinCart] = useState(false);
  const [appType, setAppType] = useState('system')
  const [vendorType, setVendorType] = useState();

  const [checkAvailiablityLoading, setcheckAvailiablityLoading] =
    useState(false);
  const [isNotValidPincode, setisNotValidPincode] = useState(false);
  const [isAvailableMsg, setisAvailableMsg] = useState();
  const [notAvailableMsg, setnotAvailableMsg] = useState();
  const [deliveryCharge, setDeliveryCharge] = useState(0);  // set as props to Subscribe Model
  const ProviderProps = useContext(AppContext);

  useEffect(() => {
    setProcessedPrice(productData.processedPrice);
    if (productData.processedPrice && productData.processedPrice.length > 0) {
      setSelectedItem(productData.processedPrice[0]);
      setPrice(productData.processedPrice[0].price);
      setSellingPrice(productData.processedPrice[0].sellingPrice);
      setSelectedVariant(productData.processedPrice[0].serialNumber);
      setAvailableQuantity(productData.processedPrice[0].stock);
      setBatch(productData.processedPrice[0].batchNumbers[0]);
      // setsalesInsentive(productData.processedPrice[0].salesIncentive);
      setVendorType(productData.vendorType)
    }
    if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
      setAppType("mobile")
    }else {
      setAppType("system")
    }
  }, [productData]);

  const detailsPart01 = [

    { key: "Brand",
      value: productData.brandName
    },
    {
      key: "Availablity",
      value: availableQuantity > 0 ? "In Stock" : "Out Of Stock",
    },
    {
      key: productData?.vendor && "Sold By & No.",
      value: productData.vendorAddress?
      (
        <div>
          <span>{productData.vendor}, {productData.vendorMobile}</span>
          <span>{productData.vendorAddress.addressLine1}</span>
          <span>{productData.vendorAddress.addressLine2}, {productData.vendorAddress.state}</span>
          <span>{productData.vendorAddress.pinCode}</span>
        </div>
      ) : `${productData?.vendor && productData?.vendor}${productData?.vendorMobile && ', ' +productData?.vendorMobile }`,
    },

    // { key: "Product Code", value: "P 014" },
  ];

  const renderShareContent = ({ name, price }) => {
    const title = `${name} at ₹ ${price}`;
    const url = isDev ? "https://www.a.net/" : window.location.href;

    return <ShareContentOnSocialMedia title={title} url={url} />;
  };

  const handleCheckAvailiablity = () => {
    console.log(productData, "product data for check availablity: ");

    var pinCodeRegex = new RegExp("^[1-9][0-9]{5}$");

    if (pinCodeRegex.test(checkPincode)) {
      setcheckAvailiablityLoading(true);
      checkAvailiablity(productData.productId, checkPincode)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.availability) {
              setisAvailableMsg(`deliverable to ${checkPincode}`);
            } else {
              setnotAvailableMsg(
                `currently non deliverable to ${checkPincode}`
              );
            }
            console.log(response, ":response for check availablity");
          }
        })
        .catch(() => {})
        .finally(() => {
          setcheckAvailiablityLoading(false);
        });
    } else setisNotValidPincode(true);
  };

  const changeVariant = (item) => {
    setSelectedItem(item);
    setPrice(item.price);
    setSellingPrice(item.sellingPrice);
    setSelectedVariant(item.serialNumber);
    setAvailableQuantity(item.stock);
    // setsalesInsentive(item.salesIncentive);
    setquantity(1);
  };
  const handleCustomerAddToCart = () => {
    const paylaod = {
      userId: cookies.get("userId"),
      accountType: "customer",
      item: {
        productId: productData.productId,
        vendorId: productData.vendorId,
        vendorType: productData.vendorType,
        quantity: Math.abs(quantity),
        itemCurrentPrice: selectedItem,
      },
    };

    addToCart(paylaod)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.fetchCartCount(props.cartCountStore + 1);
            message.success(response.data.message);
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const addTocartProduct = async () => {

    const isValidPincode = (ProviderProps.nearestPincode? productData.serviceLocations.includes(ProviderProps.nearestPincode) :
      productData.serviceLocations.includes(ProviderProps.pinCode)) || productData.serviceLocations.includes("All India");

    if (availableQuantity > 0) {
      if (activeCustomer && isValidPincode) {
        if (props.cartItems) {
          // const isAlreadyExists = await props.cartItems.find((item) => {
          //   return (
          //     item.productId == productData.productId &&
          //     item.serialNumber === selectedItem.serialNumber
          //   );
          // });

          if (inCart) {
            history.push(
              ENV_TYPE === "production" ? `/cart` : `/customer/cart`
            );
            // message.warning("Product already added to cart");
          } else {
            handleCustomerAddToCart();
          }
        } else handleCustomerAddToCart();
      } else {
        if (cookies.get("activeUserPincode") || productData.serviceLocations.includes("All India")) {
          if(isValidPincode)
            handleUpdateGuestCart();
          else
            message.error("Product not available at set pincode");
        } else {
          ProviderProps.setPincodeModalVisible(true);
          ProviderProps.setProduct(productData);
          ProviderProps.setSelectedItem(selectedItem);
          ProviderProps.setQuantity(quantity);
          // handleShowWarning();
        }
      }
    } else message.warning("Product out of stock");
  };

  const handleShowWarning = () => {
    warning({
      title: "Please enter valid Pincode",
      className: "create-confirm-modal",
    });
  };

  const handleUpdateGuestCart = async () => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart"));
    const temp = {
      productId: productData.productId,
      productName: productData.productName,
      vendorId: productData.vendorId,
      vendorType: productData.vendorType,
      quantity: quantity,
      image: productData.productImages[0],
      itemCurrentPrice: selectedItem,
      processedPriceAndStocks: productData.processedPrice,
      itemId: productData.productId + selectedItem.serialNumber,
    };
    if (guestCart) {
      // const isAlreadyExists = await guestCart.find((item) => {
      //   return (
      //     item.productId == temp.productId &&
      //     item.itemCurrentPrice.serialNumber ===
      //       temp.itemCurrentPrice.serialNumber
      //   );
      // });

      if (inCart) {
        // message.warning("Product already added to cart");
        history.push(ENV_TYPE === "production" ? `/cart` : `/customer/cart`);
      } else {
        localStorage.setItem("guestCart", JSON.stringify([...guestCart, temp]));
        message.success("Product added to cart");
        //this part of code is for calculating guest cart count
        const cartCount = guestCart.reduce((prev, cur) => {
          return prev + cur.quantity;
        }, 0);
        props.fetchCartCount(cartCount + temp.quantity);
      }
    } else {
      localStorage.setItem("guestCart", JSON.stringify([temp]));
      message.success("Product added to cart");
      //this part of code is for calculating guest cart count
      props.fetchCartCount(temp.quantity);
    }
  };

  const addSubscribeProduct = (deliveryAddress) => {
    setSubscribeOrder(false);
    if (activeCustomer) {
      setLoading(true);
      const paylaod = {
        customerId: cookies.get("userId"),
        addressId: deliveryAddress.id,
        subscriptionTitle: "",
        image:
          productData.productImages.length > 0
            ? productData.productImages[0]
            : "",
        productId: productData.productId,

        productName: productData.productName,
        quantity: subscribeQuantity,
        itemCurrentPrice: selectedItem,
        daysBetweenOrder: deliveryDays,
        period: deliveryPeriod,
        deliveryCharge: deliveryCharge,
        appType: appType
      };

      addSubscription(paylaod)
        .then((response) => {
          if (response.status === 200) {
            setLoading(false);
            setSubcribe(!subscribe);
            if (response.data.status === "success") {
              message.success(response.data.message);
            } else message.error(response.data.message);
          }
        })
        .catch(() => {})
        .finally(() => {});
    } else {
      props.openLoginModal("customer");
    }
  };

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem("activeCustomer"));

    if (customer) {
      setpinCode(customer.pinCode);
    }
  }, [localStorage.getItem("activeCustomer")]);

  const handleCheckInCart = async () => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart"));
    let isAlreadyExists;

    if (guestCart) {
      isAlreadyExists = await guestCart.find((item) => {
        return (
          item.productId == productData.productId &&
          item.itemCurrentPrice.serialNumber === selectedItem.serialNumber
        );
      });
    } else if (props.cartItems) {
      isAlreadyExists = await props.cartItems.find((item) => {
        return (
          item.productId == productData.productId &&
          item.serialNumber === selectedItem.serialNumber
        );
      });
    }

    if (isAlreadyExists) setinCart(true);
    else setinCart(false);
  };
  useEffect(() => {
    if (selectedItem) {
      handleCheckInCart();
    }
  }, [
    localStorage.getItem("guestCart"),
    productData,
    props.cartItems,
    selectedItem,
  ]);

  return (
    <>
      {loading ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Loading />
        </div>
      ) : (
        productData && (
          <div className="product-detail-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Title style={{fontSize: "1.5rem"}}>{productData.productName}</Title>
              <Popover
                content={renderShareContent({
                  name: productData.productName,
                  price: sellingPrice,
                })}
                trigger="click"
              >
                <ShareAltOutlined className="share-icon" />
              </Popover>
            </div>

            <div className="product-detail-content-price">
              <Text className="actual_price">
                ₹ {Number(sellingPrice).toFixed(2)}
              </Text>
              <Text className="prev_price" delete>
                {" "}
                ₹ {Number(price).toFixed(2)}
              </Text>
            </div>
            {/* {cookies.get("accountType") == "customer" && (
              <div className="code-tag">
                <Text>SI: ₹ {salesInsentive}</Text>
              </div>
            )} */}

            <div className="poduct-detail-content-more hr_product_detail_data">
              <div className="poduct-detail-content-more-part01">
                <div className="poduct-detail-content-more-part01-key hr_pdt_key">
                  {detailsPart01.map((data) => {
                    return <Text>{data.key} </Text>;
                  })}
                </div>
                <div className="poduct-detail-content-more-part01-value hr_pdt_value">
                  {detailsPart01.map((data) => {
                    var data_val = data.value;
                    if(typeof data_val === 'string') {
                      var lcasetxt = data_val?.replace(/\s+/g, "-").toLowerCase();
                      return <Text className={lcasetxt}>{data_val}</Text>;
                    } else return data_val;
                  })}
                </div>
              </div>
              <div className="hr_product_detail_part02">
                {/* <Radio.Group
                className="hr_weight_radio"
                defaultValue="100g"
                options={options}
                onChange={onChange3}
                value={quantity}
                optionType="button"
              /> */}
                <div className="flex">
                  {processedPrice &&
                    processedPrice.map((item) => (
                      <Button
                        className={
                          selectedVariant == item.serialNumber
                            ? "button-product-detail-item-sizes-selected"
                            : "button-product-detail-item-sizes"
                        }
                        onClick={() => changeVariant(item)}
                      >
                        {`${item.quantity} ${item.unit}`}
                      </Button>
                    ))}
                </div>
                <div className="d_flex pdt_flex_revert">
                  <div className="product-detail-quantity-container hr_quality_data">
                    <Text>Quantity</Text>

                    <QuantityController
                      quantity={quantity}
                      stock={availableQuantity}
                      update={true}
                      setQuantity={(value) => setquantity(value)}
                    />
                  </div>

                  <div className="check-availablity-conatiner hr_availablity_check">
                      {!productData.allIndiaDelivery && (
                        <Text
                          className="hr_avail_title"
                          style={{ cursor: "pointer" }}
                          onClick={() => setAvailability(!seeAvailability)}
                        >
                          <img
                            src={CheckAvail}
                            style={{
                              width: "15px",
                              height: "15px",
                              position: "relative",
                              top: "-1px",
                            }}
                          />{" "}
                          Check Availability
                        </Text>
                      )}

                    {seeAvailability && (
                      <>
                        <div class="d_flex" style={{ marginTop: "4px" }}>
                          <Input
                            placeholder=" Your PIN Code"
                            type="text"
                            maxLength="6"
                            className="product-detail-pincode-inpt"
                            value={checkPincode}
                            onChange={(e) => {
                              setcheckPincode(e.target.value);
                              setisNotValidPincode(false);
                              setisAvailableMsg();
                              setnotAvailableMsg();
                            }}
                            onPressEnter={() => handleCheckAvailiablity()}
                          />

                          <Text
                            className="hr_pincode_check"
                            onClick={() => handleCheckAvailiablity()}
                          >
                            {" "}
                            {checkAvailiablityLoading ? (
                              <Loading style={{ color: "white" }} />
                            ) : (
                              "Check"
                            )}
                          </Text>
                        </div>
                        {isNotValidPincode && (
                          <Text type="danger" style={{ fontSize: "12px" }}>
                            Please Enter a valid pincode
                          </Text>
                        )}
                        {isAvailableMsg && (
                          <Text style={{ fontSize: "14px", color: "#58D68D", textTransform: "capitalize" }}>
                            {isAvailableMsg}
                          </Text>
                        )}
                        {notAvailableMsg && (
                          <Text type="danger" style={{ fontSize: "12px" }}>
                            {notAvailableMsg}
                          </Text>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <Button
                  type="primary"
                  size="large"
                  className="product-detail-add-to-cart-btn hr_main_pdt_cart_add"
                  onClick={() => addTocartProduct()}
                >
                  {inCart ? "Go To Cart" : "Add To Cart"}
                  {/* Add To Cart */}
                </Button>
                {/* Restricted Seller's Product Subscription */}
                {cookies.get("accountType") === "customer" && vendorType !== "SELLER" &&  (
                  <Checkbox
                    onChange={(e) =>
                      activeCustomer
                        ? setSubcribe(!subscribe)
                        : props.openLoginModal("customer")
                    }
                    className="subscribe-checkbox"
                  >
                    Subscribe and Save
                  </Checkbox>
                )}
                {subscribe && (
                  <React.Fragment>
                    <div className="product-detail-quantity-container hr_quality_data subscribe-flex">
                      <Text
                        style={{ alignSelf: "center", marginRight: "15px" }}
                      >
                        Subscribe Quantity
                      </Text>

                      <QuantityController
                        quantity={subscribeQuantity}
                        stock={availableQuantity}
                        update={true}
                        setQuantity={(value) => setSubscribeQuantity(value)}
                      />
                    </div>
                    <div className="d-flex" style={{ display: "flex" }}>
                      <div className="add-product-tile delvery-types">
                        Delivery every
                        <Select
                          onSelect={(value, option, info) => {
                            setDeliveryPeriod(
                              option.value == "weekly"
                                ? "weekly"
                                : option.value + " month"
                            );
                            setDeliveryDays(
                              option.value == "weekly" ? 7 : option.value * 30
                            );
                          }}
                          // onChange={(value, info) => console.log(info)}

                          style={{ width: "100%" }}
                        >
                          {every.map((item) => (
                            <Option value={item}>
                              {item} {item != "weekly" ? "month" : ""}
                            </Option>
                          ))}
                        </Select>
                      </div>

                      <Button
                        type="primary"
                        size="large"
                        className="product-detail-add-to-cart-btn hr_main_pdt_cart_add"
                        onClick={() =>
                          deliveryDays !== ""
                            ? setSubscribeOrder(true)
                            : message.error("please select delivery period")
                        }
                        style={{ marginTop: "30px", width: "170px" }}
                      >
                        Subscribe Now
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        )
      )}
      <SubscribeModal
        visible={subscribeOrder}
        quantity={subscribeQuantity}
        productData={selectedItem}
        addSubscribeProduct={addSubscribeProduct}
        setCancel={setSubscribeOrder}
        setDeliveryCharge={setDeliveryCharge}
        deliveryCharge={deliveryCharge}
      />
    </>
  );
};

const mapDispatchToProps = { openLoginModal, fetchCartCount };
const mapStateToProps = (state) => {
  return {
    cartCountStore: state.cartCountReducer,
    cartItems: state.cartItemsReducer,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailContent);
