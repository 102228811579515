

import React, { useState, useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { Select, notification, Input, Typography, AutoComplete } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import "../../SearchBar/Searchbar.css";
import useDebounce from "../../../Hooks/useDebounce";
import Cookies from "universal-cookie";
import { addUser, fetchUserSuggestion } from "./quickCheckoutAPI";
import SearchIcon from "../../../Assets/Images/Icons/search.svg";

const { Option } = Select;
const { Text } = Typography;

const SearchCustomerId = (props) => {
  const cookies = new Cookies();

  const [searchKey, setsearchKey] = useState("");
  const [searchDebounceKey, setsearchDebounceKey] = useState("");
  const [customerName, setcustomerName] = useState();
  const [list, setlist] = useState([]);
  const [isLoading, setisLoading] = useState("");
  const debouncedSearchTerm = useDebounce(searchDebounceKey, 500);

  // const makeHRId = (customerName, hrId) => {
  //   if (!isNaN(hrId)) {
  //     if (customerName && customerName?.length > 2) {
  //       let alpha = customerName?.substring(0, 2);
  //       return ("HR" + alpha + hrId).toUpperCase();
  //     } else {
  //       return hrId;
  //     }
  //   } else {
  //     return hrId;
  //   }
  // };

  const formatSearchKey = (name, id) => {
    if (id) {
      return name + " (" + id + ")";
    } else return name;
  };
  useEffect(() => {
    if (debouncedSearchTerm) {
      handleFetchSearchSuggestions(debouncedSearchTerm);
    } else {
      handleFetchSearchSuggestions("");
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (props?.data?.customerName) {
      props.setcustomerId(props?.data?.customerId);
      setsearchKey(
        formatSearchKey(props?.data?.customerName, props?.data?.referralId)
      );
      setcustomerName(
        formatSearchKey(props?.data?.customerName, props?.data?.referralId)
      );
    } else {
      setcustomerName();
      props.setcustomerId("");
    }
  }, [props.data]);

  const handleFetchSearchSuggestions = (key) => {
    const payload = {
      offset: 0,
      size: 18,
      key: key,
    };
    if (key)
      fetchUserSuggestion(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              setlist(response.data.customers);
            } else {
              setlist([]);
              notification["error"]({
                message: "Failed",
                description: `No customer with this name`,
              });
            }
          }
        })
        .finally(() => {
          setisLoading("");
        });
    else setlist([]);
  };

  const handleproductSearch = (key) => {
    if (key) {
      setisLoading("Loading...");
    } else {
      setisLoading("");
      props.setcustomerId("");
      props.setaddress("");
    }
    setsearchKey(key);
    setsearchDebounceKey(key);
  };

  const handleFocus = () => {
    $("#scrollbar")
      .on("focus", function () {
        $("body").addClass("scroll-disabled");
      })
      .on("blur", function () {
        $("body").removeClass("scroll-disabled");
      });
  };

  const handleProductChange = (value) => {
    if (searchKey) {
      setsearchKey(formatSearchKey(value.name, value?.referralId));

      var defaultAddress;
      if (value.address) {
        defaultAddress = value.address[0];
      } else {
        defaultAddress = {
          addressType: "",
          emailId: null,
          phoneNo: "",
          buildingName: "",
          ownerName: "",
          pinCode: "",
          district: "",
          addressLine1: "",
          addressLine2: "",
          id: "",
          alternatePhoneNo: null,
          state: "",
          primary: null,
        };
      }
      props.setcustomerId(value.userId);
      props.setaddress(value.address);
      const payload = {
        customerId: value.userId,
        referralId: value?.referralId,
        customerName: value?.name,
        checkoutCartId: props.data.checkoutCartId,
        customerAddress: value?.address ? value.address[0] : null,
      };

      if (value) {
        props.setisLoading(true);
        addUser(payload)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                props.setdata(response.data.quickCheckoutCart);
                notification["success"]({
                  message: "Success",
                  description: "Customer added successfully",
                });
              }
              if (response.data.status === "failed") {
                notification["error"]({
                  message: "Failed",
                  description: response.data.message,
                });
              }
            }
          })
          .finally(() => {
            props.setisLoading(false);
            console.log("loading false");
          });
      }
    }
  };

  const handleSelectProduct = (value, option) => {
    console.log(value, ":value ", option, ": option");
    handleProductChange(option?.data);
  };

  const handleRemoveCustomer = () => {
    const payload = {
      customerId: "",
      referralId: "",
      customerName: "",
      checkoutCartId: props.data.checkoutCartId,
      customerAddress: null,
    };
    props.setisLoading(true);
    addUser(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setsearchKey();
            props.setdata(response.data.quickCheckoutCart);
            notification["success"]({
              message: "Success",
              description: "Customer removed successfully",
            });
          }
          if (response.data.status === "failed") {
            notification["error"]({
              message: "Failed",
              description: response.data.message,
            });
          }
        }
      })
      .finally(() => {
        props.setisLoading(false);
        console.log("loading false");
      });
  };

  useEffect(() => {
    setlist([]);
  }, [props]);

  return (
    <div className="cus-id">
      <h3> Billed to</h3>
      {customerName ? (
        <div className="billed_tocust_name">
          <Text strong>{customerName}</Text>
          <CloseCircleOutlined
            className="remove_customer_icon"
            onClick={() => handleRemoveCustomer()}
          />
        </div>
      ) : (
        <AutoComplete
          size={"large"}
          style={{ width: "300px" }}
          dropdownStyle={{ width: 300, minWidth: 300 }}
          dropdownMenuStyle={{ maxHeight: 600, overflowY: "visible" }}
          value={searchKey}
          className="header-cus-id search"
          showSearch
          showArrow={false}
          defaultActiveFirstOption={true}
          placeholder="Search Customer"
          onSelect={handleSelectProduct}
          onSearch={handleproductSearch}
          onFocus={handleFocus}
          notFoundContent={isLoading ? isLoading : ""}
        >
          {list.map((data, index) => {
            return (
              <React.Fragment>
                <Option value={data.referralId} key={index} data={data}>
                  <div>
                    <div
                    // onClick={() => handleProductChange(data)}
                    >
                      <Text strong>
                        {data.name}{" "}
                        {data.referralId ? "(" + data.referralId + ")" : ""}
                      </Text>
                    </div>
                  </div>
                </Option>
              </React.Fragment>
            );
          })}
        </AutoComplete>
      )}
    </div>
  );
};

export default SearchCustomerId;
