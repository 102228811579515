import React, { useEffect, useState } from "react";
import { Input, DatePicker, AutoComplete, Dropdown, Menu } from "antd";
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import Loader from "../../Admin/Reports/ReportsTable/Loader";
import Cookies from "universal-cookie";
import { fetchUsers, fetchVendors } from "../../../ApiAction/AdminApiAction/adminApi";

// This filter is used in Both Manage Account (Admin) and Request Payment (Venter)
// It contain a Search Box, Dropdown for Status, Range Pick and a Download CSV Button
const PlatformFilters = (props) => {
  const { Search } = Input;
  const { RangePicker } = DatePicker;
  const [vendorNameList, setVendorNameList] = useState([]);
  const {
    vendorType,
    setSelectedVendorId,
    showVendorNameFilter,
    type,
    setColorFilterKey,
  } = props;
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const cookies = new Cookies();
  const [accountType] = useState(cookies.get("accountType"));
  const [showColorFilter, setShowColorFilter] = useState(false);

  // function used to disable date that come after today
  const disabledDate = (current) => {
    return current > moment();
  };

  // function to Enable Download CSV button. Download button will only be
  // enabled when we select a time range
  const rangeHandler = (dateString) => {
    if (dateString === null) {
      props.setStartTime("");
      props.setEndTime("");
    }
    if (dateString) {
      if (props.offset !== 0) {
        props.setOffset(0);
        props.setCurrentPage(1);
      }
      let startTime = moment(dateString[0]).format("YYYY-MM-DD");
      let endTime = moment(dateString[1]).format("YYYY-MM-DD");
      props.setStartTime(startTime);
      props.setEndTime(endTime);
    }
  };

  const handleFilterChange = (key) => {
    setColorFilterKey(key.key);
  };

  const filterMenu = (
    <Menu onClick={handleFilterChange}>
      <Menu.Item key="-1">All</Menu.Item>
      <Menu.Item key="1">1000 - 1999</Menu.Item>
      <Menu.Item key="2">2000 - 3999</Menu.Item>
      <Menu.Item key="3">Above 4000</Menu.Item>
    </Menu>
  );

  const searchHandler = (e) => {
    if (props.offset !== 0) {
      props.setOffset(0);
      props.setCurrentPage(1);
    }
    props.setSearchVal(e.target.value);
  };

  const searchVendorName = (value) => {
    const payload = {
      accountType:
        type === "summary"
          ? "store"
          : vendorType === "STORE"
          ? "store" :
          vendorType === "AFFILIATE"
          ? "affiliate"
          : "seller",
      //filter: { term: [{ isArchived: ["false"] }, {block: ["false"]}, { status: ["APPROVED"] }] },
      filter: {
        term: [{ status: ["APPROVED"] }],
      },
      term: [{ status: ["APPROVED"] }],
      key: value,
      offset: 0,
      size: 20,
    };
    if(vendorType === "AFFILIATE") {
      payload["filter"]["term"] = payload["filter"]["term"].concat([{ accountType: ["affiliate"] }]);
    }
    (vendorType === "AFFILIATE" ? fetchUsers(payload) : fetchVendors(payload))
      .then((response) => {
        if (response?.data?.status === "success") {
          setVendorNameList(displayList(response?.data?.documents));
        } else {
          setVendorNameList([]);
        }
      })
      .catch((error) => {
        console.log("Error is finding Vendor name :: ", error.message);
      })
      .finally();
  };

  const displayList = (listData) => {
    return listData?.map((data) => {
      return {
        value: vendorType === "AFFILIATE" ?
          data?.name + " " + (data.referralId ? "(" + data.referralId + ")" : "") : data.storeName,
        id: vendorType === "AFFILIATE" ? data.referralId : data?.id,
        label: <div>{vendorType === "AFFILIATE" ?
          data?.name + " " + (data.referralId ? "(" + data.referralId + ")" : "") : data.storeName}</div>,
      };
    });
  };

  const selectVendorName = (value) => {
    setSelectedVendorId("");
    setSelectedVendorId(value?.id);
  };

  useEffect(() => {
    if (accountType === "admin") {
      setSelectedVendorId("");
      setVendorNameList([]);
      setAutoCompleteValue("");
    }
  }, [vendorType]);

  const handleVisibleChange = (key) => {
    setShowColorFilter(key);
  }

  return (
    <div className="statusSortBox">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          columnGap: "1em",
        }}
      >
        {showVendorNameFilter && (
          <AutoComplete
            dropdownMatchSelectWidth={252}
            options={vendorNameList}
            style={{
              width: 600,
            }}
            onSearch={searchVendorName}
            onSelect={(value, option) => selectVendorName(option)}
            value={autoCompleteValue}
            onChange={(value) => {
              setAutoCompleteValue(value);
              if (value === "") {
                setSelectedVendorId("");
              }
            }}
          >
            <Input.Search placeholder={"Search " + (vendorType === "AFFILIATE" ? "Affiliate" : "Vendor") + " Name"} enterButton />
          </AutoComplete>
        )}

        {type !== "summary" ? (
          <>
            <Search
              placeholder={
                props.placeholder ? props.placeholder : "Search Inv.No, Req.No"
              }
              enterButton={<SearchOutlined />}
              size="medium"
              suffix=""
              onChange={searchHandler}
            />
            <RangePicker
              disabledDate={disabledDate}
              style={{ minWidth: "30%" }}
              onChange={rangeHandler}
            />
          </>
        ) : (
          <Dropdown.Button overlay={filterMenu} onVisibleChange={handleVisibleChange} visible={showColorFilter} >
            <p className="ant-dropdown-link">Filters</p>
          </Dropdown.Button>
        )}
      </div>
    </div>
  );
};

export default PlatformFilters;
