


import React, { useState, useEffect } from "react";
import { DatePicker, Popover, Button, Divider, Typography } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import moment from "moment";
import { fetchManageOrderFilter } from "../../../ReduxStore/action";
import { connect } from "react-redux";
const { Text } = Typography;
const SortOrder = (props) => {
  const [sortValue, setSortValue] = useState("(NewestFirst)");
  const [sortType, setsortType] = useState("Date");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  function onChangeTo(date, dateString) {
    setStartDate(dateString);
    const payload = {
      sortType: props.sort.sortType,
      sortBy: props.sort.sortBy,
      startTime: new Date(dateString).getTime(),

      endTime: props.sort.endTime,
      paymentMode: props.sort.paymentMode,
      itemOrderStatus: props.sort.itemOrderStatus,
      orderFrom: props.sort.orderFrom,
    };
    props.fetchManageOrderFilter(payload);
  }
  function onChangeFrom(date, dateString) {
    setEndDate(dateString);
    const payload = {
      sortType: props.sort.sortType,
      sortBy: props.sort.sortBy,
      startTime: props.sort.startTime,
      endTime: new Date(dateString).getTime(),
      paymentMode: props.sort.paymentMode,
      itemOrderStatus: props.sort.itemOrderStatus,
      orderFrom: props.sort.orderFrom,
    };
    props.fetchManageOrderFilter(payload);
  }
  useEffect(() => {}, []);
  const renderSortStatus = () => {
    return (
      <div>
        <div
          className="sort-option-content"
          onClick={() => {
            setSortValue("(NewestFirst)");
            setsortType("Date");
            const payload = {
              sortType: "desc",
              sortBy: "orderedDate",
              startTime: props.sort.startTime,
              endTime: props.sort.endTime,
              paymentMode: props.sort.paymentMode,
              itemOrderStatus: props.sort.itemOrderStatus,
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          Date (NewestFirst)
          {sortValue === "(NewestFirst)" && sortType === "Date" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div>
        <div
          className="sort-option-content"
          onClick={() => {
            setSortValue("(OldestFirst)");
            setsortType("Date");
            const payload = {
              sortType: "asc",
              sortBy: "orderedDate",
              startTime: props.sort.block,
              endTime: props.sort.archive,
              paymentMode: props.sort.paymentMode,
              itemOrderStatus: props.sort.itemOrderStatus,
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          Date (OldestFirst)
          {sortValue === "(OldestFirst)" && sortType === "Date" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div>
        {/* <Menu.Divider /> */}

        <Divider style={{ margin: "10px 0px" }} />
        <div className="flex">
          <DatePicker
            onChange={onChangeTo}
            className="sort-date-order"
            value={startDate !== "" ? moment(startDate) : null}
          />
          <DatePicker
            onChange={onChangeFrom}
            className="sort-date-order"
            value={endDate !== "" ? moment(endDate) : null}
          />
        </div>
        {endDate !== "" ||
          (startDate !== "" && (
            <div
              className="minimum-tags"
              onClick={() => {
                setStartDate("");
                setEndDate("");

                const payload = {
                  sortType: props.sort.sortType,
                  sortBy: props.sort.sortBy,
                  startTime: "",

                  endTime: "",
                  paymentMode: props.sort.paymentMode,
                  itemOrderStatus: props.sort.itemOrderStatus,
                  orderFrom: props.sort.orderFrom,
                };
                props.fetchManageOrderFilter(payload);
              }}
            >
              Clear Date
            </div>
          ))}
      </div>
    );
  };
  return (
    <Popover
      content={renderSortStatus()}
      placement="bottom"
      trigger="click"
      className="sort-dropdown-admin"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Button style={{ height: "40px" }}>
        <span style={{ color: "#827e7e" }}>Sort By: </span>
        <span>
          &nbsp;
          <Text strong> {" " + sortType}</Text>
          <Text strong style={{ color: "red" }}>
            {" "}
            {sortValue}
          </Text>
        </span>
        <CaretDownOutlined />
      </Button>
    </Popover>
  );
};
const mapDispatchToProps = {
  fetchManageOrderFilter,
};

const mapStateToProps = (state) => {
  return {
    sort: state.manageOrderReducer,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SortOrder);
