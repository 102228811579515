import React, { useState, useEffect, useRef } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { BsArrowRight } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { FILE_PATH } from "../../../Utils/constants";
import { FaRegEye } from "react-icons/fa";
import { HiCursorClick } from "react-icons/hi";
import moment from "moment";
import EditAds from "./EditAds";

function ViewBrands(props) {
  const { brandData, type, deleteAd, allData, primaryKey, reloadAds } = props;
  const [showSettings, setShowSettings] = useState(false);
  const [adId] = useState(
    type === "banner" ? brandData?.bannerId : brandData?.cardId
  );
  const [editAds, setEditAds] = useState(false);
  const settingsBoxRef = useRef();


  useEffect(() => {
    return () => {
      setShowSettings(false);
    };
  }, [props]);

  useEffect(() => {
    window.addEventListener("mousedown", closeSettingsBox);
    return () => {
      window.removeEventListener("mousedown", closeSettingsBox);
    };
  }, []);

  const closeSettingsBox = (e) => {
    if (settingsBoxRef?.current?.contains(e.target)) {
      return false;
    }
    setShowSettings(false);
  };

  return (
    <div className="ads-each-banner-list-main-container">
      <div className="ads-each-banner-list-each">
        <span style={{ display: "flex" }}>
          <img
            loading="lazy"
            src={FILE_PATH + brandData?.images[0]}
            className="ads-each-banner-list-each-main-image"
          />
          <span className="ads-each-banner-list-each-data-box">
            <p
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "15vw",
                overflow: "hidden",
                lineHeight: "1",
              }}
            >
              {brandData?.orderBy}
            </p>
            <p>{adId}</p>
            {/* <span style={{ display: "flex", columnGap: "0.2em" }}>
              <p className="ads-each-brand-stats">
                <FaRegEye /> {brandData?.views}
              </p>
              <p className="ads-each-brand-stats">
                <HiCursorClick /> {brandData?.clicks}
              </p>
            </span> */}
          </span>
        </span>
        <span className="ads-each-banner-list-each-time-box">
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "0",
              fontSize: "0.6rem",
              alignItems: "center",
            }}
          >
            <p>
              {moment(brandData?.dateRange?.from).format("YYYY-MM-DD HH:MM:SS")}
            </p>
            <BsArrowRight style={{ transform: "translateY(-50%)" }} />
            <p>
              {moment(brandData?.dateRange?.to).format("YYYY-MM-DD HH:MM:SS")}
            </p>
          </span>
          <div className="ads-each-banner-list-progress-bar"></div>
        </span>
        <span className="ads-each-banner-right-menus">
          {brandData?.isActive ? (
            <p className="ads-each-banner-list-status-text">ACTIVE</p>
          ) : (
            <p className="ads-each-banner-list-status-text">EXPIRED</p>
          )}

          <div className="ads-each-banner-list-each-settings-box">
            <IoSettingsOutline
              style={{ cursor: "pointer" }}
              onClick={() => setShowSettings(!showSettings)}
            />
            {showSettings && (
              <div
                className="ads-each-banner-list-settings-box"
                ref={settingsBoxRef}
              >
                <ul>
                  <li onClick={() => setEditAds(true)}>
                    <FiEdit2 /> Edit
                  </li>
                  <li
                    onClick={() => {
                      deleteAd(adId);
                      setShowSettings(false);
                    }}
                  >
                    <AiOutlineDelete /> Delete
                  </li>
                </ul>
              </div>
            )}
          </div>
        </span>
      </div>
      {editAds && (
        <EditAds
          primaryKey={primaryKey}
          setEditAds={setEditAds}
          adData={brandData}
          allData={allData}
          reloadAds={reloadAds}
        />
      )}
    </div>
  );
}

export default ViewBrands;
