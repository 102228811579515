


import React from "react";
// import Visa from "../../../Assets/Images/Icons/HR-visa.svg";
// import MasterCard from "../../../Assets/Images/Icons/HR-master-card.svg";

import  MasterCard from "../../../Assets/Images/Icons/mastercard.svg"
import  VisaMaster from "../../../Assets/Images/Icons/visa.svg"
import  Rupay from "../../../Assets/Images/Icons/rupay.svg"
import  UPI  from "../../../Assets/Images/Icons/upi.svg"
import  NetBanking from "../../../Assets/Images/Icons/netbanking.svg"
import  COD  from "../../../Assets/Images/Icons/cod.svg"
import "./Payment.css";


export default function IPaymentOptions() {
  // return (
  //   // <div className="footer-payment-options-container">
  //   {/* <img className="footer-socialMedia-icon" src={Visa} alt="" />
  //     <img className="footer-socialMedia-icon" src={MasterCard} alt="" /> */}



  //   // </div >
  // );
  return (
    <>
      <ul className="flex-container-payment">

        <li className="flex-item-payment">    <p>&copy;  2024 dlmbiz.com</p></li>
        <li className="flex-item-payment">  <img src={MasterCard} alt="master_card" /></li>
        <li className="flex-item-payment">  <img src={VisaMaster} alt="visa"/></li>
        <li className="flex-item-payment">  <img src={Rupay} alt="rupay"/></li>
        <li className="flex-item-payment">  <img src={UPI} alt="upi"/></li>
        <li className="flex-item-payment"> <img src={NetBanking} alt="net_banking"/></li>
        <li className="flex-item-payment">  <img src={COD} alt="cash_on_delivery"/></li>
      </ul>

    </>
  )
}
