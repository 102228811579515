import React, { Fragment, useState } from "react";
import { MdSearch } from "react-icons/md";
import { Col, Divider, Input, message, Table } from "antd";
import "./InvoiceSearch.scss";
import Column from "antd/lib/table/Column";
import { adminInvoiceSearch } from "../../../ApiAction/AdminApiAction/adminApi";
import EmptyList from "../../GeneralComponents/emptyList";
import moment from "moment";

const InvoiceSearch = () => {
  const { Search } = Input;
  const [tableLoading, setTableLoading] = useState(false);
  const [storeData, setStoreData] = useState([]);
  const [sellerData, setSellerData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [searchedInvoiceId, setSearchedInvoiceId] = useState(null);

  // For invoice search
  const searchInvoice = (invoiceId) => {
    if (invoiceId.length > 0 && invoiceId.length > 4) {
      const payload = {
        key: invoiceId,
        sortType: "desc",
        size: 10,
        offset: 0,
        sortBy: "orderedDate",
      };
      setTableLoading(true);
      setSearchedInvoiceId(null);
      setSearchedValue(invoiceId);
      adminInvoiceSearch(payload)
        .then((response) => {
          if (response?.data?.status === "success") {
            console.log("Got Response", response?.data?.source);
            separateData(invoiceMap(response?.data?.documents[0]));
            setSearchedInvoiceId(
              response?.data?.documents[0]?.source?.orderGroupId
            );
          } else {
            message.error(response?.data?.message);
            setSellerData([]);
            setStoreData([]);
          }
        })
        .catch((error) => {
          console.log("Error in Searching Invoice :: ", error.message);
        })
        .finally(() => {
          setTableLoading(false);
        });
    } else {
      message.warning("Can't Search, invalid INVOICE number");
    }
  };

  //For Separating Store and Seller
  const separateData = (fullData) => {
    let sellers = fullData?.filter((data) => data?.vendorType === "SELLER");
    let stores = fullData?.filter((data) => data?.vendorType === "STORE");
    setSellerData(sellers);
    setStoreData(stores);
    console.log("Sellers");
    console.log(sellers);
    console.log("Stores");
    console.log(stores);
  };

  //For Invoice Mapping
  const invoiceMap = (orders) => {
    let tempArray = [];
    let oc = orders?.source?.orderedItems?.map((data, pointer) => {
      let tempObj = {
        index: pointer + 1,
        orderId: data?.orderId,
        vendorType: data?.vendorType,
        vendorName: data?.vendor,
        orderStatus: data?.itemOrderStatus?.currentStatus,
        name: data?.productName,
        gst: data?.vendorGstNumber,
        variant:
          data?.processedPriceAndStocks[0]?.quantity +
          data?.processedPriceAndStocks[0]?.unit,
        sellingPrice: data?.processedPriceAndStocks[0]?.sellingPrice,
        discount: data?.processedPriceAndStocks[0]?.discount,
        orderedDate: moment(data?.itemOrderStatus?.orderedDate).format(
          "YYYY-MM-DD"
        ),
        deliveredDate: data?.itemOrderStatus?.deliveredDate === null ? "PENDING" : moment(data?.itemOrderStatus?.deliveredDate).format(
          "YYYY-MM-DD"
        ),
        returnDate: data?.returnPeriod,
        salesIncentive: data?.itemCurrentPrice?.salesIncentive * data?.quantity ,
        orderedThrough: orders?.source?.appType,
      };
      tempArray.push(tempObj);
    });
    return tempArray;
  };

  return (
    <div className="category-management-container">
      <h1 className="content-name-header sub-title-tags" level={4}>
        <MdSearch /> Invoice Search
      </h1>
      <Divider />
      <div className="row-main-container">
        <div className="column-heading-container">
          <Search
            placeholder="Search your Invoice here."
            size="large"
            onBlur={(e) => searchInvoice(e.target.value)}
            onChange={(e) => searchInvoice(e.target.value)}
          />
        </div>
        {searchedValue?.length > 0 && (
          <h4 className="invoice-search-result-text">
            Search Result of : {searchedValue}
          </h4>
        )}
        {!tableLoading && searchedInvoiceId !== null && (
          <h4 className="invoice-search-result-text">
            INVOICE ID: {searchedInvoiceId}
          </h4>
        )}
        {storeData?.length === 0 && sellerData?.length === 0 && (
          <EmptyList
            msg={tableLoading ? "Loading Please wait" : "Nothing found..."}
          />
        )}

        {storeData?.length > 0 && (
          <Fragment>
            <h1 className="invoice-search-headings">AGENCY PRODUCTS</h1>
            <Table
              className="manageAccountTable"
              dataSource={storeData}
              pagination={false}
              loading={tableLoading}
              scroll={{ x: 2000 }}
            >
              <Column title={"#"} dataIndex="index" />
              <Column title={"ORDER ID"} dataIndex="orderId" />
              <Column
                title={"VENDOR TYPE"}
                dataIndex="vendorType"
                width="100px"
                render={(data) => {
                  if (data === "STORE") {
                    return <p className="invoice-search-tags">{data}</p>;
                  } else {
                    return <p className="invoice-search-tags-seller">{data}</p>;
                  }
                }}
              />
              <Column title={"VENDOR NAME"} dataIndex="vendorName" />
              <Column
                title={"ORDER STATUS"}
                dataIndex="orderStatus"
                render={(data) => {
                  if (data === "DELIVERED") {
                    return (
                      <p className="invoice-search-tag-green">DELIVERED</p>
                    );
                  } else if (data === "CONFIRMED") {
                    return (
                      <p className="invoice-search-tag-orange">CONFIRMED</p>
                    );
                  }
                  if (data === "CANCELLED") {
                    return <p className="invoice-search-tag-red">{data}</p>;
                  } else {
                    return <p className="invoice-search-tag-default">{data}</p>;
                  }
                }}
              />
              <Column title={"DESCRIPTION"} dataIndex="name" />
              <Column title={"GST"} dataIndex="gst" />
              <Column title={"VARIANT"} dataIndex="variant" />
              <Column title={"SELLING PRICE"} dataIndex="sellingPrice" />
              <Column title={"DISCOUNT"} dataIndex="discount" />
              <Column title={"ORDERED DATE"} dataIndex="orderedDate" />
              <Column title={"DELIVERED DATE"} dataIndex="deliveredDate" />
              <Column title={"RETURN PERIOD"} dataIndex="returnDate" />
              {/* <Column title={"BV"} dataIndex="salesIncentive" /> */}
              <Column
                title={"ORDERED THROUGH"}
                dataIndex="orderedThrough"
                width="100px"
                render={(data) => {
                  if (data === "quickCheckout") {
                    return <p>{"QUICK CHECKOUT"}</p>;
                  } else if (data === "mobile") {
                    return <p>{"WEB MOBILE"}</p>;
                  } else if (data === "system") {
                    return <p>{"WEB APPLICATION"}</p>;
                  } else if (data === "Android") {
                    return <p>{"ANDROID APP"}</p>;
                  } else if (data === "IOS") {
                    return <p>{"IOS APP"}</p>;
                  }
                  return <p>{data}</p>;
                }}
              />
            </Table>
            <Divider />
          </Fragment>
        )}

        {sellerData?.length > 0 && (
          <Fragment>
            <h1 className="invoice-search-headings">SELLERS PRODUCTS</h1>
            <Table
              className="manageAccountTable"
              dataSource={sellerData}
              pagination={false}
              loading={tableLoading}
              scroll={{ x: 2000 }}
            >
              <Column title={"#"} dataIndex="index" />
              <Column title={"ORDER ID"} dataIndex="orderId" />
              <Column
                title={"VENDOR TYPE"}
                dataIndex="vendorType"
                width="100px"
                render={(data) => {
                  if (data === "STORE") {
                    return <p className="invoice-search-tags">{data}</p>;
                  } else {
                    return <p className="invoice-search-tags-seller">{data}</p>;
                  }
                }}
              />
              <Column title={"VENDOR NAME"} dataIndex="vendorName" />
              <Column
                title={"ORDER STATUS"}
                dataIndex="orderStatus"
                render={(data) => {
                  if (data === "DELIVERED") {
                    return (
                      <p className="invoice-search-tag-green">DELIVERED</p>
                    );
                  } else if (data === "CONFIRMED") {
                    return (
                      <p className="invoice-search-tag-orange">CONFIRMED</p>
                    );
                  }
                  if (data === "CANCELLED") {
                    return <p className="invoice-search-tag-red">{data}</p>;
                  } else {
                    return <p className="invoice-search-tag-default">{data}</p>;
                  }
                }}
              />
              <Column title={"DESCRIPTION"} dataIndex="name" />
              <Column title={"GST"} dataIndex="gst" />
              <Column title={"VARIANT"} dataIndex="variant" />
              <Column title={"SELLING PRICE"} dataIndex="sellingPrice" />
              <Column title={"DISCOUNT"} dataIndex="discount" />
              <Column title={"ORDERED DATE"} dataIndex="orderedDate" />
              <Column title={"DELIVERED DATE"} dataIndex="deliveredDate" />
              <Column title={"RETURN PERIOD"} dataIndex="returnDate" />
              <Column title={"BV"} dataIndex="salesIncentive" />
              <Column
                title={"ORDERED THROUGH"}
                dataIndex="orderedThrough"
                width="100px"
                render={(data) => {
                  if (data === "quickCheckout") {
                    return <p>{"QUICK CHECKOUT"}</p>;
                  } else if (data === "mobile") {
                    return <p>{"WEB MOBILE"}</p>;
                  } else if (data === "system") {
                    return <p>{"WEB APPLICATION"}</p>;
                  } else if (data === "Android") {
                    return <p>{"ANDROID APP"}</p>;
                  } else if (data === "IOS") {
                    return <p>{"IOS APP"}</p>;
                  }
                  return <p>{data}</p>;
                }}
              />
            </Table>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default InvoiceSearch;
