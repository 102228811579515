

import React, { useState, useEffect } from "react";

import { Tabs, Button, Typography, Dropdown, Menu, message, Modal } from "antd";
import { connect } from "react-redux";
import "./manageProducts.css";
import SortIcon from "../../../Assets/Images/Icons/Seller/Sort2.svg";
import { withRouter } from "react-router-dom";

import ManageProductList from "./Components/manageProductList";
import BulkUploadModal from "./Components/bulkUpload";
import {
  publishAllProducts,
  getProducts,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import Cookies from "universal-cookie";
import Loading from "../../GeneralComponents/Loading";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const ManageProducts = (props) => {
  const cookies = new Cookies();
  const [isMobileView, setisMobileView] = useState(false);
  const [type, setType] = useState("listed");
  const [productList, setProductList] = useState([]);
  const [total, setTotal] = useState(0);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);
  const [isLoadingPublishAll, setIsLoadingPublishAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSeller] = useState(
    cookies.get("accountType") === "seller" ? true : false
  );

  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    getProductsList(type, props.searchVal);
  }, [
    props.searchVal,
    type,
    props?.sort?.sortBy,
    props.brandId,
    props.relevance,
  ]);

  const getProductsList = (keyType, search = props.searchVal) => {
    // const term = [{ active: [keyType === "listed" ? "true" : "false"] }];
    setCurrentPage(1);
    const term = [];

    setIsLoading(true);
    term.push({
      vendorId: [cookies.get("vendorId")],
    });
    term.push({
      archive: ["false"],
    });
    term.push({
      isDraft: [
        keyType === "listed"
          ? "false"
          : keyType === "rejected"
          ? "false"
          : "true",
      ],
    });
    if (type !== "draft") {
      term.push({
        status: [type === "listed" ? "APPROVED" : "REJECTED"],
      });
    }
    if (props.brandId) {
      term.push({
        brandId: [props.brandId],
      });
    }
    const payload = {
      offset: 0,
      size: 10,

      key: search,
      sortBy: props.relevance ? "" : "lastModifiedDate",
      sortType: props.relevance
        ? ""
        : props.sort?.sortType
        ? props.sort.sortBy
        : "desc",
      filter: {
        term: term,
      },
    };
    console.log("Page and offset:(API fetch) ", currentPage, payload.offset);
    getProducts(payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(response.data.products);
          setTotal(Math.ceil(response.data.count / 10) * 10);
          console.log(
            "Page and offset:(API fetch) ",
            currentPage,
            payload.offset
          );
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  // useEffect(() => {
  //   console.log(currentPage, "currentPage")
  // }
  // ,[currentPage])

  const nextpage = (page = 1) => {
    setCurrentPage(page);
    // const term = [{ active: [true] }];
    const term = [];
    setIsLoading(true);
    term.push({
      vendorId: [cookies.get("vendorId")],
    });
    term.push({
      archive: ["false"],
    });
    term.push({
      isDraft: [
        type === "listed" ? "false" : type === "rejected" ? "false" : "true",
      ],
    });
    if (type !== "draft") {
      term.push({
        status: [type === "listed" ? "APPROVED" : "REJECTED"],
      });
    }

    if (props.brandId) {
      term.push({
        brandId: [props.brandId],
      });
    }
    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      key: props.searchVal,
      sortBy: props.relevance ? "" : "lastModifiedDate",
      sortType: props.relevance
        ? ""
        : props.sort?.sortType
        ? props.sort.sortBy
        : "desc",
      filter: {
        term: term,
      },
    };
    console.log("Page and offset: ", currentPage, payload.offset);
    getProducts(payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(response.data.products);
          setTotal(Math.ceil(response.data.count / 10) * 10);
          console.log("Page and offset: ", currentPage, payload.offset);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderAddProduct = () => {
    return (
      <Menu>
        {/* <Menu.Item
          onClick={() => {
            setBulkUploadModalVisible(true);
          }}
        >
          Bulk Upload
        </Menu.Item> */}
        <Menu.Item
          onClick={() => {
            if (props.isStore) props.history.push("/store/add-product");
            else if (props.isWarehouse)
              props.history.push("/warehouse/add-product");
            else props.history.push("/seller/add-product");
          }}
        >
          Add Product
        </Menu.Item>
      </Menu>
    );
  };

  const handlePublishAllProducts = () => {
    setIsLoadingPublishAll(true);
    publishAllProducts()
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(response.data.message);
            getProductsList();
          } else {
            message.error(
              "Sorry! Something went wrong. Please try again later!"
            );
          }
        }
        console.log(response);
      })
      .finally(() => {
        setIsLoadingPublishAll(false);
      });
  };

  const renderConfirmModal = () => {
    Modal.confirm({
      title: "Confirm",
      width: 400,
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to publish all products? Note** This action will be irreversible!`,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handlePublishAllProducts(),
      onCancel: () => {
        console.log("Publish All products request Cancelled!");
      },
    });
  };

  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        {" "}
        {!isMobileView ? (
          <h1 level={4} className="sub-title-tags">
            {" "}
            Products
          </h1>
        ) : (
          <Text>
            {" "}
            <span>
              {" "}
              <img
                src={SortIcon}
                alt="delete-icon"
                style={{ width: "25px", paddingRight: "5px" }}
              />
            </span>
            Sort
          </Text>
        )}
        <div className="manage-products-action">
          {type === "draft" ? (
            <Button
              className="publish-all-products-btn"
              type="primary"
              onClick={() => renderConfirmModal()}
            >
              {isLoadingPublishAll ? (
                <Loading style={{ color: "white" }} />
              ) : (
                "Publish All"
              )}
            </Button>
          ) : (
            <></>
          )}

          {/* to restrict add bulk upload in seller side */}
          {isSeller ? (
            <Button
              size={isMobileView ? "small" : ""}
              type="primary"
              className="manage-products-action-button"
              onClick={() => {
                if (props.isStore) props.history.push("/store/add-product");
                else if (props.isWarehouse)
                  props.history.push("/warehouse/add-product");
                else props.history.push("/seller/add-product");
              }}
            >
              Add Product
            </Button>
          ) : (
            <Button
              size={isMobileView ? "small" : ""}
              type="primary"
              className="manage-products-action-button"
              onClick={() => {
                if (props.isStore) props.history.push("/store/add-product");
                else if (props.isWarehouse)
                  props.history.push("/warehouse/add-product");
                else props.history.push("/seller/add-product");
              }}
            >
              Add Product
            </Button>
          )}
        </div>
      </div>
      <Tabs
        defaultActiveKey="listed"
        onChange={(activeKey) => {
          setType(activeKey);
          setProductList([]);
          getProductsList(activeKey);
        }}
        size={"large"}
      >
        <TabPane tab="Listed" key="listed">
          <ManageProductList
            type={type}
            productList={productList}
            total={total}
            nextpage={nextpage}
            isLoading={isLoading}
            currentPage={currentPage}
          />
        </TabPane>
        <TabPane tab="Draft" key="draft">
          <ManageProductList
            type={type}
            productList={productList}
            total={total}
            nextpage={nextpage}
            isLoading={isLoading}
            currentPage={currentPage}
          />
        </TabPane>
        <TabPane tab="Rejected" key="rejected">
          <ManageProductList
            type={type}
            productList={productList}
            total={total}
            nextpage={nextpage}
            isLoading={isLoading}
            currentPage={currentPage}
          />
        </TabPane>
      </Tabs>
      <BulkUploadModal
        visible={bulkUploadModalVisible}
        setVisiblity={setBulkUploadModalVisible}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
    brandId: state.reportReducer.brandId,
  };
};
export default withRouter(connect(mapStateToProps)(ManageProducts));
