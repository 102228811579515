


import React, { Fragment, useEffect, useState } from "react";
import { Modal, Typography } from "antd";

import "./stockOverview.scss";
import StockOverviewTable from "./stockOverviewTable";
import StockDetails from "./stockDetails";
import StockSummary from "./stockSummary";

import { formatStockOverviewData } from "./stockOverViewHelper";

import { orders } from "./stockdata";

const { Text } = Typography;
const StockOverview = (props) => {
  const { visible, setvisible, product, stocksOverviews } = props;
  const [activeVariant, setactiveVariant] = useState();

  const handleCloseModal = () => {
    setvisible(false);
  };

  const handleChangeVariant = (variant) => {
    const tempActive = stocksOverviews.find((data) => {
      return data.variant === variant;
    });
    console.log(tempActive, ":new active");
    setactiveVariant(tempActive);
  };

  useEffect(() => {
    console.log("product details: ", product);
  }, [product]);

  useEffect(() => {
    stocksOverviews && setactiveVariant(stocksOverviews[0]);
  }, [stocksOverviews]);

  return (
    <Modal
      title={
        <Fragment>
          <Text strong>Stock Overview</Text>
          <br />
          <Text style={{ fontSize: "12px", color: "#3e6df3" }}>
            {product.source.name}
          </Text>
        </Fragment>
      }
      visible={visible}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      width={1000}
      footer={null}
    >
      <div className="stock_overview_container">
        <div className="stock_overview_table">
          {" "}
          <StockOverviewTable
            data={formatStockOverviewData(activeVariant)}
          />{" "}
        </div>
        <div className="stock_overview_summary">
          <StockDetails
            stockOverviews={stocksOverviews}
            active={activeVariant}
            handleChangeVariant={handleChangeVariant}
          />

          <StockSummary
            summary={activeVariant?.summary}
            availableStock={activeVariant?.availability}
          />
        </div>
      </div>
    </Modal>
  );
};

export default StockOverview;
