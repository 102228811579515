


import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Row,
  Form,
  Button,
  Divider,
  Input,
  message,
  Typography,
} from "antd";
import "./manageConfigure.scss";
import Loading from "../../GeneralComponents/Loading";
import { getCompanyDetails, postCompanyDetails } from "../../../ApiAction/AdminApiAction/adminApi";

const { Text } = Typography;

const ConfigureCompanyDetails = () => {
  const [form] = Form.useForm();
  const [isEditCompanyDetails, setIsEditCompanyDetails] = useState(false);
  const [isEditCompanyDetailsLoading, setIsEditCompnayDetailsLoading] = useState(false);
  const [initialConfig, setinitialConfig] = useState({});

  const fetchCompanyDetails = () => {
    getCompanyDetails().then((res) => {
      if (res.data.status == "success") {
        // console.log("res.data is :", res.data)
        form.setFieldsValue({...res.data.companyDetails, ...res.data.companyDetails.address});
        setinitialConfig({...res.data.companyDetails, ...res.data.companyDetails.address});
      }
    });
  };

  const handleCancelCompanyDetailsUpdate = () => {
    form.setFieldsValue(initialConfig)
    setIsEditCompanyDetails(false);
  }

  const updateCompanyDetails = (values) => {
    const payload = {
      gstNumber: values.gstNumber,
      companyEmail: values.companyEmail,
      companyName: values.companyName,
      companyUrl: values.companyUrl,
      logoUrl: values.logoUrl,
      address: {
        ownerName: values.ownerName,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        phoneNo: values.phoneNo,
        pinCode: values.pinCode,
        state: values.state
      }
    };
    setIsEditCompnayDetailsLoading(true);
    postCompanyDetails(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setIsEditCompnayDetailsLoading(false);
            setIsEditCompanyDetails(false);
            message.success(response.data.message);
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        message.error("Sorry! Something went wrong. Please try again later!");
      })
      .finally(() => { setIsEditCompnayDetailsLoading(false) });
  };

  useEffect(() => {fetchCompanyDetails()},[])

  const formFields = [
    {
      name: "ownerName",
      placeHolder: "Company Full Name"
    },
    {
      name: "companyName",
      placeHolder: "Company Short Name"
    },
    {
      name: "addressLine1",
      placeHolder: "Address Line 1"
    },
    {
      name: "addressLine2",
      placeHolder: "Address Line 2"
    },
    {
      name: "state",
      placeHolder: "State"
    },
    {
      name: "pinCode",
      placeHolder: "Pincode"
    },
    {
      name: "gstNumber",
      placeHolder: "GST No",
      rules: [
        {
          pattern: new RegExp(
            "^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[aA-zZ]{1}[0-9A-Za-z]{1})+$"
          ),
          message: "Enter a valid GST",
        }
      ]
    },
    {
      name: "companyEmail",
      placeHolder: "Company Email",
      rules: [
        {
          type: "email",
          message: "Please enter a valid email address!",
        }
      ]
    },
    {
      name: "pinCode",
      placeHolder: "Pincode"
    },
    {
      name: "companyUrl",
      placeHolder: "Website URL",
      rules: [
        {
          type: "url",
          message: "Please enter a valid website url!",
        }
      ]
    },
    {
      name: "logoUrl",
      placeHolder: "Logo URL",
      rules: [
        {
          type: "url",
          message: "Please enter a valid logo url!",
        }
      ]
    },
  ];

  return (
    <div>

      <Divider />

      {formFields.length > 0 && (
        <Form
          form={form}
          initialValues={initialConfig}
          onFinish={updateCompanyDetails}
        >
          <Row>
            {formFields.map((data) => {
              return (
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  xl={12}
                  className="add-image-col"
                  key={data.name}
                >
                  <Text>
                    {data.placeHolder}
                    <span className="required-field-asterisk">*</span>
                  </Text>
                  <Form.Item
                    name={data.name}
                    rules={
                      data.rules
                        ? [
                            ...data.rules,
                            {
                              required: true,
                              message: "This field is required !",
                            },
                          ]
                        : [
                            {
                              required: true,
                              message: "This field is required !",
                            },
                          ]
                    }
                  >
                    <Input
                      disabled={!isEditCompanyDetails}
                      type="text"
                      placeholder={data.placeHolder}
                    />
                  </Form.Item>
                </Col>
              );
            })}
          </Row>

          <div className="config_action_main">
            {isEditCompanyDetails ? (
              <Fragment>
                <Button
                  onClick={() => handleCancelCompanyDetailsUpdate()}
                  className="config_action_btn"
                >
                  Cancel
                </Button>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="config_action_btn"
                    style={{ marginLeft: "20px" }}
                  >
                    {isEditCompanyDetailsLoading ? (
                      <Loading style={{ color: "white" }} />
                    ) : (
                      " Submit"
                    )}
                  </Button>
                  <br />
                </Form.Item>
              </Fragment>
            ) : (
              <Button
                type="primary"
                onClick={() => setIsEditCompanyDetails(true)}
                className="config_action_btn"
              >
                Edit
              </Button>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default ConfigureCompanyDetails;
