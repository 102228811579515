import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { getMegamenu } from "../../../ApiAction/CustomerApiActions/homepageAPI";
import { FILE_PATH } from "../../../Utils/constants";
import { ENV_TYPE } from "../../../Utils/constants";
import { useHistory } from "react-router-dom";
import {
  addCategoryfilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
} from "../../../ReduxStore/action/productActions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

var CryptoJS = require("crypto-js");

function TypeCategory(props) {
  const [megaMenu, setMegaMenu] = useState([]);
  const history = useHistory();

  //to add selected categoryName to bread crumb
  const handleManageBreadCrumb = (categoryName, id) => {
    let breadCrumbList = [];
    let breadcrumb = {
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${categoryName}`
          : `/customer/product-listing/${categoryName}`,
      type: "catogery",
    };
    breadcrumb["label"] = categoryName;
    breadcrumb["id"] = id;

    breadCrumbList.push(breadcrumb);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  const redirectToCategoryPage = (categoryName, id, level) => {
    let encryptedCategoryId = CryptoJS.AES.encrypt(
      JSON.stringify(id),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    console.log("Encrypted Id ", encryptedCategoryId);
    console.log("LEVEL ::", level);
    //props.addCategoryfilter(id)
    switch (level) {
      case -1:
        //props.addCategoryfilter(id);
        console.log("LEVEL -1");
        history.push(
          ENV_TYPE === "production"
            ? `/fastmoving-listing/${encryptedCategoryId}`
            : `/customer/fastmoving-listing/${encryptedCategoryId}`
        );
        return;
        break;
      case 0:
        props.addSubCategory00filter(id);
        break;
      case 1:
        props.addSubCategory01filter(id);
        break;
      case 2:
        props.addSubCategory02filter(id);
        break;
      default:
        console.log("invalid category level");
    }
    handleManageBreadCrumb(categoryName, id);
    history.push(
      ENV_TYPE === "production"
        ? `/product-listing/${categoryName}`
        : `/customer/product-listing/${categoryName}`
    );
  };

  useEffect(() => {
    const localMenus = JSON.parse(localStorage.getItem("megamenu"));
    if (localMenus !== null) {
      setMegaMenu(localMenus);
    } else {
      getMegamenu()
        .then((response) => {
          console.log("Mega menu Response");
          setMegaMenu(response.data.documents);
        })
        .catch((error) => {
          console.log("Error in Fetching Mega menu :: " + error.message);
        });
    }
  }, []);

  return (
    <div className="type-category-section">
      {megaMenu?.length > 0 ? (
        <Fragment>
          {megaMenu?.map((menu, index) => (
            <div className="category-type-box" key={index}>
              <div
                className="category-type-img-name-box"
                onClick={() =>
                  redirectToCategoryPage(menu.categoryName, menu.id, -1)
                }
              >
                <img
                  src={`${FILE_PATH}${menu.image}`}
                  alt="type"
                  className="category-type-img"
                />
                <p className="category-type-name">{menu.categoryName}</p>
              </div>
              <div className="category-type-sub-category-big-box">
                <div className="category-type-sub-category-box">
                  {menu?.subCategories?.map((sub, subIndex) => (
                    <div style={{ display: "flex", flexDirection: "column" }} key={subIndex + 1000}>
                      <p
                        key={subIndex}
                        className="megaMenuHeading"
                        onClick={() =>
                          redirectToCategoryPage(sub.categoryName, sub.id, 0)
                        }
                      >
                        {sub.categoryName}
                      </p>
                      {sub?.subCategories?.map((child, childIndex) => (
                        <p
                          key={childIndex}
                          className="megaMenuSubHeading"
                          onClick={() =>
                            redirectToCategoryPage(
                              child.categoryName,
                              child.id,
                              1
                            )
                          }
                        >
                          {child.categoryName}
                        </p>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Fragment>
      ) : (
        <div className="type-category-section-skeleton-container">
          <div className="type-category-section-skeleton-box">
            <div className="type-category-section-skeleton-head"></div>
            <div className="type-category-section-skeleton-tail"></div>
          </div>
          <div className="type-category-section-skeleton-box">
            <div className="type-category-section-skeleton-head"></div>
            <div className="type-category-section-skeleton-tail"></div>
          </div>
          <div className="type-category-section-skeleton-box">
            <div className="type-category-section-skeleton-head"></div>
            <div className="type-category-section-skeleton-tail"></div>
          </div>
          <div className="type-category-section-skeleton-box">
            <div className="type-category-section-skeleton-head"></div>
            <div className="type-category-section-skeleton-tail"></div>
          </div>
          <div className="type-category-section-skeleton-box">
            <div className="type-category-section-skeleton-head"></div>
            <div className="type-category-section-skeleton-tail"></div>
          </div>
          <div className="type-category-section-skeleton-box">
            <div className="type-category-section-skeleton-head"></div>
            <div className="type-category-section-skeleton-tail"></div>
          </div>
          <div className="type-category-section-skeleton-box">
            <div className="type-category-section-skeleton-head"></div>
            <div className="type-category-section-skeleton-tail"></div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = {
  addCategoryfilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
};

export default withRouter(connect(null, mapDispatchToProps)(TypeCategory));
