

import React, { useState, useEffect } from "react";
import Avatar from "antd/lib/avatar/avatar";
import { Table, Button, Pagination, Popover, Modal, message, Input, Typography } from "antd";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom";
import SellerRequest from "../ManageSeller/sellerRequest";
import StoreDetailsModal from "./storeDetailsModal";
import LinkIcon from "../../../Assets/Images/Icons/link.svg";
import LinkedIcon from "../../../Assets/Images/Icons/linked.svg";
import {
  findBrowser,
  findDeviceModel,
} from "../../Admin/Logger/notificationBar/LogHelper";

import { ENV_TYPE, FILE_PATH, DOMAIN } from "../../../Utils/constants";

import {
  handleArcheiveBrand,
  handleBlockBrand,
} from "../ManageBrand/manageBrandHelper";
import {
  removeLiinkWarehouseToWarehouse,
  removeLiinkWarehouseToStore,
} from "../../../ApiAction/AdminApiAction/linkWarehouseAoi";
import ServiceLocationModal from "../../Seller/ManageProducts/Components/serviceLocationModal";
import LinkWarehouseModal from "./linkWarehouse";
import {
  userBlock,
  userArchive,
  platformVendorBlock,
} from "../../../ApiAction/AdminApiAction/adminApi";
import StoreBulkUploadModal from "./Components/storeBulkUpload";
import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";
import EmptyList from "../../GeneralComponents/emptyList";
import { Form } from "antd";
const { Title, Text } = Typography;
const { Column } = Table;
const confirm = Modal.confirm;

const ManageStoreTable = (props) => {
  console.log("This is the Props:");
  console.log(props);
  const cookies = new Cookies();
  const { parentWarehouse, reloadWarehouse, isLinkedStores, isBrand } = props;
  const [data, setData] = useState(["1", "2", "3", "4", "5"]);
  const [showModal, setShowModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [serviceModal, setServiceModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedData, setselectedData] = useState();
  const [requestModal, setRequestModal] = useState(false);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [serviceLocationsObject, setServiceLocationsObject] = useState();

  const [selId, setSelId] = useState("");
  const [selName, setselName] = useState("");
  const [block, setBlock] = useState();
  const [blockId, setBlockId] = useState();
  const [blockReason, setBlockReason] = useState("");
  const [showBlockModal, setBlockModal] = useState(false);

  const [form] = Form.useForm();

  //dynamicaly render link icon
  const getLinkIcon = (data) => {
    if (
      data?.linkedWarehouses?.length > 0 ||
      data?.linkedStores?.length > 0 ||
      data.linkedWarehouse
    ) {
      return LinkedIcon;
    } else return LinkIcon;
  };

  const onChange = (page) => {
    props.onPageChange(page);
  };

  //triggered while clicking on bulk upload of stocks
  const stockUpload = (details) => {
    setBulkUploadModalVisible(true);
    setSelId(details.id);
    setselName(details.name);
  };

  //triggered while clicking on view service locations
  const viewLocations = (userDetails) => {
    setselectedData(userDetails);

    if (userDetails?.serviceablePinCodesRequest) {
      console.log(
        "servicable object :",
        userDetails?.serviceablePinCodesRequest
      );
      setServiceLocations(userDetails.serviceablePinCodes);
      setServiceLocationsObject(userDetails.serviceablePinCodesRequest);
    } else console.log("servicable object missing");

    setServiceModal(true);
    console.log(userDetails, ":selected warehouse/store details");
  };

  //triggered while clicking on view linked wharehouse
  const linkWarehouse = (userDetails) => {
    setselectedData(userDetails);
    setLinkModal(true);
  };
  const switchToSeller = (id) => {
    if (ENV_TYPE == "dev") {
      cookies.set("userId", id, {
        path: "/",
      });

      cookies.set("accountType", props.isWarehouse ? "warehouse" : "store", {
        path: "/",
      });
    } else {
      cookies.set("userId", id, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      cookies.set("accountType", props.isWarehouse ? "warehouse" : "store", {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    }
    if (props.isWarehouse) props.history.push(`/warehouse`);
    else props.history.push(`/store`);
  };

  const deleteUser = (id, value, block) => {
    const payload = {
      userId: id,
      adminUser: cookies.get("userId"),
      pageURL: window.location.href,
      screenName:
        props.isWarehouse || props.isLinkedWarehouse
          ? "Manage Warehouse"
          : "Manage Store",
      browserInfo: findBrowser(),
      accountType: cookies.get("accountType"),
      deviceModel: findDeviceModel(),
    };
    confirm({
      title: `Do you want to ${value ? "Unarchive" : "archive"} this ${
        isBrand ? "Brand" : "user"
      } !!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        isBrand
          ? setData(handleArcheiveBrand(id, value, block, data))
          : userArchive(payload)
              .then((response) => {
                if (response.data.status == "success")
                  setData(
                    data.map((item) =>
                      item.id === id
                        ? { ...item, isArchived: !value, block: !block }
                        : item
                    )
                  );
              })
              .catch(() => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const platformBlock = (dataToBlock) => {
    const payload = {
      vendorId: dataToBlock?.id,
      //adminUser: cookies.get("userId"),
      //pageURL: window.location.href,
      //screenName: "Platform Charge Store",
      //browserInfo: findBrowser(),
      //accountType: accountType,
      //deviceModel: findDeviceModel(),
      block: !dataToBlock?.platformChargeBlock,
    };
    confirm({
      title: `Based on Platform Charge you want to block/unblock this vendor ?`,

      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        platformVendorBlock(payload)
          .then((response) => {
            if (response?.data?.status === "success") {
              message.success(response?.data?.message);
              console.log("------------>");
              console.log(dataToBlock);
              console.log(data);
              setData(
                data.map((item) =>
                  item.id === dataToBlock?.id
                    ? {
                        ...item,
                        platformChargeBlock: !dataToBlock?.platformChargeBlock,
                      }
                    : item
                )
              );
            } else {
              message.error(response?.data?.message);
            }
          })
          .catch()
          .finally(() => {
            //window.location.href = "/admin/manage-store";
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const blockUnblockUser = (id, value) => {
    
    if(isBrand) {
      setData(handleBlockBrand(id, value, data))
    } else {
      setBlockModal(true);
    }
    // confirm({
    //   title: `Do you want to ${value ? "unblock" : "block"}  this ${
    //     isBrand ? "Brand" : "user"
    //   } !!`,

    //   className: "create-confirm-modal",
    //   centered: true,
    //   cancelText: "No",
    //   okText: "Yes",
    //   content: (
    //     <div>
    //       <Form>
    //         <Form.Item
    //           name="blockReason"
    //           rules={[
    //             {
    //               required: true,
    //               message:"Block"
    //             }
    //           ]}>
    //           <Input
    //             style={{ textTransform: "uppercase" }}
    //             placeholder={"Block Reason"}
    //             required={true}
    //             value={blockReason}
    //             onChange={(e) => setBlockReason(e.target.value)}
    //           />
    //         </Form.Item>
    //       </Form>
    //     </div>
    //   ),
    //   onOk: () => {
    //     if(isBrand) {
    //       setData(handleBlockBrand(id, value, data))
    //     } else {
    //       if(blockReason.length > 3) {
    //         userBlock(payload)
    //             .then((response) => {
    //               if (response.data.status == "success")
    //                 setData(
    //                   data.map((item) =>
    //                     item.id === id ? { ...item, block: !value } : item
    //                   )
    //                 );
    //             })
    //             .catch(() => {});
    //           }
    //     }
    //   },
    //   onCancel() {
    //     console.log("Cancel");
    //   },
    // });
  };

  const handleConfirmRemoveLinkedWarehouse = (id) => {
    confirm({
      title: `Do you want to unlink this ${
        isLinkedStores ? "Store" : "Warehouse"
      }!!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        handleRemoveLinkedWarehouse(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleRemoveLinkedWarehouse = (id) => {
    console.log("remove warehouse id:", id, parentWarehouse);
    if (isLinkedStores) {
      //incase of removing store linked to warehouse
      const payload = {
        storeId: id,
        linkedWarehouse: "",
      };
      removeLiinkWarehouseToStore(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              console.log(
                "reponse for removed linked store of warehouse:",
                response
              );

              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error("unable to unlink please try again");
          }
        })
        .catch(() => {})
        .finally(() => {
          reloadWarehouse(1);
        });
    } else {
      //incase of removing warehouse linked to warehouse
      const payload = {
        warehouseId: parentWarehouse,
        linkedWarehouse: id,
      };
      removeLiinkWarehouseToWarehouse(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              console.log(
                "reponse for removed linked warehouse of warehouse:",
                response
              );

              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error("unable to unlink please try again");
          }
        })
        .catch(() => {})
        .finally(() => {
          reloadWarehouse(1);
        });
    }
  };

  const viewRequests = () => {
    setRequestModal(true);
  };

  const setApproved = (id) => {
    setRequestModal(false);
    setData(data.filter((item) => item.id !== id));
  };

  const blockOnFinish = () => {

    let id = blockId;
    let value = block;

    const payload = {
      userId: id,
      adminUser: cookies.get("userId"),
      pageURL: window.location.href,
      screenName:
        props.isWarehouse || props.isLinkedWarehouse
          ? "Manage Warehouse"
          : "Manage Store",
      browserInfo: findBrowser(),
      accountType: cookies.get("accountType"),
      deviceModel: findDeviceModel(),
      blockReason: blockReason
    };

    userBlock(payload)
      .then((response) => {
        
        if (response.data.status == "success")
          setData(
            data.map((item) =>
              item.id === id ? { ...item, block: !value, blockReason: !block? blockReason : undefined } : item
            )
          );
      })
      .catch(() => {});
    form.resetFields();
    setBlockModal(false);
    
  }

  useEffect(() => {
    setData(props.userData);
    setShowModal(false);
  }, [props]);

  return (
    <div
      onClick={() => {
        setShowModal(false);
      }}
    >
      <Modal
        title={`Do you want to ${block ? "unblock" : "block"} this ${
          isBrand ? "Brand" : "user"
        } !!`}
        visible={showBlockModal}
        centered="true"
        cancelText="Cancel"
        okText={!block? "Block" : "Unblock"}
        onOk={() => {
          form.submit();
          block && blockOnFinish();
        }}
        onCancel={() => {
          console.log("Cancel");
          setBlockModal(false);
          form.resetFields();
        }}
      >
        {!isBrand && (
          <Form form={form} onFinish={blockOnFinish}>
            <Text>Blocked Reason</Text>
            <Form.Item
              name="blockReason"
              rules={[
                {
                  required: true,
                  message: "Block reason required"
                }
              ]}>
              <Input
                style={{ textTransform: "uppercase", marginTop: "5px" }}
                placeholder={blockReason}
                required={true}
                value={blockReason}
                onChange={(e) => setBlockReason(e.target.value)}
                disabled={block}
              />
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Table
        dataSource={data}
        pagination={false}
        locale={{
          emptyText: (
            <span style={{ fontWeight: "600", color: "black" }}>
              {props.isWarehouse || props.isLinkedWarehouse ? (
                props.isLoadingWarehouse ? (
                  <LoadingCartItem />
                ) : (
                  <EmptyList msg={"No warehouses found"} />
                )
              ) : isBrand ? (
                props.isLoadingBrand ? (
                  <LoadingCartItem />
                ) : (
                  <EmptyList msg={"No brands found"} />
                )
              ) : props.isLoadingStore ? (
                <LoadingCartItem />
              ) : (
                <EmptyList msg={"No stores found"} />
              )}
            </span>
          ),
        }}
        onRow={(item) => ({
          onClick: (e) => {
            e.stopPropagation();
            setShowModal(isBrand ? showModal : !showModal);
            setSelectedId(
              props.warehouseLinkedStore
                ? item.storeId
                : props.warehouseLinkedWarehouse
                ? item.warehouseId
                : item.id
            );
          },
        })}
      >
        <Column
          title={""}
          dataIndex="firstName"
          render={(name, item) => (
            <div style={{ paddingLeft: "3%" }}>
              {isBrand ? (
                <Avatar size={40} src={FILE_PATH + item?.image} />
              ) : (
                <Avatar size={40} icon={item.name && item.name.charAt(0)} />
              )}
              {!isBrand && (
                <img src={getLinkIcon(item)} className="store-linked-profile" />
              )}
            </div>
          )}
        />

        <Column
          title={
            data?.length > 0
              ? props.isWarehouse || props.isLinkedWarehouse
                ? "Warehouse Id"
                : isBrand
                ? "Brand Id"
                : "Store Id"
              : ""
          }
          dataIndex="id"
          render={(name, item) => (
            <div className="table-customer-id">
              {isBrand ? item.brandCode : item.userCode}
            </div>
          )}
        />
        <Column
          title={
            data?.length > 0
              ? props.isWarehouse || props.isLinkedWarehouse
                ? "Warehouse Name"
                : isBrand
                ? "Brand Name"
                : "Store Name"
              : ""
          }
          dataIndex="name"
          render={(name, item) => (
            <div>
              <Popover
                content={
                  <StoreDetailsModal
                    reload={props.onPageChange}
                    details={item}
                    viewLocations={viewLocations}
                    linkWarehouse={linkWarehouse}
                    switchToSeller={switchToSeller}
                    viewRequests={viewRequests}
                    stockUpload={stockUpload}
                    isSeller={props.isSeller}
                    requests={props.requests}
                    isWarehouse={props.isWarehouse}
                    isLinked={
                      props.warehouseLinkedStore ||
                      props.warehouseLinkedWarehouse
                    }
                  />
                }
                placement="bottomLeft"
                trigger="click"
                visible={
                  (props.warehouseLinkedStore
                    ? item.storeId === selectedId
                    : props.warehouseLinkedWarehouse
                    ? item.warehouseId === selectedId
                    : item.id === selectedId) &&
                  showModal &&
                  !props.isLinkedWarehouse &&
                  !isLinkedStores
                }
                // getPopupContainer={trigger => trigger.parentElement}
                // onVisibleChange={()=>setShowModal(!showModal)}
              >
                <div className="table-customer-name">
                  {isBrand ? item.name : item.storeName}
                </div>
              </Popover>
            </div>
          )}
        />
        {!props.isWarehouse &&
          !props.isLinkedWarehouse &&
          !isLinkedStores &&
          !isBrand && (
            <Column
              title={data?.length > 0 ? "Pincode" : ""}
              dataIndex="phone"
              render={(name, item) => (
                <div className="table-customer-id">{item.pinCode}</div>
              )}
            />
          )}
        {!props.isLinkedWarehouse && !isLinkedStores && !isBrand && (
          <Column
            title={data?.length > 0 ? "Contact Number" : ""}
            dataIndex="phone"
            render={(name, item) => (
              <div className="table-customer-id">{item.mobile}</div>
            )}
          />
        )}

        {(props.isLinkedWarehouse || isLinkedStores) && (
          <Column
            title={""}
            dataIndex="name"
            render={(name, item) => (
              <Button
                type={"primary"}
                style={{ backgroundColor: "red", border: "none" }}
                className={
                  item.block
                    ? "button-customer-unblock"
                    : "button-customer-block"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleConfirmRemoveLinkedWarehouse(
                    isLinkedStores ? item.storeId : item.warehouseId
                  );
                }}
              >
                Remove
              </Button>
            )}
          />
        )}

        {props.warehouseLinkedWarehouse && (
          <Column
            title={""}
            dataIndex="name"
            render={(name, item) => (
              <div className="cusromer-actions">
                <Button
                  className={"button-customer-block"}
                  style={{ width: "unset" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.history.push(
                      `/warehouse/linked-warehouse/${item.warehouseId}`
                    );
                  }}
                >
                  View Products
                </Button>
              </div>
            )}
          />
        )}
        {!props.warehouseLinkedWarehouse &&
          !props.warehouseLinkedStore &&
          !props.linkedStore &&
          !props.isLinkedWarehouse &&
          !isLinkedStores && (
            <Column
              title={""}
              dataIndex="name"
              render={(name, item) => (
                <div className="cusromer-actions">
                  {!props.delete && !props.isBrand && (
                    <Button
                      className={
                        item.platformChargeBlock
                          ? "button-customer-unblock"
                          : "button-customer-block"
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowModal(false);
                        platformBlock(item);
                      }}
                    >
                      {item.platformChargeBlock ? "PC Unblock" : "PC Block"}
                    </Button>
                  )}
                  {!props.delete && (
                    <Button
                      className={
                        item.block
                          ? "button-customer-unblock"
                          : "button-customer-block"
                      }
                      onClick={(e) => {
                        console.log(item)
                        e.stopPropagation();
                        setShowModal(false);
                        setBlock(item.block);
                        setBlockId(item.id);
                        blockUnblockUser(item.id, item.block);
                        setBlockReason(item.blockReason);
                      }}
                    >
                      {item.block ? "Unblock" : "Block"}
                    </Button>
                  )}
                  <Button
                    className="button-customer-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModal(false);
                      deleteUser(item.id, item.isArchived, item.block);
                    }}
                  >
                    {item.isArchived ? "Unarchive" : "Archive"}
                  </Button>
                  <Button
                    className="button-customer-delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowModal(false);
                      props.editUser(item);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              )}
            />
          )}
      </Table>
      {props.total > 10 && (
        <Pagination
          size="small"
          total={props.total}
          current={props.currentPage}
          pageSize={20}
          // defaultCurrent={defaultPage}
          showSizeChanger={false}
          className="customer-pagination"
          onChange={(page) => {
            onChange(page);
            window.scrollTo(0, 0);
          }}
        />
      )}
      {linkModal && (
        <LinkWarehouseModal
          reload={props.onPageChange}
          visible={linkModal}
          setVisible={setLinkModal}
          id={selectedId}
          userDetails={selectedData}
          isWarehouse={props.isWarehouse}
        />
      )}
      {requestModal && (
        <SellerRequest
          selectedId={selectedId}
          visible={requestModal}
          setSuccess={setApproved}
          isFromStore
          accountType={props.isWarehouse ? "warehouse" : "store"}
          setVisible={setRequestModal}
        />
      )}
      <ServiceLocationModal
        visible={serviceModal}
        setVisible={setServiceModal}
        serviceLocations={serviceLocations}
        serviceLocationsObject={serviceLocationsObject}
        setServiceLocations={setServiceLocations}
        setServiceLocationsObject={setServiceLocationsObject}
        isAdmin
        userDetails={selectedData}
        reload={props.onPageChange}
        isForVendortype={props.isWarehouse ? "warehouse" : "store"}
      />
      <StoreBulkUploadModal
        isAdminStore
        visible={bulkUploadModalVisible}
        setVisiblity={setBulkUploadModalVisible}
        storeId={selId}
        storeName={selName}
      />
    </div>
  );
};
export default withRouter(ManageStoreTable);
