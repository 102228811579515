import React from 'react'

const BankDetails = (props) => {
    console.log(props)
    return (
        <div className="manage-account-summary-box">
            <div className="bank-sub-box">
                <p>BANK NAME</p>
                <p>{props.info.bankName}</p>
            </div>
            <div className="bank-sub-box">
                <p>BRANCH</p>
                <p>{props.info.branchName}</p>
            </div>
            <div className="bank-sub-box">
                <p>IFSC</p>
                <p>{props.info.ifscCode}</p>
            </div>
            <div className="bank-sub-box">
                <p>A/C NUMBER</p>
                <p>{props.info.accountNo}</p>
            </div>
        </div>
    )
}

export default BankDetails
