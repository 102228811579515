

import {
  ADD_CATEGORY_FILTER,
  ADD_SEARCH_FILTER,
  ADD_SUBCATEGORY_00_FILTER,
  ADD_SUBCATEGORY_01_FILTER,
  ADD_SUBCATEGORY_02_FILTER,
  ADD_SUBCATEGORY_03_FILTER,
  ADD_BRAND_FILTER,
  ADD_SELLER_ID,
  ADD_STORE_ID,
  ADD_PRICE_RANGE_FILTER,
  SET_PINCODE,
  RESET_CATEGORIES_FILTER,
  CLEAR_PRODUCT_LISTING_FILTER,
} from "./types";

export const addCategoryfilter = (data) => {
  return {
    type: ADD_CATEGORY_FILTER,
    data: data,
  };
};

export const addSubCategory00filter = (data) => {
  return {
    type: ADD_SUBCATEGORY_00_FILTER,
    data: data,
  };
};

export const addSubCategory01filter = (data) => {
  return {
    type: ADD_SUBCATEGORY_01_FILTER,
    data: data,
  };
};

export const addSubCategory02filter = (data) => {
  return {
    type: ADD_SUBCATEGORY_02_FILTER,
    data: data,
  };
};

export const addSubCategory03filter = (data) => {
  return {
    type: ADD_SUBCATEGORY_03_FILTER,
    data: data,
  };
};

export const addBrandFilter = (data) => {
  return {
    type: ADD_BRAND_FILTER,
    data: data,
  };
};

export const addSellerId = (data) => {
  return {
    type: ADD_SELLER_ID,
    data: data,
  };
};

export const addStoreId = (data) => {
  return {
    type: ADD_STORE_ID,
    data: data,
  };
};

export const addSearchFilter = (data) => {
  return {
    type: ADD_SEARCH_FILTER,
    data: data,
  };
};

export const addPriceRangeFilter = (data) => {
  return {
    type: ADD_PRICE_RANGE_FILTER,
    data,
  };
};

export const setPincode = (data) => {
  return {
    type: SET_PINCODE,
    data,
  };
};

export const resetCategoriesFilter = () => {
  return {
    type: RESET_CATEGORIES_FILTER,
  };
};

export const clearProductListingFilter = () => {
  return {
    type: CLEAR_PRODUCT_LISTING_FILTER,
  };
};
