

import { Button, Input, notification, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { CheckoutCart, addUser } from "./quickCheckoutAPI";
import TaxInvoice from "../../Customer/Components/ManageOrders/Components/TaxInvoice/index";
import Cookies from "universal-cookie";
import AddAddress from "../../Customer/Components/Cart/addAddress";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ThermalIndex from "../../Customer/Components/ManageOrders/Components/ThermalPrinter/ThermalIndex";
const confirm = Modal.confirm;
const { TextArea } = Input;
const ShippingInvoice = (props) => {
  const cookies = new Cookies();
  const [isLoading, setisLoading] = useState(false);
  const [address, setaddress] = useState("");
  const [list, setlist] = useState([]);
  const { setOutOfStockList, setLowStockList } = props;
  // const [orderId, setorderId] = useState("");
  const [invoiceVisible, setinvoiceVisible] = useState(false);
  const [addressModalVisible, setaddressModalVisible] = useState(false);
  // const [invoiceGenerated, setinvoiceGenerated] = useState(false);
  const [addressAdded, setaddressAdded] = useState(false);
  const [thermalPrinter, setThermalPrinter] = useState(false);
  const [invoicePrinter, setInvoicePrinter] = useState(false);

  useEffect(() => {
    setaddress(props.data.customerAddress);
    if (props?.data?.customerAddress) {
      setaddressAdded(true);
      props.setpincode(props.data?.customerAddress?.pinCode);
    } else {
      setaddressAdded(false);
      props.setpincode();
    }
    //findPrinterType();
  }, [props.data]);

  //* For finding Printer type (Thermal or Normal)
  const findPrinterType = () => {
    let printerType = cookies.get("printerType");
    if (printerType === undefined) {
      setInvoicePrinter(true);
    } else if (printerType === "normalInvoicePrinter") {
      setInvoicePrinter(true);
    } else {
      setThermalPrinter(true);
    }
  };

  const setShippingAddress = (address) => {
    const defaultAddress = {
      addressType: "",
      emailId: null,
      phoneNo: "",
      buildingName: "",
      ownerName: "",
      pinCode: "",
      district: "",
      addressLine1: "",
      addressLine2: null,
      id: "",
      alternatePhoneNo: null,
      state: "",
      primary: null,
    };

    var savedAddress = address ? address : defaultAddress;
    setaddress(savedAddress);
    handleShippingAddress(savedAddress);
  };

  const handleShippingAddress = (address) => {
    const payload = {
      referralId: props.data?.referralId,
      customerName: props.data?.customerName,
      checkoutCartId: props.data.checkoutCartId,
      customerAddress: address,
      customerId: props.customerId ? props.customerId : "",
    };

    addUser(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setaddressModalVisible(false);
          setaddressAdded(true);
          props.setpincode(
            response.data.quickCheckoutCart.customerAddress.pinCode
          );
        }
      }
    });
  };

  const handleCheckInvoice = async (PRINT_TYPE) => {
    console.log(
      props.data.quickCheckoutCartItems,
      ": quick checkout cart items"
    );

    const foundZeroProduct = await props.data.quickCheckoutCartItems.find(
      (data) => {
        return data.addedQuantity === 0;
      }
    );
    if (foundZeroProduct) {
      notification["error"]({
        message: "Failed",
        description: "Some Products in the order are of zero quantity.",
      });
    } else if (!props.data.quickCheckoutCartItems.length > 0) {
      notification["error"]({
        message: "Failed",
        description: "Add Product to cart",
      });
    } else if (!props.customerId) {
      notification["error"]({
        message: "Failed",
        description: "Please add a customer to place order.",
      });
    } else if (
      props.data.quickCheckoutCartItems.length > 0 &&
      props.customerId
    ) {
      if (PRINT_TYPE === "NORMAL_PRINT") {
        setInvoicePrinter(true)
      } else {
        setThermalPrinter(true)
      }
      renderConfirmModal();
    }
  };

  const handlePrintInvoice = () => {
    if (!cookies.get("vendorId")) {
      notification["error"]({
        message: "VendorId Missing",
        description:
          "It seems like you cleared your cookies, Don't worry just Logout and Login Again.",
      });
      return;
    }
    const payload = {
      accountType: "customer",
      paymentMode: "COD",
      customerId: props.customerId,
      deliveryCharge: props.deliveryCharge,
      checkoutCartId: props.data.checkoutCartId,
      vendorId: cookies.get("vendorId"),
      appType: "quickCheckout",
    };
    setisLoading(true);
    CheckoutCart(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setlist(response.data.order);
            setinvoiceVisible(true);
          }
          if (response.data.status === "failed") {
            setOutOfStockList(response?.data?.cartItemIds);
            setLowStockList(response?.data?.lowStockIds);
            notification["error"]({
              message: "Failed",
              description:
                response.data.message === "Out of stock!!"
                  ? "Some of the items in your order are out of stock."
                  : response.data.message,
            });
          }
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const renderConfirmModal = () => {
    Modal.confirm({
      title: "Confirm",
      id: "genrate-invoice-modal",
      icon: <ExclamationCircleOutlined />,
      content:
        "Are you sure you want to confirm the order? Once confirmed this cannot be updated later",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handlePrintInvoice(),
    });
  };
  // const handleViewInvoice = () => {
  //   setinvoiceGenerated(true);
  //   ViewInvoice(orderId).then((response) => {
  //     if (response.status === 200) {
  //       setlist(response.data.order);
  //       setorderId(response.data.order.orderGroupId);
  //       setinvoiceVisible(true);
  //     }
  //   });
  // };

  return (
    <div className="bottom-container">
      <div>
        {address ? (
          <div>
            <h3>Shipping Address</h3>
            <div className="shipping-address">
              <h4>Name </h4>
              <h4>: {address.ownerName}</h4>
              <h4>Address </h4>
              <h4>
                : {address.addressType}, {address.addressLine1},
                {address.addressLine2}, {address.district},{address.state} -
                {address.pinCode}
              </h4>
              <h4>Phone </h4>
              <h4>: {address.phoneNo}</h4>
              <h4>Email </h4>

              <h4>: {address.emailId}</h4>
            </div>
          </div>
        ) : (
          ""
        )}
        <Button type="primary" onClick={() => setaddressModalVisible(true)}>
          {addressAdded ? "Edit Address" : "Add Shipping Address"}
        </Button>
      </div>
      <div className="generate-invoice">
        {/* {!orderId && ( */}
        <Spin spinning={isLoading}>
          <Button
            className="print-button"
            onClick={() => {
              handleCheckInvoice("NORMAL_PRINT");
            }}
          >
            INVOICE PRINT
          </Button>
          <h2>{" "}</h2>
          <Button
            className="print-button"
            onClick={() => {
              handleCheckInvoice("THERMAL_PRINT");
            }}
          >
            THERMAL PRINT
          </Button>
        </Spin>
        {/* )} */}
        {/* {orderId && (
          <>
            <h4 style={{ color: "blue", textAlign: "center" }}>
              Order Generated
            </h4>
            <Button className="print-button" onClick={handleViewInvoice}>
              View Invoice
            </Button>
          </>
        )} */}
      </div>
      {invoiceVisible && invoicePrinter && (
        <TaxInvoice
          visible={invoiceVisible}
          setvisible={setinvoiceVisible}
          orderSummary={list}
          quickCheckout={true}
          handleRemove={props.handleRemove}
          pane={props.pane}
        />
      )}
      {invoiceVisible && thermalPrinter && (
        <ThermalIndex
          orderSummary={list}
          setvisible={setinvoiceVisible}
          quickCheckout={true}
          handleRemove={props.handleRemove}
          pane={props.pane}
        />
      )}

      {addressModalVisible &&
        (addressAdded ? (
          <AddAddress
            visible={addressModalVisible}
            setVisible={setaddressModalVisible}
            setaddress={setShippingAddress}
            editItem={address}
            quickCheckout={true}
            isEdit
          />
        ) : (
          <AddAddress
            visible={addressModalVisible}
            setVisible={setaddressModalVisible}
            handlePrintInvoice
            setaddress={setShippingAddress}
            quickCheckout={true}
          />
        ))}
    </div>
  );
};

export default ShippingInvoice;
