import { SafetyCertificateFilled } from "@ant-design/icons";
import { Divider, Tabs } from "antd";
import React from "react";
import { useState } from "react";
import ManagePlatformTable from "./ManagePlatformTable";
import PlatformFilters from "./PlatformFilters";
import Cookies from "universal-cookie";

function PlatformIndex(props) {
  const {vendorType} = props;

  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [data, setData] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const [statusKey, setStatusKey] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadType] = useState("admin");
  const [placeholder] = useState("Search here (Eg: Nadathara)");
  const [manageAccountsData, setManageAccountsData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);
  const cookies = new Cookies();
  const [selectedVendorId, setSelectedVendorId] = useState(cookies.get("vendorId"));

  


  const [activeTab, setActiveTab] = useState(
    vendorType === "SELLER" ? "1" : "3"
  );

  const { TabPane } = Tabs;
  const handleActiveTab = (activeTab) => {
    console.log("Current Tab :: ", activeTab);
    setActiveTab(activeTab);
  };

  return (
    <div className="manage-accounts">
      <div className="manage-top-part">
        <h2 style={{ fontWeight: "bold" }}>
          <SafetyCertificateFilled /> Platform Charge Recovery
        </h2>
        <h4 style={{ color: "rgba(0,0,0,0.5)" }}>
          All Transactions related to Platform Charge can be performed here.
        </h4>
        {/* This Popover will display information about this Service */}
      </div>
      <Divider />
      <PlatformFilters
        downloadType={downloadType}
        placeHolder={placeholder}
        setSearchVal={setSearchVal}
        setStatusKey={setStatusKey}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        searchVal={searchVal}
        startTime={startTime}
        endTime={endTime}
        statusKey={statusKey}
        totalDoc={totalCount}
        setOffset={setOffset}
        setCurrentPage={setCurrentPage}
        offset={offset}
        vendorType={vendorType}
        showVendorNameFilter={false}
      />

      {vendorType === "SELLER" ? (
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={activeTab}
          onChange={handleActiveTab}
        >
          <TabPane tab="Open" key="1">
            <ManagePlatformTable
              vendorType={vendorType}
              requestStatus="OPEN"
              startTime={startTime}
              endTime={endTime}
              searchVal={searchVal}
              activeTab={activeTab}
              vendorId={selectedVendorId}
              keys="1"
            />
          </TabPane>
          <TabPane tab="Paid" key="7">
            <ManagePlatformTable
              vendorType={vendorType}
              requestStatus="PAID"
              startTime={startTime}
              endTime={endTime}
              searchVal={searchVal}
              activeTab={activeTab}
              vendorId={selectedVendorId}
              keys="7"
            />
          </TabPane>
        </Tabs>
      ) : (
        <Tabs
          activeKey={activeTab}
          defaultActiveKey={activeTab}
          onChange={handleActiveTab}
        >
          <TabPane tab="Open" key="2">
            <ManagePlatformTable
              vendorType={vendorType}
              requestStatus="OPEN"
              startTime={startTime}
              endTime={endTime}
              searchVal={searchVal}
              activeTab={activeTab}
              vendorId={selectedVendorId}
              setSelectedVendorId={setSelectedVendorId}
              keys="2"
            />
          </TabPane>
          <TabPane tab="Invoiced" key="3">
            <ManagePlatformTable
              vendorType={vendorType}
              requestStatus="INVOICED"
              startTime={startTime}
              endTime={endTime}
              searchVal={searchVal}
              activeTab={activeTab}
              vendorId={selectedVendorId}
              keys="3"
            />
          </TabPane>
          <TabPane tab="Processing" key="5">
            <ManagePlatformTable
              vendorType={vendorType}
              requestStatus="PROCESSING"
              startTime={startTime}
              endTime={endTime}
              searchVal={searchVal}
              activeTab={activeTab}
              vendorId={selectedVendorId}
              keys="5"
            />
          </TabPane>
          <TabPane tab="Paid" key="6">
            <ManagePlatformTable
              vendorType={vendorType}
              requestStatus="PAID"
              startTime={startTime}
              endTime={endTime}
              searchVal={searchVal}
              activeTab={activeTab}
              vendorId={selectedVendorId}
              keys="6"
            />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
}

export default PlatformIndex;
