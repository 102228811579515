


//userType can be either customer/seller      as on august 18th
//modaltype can be either login/signup        as in august 18th

import {
  OPEN_LOGIN_MODAL,
  OPEN_SIGNUP_MODAL,
  CLOSE_AUTH_MODAL,
  ADD_REFERRAL_ID,
  ADD_AFFILIATE_ID,
} from "../action/types";

export default function authModalReducer(
  state = { userType: "", modalType: "", referralId: "", affliateId: "" },
  action
) {
  switch (action.type) {
    case OPEN_LOGIN_MODAL:
      state = {
        userType: action.data,
        modalType: "login",
      };
      break;
    case OPEN_SIGNUP_MODAL:
      state = { userType: action.data, modalType: "signup" };
      break;

    case CLOSE_AUTH_MODAL:
      state = { userType: "", modalType: "" };
      break;
    case ADD_REFERRAL_ID:
      state = { ...state, referralId: action.data };
      break;
    case ADD_AFFILIATE_ID:
      state = { ...state, affliateId: action.data };
      break;

    default:
      // console.log("no changes");
  }

  return state;
}
