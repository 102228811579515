

import React, { useEffect, useState, Fragment } from "react";
import {
  Checkbox,
  Upload,
  message,
  Button,
  Input,
  Row,
  Col,
  Select,
  Radio,
  Modal,
  Divider,
  Form,
} from "antd";
import ReactPlayer from "react-player";
import { withRouter } from "react-router-dom";
import DeleteIcon from "../../../../Assets/Images/Icons/Seller/delete.svg";
import "./bulkupload.css";
import Loading from "../../../GeneralComponents/Loading";
import Carousel from "react-multi-carousel";
import ServiceLocationModal from "./serviceLocationModal";
import AddProductCategory from "./addProductCategory";
import SellerTags from "./sellerTags";
import { responsive } from "../../../../Utils/Seller/Data";
import {
  addSellerProduct,
  addProductImage,
  getEditProducts,
  updateSellerProduct,
  productImageRemove,
} from "../../../../ApiAction/SellerApiActions/sellerApi";
import { FILE_PATH } from "../../../../Utils/constants";
import {
  addProductToCentralRepo,
  addProductImageInCentralRepo,
  fetchProductDetailFromCentralRepo,
  updateProductInCentralRepo,
  removeCentralRepoProductImage,
} from "../../../../ApiAction/AdminApiAction/centralRepoApi";
import ProductAuctoComplete from "./productAuctoComplete";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const { TextArea } = Input;
const confirm = Modal.confirm;
const { Dragger } = Upload;
const { Option } = Select;

const AddProduct = (props) => {
  const [form] = Form.useForm();
  const activeCustomer = JSON.parse(localStorage.getItem("activeCustomer"));

  const { isCentralRepo, isAdmin } = props;
  const [uploading, setUploading] = useState(false);
  const [newImgUrl, setNewImgUrl] = useState([]);
  const [videoUrl, setVideoUrl] = useState("");
  const [loadingAddEditApi, setloadingAddEditApi] = useState(false);

  const [dcBody, setDcBody] = useState("");
  const [dcWithinDistrict, setDcWithinDistrict] = useState("");
  const [dcWithinState, setDcWithinState] = useState("");
  const [dcOutofState, setDcOutofState] = useState("");
  const [returnPeriod, setReturnPeriod] = useState("");
  const [affiliateCommission, setAffiliateCommission] = useState(0);

  const [tax, setTax] = useState("");
  const [hsn, setHsn] = useState("");

  const [productOverview, setProductOverview] = useState("");
  const [productName, setProductName] = useState("");

  const [tagValues, setTagValues] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [serviceLocations, setServiceLocations] = useState([]);
  const [serviceLocationsObject, setServiceLocationsObject] = useState();
  const [specifications, setSpecifications] = useState([]);
  const [specificationsList, setSpecificationsList] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [subCategory0Id, setSubCategory0Id] = useState("");
  const [subCategory1Id, setSubCategory1Id] = useState("");
  const [subCategory2Id, setSubCategory2Id] = useState("");
  const [subCategory3Id, setSubCategory3Id] = useState("");

  const [brandId, setBrandId] = useState("");
  const [version, setVersion] = useState(0);

  const [categoryCommission, setcategoryCommission] = useState();
  const [minCategoryDiscount, setminCategoryDiscount] = useState();

  const [categoryName, setCategoryName] = useState("");
  const [subCategory0Name, setSubCategory0Name] = useState("");
  const [subCategory1Name, setSubCategory1Name] = useState("");
  const [subCategory2Name, setSubCategory2Name] = useState("");
  const [subCategory3Name, setSubCategory3Name] = useState("");
  const [brandName, setBrandName] = useState("");

  const [codEnable, setCodEnable] = useState("Yes");
  const [verified, setVerified] = useState(false);
  const [freeDelivery, setFreeDelivery] = useState("No");
  const [serviceModal, setServiceModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const [isMasterOld, setMasterOld] = useState(true);
  const [masterImage, setMasterImage] = useState("");

  const [editImages, setEditImages] = useState([]);
  const [centralRepoProductId, setcentralRepoProductId] = useState();
  const [disableEditCentralData, setdisableEditCentralData] = useState(false);

  const [taxlist, setTaxlist] = useState([
    "0",
    // "0.25",
    // "1.5",
    "3",
    "5",
    "12",
    // "15",
    "18",
    "28",
  ]);

  //return period days constant
  const returnPeriodDay = ["0","1","2","3","4","5","6","7"];

  const [productAddedType, setProductAddedType] = useState();

  const [isSeller] = useState(cookies.get('accountType') === 'seller' ? true : false);

  //For finding addedType
  useEffect(() => {
    if (cookies.get("accountType")) {
      setProductAddedType(cookies.get("accountType"))
    }
  },[])


  useEffect(() => {

  }, [subCategory3Name, subCategory3Id]);

  const onImageChange = {
    accept:
      "image/png, image/jpeg,image/webp, image/jpg, video/mp4, video/mp3, video/mov, video/avi",
    name: "file",
    multiple: false,
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload(info) {
      const isLt2M = info.size / 1024 / 1024 < 4;
      const isLessThan = info.size / 1000;
      // if (isLessThan < 100) {
      //   message.error("File size should be greater than 100 KB");
      //   setUploading(false);
      //   return false;
      // }
      // if (false) {
      //   message.error("File size should be less than 4 MB");
      //   setUploading(false);
      //   return false;
      // } else
      if (
        !(
          info.type === "image/png" ||
          info.type === "image/jpeg" ||
          info.type === "image/jpg" ||
          info.type === "image/webp" ||
          info.type === "video/mp4" ||
          info.type === "video/mp3" ||
          info.type === "video/mov" ||
          info.type === "video/avi"
        )
      ) {
        message.error("Please refer guidelines to find allowed formats.");
        setUploading(false);
        return false;
      } else if (newImgUrl.length > 9) {
        message.error("You can upload only maximum 10 images");
        setUploading(false);
        return false;
      }
    },
    onChange(info) {
      if (info.file.status === "done") {
        setUploading(false);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setNewImgUrl([
            ...newImgUrl,
            {
              url: reader.result,
              file: info.file.originFileObj,
              master: false,
            },
          ]);
        });
        reader.readAsDataURL(info.file.originFileObj);
        return;
      }
    },
  };

  const handleClearFields = () => {
    setCodEnable(isSeller ? 'No' : 'Yes');
    setVideoUrl();
    setProductName();
    setProductOverview();
    setTagValues();
    setCategoryId();
    setSubCategory0Id();
    setSubCategory1Id();
    setSubCategory2Id();
    setSubCategory3Id();
    setBrandId();
    setSpecificationsList();
    setServiceLocations();
    setServiceLocationsObject();

    setCategoryName();
    setSubCategory0Name();
    setSubCategory1Name();
    setSubCategory2Name();
    setSubCategory3Name();
    setBrandName();

    setVersion();
    setEditImages();
    setNewImgUrl([]);
    setcentralRepoProductId();
    setdisableEditCentralData(false);

    setminCategoryDiscount();
    setcategoryCommission();
  };

  const handleInitialiseProductFromCentralRepo = async (
    productId,
    forseller
  ) => {
    fetchProductDetailFromCentralRepo(productId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            const productDetails = response.data.product.source;
            setCodEnable(productDetails?.isCODEnabled ? "Yes" : "No");
            setVideoUrl(productDetails.videoURL);
            setProductName(productDetails.name);
            setProductOverview(productDetails.overview);
            setTax(productDetails.taxPerCent);
            setHsn(productDetails.hsnCode);
            setTagValues(productDetails.tags);
            setCategoryId(productDetails.categoryId);
            setSubCategory0Id(
              productDetails.subCategory0Id && productDetails.subCategory0Id
            );
            setSubCategory1Id(productDetails.subCategory1Id);
            setSubCategory2Id(productDetails.subCategory2Id);
            setSubCategory3Id(productDetails.subCategory3Id);
            setBrandId(productDetails.brandId);
            setSpecificationsList(productDetails.specifications);
            setServiceLocations(productDetails.serviceLocations);
            setServiceLocationsObject(productDetails.serviceLocationRequest);

            setCategoryName(productDetails.categoryName);
            setSubCategory0Name(
              productDetails.subCategory0Name && productDetails.subCategory0Name
            );
            setSubCategory1Name(productDetails.subCategory1Name);
            setSubCategory2Name(productDetails.subCategory2Name);
            setSubCategory3Name(productDetails.subCategory3Name);
            setBrandName(productDetails.brandName);
            setVerified(productDetails.verified? productDetails.verified : false);

            setAffiliateCommission(
              productDetails?.affiliateCommission
                ? productDetails.affiliateCommission
                : 0
            );
            setcategoryCommission(
              productDetails?.categoryCommission
                ? productDetails.categoryCommission
                : 0
            );
            setminCategoryDiscount(
              productDetails?.minCategoryDiscount
                ? productDetails.minCategoryDiscount
                : 0
            );

            setVersion(response.data.product.version);
            setEditImages(productDetails.images);
            forseller && setcentralRepoProductId(productDetails.productId);
            forseller && setdisableEditCentralData(true);

            form.setFieldsValue({
              videoUrl: productDetails.videoURL,
              hsn: productDetails.hsnCode,
              tax: productDetails?.taxPerCent,
              productName: productDetails.name,
              affiliateCommission: productDetails.affiliateCommission,
            });

            if (productDetails.images) {
              const imgObject = productDetails.images.map((item) => {
                const single = {
                  url: FILE_PATH + item,
                  file: "",
                  master: false,
                  isEdit: true,
                };
                return single;
              });
              setNewImgUrl(imgObject);
            }
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  useEffect(() => {
    if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
      setCollapsed(true);
    } else setCollapsed(false);

    if (props.isEdit) {
      if (isCentralRepo) {
        handleInitialiseProductFromCentralRepo(props.match.params.productId);
      } else {
        setdisableEditCentralData(false);

        getEditProducts(props.match.params.productId)
          .then((res) => {
            if (res.data.status == "success") {
              const details = res.data.product.source;
              console.log("PRINTING RESPONSE DATA :: ")
              console.log(details.centralRepoProductId)
              setdisableEditCentralData(
                details.centralRepoProductId ? true : false
              );
              isAdmin && setdisableEditCentralData(false)
              setCodEnable(details.isCODEnabled ? "Yes" : "No");
              setVideoUrl(details.videoURL);
              setProductName(details.name);
              setProductOverview(details.overview);
              setTax(details.taxPerCent);
              setHsn(details.hsnCode);
              setTagValues(details.tags);
              setCategoryId(details.categoryId);
              setSubCategory0Id(
                details.subCategory0Id && details.subCategory0Id
              );
              setSubCategory1Id(details.subCategory1Id);
              setSubCategory2Id(details.subCategory2Id);
              setSubCategory3Id(details.subCategory3Id);
              setBrandId(details.brandId);
              setSpecificationsList(details.specifications);
              setServiceLocations(details.serviceLocations);
              setServiceLocationsObject(details.serviceLocationRequest);

              setCategoryName(details.categoryName);
              setSubCategory0Name(
                details.subCategory0Name && details.subCategory0Name
              );
              setSubCategory1Name(details.subCategory1Name);
              setSubCategory2Name(details.subCategory2Name);
              setSubCategory3Name(details.subCategory3Name);
              setBrandName(details.brandName);

              setVersion(res.data.product.version);
              setReturnPeriod(details.returnPeriod);
              setAffiliateCommission(
                details?.affiliateCommission ? details.affiliateCommission : 0
              );
              setcategoryCommission(
                details?.categoryCommission ? details.categoryCommission : 0
              );
              setminCategoryDiscount(
                details?.minCategoryDiscount ? details.minCategoryDiscount : 0
              );
              form.setFieldsValue({
                videoUrl: details.videoURL,
                hsn: details.hsnCode,
                tax: details?.taxPerCent,
                productName: details.name,
                retunPeriod: details.returnPeriod,
                affiliateCommission: details.affiliateCommission,
              });

              setEditImages(details.images);
              if (details.images) {
                const imgObject = details.images.map((item) => {
                  const single = {
                    url: FILE_PATH + item,
                    file: "",
                    master: false,
                    isEdit: true,
                  };
                  return single;
                });
                setNewImgUrl(imgObject);
              }
            }
          })
          .catch((error) => {
            if (error?.response) {
              message.error(error.response.message);
            }
          })
          .finally(() => {});
      }
    }
  }, []);

  const handleChange = (value) => {
    setTagValues(value);
  };
  const selectMaster = (item) => {
    if (item.file === "") setMasterOld(true);
    else setMasterOld(false);
    setMasterImage(item.url);
    setNewImgUrl(
      newImgUrl.map((o) => {
        if (o.url === item.url) return { ...o, master: true };
        return { ...o, master: false };
      })
    );
  };

  const handleDeleteImage = (item) => {
    confirm({
      title: "Do you want to delete this product image!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        const payload = {
          productId: props.match.params.productId,
          version: version,
          images: editImages.filter(
            (itemFilter) => itemFilter != item.replace(FILE_PATH, "")
          ),
          image: item.replace(FILE_PATH, ""),
          type: "image",
        };
        isCentralRepo
          ? handleDeleteImageFromCentralRepo(payload, item)
          : productImageRemove(payload)
              .then((res) => {
                setNewImgUrl(
                  newImgUrl.filter((itemFilter) => itemFilter.url !== item)
                );
              })
              .catch((error) => {
                if (error?.response) {
                  message.error(error.response.message);
                }
              })
              .finally(() => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleDeleteImageFromCentralRepo = (payload, item) => {
    removeCentralRepoProductImage(payload)
      .then((response) => {
        setNewImgUrl(newImgUrl.filter((itemFilter) => itemFilter.url !== item));
      })
      .catch((error) => {
        error?.response?.message && message.error(error.response.message);
      });
  };

  const sendProductMedia = async (productId, newImageArray = newImgUrl) => {
    let success = true;
    if (productId) {
      if (newImageArray) {
        if (isCentralRepo) {
          success = await handleAddProductimagetoCentralRepo(
            productId,
            newImageArray
          );
        } else {
          for(let i = 0; i < newImageArray.length; i++) {
            let item = newImageArray[i];
            await addProductImage({
              productId: productId,
              file: item.file,
              master: item.url === masterImage ? "true" : "false",
              accountType: cookies.get("switchuser") == "true"? "admin":
                cookies.get("accountType")
            })
              .then((res) => {
                if (res.data.status == "success") {
                  success = true;
                } else {
                  success = false;
                  message.error(res.data.message);
                }
              })
              .catch((error) => {
                if (error?.response) {
                  message.error(error.response.message);
                }
              })
              .finally(() => {});
          }
        }
      }
    }
    return success;
  };

  const handleAddProductimagetoCentralRepo = async (productId, newImageArray) => {
    let res = true;
    for(let i = 0; i < newImageArray.length; i++) {
      let image = newImageArray[i];
      let payload = {
        productId: productId,
        file: image.file,
        master: image.url === masterImage ? "true" : "false",
        accountType: cookies.get("switchuser")? "admin":
          cookies.get("accountType")
      };
      await addProductImageInCentralRepo(payload)
        .then((response) => {
          if (response?.data?.status === "success") {
          } else {
            res = false;
            message.error(response.data.message);
          }
        })
        .catch(() => {
          res = false;
        });
    }
    return res;
  };

  const handleAddProduct = () => {
    isCentralRepo ? handleAddProductToCentralRepo() : handleAddVendorProduct();
  };

  const handleAddProductToCentralRepo = async () => {
    if (productName === "") {
      message.error("please enter name");
      return;
    }
    if (tagValues?.length < 5) {
      message.error("please inlcude a minimum of 5 tags");
      return;
    }
    if (tax === "") {
      message.error("please enter tax");
      return;
    }
    if (categoryName === "" || subCategory0Name === "" || subCategory1Name === "") {
      message.error("Type, Category and SubCategories are Required");
      return;
    }
    const payload = {
      brandId: brandId,
      categoryId: categoryId,
      subCategory0Id: subCategory0Id,
      subCategory1Id: subCategory1Id,
      subCategory2Id: subCategory2Id,
      subCategory3Id: subCategory3Id,

      brandName: brandName,
      categoryName: categoryName,
      subCategory0Name: subCategory0Name,
      subCategory1Name: subCategory1Name,
      subCategory2Name: subCategory2Name,
      subCategory3Name: subCategory3Name,

      tags: tagValues,
      name: productName,
      overview: productOverview,
      videoURL: videoUrl,
      specifications: specificationsList,

      affiliateCommission: affiliateCommission,
      categoryCommission: categoryCommission ? categoryCommission : 0,
      minCategoryDiscount: minCategoryDiscount ? minCategoryDiscount : 0,
      hsnCode: hsn,
      taxPerCent: Number(tax),
      addedType: productAddedType,
    };

    if (props.isEdit) {
      if (isMasterOld && masterImage != "") {
        payload["masterImage"] = masterImage.replace(FILE_PATH, "");
        payload["images"] = editImages;
      };
      const payloadEdit = await {
        id: props.match.params.productId,
        product: payload,
      };
      setloadingAddEditApi(true);
      updateProductInCentralRepo(payloadEdit)
        .then(async (response) => {
          if (response?.data?.status === "success") {
            const updateImage = newImgUrl.filter((item) => item.file != "");
            if (updateImage.length > 0) {
              const success = await sendProductMedia(
                props.match.params.productId,
                updateImage
              );
              if (success) {
                message.success("Product updated successfully");
                props.history.goBack();
              }
            } else {
              message.success("Product updated successfully");
              props.history.goBack();
            }
          } else {
            if (response.data.message == "product registration failed") {
              message.error("Duplicate product name");
            } else {
              message.error("Product could not be added");
            }
          }
        })
        .catch((error) => {
          if (error?.response) {
            message.error(error.response.message);
          }
        })
        .finally(() => {
          setloadingAddEditApi(false);
        });
    } else {
      setloadingAddEditApi(true);
      const productId = await addProductToCentralRepo(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              message.success(response.data.message);
              return response.data.product.id;
            } else {
              message.error(response.data.message);
            }
          } else {
            message.error("unable to add product please try again.");
          }
        })
        .catch(() => {})
        .finally(() => {
          setloadingAddEditApi(false);
        });

      const imageUploaded = await sendProductMedia(productId);
      if (imageUploaded) {
        message.success("Product uploaded succesfully");
        props.history.push("/admin/central-repo");
      } else
        message.success(
          "product uploaded succesfuly , please try adding images later"
        );
    }
  };

  const handleAddVendorProduct = () => {
    if (productName === "") {
      message.error("please enter name");
      return;
    }
    if (tagValues?.length < 5) {
      message.error("please inlcude a minimum of 5 tags");
      return;
    }
    if (categoryName === "" || subCategory0Name === "" || subCategory1Name === "") {
      message.error("Type, Category and SubCategories are Required");
      return;
    }

    const payload = {
      isCODEnabled: codEnable === "Yes" && isSeller === false ? true : false,
      brandId: brandId,
      categoryId: categoryId,
      subCategory0Id: subCategory0Id,
      subCategory1Id: subCategory1Id,
      subCategory2Id: subCategory2Id,
      subCategory3Id: subCategory3Id,

      brandName: brandName,
      categoryName: categoryName,
      subCategory0Name: subCategory0Name,
      subCategory1Name: subCategory1Name,
      subCategory2Name: subCategory2Name,
      subCategory3Name: subCategory3Name,

      tags: tagValues,
      name: productName,
      overview: productOverview,
      videoURL: videoUrl,
      specifications: specificationsList,
      verified: verified,
      returnPeriod: Number(returnPeriod),
      affiliateCommission: 0,

      categoryCommission: categoryCommission ? categoryCommission : 0,
      minCategoryDiscount: minCategoryDiscount ? minCategoryDiscount : 0,

      hsnCode: hsn,
      taxPerCent: Number(tax),
      addedType: productAddedType,
      // images: newImgUrl,
    };
    if (cookies.get("accountType") === "seller") {
      payload["serviceLocations"] = serviceLocations;
      payload["serviceLocationRequest"] = serviceLocationsObject;
    }
    if(activeCustomer?.accountType == "admin") {
      payload["affiliateCommission"] = Number(affiliateCommission);
    }

    if (props.isEdit) {
      if (isMasterOld && masterImage != "") {
        payload["masterImage"] = masterImage.replace(FILE_PATH, "");
        payload["images"] = editImages;
      }
      const payloadEdit = {
        id: props.match.params.productId,
        product: payload,
      };
      setloadingAddEditApi(true);
      updateSellerProduct(payloadEdit)
        .then(async (res) => {
          if (res.data.status == "success") {
            const updateImage = newImgUrl.filter((item) => item.file != "");
            if (updateImage.length > 0) {
              const success = await sendProductMedia(
                props.match.params.productId,
                updateImage
              );
              if (success) {
                message.success("Product updated successfully");
                props.history.goBack();
              }
            } else {
              message.success("Product updated successfully");
              props.history.goBack();console.log("else")
            }
          } else {
            if (res.data.message == "product registration failed") {
              message.error("Duplicate product name");
            } else {
              message.error("Product could not be added");
            }
            if(res.data.message == "invalid gst number")
              message.error("Update GST number")
          }
        })
        .catch((error) => {
          if (error?.response) {
            message.error(error.response.message);
          }
        })
        .finally(() => {
          setloadingAddEditApi(false);
        });
    } else {
      if (centralRepoProductId) {
        //if the product is added from central repo include centralRepoProductId and images from central repo
        payload["centralRepoProductId"] = centralRepoProductId;
        payload["images"] = editImages;
      }
      setloadingAddEditApi(true);
      addSellerProduct(payload)
        .then(async (res) => {
          if (res.data.status == "success") {
            const success = await sendProductMedia(
              res.data.documents.source.productId
            );
            if (success) {
              message.success("Product added successfully");
              props.history.push(`${"manage-products"}`);
            }
          } else {
            if (res.data.message == "product registration failed") {
              message.error("Duplicate product name");
            } else if(res.data.message == "Product present in central repo") {
              message.error("Please add product from central repo");
            } else {
              message.error("Product could not be added");
            }
            if(res.data.message == "invalid gst number")
              message.error("Update GST number")
          }
        })
        .catch(() => {})
        .finally(() => {
          setloadingAddEditApi(false);
        });
    }
  };

  const StoreNameValidator = (rule, value, callback) => {
    if (value && !value.match(/^[a-zA-Z0-9 -]+$/)) {
      callback("Special Character not allowed");
    } else {
      callback();
    }
  };

  const ProductNameValidator = (rule, value, callback) => {
    if (value && !value.match(/^[\w\s./+()&'%:-@]+$/)) {
      callback("Special Character not allowed");
    } else {
      callback();
    }
  };

  const NumberValidator = (rule, value, callback) => {
    if (value && !value.match(/^[0-9]+$/)) {
      callback("Alphabets/Special Character not allowed");
    } else {
      callback();
    }
  };

  const positiveNumberValidator = (rule, value, callback) => {
    if (value && value <= 0) {
      callback("Return period should be a positive value");
    } else {
      callback();
    }
  };

  const percentageValidator = (rule, value, callback) => {
    if (value !== undefined && (Number(value) > 100 || Number(value) < 0)) {
      callback("Plese enter valid affiliate percentage");
    } else {
      callback();
    }
  };

  return (
    <div className="add-product-container">
      <div className="add-product-upload-image">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="add-image-col"
          >
            <Dragger
              {...onImageChange}
              className="dragger-add-product"
              showUploadList={false}
            >
              {uploading ? (
                <Loading />
              ) : (
                <div>
                  <h5>Drag and drop files here</h5>
                  <Button type="primary">Browse</Button>
                </div>
              )}
            </Dragger>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="add-product-upload-guidelines">
              <div style={{ fontWeight: "600", marginBottom: "10px" }}>
                Upload Guidelines
              </div>
              <ul>
                <li>Only JPEG/JPG/PNG/WEBP images are supported</li>
                <li>Minimum image size should be 100kb</li>
                {/* <li>Images must be of size 640x480 (wxh)</li> */}
                <li>Only MP3/MP4/MOV/AVI videos are supported</li>
                <li>Maximum image size should be 25MB</li>
                <li>Image with White background.</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>

      {newImgUrl && (
        <div className="add-product-preview">
          <Carousel
            responsive={responsive}
            className="carousel-container-addimage"
          >
            {newImgUrl ? (
              newImgUrl.map((item, index) => {
                return (
                  <div key={index} className="add-product-image">
                    <img
                      src={item.url}
                      alt="company"
                      className="image-add-product"
                    />

                    <Checkbox
                      className="addproduct-carousel-checkbox"
                      onChange={() => selectMaster(item)}
                      checked={masterImage ? item.master : index === 0}
                    />

                    {(cookies.get("accountType") == "admin" || cookies.get("switchuser") == "true") && (
                      <img
                        src={DeleteIcon}
                        alt="company"
                        onClick={() => {
                          if (item.file === "") handleDeleteImage(item.url);
                          else {
                            confirm({
                              title: "Do you want to delete this product image!!",
                              className: "create-confirm-modal",
                              centered: true,
                              cancelText: "No",
                              okText: "Yes",
                              onOk: () =>
                                setNewImgUrl(
                                  newImgUrl.filter(
                                    (itemFilter) => itemFilter.url !== item.url
                                  )
                                ),
                            });
                          }
                        }}
                        className="add-image-delete"
                      />
                      )
                    }
                  </div>
                );
              })
            ) : (
              <div />
            )}
          </Carousel>
          {newImgUrl.length > 1 && (
            <div className="master-image-preview">
              Select one as master image
            </div>
          )}
        </div>
      )}
      <Form form={form} onFinish={handleAddProduct}>
        <div className="add-product-section-content">
          <div className="add-product-section-title">Product Video</div>
          <div className="add-product-tile">
            Video Link
            <Form.Item
              name="videoUrl"
              rules={[
                {
                  type: "url",
                  message: "The input is not valid url!",
                },
              ]}
            >
              <Input
                value={videoUrl}
                type="url"
                onChange={(e) => {
                  setVideoUrl(e.target.value);
                }}
              />
            </Form.Item>
          </div>
          {videoUrl && (
            <ReactPlayer url={videoUrl} width="250px" height="100px" />
          )}
        </div>

        <div className="add-product-section-content">
          <div className="add-product-section-title">Product Detail</div>
          {!isCentralRepo && !props.isEdit && (
            <div className="add-product-from-suggestion">
              Search Product from Central repo
              <ProductAuctoComplete
                initialiseProductDetails={
                  handleInitialiseProductFromCentralRepo
                }
                onClear={handleClearFields}
              />
              <Divider>OR</Divider>
            </div>
          )}

          <div className="add-product-tile">
            Product Title
            <Form.Item
              name="productName"
              rules={[
                {
                  validator: ProductNameValidator,
                },
                {
                  required: true,
                  message: "Product Title is required",
                },
              ]}
            >
              <Input
                value={productName}
                disabled={disableEditCentralData}
                onChange={(e) => setProductName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="add-product-tile">
            Product Overview
            <TextArea
              rows={6}
              value={productOverview}
              onChange={(e) => setProductOverview(e.target.value)}
            />
          </div>
          <div className="add-product-tile">
            Tags
            <SellerTags setTagValues={setTagValues} tagValues={tagValues} />
            {tagValues?.length !== 5 && (
              <div className="minimum-tags">Enter minimum 5 Tags</div>
            )}
          </div>
        </div>

        <AddProductCategory
          categoryName={categoryName}
          subCategory0Name={subCategory0Name}
          subCategory1Name={subCategory1Name}
          subCategory2Name={subCategory2Name}
          subCategory3Name={subCategory3Name}
          brandName={brandName}
          setCategoryId={setCategoryId}
          setSubCategory0Id={setSubCategory0Id}
          setSubCategory1Id={setSubCategory1Id}
          setSubCategory2Id={setSubCategory2Id}
          setSubCategory3Id={setSubCategory3Id}
          setBrandId={setBrandId}
          setCategoryName={setCategoryName}
          setSubCategory0Name={setSubCategory0Name}
          setSubCategory1Name={setSubCategory1Name}
          setSubCategory2Name={setSubCategory2Name}
          setSubCategory3Name={setSubCategory3Name}
          setBrandName={setBrandName}
          setSpecificationsList={setSpecificationsList}
          specificationsList={specificationsList}
          disableEditCentralData={disableEditCentralData}
          setcategoryCommission={setcategoryCommission}
          setminCategoryDiscount={setminCategoryDiscount}
          formItem={<Form.Item></Form.Item>}
        />
        {!isCentralRepo && (
          <Fragment>
            <div className="flex">
              <Row style={{ width: "100%" }}>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    collapsed ? "add-product-mobile-col" : "add-image-col"
                  }
                >
                    <div className="add-product-section-content">
                      <div className="add-product-section-title">COD Option</div>
                      <div
                        className="add-product-tile"
                        style={{ paddingLeft: "10px" }}
                      >
                        COD Option available?
                        <Radio.Group
                          style={{ width: "100%", marginTop: "10px" }}
                          onChange={(e) => setCodEnable(e.target.value)}
                          value={isSeller ? "No" : codEnable}
                          disabled={isSeller ? true : false}
                        >
                          <Radio value={"Yes"}>Yes</Radio>
                          <Radio value={"No"}>No</Radio>
                        </Radio.Group>
                      </div>
                    </div>

                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className={
                    collapsed ? "add-product-mobile-col" : "add-image-col"
                  }
                >
                  <div className="add-product-section-content return_period_add_pdt">
                    <div className="add-product-section-title">
                      Return Period
                    </div>
                    <div className="flex">
                      {/* <div
                        className="add-product-tile"
                        style={{ paddingLeft: "10px" }}
                      >
                        Free Delivery
                        <Radio.Group
                          style={{ width: "100%", marginTop: "10px" }}
                          onChange={(e) => setFreeDelivery(e.target.value)}
                          value={freeDelivery}
                        >
                          <Radio value={"Yes"}>Yes</Radio>
                          <Radio value={"No"}>No</Radio>
                        </Radio.Group>
                      </div> */}

                      <div className="add-product-tile">
                        Return Period (No Of Days)
                        <Form.Item
                          // name="retunPeriod"
                          // rules={[
                          //   {
                          //     validator: positiveNumberValidator,
                          //   },
                          // ]}
                        >
                          {/* <Input
                            min={1}
                            type="number"
                            value={returnPeriod}
                            onChange={(e) => setReturnPeriod(e.target.value)}
                          /> */}
                          <Select
                              style={{
                                width: 120,
                              }}
                              onChange={(value) => {
                                setReturnPeriod(value);
                                console.log("SELECTED DAY::" + returnPeriod);
                              }}
                              value={returnPeriod}
                            >
                              {returnPeriodDay.map((type,index) =>(
                                <Option key={index} value={type}>{type}</Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {/* {freeDelivery === "No" && (
            <div className="add-product-section-content">
              <div className="add-product-section-title">Delivery Charge</div>
              <Row>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="add-image-col"
                >
                  <div className="add-product-tile">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Local Body</div>
                    </div>
                    <Input
                      type="number"
                      onChange={(e) => setDcBody(e.target.value)}
                    />
                  </div>
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="add-image-col"
                >
                  <div className="add-product-tile">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Within District</div>
                    </div>
                    <Input
                      type="number"
                      onChange={(e) => setDcWithinDistrict(e.target.value)}
                    />
                  </div>
                </Col>

                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  className="add-image-col"
                >
                  <div className="add-product-tile">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Within State</div>
                    </div>
                    <Input
                      type="number"
                      onChange={(e) => setDcWithinState(e.target.value)}
                    />
                  </div>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <div className="add-product-tile">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>Out Of State</div>
                    </div>
                    <Input
                      type="number"
                      onChange={(e) => setDcOutofState(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )} */}
            {cookies.get("accountType") === "seller" && (
              <div className="add-product-section-content">
                <div className="add-product-section-title">
                  Service Locations
                </div>
                <div
                  className=""
                  style={{
                    color: "#3e6df3",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => setServiceModal(true)}
                >
                  <div
                    className="flex"
                    style={{
                      flexDirection: "column",
                      display: "grid",
                      gridTemplateColumns: "20% 75%",
                    }}
                  >
                    {serviceLocationsObject?.country ? (
                      <div style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                        Country
                      </div>
                    ) : (
                      <div />
                    )}
                    <div>
                      {serviceLocationsObject ? (
                        serviceLocationsObject.country
                      ) : (
                        <div />
                      )}
                    </div>
                    <div style={{ minHeight: "10px" }} />
                    <div />
                    {serviceLocationsObject?.state ? (
                      <div style={{ color: "rgba(0, 0, 0, 0.65)" }}>State</div>
                    ) : (
                      <div />
                    )}
                    <div>
                      {serviceLocationsObject ? (
                        serviceLocationsObject.state.map(
                          (item, index) =>
                            // <div className="seller-pincode-list seller-profile-tile">
                            {
                              return (
                                item +
                                (serviceLocationsObject?.state?.length - 1 >
                                index
                                  ? ", "
                                  : "")
                              );
                            }
                          // </div>
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                    <div style={{ minHeight: "10px" }} />
                    <div />
                    {serviceLocationsObject?.district ? (
                      <div style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                        Districs
                      </div>
                    ) : (
                      <div />
                    )}
                    <div>
                      {serviceLocationsObject ? (
                        serviceLocationsObject.district.map((item, index) => {
                          return (
                            item +
                            (serviceLocationsObject?.district?.length - 1 >
                            index
                              ? ", "
                              : "")
                          );
                        })
                      ) : (
                        <div />
                      )}
                    </div>
                    <div style={{ minHeight: "10px" }} />
                    <div />
                    {serviceLocationsObject?.pincode ? (
                      <div style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                        Pincode
                      </div>
                    ) : (
                      <div />
                    )}
                    <div>
                      {serviceLocationsObject ? (
                        serviceLocationsObject.pincode.map((item, index) => {
                          return (
                            item +
                            (serviceLocationsObject?.pincode?.length - 1 > index
                              ? ", "
                              : "")
                          );
                        })
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                  <div style={{ minHeight: "10px" }} />
                  <div />
                  <div style={{ color: "rgba(0, 0, 0, 0.65)" }}>
                    + Add Service Locations
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}

        {/* {isCentralRepo && */}
        <Row>
        {activeCustomer?.accountType == "admin" && (
                  <Col
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                    className={
                      collapsed ? "add-product-mobile-col" : "add-image-col"
                    }
                  >
                    <div className="add-product-section-content return_period_add_pdt">
                      <div className="add-product-section-title">
                        Affiliate Commission
                      </div>
                      <div className="flex">
                        {/* <div
                          className="add-product-tile"
                          style={{ paddingLeft: "10px" }}
                        >
                          Free Delivery
                          <Radio.Group
                            style={{ width: "100%", marginTop: "10px" }}
                            onChange={(e) => setFreeDelivery(e.target.value)}
                            value={freeDelivery}
                          >
                            <Radio value={"Yes"}>Yes</Radio>
                            <Radio value={"No"}>No</Radio>
                          </Radio.Group>
                        </div> */}

                        <div className="add-product-tile">
                          Affiliate Cut (in %)
                          <Form.Item
                            name="affiliateCommission"
                            rules={[
                              {
                                validator: percentageValidator,
                              },
                            ]}
                          >
                            <Input
                              min={0}
                              max={100}
                              type="number"
                              value={affiliateCommission}
                              onChange={(e) => setAffiliateCommission(e.target.value)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </Col>
              )}
        </Row>
        <div className="add-product-section-content">
          <div className="add-product-section-title">Tax and HSN</div>
          <div className="add-product-tile">
            <Row>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="add-image-col"
              >
                <div className="add-product-tile">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>Tax</div>
                  </div>
                  {/* <Input
                  type="number"
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                /> */}
                  <Form.Item
                    name="tax"
                    rules={[{ required: true, message: "Tax is required" }]}
                  >
                    <Select
                      disabled={disableEditCentralData}
                      onSelect={(value, option) => {
                        setTax(option.value);
                      }}
                      value={tax}
                      showArrow={false}
                      className="tracking-select tracking-input"
                      style={{ width: "100%", marginTop: "0px" }}
                    >
                      {taxlist &&
                        taxlist.map((item, index) => {
                          return (
                            <Option key={index} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="add-product-tile">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>HSN </div>
                  </div>
                  <Form.Item
                    name="hsn"
                    rules={[
                      {
                        required: true,
                        message: "HSN code is required.",
                      },
                      {
                        validator: NumberValidator,
                      },
                    ]}
                  >
                    <Input
                      disabled={disableEditCentralData}
                      style={{
                        height: "40px",
                      }}
                      value={hsn}
                      onChange={(e) => setHsn(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* } */}

        <div className="seller-action-button">
          <Button
            style={{ width: "200px" }}
            className="button-seller-request-reject"
            onClick={() => props.history.goBack()}
          >
            Cancel
          </Button>
          <Form.Item>
            <Button
              htmlType="submit"
              style={{ width: "200px" }}
              // onClick={() => handleAddProduct()}
              className="button-seller-request-approve"
            >
              {loadingAddEditApi ? (
                <Loading style={{ color: "white" }} />
              ) : props.isEdit ? (
                "Update Product"
              ) : (
                "Add Product"
              )}
              {}
            </Button>
          </Form.Item>
        </div>
      </Form>

      <ServiceLocationModal
        visible={serviceModal}
        setVisible={setServiceModal}
        serviceLocations={serviceLocations}
        serviceLocationsObject={serviceLocationsObject}
        setServiceLocations={setServiceLocations}
        setServiceLocationsObject={setServiceLocationsObject}
      />
    </div>
  );
};

export default withRouter(AddProduct);
