import { message, Tabs } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { getAllAds } from "../../../ApiAction/AdminApiAction/adminApi";
import EmptyList from "../../GeneralComponents/emptyList";

import ViewAds from "./ViewAds";

function ActiveAds(props) {
  const { activeTab, reloadData, setReloadData } = props;

  const [adsList, setAdsList] = useState([]);
  const [adsLoading, setAdsLoading] = useState(false);

  useEffect(() => {
    fetchAllAds();
  }, [activeTab, reloadData]);

  const fetchAllAds = () => {
    const payload = {
      sortBy: "lastModifiedDate",
      sortType: "desc",
      size: 10,
      offset: 0,
    };
    setAdsLoading(true);
    getAllAds(payload)
      .then((response) => {
        if (response?.data?.status === "success") {
          console.log("Fetch all Ads response");
          console.log(response);
          setAdsList(response?.data?.documents);
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setAdsLoading(false);
      });
  };

  return (
    <div className="newAds-container">
      {adsLoading ? (
        <div className="fmcg-main-skeleton">
          <div className="fmcg-main-skeleton-top">
            <div className="fmcg-main-skeleton-top-text"></div>
            <div className="fmcg-main-skeleton-top-text"></div>
          </div>
          <div className="fmcg-main-skeleton-bottom">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <div className="ads-list-container">
          {adsList?.map((ads, index) => (
            <ViewAds
              reloadAds={fetchAllAds}
              data={ads.source}
              primaryKey={ads?.id}
              reloadData={reloadData}
              setReloadData={setReloadData}
            />
          ))}
        </div>
      )}
      {!adsLoading && adsList?.length === 0 && (
        <EmptyList msg="No Advertisement Found" />
      )}
    </div>
  );
}

export default ActiveAds;
