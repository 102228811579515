


import moment from "moment";




const getValueWithFormat = (shippingAddress) => {
  if(shippingAddress)
  {


    const filterArray=[
      // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
      shippingAddress?shippingAddress.buildingName:null,
      shippingAddress?shippingAddress.addressLine1:null,
      shippingAddress?shippingAddress.addressLine2:null,
      shippingAddress?shippingAddress.pinCode:null,
      shippingAddress?shippingAddress.district:null,
      shippingAddress?shippingAddress.state:null
    
    
    ];
    console.log("data??",filterArray);
  
    const filtered = filterArray.filter(function (el) {
      return el != null;
    })
    const filteredNullStringValues = filtered.filter(Boolean);
    console.log("filtered??11",filteredNullStringValues);
    return filteredNullStringValues && filteredNullStringValues.toString().split(',').join(', ');
  
    // return filtered && filtered.toString().split(',').join(', ');
  }
  
  
      
    };

export const formatAddress = (shippingAddress) => {
  const getValue = (value) => {
    if (value !== null) return value + ",";
    else return "";
  };


  const getValueWithFormat = (shippingAddress) => {
    if(shippingAddress)
    {

 
      const filterArray=[
        // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
        shippingAddress?shippingAddress.buildingName:null,
        shippingAddress?shippingAddress.addressLine1:null,
        shippingAddress?shippingAddress.addressLine2:null,
        shippingAddress?shippingAddress.pinCode:null,
        shippingAddress?shippingAddress.district:null,
        shippingAddress?shippingAddress.state:null
      
      
      ];
      console.log("data??",filterArray);
    
      const filtered = filterArray.filter(function (el) {
        return el != null;
      })
      const filteredNullStringValues = filtered.filter(Boolean)
    
      return filteredNullStringValues && filteredNullStringValues.toString().split(',').join(', ');
    }
    
    
        
      };


  const address =
    getValueWithFormat(shippingAddress) ;
    // getValue(shippingAddress?.addressLine1) +
    // getValue(shippingAddress?.addressLine2) +
    // getValue(shippingAddress?.pinCode) +
    // getValue(shippingAddress?.district) +
    // getValue(shippingAddress?.state);

  return address;
};

export const formatBasicOrderSummary = (orderSummary) => {
  const basicOrderSummary = [
    {
      key: "Order Date",
      value: moment(orderSummary.orderedDate).format(" MMM DD YYYY "),
    },
    {
      key: "Order ID",
      value: orderSummary.orderGroupId,
    },
    {
      key: "Payment",
      value: orderSummary.paymentMode,
    },
    {
      key: "Delivery Address",
      value: getValueWithFormat(orderSummary.shippingAddress),
    },
  ];

  return basicOrderSummary;
};
