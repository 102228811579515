import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  Input,
  Menu,
  message,
  Popconfirm,
  Result,
  Timeline,
  Tooltip,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  CheckCircleOutlined,
  DownOutlined,
  SafetyCertificateFilled,
  SaveOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { AiOutlineNumber } from "react-icons/ai";
import { PLATFORM_MODE_OF_PAY } from "../../../Utils/constData";
import { RiHandCoinFill } from "react-icons/ri";
import NumberFormat from "react-number-format";
import { CgRename } from "react-icons/cg";
import moment from "moment";
import Cookies from "universal-cookie";
import {
  updatePlatformChargeStatus,
  payPlatformCharge,
  verifyPlatformPayment,
  platformRazorPayFailed,
  platformDismissalFailed,
  updateAffiliateChargeStatus,
} from "../../../ApiAction/AdminApiAction/adminApi";

function PlatformUpdateModal(props) {
  const { dataList, reload, activeTab, accountType, isAffiliate } = props;
  const cookies = new Cookies();
  const [selectedStatus, setSelectedStatus] = useState(dataList?.requestStatus);
  const [disablePayButton] = useState(
    cookies.get("switchuser") === "false"
      ? accountType === "admin"
        ? false
        : activeTab !== "5"
        ? false
        : true
      : true
  );
  const [selectedMop, setSelectedMop] = useState(
    disablePayButton
      ? dataList?.paymentMode
        ? dataList?.paymentMode
        : "ONLINE"
      : accountType === "store"
      ? dataList?.paymentMode
        ? dataList?.paymentMode
        : "CASH"
      : activeTab === "6"
      ? dataList?.paymentMode
      : "CASH"
  );
  const [comment, setComment] = useState(dataList?.comments);
  const [transactionId, setTransactionId] = useState(dataList?.transactionId);
  const [dataSaveLoading, setDataSaveLoading] = useState(false);
  const [drawerVisibleUpdate, setDrawerVisibleUpdate] = useState(false);
  const [payNowLoading, setPayNowLoading] = useState(false);
  const [platformChargePaymentResult, setPlatformChargePaymentResult] =
    useState({
      show: false,
      result: "error",
      title: "Payment Failed",
      message: "Please try again, or Contact our Administrators.",
    });

  const handleMenuClick = (e) => {
    setSelectedMop(e.key);
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      {PLATFORM_MODE_OF_PAY?.map((mode) => (
        <Menu.Item
          key={mode.name}
          icon={<SendOutlined style={{ color: "blue" }} />}
        >
          {mode.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  //*For selecting status type
  useEffect(() => {
    if (dataList?.requestStatus === "INVOICED") {
      setSelectedStatus("PROCESSING");
    } else {
      setSelectedStatus("PAID");
    }
  }, []);

  //*For Updating Platform Charge Status
  const updatePlatformStatus = () => {
    if (
      selectedMop !== "ONLINE" &&
      selectedMop !== "CASH" &&
      selectedMop !== "OTHER"
    ) {
      if (transactionId?.length === 0 || transactionId === " ") {
        message.error("Transaction Id is required");
        return false;
      }
    }
    setDataSaveLoading(true);
    const payload = {
      _id: dataList?.id,
      status: selectedStatus,
      ...(isAffiliate? {
        affiliateInvoiceId: dataList?.platformId
      } : {
        platformId: dataList?.platformId
      }),
      accountType: dataList?.accountType,
      transactionId: transactionId,
      paymentMode: selectedMop === "" ? "ONLINE" : selectedMop,
      logStatus: {
        comments: comment,
        updatedId: cookies.get("userId"),
      },
    };
    (isAffiliate? updateAffiliateChargeStatus(payload) : updatePlatformChargeStatus(payload))
      .then((response) => {
        if (response?.data?.status === "success") {
          message.success(response?.data?.message);
          reload((prev) => {
            return !prev;
          });
          props.setModalVisible(false);
          window.scrollTo(0, 0);
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log("====================================");
        console.log("Error in Status Update :: ", error.message);
        console.log("====================================");
      })
      .finally(() => {
        setDataSaveLoading(false);
      });
  };

  const handlePlaceOrder = (responseOfRazor) => {
  //   var options = {
  //     //key: ENV_TYPE === 'production' ? RAZORPAY_LIVE : RAZORPAY_TEST,
  //     key: DOMAIN === "domain.in" ? RAZORPAY_LIVE : RAZORPAY_TEST, // Enter the Key ID generated from the Dashboard
  //     // key:"CBbjGe0HLiBZEdxuDVkmjQ5g", //key
  //     amount: dataList?.platformCharge * 100, // Amount is in currency subunits. Default currency is ₹. Hence, 50000 refers to 50000 paise
  //     currency: "INR",
  //     name: dataList?.vendorName,
  //     description: "Payment Transaction",
  //     order_id: responseOfRazor?.data?.platform?.razorPayOrderId,
  //     customer_id: responseOfRazor?.data?.platform?.razorPayCustomerId,
  //     // image: "https://example.com/your_logo",
  //     save: 1,
  //     handler: async (response) => {
  //       try {
  //         const payload = {
  //           platformId: dataList?.platformId,
  //           razorpay_payment_id: response?.razorpay_payment_id,
  //           razorpay_signature: response?.razorpay_signature,
  //           razorpay_order_id: response?.razorpay_order_id,
  //           vendorId: responseOfRazor?.data?.platform?.vendorId,
  //           vendorType: responseOfRazor?.data?.platform?.vendorType,
  //         };
  //         verifyPlatformPayment(payload)
  //           .then((responses) => {
  //             if (responses.status === 200) {
  //               if (responses.data.status === "success") {
  //                 message.success("order placed successfully ");
  //                 // props.fetchOrderResponse(data);
  //                 // props.fetchCartCount(0);
  //                 // props.fetchCartItems([]);
  //                 // handleRedirectToOrderResponse("success");
  //                 setPayNowLoading(false);
  //                 reload((prev) => {
  //                   return !prev;
  //                 });
  //                 setPlatformChargePaymentResult({
  //                   show: true,
  //                   result: "success",
  //                   title: "Platform Charge Paid Successfully",
  //                   message: "Thank you, for your cooperation with us",
  //                 });
  //               }
  //             }
  //           })
  //           .catch(() => {
  //             setPayNowLoading(false);
  //             reload((prev) => {
  //               return !prev;
  //             });
  //             setPlatformChargePaymentResult({
  //               show: true,
  //               result: "error",
  //               title: "Payment Failed",
  //               message: "Please try again, or Contact our Administrators.",
  //             });
  //           });

  //         // const url = `${API_URL}capture/${paymentId}`;
  //         // const captureResponse = await Axios.post(url, {});
  //         // console.log(captureResponse.data);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     },
  //     modal: {
  //       ondismiss: function () {
  //         console.log("modal dismissed gor razorpay.");
  //         const payload = {
  //           platformId: responseOfRazor?.data?.platform?.platformId,
  //         };
  //         platformDismissalFailed(payload)
  //           .then((response) => {
  //             if (response.status === 200) {
  //               if (response.data.status === "success") {
  //                 message.error("Payment Failed, Please Try Again");
  //               }
  //             }
  //             setPayNowLoading(false);
  //             reload((prev) => {
  //               return !prev;
  //             });
  //             setPlatformChargePaymentResult({
  //               show: true,
  //               result: "error",
  //               title: "Payment Failed",
  //               message: "You closed RazorPay, you can try again.",
  //             });
  //           })
  //           .catch(() => {});
  //       },
  //     },
  //     prefill: {
  //       name: dataList?.vendorName,
  //       email: responseOfRazor?.data?.platform?.email,
  //       contact: "+" + dataList?.vendorAddress?.phoneNo,
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#F37254",
  //     },
  //   };
  //   var rzp1 = new window.Razorpay(options);
  //   rzp1.open();
  //   rzp1.on("payment.failed", function (response) {
  //     rzp1.close();
  //     message.warning("payment failed, please retry ");
  //     const payload = {
  //       platformId: responseOfRazor?.data?.platform?.platformId,
  //       vendorId: responseOfRazor?.data?.platform?.vendorId,
  //       vendorType: responseOfRazor?.data?.platform?.vendorType,
  //     };
  //     platformRazorPayFailed(payload)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           if (response.data.status === "success") {
  //             message.error("Payment Failed, Please Try Again");
  //           }
  //         }
  //         setPayNowLoading(false);
  //         reload((prev) => {
  //           return !prev;
  //         });
  //         setPlatformChargePaymentResult({
  //           show: true,
  //           result: "error",
  //           title: "Payment Failed",
  //           message: "Please try again, or Contact our Administrators.",
  //         });
  //       })
  //       .catch(() => {});
  //   });
  };

  const handlePlatformPayment = () => {
    setDataSaveLoading(true);
    setPayNowLoading(true);
    const payload = {
      _id: dataList?.id,
      status: "PROCESSING",
      platformId: dataList?.platformId,
      accountType: dataList?.accountType.toUpperCase(),
      transactionId: transactionId,
      paymentMode: selectedMop === "" ? "ONLINE" : selectedMop,
      logStatus: {
        comments: comment,
        updatedId: cookies.get("userId"),
      },
    };
    payPlatformCharge(payload)
      .then((response) => {
        if (response?.data?.status === "success") {
          // message.success(response?.data?.message);
          // reload((prev) => {
          //   return !prev;
          // });
          // props.setModalVisible(false);
          // window.scrollTo(0, 0);
          handlePlaceOrder(response);
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log("====================================");
        console.log("Error in Status Update :: ", error.message);
        console.log("====================================");
      })
      .finally(() => {
        setDataSaveLoading(false);
      });
  };

  useEffect(() => {
    if (activeTab !== "5") {
      if (selectedMop === "ONLINE" && accountType !== "admin") {
        setSelectedStatus("PAID");
      } else {
        setSelectedStatus("PROCESSING");
      }
    }
    if (activeTab === "6") {
      setSelectedStatus("COMPLETED");
    }
  }, [selectedMop]);

  return (
    <div className="platform-update-modal-container">
      <div className="manage-account-model-box">
        <div className="manage-account-model-heading">
          <span className="container-with-icon-heading">
            <span className="container-with-text">
              <p>
                <SafetyCertificateFilled /> Update {isAffiliate? "Affiliate" : "Platform"} Charge Request
              </p>
              <p>#{dataList?.platformId}</p>
            </span>
          </span>
          <Button
            className="account-Model-Close-Button"
            onClick={() => {
              props.setModalVisible(false);
              window.scrollTo(0, 0);
            }}
          >
            x close
          </Button>
        </div>
        <Divider />
        <div className="manage-account-details-box">
          <div className="manage-account-details-box-subBox">
            <p className="subBox-heading">
              <AiOutlineNumber /> {isAffiliate? "INVOICE" : "PLATFORM"} ID
            </p>
            <p className="subBox-subHeading">{dataList?.platformId}</p>
          </div>
          <div className="manage-account-details-box-subBox">
            <p className="subBox-heading">
              <CgRename /> VENDOR NAME
            </p>
            <p className="subBox-subHeading">{dataList?.vendorName}</p>
          </div>
          <div
            className="manage-account-details-box-subBox manage-account-details-box-subBox-clickable"
            style={{ cursor: "pointer" }}
            onClick={() => setDrawerVisibleUpdate(true)}
          >
            <p className="subBox-heading">
              <CgRename /> REQUESTED DATE
            </p>
            <p className="subBox-subHeading">
              {moment(dataList?.requestedDate).format("YYYY-MM-DD hh:mm A")}
            </p>
          </div>
          <div className="manage-account-details-box-subBox">
            <p className="subBox-heading">
              <RiHandCoinFill /> {isAffiliate? "AFFILIATE" : "PLATFORM"} AMOUNT
            </p>
            <p className="subBox-subHeading" style={{ color: "#008543" }}>
              <NumberFormat
                value={dataList?.platformCharge}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹ "}
              />
            </p>
          </div>
        </div>
        <Divider />
        <div className="manage-account-update-section platform-update-container">
          <div className="manage-account-update-status-sub-box">
            <p className="subBox-heading">UPDATE STATUS TO</p>
            <Button
              disabled={
                activeTab === "6"
                  ? true
                  : activeTab !== "3" && accountType !== "admin"
              }
              style={{ fontWeight: "600" }}
            >
              {selectedStatus}
            </Button>
          </div>
          <div className="manage-account-update-status-sub-box">
            <p className="subBox-heading">MODE OF PAY</p>
            <Dropdown
              overlay={menu}
              trigger="click"
              style={{ borderRadius: "0.5em" }}
              disabled={
                activeTab === "6"
                  ? true
                  : activeTab !== "3" && accountType !== "admin"
              }
            >
              <Button style={{ fontWeight: "600" }}>
                {selectedMop}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>

          <div className="manage-account-update-status-sub-box">
            <p className="subBox-heading">COMMENT</p>
            <Input
              placeholder="Comment (Eg: Ok Done.)"
              style={{ borderRadius: "0.5em" }}
              value={comment}
              disabled={
                activeTab === "6"
                  ? true
                  : activeTab !== "3" && accountType !== "admin"
              }
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <Tooltip
            placement="top"
            title={"Careful while entering Transaction Details"}
          >
            <div className="manage-account-update-status-sub-box">
              <p className="subBox-heading">TRANSACTION ID</p>
              <Input
                value={transactionId}
                disabled={
                  activeTab === "6"
                    ? true
                    : activeTab === "5"
                    ? activeTab !== "3" && accountType !== "admin"
                    : activeTab === "3"
                    ? selectedMop === "ONLINE"
                      ? accountType !== "admin"
                        ? true
                        : false
                      : activeTab !== "3" && accountType !== "admin"
                    : false
                }
                placeholder="Transaction Id (Eg: SBE972213u718)"
                style={{ borderRadius: "0.5em" }}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </div>
          </Tooltip>
        </div>
        {activeTab === "6" && (
          <>
            <Divider />
            <div className="manage-account-details-box-subBox paid-status-box">
              <p className="subBox-heading">Transaction Completed</p>
              <p className="subBox-subHeading">
                {isAffiliate? "Affiliate Invoice Payment Successful" : "Platform Charge Acquired Successfully."}
              </p>
              <CheckCircleOutlined className="paid-icon" />
            </div>
          </>
        )}

        <Divider />
        {activeTab !== "6" && accountType !== "affiliate" && (
          <div className="manage-account-action-box">
            {/* phase 2 navigation button */}
            {/* <div>
                    <Button><ArrowLeftOutlined /></Button>
                    <Button><ArrowRightOutlined /></Button>
                </div> */}

            <>
              {(selectedMop !== "ONLINE" || accountType === "admin") && (
                <Popconfirm
                  title={
                    activeTab === "3" && accountType === "admin" && !isAffiliate
                      ? "Most preferably this Section is updated by STORE, Are you sure, you want to update Payment Status"
                      : "Are you sure, you want to update Payment Status"
                  }
                  onConfirm={() => updatePlatformStatus()}
                  disabled={activeTab !== "3" && accountType !== "admin"}
                >
                  <Button
                    type="primary"
                    loading={dataSaveLoading}
                    disabled={activeTab !== "3" && accountType !== "admin"}
                  >
                    {<SaveOutlined />}
                    Update Status
                  </Button>
                </Popconfirm>
              )}

              {selectedMop === "ONLINE" && accountType !== "admin" && (
                <Button
                  disabled={disablePayButton}
                  type="primary"
                  onClick={() => handlePlatformPayment()}
                  loading={payNowLoading}
                >
                  Pay Now
                </Button>
              )}
            </>
          </div>
        )}
      </div>
      <Drawer
        title="UPDATE HISTORY"
        placement="right"
        width={400}
        closable={false}
        onClose={() => setDrawerVisibleUpdate(false)}
        visible={drawerVisibleUpdate}
        getContainer={false}
        style={{ position: "absolute" }}
      >
        <Timeline mode="left">
          {dataList?.history?.map((doc) => (
            <Timeline.Item label={doc?.status}>
              <p>
                <i>"{doc?.comments}"</i>
              </p>
              <p>Updated By, {doc?.updatedBy}, on</p>
              <b>{moment(doc?.updatedDate).format("YYYY-MM-DD hh:mm A")}</b>
            </Timeline.Item>
          ))}
        </Timeline>
      </Drawer>

      {platformChargePaymentResult?.show && (
        <div className="data-submission-success-message-box">
          <Result
            status={platformChargePaymentResult?.result}
            title={platformChargePaymentResult?.title}
            subTitle={platformChargePaymentResult?.message}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  setPlatformChargePaymentResult({
                    show: false,
                  });
                  reload((prev) => {
                    return !prev;
                  });
                  props.setModalVisible(false);
                  window.scrollTo(0, 0);
                }}
              >
                Continue
              </Button>,
            ]}
          />
        </div>
      )}
    </div>
  );
}

export default PlatformUpdateModal;
