


import React, { useEffect, Fragment, useState } from "react";
import Modal from "antd/lib/modal/Modal";

import "./linkWarehouseModal.css";

import ManageStoreTable from "../manageStoreTable";

import {
  getLinkedWarehouses,
  getLinkedStores,
} from "../../../../ApiAction/AdminApiAction/linkWarehouseAoi";
import { Button } from "antd";

export default function LinkedWarehousesModal(props) {
  const { warehouseDetails } = props;

  const [linkedWarehouses, setlinkedWarehouses] = useState();
  const [isLinkedWarehouse, setisLinkedWarehouse] = useState(false);
  const [isLinkedStores, setisLinkedStores] = useState(false);

  useEffect(() => {
    console.log("warehouse details:", warehouseDetails);
    if (props.visible) {
      if (props.isLinkedStores) {
        setisLinkedStores(true);
        setisLinkedWarehouse(false);
        const payload = {
          linkedStores: warehouseDetails.linkedStores,
        };

        getLinkedStores(payload).then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              console.log("linked stores response:", response);
              setlinkedWarehouses(response.data.linkedStores);
            }
          }
        });
      } else {
        setisLinkedWarehouse(true);
        setisLinkedStores(false);
        const payload = {
          linkedWarehouses: warehouseDetails.linkedWarehouses,
        };

        getLinkedWarehouses(payload).then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              console.log("linked warehouses response:", response);
              setlinkedWarehouses(response.data.linkedWarehouses);
            }
          }
        });
      }
    }
  }, [props.visible, props.warehouseDetails]);

  const handleCloseModal = (e) => {
    e.stopPropagation();
    props.setvisible(false);
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        title={isLinkedStores ? "Linked Stores" : "Linked Warehouses"}
        footer={null}
        visible={props.visible}
        centered={true}
        width={"70vw"}
        onOk={(e) => handleCloseModal(e)}
        onCancel={(e) => handleCloseModal(e)}
      >
        {isLinkedWarehouse && (
          <div className="link-warehouse-btn">
            <Button
              type={"primary"}
              onClick={(e) => {
                e.stopPropagation();
                props.linkWarehouse(warehouseDetails);
              }}
            >
              Link warehouse
            </Button>
          </div>
        )}

        {linkedWarehouses && (
          <ManageStoreTable
            parentWarehouse={warehouseDetails.id}
            userData={linkedWarehouses}
            viewOnly
            isLinkedWarehouse={isLinkedWarehouse}
            isLinkedStores={isLinkedStores}
            reloadWarehouse={props.reload}
          />
        )}
      </Modal>
    </div>
  );
}
