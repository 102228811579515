import React, { useState } from 'react';
import { Input, Dropdown, Button, Menu, DatePicker, Tooltip, notification } from 'antd';
import { SearchOutlined, DownOutlined, DisconnectOutlined, SendOutlined, DownloadOutlined, CheckCircleTwoTone, BankOutlined, StopOutlined, LikeTwoTone } from '@ant-design/icons'
import { RequestConstants } from '../../../Utils/constants';
import moment from "moment";
import { exportRequestPaymentStatus, exportManageAccount } from '../../../ApiAction/SellerApiActions/sellerApi';
import Loader from '../../Admin/Reports/ReportsTable/Loader';
import Cookies from 'universal-cookie';

// This filter is used in Both Manage Account (Admin) and Request Payment (Venter)
// It contain a Search Box, Dropdown for Status, Range Pick and a Download CSV Button
const FilterRequest = (props) => {

    const { Search } = Input
    const { RangePicker } = DatePicker
    const [statusText, setStatusText] = useState("Status");
    const [downloadValid, setDownloadValid] = useState(true);
    const [tooltipText, setTooltipText] = useState("Select Dates to Download")
    const [isLoadingDownload, setIsLoadingDownload] = useState(false)

    const cookies = new Cookies()

    // function get call when we click the status dropDown 
    function handleMenuClick(e) {
        if(e.key !== "clear") {
            setStatusText(e.key)
            props.setStatusKey(e.key)
            if (props.offset !== 0) {
                props.setOffset(0)
                props.setCurrentPage(1)
            }
        }else {
            props.setStatusKey("")
            setStatusText("Status")
        }
    }

    // function used to disable date that come after today
    const disabledDate = (current) => {
        return current > moment();
    };

    // function to Enable Download CSV button. Download button will only be 
    // enabled when we select a time range
    const rangeHandler = (dateString) => {
        if(dateString === null) {
            props.setStartTime("")
            props.setEndTime("")
        }
        if(dateString) {
            if (props.offset !== 0) {
                props.setOffset(0)
                props.setCurrentPage(1)
            }
            console.log("Total Doc :: " + props.totalDoc)
            setTooltipText('')
            setDownloadValid(false)
            let startTime = moment(dateString[0]).format("YYYY-MM-DD")
            let endTime = moment(dateString[1]).format("YYYY-MM-DD")
            props.setStartTime(startTime)
            props.setEndTime(endTime)
        }else {
            setTooltipText('Select Dates to Download')
            setDownloadValid(true)
        }
    }

    const searchHandler = (e) => {
        if (props.offset !== 0) {
            props.setOffset(0)
            props.setCurrentPage(1)
        }
        props.setSearchVal(e.target.value)
    }

    const handleDownload = async () => {
        let downloadType = props.downloadType;
        if(downloadType === 'vendor') {  // from Request Payment Status
            if(props.totalDoc === 0) {
                notification.open({
                    message: 'Empty Data',
                    description:
                        'Selected Time range does\'nt contain any records. ',
                    icon: <DisconnectOutlined style={{ color: '#108ee9' }} />,
                });
            }else {
                const payload = {
                    sortType: "desc",
                    sortBy: "requestedDate",
                    key: props.searchVal,
                    startTime: props.startTime,
                    endTime: props.endTime,
                    statusKey: props.statusKey,
                    vendorId: cookies.get("vendorId")
                }
                console.log(payload)
                setIsLoadingDownload(true)
                await exportRequestPaymentStatus(payload).then((response) => {
                    console.log(response, "response for order pick-list excel sheet");
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `requestPayment_report.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    }
                ).catch(error => {
                    console.log("Download CSV Error :: "+error.message)
                        notification.open({
                            message: 'Network Error',
                            description:
                                'Something went wrong, Please try again',
                            icon: <DisconnectOutlined style={{ color: '#108ee9' }} />,
                        });
                }).finally(()=> {
                    setIsLoadingDownload(false)
                })
            }
        } else if(downloadType === 'admin') { //from Manage Account
            if(props.totalDoc === 0) {
                notification.open({
                    message: 'Empty Data',
                    description:
                        'Selected Time range does\'nt contain any records. ',
                    icon: <DisconnectOutlined style={{ color: '#108ee9' }} />,
                });
            }else {
                const payload = {
                    sortType: "desc",
                    sortBy: "requestedDate",
                    key: props.searchVal,
                    startTime: props.startTime,
                    endTime: props.endTime,
                    statusKey: props.statusKey,
                }
                setIsLoadingDownload(true)
                await exportManageAccount(payload).then((response) => {
                    console.log(response, "response for order pick-list excel sheet");
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `manageAccount_report.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    }
                ).catch(error => {
                    console.log("Download CSV Error :: "+error.message)
                        notification.open({
                            message: 'Network Error',
                            description:
                                'Something went wrong, Please try again',
                            icon: <DisconnectOutlined style={{ color: '#108ee9' }} />,
                        });
                }).finally(()=> {
                    setIsLoadingDownload(false)
                })
            }
        }
    }

    // menus of Status Bar for Request Payment and Manage Request
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key={RequestConstants.REQUESTED} icon={<SendOutlined style={{color: "blue"}} />}>
                {RequestConstants.REQUESTED}
            </Menu.Item>
            <Menu.Item key={RequestConstants.APPROVED} icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}>
                {RequestConstants.APPROVED}
            </Menu.Item>
            <Menu.Item key={RequestConstants.REJECTED} icon={< StopOutlined style={{color: "red"}} />}>
                {RequestConstants.REJECTED}
            </Menu.Item>
            <Menu.Item key={RequestConstants.SENT_TO_BANK} icon={<BankOutlined style={{color: "magenta"}} />}>
                {RequestConstants.SENT_TO_BANK && "SENT TO BANK"}
            </Menu.Item>
            <Menu.Item key={RequestConstants.PAID} icon={<LikeTwoTone />}>
                {RequestConstants.PAID}
            </Menu.Item>
            <Menu.Item key="clear">
                <Button type="primary" style={{ width: "100%", borderRadius: "0.5em"}}>CLEAR</Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="statusSortBox">
            <div style={{display: "flex",justifyContent: "space-between",width: "100%", columnGap: '1em'}}>
                <Search
                    placeholder={props.placeholder ? props.placeholder : "Search here"}
                    enterButton={<SearchOutlined />}
                    size="medium"
                    suffix=""
                    onChange={searchHandler}
                />
                <Dropdown overlay={menu} trigger="click">
                    <Button>{statusText} <DownOutlined /></Button>
                </Dropdown>
                <RangePicker disabledDate={disabledDate} style={{minWidth: '30%'}} onChange={rangeHandler} />
                <Tooltip placement="topRight" title={tooltipText}>
                    <Button type="primary" disabled={downloadValid} onClick={handleDownload} className="requestDownloadCSV"><DownloadOutlined /> Download CSV</Button>
                </Tooltip>
            </div>
            {isLoadingDownload && <Loader isLoadingDownload={isLoadingDownload} />}
        </div>
    );
};

export default FilterRequest;