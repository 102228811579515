


import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import ManageCustomerTable from "../ManageCustomer/manageCustomerTable";
import ManageStore from "../ManageStore/manageStore";
import AddStoreModal from "../ManageStore/addStoreModal";
import { fetchUsers } from "../../../ApiAction/AdminApiAction/adminApi";

const { Title } = Typography;
const ManageWarehouse = (props) => {
  const [customerData, setCustmerData] = useState([]);
  const [addStoreVisible, setAddStoreVisible] = useState(false);
  const [isLoadingWarehouse, setisLoadingWarehouse] = useState(false);

  useEffect(() => {
    const customerPayload = {
      status: "success",
      count: 37,
      message: "fetched list of users",
      documents: [
        {
          id: "e017f791a959d180f887201648fc7d46be",
          name: "Arun Kumar",
          userCode: "CUS418674825",
          email: "george.vipin@yahoo.co.in",
          mobile: "356789213",
          active: false,
          block: false,
          status: "APPROVED",
          location: "Ernakulam",
          pin: "123",
        },
        {
          id: "e017f791a959d180f7201648fc7d46be",
          name: "Kumari Arun",
          userCode: "CUS418674825",
          email: "george.vipin@yahoo.co.in",
          mobile: "12356843",
          active: false,
          block: true,
          status: "APPROVED",
          location: "Ernakulam",
          pin: "123",
        },
      ],
    };

    //    setCustmerData(customerPayload.documents)
    const payload = {
      sort: "date",
      accountType: props.isWarehouse ? "warehouse" : "store",
      offset: 0,
      size: 10,
    };
    setisLoadingWarehouse(true);
    fetchUsers(payload)
      .then((response) => {
        setCustmerData(response.data.documents);
        console.log("customer data:", response.data.documents);
      })
      .catch(() => {})
      .finally(() => {
        setisLoadingWarehouse(false);
      });
  }, []);

  useEffect(() => {
    setAddStoreVisible(props.openAdd);
  }, [props.openAdd]);

  return (
    <div className="admin-manage-customer">
      <ManageStore
        userData={customerData}
        isLoadingWarehouse={isLoadingWarehouse}
        isWarehouse={props.isWarehouse}
        openAdd={props.openAdd}
        addModal={props.addModal}
        searchVal={props.searchVal}
      />
      <AddStoreModal
        visible={addStoreVisible}
        setVisible={setAddStoreVisible}
        addModal={props.addModal}
        isWarehouse
      />
    </div>
  );
};
export default ManageWarehouse;
