


import React, { useState, useEffect } from "react";

import { Card, Typography, Divider } from "antd";
import "./availableProductCard.scss";

import { FILE_PATH } from "../../../../Utils/constants";
import NoImage from "../../../../Assets/Images/PlaceHolderImages/placeholder-product-card.jpg";
import LinkedIcon from "../../../../Assets/Images/Icons/linked.svg";

import QuantityController from "../../../Customer/Components/ProductDetailPage/Components/QuantityController";
import VariantSelector from "../variantSelector";
import AddToCart from "../addToCart";

const { Text } = Typography;
const AvailableProductCard = (props) => {
  const { product, loading } = props;

  const [selectedVariant, setselectedVariant] = useState();

  const [processedPrice, setprocessedPrice] = useState([]);
  const [price, setPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [imageLoadError, setimageLoadError] = useState(false);

  const [quantity, setquantity] = useState(1);

  const handleChangeVariant = (item) => {
    setSelectedItem(item);
    setPrice(item.price);
    setSellingPrice(item.sellingPrice);
    setselectedVariant(item.serialNumber);
    setAvailableQuantity(item.stock);
    setSelectedDiscount(item.discount);
  };

  useEffect(() => {
    if (product?.processedPrice.length > 0) {
      setselectedVariant(product.processedPrice[0].serialNumber);
      setprocessedPrice(product?.processedPrice);
      setSelectedItem(product.processedPrice[0]);
      setPrice(product.processedPrice[0].price);
      setSellingPrice(product.processedPrice[0].sellingPrice);
      setAvailableQuantity(product.processedPrice[0].stock);
      setSelectedDiscount(product.processedPrice[0].discount);
    }
  }, [product]);

  return (
    <Card
      loading={loading}
      hoverable
      key={product?.productId}
      bordered={false}
      height={250}
      style={{ width: "100%", marginBottom: "20px", marginTop: "10px" }}
      className="available-product-card-container"
      //   onClick={() => handleRedirection()}
    >
      <div style={{ display: "flex" }}>
        <div className="available_product_img">
          {imageLoadError ? (
            <img
              className="available-product-card-image"
              src={NoImage}
              alt="placeholder_image"
            />
          ) : (
            <img
              onError={() => setimageLoadError(true)}
              className="available-product-card-image"
              src={`${FILE_PATH}${product?.productImage}`}
              alt={product && product.productName && product.productName}
            />
          )}
        </div>
        <div className="available_product_details">
          <div className="available_product_details_heading">
            <div>
              <Text style={{ fontSize: "20px" }}>{product?.productName}</Text>
            </div>
            <img src={LinkedIcon} style={{ width: "30px" }} alt="linked_icon" />
          </div>
          <Divider />

          <div className="available_product_detailed">
            <div className="available_product_cart_action" style={{ flex: 2 }}>
              <div>
                <Text className="actual_price">₹ {sellingPrice}</Text>
                &nbsp;
                <Text className="prev_price" type="secondary" delete>
                  ₹ {price}
                </Text>
              </div>
              <VariantSelector
                processedPrice={processedPrice}
                selectedVariant={selectedVariant}
                handleChangeVariant={handleChangeVariant}
                style={{ paddingBottom: "20px" }}
              />
              <div style={{ margin: "10px 0" }}>
                <QuantityController
                  quantity={quantity}
                  stock={availableQuantity}
                  update={true}
                  setQuantity={(value) => setquantity(value)}
                />
              </div>

              <Text>
                Available Quantity:{" "}
                {availableQuantity ? availableQuantity : "Out of Stock"}
              </Text>
            </div>
            <div style={{ flex: "1" }}>
              <Text strong>{product?.vendor?.name}</Text> <br />
              <Text>
                {product?.vendor?.address?.addressLine1.split(",").join(", ")},
                {"  "}
              </Text>{" "}
              <br />
              <Text>
                {" "}
                {product?.vendor?.address?.addressLine2
                  .split(",")
                  .join(", ")}{" "}
              </Text>{" "}
              <br />
              <Text> {product?.vendor?.address?.phoneNo} </Text> <br />
              <Text>Pincode {product?.vendor?.address?.pinCode} </Text> <br />
            </div>
            <AddToCart
              style={{ flex: "1" }}
              product={product}
              quantity={quantity}
              selectedItem={selectedItem}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AvailableProductCard;
