import "./Log.scss";
import { message } from "antd";
import Cookies from "universal-cookie";
import React, { useRef, useState, useEffect } from "react";
import { ENV_TYPE, DOMAIN } from "../../../Utils/constants";
import logLock from "../../../Assets/Images/Test/loggerLock.webp";
import { log_verify_auth } from "../../../ApiAction/AdminApiAction/logApi";

var CryptoJS = require("crypto-js");

function LogLogin(props) {
  const cookies = new Cookies();

  const pin1 = useRef();
  const pin2 = useRef();
  const pin3 = useRef();
  const pin4 = useRef();
  const pin5 = useRef();
  const pin6 = useRef();
  const mainPin = useRef();

  const [fullyFilledPin, setFullyFilledPin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      localStorage.removeItem("authToken");
    }
    if (ENV_TYPE === "dev") {
      cookies.set("accountType", "logger", {
        path: "/",
      });
      localStorage.removeItem("accountType");
    } else {
      cookies.set("accountType", "logger", {
        path: "/",
        domain: `${DOMAIN}`,
      });
    }
  }, []);

  const regainFocus = () => {
    document.getElementById("mainPin").focus();
  };

  const capturePin = (inputPin) => {
    console.log(inputPin);
    if (inputPin.length === 0) {
      pin1.current.value = "";
      pin2.current.value = "";
      pin3.current.value = "";
      pin4.current.value = "";
      pin5.current.value = "";
      pin6.current.value = "";
    }
    if (inputPin.length === 1) {
      pin1.current.value = inputPin[0];
      pin2.current.value = "";
      pin3.current.value = "";
      pin4.current.value = "";
      pin5.current.value = "";
      pin6.current.value = "";
    } else if (inputPin.length === 2) {
      pin2.current.value = inputPin[1];
      pin3.current.value = "";
      pin4.current.value = "";
      pin5.current.value = "";
      pin6.current.value = "";
    } else if (inputPin.length === 3) {
      pin3.current.value = inputPin[2];
      pin4.current.value = "";
      pin5.current.value = "";
      pin6.current.value = "";
    } else if (inputPin.length === 4) {
      pin4.current.value = inputPin[3];
      pin5.current.value = "";
      pin6.current.value = "";
    } else if (inputPin.length === 5) {
      pin5.current.value = inputPin[4];
      pin6.current.value = "";
    } else if (inputPin.length === 6) {
      pin6.current.value = inputPin[5];
    }
    // to enable and disable verify button
    if (inputPin.length === 6) {
      //double checking whether all the values are displayed on screen
      pin1.current.value = inputPin[0];
      pin2.current.value = inputPin[1];
      pin3.current.value = inputPin[2];
      pin4.current.value = inputPin[3];
      pin5.current.value = inputPin[4];
      pin6.current.value = inputPin[5];
      setFullyFilledPin(true);
    } else {
      setFullyFilledPin(false);
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    if (mainPin.current.value.length === 6) {
      const payload = {
        pin: mainPin.current.value,
      };
      // going to verify pin with the payload
      log_verify_auth(payload)
        .then((response) => {
          // to disable verify button
          document
            .querySelector(".fully-filled-pin-button")
            .classList.remove("fully-filled-pin-button");
          if (response.data.status === "success") {
            message.success("Login success");
            // to show green color for input box
            let inputs = document.querySelectorAll(".log-pin-input");
            for (let i = 0; i < inputs.length; i++) {
              inputs[i].classList.add("log-verify-success-input");
            }
            // to show fade out verify and cancel button
            document
              .querySelector(".log-login-button-container")
              .classList.add("log-verify-button-container-success");
            handleAccessGrand(response.data);
            setTimeout(() => {
              props.history.push("/log");
            }, 2000);
          } else {
            message.error("Failed to login,try again.");
          }
          console.log("Log verify response :: ");
          console.log(response);
        })
        .catch((error) => {
          console.log("Log verify Error :: ", error.message);
        });
    }
    console.log("Form submitted");
  };

  const handleAccessGrand = (data) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data.log_token),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    if (ENV_TYPE === "dev") {
      localStorage.setItem("authToken", ciphertext, {
        path: "/",
      });
    } else {
      localStorage.setItem("authToken", ciphertext, {
        path: `${DOMAIN}`,
      });
      cookies.set("accountType", "logger", {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    }
  };

  return (
    <div className="log-login-container">
      <div className="log-login-main-container">
        <img src={logLock} alt="log lock" className="logger-lock-img" />
        <p className="log-text-14 log-login-main-title">Sign in to Logger</p>
        <p className="log-text-8 log-login-sub-title">
          For your security, we want to make sure it's really you
        </p>
        <div className="log-div-relative-flex log-pin-container">
          <input
            type="text"
            className="log-pin-input"
            maxLength="1"
            required
            ref={pin1}
            name="pin1"
            onClick={() => regainFocus()}
          />
          <input
            type="text"
            className="log-pin-input"
            maxLength="1"
            required
            ref={pin2}
            name="pin2"
            onClick={() => regainFocus()}
          />
          <input
            type="text"
            className="log-pin-input"
            maxLength="1"
            required
            ref={pin3}
            name="pin3"
            onClick={() => regainFocus()}
          />
          <input
            type="text"
            className="log-pin-input"
            maxLength="1"
            required
            ref={pin4}
            name="pin4"
            onClick={() => regainFocus()}
          />
          <input
            type="text"
            className="log-pin-input"
            maxLength="1"
            required
            ref={pin5}
            name="pin5"
            onClick={() => regainFocus()}
          />
          <input
            type="text"
            className="log-pin-input"
            maxLength="1"
            required
            ref={pin6}
            name="pin6"
            onClick={() => regainFocus()}
          />
        </div>
        <form className="log-login-button-container" onSubmit={handleLogin}>
          <input
            type="text"
            autoComplete="off"
            inputMode="numeric"
            id="mainPin"
            style={{ position: "absolute" }}
            autoFocus
            maxLength="6"
            ref={mainPin}
            onKeyUp={(e) => capturePin(e.target.value)}
          />
          <button className="log-verify-pin-button log-verify-pin-cancel-button">
            CANCEL
          </button>
          <button
            className={
              fullyFilledPin
                ? "log-verify-pin-button fully-filled-pin-button"
                : "log-verify-pin-button"
            }
            disabled={!fullyFilledPin}
            type="submit"
          >
            VERIFY PIN
          </button>
        </form>
      </div>
    </div>
  );
}

export default LogLogin;
