


import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  message,
  notification,
  Spin,
} from "antd";
import CompanyLogo from "../../Assets/Images/Logo/DL_Logo.png";
import { ReactComponent as Phone } from "../../Assets/Images/Icons/Seller/phone.svg";
import { ReactComponent as Mail } from "../../Assets/Images/Icons/Seller/mail.svg";
import Icon from "@ant-design/icons";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CloseCircleOutlined } from "@ant-design/icons";
import { forgotPasswordApi } from "../../ApiAction/CustomerApiActions/customerApi";
import { openSignUpModal } from "../../ReduxStore/action/authModalActions";
import { fetchCompanyDetails } from "../../ReduxStore/action";

const { Title } = Typography;
const ForgotPassword = (props) => {
  const { companyDetails } = props;
  const [forgotMessage, setForgotMessage] = useState("");
  const [form] = Form.useForm();
  const [isLoading, setisLoading] = useState(false);

  const handleOpenSignUpModal = () => {
    props.setForgotVisible(false);
    if (props.isSeller) {
      props.openSignUpModal("seller");
    } else props.openSignUpModal("customer");
  };

  const formFields = [
    {
      name: "emailId",
      placeHolder: "Registered Email ID *",
      ruleType: "email",
      message: "Enter a vallid email Id",
    },
  ];

  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };

  const onFinish = (values) => {
    const payload = {
      email: values.emailId,
      accountType: props.isCustomer
        ? "customer"
        : props.isSeller
        ? "seller"
        : props.isStore
        ? "store"
        : props.isWarehouse
        ? "warehouse"
        : props.isAdmin
        ? "admin"
        : "customer",
    };
    setisLoading(true);
    forgotPasswordApi(payload)
      .then((response) => {
        if (response.data) {
          if (response.data.status == "failed") {
            setForgotMessage(response.data.message);
            openNotificationWithIcon("error", "Failed", response.data.message);
          } else {
            // message.success(response.data.message);
            openNotificationWithIcon(
              "success",
              "Success",
              response.data.message
            );
            form.resetFields();
            props.setSuccess();
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setisLoading(false);
      });

    // else{
    //   setForgotMessage("Please enter the email")
    // }
  };

  return (
    <Modal
      className="circle_drop"
      visible={props.visible}
      onOk={() => {
        form.resetFields();
        props.setVisible();
      }}
      onCancel={() => {
        form.resetFields();
        props.setVisible();
      }}
      width={600}
      footer={null}
    >
      <img
        style={{ width: "150px" }}
        src={CompanyLogo}
        alt={"company logo"}
        className="hide_logo_mob"
      />
      <div className="contact-modal-form login-modal-form form_data_group">
        <Title level={3}> Reset Password </Title>
        <Spin spinning={isLoading}>
          <Form
            name="normal_login"
            className="login-form"
            form={form}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            {formFields.map((data, index) => {
              return (
                <Form.Item
                  hasFeedback
                  key={index}
                  name={data.name}
                  rules={[
                    {
                      type: data.ruleType ? data.ruleType : null,
                      message: data.message ? data.message : null,
                    },
                    {
                      required: data.referalId ? false : true,
                      message: "This field is required !",
                    },
                    {
                      validator: data.validator ? data.validator : "",
                    },
                  ]}
                >
                  {data.type && data.type === "password" ? (
                    <Input.Password
                      ref={data.ref ? data.ref : null}
                      placeholder={data.placeHolder}
                    />
                  ) : (
                    <Input
                      ref={data.ref ? data.ref : null}
                      type={data.type ? data.type : null}
                      placeholder={data.placeHolder}
                    />
                  )}
                </Form.Item>
              );
            })}

            <Form.Item className="button_submit">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ fontWeight: "600" }}
              >
                RESET PASSWORD
              </Button>
              <br />
            </Form.Item>
          </Form>
        </Spin>
        {forgotMessage && <div className="error-message">{forgotMessage}</div>}
        {(props.isSeller || props.isCustomer) && (
          <React.Fragment>
            <p className="go_to_register">
              New to{" "}
              <span style={{ color: "#3e6df3", fontSize: "15px" }}>
                {" "}
                {companyDetails?.companyName}?
              </span>{" "}
              <a
                onClick={() => handleOpenSignUpModal()}
                style={{ fontWeight: "600" }}
              >
                REGISTER
              </a>
            </p>
          </React.Fragment>
        )}
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  openSignUpModal,
  fetchCompanyDetails
};
const mapStateToProps = (state) => {
  return {
    authModalUserType: state.authModalReducer.userType,
    authModalType: state.authModalReducer.modalType,
    companyDetails: state.homePageReducer.companyDetails
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
