


import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Typography,
  Divider,
  Form,
  Select,
  Input,
  message,
} from "antd";
import { connect } from "react-redux";

import Loading from "../../../../GeneralComponents/Loading";
import OrderProduct from "./orderProduct";

import { requestReturn } from "../../../../../ApiAction/CustomerApiActions/orderAPI";
import { refetchOrderList } from "../../../../../ReduxStore/action/customerOrderAction";

import "./requestReturn.scss";
import { getReturnMessage } from "../../../../../ApiAction/AdminApiAction/adminApi";

const { Text } = Typography;
const { TextArea } = Input;

const ReturnRequest = (props) => {
  const { visible, setvisible, product, groupId, orderSummary } = props;

  const [form] = Form.useForm();

  const [loading, setloading] = useState(false);

  const [reasons, setReasons] = useState([]);
  const { Option } = Select;

  // const reasons = [
  //   {
  //     label: "Product Damaged",
  //     value: "Product Damaged",
  //   },
  //   {
  //     label: "Not the actual product",
  //     value: "Not the actual product",
  //   },
  //   {
  //     label: "Delay in delivery",
  //     value: "Delay in delivery",
  //   },
  // ];

  useEffect(() => {
    getReturnList();
  }, []);

  const getReturnList = () => {
    getReturnMessage().then((res) => {
      if (res.data.status == "success") {
        setReasons(res.data.returnReasons);
      }
    });
  };

  const handleCancel = () => {
    form.resetFields();
    setvisible(false);
  };

  function handleChange(value) {}

  const handleReturnRequest = (values) => {
    const payload = {
      orderGroupId: groupId,
      orderId: product.orderId,
      reasonOfCancellation: values.reasonOfCancellation,
      quantity: Number(values.quantity),
    };
    if (values.comments) payload["comments"] = values.comments;

    setloading(true);
    requestReturn(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(
              `Return request for ${product.productName} submitted.`
            );
            props.refetchOrderList();
            setvisible(false);
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };

  const QuanityValidator = (rule, value, callback) => {
    if (value) {
      if (Number(value) > Number(product.quantity) || Number(value) < 1) {
        callback("Please enter a valid quantity");
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
  return (
    <Modal
      title="Return Request"
      visible={visible}
      onOk={() => setvisible(false)}
      onCancel={() => setvisible(false)}
      footer={null}
      width={950}
      className="cancle-reason-pop"
    >
      <OrderProduct product={product} isRequest />
      <Divider />
      <Form
        form={form}
        className="request_return_form return-form"
        onFinish={handleReturnRequest}
      >
        <label>Return Quantity* </label>
        <Form.Item
          hasFeedback
          name={"quantity"}
          rules={[
            {
              required: true,
              message: "Please enter quantity!",
            },
            { validator: QuanityValidator },
          ]}
        >
          <Input
            placeholder={"Return Quantity Eg: 1"}
            style={{ height: "40px" }}
            type="number"
            min="1"
            style={{ width: "50%" }}
          />
        </Form.Item>

        <label>Reason*</label>
        <Form.Item
          name="reasonOfCancellation"
          rules={[
            {
              required: true,
              message: "Reason is required!",
            },
          ]}
        >
          <Select
            size="large"
            onChange={handleChange}
            placeholder={"Reason for return"}
            dropdownClassName="return-options-select"
          >
            {reasons &&
              reasons.map((item, index) => {
                return (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                );
              })}
            ;
          </Select>
        </Form.Item>
        <label>Additional Information</label>
        <Form.Item name="comments">
          <TextArea rows={4} />
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Form.Item>
            <Button
              size="large"
              className="return_request_cancel_btn"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>

            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="return_request_btn"
            >
              {loading ? (
                <Loading style={{ color: "white" }} />
              ) : (
                "Send Request"
              )}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

const mapDispatchToProps = {
  refetchOrderList,
};

export default connect(null, mapDispatchToProps)(ReturnRequest);
