

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { Layout } from "antd";

import { sideBarData, sideBarDataForNewSeller } from "../../Utils/Seller/Data";

import HeaderLayout from "../../Layouts/HeaderLayout";
import FooterLayout from "../../Layouts/FooterLayout";

import "./seller.css";
import "../Admin/admin.scss";
import BreadCrumb from "./breadCrumb";
import SideBarMenu from "./SideBarMenu";
import SortBy from "./SortBy";
import SearchBar from "../SearchBar";

import ManageProducts from "./ManageProducts";
import BulkUploadModal from "./ManageProducts/Components/bulkUpload";
import AddProduct from "./ManageProducts/Components/addProduct";
import ManageProfile from "../Admin/ManageProfile/manageProfile";
import SearchBarInput from "../SearchBar/SearchBarInput";
import ManageStocks from "./ManageStocks/manageStocks";
import ManageProductStock from "./ManageStocks/manageProductStock";
import ManageOrder from "./ManageOrder/manageOrder";
import FilterAndSortOrder from "./ManageOrder/filterAndSortOrder";
import ManageReturns from "./ManageReturns/manageReturns";
import RequestPayment from "./RequestPayment";

import SalesReport from "../Admin/Reports/SalesReport";
import StocksReport from "../Admin/Reports/StocksReport";
import ExpiryReport from "../Admin/Reports/ExpiryReport";
import ReportsFilter from "../Admin/Reports/Filter/reportsFilter";

import Cookies from "universal-cookie";
import AccountTypeContext from "../Admin/accountTypeContext";
import ContentSection from "../Admin/ManageContent/contentSection";
import ConfigureDeliveryCharge from "../Admin/Configure/configureDeliveryCharge";
import CentralRepo from "../Admin/CentralRepo";
import GroupByReport from "./SortBy/groupByReport";
import { useSelector } from "react-redux";
import DetailedReport from "../Admin/Reports/DetailedReport";
import PlatformIndex from "./PlatformRecovery/PlatformIndex";
import ManageCategory from "../Admin/ManageCategory";
import BarcodeSearch from "../Admin/BarcodeSearch/BarcodeSearch";
import DiscoutProducts from "../Admin/DiscountProducts/DiscoutProducts";
import ReplacedProducts from "../Admin/CentralRepo/replacedProducts";
import DayBookReport from "../Admin/Reports/DayBookReport";
import VerifiedProducts from "../Admin/CentralRepo/verifiedProducts";
const cookies = new Cookies();

const { Content, Footer, Sider } = Layout;

const Seller = (props) => {
  const [isMobileView, setisMobileView] = useState(false);
  const [urlPath, setUrlPath] = useState("");
  const [activeTab, setactiveTab] = useState();
  const [searchVal, setSearchval] = useState("");
  const [reportSummary, setreportSummary] = useState("");
  const sellerStatus = useSelector((state) => state.sellerStatus);
  const renderSideBar = () => {
    if (isMobileView) {
      return;
    } else {
      return (
        <Sider className="nav-side-bar-admin" trigger={null} width={250}>
          <SideBarMenu
            sideBarData={
              sellerStatus.sellerStatus ?
              sellerStatus.sellerStatus === "APPROVED"
                ? sideBarData
                : sideBarDataForNewSeller : sideBarData
            }
            activeTab={activeTab}
            sellerStatus={sellerStatus.sellerStatus}
          />
        </Sider>
      );
    }
  };

  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    setUrlPath(props.location.pathname);
    setactiveTab(props.location.pathname.split("/").pop());
    console.log(props.customer);
    window.scrollTo(0, 0);
  }, [props.location]);
  return (
    <Layout>
      <HeaderLayout sellerView />
      <Layout
        className="seller-view-container"
        style={{ marginTop: urlPath === "/seller/profile" ? "35px" : "70px" }}
      >
        <Content className="seller-view-content-container">
          {!isMobileView && (
            <div className="seller-view-breadcrumb-search-sort-conatiner">
              {/* <BreadCrumb /> */}
              <div className="seller-view-search-sortBy-container">
                <div
                  className="admin-breadcrumb"
                  style={{ width: "225px" }}
                ></div>
                {(urlPath === "/seller/manage-products" ||
                  urlPath === "/admin/manage-seller" ||
                  urlPath === "/seller/manage-returns" ||
                  urlPath === "/seller/sales-report" ||
                  urlPath === "/seller/day-book-report" ||
                  urlPath === "/seller/manage-orders" ||
                  urlPath === "/seller/stocks-report" ||
                  urlPath === "/seller/detailed-report" ||
                  urlPath === "/seller/expiry-report" ||
                  urlPath === "/seller/manage-stocks" ||
                  urlPath === "/seller/manage-category" ||
                  urlPath === "/seller/central-repo") && (
                  <>
                    <SearchBarInput
                      searchValue={setSearchval}
                      urlPath={activeTab}
                    />
                    {
                      // urlPath === "/seller/stocks-report" ||
                      // urlPath === "/seller/expiry-report" ||
                      (urlPath === "/seller/sales-report" ||
                       urlPath === "/seller/day-book-report" ||
                        urlPath === "/seller/manage-products") && (
                        <ReportsFilter
                          isVendor
                          activePath={activeTab}
                          setreportSummary={setreportSummary}
                        />
                      )
                    }
                    {urlPath === "/seller/sales-report" && <GroupByReport />}

                    {urlPath === "/seller/manage-orders" ? (
                      <FilterAndSortOrder />
                    ) : (
                      urlPath !== "/seller/expiry-report" &&
                      urlPath !== "/seller/stocks-report" &&
                      urlPath !== "/seller/detailed-report" &&
                      urlPath !== "/seller/sales-report" &&
                      urlPath !== "/seller/day-book-report" &&
                      urlPath !== "/seller/central-repo" && (
                        <SortBy activePath={activeTab} />
                      )
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          <Layout className="seller-view-sider-content-container">
            <div className="profile_column_data">
              {renderSideBar()}

              <Content className="seller-view-content-layout right_profile_layout">
                <Switch>
                  <Route
                    exact
                    path="/seller"
                    render={(props) => {
                      return <Redirect to="/seller/profile" />;
                    }}
                  />
                  <Route
                    exact
                    path="/seller/manage-products"
                    render={(props) => <ManageProducts searchVal={searchVal} />}
                  />
                  <Route
                    exact
                    path="/seller/manage-category"
                    render={(props) => <ManageCategory {...props} isView />}
                  />
                  <Route
                    exact
                    path="/seller/manage-delivery-charge"
                    render={(props) => <ConfigureDeliveryCharge isSeller />}
                  />
                  <Route
                    exact
                    path="/seller/profile"
                    render={(props) => {
                      return <ManageProfile {...props} isSeller />;
                    }}
                  />
                  <Route
                    exact
                    path="/seller/add-product"
                    render={(props) => {
                      return <AddProduct {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/seller/edit-product/:productId"
                    render={(props) => {
                      return <AddProduct {...props} isEdit />;
                    }}
                  />

                  <Route
                    exact
                    path="/seller/manage-price-stock/:productId"
                    render={(props) => {
                      return <ManageProductStock {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/seller/central-repo"
                    render={(props) => (
                      <CentralRepo searchVal={searchVal} isVendor />
                    )}
                  />
                  <Route
                    exact
                    path="/seller/replaced-products"
                    render={(props) => (
                      <ReplacedProducts searchVal={searchVal} isVendor />
                    )}
                  />
                  <Route
                    exact
                    path="/seller/verified-products"
                    render={(props) => (
                      <VerifiedProducts isVendor />
                    )}
                  />

                  <Route
                    exact
                    path="/seller/manage-stocks"
                    render={(props) => <ManageStocks searchVal={searchVal} />}
                  />

                  <Route
                    exact
                    path="/seller/request-payment"
                    render={(props) => <RequestPayment />}
                  />

                  <Route
                    exact
                    path="/seller/platform-recovery"
                    render={(props) => <PlatformIndex vendorType={"SELLER"} />}
                  />
                  <Route
                    exact
                    path="/seller/discount-products"
                    render={(props) => <DiscoutProducts vendorType={"SELLER"} />}
                  />

                  <Route
                    exact
                    path="/seller/manage-orders"
                    render={(props) => <ManageOrder searchVal={searchVal} />}
                  />
                  <Route
                    exact
                    path="/seller/manage-returns"
                    render={(props) => <ManageReturns searchVal={searchVal} />}
                  />
                  <Route
                    exact
                    path="/seller/barcode-search"
                    render={(props) => <BarcodeSearch />}
                  />
                  <Route
                    exact
                    path="/seller/content/:type"
                    render={(props) => {
                      return <ContentSection {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/seller/sales-report"
                    render={(props) => (
                      <SalesReport
                        searchVal={searchVal}
                        vendorId={cookies.get("userId")}
                        reportSummary={reportSummary}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/seller/stocks-report"
                    render={(props) => (
                      <StocksReport
                        searchVal={searchVal}
                        vendorId={cookies.get("userId")}
                        reportSummary={reportSummary}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/seller/detailed-report"
                    render={(props) => (
                      <DetailedReport
                        searchVal={searchVal}
                        vendorId={cookies.get("userId")}
                        reportSummary={reportSummary}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/seller/expiry-report"
                    render={(props) => (
                      <ExpiryReport
                        searchVal={searchVal}
                        vendorId={cookies.get("userId")}
                        reportSummary={reportSummary}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/seller/day-book-report"
                    render={(props) => (
                      <DayBookReport
                        searchVal={searchVal}
                        vendorId={cookies.get("userId")}
                        reportSummary={reportSummary}
                      />
                    )}
                  />
                  <Route
                    render={(props) => {
                      return <Redirect to="/404" />;
                    }}
                  />
                </Switch>
              </Content>
            </div>
          </Layout>
        </Content>
      </Layout>
      <AccountTypeContext.Provider value="seller">
        <FooterLayout />
      </AccountTypeContext.Provider>
    </Layout>
  );
};

export default Seller;
