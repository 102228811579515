

import {
  SuitCaseIcon,
  CircleIcon,
  MoliculeIcon,
  StarIcon,
} from "../../IconList/IconList";
import React, { useContext } from "react";
import AccountTypeContext from "../../components/Admin/accountTypeContext";
import { DOMAIN, SSLHTTP } from "../../Utils/constants";

export const AboutItemList = [
  // { link: "contact", text: "Contact Us" },
  { link: "about", text: "About US" },
  { link: "carrers", text: "Careers" },
  { link: "agency", text: "Agencies" },
];
export const HelpItemList = [
  { link: "payment", text: "Payments" },
  { link: "shipping", text: "Shipping" },
  { link: "faq", text: "FAQ" },
  { link: "cancel", text: "Cancellation & Shipping" },
];

export const PolicyItemList = [
  { link: "returnP", text: "Return" },
  { link: "shipping", text: "Shipping" },
  { link: "privacy", text: "Privacy" },
  { link: "cookies", text: "Cookies" },
  { link: "terms", text: "Terms & Conditions" },
  { link: "refund", text: "Refund" },
  { link: "dnd", text: "DND" },
  { link: "onlinePayment", text: "Online Payments" },
];

export const SocialMediaItemList = [
  { link: "fb", text: "Facebook" },
  { link: "insta", text: "Instagram" },
  { link: "youtube", text: "Youtube" },
  { link: "linkedin", text: "LinkedIn" },
];

export const AppStores = [
  { link: "", text: "playStore" },
  {link: "", text: "appleStore"}
]

const fetch = () => {
  return <CircleIcon />;
};

export const otherLinks = [
  // {
  //   icon: <StarIcon />,
  //   text: "Join Affiliate Program",
  //   link: "https://www.loremipsum.net/first_purchase_products",
  // },
  {
    icon: <CircleIcon />,
    text: "Own an Agency",
    link: `${SSLHTTP}://agency.${DOMAIN}/`,
  },
  {
    icon: <MoliculeIcon />,
    text: "Own a Hub",
    link: `${SSLHTTP}://hub.${DOMAIN}/`,
  },
  {
    icon: <SuitCaseIcon />,
    text: "Sell On Dream Life",
    link: `${SSLHTTP}://seller.${DOMAIN}/`,
  },
];

export const domainLink = `2024 dlmbiz.com`;

// export const HeadOfficeAddress =
//     `loremipsum Online Shoppe Pvt. Ltd.
// Capital art businesses spaces,
// Neruvissery, Arattupuzha P.O
// Thrissur, Kerala - 680562
// `

// export const PhoneNUmber = `Phone : +91 9744338134`
// export const Email = `Mail : info@loremipsum.in`

export const HeadOfficeAddress = [
  { link: "", text: "Head Office:" },
  { link: "", text: "loremipsum Online Shoppe" },
  { link: "", text: "Mail : info@loremipsum.in" },
];
export const RegisteredOfficeAddress = [
  { link: "", text: "Registered Office: " },
  { link: "", text: "loremipsum Pvt. Ltd." },
  { link: "", text: "Ernakulam, Kerala - 682001" },
];
export const ContactInfo = [
  { link: "", text: "For Queries / Suggestions/ Complaints" },
  { link: "", text: "+91 9544500023, +91 9589500025, +91 9541500024 ( Malayalam )" },
  { link: "", text: "+91 7559900081 , +91 9778338134, +91 9714338138 ( Hindi and English )" },
]
export const FranchiseInfo = [
  { link: "", text: "For Enquires (Franchise)" },
  { link: "", text: "+91 7366183049" },
]
export const COOInfo = [
  { link: "", text: "For Queries (National & International)- Franchise" },
  { link: "", text: "Chief Operating Officer (COO) : +91 6234814065" },
]
export const MailInfo = [
  { link: "", text: "info@loremipsum.in" },
]
export const SellerInfo = [
  {
    link: "",
    text: "For Queries / Suggestions / Complaints (Multi Vendor / Sellers)",
  },
  { link: "", text: "+91 98745 00096, +91 81370 00844" },
];
export const MarketingService = [
  {
    link: "",
    text:"Marketing & Customer Care Service"
  },
  { link: "", text: "+91 9933 000 685 [Malayalam]" },
  { link: "", text: "+91 9733 000 684" },
  { link: "", text: "+91 9444 338 134" },
];
export const FinanceCustomerService = [
  {
    link: "",
    text:"Finance Customer Service"
  },
  { link: "", text: "+91 9544 500 094" },
  { link: "", text: "+91 9544 500 023" },
];
export const CustomerReview = [
  {
    link: "",
    text: "+91 8129 940 660 This Number is for customer review only"
  }
]
