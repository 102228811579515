

import React, { useState, useEffect, Fragment, useReducer } from "react";
import moment from "moment";
import {
  Typography,
  Pagination,
  Row,
  Col,
  Collapse,
  Button,
  message,
  Checkbox,
  Tooltip,
  Modal,
} from "antd";
import { connect } from "react-redux";
import "./manageOrder.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  CaretRightOutlined,
  CopyOutlined,
  ExclamationCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import onlineIcon from "../../../Assets/Images/Icons/Seller/online-shopping.svg";

import OnlineIcon from "../../../Assets/Images/Icons/online_mode.svg";
import CODIcon from "../../../Assets/Images/Icons/cod_mode.svg";
import calendarIcon from "../../../Assets/Images/Icons/Seller/calendar.svg";
import locationIcon from "../../../Assets/Images/Icons/Seller/location.svg";
import {
  getCustomerOrders,
  getProducts,
  updateOrderStatus,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import Cookies from "universal-cookie";
import OrderData from "./orderData";
import TrackingDetails from "./trackingDetails";
import SellerInvoice from "./sellerInvoice";
import Loading from "../../GeneralComponents/Loading";
import { CancelOrderItem } from "../../../ApiAction/CustomerApiActions/orderAPI";
import GenerateEwayBill from "./GenerateEwayBill";
import { formatOrderSummary } from "../../Customer/Components/ManageOrders/manageOrdersHelper";
import TaxInvoice from "../../Customer/Components/ManageOrders/Components/TaxInvoice/index";
import { excludeDeliveryCharge } from "../../../ApiAction/SellerApiActions/deliveryChargeAPI";
import {
  cancelOrderGroup,
  downloadOrderPickList,
  editVendorOrder,
} from "../../../ApiAction/SellerApiActions/vendoOrderAPI";
import { clearNewItemAPI } from "../../../ApiAction/SellerApiActions/clearNewItemAPI";
import EmptyList from "../../GeneralComponents/emptyList";
import CancelOrderRequest from "../../Customer/Components/ManageOrders/Components/cancelOrderRequest";
import ThermalIndex from "../../Customer/Components/ManageOrders/Components/ThermalPrinter/ThermalIndex";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const ManageOrderGroups = (props) => {
  const cookies = new Cookies();
  const { activeTab } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentpage, setCurrentPage] = useState(1);

  const [cancelOrderModal, setCancelOrderVisible] = useState(false);
  const [generateEwayBillVisible, setgenerateEwayBillVisible] = useState(false);
  const [activeOrder, setactiveOrder] = useState();

  const [isActivePanel, setActive] = useState("");
  const [trackingModal, setTrackingModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [thermalModal, setThermalModal] = useState(false);
  const [invoiceItem, setInvoiceItem] = useState();
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isloadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);

  const [orderGroupId, setOrderGroupId] = useState("");
  const [orderedItemsOfVendor, setOrderedItemsOfVendor] = useState("");
  const [itemCount, setItemCount] = useState("");
  const [accountType, setAccountType] = useState("");
  const [oderId, setOrderId] = useState("");
  const [selectionType, setSelectionType] = useState("");
  const [isSelectallChecked, setisSelectallChecked] = useState(false);

  const [isloadingExcludeDeliveryCharge, setIsloadingExcludeDeliveryCharge] =
    useState(false);

  const pickOrderByHandStates = ["PENDING", "INPROGRESS"];

  useEffect(() => {
    getProductsList(props.searchVal, 1);
  }, [props]);

  const getValue = (value, last) => {
    if (value !== null) return value + ", ";
    else return "";
  };

  const getValueWithFormat = (data) => {
    if (data) {
      const filterArray = [
        // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
        data.ownerName,
        data.addressLine1,
        data.buildingName,
        data.pinCode,
      ];
      console.log("data??", filterArray);

      const filtered = filterArray.filter(function (el) {
        return el != null;
      });
      const filteredNullStringValues = filtered.filter(Boolean);

      return (
        filteredNullStringValues &&
        filteredNullStringValues.toString().split(",").join(", ")
      );
    }
  };

  const activeCustomer = JSON.parse(localStorage.getItem("activeCustomer"));
  const activeCustomerType = activeCustomer.accountType;

  const handleSelectAllProducts = async (e, orderData) => {
    const currentStatus = orderData.source.orderedItemsOfVendor.find(
      (product) => {
        return (
          product.itemOrderStatus?.currentStatus !== "CANCELLED" &&
          product.itemOrderStatus?.currentStatus !== "DELIVERED"
        );
      }
    );
    console.log("current status:", currentStatus);
    const crossCheckStatus = await orderData.source.orderedItemsOfVendor.find(
      (product) => {
        return (
          product.itemOrderStatus?.currentStatus !==
            currentStatus?.itemOrderStatus?.currentStatus &&
          product.itemOrderStatus?.currentStatus !== "CANCELLED"
        );
      }
    );
    if (crossCheckStatus) {
      setisSelectallChecked(false);
      message.error(
        "Some Products in the order are ahead of status, therefore you wont be able to sellect all."
      );
    } else {
      if (currentStatus === "DELIVERED") {
        message.warning("The order has been delivered already");
      } else if (currentStatus === "CANCELLED") {
        message.warning("The order has been canceled already");
      } else if (currentStatus === "RETURN_REQUESTED") {
        message.warning("The order has been requested for return already");
      } else {
        setisSelectallChecked(e.target.checked);
        if (e.target.checked) {
          const data = orderData.source.orderedItemsOfVendor.filter((item) => {
            return item.itemOrderStatus.currentStatus !== "CANCELLED";
          });

          console.log(data, ": selected Items");
          setSelectedItems(data);
          setOrderId(orderData.id);
          setOrderGroupId(orderData.source.orderGroupId);
          console.log(orderData, ": order data");
        } else {
          setSelectedItems([]);
        }
      }
    }
  };

  const getProductsList = (
    search = props.searchVal,
    page = 1,
    isFromUpdate = false
  ) => {
    setCurrentPage(page);
    setIsLoading(true);
    const term = [];
    term.push({
      vendorId: [cookies.get("vendorId")],
    });

    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      userId: cookies.get("vendorId"),
      key: search,
      sortBy: props.sort ? props.sort.sortBy : "orderedDate",
      sortType: props.sort ? props.sort.sortType : "desc",
      vendorOrderStatuses: activeTab,
      vendorId: props.vendorId,
    };
    if (props.sort) {
      if (props.sort.paymentMode != "All")
        payload.paymentMode = props.sort.paymentMode;
      payload.accountType = props.sort.orderFrom;
    }

    if (props.delivered) {
      payload.itemOrderStatus = "DELIVERED";
    } else if (props.sort && props.sort.itemOrderStatus != "All") {
      payload.itemOrderStatus = props.sort.itemOrderStatus;
    }
    if (props.sort && props.sort.endTime) {
      payload.endTime = props.sort.endTime;
    }
    if (props.sort && props.sort.startTime) {
      payload.startTime = props.sort.startTime;
    }
    getCustomerOrders(payload)
      .then((response) => {
        setIsLoading(false);
        if (response.data.status == "success") {
          setProductList(response.data.orders);

          if (isFromUpdate) {
            setActive(isActivePanel);
          } else {
            if (response.data.orders && response.data.orders.length > 0) {
              //    setActive(response.data.orders[0].id);
            }
          }

          setTotal(Math.ceil(response.data.count / 10) * 10);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const getProductsListAfterAccept = (search = "", page = 1, currentVal) => {
    setCurrentPage(page);
    // setIsLoading(true);
    const term = [];
    term.push({
      vendorId: [cookies.get("vendorId")],
    });

    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      userId: cookies.get("vendorId"),
      key: search,
      sortBy: props.sort ? props.sort.sortBy : "orderedDate",
      sortType: props.sort ? props.sort.sortType : "desc",
      vendorOrderStatuses: activeTab,
      vendorId: props.vendorId,
    };
    if (props.sort) {
      if (props.sort.paymentMode != "All")
        payload.paymentMode = props.sort.paymentMode;
      payload.accountType = props.sort.orderFrom;
    }

    if (props.delivered) {
      payload.itemOrderStatus = "DELIVERED";
    } else if (props.sort && props.sort.itemOrderStatus != "All") {
      payload.itemOrderStatus = props.sort.itemOrderStatus;
    }
    if (props.sort && props.sort.endTime) {
      payload.endTime = props.sort.endTime;
    }
    if (props.sort && props.sort.startTime) {
      payload.startTime = props.sort.startTime;
    }
    getCustomerOrders(payload)
      .then((response) => {
        // setIsLoading(false);
        if (response.data.status == "success") {
          setProductList(response.data.orders);

          //     setActive(currentVal);

          setTotal(Math.ceil(response.data.count / 10) * 10);
        }
      })
      .catch(() => {});
  };

  const updateStatus = (item, id, groupId, accountType) => {
    setSelectedItems([item]);
    setOrderId(id);
    setOrderGroupId(groupId);
    setAccountType(accountType);
    setTrackingModal(true);
    setSelectionType("single");
  };
  const setCancel = (value) => {
    setTrackingModal(value);
    if (selectionType === "single") {
      setSelectedItems([]);
    }
  };
  const checkBoxSelection = (value, item, id, groupId) => {
    setOrderId(id);
    setOrderGroupId(groupId);
    if (value) {
      setSelectedItems([...selectedItems, item]);
    } else {
      if (item)
        setSelectedItems(selectedItems.filter((data) => data.id != item.id));
    }
  };
  const setCancelOrder = (payload) => {
    CancelOrderItem(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success("Item canceled succefully");
            setProductList([]);
            getProductsList(
              props?.searchVal ? props.searchVal : "",
              currentpage,
              true
            );
          }
        }
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const handleEditVendorOrder = (payload) => {
    editVendorOrder(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          message.success(response.data.message);
          console.log(response, ":response for edit order");
          setProductList([]);
          getProductsList("", currentpage, true);
        } else {
          message.error(response.data.message);
        }
      } else message.error("Some thing went wrong please try again later.");
    });
  };

  const handleExcludeDeliveryCharge = (orderGroupId) => {
    setIsloadingExcludeDeliveryCharge(true);
    excludeDeliveryCharge(orderGroupId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success("Delivery Charge excluded Successfully...");
            getProductsList();
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        message.error("Sorry! Something went wrong. Please try again later!");
      })
      .finally(() => {
        setIsloadingExcludeDeliveryCharge(false);
      });
  };

  const handleDownload = (orderGroupId) => {
    const payload = {
      orderGroupId,
    };
    setIsLoadingDownload(true);
    downloadOrderPickList(payload)
      .then((response) => {
        console.log(response, "response for order pick-list excel sheet");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `order_pickList.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) =>
        message.error("Sorry! Something went wrong. Please try again later!")
      )
      .finally(() => {
        setIsLoadingDownload(false);
      });
  };

  const handleCancelOrder = (reason = "") => {
    setIsLoadingCancel(true);
    const orderIds = orderedItemsOfVendor.map((item) => item.orderId);
    console.log("Order Id Array : ", orderIds);
    const payload = {
      orderGroupId,
      orderIds,
      reasonOfCancellation: reason,
    };
    console.log("Cancel Payload : ", payload);
    cancelOrderGroup(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success("Order has been cancelled");
            getProductsList();
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        message.error("Sorry! Something went wrong. Please try again later!");
      })
      .finally(() => {
        setIsLoadingCancel(false);
      });
  };

  const renderConfirmCancelOrderModal = (reason = "") => {
    setCancelOrderVisible(false);
    Modal.confirm({
      title: "Confirm",
      width: 425,
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to cancel the whole order ? `,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleCancelOrder(reason),
      onCancel: () => {
        console.log("Cancel Order Group Discared!");
      },
    });
  };

  const handleCancelOrderGroup = (orderGroupId, orderedItemsOfVendor) => {
    setOrderGroupId(orderGroupId);
    setOrderedItemsOfVendor(orderedItemsOfVendor);
    setCancelOrderVisible(true);
  };

  const renderConfirmModal = (orderGroupId) => {
    Modal.confirm({
      title: "Confirm",
      width: 480,
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to opt out of delivery for this order? Note** This action will be irreversible!`,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleExcludeDeliveryCharge(orderGroupId),
      onCancel: () => {
        console.log("Exclude Delivery Cancelled!");
      },
    });
  };

  const setSuccess = (
    prdId,
    status,
    accountUserType,
    trackId,
    trackLink,
    mobile,
    name,
    regNo
  ) => {
    setTrackingModal(false);
    const payload = {
      orderGroupId: orderGroupId,
      orderIds: prdId,
      itemOrderStatus: status,
      date: new Date().getTime(),
    };
    const payloadShipped = {
      orderGroupId: orderGroupId,
      orderIds: prdId,
      itemOrderStatus: status,
      date: new Date().getTime(),
      // trackingId: trackId,
      // trackingLink: trackLink
    };
    if (accountUserType == null || accountUserType == "customer") {
      payloadShipped["trackingId"] = trackId;
      payloadShipped["trackingLink"] = trackLink;
    } else {
      payloadShipped["driverNumber"] = mobile;
      payloadShipped["driverName"] = name;
      payloadShipped["vehicleNumber"] = regNo;
    }

    updateOrderStatus(status === "SHIPPED" ? payloadShipped : payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList([]);

          getProductsList(
            props?.searchVal ? props.searchVal : "",
            currentpage,
            true
          );
          setSelectedItems([]);
          setisSelectallChecked(false);
          // setProductList(productList.map(item=>
          //   item.source.orderGroupId==orderGroupId?{...item,source:response.data.order}:item))
        }
      })
      .catch(() => {});
  };

  const clearNewItems = (orderId, isAlreadyViewed, activeId) => {
    console.log("orderId", orderId);

    if (!isAlreadyViewed) {
      const payload = {
        orderGroupId: orderId,
      };
      clearNewItemAPI(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            if (response.data.message) {
              message.success(response.data.message);
            }

            console.log(response, ":response for edit order");
            //   setProductList([]);
            getProductsListAfterAccept(
              props?.searchVal ? props.searchVal : "",
              currentpage,
              response.data.id
            );
            //  setActive(activeId?activeId:"")
          } else {
            if (response.data.message) {
              message.error(response.data.message);
            }
          }
        } else message.error("Some thing went wrong please try again later.");
      });
    }
  };

  return (
    <div className="manage-products-container">
      {" "}
      {/* <div className="manage-products-header">
        {" "} <Title level={4}> Orders</Title>

      </div> */}
      {isLoading ? (
        <Loading />
      ) : productList?.length === 0 ? (
        <Title level={4} className="order-shipped-date">
          {" "}
          <EmptyList msg={"No Orders found"} />
        </Title>
      ) : (
        productList?.map((item) => (
          <Collapse
            bordered={false}
            activeKey={[isActivePanel]}
            accordion
            expandIconPosition="right"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined
                rotate={isActive ? 90 : 0}
                onClick={(event) => {
                  // event.preventDefault();
                  clearNewItems(
                    item.source.orderGroupId,
                    item.source.orderViewed,
                    item.id
                  );
                }}
              />
            )}
            className="stock-collapse"
            onChange={() => {
              setItemCount(item.source.orderedItemsOfVendor.length);
              // console.log("Expanded : ", itemCount);
              setSelectedItems([]);
              setChecked(!checked);
              setisSelectallChecked(false);
              isActivePanel === item.id ? setActive() : setActive(item.id);
            }}
            style={{ marginTop: "20px", background: "#f2f2f2" }}
          >
            <Panel
              className="site-collapse-custom-panel"
              key={item.id}
              style={{
                backgroundColor:
                  item.source.orderViewed === null
                    ? "#fff"
                    : item.source.orderViewed === false
                    ? "#e4fff0"
                    : "#FFF",
                boxShadow:
                  item.source.orderViewed === null
                    ? ""
                    : item.source.orderViewed === false
                    ? "3px 7px 13px 2px rgba(0, 0, 0 ,18%)"
                    : "",
                // backgroundColor: "#e4fff0"
              }}
              header={
                <Row
                  onClick={(event) => {
                    // event.preventDefault();
                    clearNewItems(
                      item.source.orderGroupId,
                      item.source.orderViewed,
                      item.id
                    );
                  }}
                >
                  {/* <Col xs={12} sm={12} md={4} lg={4} xl={4} className="add-image-col">
  
                    <div className="">
                      REF_ID:
                     </div>
  
                  </Col> */}

                  {/* <div className="order_basic_each clmn-1">
                    ORDER ID : <Text strong> {item.source.orderGroupId}</Text>{" "}
                  </div> */}
                  <Col
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    xl={7}
                    className="add-image-col"
                  >
                    <div className="order_basic_each clmn-1">
                      ORDER ID : <Text strong> {item.source.orderGroupId}</Text>{" "}
                    </div>
                  </Col>

                  <Col
                    xs={12}
                    sm={12}
                    md={9}
                    lg={9}
                    xl={9}
                    className="add-image-col"
                  >
                    <div className=" flex">
                      <img
                        src={calendarIcon}
                        style={{ width: "20px" }}
                        className="verified-icon"
                      />
                      {moment(item.source.orderedDate).format(" MMM DD, hh:mm A")}
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={5}
                    lg={5}
                    xl={5}
                    className="add-image-col"
                  >
                    <div className=" flex order-item-active">
                      {" "}
                      <img
                        src={
                          item.source.paymentMode === "ONLINE"
                            ? OnlineIcon
                            : CODIcon
                        }
                        style={{ width: "25px" }}
                        className="verified-icon"
                      />
                      {item.source.paymentMode}{" "}
                    </div>
                  </Col>
                  {/* <Col
                    xs={12}
                    sm={12}
                    md={7}
                    lg={7}
                    xl={7}
                    className="add-image-col"
                  >
                    <div className="address-row-order">
                      <img
                        src={locationIcon}
                        style={{ width: "25px" }}
                        className="verified-icon"
                      />
                      <div className="line-break-location">
                        {item &&
                          item.source &&
                          item.source.shippingAddress &&
                          getValueWithFormat(item.source.shippingAddress)}
                      </div>
                    </div>
                  </Col> */}
                  <Col
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    className="add-image-col"
                  >
                    <div className="item-count">
                      {item?.source?.orderedItemsOfVendor?.length + " ITEMS"}{" "}
                    </div>
                  </Col>
                </Row>
              }
            >
              <div className="manage-order-btn-layout-container view-invoice-layout">
                <div className="cancel-order-btn-wrapper">
                  {item.source.orderStatus === "PENDING" ? (
                    <Button
                      danger
                      type="primary"
                      className="cancel-order-btn"
                      // onClick={() =>
                      //   renderConfirmCancelOrderModal(
                      //     item.source.orderGroupId,
                      //     item.source.orderedItemsOfVendor
                      //   )
                      // }
                      onClick={() => {
                        handleCancelOrderGroup(
                          item.source.orderGroupId,
                          item.source.orderedItemsOfVendor
                        );
                      }}
                    >
                      {isLoadingCancel ? (
                        <Loading style={{ color: "white" }} />
                      ) : (
                        "Cancel Order"
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="manage-orders-button-layout">
                  {props.isWarehouse ? (
                    item.source.ewayBillNumber ? (
                      <div className="generate-eway-bill-clip">
                        <span className="eway-bill-no-fnt">{`Eway-Bill No: ${item.source.ewayBillNumber}`}</span>
                        <CopyToClipboard
                          text={item.source.ewayBillNumber}
                          onCopy={(text, result) =>
                            message.success(`copied ${text} to clipboard`)
                          }
                        >
                          <Tooltip
                            placement="bottom"
                            title={"Copy to Clipboard"}
                          >
                            <CopyOutlined className="generate-eway-bill-clip-icon" />
                          </Tooltip>
                        </CopyToClipboard>
                      </div>
                    ) : (
                      item.source.orderStatus !== "CANCELLED" && (
                        <Button
                          onClick={() => {
                            setgenerateEwayBillVisible(true);
                            setactiveOrder(item.source);
                          }}
                          className="view-invoice-btn generate-eway-bill-btn"
                        >
                          Generate Eway-Bill
                        </Button>
                      )
                    )
                  ) : (
                    <></>
                  )}
                  {/* {console.log("Order ID Array : ",item.source.orderedItemsOfVendor[0].orderId)} */}
                  {!props.isWarehouse &&
                  pickOrderByHandStates.includes(item.source.orderStatus) &&
                  !item?.source?.allItemsShipped &&
                  item.source.deliveryCharge >= 0 ? (
                    <Button
                      onClick={() => {
                        renderConfirmModal(item.source.orderGroupId);
                      }}
                      className="exclude-delivery-btn"
                    >
                      {isloadingExcludeDeliveryCharge ? (
                        <Loading />
                      ) : (
                        "Pick Up Order By Hand"
                      )}
                    </Button>
                  ) : (
                    <></>
                  )}
                  {item.source.orderStatus !== "CANCELLED" ? (
                    <Fragment>
                      <Button
                        onClick={() => {
                          setInvoiceModal(true);
                          setInvoiceItem(formatOrderSummary(item.source));
                        }}
                        className="view-invoice-btn"
                      >
                        View Invoice
                      </Button>
                      <Button
                        onClick={() => {
                          setThermalModal(true);
                          setInvoiceItem(formatOrderSummary(item.source));
                        }}
                        className="view-invoice-btn"
                      >
                        Thermal Print
                      </Button>
                    </Fragment>
                  ) : (
                    <></>
                  )}
                  {pickOrderByHandStates.includes(item.source.orderStatus) &&
                    !item?.source?.allItemsShipped && (
                      <Button
                        onClick={() => handleDownload(item.source.orderGroupId)}
                        className="print-pickList-btn"
                      >
                        {isloadingDownload ? (
                          <Loading />
                        ) : (
                          <div>
                            <span>Print PickList</span>{" "}
                            <PrinterOutlined className="print-icon-antd" />
                          </div>
                        )}
                      </Button>
                    )}

                  {/* [Send to MLM] Button renders only for store view by admin */}
                  {/* {props.isStore ? (
                  activeCustomerType === "admin" ? (
                    <Button type="primary" ghost className="send-to-MLM-btn">
                      Send to MLM
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )} */}
                </div>
              </div>

              {item.source.orderedItemsOfVendor.length > 1 && (
                <div className={"selectall-section-cont"}>
                  {" "}
                  <div className="selectall-checkbox">
                    <Checkbox
                      className="manage-order-checkbox"
                      checked={isSelectallChecked}
                      onChange={(e) => handleSelectAllProducts(e, item)}
                    />
                    Select All Products
                  </div>
                  <div className="seller-action-button">
                    {selectedItems?.length > 1 && (
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => {
                          setSelectionType("multiple");
                          setTrackingModal(true);
                        }}
                        className=""
                      >
                        Update Tracking Details
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {item?.source?.orderedItemsOfVendor?.map((orderdata) => (
                <OrderData
                  updateStatus={updateStatus}
                  checkBoxSelection={checkBoxSelection}
                  orderData={orderdata}
                  orderId={item.id}
                  // checked={checked}
                  paymentMode={item.source.paymentMode}
                  handleEditVendorOrder={handleEditVendorOrder}
                  orderGroupId={item.source.orderGroupId}
                  vendorId={cookies.get("vendorId")}
                  accountType={item.source.accountType}
                  setCancelOrder={setCancelOrder}
                  selectedItems={selectedItems}
                  itemCount={itemCount}
                />
              ))}
            </Panel>
          </Collapse>
        ))
      )}
      {trackingModal && (
        <TrackingDetails
          visible={trackingModal}
          selectedItems={selectedItems}
          setCancel={setCancel}
          groupId={orderGroupId}
          accountType={accountType}
          saveChanges={setSuccess}
        />
      )}
      {invoiceModal && (
        <TaxInvoice
          visible={invoiceModal}
          setvisible={setInvoiceModal}
          orderSummary={invoiceItem}
        />
      )}
      {thermalModal && <ThermalIndex setvisible={setThermalModal} orderSummary={invoiceItem} />}
      {total > 10 && (
        <div className="admin-manage-customer">
          <Pagination
            defaultCurrent={1}
            current={currentpage}
            showSizeChanger={false}
            total={total}
            className="customer-pagination"
            onChange={(page) => {
              getProductsList(props.searchVal, page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}
      <GenerateEwayBill
        visible={generateEwayBillVisible}
        setvisible={setgenerateEwayBillVisible}
        orderDetails={activeOrder}
        getProductsList={getProductsList}
      />
      {cancelOrderModal === true && (
        <CancelOrderRequest
          visible={cancelOrderModal}
          setVisible={setCancelOrderVisible}
          setSuccess={renderConfirmCancelOrderModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.manageOrderReducer,
  };
};
export default withRouter(connect(mapStateToProps)(ManageOrderGroups));
