


import {
  FETCH_ORDER_RESPONSE,
  REFETCH_ORDER_LIST,
  FETCH_ORDER_FAILURE,
} from "./types";

export const fetchOrderResponse = (data) => {
  return {
    type: FETCH_ORDER_RESPONSE,
    data,
  };
};

export const refetchOrderList = () => {
  return {
    type: REFETCH_ORDER_LIST,
    data: null,
  };
};

export const fetchOrderFailure = (data) => {
  return {
    type: FETCH_ORDER_FAILURE,
    data,
  };
};
