import ManageSeller from "../ManageSeller/manageSeller";
import React, { useState } from "react";
const ManageAffiliate = (props) => {

  const [customerData, setCustmerData] = useState([]);
  const [isLoadingAffiliate, setisLoadingAffiliate] = useState(false);

  return (
    <div className="admin-manage-customer">
      <ManageSeller
        userData={customerData}
        isLoadingWarehouse={isLoadingAffiliate}
        isAffiliate
        openAdd={props.openAdd}
        addModal={props.addModal}
        searchVal={props.searchVal}
      />
    </div>
  )
};
export default ManageAffiliate;