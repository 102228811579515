

/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, Fragment } from "react";
import { Card, Typography, Button, Modal, message } from "antd";
import NoImage from "../../../../Assets/Images/PlaceHolderImages/placeholder-product-card.jpg";
import Cookies from "universal-cookie";
import "./manageProductCardDraft.css";
import { withRouter } from "react-router-dom";

import EditIcon from "../../../../Assets/Images/Icons/Seller/edit.svg";
import EditIconMobile from "../../../../Assets/Images/Icons/Seller/editMobile.svg";
import DeleteIcon from "../../../../Assets/Images/Icons/Seller/delete.svg";
import { ExclamationCircleOutlined, PropertySafetyFilled } from "@ant-design/icons";
import { FILE_PATH } from "../../../../Utils/constants";
import { handleAddFromCentralRepo } from "../../../../ApiAction/SellerApiActions/sellerApi";
import Loading from "../../../GeneralComponents/Loading";

import LazyImage from "../../ManageOrder/CustomLazyImage";
import StockOverview from "../../ManageStocks/StockOverview/stockOverview";
import ReplaceProductModal from "../../../Admin/CentralRepo/components/replaceProductModal";
import { deleteCentralRepoProduct } from "../../../../ApiAction/AdminApiAction/adminApi";
import { GoDiff, GoVerified } from "react-icons/go";
import { updateProductInCentralRepo } from "../../../../ApiAction/AdminApiAction/centralRepoApi";
const confirm = Modal.confirm;
const { Text } = Typography;
const ManagaProductCardDraft = (props) => {
  const { isCentralRepo, isActive,setReload, isVendor, isSingleStock, stocksOverviews } =
    props;
  const cookies = new Cookies();
  const [isMobileView, setisMobileView] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [subCategory0Name, setSubCategory0Name] = useState("");
  const [subCategory1Name, setSubCategory1Name] = useState("");
  const [subCategory2Name, setSubCategory2Name] = useState("");
  const [subCategory3Name, setSubCategory3Name] = useState("");
  const [imageLoadError, setImageLoadError] = useState(false);
  const [isLoadingAddToStore, setisLoadingAddToStore] = useState(false);
  const [stockOverViewVisible, setstockOverViewVisible] = useState(false);
  const [replaceModal, setReplaceModal] = useState(false);
  const [isVerified, setVerified] = useState(props.product.source.verified);
  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    setCategoryName(props.product.source.categoryName);
    setSubCategory0Name(
      props.product.source.subCategory0Name &&
        props.product.source.subCategory0Name != ""
        ? " / " + props.product.source.subCategory0Name
        : ""
    );
    setSubCategory1Name(
      props.product.source.subCategory1Name &&
        props.product.source.subCategory1Name != ""
        ? " / " + props.product.source.subCategory1Name
        : ""
    );
    setSubCategory2Name(
      props.product.source.subCategory2Name &&
        props.product.source.subCategory2Name != ""
        ? " / " + props.product.source.subCategory2Name
        : ""
    );
    setSubCategory3Name(
      props?.product?.source?.subCategory3Name &&
        props?.product?.source?.subCategory3Name != ""
        ? " / " + props?.product?.source?.subCategory3Name
        : ""
    );
  }, [props.product]);

  const handleRedirectionToEdit = (productId) => {
    if (isCentralRepo)
      props.history.push(`/admin/central-repo/edit-product/${productId}`);
    else
      switch (cookies.get("accountType")) {
        case "seller":
          props.history.push(`/seller/edit-product/${productId}`);
          break;

        case "warehouse":
          props.history.push(`/warehouse/edit-product/${productId}`);
          break;
        case "store":
          props.history.push(`/store/edit-product/${productId}`);
          break;
        default:
          console.log("invalida choice");
      }
  };

  const publish = (id) => {
    confirm({
      title: "Do you want to move this product to listing!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        props.publish(props.product.source.productId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //replace central repo product modal
  const replaceProduct=() =>{
    setReplaceModal(true);
    console.log("STATUS::" + replaceModal);
  }

  const deleteProduct = (id) => {
    confirm({
      title: "Do you want to delete this product!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        props.deleteProduct(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  
  //delete central repo product
  const removeCentralRepoProduct =(prodId)=>{
    const payload = {
        id :prodId,
        forceDelete: false
    }
    deleteCentralRepoProduct(payload).then((response) =>{
      if(response?.data?.status === "success"){
        confirm({
          title: 'Deletion Warning',
          icon: <ExclamationCircleOutlined />,
          content: response?.data?.message,
          onOk() {
            const payload = {
              id :prodId,
              forceDelete: true
          }
          deleteCentralRepoProduct(payload).then((response) =>{
            if(response?.data?.status === "success"){
              setReload((prev) => {
                return !prev;
              });
              Modal.success({
                title: 'Successfully Deleted.',
                content: (
                    <div>
                    <p>{response?.data?.message}</p>
                  </div>
                ),
              });
            }else{
              Modal.error({
                title: 'Deletion failed',
                content: (
                    <div>
                    <p>{response?.data?.message}</p>
                  </div>
                ),
              });
            }
          })
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }else{
        message.error(response?.data?.message)
      }
    })
  }

  const updateVerifyStatus = () => {
    console.log(props.product)
    const payload = {
      id: props.product.source.productId,
      product: {verified: !isVerified}
    }
    updateProductInCentralRepo(payload)
      .then(response => {
        if (response.status === 200) {
          if (response?.data?.status === "success") {
            message.success("Product updated successfully");
            setVerified(!isVerified);
          } else message.error(response.data.message);
        }
      });
  }

 

  const handleAddProductToVendorDB = (product) => {
    console.log("product to add to store:", product);
    setisLoadingAddToStore(true);
    handleAddFromCentralRepo(product.productId)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.status === "success") {
            message.success(response.data.message);
          } else message.error(response.data.message);
        }
        console.log(response, ":response for add from central repo");
      })
      .catch(() => {})
      .finally(() => {
        setisLoadingAddToStore(false);
      });
  };
  return (
    <Card
      hoverable
      bordered={false}
      height={250}
      style={{ width: "100%", marginBottom: "20px", marginTop: "10px" }}
      className="manage-product-card-container"
      onClick={() => {
        props.isStock && props.singleClick(props.product.source.productId);
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {imageLoadError ? (
            <img
              src={NoImage}
              alt={
                props &&
                props.product &&
                props.product.source &&
                props.product.source.name
                  ? props.product.source.name
                  : "company"
              }
              className="manage-products-draft-product-card-image"
            />
          ) : (
            <img
              className="manage-products-draft-product-card-image"
              src={
                props.product.source.images
                  ? props.product.source.images.length > 0
                    ? FILE_PATH + props.product.source.images[0]
                    : NoImage
                  : NoImage
              }
              alt={props.product.source.name}
              onError={() => setImageLoadError(true)}
            />

            /* <LazyImage classname="manage-products-draft-product-card-image"
         src={
                props.product.source.images
                  ? props.product.source.images.length > 0
                    ? FILE_PATH + props.product.source.images[0]
                    : NoImage
                  : NoImage
              }
          /> */
          )}
        </div>
        <div className="seller-manage-product-card-summary">
          <div
            style={{
              maxWidth: "500px",
            }}
          >
            {isMobileView ? (
              <Text style={{ fontSize: "12px" }} strong>
                {props.product.source.name}
              </Text>
            ) : (
              <Text style={{ fontSize: "20px" }} strong>
                {props.product.source.name}
              </Text>
            )}

            <div className="addedType-container">
              {props?.product?.source?.addedType === "admin" ? (
                <p className="addedType-tags addedType-admin">ADMIN</p>
              ) : (
                <Fragment>
                  {props?.product?.source?.addedType === "store" ? (
                    <p className="addedType-tags addedType-store">STORE</p>
                  ) : (
                    <Fragment>
                      {props?.product?.source?.addedType === "seller" ? (
                        <p className="addedType-tags addedType-seller">
                          SELLER
                        </p>
                      ) : (
                        <Fragment>
                          {props?.product?.source?.addedType ===
                            "warehouse" && (
                            <p className="addedType-tags addedType-warehouse">
                              HUB
                            </p>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Fragment>
              )}
              {isVerified && (
                <p className="verified-tag">
                <div style={{paddingRight: "0.2em", paddingTop: "0.1em"}}><GoVerified></GoVerified></div>
                Verified
                </p>
              )}
               {isCentralRepo && props?.product?.source?.hsnCodeModifiedDate && (
                <p className="hsn-modified-tag">
                <div style={{paddingRight: "0.2em", paddingTop: "0.1em"}}><GoDiff></GoDiff></div>
                HSN Modified
                </p>
              )}
            </div>
            
            {isCentralRepo && (
              <div style={{ fontSize: "18px"}}>
                <div strong>
                  Brand Name: {props.product.source.brandName}
                </div>
                <div strong>
                  HSN Code: {props.product.source.hsnCode}
                </div>
                <div strong>
                  Tax Percent: {props.product.source.taxPerCent}
                </div>
              </div>
            )}

            <br style={{ marginBottom: "30px" }} />
            {isMobileView ? (
              props?.product?.source?.categoryName && (
                <Text style={{ color: "green", fontSize: "10px" }}>
                  {categoryName +
                    subCategory0Name +
                    subCategory1Name +
                    subCategory2Name +
                    subCategory3Name}
                </Text>
              )
            ) : (
              <Text style={{ color: "green", fontSize: "18px" }}>
                {categoryName +
                  subCategory0Name +
                  subCategory1Name +
                  subCategory2Name +
                  subCategory3Name}
              </Text>
            )}
          </div>
        </div>

        {isVendor && isCentralRepo ? (
          <Button
            className="seller-manage-products-card-action-button-edit"
            type="primary"
            onClick={() => handleAddProductToVendorDB(props.product.source)}
          >
            {isLoadingAddToStore ? (
              <Loading style={{ color: "white" }} />
            ) : (
              <Fragment>
                <img
                  src={EditIcon}
                  style={{ width: "35px", paddingRight: "15px" }}
                />
                Add Product
              </Fragment>
            )}
          </Button>
        ) : (
          !props.isStock && (
            <div className="seller-manage-products-card-action">
              {!isMobileView ? (
                <>
                  <Button
                    className="seller-manage-products-card-action-button-edit"
                    type="primary"
                    onClick={() =>
                      handleRedirectionToEdit(props.product.source.productId)
                    }
                  >
                    <img
                      src={EditIcon}
                      style={{ width: "35px", paddingRight: "15px" }}
                    />
                    Edit
                  </Button>
                  {isSingleStock && stocksOverviews?.length > 0 && (
                    <Button
                      className="seller-manage-products-card-action-button-edit"
                      type="primary"
                      onClick={() => setstockOverViewVisible(true)}
                    >
                      Stock Overview
                    </Button>
                  )}
                </>
              ) : (
                <img
                  src={EditIconMobile}
                  style={{ width: "35px", paddingRight: "20px" }}
                />
              )}
              {!props.isSingleStock && (
                <React.Fragment>
                  {!isMobileView ? (
                    props.isAdmin && (
                      <Button
                        className="seller-manage-products-card-action-button"
                        onClick={() =>
                          deleteProduct(props.product.source.productId)
                        }
                      >
                        <img
                          src={DeleteIcon}
                          style={{ width: "35px", paddingRight: "20px" }}
                        />
                        Delete
                      </Button>
                    )
                  ) : (
                    <img
                      src={DeleteIcon}
                      style={{ width: "35px", paddingRight: "20px" }}
                    />
                  )}
                  {!isMobileView ? (
                    <Button
                      className="seller-manage-products-card-action-button-edit"
                      type="primary"
                      onClick={() => publish(props.product.source.productId)}
                    >
                      {isActive ? "Un Publish" : "Publish"}
                    </Button>
                  ) : (
                    <img
                      src={EditIconMobile}
                      style={{ width: "35px", paddingRight: "20px" }}
                    />
                  )}
                  {!isMobileView && isActive ? (
                    <Button 
                      className="seller-manage-products-card-action-button-edit"
                      type="primary"
                      onClick={() => replaceProduct()}
                    >
                      Replace
                    </Button>
                  ) : (
                    ""
                  )}
                  {!isMobileView && isActive ? (
                    <Button 
                      className="seller-manage-products-card-action-button-edit"
                      type="primary"
                      onClick={() => removeCentralRepoProduct(props.product.source.productId)}
                    >
                      Permanent Delete
                    </Button>
                  ) : (
                    ""
                  )}
                  {!isMobileView && isActive ? (
                    <Button 
                      className="seller-manage-products-card-action-button-edit"
                      type="primary"
                      onClick={() => updateVerifyStatus(props.product.source.productId)}
                    >
                      {isVerified? "Unverify": "Verify"}
                    </Button>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              )}
            </div>
          )
        )}
      </div>
      <StockOverview
        visible={stockOverViewVisible}
        setvisible={setstockOverViewVisible}
        product={props.product}
        stocksOverviews={stocksOverviews}
      />
      <ReplaceProductModal
      visible={replaceModal}
      setVisible={setReplaceModal}
      setReload={setReload}
      product={props?.product?.source?.name}
      prodId = {props?.product?.id}
      />
    </Card>
  );
};
export default withRouter(ManagaProductCardDraft);
