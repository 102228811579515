


import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./orderList.scss";
import Cookies from "universal-cookie";
import { Card, Button, Typography, Divider, message } from "antd";
import CardIcon from "../../../../../Assets/Images/Icons/card.svg";
import DateIcon from "../../../../../Assets/Images/Icons/date.svg";
import ExpandIcon from "../../../../../Assets/Images/Icons/collapse_2.svg";
import HideIcon from "../../../../../Assets/Images/Icons/collapse_1.svg";

import CODIcon from "../../../../../Assets/Images/Icons/cod_mode.svg";
import OnlineIcon from "../../../../../Assets/Images/Icons/online_mode.svg";

import OrderContext from "./Context/orderContext";
import OrderByVendor from "./orderByVendor";
import Invoice from "./invoice";
import { formatOrderSummary, getDeliveryAddress } from "../manageOrdersHelper";
import TaxInvoice from "./TaxInvoice";
import Loading from "../../../../GeneralComponents/Loading";
import {
  reOrderGroup,
  reOrderGroupStoreWarehouse,
} from "../../../../../ApiAction/SellerApiActions/vendoOrderAPI";
import { fetchCartCount } from "../../../../../ReduxStore/action/";

const { Text } = Typography;

const OrderGroup = (props) => {
  const cookies = new Cookies();
  const { order, loading } = props;
  const [expanded, setexpanded] = useState(false);
  const [orderByVendorList, setorderByVendorList] = useState([]);
  const [invoiceVisible, setinvoiceVisible] = useState(false);
  const [orderSummary, setorderSummary] = useState({});
  const [isLoadingReOrder, setIsLoadingReOrder] = useState(false);

  const expandOrderHandler = () => {
    setexpanded(!expanded);
  };

  const handleReOrderGroup = (orderGroupId) => {
    setIsLoadingReOrder(true);
    const payload = {
      orderGroupId,
      accountType: cookies.get("accountType"),
      userId: cookies.get("userId"),
    };
    // console.log("reOrderGroup Payload is:", payload);
    payload.accountType === "store" || payload.accountType === "warehouse"
      ? reOrderGroupStoreWarehouse(payload)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                message.success("Item added to cart !");
                props.fetchCartCount(props.cartCountStore + 1);
              } else {
                message.error(response.data.message);
              }
            }
          })
          .catch((error) => {
            message.error(
              "Sorry! Something went wrong. Please try again later!"
            );
          })
          .finally(() => {
            setIsLoadingReOrder(false);
          })
      : reOrderGroup(payload)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                message.success("Item added to cart !");
                props.fetchCartCount(props.cartCountStore + 1);
              } else {
                message.error(response.data.message);
              }
            }
          })
          .catch((error) => {
            message.error(
              "Sorry! Something went wrong. Please try again later!"
            );
          })
          .finally(() => {
            setIsLoadingReOrder(false);
          });
  };

  useEffect(() => {
    !loading && setorderByVendorList(order.processedOrderedItems);
    setorderSummary(formatOrderSummary(order));
    getDeliveryAddress(order);
  }, [order]);

  return (
    <Card style={{ width: "100%", marginBottom: "20px" }} loading={loading}>
      {!loading && (
        <Fragment>
          <div className="customer_order_basic_details">
            <div className="order_basic_each clmn-1">
              ORDER ID : <Text strong> {order.orderGroupId}</Text>{" "}
            </div>
            <Divider
              type="vertical"
              style={{ height: "40px", marginRight: "40px" }}
            />
            <div className="odr_base">
              <div className="order_basic_each">
                {" "}
                <img src={DateIcon} className="order_basic_each_icon" />
                <Text strong>
                  {moment(order.orderedDate).format(" MMM DD YYYY ")}
                </Text>
              </div>
              <div className="order_basic_each">
                {" "}
                <img
                  src={order.paymentMode === "ONLINE" ? OnlineIcon : CODIcon}
                  className="order_basic_each_icon"
                />
                <Text strong style={{ color: "#a4d168" }}>
                  {order.paymentMode}
                </Text>
              </div>
            </div>
            <div className="flex-wrapper">
              <div className="order_basic_each invoice_btn resp_hidden">
                {/* {order.orderStatus !== "CANCELLED" ? (
                  <Button onClick={() => setinvoiceVisible(!invoiceVisible)}>
                    View Invoice
                  </Button>
                ) : (
                  <Text strong style={{ color: "#FF0000" }}>
                    ORDER CANCELLED
                  </Text>
                )} */}

                {order?.orderStatus === "CANCELLED" && (
                  <Text strong style={{ color: "#FF0000" }}>
                    ORDER CANCELLED
                  </Text>
                )}
              </div>
              <div className="re-order-btn-container">
                {order.orderStatus === "DELIVERED" ||
                order.orderStatus === "CANCELLED" ? (
                  <Button
                    // danger
                    type="primary"
                    className="re-order-btn"
                    onClick={() => {
                      handleReOrderGroup(order.orderGroupId);
                    }}
                  >
                    {isLoadingReOrder ? (
                      <Loading style={{ color: "white" }} />
                    ) : (
                      "Reorder"
                    )}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <img
              onClick={() => expandOrderHandler()}
              src={expanded ? HideIcon : ExpandIcon}
              className="expand-icon"
              style={{ width: "40px", cursor: "pointer" }}
            />
          </div>
          {expanded && (
            <OrderContext.Provider value={{ orderSummary: orderSummary }}>
              <div className="manage_order_details_container">
                <div className="order_basic_each invoice_btn resp_visible">
                  <Button onClick={() => setinvoiceVisible(!invoiceVisible)}>
                    View Invoice
                  </Button>
                </div>
                <div className="scrol_colmn">
                  {orderByVendorList.map((vendor) => {
                    return (
                      <OrderByVendor
                        order={order}
                        vendor={vendor}
                        groupId={order.orderGroupId}
                        orderStatus={order.orderStatus}
                      />
                    );
                  })}
                </div>
              </div>
            </OrderContext.Provider>
          )}
        </Fragment>
      )}

      {/* <Invoice
        visible={invoiceVisible}
        setvisible={setinvoiceVisible}
        orderSummary={orderSummary}
      /> */}
      <TaxInvoice
        visible={invoiceVisible}
        setvisible={setinvoiceVisible}
        orderSummary={orderSummary}
      />
    </Card>
  );
};

const mapDispatchToProps = { fetchCartCount };
const mapStateToProps = (state) => {
  return {
    cartCountStore: state.cartCountReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderGroup)
);
