


import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import { Modal, Divider, Typography, Input, Row, Col, Image, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// import "./manageReturnsCustomer.scss"
import onlineIcon from "../../../../Assets/Images/Icons/Seller/online-shopping.svg";
import calendarIcon from "../../../../Assets/Images/Icons/Seller/calendar.svg";
// import RequestDetails from "./requestDetails";
import { getCustomerReturns, getCustomerReturnsStatusUpdate } from "../../../../ApiAction/CustomerApiActions/orderAPI";
import { ENV_TYPE, FILE_PATH } from "../../../../Utils/constants";
import EmptyList from "../../../GeneralComponents/emptyList";
import { history } from "../../../../App";
const { Text } = Typography;
const SubscribeList = (props) => {
  const cookies = new Cookies();

  const [detailModal, setDetailModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)

  const [productItem, setProductItem] = useState()
  const [productList, setProductList] = useState([])
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)

  //     useEffect(() => {


  //         getProductsList(props.searchVal,1)

  //    }, [props])

  const accountType = cookies.get("accountType")
    ? cookies.get("accountType")
    : "customer";

  const getProductsList = (search = "", page) => {


    const payload = {
      //  offset:10 * (page - 1),
      //  size:10,
      userId: cookies.get("userId"),
      key: search,
      sortBy: "requestedDate",
      sortType: props ? props.sort && props.sort.sortBy ? props.sort.sortBy : "desc" : "desc",


    }
    getCustomerReturns(payload)
      .then((response) => {
        setLoading(true)
        if (response.data.status == "success") {
          setProductList(response.data.documents)
          setPage(Math.ceil(response.data.count / 10) * 10)
        }

      })
      .catch(() => {

      })

  };
  const cancelSubscription = (id) => {
    Modal.confirm({
      title: "Confirm",
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to cancel subscription ? `,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => props.cancelSubscribe(id),
    });
  }

  const handleRedirection = (productId) => {
    // console.log("Subcsribed product data is",productId);
    // if (accountType === "store") {
    //   history.push(
    //     `/store/linked-warehouse/product-detail/${product.productId}`
    //   );
    // } else
    if (accountType === "customer") {
      history.push(
        ENV_TYPE === "production"
          ? `/product-detail/${productId}`
          : `/customer/product-detail/${productId}`
      );
    }
    //  else if (accountType === "warehouse") {
    //   history.push(
    //     `/warehouse/linked-warehouse/product-detail/${product.productId}`
    //   );
    // }
  };

  return (
    <div className="manage_orders_container_card admin-manage-customer">
      {/* <Text strong className="manae_orders_title">
        Orders
      </Text> */}
      <div>
        {props.productList.length === 0 && loading && (
          <EmptyList msg={"No items found"} />
        )}
        {props.productList && props.productList.map(data =>
          <div className="justify-space-between customer-return-list" key={data.id}>
            <div className="flex custr_mng_rtrn">
              <div style={{cursor: "pointer" }} className="return_pdt_img" onClick={() => handleRedirection(data.source.productId)}>
                <img className="manage-products-draft-product-card-image" src={FILE_PATH+data.source.image} alt={data.source.productName}/>
              </div>
              <div className="return-data-card-seller">
                <div style={{ cursor: "pointer" }} className="seller-return-product-name" onClick={() => handleRedirection(data.source.productId)}><h3 style={{textTransform: "capitalize", fontWeight: "bold"}}>{data.source.productName}</h3></div>
                <div className="flex">
                  <div className="seller-return-product-price">₹ {data.source.itemCurrentPrice.sellingPrice}</div>
                  <div className="seller-return-product-price">
                    <Text style={{ color: "#c1c1c1", marginLeft: "15px" }}>Quantity: {data.source.quantity}</Text>
                  </div>
                </div>
                <div className="seller-return-product-price">
                  <Text delete style={{ color: "#c1c1c1" }}>₹ {data.source.itemCurrentPrice.price}</Text>
                </div>
                <div className="seller-return-name" style={{ paddingBottom: "8px" }}>
                  {data.source.itemCurrentPrice.quantity + " " + data.source.itemCurrentPrice.unit}
                </div>
                <div className="flex-align-center date_return rtn-mob">
                  <img src={calendarIcon} style={{ width: "16px" }} className="verified-icon" alt="requested_date"/>
                  {moment(data.source.subscribedDate).format(" MMM DD, hh:mm A")}
                </div>
                <div className="flex order-item-active ord_typ rtn-mob"> 
                  <img src={onlineIcon} style={{ width: "20px" }} className="verified-icon" alt="online_icon"/>
                  {data.source.period} 
                </div> 
              </div>
            </div>
            <div className="mob_visible_rtn_data custr_mng_rtrn justify-content-between">
              <div className="frm_ip_small">
                <div className="flex-align-center date_return">
                  <img src={calendarIcon} style={{ width: "16px" }} className="verified-icon" alt="requested_date"/>
                  {moment(data.source.subscribedDate).format(" MMM DD, hh:mm A")}
                </div>
                <div className="flex order-item-active ord_typ"> 
                  <img src={onlineIcon} style={{ width: "20px" }} className="verified-icon"  alt="online_icon"/>
                  {data.source.paymentMode} 
                </div>
              </div>

            </div>
            <div className="algn_retn_data">

              <div className="date-ac" style={{ textAlign: "end" }}>
                {moment(data.source.lastOrderedDate).format(" MMM DD")}
              </div>
              <div className="btn_grps" style={{ display: "flex", flexDirection: "column" }}>
                <Button style={{ minWidth: "180px", padding: "0", marginTop: "10px", height: "38px", borderColor: "#0074be", backgroundColor: "#0074be", color: "#fff", fontWeight: "500" }}
                  onClick={() => cancelSubscription(data.id)}
                  className="button-seller-request-approve" >
                  Cancel Subscription
                </Button>

              </div>
            </div>
          </div>
        )}
      </div>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};

export default connect(mapStateToProps, null)(SubscribeList);
