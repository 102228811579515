import React, { useEffect, useState, useRef } from 'react';
import JSONPretty from 'react-json-pretty';
import { IoEarth, IoCloseCircleSharp, IoTimerOutline } from "react-icons/io5";
import { AiFillApple, AiFillChrome } from "react-icons/ai";
import { FaOpera, FaFirefox, FaSafari, FaWindows, FaLinux } from "react-icons/fa";
import { HiCode } from "react-icons/hi";
import { GoScreenFull } from "react-icons/go";
import { BsBraces } from "react-icons/bs";
import { GrContactInfo } from "react-icons/gr";
import { FiCopy } from "react-icons/fi";
import { HiCursorClick } from "react-icons/hi";
import moment from 'moment';
import { message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import 'react-json-pretty/themes/monikai.css';

function LogDetailedTable(props) {

    const [ originalCode, setOriginalCode ] = useState(props?.data?.source?.currentValues?.oldValue)
    const [ newCode, setNewCode ] = useState(props?.data?.source?.currentValues?.newValue)
    const { setShowModel } = props;

    const filterActionName = (actionName) => {
        switch(actionName) {
            case 'BATCH CREATED':
                return(
                    <p className="LogDataTable-user-info-green-cards">{actionName}</p>
                )
            case 'UNARCHIVED':
            case 'UNBLOCKED':
            case 'BATCH UPDATED':
                return(
                    <p className="LogDataTable-user-info-orange-cards">{actionName}</p>
                )
            case 'ARCHIVE':
            case 'BATCH REMOVED': 
            case 'BLOCKED':
                return(
                    <p className="LogDataTable-user-info-red-cards">{actionName}</p>
                ) 
        }
    }

    const shrinkURL = (url) => {
        let shorter = url.substring(0,30);
        return shorter + "...";
    }

    const filterBrowser = (browserName) => {
        switch(browserName) {
            case 'Chrome':
                return (
                    <AiFillChrome className="LogDataTable-1-row-icon" />
                )
            case 'Opera':
                return (
                    <FaOpera className="LogDataTable-1-row-icon" />
                )
            case 'Firefox':
                return (
                    <FaFirefox className="LogDataTable-1-row-icon" />
                )
            case 'Safari':
                return (
                    <FaSafari className="LLogDataTable-1-row-icon" />
                )
        }
    }

    const filterBrowser2 = (browserName) => {
        switch(browserName) {
            case 'Chrome':
                return (
                    <AiFillChrome className="LogDataTable-1-row-icon-opacity" />
                )
            case 'Opera':
                return (
                    <FaOpera className="LogDataTable-1-row-icon-opacity" />
                )
            case 'Firefox':
                return (
                    <FaFirefox className="LogDataTable-1-row-icon-opacity" />
                )
            case 'Safari':
                return (
                    <FaSafari className="LogDataTable-1-row-icon-opacity" />
                )
        }
    } 

    const filterOs = (osName) => {
        switch(osName) {
            case 'Mac':
                return (
                    <AiFillApple className="LogDataTable-1-row-icon" />
                )
            case 'Windows':
                return (
                    <FaWindows className="LogDataTable-1-row-icon" />
                )
            case 'Linux':
                return (
                    <FaLinux className="LogDataTable-1-row-icon" />
                )

        }
    }

    const filterOs2 = (osName) => {
        switch(osName) {
            case 'Mac':
                return (
                    <AiFillApple className="LogDataTable-1-row-icon-opacity" />
                )
            case 'Windows':
                return (
                    <FaWindows className="LogDataTable-1-row-icon-opacity" />
                )
            case 'Linux':
                return (
                    <FaLinux className="LogDataTable-1-row-icon-opacity" />
                )

        }
    }    

    return (
        <div className="LogDataTable-details-model">
            <div className="LogDataTable-details-model-container">
                <IoCloseCircleSharp className="LogDataTable-details-model-container-close-button" onClick={()=> setShowModel(false)} />
                {/* <span className="LogDataTable-details-model-url-container">
                    <IoEarth className="LogDataTable-details-model-url-icon" />
                    <input type="text" className="LogDataTable-details-url-input" ref={urlRef} defaultValue={props?.data?.source.pageURL} onChange={checkURLEmpty} />
                    { urlIsEmpty && (<MdRefresh className="LogDataTable-details-model-refresh-icon" onClick={()=>setURLToDefault(props?.data?.source.pageURL)} />)}
                </span> */}
                <span className="LogDataTable-heading-and-id">
                    <p className="LogDataTable-model-id">#{props?.data?.source?.logID}</p>
                    <p className="LogDataTable-model-heading">{props?.data?.source?.logMessage}</p>
                </span>

                {/* <div className="LogDataTable-row-2-grid-3">
                        {filterOs(props?.data?.source?.userInformations?.deviceModel)}
                        {filterBrowser(props?.data?.source?.userInformations?.browserInfo)}
                        <div className="LogDataTable-grid-user-info-box">
                            <p>{props?.data?.source?.userInformations?.username}</p>
                            <p>#{props?.data?.source?.userInformations?.userId}</p>
                            {filterActionName(props?.data?.source?.action)}
                        </div>
                </div> */}

                <div className="LogDataTable-row-2">

                    <div className="LogDataTable-1-row-2-col">
                        <GrContactInfo className="LogDataTable-1-row-icon" />
                        <span>
                            <p>about user</p>
                            <p>{props?.data?.source?.userInformations?.username}</p>
                        </span>
                        <GrContactInfo className="LogDataTable-1-row-icon-opacity" />
                    </div>

                    <div className="LogDataTable-1-row-2-col">
                        <HiCursorClick className="LogDataTable-1-row-icon" />
                        <span>
                            <p>Action Name</p>
                            <p>{props?.data?.source?.action}</p>
                        </span>
                        <HiCursorClick className="LogDataTable-1-row-icon-opacity" />
                    </div>   

                    <div className="LogDataTable-1-row-2-col">
                        {filterOs(props?.data?.source?.userInformations?.deviceModel)}
                        <span>
                            <p>O.S</p>
                            <p>{props?.data?.source?.userInformations?.deviceModel}</p>
                        </span>
                        {filterOs2(props?.data?.source?.userInformations?.deviceModel)}
                        {/* <IoTimerOutline className="LogDataTable-1-row-icon-opacity" /> */}
                    </div>

                    <div className="LogDataTable-1-row-2-col">
                        {filterBrowser(props?.data?.source?.userInformations?.browserInfo)}
                        <span>
                            <p>Browser</p>
                            <p>{props?.data?.source?.userInformations?.browserInfo}</p>
                        </span>
                        {filterBrowser2(props?.data?.source?.userInformations?.browserInfo)}
                    </div>  

                    <div className="LogDataTable-1-row-2-col">
                        <IoEarth className="LogDataTable-1-row-icon" />
                        <span>
                            <p>Page url</p>
                            <CopyToClipboard text={props?.data?.source?.pageURL} onCopy={()=> message.info("URL Copied")}>
                            <p id="pageURLShortner">{shrinkURL(props?.data?.source?.pageURL)}</p>
                            </CopyToClipboard>
                        </span>
                        <IoEarth className="LogDataTable-1-row-icon-opacity" />
                    </div>                                                          

                    <div className="LogDataTable-1-row-2-col">
                        <HiCode className="LogDataTable-1-row-icon" />
                        <span>
                            <p>Class name</p>
                            <p>{props?.data?.source?.className}</p>
                        </span>
                        <HiCode className="LogDataTable-1-row-icon-opacity" />
                    </div>

                    <div className="LogDataTable-1-row-2-col">
                        <BsBraces className="LogDataTable-1-row-icon" />
                        <span>
                            <p>Function name</p>
                            <p>{props?.data?.source?.functionName}</p>
                        </span>
                        <BsBraces className="LogDataTable-1-row-icon-opacity" />
                    </div>

                    <div className="LogDataTable-1-row-2-col">
                        <GoScreenFull className="LogDataTable-1-row-icon" />
                        <span>
                            <p>Screen Name</p>
                            <p>{props?.data?.source?.screenName}</p>
                        </span>
                        <GoScreenFull className="LogDataTable-1-row-icon-opacity" />
                    </div>

                    <div className="LogDataTable-1-row-2-col">
                        <IoTimerOutline className="LogDataTable-1-row-icon" />
                        <span>
                            <p>Timestamp</p>
                            <p>{moment(props?.data?.source?.timestamp).format('YYYY-MM-DD HH:MM A')}</p>
                        </span>
                        <IoTimerOutline className="LogDataTable-1-row-icon-opacity" />
                    </div>

                                                                                                  
                </div>
                

                <div className="LogDataTable-details-model-compare-container">
                    <div className="LogDataTable-details-model-compare-each-box original-code-box" data-code-type="ORIGINAL FILE">
                        <JSONPretty className="json-pretty" data={originalCode}></JSONPretty>
                        <CopyToClipboard text={JSON.stringify(originalCode)} onCopy={()=> message.info("File copied.")}>
                            <FiCopy className="LogDataTable-copyToClipBoard-icon" />
                        </CopyToClipboard>
                    </div>
                    <div className="LogDataTable-details-model-compare-each-box new-code-box" data-code-type="NEW FILE">
                        <JSONPretty className="json-pretty" data={newCode}></JSONPretty>
                        <CopyToClipboard text={JSON.stringify(newCode)} onCopy={()=> message.info("File Copied.")}>
                            <FiCopy className="LogDataTable-copyToClipBoard-icon" />
                        </CopyToClipboard>                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogDetailedTable
