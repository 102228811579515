


import React, { useEffect, useState } from "react";
import { Typography, Tabs } from "antd";
import { connect } from "react-redux";

import ManageCustomerTable from "../ManageCustomer/manageCustomerTable";
import ManageStoreTable from "./manageStoreTable";
import EditStoreModal from "./addStoreModal";
import {
  fetchUsers,
  fetchVendors,
} from "../../../ApiAction/AdminApiAction/adminApi";
import AddStoreModal from "./addStoreModal";
const { TabPane } = Tabs;
const { Title } = Typography;
const ManageStore = (props) => {
  console.log("This is Store Propsss :");
  console.log(props);
  const [customerData, setCustmerData] = useState([]);
  const [addStoreVisible, setAddStoreVisible] = useState(false);
  const [editItem, setEditItem] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [activeKey, setActiveKey] = useState("APPROVED");
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingStore, setIsLoadingStore] = useState(false);

  useEffect(() => {
    if (!props.linkedStore) fetchStores(1, props.searchVal);
  }, [props]);

  useEffect(() => {
    setAddStoreVisible(props.openAdd);
  }, [props]);
  
  const setSuccesRegister = () => {
    setAddStoreVisible(false);
    setIsEdit(false);
    fetchStores(1);
  };
  const setModalVisible = () => {
    setAddStoreVisible(false);
    props.addModal(false);
    setIsEdit(false);
  };

  const setEditUser = (item) => {
    setAddStoreVisible(true);
    setEditItem(item);
    setIsEdit(true);
  };

  const fetchStores = (page, search = props.searchVal, key = activeKey) => {
    setCurrentPage(page)
    const term = [];

    // term.push({
    //   accountType: [props.isWarehouse ? "warehouse" : "store"],
    // });
    if (props.sort && props.sort.all === false) {
      term.push({
        isArchived: [
          props.sort ? (props.sort.archive ? "true" : "false") : "false",
        ],
      });
      term.push({
        block: [props.sort ? (props.sort.block ? "true" : "false") : "false"],
      });
    }
    term.push({
      status: [key],
    });
    const payload = {
      key: search,

      accountType: props.isWarehouse ? "warehouse" : "store",
      offset: 20 * (page - 1),
      size: 20,
      filter: {
        term: term,
      },
    };

    if (props?.sort?.sortType) {
      payload["sort"] = props.sort.sortType;
      // payload["sort"] = props.sort.sortType === "name" ? "storeName" : props.sort.sortType;
    }

    if (props?.sort?.sortBy) {
      payload["sortBy"] = props.sort.sortBy;
    }
    setIsLoadingStore(true);
    fetchVendors(payload)
      .then((response) => {
        setCustmerData(response.data.documents);
        setPage(Math.ceil(response.data.count / 10) * 10);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingStore(false);
      });
  };

  return (
    <div className="admin-manage-customer">
      <h1 className="content-name-header sub-title-tags" level={4}>
        {" "}
        {props.isWarehouse ? "Warehouse" : "Store"}
      </h1>

      <Tabs
        // tabBarExtraContent={operations}
        onChange={(activeKey) => {
          setCustmerData([]);
          fetchStores(1, "", activeKey);
          setActiveKey(activeKey);
        }}
        defaultActiveKey="Approved"
        style={{ overflowX: "auto" }}
      >
        <TabPane tab="Approved" key="APPROVED">
          <ManageStoreTable
            userData={customerData}
            total={page}
            currentPage={currentPage}
            onPageChange={fetchStores}
            editUser={setEditUser}
            linkedStore={props.linkedStore}
            isWarehouse={props.isWarehouse}
            isLoadingStore={isLoadingStore}
            isLoadingWarehouse={props.isLoadingWarehouse}
          />
        </TabPane>
        <TabPane tab="Requests" key="REQUESTED">
          <ManageStoreTable
            userData={customerData}
            total={page}
            currentPage={currentPage}
            requests
            onPageChange={fetchStores}
            editUser={setEditUser}
            linkedStore={props.linkedStore}
            isWarehouse={props.isWarehouse}
            isLoadingStore={isLoadingStore}
            isLoadingWarehouse={props.isLoadingWarehouse}
          />
        </TabPane>
        <TabPane tab="Deactivated" key="DEACTIVATED">
          <ManageStoreTable
            userData={customerData}
            total={page}
            currentPage={currentPage}
            onPageChange={fetchStores}
            editUser={setEditUser}
            linkedStore={props.linkedStore}
            isWarehouse={props.isWarehouse}
            isLoadingStore={isLoadingStore}
            isLoadingWarehouse={props.isLoadingWarehouse}
          />
        </TabPane>
        {/* <TabPane tab="Rejected" key="REJECTED">
          <ManageStoreTable
        userData={customerData}
        total={page}
        currentPage={currentPage}
        onPageChange={fetchStores}
        editUser={setEditUser}
        linkedStore={props.linkedStore}
        isWarehouse={props.isWarehouse}
      />
          </TabPane> */}
      </Tabs>

      {!isEdit && (
        <AddStoreModal
          visible={addStoreVisible}
          editItem={editItem}
          isEdit={isEdit}
          setVisible={setModalVisible}
          isWarehouse={props.isWarehouse}
          isAdmin={props.isAdmin}
          setSuccess={setSuccesRegister}
        />
      )}

      {isEdit && (
        <EditStoreModal
          visible={addStoreVisible}
          editItem={editItem}
          isEdit
          setVisible={setModalVisible}
          isWarehouse={props.isWarehouse}
          setSuccess={setSuccesRegister}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps)(ManageStore);
