


import React, { useState, useEffect } from "react";
import moment from "moment";

import { Modal, Divider, Typography, Input, Row, Col, Image } from "antd";
import { CheckCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { FILE_PATH } from "../../../Utils/constants";

const { Text, Title } = Typography;
const SellerInvoice = (props) => {

  const [status, setStatus] = useState("ORDERED")
  const [currentStatus, setCurrentStatus] = useState("ORDERED")
  const [trackingId, setTrackingId] = useState("")

  const [trackingLink, setTrackingLink] = useState("")


  const [statusList, setStatusList] = useState([
    "PACKED", "SHIPPED"

  ]);


  const getValueWithFormat = (data) => {
    if(data)
    {
    
 
      const filterArray=[
        // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
        data?data.addressLine1:null,
        data?data.addressLine2:null,
        data?data.buildingName:null,
        data?data.district:null,
        data?data.pinCode:null
      
      ];
      console.log("data??",filterArray);
    
      const filtered = filterArray.filter(function (el) {
        return el != null;
      })
      console.log("filtered??",filtered);
      const filteredNullStringValues = filtered.filter(Boolean)
      return filteredNullStringValues && filteredNullStringValues.toString().split(',').join(', ');
    }
    
    
        
      };


  return (

    <Modal

      visible={props.visible}
      onOk={() => props.setCancel(false)}
      onCancel={() => {
        props.setCancel(false)
      }}
      width={750}
      footer={null}
    >

      <div className="view-invoice-layout">
        <div className="detail-modal-heading">Order Invoice</div>
        <div className="">Order By:
               <span className="manage-order-product-name" style={{ marginLeft: "10px" }}>
            {props.selectedItems.shippingAddress.ownerName}</span></div>
        <div className="">Contact:
               <span className="manage-order-product-name" style={{ marginLeft: "10px" }}>
            {props.selectedItems.shippingAddress.phoneNo}</span></div>

      </div>

      <Row>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} >
          <div className="invoice-order-seller">Order Date</div>
          <div className="invoice-order-seller-content">
            {moment(props.selectedItems.orderedDate).format(" MMM DD, YYY")}
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} >
          <div className="invoice-order-seller">Order ID</div>
          <div className="invoice-order-seller-content">{props.selectedItems.orderGroupId}</div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} >
          <div className="invoice-order-seller" style={{ margin: "0px 10px" }}>Payment</div>
          <div className="invoice-order-seller-content" style={{ margin: "0px 10px" }}>
            {props.selectedItems.paymentMode}</div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={6} >
          <div className="invoice-order-seller">Delivery Address</div>
          <div className="invoice-order-seller-content">

          {getValueWithFormat(props.selectedItems.shippingAddress)}
            {/* {props.selectedItems.shippingAddress.addressLine1 + "," +
              props.selectedItems.shippingAddress.addressLine2 + "," +
              props.selectedItems.shippingAddress.buildingName + "," +
              props.selectedItems.shippingAddress.district + "," +
              props.selectedItems.shippingAddress.pinCode} */}
          </div>
        </Col>
      </Row>
      <Divider />
      <div style={{ padding: "10px" }}>
        {props.selectedItems.orderedItemsOfVendor.map(item =>

          <Row>
            <Col xs={24} sm={24} md={18} lg={18} xl={18} className="add-image-col">
              <div className="flex">
                <img
                  width={100}
                  height={100}
                  src={FILE_PATH + item.image}
                />
                <div className="tracking-product">
                  {item.productName}
                  <div className="flex" style={{ fontSize: "12px" }}>
                    <div >
                      {`${item.itemCurrentPrice.quantity}  ${item.itemCurrentPrice.unit}`}
                    </div>
                    <div style={{ marginLeft: "15px" }} >Qty:{item.quantity}</div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="add-image-col">
              <div className="tracking-price">₹  {item.itemCurrentPrice.sellingPrice}</div>
            </Col>
          </Row>

        )}
        <Divider />
        <div className="each_product_in_order">
          <div className="prod_details">
            <Text strong>Sub Total</Text>
          </div>
          <div className="prod_price">
            <Text strong>₹ {props.selectedItems.subTotal}</Text>
          </div>
        </div>

        {/* //delivery charge */}
        <div className="each_product_in_order">
          <div className="prod_details">
            <Text strong>Delivery charges</Text>
          </div>
          <div className="prod_price">
            <Text strong>₹ {props.selectedItems.deliveryCharge}</Text>
          </div>
        </div>
        <Divider style={{ margin: "10px 0px" }} />
        {/* //order Total */}
        <div className="each_product_in_order">
          <div className="prod_details">
            <Text strong>Total</Text>
          </div>
          <div className="prod_price">
            <Text strong style={{ fontSize: "16px" }}>
              ₹ {props.selectedItems.totalPrice}
            </Text>
          </div>
        </div>

      </div>





    </Modal>

  );
}
export default SellerInvoice;