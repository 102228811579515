import React, { createContext, useState } from "react";
import Cookies from "universal-cookie";

export const AppContext = createContext();
const cookies = new Cookies();
export const AppProvider = (props) => {
    const [showPinCodeModal, setPincodeModalVisible] = useState(false);
    const [pinCode, setPinCode] = useState(
        cookies.get("activeUserPincode")? cookies.get("activeUserPincode"): ""
    );
    const [product, setProduct] = useState();
    const [selectedItem, setSelectedItem] = useState();
    const [quantity, setQuantity] = useState();
    const [nearestPincode, setNearestPincode] = useState();
    const ProviderProps = {
        showPinCodeModal, 
        setPincodeModalVisible, 
        product,
        setProduct,
        selectedItem,
        setSelectedItem,
        quantity,
        setQuantity,
        pinCode,
        setPinCode,
        nearestPincode,
        setNearestPincode
    }

    return (
        <AppContext.Provider value={ProviderProps}>
            {props.children}
        </AppContext.Provider>
    );
}