


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import ReportsTable from "../ReportsTable";
import { stocksColumns } from "../Utils/reportHelper";
import {
  fetchStocksReport,
  exportStocksReport
} from "../../../../ApiAction/AdminApiAction/reportsApi";
import { formatStocksReport } from "../Utils/reportHelper";
const StocksReport = (props) => {
  const { vendorId, reportSummary } = props;
  const [dataSource, setdataSource] = useState([]);
  const [isLoadingStocksReport, setIsLoadingStocksReport] = useState(false);

  const handleExportStocksReport = async () => {
    const payload = {
      offset: 0,
      size: 10,
      sortBy: "addedDate",
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: props.searchVal,
      vendorId: vendorId ? vendorId : props.vendorFilter,
      brandId: props.brandId ? props.brandId : "",
    };

    if (props.stockPercentage) {
      switch (props.stockPercentage) {
        case "isStockLow":
          payload["isStockLow"] = true;
          break;

        case "isStockZero":
          payload["isStockZero"] = true;
          break;

        default:
          console.log("invalid choice");
      }
    }
    const stockReport = await exportStocksReport(payload)
      .then((response) => {
        console.log(response, "response for voucher code excel sheet");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `stocks_report.xlsx`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        message.error("Could not export file please try again");
      });
    // .finally(() => {});
    return true;
  };
  useEffect(() => {
    const payload = {
      offset: 0,
      size: 10,
      sortBy: "addedDate",
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: props.searchVal,
      vendorId: vendorId ? vendorId : props.vendorFilter,
      brandId: props.brandId ? props.brandId : "",
    };

    if (props.stockPercentage) {
      switch (props.stockPercentage) {
        case "isStockLow":
          payload["isStockLow"] = true;
          break;

        case "isStockZero":
          payload["isStockZero"] = true;
          break;

        default:
          console.log("invalid choice");
      }
    }

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endtime"] = props.dateRange.endtime;
    }
    setIsLoadingStocksReport(true);
    fetchStocksReport(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log("reponse for sales report: ", response.data.documents);
            setdataSource(formatStocksReport(response.data.documents));
          }
        }
      })
      .finally(() => {
        setIsLoadingStocksReport(false);
      });
  }, [
    props.sort,
    props.brandId,
    props.dateRange,
    props.searchVal,
    props.stockPercentage,
    props.vendorFilter,
  ]);
  return (
    <ReportsTable
      download={handleExportStocksReport}
      columns={stocksColumns}
      dataSource={dataSource}
      type={"Stocks"}
      reportSummary={reportSummary}
      summary={
        "The latest 10 stocks are listed below. To get the complete stock report, download csv"
      }
      isLoadingReport={isLoadingStocksReport}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
    brandId: state.reportReducer.brandId,
    dateRange: state.reportReducer.dateRange,
    stockPercentage: state.reportReducer.stockPercentage,
    vendorFilter: state.reportReducer.vendorId,
  };
};

export default connect(mapStateToProps)(StocksReport);
