


import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import Cookies from "universal-cookie";

import {
  Modal,
  Button,
  Select,
  Input,
  Row,
  Col,
  Image,
  Divider,
  Form,
  Checkbox,
} from "antd";
import { CheckCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import { FILE_PATH } from "../../../../Utils/constants";
const { Option } = Select;
const confirm = Modal.confirm;
const cookies = new Cookies();
const ReturnStatusCustomer = (props) => {
  const [initialItem, setInitialItems] = useState();

  const [trackingId, setTrackingId] = useState("");

  const [trackingLink, setTrackingLink] = useState("");

  const formFields = [
    { name: "trackingId", placeHolder: "Tracking ID", required: true },
    {
      name: "trackingLink",
      placeHolder: "Tracking Link",
      required: false,
    },
  ];

  const formFieldsBank = [
    {
      name: "name",
      placeHolder: "Account Holder's Name",
      required: true,
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z ]+$"),
          message: "Special characters and number are not allowed.",
        },
      ],
    },
    {
      name: "bankName",
      placeHolder: "Bank Name",
      required: true,
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z0-9 ]+$"),
          message: "Special characters are not allowed.",
        },
      ],
    },
    {
      name: "branch",
      placeHolder: "Branch",
      required: true,
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z0-9 ]+$"),
          message: "Special characters are not allowed.",
        },
      ],
    },
    {
      name: "accountNumber",
      placeHolder: "Account Number",
      required: true,
      rules: [
        {
          pattern: new RegExp("^[0-9]+$"),
          message: "Special characters and alphabets  are not allowed.",
        },
      ],
    },

    {
      name: "ifsc",
      placeHolder: "IFSC",
      required: true,
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z0-9 ]+$"),
          message: "Special characters  are not allowed.",
        },
      ],
    },
  ];

  const onFinish = (values) => {
    const payload = {
      id: props.selectedItems.id,
      // quantity: Number(values.quantity),
      shipmentTracker: {
        trackingId: values.trackingId,
        trackingLink: values.trackingLink,
      },
    };
    const payloadCOD = {
      id: props.selectedItems.id,
      // quantity: Number(values.quantity),
      isNewBankAccount: true,
      isPrimaryAccount: values.primary,
      shipmentTracker: {
        trackingId: values.trackingId,
        trackingLink: values.trackingLink,
      },
      account: {
        accountHolderName: values.name,
        bankName: values.bankName,
        ifscCode: values.ifsc,
        accountNumber: values.accountNumber,
        branch: values.branch,
      },
    };
    confirm({
      title: `Do you want to change the status!!`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        props.saveChanges(
          props.selectedItems.source.paymentMode == "COD" ? payloadCOD : payload
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    setInitialItems({});

    const initialValues = {
      quantity: props.selectedItems.source.quantity,
      trackingId:
        props.selectedItems.source.shipmentTracker &&
        props.selectedItems.source.shipmentTracker.trackingId,
      trackingLink:
        props.selectedItems.source.shipmentTracker &&
        props.selectedItems.source.shipmentTracker.trackingLink,
      name:
        props.selectedItems.source.account &&
        props.selectedItems.source.account.accountHolderName,
      bankName:
        props.selectedItems.source.account &&
        props.selectedItems.source.account.bankName,
      branch:
        props.selectedItems.source.account &&
        props.selectedItems.source.account.branch,
      accountNumber:
        props.selectedItems.source.account &&
        props.selectedItems.source.account.accountNumber,
      ifsc:
        props.selectedItems.source.account &&
        props.selectedItems.source.account.ifscCode,
    };
    setInitialItems(initialValues);
  }, [props]);

  const QuanityValidator = (rule, value, callback) => {
    if (
      value !== undefined &&
      Number(value) > Number(props.selectedItems.source.quantity)
    ) {
      callback("Plese enter valid quantity");
    } else {
      callback();
    }
  };

  return (
    <Modal
      visible={props.visible}
      onOk={() => props.setCancel(false)}
      onCancel={() => {
        props.setCancel(false);
      }}
      width={600}
      footer={null}
      className="manage_rtn_pop"
    >
      <div className="view-invoice-layout rtn_with_bnk_dlts">
        <div className="detail-modal-heading">Return Details</div>
        <div className="">
          Order ID:
          <span
            className="manage-order-product-name"
            style={{ marginLeft: "10px" }}
          >
            {props.selectedItems.source?.groupOrderId}
          </span>
        </div>
      </div>
      <div className="">
        <Row style={{ padding: "10px 0" }}>
          <Col
            xs={24}
            sm={24}
            md={18}
            lg={18}
            xl={18}
            className="add-image-col"
          >
            <div className="flex">
              <img
                width={100}
                height={100}
                src={FILE_PATH + props.selectedItems.source.image}
              />
              <div className="tracking-product custr_mng_rtrn">
                <div className="seller-return-product-name">
                  {props.selectedItems.source.productName}
                </div>
                <div
                  className="flex"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#a8a8a8",
                  }}
                >
                  <div>
                    {`${props.selectedItems.source.itemCurrentPrice.quantity}  ${props.selectedItems.source.itemCurrentPrice.unit}`}
                  </div>
                  <div style={{ marginLeft: "15px" }}>
                    Qty:{props.selectedItems.source.quantity}
                  </div>
                </div>
                <div className="add-image-col">
                  <div
                    className="tracking-price"
                    style={{ fontSize: "15px", fontWeight: "600" }}
                  >
                    ₹ {props.selectedItems.source.itemCurrentPrice.sellingPrice}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div
        className="contact-modal-form return-form"
        style={{ marginTop: "15px" }}
      >
        <Form
          name="normal_login"
          className="login-form"
          initialValues={initialItem}
          onFinish={onFinish}
        >
          {/* <Fragment>
                  <label>Return Quantity </label>
                  <Form.Item
                    hasFeedback
                    name={"quantity"}
                    
                    rules={[
                      {
                        required: true,
                        message: "Please enter quantity!",
                      },
                      { validator: QuanityValidator },
                    ]}
                  >
                    <Input
                      placeholder={"Return quantity"}
                      style={{ height: "40px" }}
                      type="number"
                     
                    />
                  </Form.Item>
                </Fragment> */}
          {cookies.get("accountType") === "customer" &&
            formFields.map((data) => {
              return (
                <Fragment>
                  <label>{data.placeHolder}</label>
                  <Form.Item
                    hasFeedback
                    name={data.name}
                    required={data.required}
                  >
                    <Input
                      placeholder={data.placeHolder}
                      style={{ height: "40px" }}
                    />
                  </Form.Item>
                </Fragment>
              );
            })}

          {props.selectedItems.source.paymentMode == "COD" && (
            <Fragment>
              <Divider />

              <div className="account-detail-header"> Account Details</div>
              <div style={{ marginBottom: "15px", fontWeight: "500" }}>
                {" "}
                Refunds are made directly to the specified account
                <span className="account-madatory">
                  {" "}
                  (All fields are mandatory)
                </span>
              </div>

              {formFieldsBank.map((data) => {
                return (
                  <Fragment>
                    <label>{data.placeHolder}</label>
                    <Form.Item
                      hasFeedback
                      name={data.name}
                      rules={
                        data.rules
                          ? [
                              ...data.rules,
                              {
                                required: true,
                                message: "This field is required !",
                              },
                            ]
                          : [
                              {
                                required: true,
                                message: "This field is required !",
                              },
                            ]
                      }
                    >
                      <Input
                        placeholder={data.placeHolder}
                        style={{ height: "40px" }}
                      />
                    </Form.Item>
                  </Fragment>
                );
              })}

              <Form.Item
                className=""
                name="primary"
                valuePropName="checked"
                style={{ marginTop: "25px", display: "block" }}
              >
                <Checkbox
                // onChange={(e) => console.log(e.target.checked)}
                >
                  Save this as my default account
                </Checkbox>
              </Form.Item>
            </Fragment>
          )}

          {!props.selectedItems.source.shipmentTracker && (
            <div className="seller-action-button">
              <Form.Item>
                <Button
                  htmlType="submit"
                  style={{ width: "200px", marginTop: "15px" }}
                  // onClick={() => saveChanges()}
                  className="button-seller-request-approve"
                >
                  Save Changes
                </Button>
              </Form.Item>
            </div>
          )}
        </Form>
      </div>
    </Modal>
  );
};
export default ReturnStatusCustomer;
