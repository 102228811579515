

//dev server
// export const API_SERV_ADDR = "http://54.224.115.38:5001";
// export const FILE_PATH = "https://a-dev.s3.amazonaws.com/";

//staging server
// export const API_SERV_ADDR = "http://52.206.204.161:5001";
// export const FILE_PATH = "https://a-dev.s3.amazonaws.com/";

//env based configurations
export const API_SERV_ADDR = process.env.REACT_APP_API_URL;
export const API_SERV_ADDR_2 = process.env.REACT_APP_API_URL_2;
export const FILE_PATH = process.env.REACT_APP_FILE_PATH;
export const ENV_TYPE = process.env.REACT_APP_COOKIE;

export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const SSLHTTP = process.env.REACT_APP_SSL;

//Request Payment Constants
export const RequestConstants = {
  REQUESTED: "REQUESTED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  SENT_TO_BANK: "SENT_TO_BANK",
  PAID: "PAID",
};
//status for log
export const LOG_STATUS = [
  "HIGHEST",
  "HIGH",
  "MEDIUM",
  "LOWEST",
  "LOW"
]
//union territories
export const unionTerritories = ['andaman and nicobar islands', 'chandigarh', 'daman & diu', 'dadar and nagar haveli', 'ladakh', 'lakshadweep']
//for ads configurations
export const adBannerType = ['CARD', 'BANNER']
export const adTypes = ['STORES', 'SELLERS', 'BRANDS']
export const cardResolution = {
  width: '400', height: '200'
}
export const bannerResolution = {
  width: '1200', height: '400'
}