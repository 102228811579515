


import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import {
  Layout,
  Typography,
  Avatar,
  Select,
  Col,
  Row,
  Button,
  Modal,
  message,
} from "antd";
import BreadCrumb from "../ProductListing/breadCrumb";
import {
  fetchCartCount,
  fetchCartItems,
} from "../../../../ReduxStore/action/index";
import "./cart.css";
import { UserOutlined, CloudUploadOutlined } from "@ant-design/icons";
import QuantityController from "../../../ProductCard/components/QuantityController";
import { withRouter } from "react-router-dom";
import {
  addToCart,
  cartGet,
  deleteFromCart,
  removeMultiple,
  updateToCart,
} from "../../../../ApiAction/SellerApiActions/sellerApi";
import CartItem from "./cartItem";
import EmptyList from "../../../GeneralComponents/emptyList";
import { ENV_TYPE } from "../../../../Utils/constants";
import Loading from "../../../GeneralComponents/Loading";
import LoadingCartItem from "./loadingCartItem";
import { openLoginModal } from "../../../../ReduxStore/action/authModalActions";
import { fetchUserCartCount } from "../../../../ApiAction/CustomerApiActions/orderAPI";

const { Content, Sider } = Layout;
const { Option } = Select;
const { Title } = Typography;
const confirm = Modal.confirm;
const Cart = (props) => {
  const { orderFeedback } = props;
  const cookies = new Cookies();
  const accountType = cookies.get("accountType");
  const [activeCustomer, setactiveCustomer] = useState(
    JSON.parse(localStorage.getItem("activeCustomer"))
  );
  const [totalPrice, setTotalPrice] = useState("");
  const [totalDiscount, setTotalDiscount] = useState("");
  const [totalMrp, setTotalMrp] = useState("");
  const [cartProducts, setCartProducts] = useState([]);

  const [cartData, setCartData] = useState();
  const [isOrderFeedback, setisOrderFeedback] = useState(false);
  const [isLoadingUserCart, setisLoadingUserCart] = useState(false);

  const [ storeItems, setStoreItems ] = useState([]);
  const [ sellerItems, setSellerItems ] = useState([]);
  const [ warehouseItems, setWarehouseItems ] = useState([]);
  const updateIds = ["60a328236f87521b3938b18d26963cb2"];

  const [ showCODmodel, setShowCODmodel ] = useState(false)

  function handleChange(value) {}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  const handleRedirectContinueShopping = () => {
    switch (cookies.get("accountType")) {
      case "customer":
        props.history.push(ENV_TYPE === "production" ? "/" : `/customer`);
        break;
      case "warehouse":
        props.history.push(`/warehouse/check-availiablity`);
        break;
      case "store":
        props.history.push(`/store/check-availiablity`);
        break;
      default:
        if (localStorage.getItem("guestCart")) {
          props.history.push(ENV_TYPE === "production" ? "/" : `/customer`);
        }

        console.log("invalid choice");
    }
  };

  useEffect(() => {
    const paylaod = {
      userId: cookies.get("userId"),
      accountType: accountType,
    };
    if (activeCustomer) {
      setisLoadingUserCart(true);
      cartGet(paylaod)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              setCartData(response.data.cart);
              setCartProducts(response.data.cart.cartItems);
              filterCartItems(response.data.cart.cartItems);
              setTotalDiscount(response.data.cart.totalDiscount);
              setTotalPrice(response.data.cart.totalPrice);
              setTotalMrp(response.data.cart.bagTotal);
              props.fetchCartCount(response.data.cart.cartItems.length);
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          setisLoadingUserCart(false);
        });
    } else {
      const guestCart = JSON.parse(localStorage.getItem("guestCart"));
      if (guestCart) {
        var mrp = guestCart.reduce(function (prev, cur) {
          return prev + cur.itemCurrentPrice.price * cur.quantity;
        }, 0);
        var sellingPrice = guestCart.reduce(function (prev, cur) {
          return prev + cur.itemCurrentPrice.sellingPrice * cur.quantity;
        }, 0);
        // var discount = guestCart.reduce(function (prev, cur) {
        //   return prev + cur.itemCurrentPrice.discount * cur.quantity;
        // }, 0);
        var discount = mrp - sellingPrice
        setCartProducts(guestCart);
        filterCartItems(guestCart)
        setTotalMrp(mrp);
        setTotalPrice(sellingPrice);
        setTotalDiscount(discount);
        const cartCount = guestCart.reduce((prev, cur) => {
          return prev + cur.quantity;
        }, 0);
        props.fetchCartCount(cartCount);
      }
    }
  }, []);

  useEffect(()=>{
  },[cartProducts])


  const filterCartItems = async (CartItems) => {
    let storeProducts = [];
    let sellerProducts = [];
    let warehouseProducts = [];
    const Items = await CartItems.map((item)=> {
      if(item.vendorType === "STORE") {
        storeProducts.push(item)
      }else if(item.vendorType === "WAREHOUSE"){
        warehouseProducts.push(item)
      }
      else {
        sellerProducts.push(item)
      }
      return item;
    })

    setStoreItems(storeProducts)
    setSellerItems(sellerProducts)
    setWarehouseItems(warehouseProducts)
  }

  useEffect(() => {
    if (
      props.location.pathname.includes("remove-items") &&
      orderFeedback.message
    ) {
      setisOrderFeedback(true);
    } else {
      accountType === "customer" &&
        props.history.push(
          ENV_TYPE === "production" ? `/cart` : `/customer/cart`
        );
    }
  }, [orderFeedback]);

  const handelFetchUserCartCount = () => {
    fetchUserCartCount().then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          props.fetchCartItems(response.data.cartItems);
        }
      }
    });
  };

  const handleConfirmRemoveMultiple = () => {
    confirm({
      title: `Are you sure , you want to remove all highlighted products from cart?`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "Cancel",
      okText: "Remove all",
      onOk: () => {
        handleRemoveMultipleItems();
      },
      onCancel: () => {},
    });
  };

  const handleRemoveMultipleItems = () => {
    var payload = {
      cartItemIds: orderFeedback.cartItemIds,
    };
    removeMultiple(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setCartProducts(
            cartProducts.filter(
              (item) => !orderFeedback.cartItemIds.includes(item.id)
            )
          );
          filterCartItems(cartProducts.filter(
            (item) => !orderFeedback.cartItemIds.includes(item.id)
          ))
          getCartWithoutList();
          handelFetchUserCartCount();

          //for redirecting to cart page of respective user roles
          switch (accountType) {
            case "customer":
              props.history.push(
                ENV_TYPE === "production" ? `/cart` : `/customer/cart`
              );
              break;
            case "warehouse":
              props.history.push(`/warehouse/cart`);
              break;
            case "store":
              props.history.push(`/store/cart`);
              break;
            default:
              break;
          }

          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      }
    });
  };

  const deleteProduct = (payload) => {
    if (activeCustomer) {
      deleteFromCart(payload)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              setCartProducts(
                cartProducts.filter((item) => item.id != payload.id)
              );
              filterCartItems(cartProducts.filter((item) => item.id != payload.id))
              getCartWithoutList();
              handelFetchUserCartCount();
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    } else {
      //remove product case for guest cart
      console.log(payload, "remove item");
      var guestCart = JSON.parse(localStorage.getItem("guestCart"));
      var updatedCart1 = guestCart.filter((data) => {
        return data.productId === payload.id && data.itemId === payload.itemId;
      });
      var updatedCart2 = guestCart.filter((data) => {
        return data != updatedCart1[0];
      });
      console.log(payload);
      handleUpdateGuestCart(updatedCart2);
    }
  };

  const getCartWithoutList = () => {
    const paylaod = {
      userId: cookies.get("userId"),
      accountType: accountType,
    };
    cartGet(paylaod)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setCartData(response.data.cart);
            setCartProducts(response.data.cart.cartItems);
            filterCartItems(response?.data?.cart?.cartItems);
            setTotalDiscount(response.data.cart.totalDiscount);
            setTotalPrice(response.data.cart.totalPrice);
            setTotalMrp(response.data.cart.bagTotal);
            props.fetchCartCount(props.cartCount + 1);
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const updateProduct = (payload) => {
    if (activeCustomer) {
      updateToCart({ ...payload, cartId: cartData.cartId })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              getCartWithoutList();
           
            }
          }
        })
        .catch(() => {})
        .finally(() => {});
    } else {
      //update cart feature for guest cart
      var guestCart = JSON.parse(localStorage.getItem("guestCart"));
      if (payload.isChangeVariant) {
        console.log("is change variant");
        if (payload.itemCurrentPrice.stock > 0) {
          console.log("new variant stock:", payload.itemCurrentPrice.stock);

          var duplicateCart = guestCart.filter((data) => {
            return (
              data.itemId !== payload.id + payload.itemCurrentPrice.serialNumber
            );
          });
          var finalCart = duplicateCart.map((data) => {
            if (
              data.productId === payload.id &&
              data.itemId === payload.itemId
            ) {
              console.log("updated");
              return {
                ...data,
                itemCurrentPrice: payload.itemCurrentPrice,
                quantity: 1,
                itemId: payload.id + payload.itemCurrentPrice.serialNumber,
              };
            } else return data;
          });

          handleUpdateGuestCart(finalCart);
        } else {
          message.warning("Product out of stock");
          handleUpdateGuestCart(guestCart);
        }
      } else {
        console.log(guestCart, payload);
        var updatedCart = guestCart.map((data) => {
          if (data.productId === payload.id && data.itemId === payload.itemId) {
            console.log("updated");
            return {
              ...data,
              itemCurrentPrice: payload.itemCurrentPrice,
              quantity: payload.quantity,
            };
          } else return data;
        });
        console.log(payload);
        handleUpdateGuestCart(updatedCart);
      }
    }
  };

  const handleUpdateGuestCart = (updatedCart) => {
    var mrp = updatedCart.reduce(function (prev, cur) {
      return prev + cur.itemCurrentPrice.price * cur.quantity;
    }, 0);
    var sellingPrice = updatedCart.reduce(function (prev, cur) {
      return prev + cur.itemCurrentPrice.sellingPrice * cur.quantity;
    }, 0);
    // var discount = updatedCart.reduce(function (prev, cur) {
    //   return prev + cur.itemCurrentPrice.discount * cur.quantity;
    // }, 0);
    var discount = mrp - sellingPrice;
    setCartProducts(updatedCart);
    filterCartItems(updatedCart);
    setTotalMrp(mrp);
    setTotalPrice(sellingPrice);
    setTotalDiscount(discount);
    const cartCount = updatedCart.reduce((prev, cur) => {
      return prev + cur.quantity;
    }, 0);
    props.fetchCartCount(cartCount);
    localStorage.setItem("guestCart", JSON.stringify(updatedCart));
  };

  const confirmAndProceed = () => {
    if (accountType) {
      switch (accountType) {
        case "customer":
          props.history.push(
            ENV_TYPE === "production"
              ? `/cart-payment`
              : `/customer/cart-payment`
          );
          break;
        case "warehouse":
          props.history.push(`/warehouse/cart-payment`);
          break;
        case "store":
          props.history.push(`/store/cart-payment`);
          break;
        default:
          props.history.push(
            ENV_TYPE === "production"
              ? `/cart-payment`
              : `/customer/cart-payment`
          );
      }
      window.scrollTo(0, 0);
    } else props.openLoginModal("customer");
  }

  //function to handle redirect to checkout page withrespect to the userType
  const handleProceedCheckout = () => {
    if(sellerItems.length > 0) {
      setShowCODmodel(true)
    }else {
      confirmAndProceed()
    }
  };

  return (
    <div
      className="customer-product-listing-page-conatiner"
      style={{ paddingTop: "0px" }}
    >
      {showCODmodel && (
        <div className="cod-confirm-model">
          <div className="cod-confirm-container">
            <span><h3>COD not allowed</h3></span>
            <span style={{color: "grey"}}>Since you added Seller products to cart. Cash on Delivery is disabled. If you want COD please remove Seller products from cart and continue checkout.</span>
            <span style={{ display: "flex", justifyContent: "right"}}><Button type="link" onClick={()=> setShowCODmodel(false)}>GO BACK</Button> <Button type="primary" style={{ padding: "0 3em"}} onClick={confirmAndProceed}>OK</Button></span>
          </div>
        </div>
      )}
      <Layout className="customer-product-listing-main-layout">
        {/* <div className="product-listing-breadcrumb-sort-by">
                    <BreadCrumb />

                </div> */}

        <Content>
          <Layout style={{ backgroundColor: "#fff" }}>
            <Content>
              <div className="cart_bg_wrap">
                <div className="cart-layout cart_pg_container container">
                  <div
                    className="cart-total-price-border block-rev"
                    style={{ padding: "20px", fontSize: "18px" }}
                  >
                    <h1
                      className="cart-total-mrp sub-title-tags"
                      //   style={{  fontWeight: "500" }}
                    >
                      Cart Items{" "}
                      {isOrderFeedback && (
                        <div className="cart_order_failure_message">
                          Highlighted items {orderFeedback.message}*
                        </div>
                      )}
                    </h1>
                    {isOrderFeedback && (
                      <Button
                        type="primary"
                        className="remove_multiple_btn"
                        onClick={() => handleConfirmRemoveMultiple()}
                      >
                        Remove non Deliverable Items
                      </Button>
                    )}
                  </div>

                  {/* {cartProducts.map((item, index) => (
                    <CartItem
                      product={item}
                      deleteProduct={deleteProduct}
                      updateProduct={updateProduct}
                      number={index + 1}
                      remove={
                        isOrderFeedback &&
                        orderFeedback.cartItemIds.includes(item.id)
                      }
                      update={
                        isOrderFeedback &&
                        orderFeedback?.lowStockIds?.includes(item.id)
                      }
                    />
                  ))} */}

                  {sellerItems.length > 0 && (<p className="cartTags">Seller Products (COD not allowed)</p>)}
                  {
                    sellerItems.map((item, index) => (
                      <CartItem
                        product={item}
                        deleteProduct={deleteProduct}
                        updateProduct={updateProduct}
                        number={index + 1}
                        remove={
                          isOrderFeedback &&
                          orderFeedback.cartItemIds.includes(item.id)
                        }
                        update={
                          isOrderFeedback &&
                          orderFeedback?.lowStockIds?.includes(item.id)
                        }
                      />
                    ))
                  }

                  {storeItems.length > 0 && (<p className="cartTags">Agency Products</p>)}
                  {
                    storeItems.map((item, index) => (
                      <CartItem
                        product={item}
                        deleteProduct={deleteProduct}
                        updateProduct={updateProduct}
                        number={index + 1}
                        remove={
                          isOrderFeedback &&
                          orderFeedback.cartItemIds.includes(item.id)
                        }
                        update={
                          isOrderFeedback &&
                          orderFeedback?.lowStockIds?.includes(item.id)
                        }
                      />
                    ))
                  }

                  {warehouseItems.length > 0 && (<p className="cartTags">Warehouse Products</p>)}
                  {
                    warehouseItems.map((item, index) => (
                      <CartItem
                        product={item}
                        deleteProduct={deleteProduct}
                        updateProduct={updateProduct}
                        number={index + 1}
                        remove={
                          isOrderFeedback &&
                          orderFeedback.cartItemIds.includes(item.id)
                        }
                        update={
                          isOrderFeedback &&
                          orderFeedback?.lowStockIds?.includes(item.id)
                        }
                      />
                    ))
                  }

                  {isLoadingUserCart ? (
                    <LoadingCartItem />
                  ) : cartProducts.length === 0 ? (
                    <EmptyList msg={"No items found"} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {cartProducts.length > 0 && (
                <Row
                  className="container final-stg-price"
                  style={{ margin: "50px auto 30px" }}
                >
                  <Col span={12}>
                    <div className="cart_count">
                      <div className="cart-total-price">
                        <div className="cart-total-mrp">Total MRP</div>
                        <div className="cart-total-mrp">
                          ₹{" "}
                          <span style={{ color: "#3e6df3" }}>
                            {Number(totalMrp)?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="cart-total-price">
                        <div className="cart-total-mrp">Discounts</div>
                        <div className="cart-total-mrp">
                          ₹{" "}
                          <span style={{ color: "#3e6df3" }}>
                            {Number(totalDiscount)?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="cart-total-price tlt_prz">
                        <div className="cart-total-mrp">Total DLP</div>
                        <div className="cart-total-mrp">
                          ₹{" "}
                          <span style={{ color: "#3e6df3" }}>
                            {Number(totalPrice)?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="cart-total-price">
                        <div></div>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            color: "#8d8d8d",
                          }}
                        >
                          {" "}
                          * Additional charges may apply during checkout
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="cart-button-layout">
                      {/* <Button
                                            className="button-seller-request-reject"
                                            style={{ marginLeft: "15px", marginBottom: "10px" }}
                                        >
                                            Schedule Auto-Cart
                                             </Button> */}

                      <Button
                        style={{ marginBottom: "10px" }}
                        onClick={() => handleProceedCheckout()}
                        className="button-seller-request-approve ck2-btn"
                      >
                        Proceed To Checkout
                      </Button>
                      <div
                        onClick={() => handleRedirectContinueShopping()}
                        className="cont-shop"
                        style={{
                          color: "#3e6df3",
                          display: "flex",
                          justifyContent: "center",
                          fontWeight: "600",
                          cursor: "pointer",
                          fontSize: "16px",
                          padding: "10px 0",
                        }}
                      >
                        Continue Shopping
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
            </Content>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};
const mapDispatchToProps = { fetchCartCount, fetchCartItems, openLoginModal };
const mapStatesToProps = (state) => {
  return {
    orderFeedback: state.customerOrderReducer.orderFailure,
    cartCount: state.cartCountReducer,
  };
};
export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(Cart));
