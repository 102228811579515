


import { API_SERV_ADDR } from "../../Utils/constants";
import Axios from "../axios";

import Cookies from "universal-cookie";
// const cookies = new Cookies();


export const clearNewItemAPI = (payload) => {
    return Axios({
        url: `${API_SERV_ADDR}/order/viewed`,
        method: "POST",
        data: payload,
    });
};
export const clearNewItemAllOrder = (payload) => {
    return Axios({
        url: `${API_SERV_ADDR}/order/overall-order-viewed`,
        method: "POST",
        data: payload,
    });
};