

import React from "react";
import { Layout } from "antd";
import "antd/dist/antd.css";
import "./index.css";

import FooterLayout from "../FooterLayout";
import HeaderLayout from "../HeaderLayout";
import ContentLayout from "../ContentLayout";
import AccountTypeContext from "../../components/Admin/accountTypeContext";
import { AppProvider } from "../../components/GeneralComponents/appContext";
import "./googleAd.scss";

export default function MainLayout(props) {
  return (
    <Layout className="layout">
      <AppProvider>
        <HeaderLayout {...props} />
        <ContentLayout {...props} />

        {/* <div className="googleAdsContainer">
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          ></script>
          <ins
            class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-layout-key="-ef+6k-30-ac+ty"
            data-ad-client="ca-pub-2847416638200274"
            data-ad-slot="5073409574"
          ></ins>
          <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
        </div> */}
        <AccountTypeContext.Provider value="customer">
          <FooterLayout />
        </AccountTypeContext.Provider>
      </AppProvider>
    </Layout>
  );
}
