


import React, { useState } from "react";
import { Table, Checkbox, Radio } from "antd";

const { Column } = Table;

const BatchDropdown = (props) => {
  const [selectbatch, setselectbatch] = useState([]);
  const [batchlen, setbatchlen] = useState(props.batches[1] ? true : false);

  const handleBatchChange = (item, event) => {
    console.log("batch -->", event.target.value, item);
    props.handleBatchChange(props.item, item);
    props.setVisible(false);
  };

  // const data = [
  //   {
  //     slno: "1",
  //     mrp: 12,
  //     hrp: 1,
  //     expiry: "11/12/1111",
  //     varient: "1 Kg",
  //     avalQty: 12,
  //   },
  //   {
  //     slno: "2",
  //     mrp: 12,
  //     hrp: 1,
  //     expiry: "11/12/1111",
  //     varient: "1 Kg",
  //     avalQty: 12,
  //   },
  //   {
  //     slno: "3",
  //     mrp: 12,
  //     hrp: 1,
  //     expiry: "11/12/1111",
  //     varient: "1 Kg",
  //     avalQty: 12,
  //   },
  // ];

  return (
    <Table
      dataSource={props.batches}
      pagination={false}
      className="batch-dropdown"
      // onRow={(item) => ({
      //   onClick: (e) => {
      //     e.stopPropagation();
      //     handleBatchChange(item, e);
      //   },
      // })}
      // rowSelection={{
      //   type: radio,
      //   ...rowSelection,
      // }}
    >
      {console.log(props.batches)}
      <Column
        title={"Sl No."}
        dataIndex="slno"
        width="50px"
        render={(name, item) => (
          <div>
            {/* <Checkbox
              onChange={(event) => {
                handleBatchChange(item, event);
              }}
            /> */}
            <Radio
              value={item}
              checked={item.batchNumber === props.activeBatchNumber}
              onChange={(event) => {
                handleBatchChange(item, event);
              }}
            >
              {props.batches.indexOf(item) + 1}
            </Radio>
            {/* <h5>{props.batches.indexOf(item) + 1}</h5> */}
          </div>
        )}
      />
      <Column
        title={"MRP"}
        dataIndex="mrp"
        width="50px"
        render={(name, item) => (
          <div>
            <h5>{item.price}</h5>
          </div>
        )}
      />
      <Column
        title={"DLP"}
        dataIndex="hrp"
        width="50px"
        render={(name, item) => (
          <div>
            <h5>{item.sellingPrice}</h5>
          </div>
        )}
      />
      <Column
        title={"Expiry"}
        dataIndex="expiry"
        width="50px"
        render={(name, item) => (
          <div>
            <h5>{item.expiryDate}</h5>
          </div>
        )}
      />

      <Column
        title={"Available Qty"}
        dataIndex="avalQty"
        width="50px"
        render={(name, item) => (
          <div>
            <h5>{item.availableQuantity}</h5>
          </div>
        )}
      />
    </Table>
  );
};

export default BatchDropdown;
