


import React from "react";
import "./FooterView.scss";

import { Link } from "react-router-dom";

import FooterList from "./Components/FooterList";
import ContactUs from "./Components/ContactUs";
import SocialMedia from "./Components/SocialMedia";
import PaymentOptions from "./Components/PaymentOptions";
import FooterContainer from "./FooterContainer";

const FooterView = () => {
  return (
    // <div className="footer">
    //   <div className="footer-part1">
    //     <FooterList />
    //     <FooterList />
    //     <FooterList className="footer-list-2-mobile" />
    //     <ContactUs className="footer-list-2-mobile" />
    //   </div>
    //   <div className="footer-part2">
    //     <SocialMedia />
    //     <PaymentOptions />
    //   </div>
    // </div>
    <FooterContainer />
  );
};

export default FooterView;
