

import axios from "axios";
import { API_SERV_ADDR, API_SERV_ADDR_2 } from "../../Utils/constants";
import Axios from "../axios";
import { getLogAttributes } from "../../components/Admin/Logger/notificationBar/LogHelper";

import Cookies from "universal-cookie";
var CryptoJS = require("crypto-js");
const cookies = new Cookies();
var encryptedToken;

if (localStorage.getItem("remember")) {
  encryptedToken = localStorage.getItem("authToken");
} else {
  encryptedToken = sessionStorage.getItem("authToken");
}
if (encryptedToken) {
  var bytes = CryptoJS.AES.decrypt(
    encryptedToken,
    process.env.REACT_APP_ENCRYPTION_KEY
  );
  var token = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

export const downloadSellerAttachment = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/get-file-url`,
    method: "POST",
    data: payload,
    // responseType: "blob",
  });
};

//updated
export const getSellerProfile = () => {
  return Axios({
    url: `${API_SERV_ADDR}/user/profile/`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    data: {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
    },
  });
};

//updated
export const AddSellerProfileAddress = (data, imageUrl) => {
  const formData = new FormData();
  let payload = {
    ...data,
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  };
  payload = getLogAttributes(payload, "Manage Profile");
  formData.append("data", JSON.stringify(payload));
  if (imageUrl.length > 0) {
    imageUrl.map((item, index) => {
      formData.append("file" + index, item.file);
    });
  }
  return Axios({
    url: `${API_SERV_ADDR}/seller/profile/form`,
    method: "POST",
    headers: { "content-type": "multipart/form-a" },
    data: formData,
  });
};

export const AddUserProfilePic = (imageUrl) => {
  const formData = new FormData();
  formData.append("file", imageUrl);
  return Axios({
    url: `${API_SERV_ADDR}/customer/${cookies.get(
      "userId"
    )}/profile-image-upload`,
    method: "POST",
    headers: { "content-type": "multipart/form-data" },
    data: formData,
  });
};

export const AddAdminProfileAddress = (data) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/account/update`,
    method: "POST",
    data: data,
  });
};

//updated
export const addSellerProduct = (data) => {
  let payload = {
    ...data,
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  }
  payload = getLogAttributes(payload, "");
  return Axios({
    url: `${API_SERV_ADDR}/product/add`,
    method: "PUT",
    data: JSON.stringify(payload),
  });
};

//updated
export const updateSellerProduct = (data) => {
  let payload = {
    ...data,
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  };
  payload = getLogAttributes(payload, "Manage Products");
  return Axios({
    url: `${API_SERV_ADDR}/product/update`,
    method: "POST",
    data: JSON.stringify(payload),
  });
};

//updated
export const bulkUpload = (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append("files", data);
  bodyFormData.append("userId", cookies.get("userId"));
  bodyFormData.append("accountType", cookies.get("accountType"));
  return Axios({
    url: `${API_SERV_ADDR}/product/bulk/upload`,
    method: "POST",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addProductImage = (data) => {
  const formData = new FormData();
  formData.append("accountType", data.accountType);
  formData.append("productId", data.productId);
  formData.append("files", data.file);
  formData.append("master", data.master);

  return Axios({
    url: `${API_SERV_ADDR}/product/image-upload`,
    method: "POST",
    headers: { "content-type": "multipart/form-data" },
    data: formData,
  });
};

//updated
export const getProducts = (payload) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelled");
  }
  cancelToken = axios.CancelToken.source();
  const data = {
    ...payload,
    accountType: cookies.get("accountType"),
  };
  return Axios({
    url: `${API_SERV_ADDR_2}/product/search/filter`,
    method: "POST",
    data: data,
    cancelToken: cancelToken.token,
  });
};

export const getSellerReturns = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/return/vendor/list-orders`,
    method: "POST",
    data: payload,
  });
};

export const updateSellerStatus = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/return/approve-reject-request`,
    method: "POST",
    data: payload,
  });
};

export const getLinkedWarehouseProducts = (payload, id) => {
  const data = {
    ...payload,
    accountType: cookies.get("accountType"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/warehouse/linked-warehouse/${id}/products`,
    method: "POST",
    data: data,
  });
};

export const getCustomerOrders = (payload) => {
  // if (typeof cancelToken != typeof undefined) {
  //   cancelToken.cancel("Cancelled");
  // }
  // cancelToken = axios.CancelToken.source();
  return Axios({
    url: `${API_SERV_ADDR}/order/vendor/list-search`,
    method: "POST",
    data: payload,
    // cancelToken: cancelToken.token,
  });
};

export const updateOrderStatus = (payload) => {
  let data = {
    ...payload,
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  };
  data = getLogAttributes(data, "Manage Warehouse");
  return Axios({
    url: `${API_SERV_ADDR}/order/vendor/multiple-item/update-status`,
    method: "POST",
    data: data,
  });
};
export const getProductsStoreLinkedWarehouse = (payload, id) => {
  const data = {
    ...payload,
    accountType: "warehouse",
  };
  return Axios({
    url: `${API_SERV_ADDR}/store/linked-warehouse/${id}/products`,
    method: "POST",
    data: data,
  });
};
export const moveProducts = (id) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/draft/${id}`,
    method: "GET",
  });
};

export const getEditProducts = (id) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/get/${id}`,
    method: "GET",
  });
};

export const deleteSingleProduct = (id) => {
  const payload = getLogAttributes({}, "Manage Products");
  return Axios({
    url: `${API_SERV_ADDR}/product/delete/${id}`,
    method: "DELETE",
    data: payload
  });
};

export const approveSingleProduct = (payload) => {
  payload = getLogAttributes(payload, "Manage Products")
  return Axios({
    url: `${API_SERV_ADDR}/admin/product/approve-reject`,
    method: "POST",
    data: payload,
  });
};

export const approveAllProducts = () => {
  const payload = {
    productId: [],
    status: "APPROVED",
    approveAll: true,
  };
  return Axios({
    url: `${API_SERV_ADDR}/admin/product/approve-reject`,
    method: "POST",
    data: payload,
  });
};

export const publishAllProducts = () => {
  const payload = {
    vendorId: cookies.get("userId"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/product/approve-all-stocks`,
    method: "POST",
    data: payload,
  });
};

// to get all log list
export const addBatch = (payload) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelled");
  }
  cancelToken = axios.CancelToken.source();
  return Axios({
    url: `${API_SERV_ADDR}/product/add-batch`,
    method: "POST",
    data: payload,
    cancelToken: cancelToken.token,
  });
};

export const deleteBatch = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/remove-batch`,
    method: "POST",
    data: payload,
  });
};

export const updateBatch = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/update-batch`,
    method: "POST",
    data: payload,
  });
};

export const addToCart = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/cart/add`,
    method: "PUT",
    data: payload,
  });
};

export const addToCartVendor = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/vendor/cart/add`,
    method: "PUT",
    data: payload,
  });
};

export const addSubscription = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/add-subscription`,
    method: "POST",
    data: payload,
  });
};
export const updateToCart = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/cart/update`,
    method: "POST",
    data: payload,
  });
};

export const deleteFromCart = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/cart/item/delete`,
    method: "DELETE",
    data: payload,
  });
};

//delete multiple products from cart

export const removeMultiple = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/cart/items/delete-by-ids`,
    method: "DELETE",
    data: payload,
  });
};

export const cartGet = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/cart/get`,
    method: "POST",
    data: payload,
  });
};
//updated

export const productImageRemove = (payload) => {
  const data = {
    ...payload,
    accountType: cookies.get("accountType"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/product/image-video-remove`,
    method: "DELETE",
    data: data,
  });
};

//add productFrom Central Repo
export const handleAddFromCentralRepo = (productId) => {
  let data = {
    productId: productId,
    vendorId: cookies.get("userId"),
    vendorType: cookies.get("accountType").toUpperCase(),
  };
  data = getLogAttributes(data, "Central Repo")

  return Axios({
    url: `${API_SERV_ADDR}/product/add-from-central-repo`,
    method: "POST",
    data: data,
  });
};

export const getLatestStock = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/vendor/list-search-update-stock`,
    method: "POST",
    data: payload,
  });
};

export const sendEnquiry = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/feedback/contact-us`,
    method: "POST",
    data: payload,
  });
};

//API for adding new customer
export const addNewCustomer = (payload) => {
  const data = {
    ...payload,
    accountType: "customer",
  };
  return Axios({
    url: `${API_SERV_ADDR}/quick-check-out/user/signup`,
    method: "PUT",
    data: data,
  });
};

//API to delete attatched documents
export const deleteVendorAttachment = (filepath) => {
  return Axios({
    url: `${API_SERV_ADDR}/vendor/file-remove`,
    method: "DELETE",
    data: {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
      filePath: filepath,
    },
  });
};

export const getRequestPayment = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/get-request-payment`,
    method: "POST",
    data: payload,
  });
};

export const requestPayment = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/request-payment`,
    method: "POST",
    data: payload,
  });
};

let cancelToken;

export const getRequestPaymentStatus = (payload) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelled");
  }
  cancelToken = axios.CancelToken.source();
  return Axios({
    url: `${API_SERV_ADDR}/order/get-request-status`,
    method: "POST",
    data: payload,
    cancelToken: cancelToken.token,
  });
};

export const exportRequestPaymentStatus = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/export-request-status`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

export const getManageAccount = (payload) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelled");
  }
  cancelToken = axios.CancelToken.source();
  return Axios({
    url: `${API_SERV_ADDR}/order/get-manage-account`,
    method: "POST",
    data: payload,
    cancelToken: cancelToken.token,
  });
};

export const exportManageAccount = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/export-manage-account`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

export const manageAccountUpdateStatus = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/manage-account-update-status`,
    method: "POST",
    data: payload,
  });
};

export const getInvoiceSplits = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/get-invoice-splits`,
    method: "POST",
    data: payload,
  });
};

export const updateBatchBarcode = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/change-barcode`,
    method: "POST",
    data: payload,
  });
};

export const generateNewBarcode = () => {
  return Axios({
    url: `${API_SERV_ADDR}/product/generate-barcode`,
    method: "GET",
  });
};


export const fetchBarcodeWithVariant = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/fetch-barcode-with-variant`,
    method: "POST",
    data: payload,
  });
};
