


import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, Menu, Typography } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import "./filters.scss";

import {
  addCategoryfilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
} from "../../../../../../ReduxStore/action/productActions";

const { Text } = Typography;

const { SubMenu } = Menu;
const CatogeryFilter = (props) => {
  const { width, filterCategories, setisFilterApplied } = props;
  const [hide, sethide] = useState(false);

  const handleUpdateFilter = (e, level, name) => {
    console.log("category filter updating: ", e, level, name);
    setisFilterApplied(true);
    switch (level) {
      case 0:
        props.addSubCategory00filter(e.key);
        break;
      case 1:
        props.addSubCategory01filter(e.key);
        break;
      case 2:
        props.addSubCategory02filter(e.key);
        break;
      case 3:
        props.addSubCategory03filter(e.key);
        break;
      default:
        console.log("invalid category level");
    }
  };
  const handleClick = (e) => {
    console.log("click ", e);
  };

  const extra = () => {
    return (
      <div onClick={() => sethide(!hide)}>
        {hide ? <DownOutlined /> : <UpOutlined />}
      </div>
    );
  };

  const title = () => {
    return (
      <Text onClick={() => sethide(!hide)} style={{ cursor: "pointer" }}>
        Category
      </Text>
    );
  };

  useEffect(() => {
    console.log("filterCategories: ", filterCategories);
  }, [filterCategories]);
  return (
    <Card
      title={title()}
      extra={extra()}
      style={{ width: width ? width : 310 }}
      className="filter-by-conatiner"
    >
      {/* //menu component should be replaced with some customer component inorder to fit to the design// */}
      {!hide && (
        <div className="filter-by-options filter_menu_list">
          <Menu onClick={handleClick} mode="inline">
            {filterCategories?.map((mainCategory) => {
              return (
                <SubMenu
                  onTitleClick={(e) =>
                    handleUpdateFilter(
                      e,
                      mainCategory.level,
                      mainCategory.categoryName
                    )
                  }
                  key={mainCategory.id}
                  title={mainCategory.categoryName}
                >
                  {mainCategory?.subCategories?.length > 0 &&
                    mainCategory.subCategories.map((subcategory01) => {
                      return (
                        <SubMenu
                          onTitleClick={(e) =>
                            handleUpdateFilter(
                              e,
                              subcategory01.level,
                              subcategory01.categoryName
                            )
                          }
                          key={subcategory01.id}
                          title={subcategory01.categoryName}
                        >
                          {subcategory01?.subCategories?.length > 0 &&
                            subcategory01.subCategories.map((subcategory02) => {
                              return (
                                <SubMenu
                                  onTitleClick={(e) =>
                                    handleUpdateFilter(
                                      e,
                                      subcategory02.level,
                                      subcategory02.categoryName
                                    )
                                  }
                                  key={subcategory02.id}
                                  title={subcategory02.categoryName}
                                >
                                  {subcategory02?.subCategories?.length > 0 &&
                                    subcategory02.subCategories.map(
                                      (subcategory03) => {
                                        return (
                                          <Menu.Item
                                            key={subcategory03.id}
                                            onClick={(e) =>
                                              handleUpdateFilter(
                                                e,
                                                subcategory03.level,
                                                subcategory03.categoryName
                                              )
                                            }
                                          >
                                            {subcategory03.categoryName}
                                          </Menu.Item>
                                        );
                                      }
                                    )}
                                </SubMenu>
                              );
                            })}
                        </SubMenu>
                      );
                    })}
                </SubMenu>
              );
            })}
          </Menu>
        </div>
      )}
    </Card>
  );
};

const mapDispatchToProps = {
  addCategoryfilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
};

export default connect(null, mapDispatchToProps)(CatogeryFilter);
