


import React from "react";
import { Button, Card, Divider, Typography } from "antd";
import moment from "moment";
import onlineIcon from "../../../Assets/Images/Icons/Seller/online-shopping.svg";
import calendarIcon from "../../../Assets/Images/Icons/Seller/calendar.svg";
import messageIcon from "../../../Assets/Images/Icons/Seller/chat.svg";
import "./manageReturns.scss";
import { FILE_PATH } from "../../../Utils/constants";

const { Text } = Typography;

const ReturnProductData = (props) => {
  return (
    <Card
      bordered={false}
      height={250}
      style={{ width: "100%", marginBottom: "20px", marginTop: "10px" }}
      className="manage-product-card-container"
    >
      <div className="flex">
        <div className="flex-align-center">
          <img
            className="manage-products-draft-product-card-image"
            src={FILE_PATH + props.data.source.image}
            alt={
              props &&
              props.data &&
              props.data.source &&
              props.data.source.productName
                ? props.data.source.productName
                : "company"
            }
          />
        </div>
        <div className="return-data-card-seller">
          <div className="justify-space-between">
            <div>
              <div className="seller-return-product-name">
                {props.data.source.productName}
              </div>

              <div className="flex">
                <div className="justify-space-between">
                  <div className="seller-return-product-price">
                    ₹ {props.data.source.itemCurrentPrice.sellingPrice}
                  </div>
                </div>
                <div className="seller-return-product-price">
                  <Text style={{ color: "#c1c1c1", marginLeft: "15px" }}>
                    Quantity: {props.data.source.quantity}
                  </Text>
                </div>
              </div>
              <div>
                <Text delete>₹ {props.data.source.itemCurrentPrice.price}</Text>
              </div>
              <div className="seller-return-name">
                {" "}
                {props.data.source.itemCurrentPrice.quantity +
                  " " +
                  props.data.source.itemCurrentPrice.unit}
              </div>
            </div>
            <div>
              {/* <div className=""> */}
              <div className="flex" style={{ justifyContent: "flex-end" }}>
                {moment(props.data.source.requestedDate).fromNow()}
              </div>
              <div> ORDER ID{" " + props.data.source.groupOrderId}</div>

              {/* </div> */}
              <div className="flex" style={{ justifyContent: "flex-end" }}>
                {/* <div> ORDR NO{"100"},</div> */}
                <div className="seller-return-name">
                  {" "}
                  {props.data?.source?.shippedAddress?.ownerName}
                  <br />
                  {props.data?.source?.shippedAddress?.addressLine1 +
                    (props.data?.source?.shippedAddress?.buildingName
                      ? ","
                      : "")}
                  {props.data?.source?.shippedAddress?.buildingName}
                  <br />
                  {props.data?.source?.shippedAddress?.pinCode}
                </div>
              </div>
            </div>
          </div>
          <Divider style={{ margin: "10px 0px" }} />

          <div className="justify-space-between">
            <div className=" flex-align-center">
              <img
                src={calendarIcon}
                style={{ width: "20px" }}
                className="verified-icon"
                alt="company"
              />
              {moment(props.data.source.requestedDate).format(
                " MMM DD, hh:mm A"
              )}
            </div>

            <div className=" flex-align-center seller-return-name">
              <img
                src={messageIcon}
                style={{ width: "20px" }}
                className="verified-icon"
                alt="company"
              />
              {props.data.source.reasonOfCancellation}
            </div>
            <Button
              className="seller-manage-products-card-action-button"
              onClick={() => props.openDetail(props.data)}
            >
              View Details
            </Button>
          </div>

          <div className=" flex order-item-active">
            {" "}
            <img
              src={onlineIcon}
              style={{ width: "25px" }}
              className="verified-icon"
              alt="company"
            />
            {props.data.source.paymentMode}{" "}
          </div>
        </div>
      </div>
    </Card>
  );
};
export default ReturnProductData;
