

import React, { useEffect, useState } from "react";
import Avatar from "antd/lib/avatar/avatar";
import { Button } from "antd";
import Cookies from "universal-cookie";
import { ENV_TYPE, DOMAIN } from "../../../Utils/constants";
const CustomerDetailsModal = (props) => {
  const cookies = new Cookies();
  const [userDetails, setUserDetails] = useState(props.details);

  useEffect(() => {
    setUserDetails(props.details);
  }, [props]);

  return (
    <div className="admin-manage-customer-details">
      <div>
        <Avatar
          icon={userDetails && userDetails.name && userDetails.name.charAt(0)}
        />
      </div>
      {userDetails && (
        <div className="customer-address-content">
          <div>{userDetails.name} </div>
          <div style={{ fontSize: "10px" }}> {userDetails.userCode}</div>
          <div className="address-location"> Email: {userDetails.email}</div>
          <div className="address-location-item">
            {" "}
            Phone: {userDetails.mobile}
          </div>
          <div className="address-location-item">
            {" "}
            Location: {userDetails.location} (
            <span style={{ color: "#0074be" }}>{userDetails.pinCode}</span>){" "}
          </div>

          {props.requests && (
            <div className="address-location">
              <Button
                className="button-seller-request"
                onClick={() => props.viewRequests()}
              >
                See {props.isAffiliate? "Affiliate" : "Seller"} Request
              </Button>
            </div>
          )}
          {props.isSeller && (
            <div className="address-location">
              <Button
                onClick={() => {
                  if (ENV_TYPE == "dev") {
                    cookies.set("switchuser", "true", {
                      path: "/",
                    });
                  } else
                    cookies.set("switchuser", "true", {
                      path: "/",
                      domain: `.${DOMAIN}`,
                    });
                  props.switchToSeller(userDetails.id);
                }}
                className="button-seller-view"
              >
                Switch To Seller View
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default CustomerDetailsModal;
