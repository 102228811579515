import React, { useEffect, useState } from 'react'
import { HiChevronRight } from "react-icons/hi";
import { MdClear } from "react-icons/md";

function LogDropDown(props) {

    const [selectedItem, setSelectedItem] = useState('');
    const [dropLists, setDropLists] = useState(props?.data);
    const {title, setOffSet, setCurrentPage} = props;

    const selectThisItem = (list) => {
        setSelectedItem(list)
        props.functionName(list)
        setOffSet(0)
        setCurrentPage(1)
    }

    useEffect(()=> {
        setDropLists(props?.data)
        props.functionName(selectedItem)
    },[props]);

    const clearText = () => {
        setSelectedItem('');
        props.functionName('')
        setOffSet(0)
        setCurrentPage(1)
    }

    return (
        <div className="LogDropDown-main-container" data-drop-title={title}>
            <p className="LogDropDown-selected-item" >{selectedItem}</p>
            <ul className="LogDropDown-list-box">
                {dropLists?.map((list, index)=> (
                    <li className="LogDropDown-lists" key={index} onClick={()=>selectThisItem(list)}>{list}</li>
                ))}
            </ul>
            { selectedItem ? (
                <MdClear onClick={clearText} className="LogDropDown-clear-text-icon" />
            ) : (
                <HiChevronRight className="LogDropDown-drop-icon" />                
            )}
            
        </div>
    )
}

export default LogDropDown
