

import axios from "axios";
import { API_SERV_ADDR } from "../../Utils/constants";
import Axios from "../axios";
import Cookies from "universal-cookie";
import { getLogAttributes } from "../../components/Admin/Logger/notificationBar/LogHelper";

const cookies = new Cookies();

export const AddContentFiles = (fileObj) => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/add/content`,
    method: "POST",
    data: fileObj,
  });
};

export const GetStaticFiles = (type) => {
  return Axios({
    url: `${API_SERV_ADDR}/get/content/${type}`,
    method: "GET",
  });
};

export const UpdateContentFiles = (data) => {
  data = getLogAttributes(data, "Manage Content")
  return Axios({
    url: `${API_SERV_ADDR}/admin/update/content`,
    method: "POST",
    data: data,
  });
};

//ge social media links
export const getSocialMediaLinks = () => {
  return Axios({
    url: `${API_SERV_ADDR}/get/social-media-profiles`,
    method: "GET",
  });
};

export const addSocialMediaLinks = (data) => {
  data = getLogAttributes(data, "Manage Content")
  return Axios({
    url: `${API_SERV_ADDR}/admin/add/social-media-profiles`,
    method: "POST",
    data: data,
  });
};

export const fetchUsers = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/list`,
    method: "POST",
    data: payload,
  });
};

let cancelToken;
export const fetchVendors = (payload) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelled");
  }
  cancelToken = axios.CancelToken.source();
  return Axios({
    url: `${API_SERV_ADDR}/vendor/list`,
    method: "POST",
    data: payload,
    cancelToken: cancelToken.token,
  });
};

export const addReturnMessage = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/add/return-reason`,
    method: "POST",
    data: payload,
  });
};

export const updateReturnMessage = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/update/return-reasons`,
    method: "POST",
    data: payload,
  });
};

export const getReturnMessage = () => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/get/return-reasons`,
    method: "GET",
  });
};

//cancel message
export const addCancelMessage = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/add/cancel-reason`,
    method: "POST",
    data: payload,
  });
};

export const updateCancelMessage = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/update/cancel-reasons`,
    method: "POST",
    data: payload,
  });
};

export const getCancelMessage = () => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/get/cancel-reasons`,
    method: "GET",
  });
};

export const addIdProof = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/add/id-proof-configuration`,
    method: "POST",
    data: payload,
  });
};

export const updateIdProof = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/update/id-proof-configuration`,
    method: "POST",
    data: payload,
  });
};

export const getIdProof = () => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/get/id-proof-configuration`,
    method: "GET",
  });
};

export const getBatchTypeAndVariants = () => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/get/batch-configurations`,
    method: "GET",
  });
};

export const addBatchAndType = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/add/batch-configuration`,
    method: "POST",
    data: payload,
  });
};

export const updateBatchAndType = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/update/batch-configuration`,
    method: "POST",
    data: payload,
  });
};

export const deleteBatchAndType = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/remove/batch-configuration`,
    method: "POST",
    data: payload,
  });
};

export const getUnconfirmedOrderCancel = () => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/get-unconfirmed-order-cancel-threshold`,
    method: "GET",
  });
};

export const postUnconfirmedOrderCancel = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/update-unconfirmed-order-cancel-threshold`,
    method: "POST",
    data: payload,
  });
};

export const getCompanyCommission = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/get/company-commission-configurations`,
    method: "GET",
  });
};

export const postCompanyCommission = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/add/company-commission-configuration`,
    method: "POST",
    data: payload,
  });
};

export const getSignUpPaymentAmount = () => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/get-signup-payment-amount`,
    method: "GET",
  });
};

export const postSignUpPaymentAmount = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/update-signup-payment-amount`,
    method: "POST",
    data: payload,
  });
};

export const deleteUsers = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/users/delete`,
    method: "DELETE",
    data: payload,
  });
};

export const getViewUser = (id) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/view/${id}`,
    method: "GET",
  });
};

export const getCategory = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/category/search`,
    method: "POST",
    data: payload,
  });
};

export const approveRejectUser = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/seller/request/approve-reject`,
    method: "POST",
    data: payload,
  });
};

export const getSubCategory = (id, level) => {
  const data = {
    accountType: cookies.get("accountType") === "seller" ? "seller" : "",
  };
  return Axios({
    url: `${API_SERV_ADDR}/category/sublist/${id}/${level}`,
    method: "POST", //changed method from get to post
    data: data,
  });
};

export const addAdminSection = (payload) => {
  payload = getLogAttributes(payload, "Manage Sections");
  return Axios({
    url: `${API_SERV_ADDR}/section/add`,
    method: "POST", //changed method from get to post
    data: payload,
  });
};

export const addAdminSectionReorder = (payload) => {
  payload = getLogAttributes(payload, "Manage Sections");
  return Axios({
    url: `${API_SERV_ADDR}/sections/reorder`,
    method: "POST", //changed method from get to post
    data: payload,
  });
};

export const addAdminSectionBannerImage = (payload) => {
  const formData = new FormData();
  formData.append("files", payload);
  return Axios({
    url: `${API_SERV_ADDR}/banner/image-upload`,
    method: "POST",
    headers: { "content-type": "multipart/form-data" },
    data: formData,
  });
};

export const addAdminSectionPopularImage = (payload) => {
  const formData = new FormData();
  formData.append("files", payload);
  return Axios({
    url: `${API_SERV_ADDR}/category/image-upload`,
    method: "POST",
    headers: { "content-type": "multipart/form-data" },
    data: formData,
  });
};

export const getCategoryBrand = (id) => {
  return Axios({
    url: `${API_SERV_ADDR}/brand/category/list/${id}`,
    method: "GET",
  });
};

export const getAdminSections = () => {
  return Axios({
    url: `${API_SERV_ADDR}/section/list-all`,
    method: "GET",
  });
};

export const userBlock = (data) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/lock_unlock`,
    method: "POST",
    data: data,
  });
};

export const deleteAdminSection = (id) => {
  let payload = getLogAttributes({}, "Manage Sections");
  return Axios({
    url: `${API_SERV_ADDR}/section/${id}/remove`,
    method: "DELETE",
    data: payload
  });
};

export const deleteBannerSection = (payload) => {
  payload = getLogAttributes(payload, "Manage Sections");
  return Axios({
    url: `${API_SERV_ADDR}/banner/remove`,
    method: "DELETE",
    data: payload,
  });
};

export const deletePopularSection = (payload) => {
  payload = getLogAttributes(payload, "Manage Sections")
  return Axios({
    url: `${API_SERV_ADDR}/section/popular-category/remove`,
    method: "POST",
    data: payload,
  });
};

export const deleteUserId = (id) => {
  let payload = getLogAttributes({}, "Manage Customer");
  return Axios({
    url: `${API_SERV_ADDR}/user/delete-id/${id}`,
    method: "DELETE",
    data: payload
  })
}

export const userArchive = (data) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/archive-unarchive`,
    method: "POST",
    data: data,
  });
};

export const userLogout = (id) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/logout`,
    method: "GET",
  });
};

export const userAddByAdmin = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/add`,
    method: "POST",
    data: payload
  });
};

//fetch vendorList
export const fetchVendorList = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/vendor/list-search`,
    method: "POST",
    data: payload,
  });
};

export const adminInvoiceSearch = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/invoice-search`,
    method: "POST",
    data: payload,
  });
};
//submitting ads
export const addAdvertisement = (payload) => {
  payload.append("logAttributes", JSON.stringify(getLogAttributes(payload, "Manage Advertisement")));
  return Axios({
    url: `${API_SERV_ADDR}/advertisement/add`,
    method: "POST",
    data: payload,
  });
};

// to get details of both seller and store
export const getVendors = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/get/vendors`,
    method: "POST",
    data: payload,
  });
};

// to get all ads in admin panel
export const getAllAds = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/advertisement/get`,
    method: "POST",
    data: payload,
  });
};

// to remove ads in admin panel
export const deleteAds = (payload) => {
  payload = getLogAttributes(payload, "Manage Advertisement")
  return Axios({
    url: `${API_SERV_ADDR}/advertisement/delete`,
    method: "POST",
    data: payload,
  });
};

// to update ads

export const updateAds = (payload) => {
  payload = getLogAttributes(payload, "Manage Advertisement")
  return Axios({
    url: `${API_SERV_ADDR}/advertisement/update`,
    method: "POST",
    data: payload,
  });
};
export const fetchEligibleCharges = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/fetch-eligible-charges`,
    method: "POST",
    data: payload,
  });
};
export const fetchPlatformInvoice = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/fetch-invoices`,
    method: "POST",
    data: payload,
  });
};
export const generateInvoice = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/generate-invoice`,
    method: "POST",
    data: payload,
  });
};
export const updatePlatformChargeStatus = (payload) => {
  payload = getLogAttributes(payload, "Manage Platform");
  return Axios({
    url: `${API_SERV_ADDR}/platform/update-status`,
    method: "PUT",
    data: payload,
  });
};
export const updateAffiliateChargeStatus = (payload) => {
  payload = getLogAttributes(payload, "Affiliate Charge");
  return Axios({
    url: `${API_SERV_ADDR}/affiliate/invoice/update-status`,
    method: "PUT",
    data: payload,
  });
};
export const fetchAllExceededPlatformCharge = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/fetch-exceeded-charges`,
    method: "POST",
    data: payload,
  });
};
export const fetchPlatformChargeExceed = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/check-platform-charge-exceeded`,
    method: "POST",
    data: payload,
  });
};

export const platformVendorBlock = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/user-block-unblock`,
    method: "POST",
    data: payload,
  });
};

export const payPlatformCharge = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/pay-platform-charge`,
    method: "POST",
    data: payload,
  });
};

export const verifyPlatformPayment = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/verify-payment`,
    method: "POST",
    data: payload,
  });
};

export const platformRazorPayFailed = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/failed-payment`,
    method: "POST",
    data: payload,
  });
};

export const platformDismissalFailed = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/platform/dismissed-payment`,
    method: "POST",
    data: payload,
  });
};

export const fetchEligibleAffiliateCharges = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/affiliate/fetch-eligible-charges`,
    method: "POST",
    data: payload,
  });
};

export const fetchAffiliateInvoice = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/affiliate/fetch-invoices`,
    method: "POST",
    data: payload,
  });
};

export const generateAffiliateInvoice = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/affiliate/generate-invoice`,
    method: "POST",
    data: payload,
  });
};

export const universalBarcodeSearch = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/universal-barcode-search`,
    method: "POST",
    data: payload,
  });
};

//Ab- fetch highest discounted products
export const fetchHighestDiscountProducts = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/topDiscountedProducts`,
    method: "POST",
    data: payload,
  });
}
//Ab- fetch all orders for admin
export const fetchAllOrdersAdmin = (payload) => {
  // if (typeof cancelToken != typeof undefined) {
  //   cancelToken.cancel("Cancelled");
  // }
  // cancelToken = axios.CancelToken.source();
  return Axios({
    url: `${API_SERV_ADDR}/order/manage-overall-orders`,
    method: "POST",
    data: payload,
    // cancelToken: cancelToken.token,
  });
}

// Request Payment by admin
export const getRequestPayment = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/get-all-seller-request-payment`,
    method: "POST",
    data: payload,
  });
};

export const requestPayment = (payload) => {
  payload = getLogAttributes(payload, "Manage Platform")
  return Axios({
    url: `${API_SERV_ADDR}/admin/send-request-payment-of-sellers`,
    method: "POST",
    data: payload,
  });
};
//replace central repo product
export const replaceCentralRepoProduct = (payload) => {
  payload = getLogAttributes(payload, "Manage Central Repo")
  return Axios({
    url: `${API_SERV_ADDR}/product/central-repo-replacement`,
    method: "POST",
    data: payload,
  });
};
//fetch all replaced central repo products
export const fetchReplacedProducts = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/fetch-replaced-central-repo-products`,
    method: "POST",
    data: payload,
  });
};
//delete central repo product
export const deleteCentralRepoProduct = (payload) => {
  payload = getLogAttributes(payload, "Manage Central Repo")
  return Axios({
    url: `${API_SERV_ADDR}/product/delete-replaced-central-repo-products`,
    method: "POST",
    data: payload,
  });
};
//fetch verified products
export const fetchVerfiedProducts = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/product/fetch-verified-central-repo-products`,
    method: "POST",
    data: payload,
  });
};

export const getCompanyDetails = () => {
  return Axios({
    url: `${API_SERV_ADDR}/get/company-details`,
    method: "GET",
  });
};

export const postCompanyDetails = (payload) => {
  payload = getLogAttributes(payload, "Configure");
  return Axios({
    url: `${API_SERV_ADDR}/admin/update-company-details`,
    method: "POST",
    data: payload,
  });
};
