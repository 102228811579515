import React, { Fragment, useEffect, useState } from "react";
import "./fmcg.scss";

import { connect } from "react-redux";
import Cookies from "universal-cookie";
import FmcgProduct from "./FmcgProduct";
import FmcgBrandAds from "./FmcgBrandAds";

import { FiArrowRight } from "react-icons/fi";

import {
  addSubCategory00filter,
  addBrandFilter,
} from "../../../../ReduxStore/action/productActions";

import {
  product_fastMoving,
  categoryGetBrands,
} from "../../../../ApiAction/CustomerApiActions/productApi";
import { message } from "antd";
import { ENV_TYPE, FILE_PATH } from "../../../../Utils/constants";
import EmptyList from "../../../GeneralComponents/emptyList";
import { withRouter } from "react-router";
import { compose } from "redux";
import FmcgBrands from "./FmcgBrands";

var CryptoJS = require("crypto-js");

function FmcgListing(props) {
  const [gradientTop] = useState("#D44000");
  const [gradientBottom] = useState("#FF7A00");

  const [productData, setProductData] = useState([]);
  const [totalValidBrands, setTotalValidBrands] = useState(0);
  const [brandList, setBrandList] = useState();

  const [emptyProductList, setEmptyProductList] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [brandLoading, setBrandLoading] = useState(false);

  const productListingLimit = 12;

  const cookies = new Cookies();

  useEffect(() => {
    let path = window.location.pathname;
    let encryptedCategoryId = path.split("fastmoving-listing/");
    window.scrollTo(0, 0);
    setProductData([]);
    setEmptyProductList(false);
    setListLoading(true);
    setBrandLoading(true);
    try {
      let decryptedCategoryId = CryptoJS.AES.decrypt(
        encryptedCategoryId[1],
        process.env.REACT_APP_ENCRYPTION_KEY
      );
      fetchFastMovingItems(
        JSON.parse(decryptedCategoryId.toString(CryptoJS.enc.Utf8))
      );
    } catch (error) {
      console.log("Error in decrypting category id :: ", error.message);
    }
  }, [props.pinCode, props.location]);

  const fetchFastMovingItems = (categoryId) => {
    const payload = {
      categoryId: categoryId,
      pinCode: cookies.get("activeUserPincode")
        ? cookies.get("activeUserPincode")
        : "",
    };
    product_fastMoving(payload)
      .then((response) => {
        if (response.data.status === "success") {
          checkIfResponseIsEmpty(
            response.data.subCategories,
            response.data.count
          );
          setProductData(response.data);
          fetchCategroyBrands(categoryId);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log("Error in fetching fastmoving products", error.message);
        message.error(error.message);
      })
      .finally(() => {
        setListLoading(false);
      });
  };

  const fetchCategroyBrands = (categoryTypeId) => {
    const payload = {
      level: "-1",
      categoryId: categoryTypeId,
      totalBrands: 5000,
    };
    categoryGetBrands(payload)
      .then((response) => {
        if (response?.data?.status === "success") {
          setBrandList(response?.data?.brands);
          countValidBrands();
        } else {
          setBrandList([]);
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setBrandLoading(false);
      });
  };

  const countValidBrands = () => {
    let totalBrands = 0;
    let temp = brandList?.map((brands) => {
      if (brands?.source?.image !== null) {
        totalBrands++;
        return brands?.source?.image;
      }
    });
    console.log("Total valid brands :: ", totalBrands);
    console.log(temp);
    setTotalValidBrands(totalBrands);
  };

  const checkIfResponseIsEmpty = (response, totalCount) => {
    let nonZeroRecordCount = 0;
    let itratedValue = response.map((data) => {
      if (data.count === 0) {
        nonZeroRecordCount++;
      }
    });
    console.log("totalCount and non", totalCount, nonZeroRecordCount);

    if (nonZeroRecordCount === totalCount) {
      setEmptyProductList(true);
    } else {
      setEmptyProductList(false);
    }
  };

  const handleRedirection = (categoryName, Id) => {
    props.addSubCategory00filter(Id);
    handleManageBreadCrumb(categoryName, Id);
    let pathname = ENV_TYPE === "production"
    ? `/product-listing/${categoryName}`
    : `/customer/product-listing/${categoryName}`
    console.log(pathname, pathname.includes('fastmoving-listing'))
    console.log(props.location.pathname)
    let FMCGIds = JSON.parse(localStorage.getItem("megamenu")).find(o => o.categoryNameAnalyzed === 'FMCG').childCategoryId
    FMCGIds.find(FMCGId => Id === FMCGId)? 
    props.history.push({pathname: pathname, state: {categoryId: Id}}) : props.history.push(pathname);
  };

  const handleManageBreadCrumb = (categoryName, Id) => {
    let breadCrumbList = [];
    let breadcrumb = {
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${categoryName}`
          : `/customer/product-listing/${categoryName}`,
      type: "category",
    };
    breadcrumb["label"] = categoryName;
    breadcrumb["id"] = Id;

    breadCrumbList.push(breadcrumb);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  const handleBrandRedirect = (brandId) => {
    props.addBrandFilter([brandId]);
    handleManageBreadCrumb(
      productData?.parentCategoryName,
      productData?.parentCategoryId
    );
    props.history.push(
      ENV_TYPE === "production"
        ? `/product-listing/${productData?.parentCategoryName}`
        : `/customer/product-listing/${productData?.parentCategoryName}`
    );
  };

  return (
    <div className="fmcg-main-container">
      {!productData?.subCategories && (
        <div
          style={{
            background: `linear-gradient(${gradientTop},${gradientBottom})`,
          }}
          className="fmcg-banner-container"
        >
          <p className="fmcg-text-skeleton"></p>
        </div>
      )}

      <div className="fmcg-brand-listing-container">
        {!brandLoading ? (
          <Fragment>
            {brandList?.map((brandImg, index) => {
              if (brandImg?.source?.image !== null) {
                return (
                  <img
                    key={index}
                    onClick={() =>
                      handleBrandRedirect(brandImg?.source?.brandId)
                    }
                    src={FILE_PATH + brandImg?.source?.image}
                    className="fmcg-brand-listing-container-img"
                  />
                );
              }
            })}
          </Fragment>
        ) : (
          <Fragment>
            <div className="type-category-section-skeleton-box">
              <div className="type-category-section-skeleton-head"></div>
              <div className="type-category-section-skeleton-tail"></div>
            </div>
            <div className="type-category-section-skeleton-box">
              <div className="type-category-section-skeleton-head"></div>
              <div className="type-category-section-skeleton-tail"></div>
            </div>
            <div className="type-category-section-skeleton-box">
              <div className="type-category-section-skeleton-head"></div>
              <div className="type-category-section-skeleton-tail"></div>
            </div>
            <div className="type-category-section-skeleton-box">
              <div className="type-category-section-skeleton-head"></div>
              <div className="type-category-section-skeleton-tail"></div>
            </div>
            <div className="type-category-section-skeleton-box">
              <div className="type-category-section-skeleton-head"></div>
              <div className="type-category-section-skeleton-tail"></div>
            </div>
            <div className="type-category-section-skeleton-box">
              <div className="type-category-section-skeleton-head"></div>
              <div className="type-category-section-skeleton-tail"></div>
            </div>
          </Fragment>
        )}
      </div>

      {productData?.subCategories?.length > 0 && (
        <Fragment>
          {productData?.subCategories?.length > 0 &&
            productData?.subCategories?.map((product, index) => {
              if (product?.sectionData?.length > 0) {
                return (
                  <Fragment>
                    {product?.advertisements !== null &&
                      product?.advertisements?.advertisementBanner !== null &&
                      product?.advertisements?.advertisementBanner?.length >
                        0 && (
                        <FmcgBrands
                          categoryName={product?.subCategory0Name}
                          categoryId={product?.subCategory0Id}
                          adInfo={product?.advertisements?.advertisementBanner}
                        />
                      )}

                    <div className="fmcg-each-container" key={index}>
                      <span>
                        <p className="fmcg-each-container-title">
                          {product?.subCategory0Name}
                        </p>
                      </span>
                      {product && (
                        <button
                          className="fmcg-each-container-view-all-button"
                          onClick={() =>
                            handleRedirection(
                              product?.subCategory0Name,
                              product?.subCategory0Id
                            )
                          }
                        >
                          View All{" "}
                          <FiArrowRight className="fmcg-each-container-view-all-button-icon" />
                        </button>
                      )}

                      {product?.advertisements !== null &&
                        product?.advertisements?.advertisementCard !== null &&
                        product?.advertisements?.advertisementCard?.length >
                          0 && (
                          <div className="fmcg-brand-ads-container">
                            {product?.advertisements?.advertisementCard?.map(
                              (cardInfo, cardIndex) => {
                                return (
                                  <FmcgBrandAds
                                    key={cardIndex}
                                    banner={cardInfo.images[0]}
                                    adInfo={cardInfo}
                                    categoryName={product?.subCategory0Name}
                                    categoryId={product?.subCategory0Id}
                                  />
                                );
                              }
                            )}
                          </div>
                        )}

                      <div className="fmcg-each-container-product-listing-box">
                        {product?.sectionData?.length > 0 &&
                          product?.sectionData?.map((item, index2) => {
                            if (index2 < productListingLimit) {
                              return (
                                <FmcgProduct
                                  product={item?.source}
                                  key={index2}
                                />
                              );
                            }
                          })}
                      </div>
                    </div>
                  </Fragment>
                );
              }
            })}
        </Fragment>
      )}
      {listLoading && (
        <div className="fmcg-main-skeleton">
          <div className="fmcg-main-skeleton-top">
            <div className="fmcg-main-skeleton-top-text"></div>
            <div className="fmcg-main-skeleton-top-text"></div>
          </div>
          <div className="fmcg-main-skeleton-bottom">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      {emptyProductList && <EmptyList msg="Empty now, filling soon" />}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    pinCode: state.productReducer.pinCode,
  };
};

const mapDispatchToProps = {
  addSubCategory00filter,
  addBrandFilter,
};

//export default withRouter(connect(mapStateToProps,null, mapDispatchToProps))(FmcgListing);
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(FmcgListing);

//export default FmcgListing
