import React, { useState } from 'react'
import { FaCalendarAlt } from "react-icons/fa";
import { HiOutlineDownload } from "react-icons/hi";
import { FiChevronRight } from "react-icons/fi";
import { DatePicker } from 'antd'
import moment from 'moment';
import { IoMdCloseCircle } from "react-icons/io";

function LogDateFilter(props) {

    const { RangePicker } = DatePicker;
    const [showDateRanger, setShowDateRanger] = useState(false);
    const [selectedRangeDate, setSelectedRangeDate] = useState(false);
    const [fromDate, setFromDate] = useState()
    const [toDate, setToDate] = useState()
    const { setStartDate, setEndDate, downloadReady, setOffSet, setCurrentPage } = props

    const dateRangeHandler = (dateString) => {
        setOffSet(0)
        setCurrentPage(1)
        if(dateString !== null) {
            setFromDate(moment(dateString[0]).format("YYYY-MM-DD"))
            setToDate(moment(dateString[1]).format("YYYY-MM-DD"))
            setStartDate(moment(dateString[0]).format("YYYY-MM-DD"))
            setEndDate(moment(dateString[1]).format("YYYY-MM-DD"))
            downloadReady(true)

            setSelectedRangeDate(true)
        }else {
            downloadReady(false)

            setSelectedRangeDate(!selectedRangeDate)
            setFromDate(moment(new Date()).format("YYYY-MM-DD"))
            setToDate(moment(new Date()).format("YYYY-MM-DD"))
        }
        setShowDateRanger(false)
    }

    const clearSelectedDate = () => {
        setSelectedRangeDate(!selectedRangeDate)
        setShowDateRanger(false)
        setFromDate('')
        setEndDate('')
        setToDate('')
        setStartDate('')
        downloadReady(false)
        setOffSet(0)
        setCurrentPage(1)
    }    

    return (
        <div className="LogDateFilter-main-container" >
            <div className="LogDateFilter-calender-icon" onClick={()=> setShowDateRanger(!showDateRanger)}>
                <FaCalendarAlt />
            </div>
            { showDateRanger && (
                <div className="LogDateFilter-calender-select-box">
                    <RangePicker inputReadOnly className="LogDateFilter-calender-rangepicker" onChange={dateRangeHandler}  />
                </div>
            )}
            <div className="LogDateFilter-calender-selected-dates">
                <p style={{ fontSize: "0.6rem"}} onClick={()=> setShowDateRanger(!showDateRanger)}>{fromDate ? fromDate : 'FROM DATE'}</p>
                {selectedRangeDate ? (
                    <IoMdCloseCircle className="LogDateFilter-calender-selected-date-icon-close" onClick={clearSelectedDate} />
                ) : (
                    <FiChevronRight className="LogDateFilter-calender-selected-date-icon" />
                )}
                
                <p style={{ fontSize: "0.6rem"}}>{toDate ? toDate : 'TO DATE'}</p>
            </div>
            {/* <button className="LogDateFilter-export-button"><HiOutlineDownload style={{fontSize: "1rem"}} /></button> */}
        </div>
    )
}

export default LogDateFilter
