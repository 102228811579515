

import React, { useState, useEffect } from "react";
import { Modal, Button, Typography, Form, Input, message } from "antd";
import CompanyLogo from "../../Assets/Images/Logo/DL_Logo.png";
import Cookies from "universal-cookie";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateEmailAndLogin } from "../../ApiAction/CustomerApiActions/customerApi";

import { openSignUpModal } from "../../ReduxStore/action/authModalActions";
import { ENV_TYPE, DOMAIN } from "../../Utils/constants";
import Loading from "./Loading";
var CryptoJS = require("crypto-js");
const { Title, Text } = Typography;
const UpdateEmailModal = (props) => {
  const { initialUserData } = props;
  const [errorMessage, seterrorMessage] = useState("");
  const [loading, setloading] = useState(false);
  const [remember, setRemember] = useState(true);
  const [form] = Form.useForm();
  const cookies = new Cookies();

  const formFields = [
    {
      name: "emailId",
      placeHolder: "Enter your Email ID",
      ruleType: "email",
      message: "Enter a vallid email Id",
    },
  ];

  const handleTriggerCustomerLogin = async (response) => {
    if (ENV_TYPE === "dev") {
      var ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(response.token),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      if (localStorage.getItem("remember") == "true")
        localStorage.setItem("authToken", ciphertext, {
          path: "/",
        });
      else
        sessionStorage.setItem("authToken", ciphertext, {
          path: "/",
        });
      // cookies.set("authToken", ciphertext, {
      //   path: "/",
      // });
      cookies.set("userId", response.userId, {
        path: "/",
      });

      cookies.set("accountType", response.accountType, {
        path: "/",
      });
      cookies.set("activeUserPincode", response.pinCode, {
        path: "/",
      });
    } else {
      var ciphertext2 = CryptoJS.AES.encrypt(
        JSON.stringify(response.token),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      if (localStorage.getItem("remember") == "true")
        localStorage.setItem("authToken", ciphertext2, {
          path: "/",
          domain: `.${DOMAIN}`,
        });
      else
        sessionStorage.setItem("authToken", ciphertext2, {
          path: "/",
          domain: `.${DOMAIN}`,
        });
      // cookies.set("authToken", ciphertext2, {
      //   path: "/",
      //   domain: `.${DOMAIN}`,
      // });
      cookies.set("userId", response.userId, {
        path: "/",
        domain: `.${DOMAIN}`,
      });

      cookies.set("accountType", response.accountType, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      cookies.set("activeUserPincode", response.pinCode, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    }
    // props.setPincode(response.pinCode);
    message.success(response.message);
    const activeCustomer = response;
    await delete activeCustomer.token;
    localStorage.setItem("activeCustomer", JSON.stringify(activeCustomer));
    props.setVisible(false);
    window.location.reload(true);
  };
  const onFinish = (values) => {
    const payload = { userId: initialUserData.userId, email: values.emailId };
    setloading(true);
    localStorage.setItem("remember", values.remember);
    setRemember(values.remember);
    updateEmailAndLogin(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log("response for update email and login:", response);
            handleTriggerCustomerLogin(response.data);
            seterrorMessage("");
          } else {
            if (response.data?.message) {
              seterrorMessage(response.data.message);
            } else {
              seterrorMessage("something went wrong please please try again");
            }
            console.log(
              "Response for failure case of update email: ",
              response
            );
          }
        } else {
          seterrorMessage("something went wrong please please try again");
        }
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };
  return (
    <Modal
      className="circle_drop"
      visible={props.visible}
      onOk={() => {
        form.resetFields();
        props.setVisible(false);
      }}
      onCancel={() => {
        form.resetFields();
        props.setVisible(false);
      }}
      width={600}
      footer={null}
    >
      <img
        style={{ width: "150px" }}
        src={CompanyLogo}
        alt={"company logo"}
        className="hide_logo_mob"
      />
      <div className="contact-modal-form login-modal-form form_data_group">
        <Title level={3}> Update Email ID </Title>
        <Text style={{ marginBottom: "20px" }}>
          Seems to be already an user exists with the emailId{" "}
          <a> {initialUserData?.email}</a>
          please use other email address to continue login.
        </Text>
        <Form
          name="normal_login"
          className="login-form"
          form={form}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          {formFields.map((data, index) => {
            return (
              <Form.Item
                hasFeedback
                key={index}
                name={data.name}
                rules={[
                  {
                    type: data.ruleType ? data.ruleType : null,
                    message: data.message ? data.message : null,
                  },
                  {
                    required: data.referalId ? false : true,
                    message: "This field is required !",
                  },
                  {
                    validator: data.validator ? data.validator : "",
                  },
                ]}
              >
                <Input
                  ref={data.ref ? data.ref : null}
                  type={data.type ? data.type : null}
                  placeholder={data.placeHolder}
                />
              </Form.Item>
            );
          })}

          <Form.Item className="button_submit">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ fontWeight: "600" }}
            >
              {loading ? (
                <Loading style={{ color: "white" }} />
              ) : (
                " Update Email ID"
              )}
            </Button>
            <br />
          </Form.Item>
        </Form>

        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  openSignUpModal,
};
const mapStateToProps = (state) => {
  return {
    authModalUserType: state.authModalReducer.userType,
    authModalType: state.authModalReducer.modalType,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UpdateEmailModal)
);
