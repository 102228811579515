

import React, { useState, useEffect } from "react";
import "./QuantityController.scss";
import { message } from "antd";

const QuantityController = (props) => {
  const { isOrderEdit } = props;
  const [value, setValue] = useState(props.quantity);

  const handleChangeCount = (event) => {
    if (event.target.value === "") {
      console.log(event.target.value, props.stock, ":value for quantity field");
      props.setQuantity(0,props?.item);
      setValue(0);
    } else {
      if (event.target.value && event.target.value <= props.stock) {
        props.setQuantity(Number(event.target.value), props?.item);
        setValue(Number(event.target.value));
        console.log(
          Number(event.target.value),
          props.stock,
          ":value for quantity field"
        );
      } else {
        isOrderEdit
          ? message.error(
              "The customer has only requested for " +
                props.stock +
                " quantity of products."
            )
          : message.error(
              "sorry for the inconvinience only " +
                props.stock +
                " stocks are available for now."
            );
      }
    }
  };
  const increment = () => {
    handleFocus();
    if (value + 1 <= props.stock) {
      props.setQuantity(value + 1,props?.item);

      setValue(value + 1);
      // props.setQuantity((prevValue) => prevValue + 1);
    } else {
      isOrderEdit
        ? message.error(
            "The customer has only requested for " +
              props.stock +
              " quantity of products."
          )
        : message.error(
            "sorry for the inconvinience only " +
              props.stock +
              " stocks are available for now."
          );
    }
  };

  const handleFocus = () => {
      document.getElementById("editQty").focus();
    };

  const decrement = () => {
    handleFocus();
    if (value > 1) {
      props.setQuantity(value - 1, props?.item);

      setValue(value - 1);
    }
    // props.setQuantity((prevValue) => (prevValue > 1 ? prevValue - 1 : 1));
  };
  // useEffect(() => {
  //   console.log(value, ":value here");
  //   if (props.update == true) {
  //     console.log("value updated");
  //     props.setQuantity(value);
  //   }
  // }, [value]);

  useEffect(() => {
    setValue(props.quantity);
  }, [props.quantity]);

  return (
    <div className="quantity-main">
      <div className="quantity-input">
        <button
          id="editQty"
          className="quantity-input__modifier quantity-input__modifier--left cart-quantity-controller" style={{  color: "#07044c",
  fontWeight: "500"}}
          onClick={() => decrement()}
        >
          &mdash;
        </button>
        <input
          className="quantity-input__screen" style={{  color: "#07044c",
          fontWeight: "500"}}
          type="text"
          value={value}
          // readonly
          onChange={(e) => handleChangeCount(e)}
        />
        <button
          id="editQty"
          className="quantity-input__modifier quantity-input__modifier--right cart-quantity-controller "  style={{  color: "#07044c",
  fontWeight: "500"}}
          onClick={() => increment()}
        >
          &#xff0b;
        </button>
      </div>
    </div>
  );
};

export default QuantityController;
