

import React, { useState, useEffect, Fragment } from "react";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";

import {
  Row,
  Col,
  Avatar,
  Checkbox,
  Input,
  Select,
  Collapse,
  message,
  Button,
} from "antd";
import { FILE_PATH } from "../../../Utils/constants";
import CancelOrderRequest from "../../Customer/Components/ManageOrders/Components/cancelOrderRequest";
import EditOrderModal from "./editOrderModal";
import { editVendorOrder } from "../../../ApiAction/SellerApiActions/vendoOrderAPI";
import { getLatestStock } from "../../../ApiAction/SellerApiActions/sellerApi";
import Loading from "../../GeneralComponents/Loading";

import LazyImage from "./LazyImage";
class OrderData extends React.Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      isActivePanel: "",
      selectedItems: [],
      orderData: null,
      checked: false,
      cancelOrderModal: false,
      editOrderModal: false,
      currentStock: 0,
      isLoadingEditOrder: false,
    };
  }

  handleEditItem = (vendorId, orderGroupId, orderId) => {
    const payload = {
      vendorId: vendorId,
      orderGroupId: orderGroupId,
      orderId: orderId,
    };
    console.log("this is the vendor id : ", vendorId);
    console.log("this is the group id : ", orderGroupId);
    console.log("this is the order id : ", orderId);
    this.setState({ isLoadingEditOrder: true });
    getLatestStock(payload).then((response) => {
      console.log("latest stock response: ", response.data.message);
      if (response.data.status == "success") {
        this.setState({ currentStock: response.data.currentStock });
        this.setState({ isLoadingEditOrder: false });
        this.setState({ editOrderModal: true });
      } else {
        this.setState({ isLoadingEditOrder: false });
        message.error(response.data.message);
      }
    });
  };

  handleCloseEditModal = () => {
    this.setState({ editOrderModal: false });
  };

  handleEditOrder = (quantity) => {
    const payload = {
      orderGroupId: this.props.orderGroupId,
      orderId: this.state.orderData.orderId,
      quantity: quantity,
    };

    this.props.handleEditVendorOrder(payload);
    this.handleCloseEditModal();
  };
  handleCancelItem = () => {
    this.setState({ cancelOrderModal: true });
  };

  setCancelOrderVisible = () => {
    this.setState({ cancelOrderModal: false });
  };

  handleCancelOrder = (reason = "") => {
    this.setCancelOrderVisible();
    const payload = {
      orderGroupId: this.props.orderGroupId,
      orderId: this.state.orderData.orderId,
      reasonOfCancellation: reason,
    };
    this.props.setCancelOrder(payload);
  };

  isChecked = async (orderId) => {
    const result = await this.props.selectedItems.find((data) => {
      return data.id === orderId;
    });
    if (result) {
      return true;
    } else return false;
  };

  selectdProducts = (value, item) => {
    // if(this.state.orderData.itemOrderStatus.currentStatus ==="SHIPPED")
    // {
    //     message.warning("This product has been shipped")

    // }
    if (this.state.orderData.itemOrderStatus.currentStatus === "CANCELLED") {
      message.warning("This product has been cancelled");
    } else if (
      this.state.orderData.itemOrderStatus.currentStatus === "DELIVERED"
    ) {
      message.warning("This product has been delivered");
    } else if (
      this.state.orderData.itemOrderStatus.currentStatus === "RETURN_REQUESTED"
    ) {
      message.warning("This product has been requested for return");
    } else {
      this.setState({ checked: value });

      this.props.checkBoxSelection(
        value,
        item,
        this.props.orderId,
        this.props.orderGroupId
      );
    }
  };
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):

    if (prevProps.selectedItems !== this.props.selectedItems) {
      //update checked state
      this.isChecked(this.props.orderData.id).then((response) => {
        this.setState({
          checked: response,
        });
        console.log(
          "reponse for isChecked in component did update: ",
          response
        );
      });
    }

    // if (this.props.checked !== prevProps.checked) {
    //     this.setState({ checked: false });
    // }
  }
  componentDidMount() {
    // Typical usage (don't forget to compare props):

    this.setState({ orderData: this.props.orderData });
    this.isChecked(this.props.orderData.id).then((response) => {
      this.setState({
        checked: response,
      });
      console.log(response, "response for is checked");
    });
  }

  render() {
    return (
      <div className="cart-items" key={this.props.orderData.id}>
        {this.state.orderData && (
          <Row style={{ width: "100%" }}>
            <Col span={12}>
              <div className="flex">
                {this.props.itemCount > 1 && (
                  <Checkbox
                    className="manage-order-checkbox"
                    checked={this.state.checked}
                    onChange={(e) =>
                      this.selectdProducts(
                        e.target.checked,
                        this.state.orderData
                      )
                    }
                  />
                )}

                <div className="flex-center">
                  {/* <Avatar
                    src={FILE_PATH + this.state.orderData.image}
                    size={50}
                    icon={<UserOutlined />}
                  /> */}

                  <LazyImage
                    //   key={i}
                    src={FILE_PATH + this.state.orderData.image}
                    //  alt={`Random image ${i}`}
                    alt={
                      this.state.orderData && this.state.orderData.productName
                        ? this.state.orderData.productName
                        : "company"
                    }
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    marginLeft: "15px",
                  }}
                >
                  <div className="cart-name-layout">
                    <div className="product-cart-name">
                      {this.state.orderData.orderId}
                    </div>
                    <div className="manage-order-product-name">
                      {this.state.orderData.productName}
                    </div>
                    <div className="flex">
                      <div>
                        <div className="cart-price">
                          ₹{this.state.orderData.itemCurrentPrice.sellingPrice}
                        </div>
                        <div className="cart-actual-price">
                          {this.state.orderData.itemCurrentPrice.price}
                        </div>
                      </div>
                      <div className="cart-size">
                        {`  ${this.state.orderData.itemCurrentPrice.quantity} ${this.state.orderData.itemCurrentPrice.unit}`}
                      </div>
                      <div className="cart-size">
                        Qty: {this.state.orderData.quantity}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={5} className="order-shipped-date">
              <div style={{ color: "green", fontWeight: "500" }}>
                {" "}
                {this.state?.orderData?.itemOrderStatus?.currentStatus?.replace(
                  /_/g,
                  " "
                )}
              </div>
              <div className="order-ship-date-border"> </div>
              <div>
                {" "}
                {moment(
                  this.state.orderData.itemOrderStatus.orderedDate
                ).format(" MMM ")}
              </div>
              <div className="order-ship-date-border"> </div>
              <div className="order-platform-container">
                <h4>PC</h4>
                <h4>
                  {(
                    this.props?.orderData?.totalAmount *
                    (this.props?.orderData?.categoryCommission / 100)
                  )?.toFixed(2)}
                </h4>
              </div>
            </Col>
            <Col span={7} className="order-shipped-date">
              <div>
                {!this.state.checked &&
                  this.state.orderData.itemOrderStatus.currentStatus !==
                    "CANCELLED" && (
                    <div
                      style={{
                        color: "blue",
                        fontWeight: "500",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        this.props.updateStatus(
                          this.state.orderData,
                          this.props.orderId,
                          this.props.orderGroupId,
                          this.props.accountType
                        )
                      }
                    >
                      {" "}
                      Tracking Details
                    </div>
                  )}

                {this.state.orderData.itemOrderStatus.currentStatus ==
                  "ORDERED" && (
                  <Fragment>
                    <div
                      className="order_prouct_details_action cancle-ord"
                      style={{ marginTop: "10px" }}
                    >
                      <Button
                        style={{
                          border: "1px solid red",
                          marginRight: "5px",
                          width: "116px",
                        }}
                        type="text"
                        onClick={() =>
                          this.handleEditItem(
                            this.props.vendorId,
                            this.props.orderGroupId,
                            this.props.orderData.orderId
                          )
                        }
                      >
                        {this.state.isLoadingEditOrder ? (
                          <Loading />
                        ) : (
                          "Edit Order"
                        )}
                      </Button>
                    </div>
                    <div
                      className="order_prouct_details_action cancle-ord"
                      style={{ marginTop: "10px" }}
                    >
                      <Button
                        style={{
                          border: "1px solid red",
                          marginRight: "5px",
                          width: "116px",
                        }}
                        type="text"
                        onClick={() => this.handleCancelItem()}
                      >
                        Cancel Item
                      </Button>
                    </div>
                  </Fragment>
                )}
              </div>
            </Col>
          </Row>
        )}

        {this.state.cancelOrderModal === true && (
          <CancelOrderRequest
            visible={this.state.cancelOrderModal}
            setVisible={this.setCancelOrderVisible}
            setSuccess={this.handleCancelOrder}
            cancelItem
          />
        )}

        {this.state.editOrderModal && (
          <EditOrderModal
            visible={this.state.editOrderModal}
            setVisible={this.handleCloseEditModal}
            handleEditOrder={this.handleEditOrder}
            maxQuantity={this.state.orderData.quantity}
            availableStock={this.state.currentStock}
            productName={this.state.orderData.productName}
            paymentMode={this.props.paymentMode}
          />
        )}
      </div>
    );
  }
}

export default OrderData;
