import Axios from "../axios";
import { API_SERV_ADDR } from "../../Utils/constants";
import axios from "axios";

// for log authentication (log)
export const log_verify_auth = (data) => {
  return Axios({
    url: `${API_SERV_ADDR}/log/verify-auth`,
    method: "POST",
    data: data,
  });
};
// to get all screen name
export const log_get_screens = () => {
  return Axios({
    url: `${API_SERV_ADDR}/log/get-screens`,
    method: "GET",
  });
};

// to get all log list
let cancelToken;
export const log_get_all = (payload) => {
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelled");
  }
  cancelToken = axios.CancelToken.source();  
  return Axios({
    url: `${API_SERV_ADDR}/log/get-logs/`,
    method: "POST",
    data: payload,
    cancelToken: cancelToken.token
  });
};
// to get all action names
export const log_get_actions = () => {
  return Axios({
    url: `${API_SERV_ADDR}/log/get-actions`,
    method: "GET",
  });
};
// to get details of data entry members
export const get_data_entry_users = () => {
  return Axios({
    url: `${API_SERV_ADDR}/log/work-report/user-details`,
    method: "GET",
  });
};
// to download CSV file
export const log_get_export = (data) => {
  return Axios({
    url: `${API_SERV_ADDR}/log/export-logs`,
    method: "POST",
    data: data,
    responseType: "blob",
  });
};