


import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Button,
  Typography,
  Form,
  Input,
  message,
  notification,
  Spin,
} from "antd";
import CompanyLogo from "../../Assets/Images/Logo/DL_Logo.png";
import { ReactComponent as Phone } from "../../Assets/Images/Icons/Seller/phone.svg";
import { ReactComponent as Mail } from "../../Assets/Images/Icons/Seller/mail.svg";
import Icon from "@ant-design/icons";
import { CloseCircleOutlined } from "@ant-design/icons";
import { loginCustomer, resetPasswordApi } from "../../ApiAction/CustomerApiActions/customerApi";
const { Title } = Typography;
const ValidateOtp = (props) => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState("");
  const [fields, setfields] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const passwordRef = useRef();

  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };

  const PasswordValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 8) {
      callback("Password should consists of minimun of 8 chartcters");
    } else {
      callback();
    }
  };

  const compareToFirstPassword = (rule, value, callback) => {
    console.log("hello_validaton password", passwordRef.current.props.value);
    const password = passwordRef.current.props.value;

    if (value && value !== password) {
      callback("Passwords mismatch. Please re-enter");
    } else {
      callback();
    }
  };
  const formFields = [
    {
      name: "otp",
      placeHolder: "OTP *",
      rules: [
        {
          pattern: new RegExp("^[0-9]*$"),
          message: "Enter a valid OTP",
        },
        {
          required: true,
          message: "This field is required !",
        },
      ],
    },

  ];

  const onFinish = (values) => {
    console.log(values)
    const payload = {
      otp: parseInt(values.otp),
      ...props.payload
    };
    if (props.isCustomer) {
      payload["referralId"] = values.referralId;
    }
    setisLoading(true);
    if(props.signUp) {
      props.setOtp(values.otp);
      setisLoading(false);
    } else {
      loginCustomer(payload)
        .then((response) => {
          if (response.data) {
            if (response.data.status == "failed") {
              setErrorMessage(response.data.message);
              openNotificationWithIcon("error", "Failed", response.data.message);
            } else {
              // message.success(response.data.message);
              props.setTwoFactorAuth(response);
              form.resetFields();
              props.setVisible();
            }
          }
        })
        .catch(() => {})
        .finally(() => {
          setisLoading(false);
        });
    }
  };

  useEffect(() => {
     setfields([formFields])
  }, []);

  return (
    <Modal
      className="circle_drop"
      visible={props.visible}
      onOk={() => {
        props.setVisible();
        form.resetFields();
      }}
      onCancel={() => {
        {
          props.setVisible();
          form.resetFields();
          props.setOtp();
        }
      }}
      width={600}
      footer={null}
    >
      <img
        style={{ width: "150px" }}
        src={CompanyLogo}
        alt={"company logo"}
        className="hide_logo_mob"
      />
      <div className="contact-modal-form  login-modal-form form_data_group">
        <Title level={3}> Enter OTP </Title>

        <div style={{ fontWeight: "500" }}>
          {/* Enter the email address associated with your account  */}
        </div>
        <Spin spinning={isLoading}>
          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >

            <Form.Item
              key={"otp"}
              hasFeedback
              name={"otp"}
              rules={[
                {
                  pattern: new RegExp("^[0-9]*$"),
                  message: "Enter a valid OTP",
                },
                {
                  required: true,
                  message: "This field is required !",
                },
              ]}
            >

              <Input
                placeholder={"OTP *"}
              />

            </Form.Item>

            <Form.Item className="button_submit">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{ fontWeight: "600" }}
              >
                VALIDATE OTP
              </Button>
              <br />
            </Form.Item>
          </Form>
        </Spin>

        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </Modal>
  );
};
export default ValidateOtp;
