


import React, { useEffect, useState } from "react";

import { Card, Row, Col, Avatar, Select, message, Popconfirm } from "antd";

import { fetchBrandList } from "../../../../ApiAction/AdminApiAction/brandApi";
import { FILE_PATH } from "../../../../Utils/constants";

import "./addBrand.css";

import ActiveTopIcon from "../../../../Assets/Images/Icons/top.svg";
import InactiveTopIcon from "../../../../Assets/Images/Icons/crown_inactive.svg";
import DeleteIcon from "../../../../Assets/Images/Icons/delete-light.svg";

const { Option } = Select;
const AddBrand = (props) => {
  const {
    brandIds,
    setbrandIds,
    topBrandIds,
    settopBrandIds,
    draftList,
    setdraftList,
  } = props;
  const brandList = [1, 2, 3, 4, 5, 6, 7];

  const [suggestbrandList, setsuggestbrandList] = useState([]);
  // const [searchKey, setsearchKey] = useState("");

  const handleCheckinTopList = (id) => {
    const result = topBrandIds.filter((data) => {
      return data === id;
    });
    if (result) {
      return true;
    } else {
      return false;
    }
  };

  const handleTopBrand = (id) => {
    if (topBrandIds.includes(id)) {
      settopBrandIds(
        topBrandIds.filter((data) => {
          return data !== id;
        })
      );

      setdraftList(
        draftList.map((item) =>
          item.id === id ? { ...item, topBrand: false } : item
        )
      );
    } else {
      if (topBrandIds.length === 12) {
        message.error(
          "A category could only consist of 12 top brands, please remove some to add more."
        );
      } else {
        settopBrandIds([...topBrandIds, id]);

        setdraftList(
          draftList.map((item) =>
            item.id === id ? { ...item, topBrand: true } : item
          )
        );
      }
    }
  };

  //functions related to add brand
  function onChange(value, option) {
    const temp = {
      id: value,
      name: option.children,
      topBrand: false,
    };

    setdraftList([...draftList, temp]);
    setbrandIds([...brandIds, value]);
  }

  function onBlur() {}

  function onFocus() {}

  function onSearch(val) {}

  const handleRemoveFromDraft = (id) => {
    setdraftList(
      draftList.filter((data) => {
        return data.id !== id;
      })
    );

    setbrandIds(
      brandIds.filter((data) => {
        return data !== id;
      })
    );

    settopBrandIds(
      topBrandIds.filter((data) => {
        return data !== id;
      })
    );
  };

  useEffect(() => {
    const payload = {
      block: false,
      // key: searchKey,
      offset: 0,
      size: 10000,
    };

    fetchBrandList(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setsuggestbrandList(response.data.documents);
        }
      }
    });
  }, []);

  //for avoiding duplications while adding new brands
  useEffect(() => {
    setsuggestbrandList(
      suggestbrandList.filter((data) => {
        return !brandIds.includes(data.id);
      })
    );
  }, [brandIds]);

  return (
    <Card className="manage_category_link_brand">
      <Row gutter={[16, 24]}>
        {draftList.length > 0 &&
          draftList.map((data) => {
            return (
              <Col className="gutter-row" span={8}>
                <div className="linked_brand_each">
                  {" "}
                  <Avatar
                    className="linked_brand_logo"
                    style={{ flex: "1" }}
                    src={
                      data?.logo
                        ? FILE_PATH + data.logo
                        : "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                    }
                  />
                  <p className="linked_brand_name"> {data.name}</p>
                  <div
                    className="linked_brand_icon"
                    onClick={() => handleTopBrand(data.id)}
                  >
                    <img
                      src={data.topBrand ? ActiveTopIcon : InactiveTopIcon}
                      alt="topbrand"
                      style={{ width: "18px" }}
                    />
                  </div>
                  <Popconfirm
                    title="Are you sure to delete this Brand?"
                    onConfirm={() => handleRemoveFromDraft(data.id)}
                    okText="Remove"
                    cancelText="Never"
                  >
                    <div className="linked_brand_icon linked_brand_delete_icon">
                        <img
                          src={DeleteIcon}
                          className="delete_icon"
                          style={{ width: "15px" }}
                          alt="brand_delete_icon"
                        />
                    </div>
                  </Popconfirm>
                </div>
              </Col>
            );
          })}
        <Select
          size="large"
          showSearch
          style={{ width: 290, marginTop: "10px", marginLeft: "10px" }}
          placeholder="+ Add Brand"
          optionFilterProp="children"
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {suggestbrandList.map((data) => {
            return <Option value={data.id}>{data.source.brandName}</Option>;
          })}
        </Select>
      </Row>
    </Card>
  );
};

export default AddBrand;
