import { Typography, message } from 'antd'
import React, { useEffect, useRef, useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { fetchCartCount } from "../../../../ReduxStore/action";
import Cookies from 'universal-cookie';
import { addToCart } from "../../../../ApiAction/SellerApiActions/sellerApi"
import FmcgVariantProduct from './FmcgVariantProduct';
import { ENV_TYPE } from '../../../../Utils/constants';
import { IoClose } from "react-icons/io5";
import { AiTwotonePropertySafety } from "react-icons/ai";
import { AppContext } from '../../../GeneralComponents/appContext';

function FmcgVarient(props) {

    const { Text } = Typography;
    const { product, setShowVarient } = props;
    const containerRef = useRef();
    const cookies = new Cookies()
    const [ inCart, setinCart ] = useState(false);
    const [ quantity ] = useState(1);
    const [ activeCustomer ] = useState(
        JSON.parse(localStorage.getItem("activeCustomer"))
    );
    const [processedPrice, setProcessedPrice] = useState();
    const [price, setPrice] = useState("");
    const [sellingPrice, setSellingPrice] = useState("");
    const [salesIncentive, setsalesIncentive] = useState("");
    const [selectedVariant, setSelectedVariant] = useState("");
    const [selectedQuantity, setSelectedQuantity] = useState("");
    const [selectedDiscount, setSelectedDiscount] = useState("");
    const [availableQuantity, setAvailableQuantity] = useState("");
    const ProviderProps = useContext(AppContext);

    useEffect(()=>{
        window.addEventListener("mousedown", checkContainer)
        return() => {
            window.removeEventListener("mousedown", checkContainer)
        }
    }, [])

    const addTocartProduct = async ( variant ) => {
        handleCheckInCart(variant);
        if (variant.stock > 0) {
            setProcessedPrice(product?.processedPriceAndStock);
            setPrice(variant?.price);
            setSellingPrice(variant?.sellingPrice);
            // setsalesIncentive(variant?.salesIncentive);
            setSelectedVariant(variant?.serialNumber);
            setAvailableQuantity(variant?.stock);
            setSelectedQuantity(
            `${variant?.quantity} ${variant?.unit}`
            );
            setSelectedDiscount(variant?.discount);

          if (activeCustomer && cookies.get("accountType") && cookies.get("activeUserPincode")) {
            if (props.cartItems) {
              if (inCart) {
                props.history.push(
                  ENV_TYPE === "production" ? `/cart` : `/customer/cart`
                );
              } else {
                handleCustomerAddToCart(variant);
              }
            } else handleCustomerAddToCart(variant);
          } else {
            if (cookies.get("activeUserPincode") || isAllIndiaProduct()) {
              handleUpdateGuestCart(variant);
            } else {
              console.log("Is all India :: ", isAllIndiaProduct())
              ProviderProps.setPincodeModalVisible(true);
              ProviderProps.setProduct(product);
              ProviderProps.setSelectedItem(variant);
              ProviderProps.setQuantity(quantity);
              message.warning("Please enter PINCODE")
            }
          }
        } else message.warning("Product out of stock");
      };

      const handleCheckInCart = async (variant) => {
        const guestCart = JSON.parse(localStorage.getItem("guestCart"));

        let isAlreadyExists;

        if (guestCart && guestCart.length > 0) {
          isAlreadyExists = await guestCart.find((item) => {
            return (
              item.productId == product.productId &&
              item.itemCurrentPrice.serialNumber === variant?.serialNumber
            );
          });
        } else if (props.cartItems) {
          isAlreadyExists = await props.cartItems.find((item) => {
            return (
              item.productId == product.productId &&
              item.serialNumber === variant?.serialNumber
            );
          });
        }

        if (isAlreadyExists) setinCart(true);
        else setinCart(false);
      };


    const handleCustomerAddToCart = (variant) => {
        const paylaod = {
          userId: cookies.get("userId"),
          accountType: cookies.get("accountType"),
          item: {
            productId: product.productId,
            vendorId: product.vendorId,
            vendorType: product.vendorType,
            quantity: Math.abs(quantity),
            itemCurrentPrice: variant,
          },
        };

        addToCart(paylaod)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                props.fetchCartCount(props.cartCountStore + 1);
                message.success(response.data.message);
              } else {
                message.error(response.data.message);
              }
            }
          })
          .catch((error) => {
            message.error("Cart Error", error.message)
          })
          .finally(() => {});
      };

    const checkContainer = (e) => {
        try {
            if (containerRef.current.contains(e.target)) {
                return
            }
            setShowVarient(false)
        }catch(error){
            console.log(error.message)
        }
    }

    const isAllIndiaProduct = () => {
        return product?.serviceLocations.includes('All India') ? true : false
    }

    const handleUpdateGuestCart = async (variant) => {
        const guestCart = JSON.parse(localStorage.getItem("guestCart"));
        console.log("Selected ITEM :: ", variant)
        const temp = {
          productId: product.productId,
          productName: product.name,
          vendorId: product.vendorId,
          vendorType: product.vendorType,
          quantity: quantity,
          image: product.images[0],
          itemCurrentPrice: variant,
          processedPriceAndStocks: product.processedPriceAndStock,
          itemId: product.productId + variant.serialNumber,
        };
        console.log(temp, guestCart, "guest cart validation");
        if (guestCart) {
          // const isAlreadyExists = await guestCart.find((item) => {
          //   return (
          //     item.productId == temp.productId &&
          //     item.itemCurrentPrice.serialNumber ===
          //       temp.itemCurrentPrice.serialNumber
          //   );
          // });

          if (inCart) {
            // message.warning("Product already added to cart");
            props.history.push(
              ENV_TYPE === "production" ? `/cart` : `/customer/cart`
            );
          } else {
            localStorage.setItem("guestCart", JSON.stringify([...guestCart, temp]));
            message.success("Product added to cart");
            //this part of code is for calculating guest cart count
            const cartCount = guestCart.reduce((prev, cur) => {
              return prev + cur.quantity;
            }, 0);
            props.fetchCartCount(cartCount + temp.quantity);
          }
        } else {
          localStorage.setItem("guestCart", JSON.stringify([temp]));
          message.success("Product added to cart");
          //this part of code is for calculating guest cart count
          props.fetchCartCount(temp.quantity);
        }
      };

    return (
        <div className="fmcg-varient-main-modal">

            <div className="fmcg-varient-modal" ref={containerRef}>
                <div className="fmcg-varient-modal-top-part" >
                    <span className="fmcg-row-flex">
                        <Text strong style={{fontSize: '1rem'}}>Choose Variant</Text>
                        <Text style={{fontSize: '0.8rem'}}>{product?.name}</Text>
                    </span>
                    <IoClose style={{ color: 'grey', position: 'absolute', right: '0.5em', top: '1.5em', fontSize: '1rem', cursor: 'pointer'}} onClick={()=> setShowVarient(false)} />
                </div>
                <div className="fmcg-varient-modal-middle-part">
                    {product?.processedPriceAndStock?.map((variant,index)=> {
                        if( variant?.stock > 0) {
                            return (
                                <FmcgVariantProduct addTocartProduct={addTocartProduct} key={index} product={product} variant={variant} />
                            )
                        }
                    })}
                </div>
                <div className="fmcg-varient-modal-bottom-part">
                  <p style={{ fontSize: '0.6rem', width: '100%', textAlign: 'center', color: 'rgba(0,0,0,0.3)'}}><AiTwotonePropertySafety /> Safe and Secure Payments.Easy returns.100% Authentic products.</p>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = { fetchCartCount };
const mapStateToProps = (state) => {
  return {
    cartCountStore: state.cartCountReducer,
    pinCode: state.productReducer.pinCode,
    cartItems: state.cartItemsReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FmcgVarient)
);
//export default FmcgVarient