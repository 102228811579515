

import React, { useState, useEffect } from "react";
import { Layout, Dropdown, Button, Menu, Typography } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";

const { Text } = Typography;
const FilterBy = (props) => {
  const { options, setfilterBy } = props;
  const [sortValue, setSortValue] = useState("");
  const [label, setlabel] = useState("");

  const handleSetLabel = (key) => {
    switch (key) {
      case "seller":
        setlabel("Products (Sellers)");
        break;
      case "linkedWarehouse":
        setlabel("Products (Linked Warehouse");
        break;
      default:
        console.log("invalid choice");
    }
  };

  useEffect(() => {
    {
      if (options?.length > 0) {
        setSortValue(options[0].key);
        setfilterBy(options[0].key);
        handleSetLabel(options[0].key);
      }
    }
  }, [options]);

  useEffect(() => {}, []);

  const handleSetFilter = (data) => {
    setSortValue(data.key);
    setfilterBy(data.key);
    handleSetLabel(data.key);
  };
  const renderSortStatus = () => {
    return (
      <Menu onClick={handleSetFilter}>
        {options?.map((option) => {
          return option.key !== "seller" ? (
            <Menu.Item key={option.key}>
              {option.value}
              {sortValue === option.key && (
                <CheckCircleTwoTone
                  twoToneColor="#0074be"
                  style={{ fontSize: "16px", float: "right" }}
                />
              )}
            </Menu.Item>
          ) : (
            <Menu.Item key={option.key} disabled={true}>
              {option.value}
              {sortValue === option.key && (
                <CheckCircleTwoTone
                  twoToneColor="#0074be"
                  style={{ fontSize: "16px", float: "right" }}
                />
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };
  return (
    <Dropdown
      overlay={() => renderSortStatus()}
      placement="bottomCenter"
      trigger={["click"]}
      className="sort-dropdown-admin"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Button style={{ height: "40px" }}>
        <span style={{ color: "#827e7e" }}>Filter By: </span>
        <span>
          {" "}
          <Text strong style={{ color: "red" }}>
            &nbsp;
            {label}
          </Text>
        </span>
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};

export default FilterBy;
