

import axios from "axios";
import Axios from "../axios";
import { API_SERV_ADDR, API_SERV_ADDR_2 } from "../../Utils/constants";

export const getMegamenu = () => {
  return Axios({
    url: `${API_SERV_ADDR_2}/multilevel/category`,
    method: "POST",
  });
};

export const fetchHomePageData = (data) => {
  return Axios({
    url: `${API_SERV_ADDR_2}/home`,
    method: "POST",
    data: data,
  });
};

let cancelToken;
export const fetchSearchSuggestions=(data)=>{
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Cancelled");
  }
  cancelToken = axios.CancelToken.source(); 
  return axios({
    url:`${API_SERV_ADDR_2}/product/suggestion`,
    method:"POST",
    data:data,
    cancelToken: cancelToken.token,
  })
}
