


import React, { useEffect, useState } from "react";
import axios from "axios";

import {
  Modal,
  Form,
  Input,
  Typography,
  Button,
  Radio,
  Spin,
  notification,
  Row,
  Col,
} from "antd";
import PhoneInput from "react-phone-input-2";
import { addNewCustomer } from "../../../ApiAction/SellerApiActions/sellerApi";
import LoadingGif from "../../../Assets/Images/Icons/loading.gif";

const { Text } = Typography;
const AddCustomerModal = (props) => {
  const { isModalVisible, setisModalVisible } = props;

  const [activeFormFields, setactiveFormFields] = useState();
  const [activeForm, setactiveForm] = useState("new");
  const [isLoadingCreateCustomer, setisLoadingCreateCustomer] = useState(false);
  const [form] = Form.useForm();

  const LoaderGif = <img src={LoadingGif} />;
  const options = [
    { label: "New user", value: "new" },
    { label: "MLM User", value: "mlm" },
  ];
  const PhoneValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 12) {
      callback("Enter valid phone number");
    } else {
      callback();
    }
  };
  const formFields = [
    {
      label: "Customer Name",
      name: "name",
      rules: [
        {
          pattern: new RegExp("^[A-Za-z ]+$"),
          message: "Enter a valid Name",
        },
        { required: true, message: "This field is required !" },
      ],
    },
    {
      label: " Email",
      name: "email",
      rules: [
        {
          type: "email",
        },
        { required: true, message: "Enter a valid email Id" },
      ],
    },
    {
      label: "Phone Number",
      name: "mobile",
      rules: [
        {
          validator: PhoneValidator,
        },
        {
          required: true,
          message: "This field is required !",
        },
      ],
    },
    {
      label: "Pincode",
      name: "pinCode",
      rules: [
        {
          pattern: new RegExp("^[1-9][0-9]{5}$"),
          message: "Enter a valid pincode",
        },
        { required: true, message: "This field is required !" },
      ],
    },
    {
      label: "Referal Id",
      name: "affiliateId",
    },
  ];
  const mlmUserFormFields = [
    {
      label: "Customer Name",
      name: "name",
      rules: [
        {
          pattern: new RegExp("^[A-Za-z ]+$"),
          message: "Enter a valid Name",
        },
        { required: true, message: "This field is required !" },
      ],
    },
    {
      label: " Email",
      name: "email",
      rules: [{ required: true, message: "This field is required !" }],
    },
    {
      label: "Phone Number",
      name: "mobile",
      rules: [
        { validator: PhoneValidator },
        {
          required: true,
          message: "This field is required !",
        },
      ],
    },
    {
      label: "Pincode",
      name: "pinCode",
      rules: [
        {
          pattern: new RegExp("^[1-9][0-9]{5}$"),
          message: "Enter a valid pincode",
        },
        { required: true, message: "This field is required !" },
      ],
    },
    {
      label: "Customer ID",
      name: "referralId",
      rules: [{ required: true, message: "This field is required !" }],
    },
  ];

  const handleOk = () => {
    setisModalVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setisModalVisible(false);
    form.resetFields();
  };

  const handleOnChangeUserType = (e) => {
    setactiveForm(e.target.value);
    switch (e.target.value) {
      case "new":
        setactiveFormFields([...formFields]);

        break;

      case "mlm":
        setactiveFormFields([...mlmUserFormFields]);
        break;

      default:
        setactiveFormFields([...formFields]);
    }
  };

  const handleCreateNewCustomer = (values) => {

    console.log(values.affiliateId);

    console.log("values for add new customer:", values);
    console.log("referralId", values.referralId);
    setisLoadingCreateCustomer(true);
    addNewCustomer(values)
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.status === "success") {
            console.log(response, ": reponse for add new customer");
            openNotificationWithIcon(
              "success",
              "Success",
              response.data.message
            );
            setisModalVisible(false);
            form.resetFields();
          } else {
            openNotificationWithIcon("error", "Failed", response.data.message);
            console.log(response, ": reponse for add new customer");
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setisLoadingCreateCustomer(false);
      });

  };

  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };

  useEffect(() => {
    setactiveFormFields([...formFields]);
  }, []);
  return (
    <Modal
      title="Add Customer"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      className="add_customer_modal"
      footer={null}
      width={800}
    >
      <Spin spinning={isLoadingCreateCustomer}>
        <Form form={form} layout="vertical" onFinish={handleCreateNewCustomer}>
          <Form.Item>
            <Radio.Group
              options={options}
              onChange={handleOnChangeUserType}
              value={activeForm}
              // optionType="button"
            />
          </Form.Item>
          <Row>
            {activeFormFields?.map((data) => {
              return (
                <Col span={12} className="add-image-col">
                  <Form.Item
                    name={data.name}
                    key={data.name}
                    label={
                      <Text strong style={{ color: "grey" }}>
                        {data.label}
                      </Text>
                    }
                    rules={data.rules}
                  >
                    {data.name === "mobile" ? (
                      <PhoneInput
                        country={"in"}
                        className="phone_number_inpt"
                        countryCodeEditable={false}
                        onChange={(phone) => console.log(phone)}
                      />
                    ) : data.name === "pinCode" ? (
                      <Input size="large" maxlength="6" />
                    ) : (
                      <Input size="large" />
                    )}
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
          <div className="add_customer_modal_action">
            <Button
              size="large"
              className="add_customer_cancel_btn"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="btn_theme"
              >
                Add Customer
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default AddCustomerModal;
