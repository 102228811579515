


import React, { useState ,useEffect} from "react";
import { Layout, Dropdown, Button, Menu, Typography } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { fetchSort ,fetchSortBy} from "../../../ReduxStore/action"
import { connect } from "react-redux";
const { Text } = Typography;
const FilterbyDomin= (props) => {
  const [sortValue, setSortValue] = useState("All");
  
  useEffect(() => {


    

    
  }, [])
  const renderSortStatus = () => {
    return (
      <Menu>
      
        <Menu.Item
          onClick={() => {
            setSortValue("Seller");
          
            const payload={
              sortType:props.sort.sortType,
              sortBy:props.sort.sortBy,
              block: true,
              archive:false,
              all:false,
              status:props.sort.status,
              filterType:"SELLER",
            }
            props.fetchSort(payload)
            // this.handleSelectChange("open", "projectStatus");
            // this.props.searchFilterStore.projectStatusFilterChange("open");
          }}
        >
          Seller
          {sortValue === "Seller" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>


        <Menu.Item
          onClick={() => {
            setSortValue("Store");
            const payload={
                sortType:props.sort.sortType,
                sortBy:props.sort.sortBy,
                block: false,
                archive:false,
                all:false,
                status:props.sort.status,
                filterType:"STORE",
              }
            props.fetchSort(payload)
          }}
        >
          Store
          {sortValue === "Store"  && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
  
        <Menu.Item
          onClick={() => {
            setSortValue("Warehouse");
            
            const payload={
                sortType:props.sort.sortType,
                sortBy:props.sort.sortBy,
                block: true,
                archive:true,
                all:false,
                status:props.sort.status,
              filterType:"WAREHOUSE",
              }
            props.fetchSort(payload)
            // this.handleSelectChange("open", "projectStatus");
            // this.props.searchFilterStore.projectStatusFilterChange("open");
          }}
        >
          Warehouse
          {sortValue === "Warehouse" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
        
        <Menu.Item
          onClick={() => {
            setSortValue("All");
            const payload={
                sortType:props.sort.sortType,
                sortBy:props.sort.sortBy,
                block: false,
                archive:false,
                all:true,
                status:props.sort.status,
              filterType:"ALL",
              }
            props.fetchSort(payload)
          }}
        >
         All
          {sortValue === "All"  && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px",float: "right" }}
            />
          )}
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <Dropdown
      overlay={() => renderSortStatus()}
      placement="bottomCenter"
      trigger={["click"]}
      className="sort-dropdown-admin"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Button style={{height:"40px"}}>
        <span style={{ color: "#827e7e"}}>Seller/Store/Warehouse: </span>
        <span>
          {" "}
          
          <Text strong style={{ color: "red" }}>
          &nbsp;
            {sortValue}
          </Text>
        </span>
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
}
const mapDispatchToProps = {
  fetchSort,fetchSortBy
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterbyDomin);
