


import {
  FETCH_ORDER_RESPONSE,
  REFETCH_ORDER_LIST,
  FETCH_ORDER_FAILURE,
} from "../action/types";

export default function customerOrderReducer(
  state = {
    orderResponse: {},
    refetchOrderList: false,
    orderFailure: {},
  },
  action
) {
  switch (action.type) {
    case FETCH_ORDER_RESPONSE:
      state = { ...state, orderResponse: action.data };
      break;
    case REFETCH_ORDER_LIST:
      state = { ...state, refetchOrderList: !state.refetchOrderList };
      break;
    case FETCH_ORDER_FAILURE:
      state = { ...state, orderFailure: action.data };
      break;

    default:
      // console.log("invalid choice");
  }

  return state;
}
