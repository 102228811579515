

import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Row,
  Col,
  Select,
  Input,
  Checkbox,
  Upload,
  message,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import {
  addAdminSectionBannerImage,
  addAdminSectionPopularImage,
  fetchUsers,
  getCategory,
  getCategoryBrand,
  getSubCategory,
} from "../../../ApiAction/AdminApiAction/adminApi";
import Loading from "../../GeneralComponents/Loading";
import DeleteIcon from "../../../Assets/Images/Icons/Seller/delete.svg";
import Carousel from "react-multi-carousel";
import { responsive } from "../../../Utils/Seller/Data";
import "./manageSection.scss";
import { getProducts } from "../../../ApiAction/SellerApiActions/sellerApi";
import { getLogAttributes } from "../Logger/notificationBar/LogHelper";
const { Option } = Select;
const { Dragger } = Upload;
const { Title } = Typography;
const AddNewSections = (props) => {
  const [uploading, setUploading] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryListLevel0, setCategoryListLevel0] = useState([]);
  const [categoryListLevel1, setCategoryListLevel1] = useState([]);
  const [categoryListLevel2, setCategoryListLevel2] = useState([]);
  const [categoryListLevel3, setCategoryListLevel3] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [type, setType] = useState("");
  const [bannerCategory, setBannerCategory] = useState("");
  const [bannerList, setBannerList] = useState([
    "STORE",
    "PRODUCT",
    "CATEGORY",
  ]);

  const [typeList, setTypeList] = useState([
    "STORE",
    "BANNER",
    "CATEGORY",
    "POPULAR CATEGORY",
    "BIG OFFER"
  ]);
  const [brandList, setBrandList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [selectedBrandList, setSelectedBrandList] = useState([]);
  const [selectedBrandNameList, setSelectedBrandNameList] = useState([]);
  const [categoryName, setCategoryName] = useState();
  const [subCategory0Name, setSubCategory0Name] = useState();
  const [subCategory1Name, setSubCategory1Name] = useState();
  const [subCategory2Name, setSubCategory2Name] = useState();
  const [subCategory3Name, setSubCategory3Name] = useState();
  const [categoryId, setCategoryId] = useState();
  const [subCategory0Id, setSubCategory0Id] = useState();
  const [subCategory1Id, setSubCategory1Id] = useState();
  const [subCategory2Id, setSubCategory2Id] = useState();
  const [subCategory3Id, setSubCategory3Id] = useState();
  const [brandId, setBrandId] = useState("");
  const [value, setValue] = useState();
  const [storeId, setStoreId] = useState();
  const [storeName, setStoreName] = useState("");
  const [newImageFile, setNewImageFile] = useState();
  const [newImgSrc, setNewImgSrc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [productValue, setProductValue] = useState();
  const [productId, setProductId] = useState();
  const [productName, setProductName] = useState("");
  const [productList, setProductList] = useState([]);
  useEffect(() => {
    const payload = {
      key: "",
      offset: 0,
      size: 20,
      level: -1,
    };
    getCategory(payload)
      .then((response) => {
        setCategoryList(response.data.category);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (props.isUpdate) setType(props.type);
    if (props.isClear) {
      onCloseModal();
    }
    if(props.type == "BIG OFFER")
      setBannerCategory("PRODUCT");
  }, [props]);

  const onCloseModal = () => {
    setBannerCategory("");
    setStoreName("");
    setCategoryName("");
    setSubCategory0Name("");
    setSubCategory1Name("");
    setSubCategory2Name("");
    setSubCategory3Name("");
    setProductName("");
  };
  const getSelectedList = (value, info) => {
    setSelectedBrandList(info.map((item) => item.key));
    setSelectedBrandNameList(value);
  };

  const convertToCapitalize = (str) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(" ");
  };

  const fetchProducts = (search = "") => {
    const term = [];

    term.push({
      vendorId: [storeId],
    });
    term.push({
      status: ["APPROVED"],
    });
    const payload = {
      offset: 0,
      size: 10,

      key: search,
      sortBy: "lastModifiedDate",
      sortType: "asc",
      filter: {
        term: term,
      },
    };
    getProducts(payload)
      .then((response) => {
        setProductList(response.data.products);
      })
      .catch(() => {});
  };

  const fetchStores = (search = "") => {
    const term = [];

    term.push({
      accountType: ["store", "seller"],
    });

    const payload = {
      key: search,
      sort: "date",
      sortBy: "asc",
      accountType: "store",
      offset: 0,
      size: 20,
      filter: {
        term: term,
      },
    };
    fetchUsers(payload)
      .then((response) => {
        setStoreList(response.data.documents);
      })
      .catch(() => {});
  };

  const getSubCategoryList = (id, level) => {
    getSubCategory(id, level)
      .then((response) => {
        if (level === 0) {
          setCategoryListLevel0(response.data.category);
        }
        if (level === 1) {
          setCategoryListLevel1(response.data.category);
        }
        if (level === 2) setCategoryListLevel2(response.data.category);
        if (level === 3) setCategoryListLevel3(response.data.category);

        const feturedObject =
          level === 0
            ? categoryListLevel0.find((item) => item.id === id)
            : categoryListLevel1.find((item) => item.id === id);
        const features = feturedObject.source.categoryFeature.map((item) => {
          const subFeature = item.subFeature.map((item) => {
            const singleItem = {
              key: item.feature,
              value: "",
            };
            return singleItem;
          });
          return subFeature;
        });
        const result = features.reduce((r, e) => (r.push(...e), r), []);
      })
      .catch(() => {});
    getBrand(id);
  };

  const getBrand = (id) => {
    getCategoryBrand(id)
      .then((response) => {
        if (response.data.status === "success")
          setBrandList(response.data.brand);
      })
      .catch(() => {});
  };

  const addSections = () => {
    const term = [];

    let first = categoryName ? categoryName : "";
    let level0 = subCategory0Name ? "/" + subCategory0Name : "";
    let level1 = subCategory1Name ? "/" + subCategory1Name : "";
    let level2 = subCategory2Name ? "/" + subCategory2Name : "";
    let level3 = subCategory3Name ? "/" + subCategory3Name : "";
    const typeName =
      type === "STORE" ? storeName : first + level0 + level1 + level2 + level3;

    if (categoryId)
      term.push({
        categoryId: [categoryId],
      });
    if (subCategory0Id)
      term.push({
        subCategory0Id: [subCategory0Id],
      });
    if (subCategory1Id)
      term.push({
        subCategory1Id: [subCategory1Id],
      });
    if (subCategory2Id)
      term.push({
        subCategory2Id: [subCategory2Id],
      });
    if (subCategory3Id)
      term.push({
        subCategory3Id: [subCategory3Id],
      });
    if (type === "STORE" || type === "CATEGORY") {
      if (selectAll)
        term.push({
          brandId: [],
        });
      else
        term.push({
          brandId: selectedBrandList,
        });
    }

    if (type === "STORE")
      term.push({
        vendorId: [storeId],
      });
    if (type === "BANNER" && bannerCategory == "STORE")
      term.push({
        vendorId: [storeId],
      });

    if (type === "BANNER" && bannerCategory == "PRODUCT")
      term.push({
        productId: [productId],
      });
    
    if (type === "BIG OFFER" && bannerCategory == "PRODUCT")
      term.push({
        productId: [productId],
      });

    const payload = {
      sectionTitle: sectionName,
      sectionType: type,
      sectionTypeName: typeName,
      sectionOrder: props.sectionOrder,
      conditions: {
        filter: {
          term: term,
        },
      },
    };

    if (type === "STORE" || type === "CATEGORY") props.addSection(payload);

    if (type === "BANNER") {
      setIsLoading(true);

      addAdminSectionBannerImage(newImageFile)
        .then((response) => {
          if (response.data.status === "success") {
            setIsLoading(false);
            if (props.isUpdate) {
              let updateBannerPayload = {
                sectionId: props.sectionId,
                sectionType: "BANNER",

                banner: {
                  bannerType: "LARGE",
                  bannerCategory: bannerCategory,
                  bannerCategoryName:
                    bannerCategory == "STORE"
                      ? storeName
                      : bannerCategory == "PRODUCT"
                      ? productName
                      : typeName,

                  image: response.data.image,
                  bannerConditions: {
                    filter: {
                      term: term,
                    },
                  },
                },
              };
              updateBannerPayload = getLogAttributes(updateBannerPayload, "Manage Banner")
              props.setCancel(false);
              props.updateSection(updateBannerPayload);
            } else {
              const bannerPayload = {
                sectionTitle: sectionName,
                sectionType: type,

                sectionOrder: props.sectionOrder,
                banner: {
                  bannerType: "LARGE",
                  bannerCategory: bannerCategory,

                  bannerCategoryName:
                    bannerCategory == "STORE"
                      ? storeName
                      : bannerCategory == "PRODUCT"
                      ? productName
                      : typeName,
                  image: response.data.image,
                  bannerConditions: {
                    filter: {
                      term: term,
                    },
                  },
                },
              };
              props.addSection(bannerPayload);
            }
          } else {
            response?.data?.message && message.error(response.data.message);
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (type === "POPULAR CATEGORY") {
      setIsLoading(true);
      addAdminSectionPopularImage(newImageFile)
        .then((response) => {
          if (response.data.status === "success") {
            setIsLoading(false);
            if (props.isUpdate) {
              const popularUpdate = {
                sectionId: props.sectionId,
                sectionType: "POPULAR_CATEGORY",

                category: {
                  level: subCategory3Name
                    ? 3
                    : subCategory2Name
                    ? 2
                    : subCategory1Name
                    ? 1
                    : subCategory0Name
                    ? 0
                    : -1,
                  categoryId: categoryId,
                  subCategory0Id: subCategory0Id,
                  subCategory1Id: subCategory1Id,

                  subCategory2Id: subCategory2Id,

                  subCategory3Id: subCategory3Id,

                  popularCategoryName: typeName,
                  categoryName: categoryName,

                  subCategory0Name: subCategory0Name,
                  subCategory1Name: subCategory1Name,

                  subCategory2Name: subCategory2Name,

                  subCategory3Name: subCategory3Name,

                  image: response.data.image,
                },
              };
              props.setCancel(false);
              props.updateSection(popularUpdate);
            } else {
              const popularPayload = {
                sectionTitle: sectionName,
                sectionType: "POPULAR_CATEGORY",

                sectionOrder: props.sectionOrder,
                category: {
                  level: subCategory3Name
                    ? 3
                    : subCategory2Name
                    ? 2
                    : subCategory1Name
                    ? 1
                    : subCategory0Name
                    ? 0
                    : -1,

                  categoryId: categoryId,
                  subCategory0Id: subCategory0Id,
                  subCategory1Id: subCategory1Id,

                  subCategory2Id: subCategory2Id,

                  subCategory3Id: subCategory3Id,

                  categoryName: categoryName,

                  subCategory0Name: subCategory0Name,
                  subCategory1Name: subCategory1Name,

                  subCategory2Name: subCategory2Name,

                  subCategory3Name: subCategory3Name,
                  popularCategoryName: typeName,

                  image: response.data.image,
                },
              };

              props.addSection(popularPayload);
            }
          } else {
            response?.data?.message && message.error(response.data.message);
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
    if (type === "BIG OFFER") {
      setIsLoading(true);
  
      addAdminSectionBannerImage(newImageFile)
        .then((response) => {
          if (response.data.status === "success") {
            setIsLoading(false);
            if (props.isUpdate) {
              let updateBannerPayload = {
                sectionId: props.sectionId,
                sectionType: "BIG_OFFER",
  
                banner: {
                  bannerType: "LARGE",
                  bannerCategory: bannerCategory,
                  bannerCategoryName:
                    bannerCategory == "STORE"
                      ? storeName
                      : bannerCategory == "PRODUCT"
                      ? productName
                      : typeName,
  
                  image: response.data.image,
                  bannerConditions: {
                    filter: {
                      term: term,
                    },
                  },
                },
              };
              updateBannerPayload = getLogAttributes(updateBannerPayload, "Manage Big Offer")
              props.setCancel(false);
              props.updateSection(updateBannerPayload);
            } else {
              const bannerPayload = {
                sectionTitle: sectionName,
                sectionType: "BIG_OFFER",
  
                sectionOrder: props.sectionOrder,
                banner: {
                  bannerType: "LARGE",
                  bannerCategory: bannerCategory,
  
                  bannerCategoryName:
                    bannerCategory == "STORE"
                      ? storeName
                      : bannerCategory == "PRODUCT"
                      ? productName
                      : typeName,
                  image: response.data.image,
                  bannerConditions: {
                    filter: {
                      term: term,
                    },
                  },
                },
              };
              props.addSection(bannerPayload);
            }
          } else {
            response?.data?.message && message.error(response.data.message);
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    }

    setType("");
    setSectionName("");
    setCategoryName();
    setSubCategory0Name();
    setSubCategory1Name();
    setSubCategory2Name();
    setSubCategory3Name();
    setNewImgSrc();
  };
  const SearchOnChange = (value) => {
    setValue(value);
    setStoreList([]);
  };
  const SearchOnChangeProduct = (value) => {
    setProductValue(value);
    setProductList([]);
  };

  const onImageChange = {
    name: "file",
    multiple: false,
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload(info) {
      const isLt2M = info.size / 1024 / 1024 < 4;
      if (!isLt2M) {
        message.error("File size should be less than 4 MB");
        setUploading(false);
        return false;
      } else if (
        !(
          info.type === "image/png" ||
          info.type === "image/jpeg" ||
          info.type === "image/webp"
        )
      ) {
        message.error("You can only upload JPEG/JPG/PNG images");
        setUploading(false);
        return false;
      }
    },
    onRemove(info) {
      setNewImgSrc();
      setNewImageFile();
    },
    onChange(info) {
      if (info.file.status === "done") {
        setUploading(false);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setNewImgSrc(reader.result);
          setNewImageFile(info.file.originFileObj);
          //   setNewImgUrl([
          //     ...newImgUrl,
          //     { url: reader.result, file: info.file.originFileObj, master: false },
          //   ]);
        });
        reader.readAsDataURL(info.file.originFileObj);
        return;
      }
    },
  };

  return (
    <div>
      {isLoading && <Loading />}
      <div className="section-layout category_drag_layout">
        {!props.isUpdate && (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="add-image-col"
            >
              <div className="add-product-tile">
                Section Name
                <Input
                  placeholder="Section Name"
                  //   className="tracking-input"
                  value={sectionName}
                  onChange={(e) => {
                    setSectionName(convertToCapitalize(e.target.value));
                    console.log(e.target.value);
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="add-product-tile">
                Section Type
                <Select
                  onSelect={(value, option) => {
                    setType(option.value);
                    option.value == "BIG OFFER"? setBannerCategory("PRODUCT")
                    : setBannerCategory("");
                  }}
                  value={type}
                  style={{ width: "100%" }}
                >
                  {typeList &&
                    typeList.map((item, index) => {
                      return (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </Col>
          </Row>
        )}

        {type === "BANNER" && (
          <div className="add-product-tile">
            Banner Type
            <Select
              onSelect={(value, option) => {
                setBannerCategory(option.value);
              }}
              value={bannerCategory}
              style={{ width: "100%" }}
            >
              {bannerList &&
                bannerList.map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  );
                })}
            </Select>
          </div>
        )}

        {type === "BIG OFFER" && (
          <div className="add-product-tile">
            Banner Type
            <Select
              value={"PRODUCT"}
              style={{ width: "100%" }}
              disabled={true}
            >
            </Select>
          </div>
        )}

        {(type == "STORE" || bannerCategory == "STORE") && (
          <div className="add-product-tile">
            Store
            <Select
              // mode="multiple"

              placeholder="select store"
              style={{ width: "100%", marginBottom: "20px" }}
              className="brand-multi-select"
              showSearch
              value={value}
              onSearch={(value) => fetchStores(value)}
              onChange={(value, info) => SearchOnChange(value)}
              optionLabelProp="text"
              labelInValue
              // notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSelect={(value, Option) =>
                // associateProduct(Option.key, bannerIndex)
                {
                  setStoreName(Option.text);
                  setStoreId(Option.key);
                }
              }
            >
              {storeList.map((data) => {
                return (
                  <Option text={data.storeName} key={data.id}>
                    <div className="demo-option-label-item">
                      {data.storeName}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </div>
        )}
        {(type == "BANNER" || type == "BIG OFFER") && bannerCategory == "PRODUCT" && (
          <div className="add-product-tile">
            Vendor
            <Select
              // mode="multiple"
              placeholder="select store or seller"
              style={{ width: "100%", marginBottom: "20px" }}
              className="brand-multi-select"
              showSearch
              value={value}
              onSearch={(value) => fetchStores(value)}
              onChange={(value, info) => SearchOnChange(value)}
              optionLabelProp="text"
              labelInValue
              // notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSelect={(value, Option) =>
                // associateProduct(Option.key, bannerIndex)
                {
                  setStoreName(Option.text);
                  setStoreId(Option.key);
                  setProductValue("")
                }
              }
            >
              {storeList.map((data) => {
                return (
                  <Option text={data.storeName} key={data.id}>
                    <div className="demo-option-label-item">
                      {data.storeName}
                    </div>
                  </Option>
                );
              })}
            </Select>
            Product
            <Select
              // mode="multiple"
              disabled={storeId? false: true}
              placeholder="select product"
              style={{ width: "100%", marginBottom: "20px" }}
              className="brand-multi-select"
              showSearch
              value={productValue}
              onSearch={(value) => fetchProducts(value)}
              onChange={(value, info) => SearchOnChangeProduct(value)}
              optionLabelProp="text"
              labelInValue
              // notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSelect={(value, Option) =>
                // associateProduct(Option.key, bannerIndex)
                {
                  setProductName(Option.text);
                  setProductId(Option.key);
                }
              }
            >
              {productList.map((data) => {
                return (
                  <Option text={data.source.name} key={data.source.productId}>
                    <div className="demo-option-label-item">
                      {data.source.name}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </div>
        )}
        {type != "STORE" && type != "BIG OFFER" &&
          bannerCategory != "STORE" &&
          bannerCategory != "PRODUCT" && (
            <Row>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="add-image-col"
              >
                <div className="add-product-tile">
                  Category
                  <Select
                    onSelect={(value, option, info) => {
                      setCategoryName(option.value);
                      setCategoryId(option.key);
                      getSubCategoryList(option.key, 0);
                    }}
                    value={categoryName}
                    style={{ width: "100%" }}
                  >
                    {categoryList &&
                      categoryList.map((item, index) => {
                        return (
                          <Option
                            key={item.source.id}
                            value={item.source.categoryName}
                            defaultValue={props.categoryId}
                          >
                            {item.source.categoryName}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="add-product-tile">
                  SubCategory
                  <Select
                    onSelect={(value, option) => {
                      setSubCategory0Name(option.value);
                      setSubCategory0Id(option.key);
                      getSubCategoryList(option.key, 1);
                    }}
                    value={subCategory0Name}
                    style={{ width: "100%" }}
                  >
                    {categoryListLevel0 &&
                      categoryListLevel0.map((item, index) => {
                        return (
                          <Option
                            key={item.source.id}
                            value={item.source.categoryName}
                          >
                            {item.source.categoryName}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </Col>
            </Row>
          )}
        {categoryListLevel1.length > 0 && (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="add-image-col"
            >
              <div className="add-product-tile">
                SubCategory
                <Select
                  onSelect={(value, option) => {
                    setSubCategory1Name(option.value);
                    setSubCategory1Id(option.key);
                    getSubCategoryList(option.key, 2);
                  }}
                  value={subCategory1Name}
                  style={{ width: "100%" }}
                >
                  {categoryListLevel1 &&
                    categoryListLevel1.map((item, index) => {
                      return (
                        <Option
                          key={item.source.id}
                          value={item.source.categoryName}
                        >
                          {item.source.categoryName}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </Col>
            {categoryListLevel2.length > 0 && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="add-image-col"
              >
                <div className="add-product-tile">
                  SubCategory
                  <Select
                    onSelect={(value, option) => {
                      setSubCategory2Name(option.value);
                      setSubCategory2Id(option.key);
                      getSubCategoryList(option.key, 3);
                    }}
                    value={subCategory2Name}
                    style={{ width: "100%" }}
                  >
                    {categoryListLevel2 &&
                      categoryListLevel2.map((item, index) => {
                        return (
                          <Option
                            key={item.source.id}
                            value={item.source.categoryName}
                          >
                            {item.source.categoryName}
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </Col>
            )}
          </Row>
        )}

        {categoryListLevel3.length > 0 && (
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className="add-product-tile">
                SubCategory
                <Select
                  onSelect={(value, option) => {
                    setSubCategory3Name(option.value);
                    setSubCategory3Id(option.key);
                    getBrand(option.key);
                  }}
                  value={subCategory3Name}
                  style={{ width: "100%" }}
                >
                  {categoryListLevel3 &&
                    categoryListLevel3.map((item, index) => {
                      return (
                        <Option
                          key={item.source.id}
                          value={item.source.categoryName}
                        >
                          {item.source.categoryName}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </Col>
          </Row>
        )}

        {(type == "BANNER" || type == "BIG OFFER" || type == "POPULAR CATEGORY" || props.isUpdate) && (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="add-image-col"
            >
              <Dragger {...onImageChange} className="dragger-add-product">
                {uploading ? (
                  <Loading />
                ) : (
                  <div>
                    <h5>Drag and drop file here</h5>
                    <Button
                      type="primary"
                      // onClick={() => setUploading(true)}
                    >
                      Browse
                    </Button>
                  </div>
                )}
              </Dragger>
            </Col>
            {newImgSrc && (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="add-image-col"
              >
                <img
                  src={newImgSrc}
                  style={{ width: "75%" }}
                  alt=""
                  className="image-add-product"
                />
              </Col>
            )}
          </Row>
        )}
        {!props.isUpdate && (type == "CATEGORY" || type == "STORE") && (
          <Row>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="add-image-col product-comes-under"
            >
              <Checkbox
                onChange={(e) => setSelectAll(e.target.checked)}
                checked={selectAll}
              >
                Select all brands under this category
              </Checkbox>
            </Col>
          </Row>
        )}
        {!props.isUpdate && (type == "CATEGORY" || type == "STORE") && (
          <div className="add-product-tile">
            Brands
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="select brands"
              value={selectedBrandNameList}
              className="brand-multi-select"
              onChange={getSelectedList}
              optionLabelProp="label"
            >
              {brandList.map((data) => {
                return (
                  <Option
                    key={data.source.brandId}
                    value={data.source.brandName}
                  >
                    <div className="demo-option-label-item">
                      {data.source.brandName}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </div>
        )}
      </div>
      <Button
        type="primary"
        onClick={() => addSections()}
        style={{ marginTop: "15px", fontWeight: "500" }}
        className="login-form-button new_section_btn"
      >
        {props.isUpdate
          ? props.type == "BANNER" || props.type == "BIG OFFER"
            ? "Add New Banner"
            : "Add New Popular Category"
          : "Add New Section"}
      </Button>
    </div>
  );
};

export default AddNewSections;
