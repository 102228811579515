

import React, { useEffect } from "react";

import { withRouter } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./OneLineCardstSection.scss";
import CategoryCard from "../../../components/CategoryCard";
import ProductCard from "../../../components/ProductCard";
import MobileProductCard from "../../../components/MobileProductCard";

import WorkFromHome from "../../../Assets/Images/Test/work.jpg";
import Kitchen from "../../../Assets/Images/Test/kitchen.jpg";
import Ayurveda from "../../../Assets/Images/Test/Home.jpg";
import Home from "../../../Assets/Images/Test/Essentials.jpg";

import { FILE_PATH, ENV_TYPE } from "../../../Utils/constants";

const OneLineCardstSection = (props) => {
  const { type, title, productData, viewall, categories, sectionId, loading, maxDiscount } = props;

  const handleManageBreadCrumb = () => {
    let breadCrumbList = [];
    let breadcrumb = {
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${title}/${sectionId}`
          : `/customer/product-listing/${title}/${sectionId}`,
      type: "section",
    };
    breadcrumb["label"] = title;
    breadcrumb["id"] = sectionId;

    breadCrumbList.push(breadcrumb);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };
  const handleRedirection = () => {
    // props.history.push("/customer/product-listing");ss

    if (type === "product") {
      handleManageBreadCrumb();

      let pathname = ENV_TYPE === "production"
      ? `/product-listing/${title}/${sectionId}`
      : `/customer/product-listing/${title}/${sectionId}`;

      let FMCGCategory = JSON.parse(localStorage.getItem("megamenu")).find(o => o.categoryNameAnalyzed === 'FMCG')
      .subCategories.find(FMCG => title === FMCG.categoryNameAnalyzed)

      FMCGCategory?
      props.history.push({pathname: pathname, state: {categoryId: FMCGCategory.id}}) : props.history.push(pathname);
    }
  };
  const renderProductCard = (data, index) => {
    if (window.innerWidth <= 760) {
      return <ProductCard key={index} product={data} loading={loading} />;
    } else return <ProductCard key={index} product={data} loading={loading} />;
  };

  const Catogeryresponsive = {
    laptop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 4,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },

    laptop15Inch: {
      breakpoint: { max: 1800, min: 1501 },
      items: 4,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1801 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1300, min: 992 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    mobileX: {
      breakpoint: { max: 767, min: 641 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 640, min: 360 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    mobileS: {
      breakpoint: { max: 359, min: 300 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 100,
    },
  };

  const productresponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1801 },
      items: 7,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },

    laptop15Inch: {
      breakpoint: { max: 1800, min: 1531 },
      items: 7,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
    laptop: {
      breakpoint: { max: 1530, min: 1441 },
      items: 6,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
    laptopsmall: {
      breakpoint: { max: 1440, min: 1360 },
      items: 6,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
    Xtablet: {
      breakpoint: { max: 1359, min: 1200 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1199, min: 992 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    tabletV: {
      breakpoint: { max: 991, min: 768 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    mobileX: {
      breakpoint: { max: 767, min: 641 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    mobile: {
      breakpoint: { max: 640, min: 551 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 60,
    },
    mobileS: {
      breakpoint: { max: 550, min: 360 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 0,
    },
    mobileXS: {
      breakpoint: { max: 359, min: 300 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
      partialVisibilityGutter: 100,
    },
  };

  // const [width, setWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   function handleResize() {
  //     setWidth(window.innerWidth);
  //   }
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [width]);

  // useEffect(() => {
  //   width < 360;
  // },[width]);

  return (
    <div className="online-cards-section-main">
      <div
        className="card_title_box"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div>
          <p className="section-title" style={{fontWeight:'bold'}}>{title}</p>
          {type == "product" && maxDiscount && (<p>Upto {maxDiscount} % off</p>)}
        </div>
        {viewall && (
          <p className="section-view-all" onClick={() => handleRedirection()}>
            View all
          </p>
        )}
      </div>
      <Carousel
        className="carousol-container product_slide_box"
        additionalTransfrom={-20}
        //arrows={window.innerWidth <= 760 && type !== "category" ? false : true}
        autoPlaySpeed={3000}
        centerMode={false}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={true}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        partialVisible
        responsive={
          type === "category" ? Catogeryresponsive : productresponsive
        }
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {type === "category" &&
          categories?.map((data) => {
            return (
              <CategoryCard
                image={`${FILE_PATH}${data.image}`}
                title={data.categoryName}
                key={data.categoryId}
                id={data.categoryId}
                seller={title === "All India Products"}
                subCategoryId={data.subCategory0Id}
                subCategoryName={data.subCategory0Name}
              />
            );
          })}
        {type === "product" &&
          productData.map((data, index) => {
            return renderProductCard(data, index);
          })}
      </Carousel>
    </div>
  );
};

export default withRouter(OneLineCardstSection);
