


import React from "react";

import { Typography } from "antd";

import "./description.scss";

const { Text } = Typography;
export default function Description(props) {
  const { description } = props;
  return (
    <div className="description-container hr_pdt_description">
      {description && <Text>{description}</Text>}
    </div>
  );
}
