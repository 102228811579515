

import React, { useEffect, useState, Fragment } from "react";
import { Menu } from "antd";
import {
  sideBarDataForPlatformBlockedVendor,
  sideBarDataStore,
} from "../../Utils/Seller/Data";
import { NavLink } from "react-router-dom";
import Icon from "@ant-design/icons";
import "antd/dist/antd.css";
import "../Admin/admin.scss";
import ManageReturnsIcon from "../../Assets/Images/Icons/Seller/returns.svg";
import ManageReturnsIconActive from "../../Assets/Images/Icons/Seller/returnsActive.svg";
import { fetchSort } from "../../ReduxStore/action";
import { connect } from "react-redux";
import { getSellerProfile } from "../../ApiAction/SellerApiActions/sellerApi";
import Cookies from "universal-cookie";
const { SubMenu } = Menu;

const StoreMenu = (props) => {
  const { activeTab, setSearchval } = props;
  const [urlPath, setUrlPath] = useState("");
  const [selectedMenu, setActiveMenu] = useState("my-profile");
  const [isVendorPlatformBlocked, setIsVendorPlatformBlocked] = useState(true);
  const cookies = new Cookies();

  const menuItemClick = (data) => {
    setActiveMenu(data.key);
    setSearchval && setSearchval("");
    if (props.isNav) {
      props.setNavVisible(false);
    }
  };

  useEffect(() => {
    setUrlPath(props.pathname);
    getSellerProfile()
      .then((response) => {
        console.log("Called Profile")
        cookies.set("activeUserPincode", response?.data?.profile?.source?.address?.pinCode)
        if (response?.data?.status === "success" && cookies.get("switchuser") !== "true") {
          setIsVendorPlatformBlocked(
            response?.data?.profile?.source?.platformChargeBlock
          );
        } else {
          setIsVendorPlatformBlocked(false)
        }
      })
  }, [props]);

  const getReportSubMenu = () => {
    return (
      <SubMenu
        key="sales-report"
        title={
          <Fragment>
            <span
              style={{paddingLeft: "10px"}}>
              <img
                alt="Reports"
                src={
                  "sales-report" === activeTab
                    ? ManageReturnsIconActive
                    : ManageReturnsIcon
                }
                style={{ width: "20px", marginRight: "10px" }}
              />
            </span>
            <span
              className="side-menu-link-content"
              style={{
                fontWeight: "400",
                fontSize: "15px",
                lineHeight: "normal",
              }}
            >
              <span>Reports</span>
            </span>
          </Fragment>
        }
        style={{paddingLeft: "30px"}}
      >
        <Menu.Item key="sales-report">
          <NavLink to={"/store/sales-report"}>
            <span className="side-menu-link-content">Sales</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="stocks-report">
          <NavLink to={"/store/stocks-report"}>
            <span className="side-menu-link-content">Stocks</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="detailed-report">
          <NavLink to={"/store/detailed-report"}>
            <span className="side-menu-link-content">Stock Value</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="expiry-report">
          <NavLink to={"/store/expiry-report"}>
            <span className="side-menu-link-content">Expiry</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="day-book-report">
          <NavLink to={"/store/day-book-report"}>
            <span className="side-menu-link-content">Day Book Report</span>
          </NavLink>
        </Menu.Item>
          {/* <Menu.Item key="online-payment">
            <NavLink to={"/store/online-payment"}>
              <span className="side-menu-link-content">Online Payment</span>
            </NavLink>
          </Menu.Item> */}
      </SubMenu>
    )
  }

  return (

    <Menu
      className="side-nav-menu"
      mode="inline"
      defaultSelectedKeys={[activeTab]}
      selectedKeys={[activeTab]} //activeTab refers to the current active tab
      onClick={menuItemClick}
    >
      {isVendorPlatformBlocked? (
        sideBarDataForPlatformBlockedVendor.map((data) => {
          if(data.key == 'reports')
            return getReportSubMenu()
          return (
            <Menu.Item
              key={data.key}
              // disabled={data.key !== "manage-products" ? true : false}
            >
              <NavLink to={data.url}>
                <span>
                  {" "}
                  <img
                    src={data.key === activeTab ? data.activeIcon : data.icon}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                </span>
                <span className="side-menu-link-content">
                  <span>{data.label}</span>
                  {data.newModule && (
                    <span className="new-label">{data.newModule}</span>
                  )}
                </span>
              </NavLink>
            </Menu.Item>  
          )})
        ) : (
          sideBarDataStore.map((data) => {
            if(data.key == 'reports')
              return getReportSubMenu()
            return (
              <Menu.Item
                key={data.key}
                // disabled={data.key !== "manage-products" ? true : false}
              >
                <NavLink to={data.url}>
                  <span>
                    {" "}
                    <img
                      src={data.key === activeTab ? data.activeIcon : data.icon}
                      style={{ width: "20px", marginRight: "10px" }}
                    />
                  </span>
                  <span className="side-menu-link-content">
                    <span>{data.label}</span>
                    {data.newModule && (
                      <span className="new-label">{data.newModule}</span>
                    )}
                  </span>
                </NavLink>
              </Menu.Item>
            );
          })
        )
      }
    </Menu>
  );
};
const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreMenu);
