


import Axios from "../axios";
import { API_SERV_ADDR, API_SERV_ADDR_2 } from "../../Utils/constants";
import { getLogAttributes } from "../../components/Admin/Logger/notificationBar/LogHelper";

import Cookies from "universal-cookie";
const cookies = new Cookies();
const user = JSON.parse(localStorage.getItem("activeCustomer"));

export const addProductToCentralRepo = (data) => {
  data = getLogAttributes(data, "Manage Central Repo");
  return Axios({
    url: `${API_SERV_ADDR}/admin/add-central-product`,
    method: "POST",
    data: data,
  });
};

export const bulkUploadProductToCentralRepo = (data) => {
  var bodyFormData = new FormData();
  bodyFormData.append("files", data);
  return Axios({
    url: `${API_SERV_ADDR}/admin/bulk-upload-central-product`,
    method: "POST",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const bulkUploadAdminToCentralRepo = (data, userId) => {
  var bodyFormData = new FormData();
  bodyFormData.append("files", data);
  return Axios({
    url: `${API_SERV_ADDR}/product/bulk-upload-batch/${userId}`,
    method: "POST",
    data: bodyFormData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateProductInCentralRepo = (data) => {
  data = getLogAttributes(data, "Manage Central Repo");
  return Axios({
    url: `${API_SERV_ADDR}/admin/update-central-product`,
    method: "POST",
    data: data,
  });
};

export const deleteProductFromCentralRepo = (productId) => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/delete-central-product/${productId}`,
    method: "DELETE",
  });
};

export const draftToggleCentralRepo = (productId) => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/toggle-draft-central-product/${productId}`,
    method: "POST",
  });
};

export const fetchCentralRepo = (data) => {
  return Axios({
    url: `${API_SERV_ADDR_2}/admin/search-products`,
    method: "POST",
    data: data,
  });
};

export const fetchProductDetailFromCentralRepo = (productId) => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/get-product/${productId}`,
    method: "GET",
  });
};

export const addProductImageInCentralRepo = (data) => {
  const bodyFormData = new FormData();
  bodyFormData.append("accountType", data.accountType);
  bodyFormData.append("productId", data.productId);
  bodyFormData.append("files", data.file);
  bodyFormData.append("master", data.master);

  return Axios({
    url: `${API_SERV_ADDR}/product/central-repo/image-upload`,
    method: "POST",
    data: bodyFormData,
  });
};

export const removeCentralRepoProductImage = (payload) => {
  const data = {
    ...payload,
    accountType: cookies.get("accountType"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/product/central-repo//image-video-remove`,
    method: "DELETE",
    data: data,
  });
};

export const exportCentralRepo = (data) => {
  return Axios({
    url: `${API_SERV_ADDR}/export-products`,
    method: "POST",
    data: data,
    responseType: "blob",
  });
};
