


import {
  OPEN_LOGIN_MODAL,
  OPEN_SIGNUP_MODAL,
  CLOSE_AUTH_MODAL,
  OPEN_CONTACT_MODAL,
  ADD_REFERRAL_ID,
  ADD_AFFILIATE_ID,
} from "./types";

export const openLoginModal = (data) => {
  return {
    type: OPEN_LOGIN_MODAL,
    data,
  };
};

export const openContactModal = (data) => {
  return {
    type: OPEN_CONTACT_MODAL,
    data,
  };
};

export const openSignUpModal = (data) => {
  return {
    type: OPEN_SIGNUP_MODAL,
    data,
  };
};

export const closeAuthModal = () => {
  return {
    type: CLOSE_AUTH_MODAL,
    data: "",
  };
};

export const updateReferralId = (data) => {
  return {
    type: ADD_REFERRAL_ID,
    data,
  };
};

export const updateAffiliateId = (data) => {
  return {
    type: ADD_AFFILIATE_ID,
    data,
  };
};
