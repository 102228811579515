


import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Row,
  Form,
  Button,
  Divider,
  Input,
  message,
  Typography,
} from "antd";
import "./manageConfigure.scss";
import Loading from "../../GeneralComponents/Loading";
import { getSignUpPaymentAmount, postSignUpPaymentAmount } from "../../../ApiAction/AdminApiAction/adminApi";

const { Text } = Typography;

const ConfigureSignUpAmount = (props) => {
  const [form] = Form.useForm();
  const [signupPaymentAmount, setSignupPaymentAmount] = useState();
  const [isEditSignUpPaymentAmount, setIsEditSignUpPaymentAmount] = useState(false);
  const [isEditSignUpPaymentAmountLoading, setIsEditSignUpPaymentAmountLoading] = useState(false);

  const fetchSignUpPaymentAmount = () => {
    getSignUpPaymentAmount().then((res) => {
      if (res.data.status == "success") {
        // console.log("res.data is :", res.data)
        form.setFieldsValue({
          signupPaymentAmount: res.data.signupPaymentAmount
        })
        setSignupPaymentAmount(res.data.signupPaymentAmount);
      }
    });
  };

  const handleCancelSignUpPaymentAmount = () => {
    form.setFieldsValue({
      signupPaymentAmount: signupPaymentAmount
    })
    setIsEditSignUpPaymentAmount(false);
  }

  const updateSignupPaymentAmount = (values) => {
    const payload = {
      signupPaymentAmount: Number(values.signupPaymentAmount),
    };
    setIsEditSignUpPaymentAmountLoading(true);
    postSignUpPaymentAmount(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setIsEditSignUpPaymentAmountLoading(false);
            setIsEditSignUpPaymentAmount(false);
            message.success(response.data.message);
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        message.error("Sorry! Something went wrong. Please try again later!");
      })
      .finally(() => { setIsEditSignUpPaymentAmountLoading(false) });
  };

  useEffect(() => {fetchSignUpPaymentAmount()},[])

  return (
    <div>
      <Divider />
      <Form
        form={form}
        name="signup_payment_amount"
        onFinish={updateSignupPaymentAmount}
      >
        <Row>
          <Col
            xs={24}
            sm={24}
            md={16}
            lg={16}
            xl={16}
            className="add-image-col"
          >
            <Text>
              {"Sign-up Payment Amount ( in rupees )"}{" "}
              <span className="required-field-asterisk">*</span>
            </Text>
            <Form.Item
              name="signupPaymentAmount"
              rules={[
                {
                  required: true,
                  message: "This field is required !",
                },
                {
                  pattern: new RegExp("^[1-9]+[0-9]*$"),
                  message: "Enter a valid number !",
                },
              ]}
            >
              <Input
                disabled={!isEditSignUpPaymentAmount}
                type="number"
                placeholder="threshold value"
                defaultValue="0"
                min="0"
              />
            </Form.Item>
          </Col>
          {/* ); */}
          {/* })} */}
        </Row>
        <div className="config_action_main">
          {isEditSignUpPaymentAmount ? (
            <Fragment>
              <Button
                onClick={() => handleCancelSignUpPaymentAmount()}
                className="config_action_btn"
              >
                Cancel
              </Button>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="config_action_btn"
                  style={{ marginLeft: "20px" }}
                >
                  {isEditSignUpPaymentAmountLoading ? (
                    <Loading style={{ color: "white" }} />
                  ) : (
                    " Submit"
                  )}
                </Button>
              </Form.Item>
            </Fragment>
          ) : (
            <Button
              type="primary"
              onClick={() => setIsEditSignUpPaymentAmount(true)}
              className="config_action_btn"
            >
              Edit
            </Button>
          )}
        </div>
        {/* <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <br />
              </Form.Item> */}
      </Form>
    </div>
  );
};

export default ConfigureSignUpAmount;
