


import React from "react";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";

import NotFoundLogo from "../../../src/Assets/Images/Logo/DL_Logo.png";
import NotFoundVector from "../../../src/Assets/Images/Vectors/notfound.png";

import { ENV_TYPE } from "../../Utils/constants";
const PageNotFound = (props) => {
  const cookies = new Cookies();

  const handleRedirectToHome = () => {
    switch (cookies.get("accountType")) {
      case "customer":
        props.history.push(ENV_TYPE === "production" ? "/" : `/customer`);
        break;
      case "warehouse":
        props.history.push(`/warehouse/profile`);
        break;
      case "store":
        props.history.push(`/store/profile`);
        break;
      case "admin":
        props.history.push(`/admin/profile`);
        break;
      default:
        props.history.push("/");
        console.log("invalid choice");
    }
  };
  return (
    <div className="page-not-found" style={{ backgroundColor: "white" }}>
      <div className="page-visit" style={{ height: "100vh" }}>
        <div
          className="container"
          style={{
            height: "100%",
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <div class="not-found" style={{ width: "100%", alignSelf: "center" }}>
            <div
              className="not-found-logo"
              style={{ width: "220px", margin: "0 auto" }}
            >
              <img
                src={NotFoundLogo}
                alt="Company"
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ textAlign: "center", marginTop: "25px" }}>
              <h1
                style={{
                  fontSize: "84px",
                  color: "#030c45",
                  fontWeight: "600",
                  lineHeight: "1.8",
                  marginBottom: "0",
                }}
              >
                404
              </h1>
              <h3
                style={{
                  fontSize: "22px",
                  color: "#030c45",
                  fontWeight: "700",
                  lineHeight: "1.7",
                  marginBottom: "15px",
                }}
              >
                Page Not Found
              </h3>
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "1.6",
                  marginBottom: "15",
                }}
              >
                Sorry! We can't seem to find the page you're looking for.{" "}
                <br></br>Please try going back to the Home page or contact our
                customer care for any assistance.
              </p>
              <a
                onClick={() => handleRedirectToHome()}
                style={{
                  width: "180px",
                  margin: "0 auto",
                  display: "table",
                  fontSize: "16px",
                  color: "#fff",
                  padding: "10px 15px",
                  fontWeight: "500",
                  lineHeight: "1.6",
                  borderRadius: "20px",
                  backgroundColor: "#3e6df3",
                  marginTop: "25px",
                }}
              >
                Back to Home
              </a>
            </div>
          </div>
          <div
            className="vector-img"
            style={{ maxWidth: "830px", margin: "0 auto" }}
          >
            <img
              src={NotFoundVector}
              alt="Company"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(PageNotFound);
