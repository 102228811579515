

import { getLogAttributes } from "../../components/Admin/Logger/notificationBar/LogHelper";
import { API_SERV_ADDR_2 } from "../../Utils/constants";
import Axios from "../axios";

export const addCategory = (payload) => {
  payload = getLogAttributes(payload, "Manage Category");
  return Axios({
    url: `${API_SERV_ADDR_2}/category/add`,
    method: "PUT",
    data: payload,
  });
};

export const updateCategory = (payload) => {
  payload = getLogAttributes(payload, "Manage Category");
  return Axios({
    url: `${API_SERV_ADDR_2}/category/update`,
    method: "POST",
    data: payload,
  });
};

export const deleteCategory = (categoryId) => {
  let payload = getLogAttributes({}, "Manage Category");
  return Axios({
    url: `${API_SERV_ADDR_2}//category/delete/${categoryId}`,
    method: "DELETE",
    data: payload
  });
};

export const reorderCategory = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR_2}/category/reorder`,
    method: "POST",
    data: payload,
  });
};

export const addTypeCategory = (payload) => {
  payload = getLogAttributes(payload, "Manage Categories");
  return Axios({
    url: `${API_SERV_ADDR_2}/category/type/add`,
    method: "POST",
    data: payload,
  });
};

export const addTypeImage = (data) => {
  const bodyFormData = new FormData();
  bodyFormData.append("id", data.id);
  bodyFormData.append("files", data.files);

  return Axios({
    url: `${API_SERV_ADDR_2}/category/type/image-upload`,
    method: "POST",
    data: bodyFormData,
  });
};

export const refetchCategoryList = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR_2}/category/type/get/level/zero`,
    method: "POST",
    data: payload,
  });
};

export const updateCategoryType = (payload) => {
  payload = getLogAttributes(payload, "Manage Categories")
  return Axios({
    url: `${API_SERV_ADDR_2}/category/type/update`,
    method: "POST",
    data: payload,
  });
};
