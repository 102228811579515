


export const CUSTOMER = "CUSTOMER";
export const SORTBY = "SORTBY";
export const CART_COUNT = "CART_COUNT";
export const MANAGE_ORDER = "MANAGE_ORDER";

//login and signup modal management
export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const OPEN_CONTACT_MODAL = "OPEN_CONTACT_MODAL";
export const OPEN_SIGNUP_MODAL = "OPEN_SIGNUP_MODAL";
export const CLOSE_AUTH_MODAL = "CLOSE_AUTH_MODAL";
export const ADD_REFERRAL_ID = "ADD_REFERRAL_ID";
export const ADD_AFFILIATE_ID = "ADD_AFFILIATE_ID";

//customer address management
export const REFTECH_ADDRESS_LIST = "REFTECH_ADDRESS_LIST";

//product listing
export const ADD_CATEGORY_FILTER = "ADD_CATEGORY_FILTER";
export const ADD_SUBCATEGORY_00_FILTER = "ADD_SUBCATEGORY_00_FILTER";
export const ADD_SUBCATEGORY_01_FILTER = "ADD_SUBCATEGORY_01_FILTER";
export const ADD_SUBCATEGORY_02_FILTER = "ADD_SUBCATEGORY_02_FILTER";
export const ADD_SUBCATEGORY_03_FILTER = "ADD_SUBCATEGORY_03_FILTER";
export const ADD_BRAND_FILTER = "ADD_BRAND_FILTER";
export const ADD_SELLER_ID = "ADD_SELLER_ID";
export const ADD_STORE_ID = "ADD_STORE_ID";
export const ADD_SEARCH_FILTER = "ADD_SEARCH_FILTER";
export const ADD_PRICE_RANGE_FILTER = "ADD_PRICE_RANGE_FILTER";
export const SET_PINCODE = "SET_PINCODE";
export const RESET_CATEGORIES_FILTER = "RESET_CATEGORIES_FILTER";
export const CLEAR_PRODUCT_LISTING_FILTER = "CLEAR_FILTER";

//customer Order
export const FETCH_ORDER_RESPONSE = "FETCH_ORDER_RESPONSE";
export const REFETCH_ORDER_LIST = "REFETCH_ORDER_LIST";
export const FETCH_ORDER_FAILURE = "FETCH_ORDER_FAILURE";

//reports
export const SET_FILTER_BY_BRAND = "SET_FILTER_BY_BRAND";
export const SET_FILTER_BY_VENDOR = "SET_FILTER_BY_VENDOR";
export const SET_FILTER_BY_DATE_RANGE = "SET_FILTER_BY_DATE_RANGE";
export const SET_FITER_BY_STOCK_PERCENTAGE = "SET_FITER_BY_STOCK_PERCENTAGE";
export const SET_FILTER_BY_SCREEN = "SET_FILTER_BY_SCREEN";

//homepage
export const FETCH_HOMEPAGE_DATA = "FETCH_HOMEPAGE_DATA";

//cartItems
export const FETCH_CART_ITEMS = "FETCH_CART_ITEMS";
export const FETCH_COMPANY_DETAILS = "FETCH_COMPANY_DETAILS";


//request payment
export const REFETCH_REQUEST_PAYMENT = "REFETCH_REQUEST_PAYMENT";

//seller
export const SELLER_STATUS = "SELLER_STATUS";