


import React, { Fragment } from "react";
import { Card } from "antd";
const LoadingCartItem = () => {
  const dummyData = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <Fragment>
      {dummyData.map((data) => {
        return (
          <Card
            key={data}
            style={{ width: "100%", marginBottom: "20px", marginTop: "20px" }}
            loading={true}
          ></Card>
        );
      })}
    </Fragment>
  );
};

export default LoadingCartItem;
