

import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import { CheckAvailablProducts } from "../../../../ApiAction/SellerApiActions/vendoOrderAPI";
import AvailableProductCard from "./availableProductCard";
import { formatProductData } from "../../../Customer/Components/ProductListing/productListingHelper";

import EmptyList from "../../../GeneralComponents/emptyList";

let size = 10;

const AvailableProductList = (props) => {
  const { filterBy, searchKey } = props;
  const [productList, setproductList] = useState([]);
  const [totalCount, settotalCount] = useState(0);
  const [pageNo, setpageNo] = useState(1);
  const [loading, setloading] = useState(false);

  const LoadingList = Array.from(Array(5).keys());

  const handleFetchAvailiableProducts = (page = 1) => {
    setpageNo(page);
    const range = [
      {
        outOfStock: {
          gte: "1",
        },
      },
    ];
    const payload = {
      type: filterBy,
      conditions: {
        key: searchKey ? searchKey : "",
        size: size,
        offset: (page - 1) * size,
        filter: {
          range: range,
        },
      },
    };
    setloading(true);
    CheckAvailablProducts(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            if (response.data.products.length == 0) {
              if (payload.conditions.key.length > 0) {
                settotalCount(0);
                setloading(false);
                setproductList([]);
              } else {
                size = size + 2;
                handleFetchAvailiableProducts();
              }
            } else {
              setproductList(formatProductData(response.data.products, true));
              settotalCount(Math.ceil(response.data.count / 4) * 4);
              setloading(false);
            }
          } else {
            setproductList([]);
          }
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    handleFetchAvailiableProducts();
  }, [filterBy, searchKey, props.brandId]);
  return (
    <Fragment>
      {productList.length === 0 && !loading ? (
        <EmptyList msg={"No Products found"} />
      ) : loading ? (
        LoadingList.map((data) => {
          return <AvailableProductCard loading={loading} />;
        })
      ) : (
        productList.map((product) => {
          if (product?.processedPrice?.length > 0) {
            return <AvailableProductCard product={product} loading={loading} />;
          }
        })
      )}

      {totalCount > 4 && (
        <Pagination
          size="small"
          total={totalCount}
          defaultPageSize={size}
          current={pageNo}
          defaultCurrent={1}
          showSizeChanger={false}
          className="customer-pagination"
          onChange={(page) => {
            // setpageNo(page);
            handleFetchAvailiableProducts(page);
            window.scrollTo(0, 0);
          }}
        />
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    brandId: state.reportReducer.brandId,
  };
};

export default connect(mapStateToProps)(AvailableProductList);
