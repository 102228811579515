


import React, { useState } from "react";
import moment from "moment";

import {
  Modal,
  Divider,
  Typography,
  Input,
  Row,
  Col,
  Image,
  Button,
} from "antd";

import onlineIcon from "../../../Assets/Images/Icons/Seller/online-shopping.svg";
import calendarIcon from "../../../Assets/Images/Icons/Seller/calendar.svg";
import { FILE_PATH } from "../../../Utils/constants";
import { updateOrderStatus } from "../../../ApiAction/SellerApiActions/sellerApi";
const { Text } = Typography;
const { TextArea } = Input;
const SellerReturnDetails = (props) => {
  const [replyMessage, setMessage] = useState("");

  const updateStatus = (type) => {
    const payload = {
      id: props.data.id,
      status: type,
      replyToTheRequest: replyMessage,
    };

    props.updateStatus(payload);
  };

  return (
    <Modal
      visible={true}
      onOk={() => props.setCancel(false)}
      onCancel={() => {
        props.setCancel(false);
      }}
      width={750}
      footer={null}
    >
      <div className="view-invoice-layout" style={{ padding: "10px" }}>
        <div className="justify-space-between">
          <div className="flex">
            <div>
              <img
                className="manage-products-draft-product-card-image"
                src={FILE_PATH + props.data.source.image}
                alt={
                  props.data &&
                  props.data.source &&
                  props.data.source.productName
                    ? props.data.source.productName
                    : "product"
                }
              />
            </div>
            <div className="return-data-card-seller">
              <div className="seller-return-product-name">
                {props.data.source.productName}
              </div>
              <div className="seller-return-product-price">
                ₹ {props.data.source.itemCurrentPrice.sellingPrice}
              </div>
              <div>
                <Text delete>₹ {props.data.source.itemCurrentPrice.price}</Text>
              </div>
              <div className="seller-return-name">
                ₹{" "}
                {props.data.source.itemCurrentPrice.quantity +
                  " " +
                  props.data.source.itemCurrentPrice.unit}
              </div>
              <div className=" flex-align-center">
                <img
                  src={calendarIcon}
                  style={{ width: "20px" }}
                  className="verified-icon"
                  alt="company"
                />
                {moment(props.data.source.requestedDate).format(
                  " MMM DD, hh:mm A"
                )}
              </div>
              <div className=" flex order-item-active">
                {" "}
                <img
                  src={onlineIcon}
                  style={{ width: "25px" }}
                  className="verified-icon"
                  alt="company"
                />
                {" " + props.data.source.paymentMode}{" "}
              </div>
            </div>
          </div>
          <div>
            <div className="seller-return-name">
              {" "}
              {props.data.source.shippedAddress.ownerName}
            </div>
            <div className="">
              {props.data.source.shippedAddress.addressLine1 +
                (props.data.source.shippedAddress.buildingName ? "," : "")}
              {props.data.source.shippedAddress.buildingName}
              <br />
              {props.data.source.shippedAddress.pinCode}
            </div>
            <div style={{ marginTop: "10px" }}>
              {props.data.source.shippedAddress.phoneNo}
            </div>
          </div>
        </div>

        <Divider />

        <div className="justify-space-between">
          <div className="seller-return-product-price"></div>
          <div className="flex">
            <div> ORDER ID{" " + props.data.source.groupOrderId}</div>
            <div> , {moment(props.data.source.requestedDate).fromNow()}</div>
          </div>
        </div>

        <div className="return-message">
          {" "}
          {props.data.source.reasonOfCancellation}
        </div>

        <TextArea
          rows={4}
          placeholder="Reply text"
          style={{ marginBottom: "15px" }}
          defaultValue={props.data.source.replyToTheRequest}
          onChange={(e) => setMessage(e.target.value)}
        />

        {props.data.source.status == "PENDING" && (
          <div className="seller-action-button">
            <Button
              style={{ width: "200px" }}
              className="button-seller-request-reject"
              onClick={() =>
                // updateOrderStatus("REJECTED")
                updateStatus("REJECTED")
              }
            >
              Reject Request
            </Button>

            <Button
              style={{ width: "200px" }}
              onClick={() => updateStatus("ACCEPTED")}
              className="button-seller-request-approve"
            >
              Approve Request
            </Button>
          </div>
        )}
        {props.data.source.status == "ACCEPTED" &&
          props.data.source.account &&
          props.data.source.paymentMode == "COD" && (
            <div>
              <div className="seller-return-name"> Account Details</div>
              <div> {props.data.source.account.accountHolderName}</div>
              <div className="">
                {props.data.source.account.accountNumber}
                <br />
                {props.data.source.account.bankName}
                <br />
                {props.data.source.account.branch}
              </div>
              <div>{props.data.source.account.ifscCode}</div>
            </div>
          )}
      </div>
    </Modal>
  );
};
export default SellerReturnDetails;
