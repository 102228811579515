import React, { useEffect } from 'react'

function LogPagination(props) {

    const { count, currentPage, setCurrentPage, setOffSet, size } = props;

    useEffect(() => {
        console.log("Current page :: ", props)
        appendButtons()
    }, [props])

    const appendButtons = () => {
        const _widget = document.querySelector('#LogPagination-container');
        const _totalButtons = parseInt(count) / parseInt(size) > 0 ? Math.floor(parseInt(count) / parseInt(size)) : 1;

        removeAllChildNodes(_widget)

        if(_totalButtons > 10) {
            let before = currentPage;
            let after = _totalButtons - currentPage;
            console.log("Before and after", before, after)
            removeAllChildNodes(_widget)

            if(before > after) {
                console.log("Before is greater")
                displayFirstPart()
                if((_totalButtons - currentPage) > 9) {
                    displayBetweenPageNumbers(currentPage - 5, currentPage+5)
                    displayLastPart(_totalButtons) 
                }else {
                    let vector = _totalButtons - currentPage;
                    console.log("Vector", vector)
                    let difference = currentPage - (10 - vector)
                    console.log("Difference ", difference)
                    displayBetweenPageNumbers(difference, difference+10)                       
                }
                if(after > (currentPage + 10)) {
                    displayLastPart(_totalButtons) 
                }
            }else {
                if(currentPage > 9) {
                    console.log("Greater than 9")
                    displayFirstPart()
                    // if the totalnumber is greater than current + 10
                    if((_totalButtons - currentPage) > 10) {
                        displayBetweenPageNumbers(currentPage - 5, currentPage+5)
                        displayLastPart(_totalButtons)
                    }
                }else {
                    console.log("Not greater than")
                    displayBetweenPageNumbers(1,10)
                    displayLastPart(_totalButtons)
                } 
            } 
        }else {
            displayBetweenPageNumbers(1,_totalButtons)
        }
    }

    const displayFirstPart = () => {
        const _widget = document.querySelector('#LogPagination-container');
        const _firstButton = document.createElement("button")
        _firstButton.innerHTML = '1';
        _firstButton.setAttribute('class', "LogPagination-buttons")
        _firstButton.onclick = () => {
            finder(1)
        }
        _widget.append(_firstButton)  
        
        const _dots = document.createElement("p")
        _dots.innerHTML = "...";
        _widget.append(_dots)
    }

    const displayBetweenPageNumbers =  (starting, ending) => {
        const _widget = document.querySelector('#LogPagination-container');
        for(let i = starting; i <= ending; i++) {
            const _paginationButton = document.createElement('button');
            _paginationButton.innerHTML = i;
            _paginationButton.setAttribute('class', "LogPagination-buttons")
            if(i === parseInt(currentPage)) {
                _paginationButton.classList.add('activePageButton')
            }
            _paginationButton.onclick = () => {
                finder(i)
            }
            _widget.append(_paginationButton)
        }
    }

    const displayLastPart = (_totalButtons) => {
        const _widget = document.querySelector('#LogPagination-container');
        const _dots = document.createElement("p")
        _dots.innerHTML = "...";
        _widget.append(_dots)

        const _lastButton = document.createElement("button");
        _lastButton.innerHTML = _totalButtons;
        _lastButton.setAttribute('class', "LogPagination-buttons")
        _lastButton.onclick = () => {
            finder(_totalButtons)
        }
        _widget.append(_lastButton) 
    }


    const removeAllChildNodes = (parent) => {
        while (parent.firstChild) {
            parent.removeChild(parent.firstChild);
        }
    }

    const finder = (value) => {
        setCurrentPage(value);
        setOffSet(value * 10);
    }

    return (
        <div className="LogPagination-main-container" id="LogPagination-container"></div>
    )
}

export default LogPagination
