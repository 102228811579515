

import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  InputNumber,
  Select,
  Input,
  Button,
  Modal,
  message,
  Divider,
} from "antd";
import Text from "antd/lib/typography/Text";
import {
  updateBatchBarcode,
  generateNewBarcode,
  fetchBarcodeWithVariant,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import Cookies from "universal-cookie";

const { Option } = Select;

const StockVarient = (props) => {console.log(props)
  const [form] = Form.useForm();
  const {
    stockType,
    typeVariants,
    updateMultipleField,
    categoryCommission,
    selectedUnit,
    selectedQuantity,
    barcode,
    editBarcode,
    setBarcode,
    acceptBarcodeValues,
    newBarcode,
    setNewBarcode,
    productId,
    getProductDetails,
    removeBatch,
    reloadInputs,
    setReloadInputs,
    centralRepoId,
  } = props;
  const cookies = new Cookies();
  const [sellingPrice, setSellingPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [currentBarcode, setCurrentBarcode] = useState();
  const [percentage, setPercentage] = useState(0);
  const [weightInkg, setWeightInkg] = useState(0);
  const [dealerPrice, setdealerPrice] = useState(0);
  const [variantOptions, setvariantOptions] = useState();
  const [quantity, setquantity] = useState();
  const [unit, setunit] = useState();
  const [stock, setstock] = useState();
  const [stockEditable, setStockEditable] = useState(false);
  const [showUpdateBarcode, setShowUpdateBarcode] = useState(false);
  const [isThisAdmin] = useState(cookies.get("switchuser") == "true" || 
    cookies.get("accountType") == "admin");
  const [enableGenerateBarcode, setEnableGenerateBarcode] = useState(true);
  const [generatingBarcodeLoading, setGeneratingBarcodeLoading] =
    useState(false);
  const [batchType, setBatchType] = useState();
  const [typeList, setTypeList] = useState([]);

  const fetchVariantsList = () => {
    typeVariants.map((data) => {
      if (data.type === stockType) {
        console.log(data.variants, "this is variant list for edit");
        setvariantOptions(data.variants);
        console.log("Props Initial Values are : ", props.initialValues);
      }
    });
  };
  const [platformCharge, setPlatformCharge] = useState(0);

  useEffect(() =>{
    if(acceptBarcodeValues){
      form.setFieldsValue({
        variantStock: '',
        variantMrp: '',
        variantDiscount: '',
        variantSellingPrice: '',
        variantWeight: '',
        variantDealerPrice: '',
      });
      console.log("inputs");
    }
  }, [reloadInputs]);

  useEffect(() => {
    setPrice(props.priceStock.price);
    setPercentage(props.priceStock.discount);
    setWeightInkg(props.priceStock.weightInKg);
    setdealerPrice(props.priceStock.dealerPrice);
    setSellingPrice(props.priceStock.sellingPrice);
    setBarcode(props.priceStock.barcode);
    setquantity(props.priceStock.quantity);
    if (!acceptBarcodeValues) {
      setCurrentBarcode(props.priceStock.barcode);
    } else {
      setCurrentBarcode(barcode);
    }
    setunit(props.priceStock.unit);
    setstock(props.priceStock.stock);
    if (props.noNeedToCheckStock) {
      setStockEditable(false);
    } else {
      setStockEditable(true);
    }
    if(typeVariants) {
      setBatchType(typeVariants?.filter(o => o.type == stockType)?.[0]?.type);
      setTypeList(typeVariants?.map(o => o.type));
    }
  }, []);

  useEffect(() => {
    setunit("");
    props.editVarient(props.priceStock.variant, "unit", "");
  }, [props.variantList]);

  useEffect(() => {
    if (props.initialValues) {
      form.setFieldsValue({
        variantQuantity: props.initialValues.quantity,
        variantStock: props.initialValues.stock,
        variantMrp: props.initialValues.price,
        variantDiscount: props.initialValues.discount,
        variantSellingPrice: props.initialValues.sellingPrice,
        variantWeight: props.initialValues.weightInKg,
        variantDealerPrice: props.initialValues.dealerPrice,
      });
    }
  }, [props.initialValues]);

  //rerender price attribute on refetching batch list
  useEffect(() => {
    setPrice(props.priceStock.price);
    setPercentage(props.priceStock.discount);
    setWeightInkg(props.priceStock.weightInKg);
    setBarcode(props?.priceStock?.barcode);
    setdealerPrice(props.priceStock.dealerPrice);
    setSellingPrice(props.priceStock.sellingPrice);
    setquantity(props.priceStock.quantity);
    if (!acceptBarcodeValues) {
      setCurrentBarcode(props.priceStock.barcode);
    } else {
      setCurrentBarcode(barcode);
    }
    setunit(props.priceStock.unit);
    setstock(props.priceStock.stock);
    findPlatformCharge();
    if(props.edit) {
      setBatchType(typeVariants?.find(item => 
        item.variants.includes(props.priceStock.unit))?.type)
    }
  }, [props.initialValues]);

  useEffect(() => {
    if (typeVariants && stockType) {
      fetchVariantsList();
    }
  }, [stockType]);

  useEffect(() => {
    if (acceptBarcodeValues) {
      console.log("Called 4 with props.edit");
      form.setFieldsValue({ variantQuantity: selectedQuantity });
      setunit(selectedUnit);
      setBarcode(barcode);
      setCurrentBarcode(barcode);
    }
  }, [selectedQuantity, props.edit, selectedUnit]);

  useEffect(() => {
    if (acceptBarcodeValues) {
      // console.log("Called 4 with props.edit");
      // form.setFieldsValue({ variantQuantity: selectedQuantity });
      // setunit(selectedUnit);
      setBarcode(barcode);
      setCurrentBarcode(barcode);
    }
  }, [barcode]);

  useEffect(() => {
    props.editVarient(props.priceStock.variant, "sellingPrice", sellingPrice);
  }, [sellingPrice]);

  const getPercentage = (percentageOf = 0, number = 0) => {
    if (percentageOf === 0 || percentageOf === "") {
      return number;
    } else return 100 - (percentageOf / number) * 100;
  };
  const percentageOf = (num = 0, per = 0) => {
    if (per == 0 || per == "") return num;
    return num - (per / 100) * num;
  };

  const handleChangeMRP = async (mrp) => {
    if (mrp) {
      const fields = [
        { variant: props.priceStock.variant, type: "sellingPrice", value: mrp },
        { variant: props.priceStock.variant, type: "discount", value: 0 },
        { variant: props.priceStock.variant, type: "price", value: mrp },
      ];
      updateMultipleField(fields);
      await setPrice(mrp);
      await form.setFieldsValue({ variantMrp: mrp });
      await setSellingPrice(mrp);
      await form.setFieldsValue({ variantSellingPrice: mrp });
      await setPercentage("");
      await form.setFieldsValue({ variantDiscount: "" });
      // await props.editVarient(props.priceStock.variant, "sellingPrice", mrp);
      // await props.editVarient(props.priceStock.variant, "discount", 0);
      // props.editVarient(props.priceStock.variant, "price", mrp);
    } else {
      const fields = [
        { variant: props.priceStock.variant, type: "sellingPrice", value: 0 },
        { variant: props.priceStock.variant, type: "discount", value: 0 },
        { variant: props.priceStock.variant, type: "price", value: 0 },
      ];
      updateMultipleField(fields);
      await setPrice("");
      await form.setFieldsValue({ variantMrp: "" });
      await setSellingPrice("");
      await form.setFieldsValue({ variantSellingPrice: "" });
      await setPercentage("");
      await form.setFieldsValue({ variantDiscount: "" });
      // await props.editVarient(props.priceStock.variant, "sellingPrice", 0);
      // await props.editVarient(props.priceStock.variant, "discount", 0);
      // props.editVarient(props.priceStock.variant, "price", 0);
    }
  };

  const handleChangeDiscount = async (discount) => {
    if (discount) {
      const fields = [
        {
          variant: props.priceStock.variant,
          type: "sellingPrice",
          value: percentageOf(price, discount),
        },
        {
          variant: props.priceStock.variant,
          type: "discount",
          value: Number(discount),
        },
      ];
      updateMultipleField(fields);
      await setPercentage(discount);
      await form.setFieldsValue({ variantDiscount: discount });
      await setSellingPrice(percentageOf(price, discount));
      await form.setFieldsValue({
        variantSellingPrice: percentageOf(price, discount),
      });
      // await props.editVarient(
      //   props.priceStock.variant,
      //   "sellingPrice",
      //   percentageOf(price, discount)
      // );
      // props.editVarient(props.priceStock.variant, "discount", Number(discount));
    } else {
      console.log("------>", discount);
      const fields = [
        {
          variant: props.priceStock.variant,
          type: "sellingPrice",
          value: percentageOf(price, 0),
        },
        {
          variant: props.priceStock.variant,
          type: "discount",
          value: 0,
        },
      ];
      updateMultipleField(fields);

      await setPercentage(0);
      await form.setFieldsValue({ variantDiscount: 0 });
      await setSellingPrice(percentageOf(price, 0));
      await form.setFieldsValue({
        variantSellingPrice: percentageOf(price, 0),
      });
      // await props.editVarient(
      //   props.priceStock.variant,
      //   "sellingPrice",
      //   percentageOf(price, 0)
      // );
      // props.editVarient(props.priceStock.variant, "discount", 0);
    }
  };

  useEffect(() => {
    findPlatformCharge();
  }, [sellingPrice]);

  const findPlatformCharge = () => {
    if (!isNaN(sellingPrice)) {
      let tempPc = sellingPrice * (categoryCommission / 100);
      setPlatformCharge(tempPc.toFixed(2));
    }
  };

  const handleChangeSellingPrice = async (finalprice) => {
    if (finalprice) {
      const fields = [
        {
          variant: props.priceStock.variant,
          type: "sellingPrice",
          value: finalprice,
        },
        {
          variant: props.priceStock.variant,
          type: "discount",
          value: getPercentage(finalprice, price),
        },
      ];
      updateMultipleField(fields);

      await setSellingPrice(percentageOf(finalprice));
      await form.setFieldsValue({ variantSellingPrice: finalprice });
      await setPercentage(getPercentage(finalprice, price));
      await form.setFieldsValue({
        variantDiscount: getPercentage(finalprice, price),
      });
      // await props.editVarient(
      //   props.priceStock.variant,
      //   "sellingPrice",
      //   finalprice
      // );
      // props.editVarient(
      //   props.priceStock.variant,
      //   "discount",
      //   getPercentage(finalprice, price)
      // );
    } else {
      const fields = [
        {
          variant: props.priceStock.variant,
          type: "sellingPrice",
          value: 0,
        },
        {
          variant: props.priceStock.variant,
          type: "discount",
          value: 0,
        },
      ];
      updateMultipleField(fields);

      await setSellingPrice("");
      await form.setFieldsValue({ variantSellingPrice: "" });
      await setPercentage("");
      await form.setFieldsValue({ variantDiscount: "" });
      // await props.editVarient(props.priceStock.variant, "sellingPrice", 0);
      // props.editVarient(props.priceStock.variant, "discount", 0);
    }
  };

  // useEffect(() => {
  //   props.variantList !== undefined &&
  //     console.log(props.variantList, ":this is variant list ");
  // }, [props.variantList]);

  const updateBarcode = () => {
    const payload = {
      productId: productId,
      oldBarcode: currentBarcode,
      newBarcode:
        newBarcode != null && newBarcode.length > 0
          ? newBarcode
          : currentBarcode,
      unit: unit,
      quantity: quantity,
    };
    updateBatchBarcode(payload)
      .then((response) => {
        if (response?.data?.status === "success") {
          setNewBarcode("");
          props.setEdit(true);
          props.editVarient(
            props.priceStock.variant,
            "barcode",
            newBarcode != null && newBarcode.length > 0 ? newBarcode : barcode
          );
          getProductDetails();
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch(() => {})
      .finally(() => {
        setShowUpdateBarcode(false);
      });
  };

  useEffect(() => {
    if (
      (quantity !== "" && unit !== "") ||
      (selectedQuantity !== "" && selectedUnit !== "")
    ) {
      const payload = {
        centralRepoProductId: centralRepoId,
        quantity: String(
          selectedQuantity !== ""
            ? typeof selectedQuantity !== "undefined"
              ? selectedQuantity
              : quantity
            : quantity
        ),
        unit:
          selectedUnit !== ""
            ? typeof selectedUnit !== "undefined"
              ? selectedUnit
              : unit
            : unit,
      };

      if (centralRepoId === null) {
        setGeneratingBarcodeLoading(true);
      }

      //if the payload doesn't contain quantity, unit and centralReporId
      if (
        !payload.quantity ||
        !payload.unit ||
        !payload.centralRepoProductId ||
        payload.quantity === "null"
      ) {
        console.log("Hai -->", payload);
        return;
      }
      setGeneratingBarcodeLoading(true);
      fetchBarcodeWithVariant(payload)
        .then((response) => {
          if (response?.data?.status === "failed") {
            if (response?.data?.errorCode === "NOT_FOUND") {
              setEnableGenerateBarcode(false);
              // if (barcode === "") {
              //   setBarcode("");
              //   props.editVarient(props.priceStock.variant, "barcode", "");
              // }
            } else {
              message.error(response?.data?.message);
              setEnableGenerateBarcode(true);
            }
          } else {
            if (!editBarcode) {
              setBarcode(response?.data?.info?.barcode);
              // props.editVarient(
              //   props.priceStock.variant,
              //   "barcode",
              //   response?.data?.info?.barcode
              // );
              setEnableGenerateBarcode(true);
            } else {
              if (barcode === "") {
                setEnableGenerateBarcode(false);
              } else {
                setEnableGenerateBarcode(true);
              }
            }
          }
        })
        .catch()
        .finally(() => {
          setGeneratingBarcodeLoading(false);
        });
      // setEnableGenerateBarcode(false);
    } else {
      setEnableGenerateBarcode(true);
    }
  }, [selectedQuantity, selectedUnit, quantity, unit]);

  const generateBarcode = () => {
    if (
      (quantity !== "" && unit !== "") ||
      (selectedQuantity !== "" && selectedUnit !== "")
    ) {
      setGeneratingBarcodeLoading(true);
      generateNewBarcode()
        .then((response) => {
          if (response?.data?.status === "success") {
            setBarcode(response?.data?.barcode);
            props.editVarient(
              props.priceStock.variant,
              "barcode",
              response?.data?.barcode
            );
            setEnableGenerateBarcode(true);
          }
        })
        .catch()
        .finally(() => {
          setGeneratingBarcodeLoading(false);
        });
    } else {
      message.error(
        "You need to Enter Quantity and Unit before Generating Barcode"
      );
    }
  };

  const generateBarcodeForChangeBarcode = () => {
    setGeneratingBarcodeLoading(true);
    generateNewBarcode()
      .then((response) => {
        if (response?.data?.status === "success") {
          setNewBarcode(response?.data?.barcode);
          setEnableGenerateBarcode(true);
        }
      })
      .catch()
      .finally(() => {
        setGeneratingBarcodeLoading(false);
      });
  };

  return (
    <div
      style={{
        borderBottom: "1px solid #f0f2f5",
        paddingBottom: "5px",
        marginBottom: "20px",
      }}
    >
      <Form form={form} name="stock-variant-form">
        <Row>
          {acceptBarcodeValues && (
            <>
              {props?.priceStock?.variant !== 1 && (
                <Button
                  className="remove-batch-button"
                  type="text"
                  onClick={() => removeBatch(props.priceStock.variant)}
                >
                  REMOVE
                </Button>
              )}
            </>
          )}
        </Row>
        {isThisAdmin && props.edit == false 
          && stockEditable && props.priceStock.stock !== 0 && (
          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="add-image-col">
              <div className="add-product-tile">
                <span className="required-field-asterisk">*</span>Type
                <Select
                  onSelect={(value, option, info) => {
                    setBatchType(option.value);
                    let item = typeVariants.find((item) => item.type == option.value)
                    setvariantOptions(item.variants)
                    setunit(item.variants[0])
                    const fields = [
                      {
                        variant: props.priceStock.variant,
                        type: "unit",
                        value: item.variants[0],
                      },
                      {
                        variant: props.priceStock.variant,
                        type: "type",
                        value: option.value,
                      },
                    ];
                    updateMultipleField(fields);
                  }}
                  // onChange={(value, info) => console.log(info)}
                  value={batchType}
                  style={{ width: "100%" }}
                >
                  {typeList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className="add-image-col">
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>Quantity
              <Form.Item
                name="variantQuantity"
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                  {
                    pattern: new RegExp("^[0-9.]+$"),
                    message: "Enter a valid number !",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  className="stock-variant-input"
                  value={quantity}
                  disabled={
                    editBarcode
                      ? true
                      : stockEditable
                      ? !props.edit && props.priceStock.stock !== 0
                        ? false
                        : true
                      : false
                  }
                  onChange={(value) => {
                    if (value >= 0) {
                      if (value === 0) {
                        props.editVarient(
                          props.priceStock.variant,
                          "quantity",
                          "0"
                        );
                        setquantity(0);
                      } else {
                        props.editVarient(
                          props.priceStock.variant,
                          "quantity",
                          value
                        );
                        setReloadInputs((prev)=> !prev);
                        setquantity(value);
                      }
                      form.setFieldsValue({ variantQuantity: value });
                    } else {
                      props.editVarient(
                        props.priceStock.variant,
                        "quantity",
                        ""
                      );
                      setquantity("");
                    }
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className="add-image-col">
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>Unit
              <Select
                disabled={
                  editBarcode
                    ? true
                    : stockEditable
                    ? !props.edit && props.priceStock.stock !== 0
                      ? false
                      : true
                    : false
                }
                onSelect={(value, option, info) => {}}
                onChange={(value, info) => {
                  props.editVarient(props.priceStock.variant, "unit", value);
                  setunit(value);
                  setReloadInputs((prev) => !prev);
                  if(props.edit) {
                    setBatchType(typeVariants.find(item => item.variants.includes(value))?.type)
                  }
                }}
                value={unit}
                // defaultValue={
                //   props?.priceStock?.unit && props.priceStock.unit
                // }
                style={{ width: "100%" }}
              >
                {props.variantList &&
                  props.variantList.map((item) => (
                    <Option value={item}>{item}</Option>
                  ))}
                {variantOptions?.map((item) => (
                  <Option value={item}>{item}</Option>
                ))}

                {/* <Option value="kgs">kgs</Option>
                            <Option value="grm">grm</Option>
                            <Option value="liter">liter</Option>
                            <Option value="milli liter">milli liter</Option> */}
              </Select>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>Stock
              <Form.Item
                name="variantStock"
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                  {
                    pattern: new RegExp("^[0-9]+$"),
                    message: "Enter a valid number !",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  className="stock-variant-input"
                  // min={0}
                  value={stock}
                  // defaultValue={props.priceStock.stock}
                  disabled={
                    isThisAdmin
                      ? !props?.edit
                        ? false
                        : true
                      : stockEditable && true
                  }
                  onBlur={() => {
                    if (editBarcode) {
                      props.editVarient(
                        props.priceStock.variant,
                        "barcode",
                        barcode
                      );
                    }
                  }}
                  onFocus={() => {
                    if (editBarcode) {
                      props.editVarient(
                        props.priceStock.variant,
                        "quantity",
                        selectedQuantity
                      );
                    }
                  }}
                  onChange={(value) => {
                    if (value >= 0) {
                      props.editVarient(
                        props.priceStock.variant,
                        "stock",
                        value
                      );

                      setstock(value);
                      form.setFieldsValue({ variantStock: value });
                    } else {
                      props.editVarient(props.priceStock.variant, "stock", "");

                      setstock("");
                    }
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className="add-image-col">
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>MRP
              <Form.Item
                name="variantMrp"
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                  {
                    pattern: new RegExp("^[1-9]+[0-9]*[.]*[0-9]*$"),
                    message: "Enter a valid number !",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  className="stock-variant-input"
                  // min={1}
                  addonBefore="₹"
                  //   defaultValue={props.priceStock.price}
                  value={price}
                  onFocus={() => {
                    if (editBarcode) {
                      props.editVarient(
                        props.priceStock.variant,
                        "unit",
                        selectedUnit
                      );
                    }
                  }}
                  disabled={
                    stockEditable
                      ? !props.edit && props.priceStock.stock !== 0
                        ? false
                        : true
                      : false
                  }
                  onChange={(value) => {
                    // setPrice(value);
                    // setSellingPrice(percentageOf(value, percentage));
                    // setPercentage(getPercentage(sellingPrice, value));
                    // props.editVarient(
                    //   props.priceStock.variant,
                    //   "price",
                    //   value
                    // );
                    if (value >= 1) {
                      handleChangeMRP(value);
                    } else {
                      props.editVarient(props.priceStock.variant, "price", "");
                      setPrice("");
                    }
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className="add-image-col">
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>Discount
              percentage
              <Form.Item
                name="variantDiscount"
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                  {
                    pattern: new RegExp("^(0|[1-9])[0-9]?$"),
                    message: "Enter a valid number below 100 !",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  className="stock-variant-input"
                  // min={1}
                  //   defaultValue={props.priceStock.discount}
                  value={percentage}
                  disabled={
                    stockEditable
                      ? !props.edit && props.priceStock.stock !== 0
                        ? false
                        : true
                      : false
                  }
                  onChange={(value) => {
                    // setPercentage(value);
                    // setSellingPrice(percentageOf(price,value));
                    // props.editVarient(
                    //   props.priceStock.variant,
                    //   "discount",
                    //   value
                    // );
                    handleChangeDiscount(value);
                  }}
                />
              </Form.Item>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>Selling price
              <Form.Item
                name="variantSellingPrice"
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                  {
                    pattern: new RegExp("^[1-9]+[0-9]*[.]*[0-9]*$"),
                    message: "Enter a valid number !",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  className="stock-variant-input"
                  // min={1}
                  addonBefore="₹"
                  //   defaultValue={props.priceStock.sellingPrice}
                  disabled={
                    stockEditable
                      ? !props.edit && props.priceStock.stock !== 0
                        ? false
                        : true
                      : false
                  }
                  value={sellingPrice}
                  onChange={(value) => {
                    // props.editVarient(
                    //   props.priceStock.variant,
                    //   "sellingPrice",
                    //   value
                    // );
                    // setSellingPrice(percentageOf(price, percentage));
                    // setPercentage(getPercentage(value, price));
                    if (value >= 1) {
                      handleChangeSellingPrice(value);
                    } else {
                      props.editVarient(
                        props.priceStock.variant,
                        "sellingPrice",
                        ""
                      );
                      setSellingPrice("");
                    }
                  }}
                />
                {sellingPrice && (
                  <Text style={{ color: "#3e6df3" }}>P.C {platformCharge}</Text>
                )}
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className="add-image-col">
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>Weight In Kg
              <Form.Item
                name="variantWeight"
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                  {
                    pattern: new RegExp("^[0-9]*[1-9.]+[0-9]*$"),
                    message: "Enter a valid number !",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  className="stock-variant-input"
                  // min={0}
                  value={weightInkg}
                  disabled={
                    stockEditable
                      ? !props.edit && props.priceStock.stock !== 0
                        ? false
                        : true
                      : false
                  }
                  onChange={(value) => {
                    if (value > 0) {
                      setWeightInkg(value);
                      form.setFieldsValue({ variantWeight: value });
                      // setSellingPrice(percentageOf(value, percentage));
                      // setPercentage(getPercentage(sellingPrice, value));
                      props.editVarient(
                        props.priceStock.variant,
                        "weightInKg",
                        value
                      );
                    } else {
                      setWeightInkg("");
                      props.editVarient(
                        props.priceStock.variant,
                        "weightInKg",
                        ""
                      );
                    }
                  }}
                />
              </Form.Item>
            </div>
          </Col>{" "}
          <Col xs={12} sm={12} md={8} lg={8} xl={8} className="add-image-col">
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>Dealer Price
              <Form.Item
                name="variantDealerPrice"
                rules={[
                  {
                    required: true,
                    message: "This field is required !",
                  },
                  {
                    pattern: new RegExp("^[0-9.]+$"),
                    message: "Enter a valid number !",
                  },
                ]}
              >
                <InputNumber
                  type="number"
                  className="stock-variant-input"
                  // min={0}
                  value={dealerPrice}
                  disabled={
                    stockEditable
                      ? !props.edit && props.priceStock.stock !== 0
                        ? false
                        : true
                      : false
                  }
                  onChange={(value) => {
                    setdealerPrice(value);
                    form.setFieldsValue({ variantDealerPrice: value });
                    props.editVarient(
                      props.priceStock.variant,
                      "dealerPrice",
                      value
                    );
                  }}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col className="add-image-col">
            <div className="add-product-tile">
              <span className="required-field-asterisk">*</span>Barcode
              <span
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 0.2fr ",
                  gap: "0.5em",
                }}
              >
                <Input
                  defaultValue={currentBarcode}
                  className="stock-variant-input"
                  value={currentBarcode}
                  disabled={
                    !acceptBarcodeValues
                      ? true
                      : stockEditable
                      ? !props.edit && props.priceStock.stock !== 0
                        ? false
                        : true
                      : false
                  }
                  onChange={(e) => {
                    setBarcode(e.target.value);
                    props.editVarient(
                      props.priceStock.variant,
                      "barcode",
                      e.target.value
                    );
                  }}
                />
                {acceptBarcodeValues && (
                  <Button
                    disabled={enableGenerateBarcode}
                    onClick={() => generateBarcode()}
                    loading={generatingBarcodeLoading}
                  >
                    {generatingBarcodeLoading
                      ? "Barcode Searching"
                      : "Generate Barcode"}
                  </Button>
                )}

                {!acceptBarcodeValues && (
                  <Button
                    type="primary"
                    onClick={() => setShowUpdateBarcode(true)}
                    disabled={
                      stockEditable
                        ? !props.edit && props.priceStock.stock !== 0
                          ? false
                          : true
                        : false
                    }
                  >
                    Change Barcode
                  </Button>
                )}
              </span>
            </div>
          </Col>
        </Row>
      </Form>
      <Modal
        title="Update Barcode"
        visible={showUpdateBarcode}
        onOk={() => {
          updateBarcode();
        }}
        onCancel={() => {
          setShowUpdateBarcode(false);
        }}
      >
        <h4>
          Current Barcode : <strong>{currentBarcode}</strong>
        </h4>
        <p>New Barcode</p>
        <Row>
          <Col xs={12} xl={8} className="add-image-col">
            <Input
              placeholder="New Barcode"
              className="stock-variant-input"
              value={newBarcode}
              onChange={(e) => {
                setNewBarcode(e.target.value);
              }}
              autoFocus={true}
            />
          </Col>
          <Col>
            <Button
              disabled={currentBarcode === "" ? false : true}
              onClick={() => generateBarcodeForChangeBarcode()}
              loading={generatingBarcodeLoading}
            >
              {generatingBarcodeLoading
                ? "Barcode Searching"
                : "Generate Barcode"}
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export default StockVarient;
