

import Axios from "../axios";
import { API_SERV_ADDR } from "../../Utils/constants";

// export const fetchSalesReport = (payload) => {
//   return Axios({
//     url: `${API_SERV_ADDR}/report/list-enhanced-sales-report`,
//     method: "POST",
//     data: payload,
//   });
// };
export const fetchSalesReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/list-sales-report`,
    method: "POST",
    data: payload,
  });
};

export const exportSalesReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-sales-report`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

export const exportWorkReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-work-report`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

export const listAutoApprovedProducts = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/list-auto-approved-report`,
    method: "POST",
    data: payload,
  });
};

export const exportAutoApprovedProducts = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-auto-approved-report`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

export const listAffiliateChargeReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/affiliate-payment-report`,
    method: "POST",
    data: payload,
  });
};

export const exportAffiliateChargeReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-affiliate-payment-report`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

export const fetchStocksReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/list-stock-report`,
    method: "POST",
    data: payload,
  });
};

export const exportStocksReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-stock-report`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

//fetch search report by search key
export const fetchSearchReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/list-search-report`,
    method: "POST",
    data: payload,
  });
};

export const exportSearchReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-search-report`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

//fetchSearch report by productName
export const fetchSearchReportByProduct = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/list-product-view-report`,
    method: "POST",
    data: payload,
  });
};

export const exportSearchReportByProduct = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-product-view-report`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

//API related to expiry report
export const fetchExpiryReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/list-expiry-report`,
    method: "POST",
    data: payload,
  });
};

export const exportExpiryReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-expiry-report`,
    method: "POST",
    responseType: "blob",
    data: payload,
  });
};

export const regenerateExpiryReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-expiry-report-using-vendorId`,
    method: "POST",
    data: payload,
  });
};

export const handleBatchVisiblity = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/enable-disable/product-batch`,
    method: "POST",
    data: payload,
  });
};

export const fetchDetailedStockReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/fetch-stock-report`,
    method: "POST",
    data: payload,
  });
};

export const exportDetailedStockReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-fetch-stock-report`,
    method: "POST",
    data: payload,
    responseType: "blob",
  });
};

export const fetchDayBookReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/list-day-book-report`,
    method: "POST",
    data: payload
  })
};

export const exportDayBookReport = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/report/export-day-book-report`,
    method: "POST",
    responseType: "blob",
    data: payload
  })
}