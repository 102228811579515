

import React, { useState, useEffect, useContext } from "react";

import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { message, Card, Typography, Button, Select, Modal } from "antd";
import { FILE_PATH, ENV_TYPE } from "../../Utils/constants";

import PlaceholderImage from "../../Assets/Images/PlaceHolderImages/Product_Image_Placeholder.jpg";

import "./productCard.scss";
import QuantityController from "./components/QuantityController";
import { addToCart } from "../../ApiAction/SellerApiActions/sellerApi";
import { openLoginModal } from "../../ReduxStore/action/authModalActions";
import { fetchCartCount, fetchCartItems } from "../../ReduxStore/action";
import { checkAvailiablity } from "../../ApiAction/CustomerApiActions/productApi";
import { AppContext } from "../GeneralComponents/appContext";

const { Text } = Typography;
const { Option } = Select;
const warning = Modal.warning;

const ProductCard = (props) => {
  const cookies = new Cookies();
  const { cardWidth, product, handleAddToCart, loading } = props;
  const [activeCustomer, setactiveCustomer] = useState(
    JSON.parse(localStorage.getItem("activeCustomer"))
  );
  const [quantity, setQuantity] = useState(1);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [processedPrice, setProcessedPrice] = useState();
  const [price, setPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [salesIncentive, setsalesIncentive] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [selectedItem, setSelectedItem] = useState();

  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [inCart, setinCart] = useState(false);
  const ProviderProps = useContext(AppContext);

  const accountType = cookies.get("accountType")
    ? cookies.get("accountType")
    : "customer";

  useEffect(() => {
    setProcessedPrice(product.processedPrice);
    if (product.processedPrice && product.processedPrice.length > 0) {
      setSelectedItem(product.processedPrice[0]);
        setPrice(product.processedPrice[0].price);
        setSellingPrice(product.processedPrice[0].sellingPrice);
        // setsalesIncentive(product.processedPrice[0]?.salesIncentive);
        setSelectedVariant(product.processedPrice[0].serialNumber);
        setAvailableQuantity(product.processedPrice[0]?.stock);
        setSelectedQuantity(
          `${product.processedPrice[0].quantity} ${product.processedPrice[0].unit}`
        );
        setSelectedDiscount(product.processedPrice[0].discount);
    }
  }, [product]);
  function handleChange(value, info) {
    const selected = processedPrice.find(
      (item) => item.serialNumber == info.key
    );
    setQuantity(1);

    setSelectedItem(selected);
    setPrice(selected.price);
    setSellingPrice(selected.sellingPrice);
    // setsalesIncentive(selected?.salesIncentive);
    setSelectedVariant(selected.serialNumber);
    setAvailableQuantity(selected.stock);
    setSelectedQuantity(`${selected.quantity} ${selected.unit}`);
    // setSelectedQuantity(selected.quantity + selected.unit);
    setSelectedDiscount(selected.discount);
  }

  //function to handle add to cart function on both web view and mobile view
  const handleProductAddToCart = (product) => {
    if (window.innerWidth <= 767) {
      //for the case of mobile  view
      handleAddToCart({ visible: true, productDetails: product });
    }
  };

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s
      .trim()
      .toLowerCase()
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  };

  //to add selected productName to bread crumb
  const handleManageBreadCrumb = (data) => {
    let breadCrumbList = localStorage.getItem("breadCrumbData")
      ? JSON.parse(localStorage.getItem("breadCrumbData"))
      : [];
    breadCrumbList.push(data);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  const handleRedirection = () => {
    if (accountType === "store") {
      props.history.push(
        `/store/linked-warehouse/product-detail/${product.productId}`
      );
    } else if (accountType === "customer") {
      props.history.push(
        ENV_TYPE === "production"
          ? `/product-detail/${product.productId}`
          : `/customer/product-detail/${product.productId}`
      );
    } else if (accountType === "warehouse") {
      props.history.push(
        `/warehouse/linked-warehouse/product-detail/${product.productId}`
      );
    }

    const data = {
      label: product.productName,
      url:
        ENV_TYPE === "production"
          ? `/product-detail`
          : `/customer/product-detail`,
      type: "product",
      id: product.id,
    };
    handleManageBreadCrumb(data);
  };

  const handleCustomerAddToCart = () => {
    const paylaod = {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
      item: {
        productId: product.productId,

        vendorId: product.vendorId,
        vendorType: product.vendorType,
        quantity: Math.abs(quantity),
        itemCurrentPrice: selectedItem,
      },
    };

    addToCart(paylaod)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.fetchCartCount(props.cartCountStore + 1);
            message.success(response.data.message);
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const addTocartProduct = async () => {

    const isValidPincode = (ProviderProps.nearestPincode? product.serviceLocations.includes(ProviderProps.nearestPincode) :
      product.serviceLocations.includes(ProviderProps.pinCode)) || product.serviceLocations.includes("All India");

    if (availableQuantity > 0) {
      if (activeCustomer && cookies.get("accountType") && isValidPincode) {
        if (props.cartItems) {
          // const isAlreadyExists = await props.cartItems.find((item) => {
          //   return (
          //     item.productId == product.productId &&
          //     item.serialNumber === selectedItem.serialNumber
          //   );
          // });

          if (inCart) {
            // message.warning("Product already added to cart");
            props.history.push(
              ENV_TYPE === "production" ? `/cart` : `/customer/cart`
            );
          } else {
            handleCustomerAddToCart();
          }
        } else handleCustomerAddToCart();
      } else {
        if (cookies.get("activeUserPincode") || product.serviceLocations.includes("All India")) {
          if(isValidPincode)
            handleUpdateGuestCart();
          else
            message.error("Product not available at set pincode")
        } else {
          ProviderProps.setPincodeModalVisible(true);
          ProviderProps.setProduct(product);
          ProviderProps.setSelectedItem(selectedItem);
          ProviderProps.setQuantity(quantity);
          // handleShowWarning();
        }
      }
    } else message.warning("Product out of stock");
  };

  const handleShowWarning = () => {
    warning({
      title: "Please enter valid Pincode",
      className: "create-confirm-modal",
    });
  };

  const handleUpdateGuestCart = async () => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart"));
    const temp = {
      productId: product.productId,
      productName: product.productName,
      vendorId: product.vendorId,
      vendorType: product.vendorType,
      quantity: quantity,
      image: product.productImage,
      itemCurrentPrice: selectedItem,
      processedPriceAndStocks: product.processedPrice,
      itemId: product.productId + selectedItem.serialNumber,
    };
    console.log(temp, guestCart, "guest cart validation");
    if (guestCart) {
      // const isAlreadyExists = await guestCart.find((item) => {
      //   return (
      //     item.productId == temp.productId &&
      //     item.itemCurrentPrice.serialNumber ===
      //       temp.itemCurrentPrice.serialNumber
      //   );
      // });

      if (inCart) {
        // message.warning("Product already added to cart");
        props.history.push(
          ENV_TYPE === "production" ? `/cart` : `/customer/cart`
        );
      } else {
        localStorage.setItem("guestCart", JSON.stringify([...guestCart, temp]));
        message.success("Product added to cart");
        //this part of code is for calculating guest cart count
        const cartCount = guestCart.reduce((prev, cur) => {
          return prev + cur.quantity;
        }, 0);
        props.fetchCartCount(cartCount + temp.quantity);
      }
    } else {
      localStorage.setItem("guestCart", JSON.stringify([temp]));
      message.success("Product added to cart");
      //this part of code is for calculating guest cart count
      props.fetchCartCount(temp.quantity);
    }
  };

  useEffect(() => {}, [imageLoadError]);

  const handleCheckInCart = async () => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart"));
    let isAlreadyExists;

    if (guestCart) {
      isAlreadyExists = await guestCart.find((item) => {
        return (
          item.productId == product.productId &&
          item.itemCurrentPrice.serialNumber === selectedItem.serialNumber
        );
      });
    } else if (props.cartItems) {
      isAlreadyExists = await props.cartItems.find((item) => {
        return (
          item.productId == product.productId &&
          item.serialNumber === selectedItem.serialNumber
        );
      });
    }

    if (isAlreadyExists) setinCart(true);
    else setinCart(false);
  };

  useEffect(() => {
    if (selectedItem) {
      handleCheckInCart();
    }
  }, [
    localStorage.getItem("guestCart"),
    product,
    props.cartItems,
    selectedItem,
  ]);

  return (
    // <a href="#prodduct-detail-page-conatiner-id">

    <Card
      loading={loading ? true : false}
      hoverable
      className="product-card"
      style={{ borderRadius: "8px" }}
      // style={{ width: cardWidth ? cardWidth : 200 }}
      cover={
        !imageLoadError &&
        (product?.productImage || product?.productImage2) &&
        !loading ? (
          <>
            <img
              alt={
                product && product.productName
                  ? capitalize(product.productName)
                  : "company"
              }
              src={
                product?.productImage
                  ? FILE_PATH + product.productImage
                  : product.productImage2
              }
              onError={() => {
                setImageLoadError(true);
              }}
              onClick={() => {
                handleRedirection();
                window.scrollTo(0, 0);
              }}
              className="product-list-image"
            />
            <p className="discount_title discount_with_tail">
              {Number(selectedDiscount) % 1 !== 0
                ? Number(parseInt(selectedDiscount))
                : Number(selectedDiscount)}
              % OFF
            </p>
          </>
        ) : (
          // <div className="pdt_category_image" src={PlaceholderImage} onClick={() => handleRedirection()} style={{backgroundImage: "url(" + PlaceholderImage + ")"}}></div>
          <>
          <img
            alt="company"
            src={PlaceholderImage}
            className="placehold_image"
            onClick={() => handleRedirection()}
          />
          <p className="discount_title discount_with_tail">
              {Number(selectedDiscount) % 1 !== 0
                ? Number(parseInt(selectedDiscount))
                : Number(selectedDiscount)}
              % OFF
            </p>
            </>
        )
      }
    >
      <Text className="product-name-ellipsis">
        {product?.productName ? product.productName : "Product Name"}{" "}
      </Text>
      <div className="product-card-des">
        <div className="product-card-des-line1">
          <Text className="actual_price">
            ₹ {Number(sellingPrice).toFixed(2)}
          </Text>
          <Text className="prev_price" type="secondary" delete>
            ₹ {Number(price).toFixed(2)}
          </Text>
        </div>
        <div className="product-card-des-line1">
          <Text className="weight_title">{selectedQuantity}</Text>
        </div>

        {/* {salesIncentive && cookies.get("accountType") == "customer" && (
          <div className="product-card-res2">
            <Text>SI: </Text>
            <Text> ₹ {salesIncentive.toFixed(2)}</Text>
          </div>
        )} */}
      </div>

      <div className="product_cart_data">
        <div className="product-cart-action">
          <Select
            size={"small"}
            value={selectedQuantity}
            onChange={handleChange}
            className="product-cart-action-select"
          >
            {processedPrice &&
              processedPrice.map((item) => (
                <Option
                  value={`${item.quantity}  ${item.unit}`}
                  key={item.serialNumber}
                >
                  {`${item.quantity}  ${item.unit}`}
                </Option>
              ))}
          </Select>
          {/* {!props.linkedWarehouse && ( */}
          <QuantityController
            quantity={quantity}
            stock={availableQuantity}
            update={true}
            setQuantity={(value) => setQuantity(value)}
          />
          {/* )} */}
        </div>
        {/* {!props.linkedWarehouse && ( */}
        <Button
          onClick={() => addTocartProduct()}
          className="add-to-cart-button"
        >
          {inCart ? "Go To Cart" : "Add To Cart"}
          {/* Add To Cart */}
        </Button>
        {/* )} */}
      </div>
    </Card>
    // </a>
  );
};

const mapDispatchToProps = { openLoginModal, fetchCartCount };
const mapStateToProps = (state) => {
  return {
    cartCountStore: state.cartCountReducer,
    pinCode: state.productReducer.pinCode,
    cartItems: state.cartItemsReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductCard)
);
