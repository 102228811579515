import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "antd";
import ReactToPrint from "react-to-print";
import "./ThermalPrinter.scss";
import Barcode from "react-barcode";
import LogoRecipet from "../../../../../../Assets/Images/Logo/C_Logo.png";
import moment from "moment";
import { connect } from "react-redux";
import { fetchCompanyDetails } from "../../../../../../ReduxStore/action";

function ThermalIndex(props) {
  const componentRef = useRef();
  const { orderSummary, setvisible, companyDetails } = props;
  const [vendorType, setVendorType] = useState();
  const [multiWindow, setMultiWindow] = useState(false);

  //* For getting vendorType
  useEffect(() => {
    let fetchedType = orderSummary?.orderedItems?.map((data) => {
      return data?.vendorType;
    });
    setVendorType(fetchedType[0]);
    // to get multiWindow
    let vendorWindow = orderSummary?.orderedItems[0]?.windowType;
    if (vendorWindow === "multiWindow") {
      setMultiWindow(true);
    } else {
      setMultiWindow(false);
    }
  }, []);

  //* For rendering Invoice header
  const renderHeading = () => {
    return (
      <div className="thermal-printer-heading">
        {vendorType === "STORE" ? (
          <Fragment>
            {multiWindow ? (
              <Fragment>
                {orderSummary?.orderedItems
                  ? orderSummary?.orderedItems[0]?.vendorAddress && (
                      <Fragment>
                        <h1 className="thermal-printer-text-900 thermal-printer-text-center">
                          {
                            orderSummary?.orderedItems[0]?.vendorAddress
                              ?.ownerName
                          }
                        </h1>
                        <h2 className="thermal-printer-text-800 thermal-printer-text-center">
                          {
                            orderSummary?.orderedItems[0]?.vendorAddress
                              .addressLine1
                          }
                        </h2>
                        <h2 className="thermal-printer-text-800 thermal-printer-text-center">
                          {
                            orderSummary?.orderedItems[0]?.vendorAddress
                              .addressLine2
                          }
                          ,{orderSummary?.orderedItems[0]?.vendorAddress.state},{" "}
                          {orderSummary?.orderedItems[0]?.vendorAddress.pinCode}
                        </h2>
                        <h2 className="thermal-printer-text-800 thermal-printer-text-center">
                          GST IN :{" "}
                          {orderSummary?.orderedItems[0]?.vendorGstNumber}
                        </h2>
                        <h2 className="thermal-printer-text-800 thermal-printer-text-center">
                          Ph:
                          {orderSummary?.orderedItems[0]?.vendorAddress.phoneNo}
                          {orderSummary?.orderedItems[0]?.vendorAddress.emailId}
                        </h2>
                      </Fragment>
                    )
                  : ""}
              </Fragment>
            ) : (
              <Fragment>
                <h1 className="thermal-printer-text-900 thermal-printer-text-center">
                  {companyDetails?.address?.ownerName}
                </h1>
                <h2 className="thermal-printer-text-800 thermal-printer-text-center">
                  {companyDetails?.address?.addressLine1}
                </h2>
                <h2 className="thermal-printer-text-800 thermal-printer-text-center">
                  {companyDetails?.address?.addressLine1}, {companyDetails?.address?.state}, {companyDetails?.address?.pinCode}
                </h2>
                <h2
                  className="thermal-printer-text-800
                thermal-printer-text-center"
                >
                  GST IN:{companyDetails?.gstNumber}
                </h2>
                <h2
                  className="thermal-printer-text-800
                thermal-printer-text-center"
                >
                  Ph: {companyDetails?.address?.phoneNo}
                </h2>
                <h2
                  className="thermal-printer-text-800
                thermal-printer-text-center"
                >
                  {companyDetails?.companyEmail}
                </h2>
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {orderSummary?.orderedItems
              ? orderSummary?.orderedItems[0]?.vendorAddress && (
                  <h2 className="thermal-printer-text-800">
                    {orderSummary?.orderedItems[0]?.vendorAddress?.ownerName}
                    <br></br>
                    {orderSummary?.orderedItems[0]?.vendorAddress?.addressLine1}
                    <br></br>
                    {orderSummary?.orderedItems[0]?.vendorAddress?.addressLine2}
                    ,{orderSummary?.orderedItems[0]?.vendorAddress?.state},{" "}
                    {orderSummary?.orderedItems[0]?.vendorAddress?.pinCode}
                    <br></br>
                    GST IN: {orderSummary?.orderedItems[0]?.vendorGstNumber}
                    <br></br>
                    Ph: {orderSummary?.orderedItems[0]?.vendorAddress?.phoneNo}
                    <br></br>
                    {orderSummary?.orderedItems[0]?.vendorAddress?.emailId}
                  </h2>
                )
              : ""}
          </Fragment>
        )}
      </div>
    );
  };

  return (
    <div className="thermal-printer-main-container">
      <div className="thermal-printer-container-modal">
        <div className="thermal-printer-close-area">
          <button
            className="thermal-printer-close-button"
            onClick={() => {
              setvisible(false);
              if (props.quickCheckout) {
                props.handleRemove(props.pane);
              }
            }}
          >
            X Close
          </button>
        </div>
        <div className="thermal-printer-invoice" ref={componentRef}>
          {renderHeading()}
          <div className="thermal-padding"></div>
          {!multiWindow && (
            <Fragment>
              <h2 className="thermal-printer-invoice-table-text-heading">
                {orderSummary?.orderedItems[0]?.vendorAddress?.ownerName}
              </h2>
              <h2 className="thermal-printer-text-800">
                {orderSummary?.orderedItems[0]?.vendorAddress?.addressLine1}
              </h2>
              <h2 className="thermal-printer-text-800">
                {orderSummary?.orderedItems[0]?.vendorAddress?.addressLine2},
                {orderSummary?.orderedItems[0]?.vendorAddress?.state},{" "}
                {orderSummary?.orderedItems[0]?.vendorAddress?.pinCode}
              </h2>
              <h2 className="thermal-printer-text-800">
                GST IN:
                {orderSummary?.orderedItems[0]?.vendorGstNumber}
              </h2>
              <h2 className="thermal-printer-text-800">
                Ph:{orderSummary?.orderedItems[0]?.vendorAddress?.phoneNo}<br></br>
                {orderSummary?.orderedItems[0]?.vendorAddress?.emailId}
              </h2>
            </Fragment>
          )}
          <div className="thermal-padding"></div>
          <div className="thermal-padding"></div>
          <h2 className="thermal-printer-text-800">
            CU.ID. : {orderSummary?.referralId}
          </h2>
          <h2 className="thermal-printer-text-800">
            Bill To :{" "}
            {orderSummary?.shippingAddress?.ownerName
              ? `${orderSummary?.shippingAddress?.ownerName}`
              : "Anonymous"}
          </h2>
          <div className="thermal-padding"></div>

          <h2 className="thermal-printer-text-800">
            INV..No.. : {orderSummary?.orderGroupId}
          </h2>
          <h2 className="thermal-printer-text-800">
            INV.Date :{" "}
            {moment(orderSummary?.orderedDate).format("DD-MM-YYYY")}
            {moment(orderSummary?.orderedDate).format("MMM DD, hh:mm A").substring(7)}
          </h2>
          <div className="thermal-padding"></div>
          <span className="thermal-printer-invoice-table-headline">
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned thermal-printer-item-name">
              Item Name
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned">
              {" "}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned">
              MRP
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned">
              Qty
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned">
              Rate
            </h2>
            {/* <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned">
              S.I
            </h2> */}
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned">
              HSN
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned">
              Total
            </h2>
          </span>
          {orderSummary?.orderedItems?.length > 0 &&
            orderSummary?.orderedItems?.map((product, index) => (
              <span className="thermal-printer-invoice-table-data" key={index}>
                <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-left-aligned thermal-printer-item-name">
                  <strong>
                    {"["}
                    {product.itemCurrentPrice.quantity}
                    {product.itemCurrentPrice.unit}
                    {"] "}
                  </strong>
                  {product.productName}
                </h2>
                <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                  {" "}
                </h2>
                <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                  {product.itemCurrentPrice.price.toFixed(2)}
                </h2>
                <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                  {product.quantity}
                </h2>
                <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                  {product.itemCurrentPrice.sellingPrice.toFixed(2)}
                </h2>
                <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                  {orderSummary.accountType === "customer" && (
                    // <td>
                    //   {(
                    //     product.itemCurrentPrice.salesIncentive *
                    //     product.quantity
                    //   ).toFixed(2)}
                    // </td>
                    product.hsnCode
                  )}
                </h2>
                <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                  {product.totalAmount.toFixed(2)}
                </h2>
              </span>
            ))}
          <span className="thermal-printer-invoice-table-data">
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-left-aligned thermal-printer-item-name">
              [<strong>Delivery Charge</strong>]
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {" "}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {" "}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {" "}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {" "}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {" "}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {orderSummary?.deliveryCharge
                ? orderSummary.deliveryCharge.toFixed(2)
                : "00.00"}
            </h2>
          </span>

          <div className="thermal-padding"></div>
          <div className="thermal-printer-dotted-line"></div>
          <div className="thermal-padding"></div>

          <span className="thermal-printer-invoice-table-data">
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              <strong>
                ({orderSummary?.orderedItems?.length})Item
                {orderSummary?.orderedItems?.length > 1 && "s"}
              </strong>
            </h2>
            {/* <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned"></h2> */}
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned thermal-big-text" style={{"grid-column": "7"}}>
              <strong>TOTAL</strong>{" "}
              <strong>₹{orderSummary?.totalPrice.toFixed(2)}</strong>
            </h2>
          </span>
          <span class="thermal-printer-invoice-table-data">
          <h2 className="thermal-printer-invoice-table-text-heading" style={{"grid-column": "1/3"}}>
            You Saved (₹{orderSummary?.totalDiscount.toFixed(2)})
          </h2>
          <h2 className="thermal-printer-invoice-table-text-data" style={{"grid-column": "4/7", "text-align": "right"}}>
              All Tax Included
          </h2>
          </span>
          <div className="thermal-padding"></div>
          <h2 className="thermal-printer-invoice-table-text-heading">
            Tax Summary
          </h2>
          <span className="thermal-printer-invoice-table-headline-tax-summary">
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned thermal-text-no-wrap">
              T.Price
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned thermal-text-no-wrap">
              CGST %
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned thermal-text-no-wrap">
              CGST
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned thermal-text-no-wrap">
              SGST %
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned thermal-text-no-wrap">
              SGST
            </h2>
            <h2 className="thermal-printer-invoice-table-text-heading thermal-printer-text-left-aligned thermal-text-no-wrap">
              T.T.Amount
            </h2>
          </span>
          {orderSummary?.taxSummaries.map((tax) => (
            <span className="thermal-printer-invoice-table-headline-tax-summary-data">
              <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                {(tax.taxablePrice - tax.taxValue).toFixed(2)}
              </h2>
              <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                {tax.tax / 2}
              </h2>
              <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                {(tax.taxValue / 2).toFixed(2)}
              </h2>
              <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                {tax.tax / 2}
              </h2>
              <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                {(tax.taxValue / 2).toFixed(2)}
              </h2>
              <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
                {tax.taxValue.toFixed(2)}
              </h2>
            </span>
          ))}
          <div className="thermal-printer-dotted-line"></div>
          <span className="thermal-printer-invoice-table-headline-tax-summary-data">
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-left-aligned">
              {(
                orderSummary.subTotal.toFixed(2) -
                orderSummary.totalTax.toFixed(2)
              ).toFixed(2)}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {""}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {(orderSummary.totalTax / 2).toFixed(2)}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {" "}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {(orderSummary.totalTax / 2).toFixed(2)}
            </h2>
            <h2 className="thermal-printer-invoice-table-text-data thermal-printer-text-right-aligned">
              {orderSummary.totalTax.toFixed(2)}
            </h2>
          </span>

          <div className="thermal-padding"></div>
          <div className="thermal-padding"></div>
          <div className="thermal-printer-barcode">
            <Barcode value={orderSummary?.orderGroupId} width="2" height="60" />
          </div>
          <div className="thermal-padding"></div>
          <h2 className="thermal-printer-text-400">
            <strong>Declaration :</strong> We declare that this invoice shows
            the actual price of the goods Authozied signatory Describe and that
            all particulars are true and correct
          </h2>
          <div className="thermal-printing-logos">
            Ordered Through
            <img src={LogoRecipet} className="thermal-printer-small-logo" />
          </div>
          <div className="thermal-padding"></div>
          <h1 className="thermal-printer-text-800 thermal-printer-text-center">
            Visit today {companyDetails?.companyUrl?.substring(8)}
          </h1>
        </div>
        <div className="thermal-padding"></div>
        <ReactToPrint
          trigger={() => <Button type="primary">Print Invoice</Button>}
          content={() => componentRef.current}
          onAfterPrint={() => {
            setvisible(false);
            if (props.quickCheckout) {
              props.handleRemove(props.pane);
            }
          }}
          onPrintError={(e) => console.log(e)}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    companyDetails: state.homePageReducer.companyDetails,
  };
};
const mapDispatchToProps = {
  fetchCompanyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThermalIndex);
