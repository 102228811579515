


import React, { useEffect, useState, useRef } from "react";
import "./manageContent.css";
import { Upload, Button, message, Input, Form } from "antd";
import Icon from "@ant-design/icons";
import Facebook from "../../../Assets/Images/Icons/facebook.svg";
import Instagram from "../../../Assets/Images/Icons/instagram.svg";
import Twitter from "../../../Assets/Images/Icons/twitter.svg";
import Youtube from "../../../Assets/Images/Icons/youtube.svg";
import { LinkOutlined } from "@ant-design/icons";

import UpdateContentEditor from "./updateContentEditor";
import {
  GetStaticFiles,
  UpdateContentFiles,
  AddContentFiles,
  addSocialMediaLinks,
  getSocialMediaLinks,
} from "../../../ApiAction/AdminApiAction/adminApi";

const ManageContent = (props) => {
  const { Dragger } = Upload;
  const [currentAboutUs, setcurrentAboutUs] = useState("");
  const [currentPrivacy, setcurrentPrivacy] = useState("");
  const [currentTerms, setcurrentTerms] = useState("");
  const [currentPayment, setcurrentPayment] = useState("");
  const [currentShipping, setCurrentShipping] = useState("");
  const [currentcookiesPolicy, setCurrentCookiesPolicy] = useState("");
  const [currentdisclaimer, setCurrentDisclaimer] = useState("");
  const [currentRefund, setCurrentRefund] = useState("");
  const [currentDnd, setCurrentDnd] = useState("");
  const [currentOnlinePayments, setCurrentOnlinePayments] = useState("");
  const [currentprevilageCustomer, setCurrentPrevilageCustomer] = useState("");

  const [aboutVersion, setAboutVersion] = useState();
  const [privacyVersion, setPrivacyVersion] = useState();
  const [termsVersion, setTermsVersion] = useState();
  const [paymentVersion, setPaymentVersion] = useState();

  const [about, setAbout] = useState();
  const [privacy, setPrivacy] = useState();
  const [terms, setTerms] = useState();
  const [payment, setPayment] = useState();
  const [shipping, setShipping] = useState();
  const [cookiesPolicy, setCookies] = useState("");
  const [disclaim, setDisclaim] = useState("");
  const [refund, setRefund] = useState("");
  const [dnd, setDnd] = useState("");
  const [onlinePayment, setOnlinePayment] = useState("");
  const [previlage, setPrevilage] = useState("");

  const [read, setread] = useState(true);
  const [editSocialMedia, setEditSocialMedia] = useState(true);

  const [aboutAdd, setAboutAdd] = useState(false);
  const [privacyAdd, setPrivacyAdd] = useState(false);
  const [termsAdd, setTermsAdd] = useState(false);
  const [paymentAdd, setPaymentAdd] = useState(false);
  const [shippingAdd, setShippingAdd] = useState(false);
  const [refundAdd, setRefundAdd] = useState(false);
  const [dndAdd, setDndAdd] = useState(false);
  const [onlinePaymentAdd, setOnlinePaymentAdd] = useState(false);
  const [cookiesPolicyAdd, setCookiesPolicyAdd] = useState("");
  const [disclaimerAdd, setDisclaimerAdd] = useState("");
  const [previlageCustomerAdd, setPrevilageCustomerAdd] = useState("");

  const [socialmediaItems, setScocialItems] = useState();

  const socialRef = useRef();
  const [form] = Form.useForm();
  const formFields = [
    { name: "facebook", placeHolder: "Facebook Link", icon: Facebook },
    { name: "instagram", placeHolder: "Instagram Link", icon: Instagram },
    { name: "twitter", placeHolder: "Twitter Link", icon: Twitter },
    { name: "youtube", placeHolder: "Youtube Link", icon: Youtube },
  ];

  useEffect(() => {
    getContents("aboutUs");
    getContents("privacyPolicy");
    getContents("termsAndCondition");
    getContents("returnPolicy");
    getContents("shippingPolicy");
    getContents("cookiesPolicy");
    getContents("disclaimer");
    getContents("refundPolicy");
    getContents("dndPolicy");
    getContents("onlinePayment");
    getContents("previlageCustomer");
    getSocialLinks();
  }, []);

  const getSocialLinks = () => {
    getSocialMediaLinks().then((res) => {
      if (res.status == 200) {
        socialRef.current.setFieldsValue({
          facebook: res?.data?.socialMediaProfiles?.facebook,
          instagram: res?.data?.socialMediaProfiles?.instagram,
          twitter: res?.data?.socialMediaProfiles?.twitter,
          youtube: res?.data?.socialMediaProfiles?.youtube
        });
        setScocialItems(res.data.socialMediaProfiles);
      }
    });
  };

  const getContents = (type) => {
    GetStaticFiles(type).then((response) => {
      if (response.status == 200) {
        if (type == "disclaimer") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setDisclaimerAdd(true);
          } else {
            setDisclaimerAdd(false);
            setCurrentDisclaimer(response.data.content.source.body);
            setDisclaim(response.data?.content?.id);
          }
        }
        if (type == "cookiesPolicy") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setCookiesPolicyAdd(true);
          } else {
            setCookiesPolicyAdd(false);
            setCurrentCookiesPolicy(response.data.content.source.body);
            setCookies(response.data?.content?.id);
          }
        }
        if (type == "previlageCustomer") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setPrevilageCustomerAdd(true);
          } else {
            setPrevilageCustomerAdd(false);
            setCurrentPrevilageCustomer(response.data.content.source.body);
            setPrevilage(response.data?.content?.id);
          }
        }
        if (type == "aboutUs") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setAboutAdd(true);
          } else {
            setAboutAdd(false);
            setcurrentAboutUs(response.data.content.source.body);
            setAbout(response.data?.content?.id);
          }
        }
        if (type == "privacyPolicy") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setPrivacyAdd(true);
          } else {
            setPrivacyAdd(false);
            setcurrentPrivacy(response.data.content.source.body);
            setPrivacy(response.data?.content.id);
          }
        }
        if (type == "termsAndCondition") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setTermsAdd(true);
          } else {
            setTermsAdd(false);
            setcurrentTerms(response.data.content.source.body);
            setTerms(response.data?.content.id);
          }
        }
        if (type == "returnPolicy") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setPaymentAdd(true);
          } else {
            setPaymentAdd(false);
            setcurrentPayment(response.data.content.source.body);
            setPayment(response.data?.content.id);
          }
        }
        if (type == "shippingPolicy") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setShippingAdd(true);
          } else {
            setShippingAdd(false);
            setCurrentShipping(response.data.content.source.body);
            setShipping(response.data?.content.id);
          }
        }
        if (type == "dndPolicy") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setDndAdd(true);
          } else {
            setDndAdd(false);
            setCurrentDnd(response.data.content.source.body);
            setDnd(response.data?.content.id);
          }
        }
        if (type == "refundPolicy") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setRefundAdd(true);
          } else {
            setRefundAdd(false);
            setCurrentRefund(response.data.content.source.body);
            setRefund(response.data?.content.id);
          }
        }
        if (type == "onlinePayment") {
          if (
            response.data?.status == "success" &&
            response.data?.message == "Not found"
          ) {
            setOnlinePaymentAdd(true);
          } else {
            setOnlinePaymentAdd(false);
            setCurrentOnlinePayments(response.data.content.source.body);
            setOnlinePayment(response.data?.content.id);
          }
        }
      }
    });
  };

  const updateContents = (file, version, id, typeAdd, type) => {
    const payload = {
      content: {
        type: type,
        title: typeAdd,
        body: file,
      },
      id: id,
    };

    UpdateContentFiles(payload).then((res) => {
      if (res.status == 200) {
        message.success("Updated successfully");
        getContents(type);
      } else {
        message.success("could not upload  ");
      }
    });
  };

  const addContents = (file, version, id, typeAdd, type) => {
    const payloadAdd = {
      body: file,
      type: type,
      title: typeAdd,
    };

    AddContentFiles(payloadAdd).then((res) => {
      if (res.status == 200) {
        message.success("Updated successfully");
        getContents(type);
      } else {
        message.success("could not upload  ");
      }
    });
  };
  const onFinish = (payload) => {
    addSocialMediaLinks(payload).then((res) => {
      if (res.status == 200) {
        message.success("Updated successfully");
        setEditSocialMedia(true);
      }
    });
  };

  return (
    <div className="manage-contents-body">
      <div className="manage-content-section">
        <h3>Social Media Integration</h3>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={socialmediaItems}
          ref={socialRef}
          onFinish={onFinish}
          form={form}
        >
          {formFields.map((data) => {
            return (
              <div className="flex" style={{ alignItems: "start" }}>
                <img
                  src={data.icon}
                  style={{ width: "33px", marginRight: "15px" }}
                />
                <Form.Item
                  hasFeedback
                  name={data.name}
                  style={{ width: "100%" }}
                >
                  <Input
                    addonBefore={<LinkOutlined />}
                    disabled={editSocialMedia}
                    placeholder={data.placeHolder}
                  />
                </Form.Item>
              </div>
            );
          })}

          {editSocialMedia ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                onClick={() => setEditSocialMedia(false)}
                className="update-button"
              >
                Edit
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Form.Item>
                <Button
                  type="primary"
                  className="cancel-button"
                  onClick={() => {
                    setEditSocialMedia(true);
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="update-button"
                >
                  Update
                </Button>
              </Form.Item>
            </div>
          )}
        </Form>
      </div>

      <div className="manage-content-section">
        <h3>About Us</h3>

        <div className="manage-content">
          <UpdateContentEditor
            content={currentAboutUs}
            version={aboutVersion}
            id={about}
            type="aboutUs"
            typeUpdate="about-us"
            update={updateContents}
            addContent={addContents}
            typeAdd="About Us"
            read={read}
            add={aboutAdd}
          />
        </div>

        <h3>Privacy Policy</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentPrivacy}
            version={privacyVersion}
            id={privacy}
            type="privacyPolicy"
            update={updateContents}
            typeUpdate="privacy-policy"
            typeAdd="Privacy Policy"
            read={read}
            add={privacyAdd}
            addContent={addContents}
          />
        </div>
        <h3>Terms and condition</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentTerms}
            version={termsVersion}
            id={terms}
            type="termsAndCondition"
            update={updateContents}
            typeUpdate="terms-of-use"
            typeAdd="Terms And Condition"
            read={read}
            add={termsAdd}
            addContent={addContents}
          />
        </div>
        <h3>Return Policy</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentPayment}
            version={paymentVersion}
            id={payment}
            type="returnPolicy"
            update={updateContents}
            typeUpdate="payment-and-refund-policy"
            typeAdd="Return Policy"
            read={read}
            add={paymentAdd}
            addContent={addContents}
          />
        </div>

        <h3>Shipping Policy</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentShipping}
            version={paymentVersion}
            id={shipping}
            type="shippingPolicy"
            update={updateContents}
            typeUpdate="payment-and-refund-policy"
            typeAdd="Shipping Policy"
            read={read}
            add={shippingAdd}
            addContent={addContents}
          />
        </div>

        <h3>Disclaimer</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentdisclaimer}
            version={paymentVersion}
            id={disclaim}
            type="disclaimer"
            update={updateContents}
            typeUpdate="payment-and-refund-policy"
            typeAdd="Disclaimer"
            read={read}
            add={disclaimerAdd}
            addContent={addContents}
          />
        </div>

        <h3>Cookies Policy</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentcookiesPolicy}
            version={paymentVersion}
            id={cookiesPolicy}
            type="cookiesPolicy"
            update={updateContents}
            typeUpdate="payment-and-refund-policy"
            typeAdd="Cookies Policy"
            read={read}
            add={cookiesPolicyAdd}
            addContent={addContents}
          />
        </div>

        <h3>Refund Policy</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentRefund}
            id={refund}
            type="refundPolicy"
            update={updateContents}
            typeUpdate="payment-and-refund-policy"
            typeAdd="Refund Policy"
            read={read}
            add={refundAdd}
            addContent={addContents}
          />
        </div>

        <h3>DND Policy</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentDnd}
            id={dnd}
            type="dndPolicy"
            update={updateContents}
            typeUpdate="payment-and-refund-policy"
            typeAdd="DND Policy"
            read={read}
            add={dndAdd}
            addContent={addContents}
          />
        </div>

        <h3>Online Payment</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentOnlinePayments}
            id={onlinePayment}
            type="onlinePayment"
            update={updateContents}
            typeUpdate="payment-and-refund-policy"
            typeAdd="Online Payments"
            read={read}
            add={onlinePaymentAdd}
            addContent={addContents}
          />
        </div>

        <h3>Previlage Customer</h3>
        <div className="manage-content">
          <UpdateContentEditor
            content={currentprevilageCustomer}
            version={paymentVersion}
            id={previlage}
            type="previlageCustomer"
            update={updateContents}
            typeUpdate="payment-and-refund-policy"
            typeAdd="Previlage Customer"
            read={read}
            add={previlageCustomerAdd}
            addContent={addContents}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageContent;
