


import React, { Fragment, useState } from "react";
import { Typography, Button, message } from "antd";
import "./reportsTable.scss";
import SearchBarInput from "../../../SearchBar/SearchBarInput";
import Loading from "../../../GeneralComponents/Loading";
import { regenerateExpiryReport } from "../../../../ApiAction/AdminApiAction/reportsApi";
import Cookies from "universal-cookie";
import Loader from "./Loader";

const { Text } = Typography;

const TableHeader = (props) => {
  const cookies = new Cookies();
  const {
    title,
    url,
    reportSummary,
    download,
    searchKey,
    setsearchKey,
    summary,
    setRegenerate,
    isLoadingReport
  } = props;
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const [isLoadingRegenerateReport, setIsLoadingRegenerateReport] = useState(
    false
  );
  const accountType = cookies.get("accountType");
  const handleDownload = async () => {
    setIsLoadingDownload(true);
    const downloaded = await download();
    if (downloaded) {
      // console.log(downloaded);
      setIsLoadingDownload(false);
    }
  };
  const handleRegenerateReport = () => {
    setIsLoadingRegenerateReport(true);
    const payload = {
      vendorId: cookies.get("vendorId"),
    };
    console.log("payload for regenrate-expiry-report : ", payload);
    regenerateExpiryReport(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(response.data.message);
            // Reload function
            setRegenerate(true);
          } else {
            message.error(response.data.message);
          }
        }
        console.log("response for regenerate expiry report: ", response);
      })
      .catch(() => {
        message.error("Sorry! some thing went wrong please try again later");
      })
      .finally(() => {
        setIsLoadingRegenerateReport(false);
        setRegenerate(false);
      });
  };
  return (
    <Fragment>
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="table_header"
      >
        <h1 level={4} className="sub-title-tags">
          {title === "Product" ? "Product List" : `${title} Report`}{" "}
        </h1>
        {title === "Product" && <SearchBarInput searchValue={setsearchKey} />}
        <div className="expiry-report-btn-container">
          {title === "Expiry" && accountType === "store" ? (
            <Button
              disabled={isLoadingRegenerateReport ? true : false}
              className="btn_theme"
              type="primary"
              size="large"
              onClick={() => handleRegenerateReport()}
              style={{ width: "190px", marginRight: "20px" }}
            >
              {isLoadingRegenerateReport ? (
                <Loading style={{ color: "white" }} />
              ) : (
                "Regenerate Report"
              )}
            </Button>
          ) : (
            <Button></Button>
          )}

          { title !== "Daily" && ( <Button
            className="btn_theme"
            size="large"
            type="primary"
            onClick={() => handleDownload()}
            // style={{ width: "150px" }}
            disabled={isLoadingReport ? true : false}
          >
            {title === "Product" ? (
              "Add Product"
            ) : isLoadingDownload ? (
              <Loading style={{ color: "white" }} />
            ) : (
              "Download Report"
            )}
          </Button>
          )}
        </div>
      </div>
      <br></br>
      {!isLoadingReport && (
        <Text style={{ color: "#0074be" }}>
          {summary ? summary : reportSummary}
        </Text>
      )}

      {isLoadingDownload && <Loader isLoadingDownload={isLoadingDownload} />}
    </Fragment>
  );
};

export default TableHeader;
