

import React, { useState, useEffect } from "react";
import $ from "jquery";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import { Select, Spin, Input, Typography, AutoComplete } from "antd";

import "./Searchbar.css";
import { searchProducts } from "../../ApiAction/CustomerApiActions/productApi";

import {
  addCategoryfilter,
  addSearchFilter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
  addBrandFilter,
} from "../../ReduxStore/action/productActions";

import { ENV_TYPE } from "../../Utils/constants";
import { fetchSearchSuggestions } from "../../ApiAction/CustomerApiActions/homepageAPI";
import useDebounce from "../../Hooks/useDebounce";

const { Option } = Select;
const { Search } = Input;
const { Text } = Typography;
const Searchbar = (props) => {
  const { searchProduct, speechText } = props;

  const [searchKey, setsearchKey] = useState();
  const [list, setlist] = useState([]);
  const debouncedSearchTerm = useDebounce(searchKey, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleFetchSearchSuggestions(debouncedSearchTerm);
    } else {
      handleFetchSearchSuggestions("");
    }
  }, [debouncedSearchTerm]);

  //to add search keyword to bread crumb
  const handleManageBreadCrumb = (data) => {
    let breadCrumbList = [];
    let breadcrumb = {
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${data}`
          : `/customer/product-listing/${data}`,
      type: "search",
    };
    breadcrumb["label"] = data;
    breadcrumb["id"] = data;

    breadCrumbList.push(breadcrumb);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };
  const handleFetchSearchSuggestions = (key) => {
    const payload = {
      offset: 0,
      size: 10,
      key: key,
      pinCode: props.pinCode ? props.pinCode : "",
    };
    if (key)
      fetchSearchSuggestions(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setlist([{ name: key }, ...response.data.documents]);
          } else setlist([{ name: key }]);
        }
        console.log("Response for search suggestions:", response);
      });
    else setlist([]);
  };

  const handleproductSearch = (key) => {
    setsearchKey(key);
  };

  const handleFocus = () => {
    $("#scrollbar")
      .on("click keydown", function () {
        console.log(`on focus `);
        $("body").addClass("scroll-disabled");
      })
      .on("blur", function () {
        console.log(`on blur `);
        $("body").removeClass("scroll-disabled");
      });
  };

  const handleProductChange = (value) => {
    value = value.replace("/", " ");
    console.log(`selected ${value}`);
    handleManageBreadCrumb(value);
    setsearchKey(value);
    props.addSearchFilter(value);

    searchProduct &&
      props.history.push(
        ENV_TYPE === "production"
          ? `/product-listing/${value}`
          : `/customer/product-listing/${value}`
      );
    setsearchKey("");
  };

  useEffect(() => {
    setlist([]);
    if (speechText !== null || speechText === "") {
      setsearchKey(speechText);
      handleFetchSearchSuggestions(speechText);
      $("#scrollbar").focus();
    }
  }, [props]);

  return (
    <AutoComplete
      size={"large"}
      value={searchKey}
      className="header-search-bar"
      showSearch
      showArrow={false}
      defaultActiveFirstOption={true}
      placeholder="Search Products"
      // optionFilterProp="children"
      // notFoundContent={loadingProducts ? <Spin size="small" /> : null}
      // onChange={handleProductChange}
      //   onFocus={onFocus}
      //   onBlur={onBlur}
      onSelect={handleProductChange}
      onSearch={handleproductSearch}
      onFocus={handleFocus}
      id="scrollbar"
      // filterOption={(input, option) =>
      //   option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      // }
    >
      {list.map((data, index) => {
        return (
          <Option value={data.name} key={index}>
            <Text strong>{data.name}</Text> {data?.currentCategory && "in"}{" "}
            <Text strong style={{ color: "#3e6df3" }}>
              {data?.currentCategory}
            </Text>
          </Option>
        );
      })}
    </AutoComplete>
  );
};

const mapStateToProps = (state) => {
  return { pinCode: state.productReducer.pinCode };
};

const mapDispatchToProps = {
  addCategoryfilter,
  addSearchFilter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
  addBrandFilter,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Searchbar)
);
