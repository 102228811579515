


import React from "react";
import { Card, Typography, Select } from "antd";

const { Text } = Typography;
const { Option } = Select;
const StockDetails = (props) => {
  const { stockOverviews, active, handleChangeVariant } = props;
  const handleChange = (value) => {
    console.log("value: ", value);
    handleChangeVariant(value);
  };
  return (
    <Card
      title="Stock Details"
      style={{ width: 300, border: "1px solid  #D3D3D3" }}
      bordered={true}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text strong>Available Variants:</Text>
        <Select
          defaultValue={active?.variant}
          style={{ width: 100 }}
          onChange={handleChange}
          size={"small"}
        >
          {stockOverviews?.map((data) => {
            return <Option value={data.variant}>{data.variant}</Option>;
          })}
        </Select>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <Text strong style={{ fontSize: "10px", color: "#3e6df3" }}>
          Select a variant to view stock overview**
        </Text>
      </div>
    </Card>
  );
};

export default StockDetails;
