


import React, { useEffect, useState } from "react";
import { Layout, Button, Dropdown,Menu} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Route, Redirect, Switch } from "react-router-dom";

import "antd/dist/antd.css";
import "../Admin/admin.scss";
import { fetchSort } from "../../ReduxStore/action";
import { connect } from "react-redux";
import FooterLayout from "../../Layouts/FooterLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import StoreMenu from "./storeMenu";

import ManageProfile from "../Admin/ManageProfile/manageProfile";
import ManageProducts from "../Seller/ManageProducts";
import AddProduct from "../Seller/ManageProducts/Components/addProduct";
import SearchBarInput from "../SearchBar/SearchBarInput";
import SortBy from "../Seller/SortBy";
import LinkedWarehouse from "./linkedWarehouse";
import ManageOrder from "../Seller/ManageOrder/manageOrder";
import FilterAndSortOrder from "../Seller/ManageOrder/filterAndSortOrder";
import ManageReturns from "../Seller/ManageReturns/manageReturns";
import ManageProductStock from "../Seller/ManageStocks/manageProductStock";
import ManageStocks from "../Seller/ManageStocks/manageStocks";
import ProductDetailPage from "../Customer/Components/ProductDetailPage";

import CheckAvailablity from "../Warehouse/components/CheckAvailiablity";
import FilterBy from "../Warehouse/components/CheckAvailiablity/filterBy";
import Cart from "../Customer/Components/Cart/cart";
import Cartpayment from "../Customer/Components/Cart/cartpayment";
import OrderResponse from "../Customer/Components/Cart/OrderResponseComponents";
import ManageMyOrder from "../Customer/Components/ManageOrders";
import RequestPayment from "../Seller/RequestPayment";

import SalesReport from "../Admin/Reports/SalesReport";
import StocksReport from "../Admin/Reports/StocksReport";
import DetailedReport from "../Admin/Reports/DetailedReport"
import ReportsFilter from "../Admin/Reports/Filter/reportsFilter";
import ExpiryReport from "../Admin/Reports/ExpiryReport";
import PaymentType from "../Admin/Reports/PaymentType";
import Cookies from "universal-cookie";
import ContentSection from "../Admin/ManageContent/contentSection";
import AccountTypeContext from "../Admin/accountTypeContext";
import ManageReturnsCustomer from "../Customer/Components/ManageReturns/manageReturnsCustomer";
import { availiableProductsFilterOptions } from "../Warehouse/components/CheckAvailiablity/checkAvailablityHelper";
import ConfigureDeliveryCharge from "../Admin/Configure/configureDeliveryCharge";
import CentralRepo from "../Admin/CentralRepo";
import QuickCheckout from "../Seller/QuickCheckout";
import GroupByReport from "../Seller/SortBy/groupByReport";
import PlatformIndex from "../Seller/PlatformRecovery/PlatformIndex";
import ManageCategory from "../Admin/ManageCategory";
import BarcodeSearch from "../Admin/BarcodeSearch/BarcodeSearch";
import DiscoutProducts from "../Admin/DiscountProducts/DiscoutProducts";
import ReplacedProducts from "../Admin/CentralRepo/replacedProducts";
import DayBookReport from "../Admin/Reports/DayBookReport";
import VerifiedProducts from "../Admin/CentralRepo/verifiedProducts";
import VerifyPayment from "../Customer/Components/Cart/verifyPayment";

const cookies = new Cookies();
const { Content, Sider } = Layout;
const Store = (props) => {
  const [searchVal, setSearchval] = useState("");
  const [urlPath, setUrlPath] = useState("");
  const [activeTab, setactiveTab] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const [reportSummary, setreportSummary] = useState("");
  const [relevance, setrelevance] = useState(true);
  const [filterBy, setfilterBy] = useState();
  
function handleMenuClick(e) {
  
  console.log('click', e);
}
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" >
        Requested
      </Menu.Item>
      <Menu.Item key="2" >
        Approved
      </Menu.Item>
      <Menu.Item key="3" >
        Sent to Bank
      </Menu.Item>
      <Menu.Item key="3" >
        Rejected
      </Menu.Item>
      <Menu.Item key="3" >
        Paid
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
      setCollapsed(true);
    } else setCollapsed(false);
  }, []);

  useEffect(() => {
    setUrlPath(props.location.pathname);
    setactiveTab(props.location.pathname.split("/").pop());
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <Layout>
      <HeaderLayout pathname={urlPath} isStore />
      {urlPath !== "/store/profile" &&
      urlPath !== "/store/linked-warehouse" &&
      urlPath !== "/store/request-payment" ? (
        <div className="sort-breadcrumb" style={{}}>
          <div className="admin-breadcrumb"></div>
          {(urlPath === "/store/manage-products" ||
            urlPath === "/store/manage-orders" ||
            urlPath === "/store/my-orders" ||
            urlPath === "/store/sales-report" ||
            urlPath === "/store/day-book-report" ||
            urlPath === "/store/online-payment" ||
            urlPath === "/store/stocks-report" ||
            urlPath === "/store/detailed-report" ||
            urlPath === "/store/expiry-report" ||
            urlPath === "/store/manage-stocks" ||
            urlPath === "/store/manage-returns" ||
            urlPath === "/store/my-returns" ||
            urlPath === "/store/manage-category" ||
            urlPath === "/store/central-repo") && (
            //|| urlPath === "/store/check-availiablity"
            <>
              {urlPath !== "/store/check-availiablity" && (
                <SearchBarInput searchValue={setSearchval} urlPath={urlPath} />
              )}

              {
                // urlPath === "/store/stocks-report" ||
                // urlPath === "/store/expiry-report" ||
                (urlPath === "/store/sales-report" ||
                  urlPath === "/store/online-payment" ||
                  urlPath === "/store/day-book-report" ||
                  urlPath === "/store/manage-products" ||
                  urlPath === "/store/check-availiablity") && (
                  <ReportsFilter
                    isStore
                    isVendor
                    activePath={activeTab}
                    setreportSummary={setreportSummary}
                  />
                )
              }
              {urlPath === "/store/sales-report" && <GroupByReport />}
              {urlPath === "/store/online-payment" && <GroupByReport />}
              {urlPath === "/store/manage-orders" ? (
                <FilterAndSortOrder />
              ) : (
                urlPath !== "/store/expiry-report" &&
                urlPath !== "/store/stocks-report" &&
                urlPath !== "/store/detailed-report" &&
                urlPath !== "/store/sales-report" &&
                urlPath !== "/store/day-book-report" &&
                urlPath !== "/store/online-payment" &&
                urlPath !== "/store/central-repo" &&
                urlPath !== "/store/check-availiablity" && (
                  <SortBy activePath={activeTab} setrelevance={setrelevance} />
                )
              )}
            </>
          )}
          {urlPath === "/store/check-availiablity" && (
            <FilterBy
              options={availiableProductsFilterOptions}
              setfilterBy={setfilterBy}
            />
          )}
        </div>
      ) : (
        <div style={{ marginTop: "2%" }}></div>
      )}

      <Layout className="nav-side-content-admin" style={{ padding: "3%" }}>
        <div className="profile_column_data">
          {!collapsed && (
            <Sider
              className="nav-side-bar-admin"
              trigger={null}
              collapsed={collapsed}
            >
              <StoreMenu activeTab={activeTab} setSearchval={setSearchval} />
            </Sider>
          )}
          <Layout className="admin-layout-content right_profile_layout">
            <Content>
              <Switch>
                <Route
                  exact
                  path="/store"
                  render={(props) => {
                    return <Redirect to="/store/profile" />;
                  }}
                />

                <Route
                  exact
                  path="/store/profile"
                  render={(props) => <ManageProfile {...props} isStore />}
                />
                <Route
                  exact
                  path="/store/manage-products"
                  render={(props) => (
                    <ManageProducts searchVal={searchVal} isStore />
                  )}
                />

                <Route
                  exact
                  path="/store/central-repo"
                  render={(props) => (
                    <CentralRepo searchVal={searchVal} isVendor />
                  )}
                />

                <Route
                  exact
                  path="/store/replaced-products"
                  render={(props) => (
                    <ReplacedProducts searchVal={searchVal} isVendor />
                  )}
                />

                <Route
                  exact
                  path="/store/verified-products"
                  render={(props) => (
                    <VerifiedProducts isVendor />
                  )}
                />

                <Route
                  exact
                  path="/store/manage-category"
                  render={(props) => <ManageCategory {...props} isView />}
                />
                <Route
                  exact
                  path="/store/linked-warehouse"
                  render={(props) => (
                    <LinkedWarehouse searchVal={searchVal} isStore />
                  )}
                />
                <Route
                  exact
                  path="/store/check-availiablity"
                  render={(props) => (
                    <CheckAvailablity {...props} filterBy={filterBy} />
                  )}
                />
                <Route
                  exact
                  path="/store/request-payment"
                  render={(props) => <RequestPayment />}
                />
                <Route
                  exact
                  path="/store/platform-recovery"
                  render={(props) => <PlatformIndex vendorType={"STORE"} />}
                />
                <Route
                  exact
                  path="/store/barcode-search"
                  render={(props) => <BarcodeSearch />}
                />
                <Route
                  exact
                  path="/store/discount-products"
                  render={(props) => <DiscoutProducts />}
                />
                <Route
                  exact
                  path="/store/cart"
                  render={(props) => {
                    return <Cart {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/store/cart/remove-items"
                  render={(props) => {
                    return <Cart {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/store/cart-payment"
                  render={(props) => {
                    return <Cartpayment {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/store/verify-payment/:orderGroupId"
                  render={(props) => {
                    return <VerifyPayment {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/store/order-response/:status"
                  render={(props) => {
                    return <OrderResponse />;
                  }}
                />
                <Route
                  exact
                  path="/store/my-orders"
                  render={(props) => {
                    return <ManageMyOrder searchVal={searchVal} />;
                  }}
                />

                <Route
                  exact
                  path="/store/linked-warehouse/product-detail/:productId"
                  render={(props) => {
                    return <ProductDetailPage {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/store/add-product"
                  render={(props) => {
                    return <AddProduct {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/store/edit-product/:productId"
                  render={(props) => {
                    return <AddProduct {...props} isEdit />;
                  }}
                />
                <Route
                  exact
                  path="/store/manage-orders"
                  render={(props) => (
                    <ManageOrder searchVal={searchVal} isStore />
                  )}
                />
                <Route
                  exact
                  path="/store/manage-returns"
                  render={(props) => <ManageReturns searchVal={searchVal} />}
                />
                <Route
                  exact
                  path="/store/my-returns"
                  render={(props) => (
                    <ManageReturnsCustomer searchVal={searchVal} />
                  )}
                />
                <Route
                  exact
                  path="/store/quick-checkout"
                  render={(props) => <QuickCheckout />}
                />
                <Route
                  exact
                  path="/store/manage-price-stock/:productId"
                  render={(props) => {
                    return <ManageProductStock {...props} isStore />;
                  }}
                />

                <Route
                  exact
                  path="/store/manage-stocks"
                  render={(props) => (
                    <ManageStocks
                      searchVal={searchVal}
                      isStore
                      relevance={relevance}
                    />
                  )}
                />

                <Route
                  exact
                  path="/store/content/:type"
                  render={(props) => {
                    return <ContentSection {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/store/sales-report"
                  render={(props) => (
                    <SalesReport
                      isStore
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                <Route
                  exact
                  path="/store/stocks-report"
                  render={(props) => (
                    <StocksReport
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                <Route
                  exact
                  path="/store/detailed-report"
                  render={(props) => (
                    <DetailedReport
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                {/* //removed configure delivery charge feature for store// */}
                {/* <Route
                exact
                path="/store/manage-delivery-charge"
                render={(props) => <ConfigureDeliveryCharge isStore />}
              /> */}
                <Route
                  exact
                  path="/store/expiry-report"
                  render={(props) => (
                    <ExpiryReport
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                <Route
                  exact
                  path="/store/day-book-report"
                  render={(props) => (
                    <DayBookReport
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                <Route
                  exact
                  path="/store/online-payment"
                  render={(props) => (
                    <PaymentType
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                <Route
                  render={(props) => {
                    return <Redirect to="/404" />;
                  }}
                />
              </Switch>
            </Content>
          </Layout>
        </div>
      </Layout>
      <AccountTypeContext.Provider value="seller">
        <FooterLayout />
      </AccountTypeContext.Provider>
    </Layout>
  );
};
const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Store);
