


import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, message } from "antd";
import ReportsTable from "../ReportsTable";
import { affiliateChargeColumns, formatAffiliateChargeReport } from "../Utils/reportHelper";
import {
  exportAffiliateChargeReport,
  listAffiliateChargeReport,
} from "../../../../ApiAction/AdminApiAction/reportsApi";
const AffiliateChargeReport = (props) => {
  const warning = Modal.warning;
  const { vendorId, reportSummary } = props;
  const [dataSource, setdataSource] = useState([]);
  const [isLoadingAffiliateChargeReport, setIsLoadingAffiliateChargeReport] = useState(false);

  const handleShowWarning = (msg) => {
    warning({
      title: msg,
      className: "create-confirm-modal",
    });
  };

  const handleValidateDateRange = (startDate, endDate) => {
    console.log("startDate: ", startDate, "endDate: ", endDate);

    var date1 = new Date(startDate);
    var date2 = new Date(endDate);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 186) {
      handleShowWarning(
        `Please choose a date range of lesser than or equal to
        Six months.`
      );
      return false;
    }
    return true;
  };

  const handleExportAffiliateChargeReport = async () => {
    const payload = {
      offset: 0,
      size: 10,
      key: props.searchVal,
      affiliateId: vendorId ? vendorId : props.vendorFilter,
    };

    if (props.dateRange) {
      if (
        handleValidateDateRange(
          props.dateRange.startTime,
          props.dateRange.endtime
        )
      ) {
          payload["startTime"] = props.dateRange.startTime;
          payload["endtime"] = props.dateRange.endtime;

          await exportAffiliateChargeReport(payload)
          .then((response) => {
            console.log(response, "response for voucher code excel sheet");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `affiliate charge report.xlsx`);

            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            message.error("Could not export file please try again");
          });
        }

    } else {
      handleShowWarning(
        `Please Choose a date range maximum of Six months
        to download affiliate charge report.`
      );
    }

    return true;
  };
  useEffect(() => {
    const payload = {
      offset: 0,
      size: 10,
      sortBy: "requestedDate",
      sortType: props.sort ? props.sort.sortBy : "desc",
      key: props.searchVal,
      affiliateId: vendorId ? vendorId : props.vendorFilter,
    };

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endtime"] = props.dateRange.endtime;
    }

    setIsLoadingAffiliateChargeReport(true);
    listAffiliateChargeReport(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log("reponse for affiliate charge report: ", response.data.documents);
            setdataSource(formatAffiliateChargeReport(response.data.documents));
          }
        }
      })
      .finally(() => {
        setIsLoadingAffiliateChargeReport(false);
      });
  }, [
    props.sort,
    props.dateRange,
    props.searchVal,
    props.vendorFilter,
  ]);
  return (
    <ReportsTable
      download={handleExportAffiliateChargeReport}
      columns={affiliateChargeColumns}
      dataSource={dataSource}
      type={"Affiliate Charge"}
      reportSummary={reportSummary}
      summary={
        "Affiliate payment details are listed below"
      }
      isLoadingReport={isLoadingAffiliateChargeReport}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
    dateRange: state.reportReducer.dateRange,
    vendorFilter: state.reportReducer.vendorId,
  };
};

export default connect(mapStateToProps)(AffiliateChargeReport);
