import { Button, Divider } from "antd";
import React from "react";
import moment from "moment";
import { useRef } from "react";
import { useEffect } from "react";
import ReactToPrint from "react-to-print";
import LogoRecipet from "../../../Assets/Images/Logo/C_Logo.png";
import { ToWords } from "to-words";

function PlatformInvoiceModal(props) {
  const { dataList, setShowPlatformInvoice, isAffiliate } = props;
  const modelRef = useRef();
  const printRef = useRef();
  const toWords = new ToWords();

  useEffect(() => {
    window.addEventListener("mousedown", handleModalClose);
    return () => {
      window.removeEventListener("mousedown", handleModalClose);
    };
  }, []);

  const handleModalClose = (e) => {
    if (modelRef.current.contains(e.target)) {
      return true;
    } else {
      setShowPlatformInvoice(false);
    }
  };

  const handleTaxCalculate = (amount) => {
    let tax = parseFloat(amount) * 0.18;
    return tax.toFixed(2);
  };

  const handleTaxableAmount = (amount) => {
    let tax = parseFloat(amount) * 0.18;
    let taxAmount = parseFloat(amount) - tax;
    return taxAmount.toFixed(2);
  };


  const calculateTaxValue = () => {
    let tax = 0;
    let temp = dataList?.platformOrders?.map((data) => {
      let taxAmount = (isAffiliate? data?.affiliateAmount : data?.platformAmount) * 0.18;
      tax += taxAmount

    })
    return (<h4 className="platform-heading-bold-800">{tax.toFixed(2)}</h4>);
  }

  const calculateTaxableValue = () => {
    let tax = 0;
    let temp = dataList?.platformOrders?.map((data) => {
      let taxAmount = (isAffiliate? data?.affiliateAmount : data?.platformAmount) * 0.18;
      let taxable = (isAffiliate? data?.affiliateAmount : data?.platformAmount) - taxAmount;
      tax += taxable;
    });
    return <h4 className="platform-heading-bold-800">{tax.toFixed(2)}</h4>;
  };

  return (
    <div className="platform-invoice-main-container">
      <div
        className="platform-invoice-modal platform-heading-padding-500"
        ref={modelRef}
      >
        <div
          style={{ padding: "1em", borderTop: "3px solid #0074be" }}
          ref={printRef}
        >
          <h2 className="platform-heading-800 platform-heading-bold-600 platform-heading-padding-500 platform-text-align-center">
            TAX INVOICE
          </h2>
          <div className="platform-heading-padding-1000">
            <h2 className="platform-heading-1000 platform-heading-bold-600 line-height-500">
              loremIpsum Online Shoppe Pvt. Ltd.
            </h2>
            <h3 className="platform-heading-800 line-height-500 text-padding-left">
              Capital Art Businesses Spaces,
            </h3>
            <h3 className="platform-heading-800 line-height-500 text-padding-left">
              Neruvissery, Arattupuzha P.O
            </h3>
            <h3 className="platform-heading-800 line-height-500  text-padding-left">
              Thrissur, Kerala - 680562
            </h3>
            <h3 className="platform-heading-800 line-height-500 text-padding-left">
              Ph: +91 9744338134,info@loremIpsum.in
            </h3>
          </div>
          <div className="platform-diff-container">
            <h4>{dataList?.platformId}</h4>
            <h4>{dataList?.invoiceDate}</h4>
          </div>
          <div className="platform-heading-padding-1000">
            <h2 className="platform-heading-800 platform-heading-bold-600">
              Billed To.
            </h2>
            <h3 className="platform-heading-800  line-height-500 text-padding-left">
              {dataList?.vendorName},[
              {dataList?.vendorAddress?.ownerName} ]
            </h3>
            <h3 className="platform-heading-800  line-height-500 text-padding-left">
              {dataList?.vendorAddress?.addressLine1},
              {dataList?.vendorAddress?.addressLine2}
            </h3>
            <h3 className="platform-heading-800  line-height-500 text-padding-left">
              {dataList?.vendorAddress?.state},{" "}
              {dataList?.vendorAddress?.pinCode}
            </h3>
            <h3 className="platform-heading-800 line-height-500 text-padding-left">
              Ph: {dataList?.vendorAddress?.phoneNo}
            </h3>
          </div>
          <div class="platform-table">
            <table>
              <thead>
                <tr>
                  <th>SL No</th>
                  <th>INVOICE NO</th>
                  <th>INVOICE DATE</th>
                  <th>{isAffiliate? "AFFILIATE" : "PLATFORM"} CHARGE</th>
                  <th>GST (18%)</th>
                  <th>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                {dataList?.platformOrders?.map((data, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data?.orderGroupId}</td>
                    <td>
                      {moment(data?.invoiceDate).format("YYYY-MM-DD hh:mm A")}
                    </td>
                    <td>{handleTaxableAmount(isAffiliate? data?.affiliateAmount : data?.platformAmount)}</td>
                    <td>{handleTaxCalculate(isAffiliate? data?.affiliateAmount : data?.platformAmount)}</td>
                    <td>{isAffiliate? data?.affiliateAmount.toFixed(2) : data?.platformAmount.toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td></td>

                  <td
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h4 className="platform-heading-bold-800">TOTAL</h4>
                  </td>
                  <td></td>
                  <td>{calculateTaxableValue()}</td>
                  <td>{calculateTaxValue()}</td>
                  <td>
                    <h4 className="platform-heading-bold-800">
                      {dataList?.platformCharge}
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="platform-grand-total">
              <span>
                <h3>GRAND TOTAL (Inc.Tax)</h3>
                <h3>{dataList?.platformCharge}</h3>
              </span>
            </div>
          </div>

          <div className="thermal-padding"></div>

          <div className="invoice-footer-image">
            <div class="logo-receipt">
              <img src={LogoRecipet} />
            </div>
          </div>
        </div>
        <Divider />
        <ReactToPrint
          trigger={() => <Button type="primary">Print Invoice</Button>}
          content={() => printRef.current}
        />
      </div>
    </div>
  );
}

export default PlatformInvoiceModal;
