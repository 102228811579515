


import React, { useState } from "react";

import { Drawer, Typography, Button } from "antd";

import RadioUnchecked from "../../../../../../Assets/Images/Icons/radio_uncheck.svg";
import RadioChecked from "../../../../../../Assets/Images/Icons/radio_check.svg";

import CatogeryFilter from "./catogeryfilter";
import PriceFilter from "./priceFilter";
import BrandFilter from "./brandFilter";

import QuantityController from "../../../../../ProductCard/components/QuantityController";

const { Text } = Typography;
export default function MobileFilter(props) {
  const {
    mobileFilterVisible,
    setmobileFilterVisible,
    type,
    product,
    filterCategories,
    filterBrands,
    setisFilterApplied,
  } = props;

  const [selectedId, setselectedId] = useState();
  const [filterTypeVisible, setfilterTypeVisible] = useState(false);
  const [viewUpdate, setViewUpdate] = useState(false);
  const [activeClassName, setactiveClassName] = useState(1);
  const sortByData = [
    {
      id: 1,
      type: "Price",
      value: "Low - High",
    },
    {
      id: 2,
      type: "Price",
      value: "High - Low",
    },
    { id: 3, type: "Relevance", value: "Popularity" },
  ];

  const quantityData = [
    {
      id: 1,
      value: "1 kilogram",
    },
    {
      id: 2,
      value: "2 kilogram",
    },
    {
      id: 3,
      value: "3 kilogram",
    },
  ];

  const onClose = () => {
    setmobileFilterVisible(false);
    handlefilterType(false);
  };

  const handleSelect = (id) => {
    if (selectedId === id) {
      setselectedId();
    } else setselectedId(id);
  };

  //more actions to be included here for applying filter conditions to product listing
  const handleApplyFilter = () => {
    setmobileFilterVisible(false);
    handlefilterType(false);
  };

  const handlefilterType = (value) => {
    setfilterTypeVisible(!filterTypeVisible);
    setViewUpdate(value);
    setactiveClassName(value);
  };

  const renderAddToCart = () => {
    return (
      <div className="mobile-filter-container sortby_mobile">
        {quantityData.map((data) => {
          return (
            <div
              onClick={() => handleSelect(data.id)}
              className="mobile-view-sortby-each"
            >
              <Text>{data.value}</Text>
              <img
                className="sort-by-radio-icon"
                src={selectedId === data.id ? RadioChecked : RadioUnchecked}
                alt="radio-checked-icon"
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderSortBy = () => {
    return (
      <div className="mobile-filter-container sortby_mobile">
        {sortByData.map((data) => {
          return (
            <div
              onClick={() => handleSelect(data.id)}
              className="mobile-view-sortby-each"
            >
              <Text>
                {data.type} ({data.value}){" "}
              </Text>
              <img
                className="sort-by-radio-icon"
                src={selectedId === data.id ? RadioChecked : RadioUnchecked}
                alt="radio-checked-icon"
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderFilterBy = () => {
    return (
      <div
        className="mobile-filter-container"
        setfilterTypeVisible={setfilterTypeVisible}
      >
        <div className="filter_by_group_data">
          <div
            className={activeClassName === 1 && "active"}
            onClick={() => handlefilterType(1, true)}
          >
            Category
          </div>
          <div
            className={activeClassName === 2 && "active"}
            onClick={() => handlefilterType(2, true)}
          >
            Price
          </div>
          <div
            className={activeClassName === 3 && "active"}
            onClick={() => handlefilterType(3, true)}
          >
            Brand
          </div>
        </div>
        {viewUpdate === 3 ? (
          <BrandFilter
            filterBrands={filterBrands}
            width="100%"
            setisFilterApplied={setisFilterApplied}
          />
        ) : viewUpdate === 2 ? (
          <PriceFilter width="100%" setisFilterApplied={setisFilterApplied} />
        ) : (
          <CatogeryFilter
            filterCategories={filterCategories}
            width="100%"
            setisFilterApplied={setisFilterApplied}
          />
        )}
      </div>
    );
  };

  const getFilterMenu = () => {
    switch (type) {
      case "Sort By":
        return renderSortBy();
        break;
      case "Filter By":
        return renderFilterBy();
        break;

      case "Add To Cart":
        return renderAddToCart();
        break;
      default:
        break;
    }
  };

  return (
    <Drawer
      height={"auto"}
      title={type === "Add To Cart" ? product.productName : type}
      placement={"bottom"}
      closable={true}
      onClose={onClose}
      visible={mobileFilterVisible}
      key={"bottom"}
      className="filter_pop_data"
    >
      {getFilterMenu()}

      {type === "Add To Cart" ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <QuantityController
            quantity={1}
            stock={10}
            update={true}
            setQuantity={(value) => console.log(value)}
          />
          <Button
            size="small"
            type="primary"
            onClick={() => handleApplyFilter()}
            className="mobile-view-apply-filter-button"
          >
            Add (₹ 150.00)
          </Button>
        </div>
      ) : (
        <Button
          size="large"
          type="primary"
          onClick={() => handleApplyFilter()}
          className="mobile-view-apply-filter-button"
        >
          Apply filter
        </Button>
      )}
    </Drawer>
  );
}
