


import React, { Fragment } from "react";

import { Card, Button, Divider, Typography } from "antd";

const { Text } = Typography;
const AddressCard = (props) => {
  const { data, loading, handleEditAddress, handleAction } = props;

  const title = () => {
    var str_type = data.addressType;
    return (
      <div className="address_type" data-type={str_type.toLowerCase()}>
        {data.addressType}
      </div>
    );
  };
  const getValue = (value) => {
if(data)
{
  const filterArray=[
    data?.buildingName?data?.buildingName:null,
    data?.addressLine1?data?.addressLine1:null,
      data?.addressLine2?data?.addressLine2:null, 
      data?.district?data?.district:null,
    data?.state?data?.state:null,
    data?.pinCode?data?.pinCode:null
  ];
  console.log("data??",filterArray);

  const filtered = filterArray.filter(function (el) {
    return el != null;
  })
  const filteredNullStringValues = filtered.filter(Boolean)

  return filteredNullStringValues && filteredNullStringValues.toString().split(',').join(', ');
}


    // if (value !== null) return value + ",";
    // else return "";
  };



  // console.log("formattedArray", data, data.filter(function (el) {
  //   return el != null;
  // })
  // )

  return (
    <Fragment>
      <Card
        loading={loading}
        title={!loading && title()} //while using as loading card component title need not be shown
        extra={
          !loading && ( //while using as loading card componnet this part need not been shown
            <div className="address_card_action">
              <Button
                style={{ color: "#fd5a15", fontWeight: "600" }}
                type="text"
                onClick={() => handleEditAddress(data)}
              >
                Edit
              </Button>
              <Button
                style={{ color: "gray", fontWeight: "600" }}
                type="text"
                onClick={() => handleAction({ remove: true, address: data })}
              >
                Remove
              </Button>
            </div>
          )
        }
        hoverable={false}
        style={{
          width: "100%",
          backgroundColor: "#ffff",
        }}
        className={data?.primary ? "default_address_card" : ""}
      >
        <div className="address_card_data_main">
          <div className="address_card_data_each">
            <div className="address_card_data_each_key">
              {" "}
              <Text strong>Name :</Text>
            </div>
            <div className="address_card_data_each_value">
              <Text strong>{data?.ownerName}</Text>
            </div>
          </div>
          <div className="address_card_data_each">
            <div className="address_card_data_each_key">
              {" "}
              <Text strong>Address :</Text>
            </div>
            <div className="address_card_data_each_value">
              <Text strong>
                {/* {getValue(data?.buildingName) +
                  getValue(data?.addressLine1) +
                  getValue(data?.addressLine2) +
                  getValue(data?.district) +
                  getValue(data?.state) +
                  getValue(data?.pinCode)} */}
                  {getValue()}
              </Text>
            </div>
          </div>

          <Divider />

          <div className="address_card_data_each">
            <div className="address_card_data_each_key">
              {" "}
              <Text strong>Phone :</Text>
            </div>
            <div className="address_card_data_each_value">
              <Text strong>{data?.phoneNo}</Text>
            </div>
          </div>

          <div className="address_card_data_each">
            <div className="address_card_data_each_key">
              {" "}
              <Text strong>Email :</Text>
            </div>
            <div className="address_card_data_each_value">
              <Text strong>{data?.emailId}</Text>
            </div>
          </div>
        </div>

        <div className="address_card_makeDefault">
          {!data?.primary && ( //need to be shown only for non default address
            <Button
              type="text"
              style={{ color: "#fd5a15", fontWeight: "600" }}
              onClick={() =>
                handleAction({ setAsDefault: true, address: data })
              }
            >
              {" "}
              SET AS DEFAULT ADDRESS
            </Button>
          )}
        </div>
      </Card>
    </Fragment>
  );
};

export default AddressCard;
