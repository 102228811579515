

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import "./Megamenumodal.scss";
import { Typography } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import Brand1 from "../../../Assets/Images/dummyBrands/1.png";
import Brand2 from "../../../Assets/Images/dummyBrands/2.png";
import Brand3 from "../../../Assets/Images/dummyBrands/3.png";
import Brand4 from "../../../Assets/Images/dummyBrands/4.png";
import Brand5 from "../../../Assets/Images/dummyBrands/5.png";
import Brand6 from "../../../Assets/Images/dummyBrands/6.png";

import { ENV_TYPE, FILE_PATH } from "../../../Utils/constants";

import {
  addCategoryfilter,
  addSearchFilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
  addBrandFilter,
  resetCategoriesFilter,
} from "../../../ReduxStore/action/productActions";
import BreadcrumbSeparator from "antd/lib/breadcrumb/BreadcrumbSeparator";

const { Text } = Typography;
const MegamenuModal = (props) => {
  const { visible, data, type, topBrands, setvisible } = props;
  const [catogeriesData, setcatogeriesData] = useState([]);
  const [selectedCatogeryLevelZero, setselectedCatogeryLevelZero] = useState(0);
  const [selectedCatogeryLevelOne, setselectedCatogeryLevelOne] = useState(0);
  const [selectedCAtogeryLevelTwo, setselectedCAtogeryLevelTwo] = useState(0);
  const [selectedCatogeryLevelThree, setselectedCatogeryLevelThree] =
    useState(0);

  const [topBrandList, settopBrandList] = useState([]);
  const [selectedCatogeryLevelFour, setselectedCatogeryLevelFour] = useState(0);
  const brandList = [Brand1, Brand2, Brand3, Brand4, Brand5, Brand6];

  //to add selected categoryName to bread crumb
  const handleManageBreadCrumb = (data) => {
    let breadCrumbList = [];
    let breadcrumb = {
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${data.categoryName}`
          : `/customer/product-listing/${data.categoryName}`,
      type: "catogery",
    };
    breadcrumb["label"] = data.categoryName;
    breadcrumb["id"] = data.id;

    breadCrumbList.push(breadcrumb);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  //for redirection to product listing based on brands
  const handleRedirectBrandWise = (e, brand) => {
    e.stopPropagation();
    props.addBrandFilter([brand.brandId]);
    props.resetCategoriesFilter();
    props.history.push(
      ENV_TYPE === "production"
        ? `/product-listing/${brand.brandName}`
        : `/customer/product-listing/${brand.brandName}`
    );
  };

  //this part of code redirects to equalent catogeries/subcatogeries productListing page
  const handleRedirection = (e, data, level) => {
    e.stopPropagation();
    handleManageBreadCrumb(data);
    let actualLevel = type === "each-catogery" ? level + 1 : level; //there will be two cases all category modal and each category modal, each category modal will have one column less that is why we are handling it here
    console.log("Now we are talking", actualLevel)
    switch (actualLevel) {
      case -1:
        props.addCategoryfilter(data.id);
        break;
      case 0:
        props.addSubCategory00filter(data.id);
        break;
      case 1:
        props.addSubCategory01filter(data.id);
        break;
      case 2:
        props.addSubCategory02filter(data.id);
        break;
      case 3:
        props.addSubCategory03filter(data.id);
        break;
      default:
        console.log("invalid category level");
    }

    props.history.push(
      ENV_TYPE === "production"
        ? `/product-listing/${data.categoryName}`
        : `/customer/product-listing/${data.categoryName}`
    );
    setvisible(false);
  };

  const handleZeroLevelCategoryChange = (data, index) => {
    console.log("selected category data:", data, type);
    setselectedCatogeryLevelZero(index);

    if (type === "all-catogery") {
      settopBrandList(data.topBrands);
    }
  };

  //initial rendering for all catogeries
  useEffect(() => {
    if (data) {
      setcatogeriesData(data);
    }
  }, [data]);

  useEffect(() => {
    if (selectedCatogeryLevelZero) {
      setselectedCatogeryLevelOne(0);
      setselectedCAtogeryLevelTwo(0);
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [selectedCatogeryLevelZero]);

  //on Changing catogery in first layer
  useEffect(() => {
    if (selectedCatogeryLevelOne) {
      setselectedCAtogeryLevelTwo(0);
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [selectedCatogeryLevelOne]);

  //on Changing catogery in Second layer
  useEffect(() => {
    if (selectedCAtogeryLevelTwo) {
      setselectedCatogeryLevelThree(0);
      setselectedCatogeryLevelFour(0);
    }
  }, [selectedCAtogeryLevelTwo]);

  //on Changing catogery in Third layer
  useEffect(() => {
    if (selectedCatogeryLevelThree) {
      setselectedCatogeryLevelFour(0);
    }
  }, [selectedCatogeryLevelThree]);

  useEffect(() => {
    if (type === "each-catogery" && topBrands) {
      console.log(topBrands, ":  top brands");
      settopBrandList(topBrands);
    } else {
      settopBrandList(data[0]?.topBrands);
    }
  }, [type]);

  return (
    <>
      {visible && data && (
        <div className="megamenu-modal-container main_megamenu_list">
          {" "}
          {/* //level zero catogeries// */}
          {catogeriesData.length > 0 ? (
            <div className="mega-menu-catogery-odd">
              {catogeriesData.map((data, index) => {
                return (
                  <div
                    className={
                      selectedCatogeryLevelZero === index
                        ? "megamenu-catogery-each-active"
                        : "megamenu-catogery-each"
                    }
                    onMouseOver={() =>
                      handleZeroLevelCategoryChange(data, index)
                    }
                    onClick={(e) => handleRedirection(e, data, -1)} //redirect to equalent product listing page
                  >
                    <Text>{data.categoryName}</Text>
                    <CaretRightOutlined
                      className={
                        selectedCatogeryLevelZero === index &&
                        data?.subCategories.length
                          ? "active-icon"
                          : "deactive-icon"
                      }
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mega-menu-catogery-odd"></div>
          )}
          {/* //level one catogeries// */}
          {
            <div className="mega-menu-catogery">
              {catogeriesData.map((data, index) => {
                if (index === selectedCatogeryLevelZero) {
                  return data.subCategories?.length ? (
                    data.subCategories.map((dataLevel1, indexLevel1) => {
                      return (
                        <div
                          className={
                            selectedCatogeryLevelOne === indexLevel1
                              ? "megamenu-catogery-each-active"
                              : "megamenu-catogery-each"
                          }
                          onMouseOver={() =>
                            setselectedCatogeryLevelOne(indexLevel1)
                          }
                          onClick={(e) => handleRedirection(e, dataLevel1, 0)} //redirect to equalent product listing page
                        >
                          <Text>{dataLevel1.categoryName}</Text>
                          <CaretRightOutlined
                            className={
                              selectedCatogeryLevelOne === indexLevel1 &&
                              dataLevel1?.subCategories.length
                                ? "active-icon"
                                : "deactive-icon"
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="mega-menu-catogery"></div>
                  );
                }
              })}
            </div>
          }
          {/* //level2 catogeries// */}
          {
            <div className="mega-menu-catogery">
              {catogeriesData.map((data, index) => {
                if (index === selectedCatogeryLevelZero) {
                  return data.subCategories?.length ? (
                    data.subCategories.map((dataLevel1, indexLevel1) => {
                      if (indexLevel1 === selectedCatogeryLevelOne) {
                        return dataLevel1.subCategories?.length ? (
                          dataLevel1.subCategories.map(
                            (dataLevel2, indexLevel2) => {
                              return (
                                <div
                                  className={
                                    selectedCAtogeryLevelTwo === indexLevel2
                                      ? "megamenu-catogery-each-active"
                                      : "megamenu-catogery-each"
                                  }
                                  onMouseOver={() =>
                                    setselectedCAtogeryLevelTwo(indexLevel2)
                                  }
                                  onClick={(e) =>
                                    handleRedirection(e, dataLevel2, 1)
                                  } //redirect to equalent product listing page
                                >
                                  <Text>{dataLevel2.categoryName}</Text>
                                  <CaretRightOutlined
                                    className={
                                      selectedCAtogeryLevelTwo ===
                                        indexLevel2 &&
                                      dataLevel2?.subCategories.length
                                        ? "active-icon"
                                        : "deactive-icon"
                                    }
                                  />
                                </div>
                              );
                            }
                          )
                        ) : (
                          <div className="mega-menu-catogery-odd"></div>
                        );
                      }
                    })
                  ) : (
                    <div className="mega-menu-catogery-odd"></div>
                  );
                }
              })}
            </div>
          }
          {/* //level3 catogeries// */}
          {
            <div className="mega-menu-catogery-odd">
              {catogeriesData.map((data, index) => {
                if (index === selectedCatogeryLevelZero) {
                  return data.subCategories?.length ? (
                    data.subCategories.map((dataLevel1, indexLevel1) => {
                      if (indexLevel1 === selectedCatogeryLevelOne) {
                        return dataLevel1.subCategories?.length ? (
                          dataLevel1.subCategories.map(
                            (dataLevel2, indexLevel2) => {
                              if (indexLevel2 === selectedCAtogeryLevelTwo) {
                                return dataLevel2.subCategories?.length ? (
                                  dataLevel2.subCategories.map(
                                    (dataLevel3, indexLevel3) => {
                                      return (
                                        <div
                                          className={
                                            selectedCatogeryLevelThree ===
                                            indexLevel3
                                              ? "megamenu-catogery-each-active"
                                              : "megamenu-catogery-each"
                                          }
                                          onMouseOver={() =>
                                            setselectedCatogeryLevelThree(
                                              indexLevel3
                                            )
                                          }
                                          onClick={(e) =>
                                            handleRedirection(e, dataLevel3, 2)
                                          } //redirect to equalent product listing page
                                        >
                                          <Text>{dataLevel3.categoryName}</Text>
                                          <CaretRightOutlined
                                            className={
                                              selectedCatogeryLevelThree ===
                                                indexLevel3 &&
                                              dataLevel3?.subCategories?.length
                                                ? "active-icon"
                                                : "deactive-icon"
                                            }
                                          />
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <div className="mega-menu-catogery-odd"></div>
                                );
                              }
                            }
                          )
                        ) : (
                          <div className="mega-menu-catogery-odd"></div>
                        );
                      }
                    })
                  ) : (
                    <div className="mega-menu-catogery-odd"></div>
                  );
                }
              })}
            </div>
          }
          {/* //level4 catogeries// */}
          {type === "all-catogery" && (
            <div className="mega-menu-catogery">
              {catogeriesData.map((data, index) => {
                if (index === selectedCatogeryLevelZero) {
                  return data.subCategories?.length ? (
                    data.subCategories.map((dataLevel1, indexLevel1) => {
                      if (indexLevel1 === selectedCatogeryLevelOne) {
                        return dataLevel1.subCategories?.length ? (
                          dataLevel1.subCategories.map(
                            (dataLevel2, indexLevel2) => {
                              if (indexLevel2 === selectedCAtogeryLevelTwo) {
                                return dataLevel2.subCategories?.length ? (
                                  dataLevel2.subCategories.map(
                                    (dataLevel3, indexLevel3) => {
                                      if (
                                        indexLevel3 ===
                                        selectedCatogeryLevelThree
                                      ) {
                                        return dataLevel3.subCategories
                                          ?.length ? (
                                          dataLevel3.subCategories.map(
                                            (dataLevel4, indexLevel4) => {
                                              return (
                                                <div
                                                  className={
                                                    selectedCatogeryLevelFour ===
                                                    indexLevel4
                                                      ? "megamenu-catogery-each-active"
                                                      : "megamenu-catogery-each"
                                                  }
                                                  onMouseOver={() =>
                                                    setselectedCatogeryLevelFour(
                                                      indexLevel4
                                                    )
                                                  }
                                                  onClick={(e) =>
                                                    handleRedirection(
                                                      e,
                                                      dataLevel4,
                                                      3
                                                    )
                                                  } //redirect to equalent product listing page
                                                >
                                                  <Text>
                                                    {dataLevel4.categoryName}
                                                  </Text>
                                                  {/* <CaretRightOutlined
                                                  className={
                                                    selectedCatogeryLevelFour ===
                                                    indexLevel4
                                                      ? "active-icon"
                                                      : "deactive-icon"
                                                  }
                                                /> */}
                                                </div>
                                              );
                                            }
                                          )
                                        ) : (
                                          <div className="mega-menu-catogery"></div>
                                        );
                                      }
                                    }
                                  )
                                ) : (
                                  <div className="mega-menu-catogery"></div>
                                );
                              }
                            }
                          )
                        ) : (
                          <div className="mega-menu-catogery"></div>
                        );
                      }
                    })
                  ) : (
                    <div className="mega-menu-catogery"></div>
                  );
                }
              })}
            </div>
          )}
          {topBrandList.length > 0 && (
            <div className="megamenu-top-brands-section-main">
              <div className="megamenu-top-brands-section-main">
                <Text style={{ paddingLeft: "25px" }}>Top Brand</Text>
              </div>
              <div className="megamenu-top-brands-images">
                <div className="megamenu-top-brands-left">
                  {topBrandList.slice(0, 4).map((data) => {
                    return (
                      <img
                        src={FILE_PATH + data.image}
                        alt={"brand-image"}
                        className="top-brand-image"
                        onClick={(e) => handleRedirectBrandWise(e, data)}
                      />
                    );
                  })}
                </div>
                <div className="megamenu-top-brands-section-left">
                  {topBrandList.slice(4, 8).map((data) => {
                    return (
                      <img
                        src={FILE_PATH + data.image}
                        alt={"brand-image"}
                        className="top-brand-image"
                        onClick={(e) => handleRedirectBrandWise(e, data)}
                      />
                    );
                  })}
                </div>
                <div className="megamenu-top-brands-left">
                  {topBrandList.slice(8, 12).map((data) => {
                    return (
                      <img
                        src={FILE_PATH + data.image}
                        alt={"brand-image"}
                        className="top-brand-image"
                        onClick={(e) => handleRedirectBrandWise(e, data)}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const mapDispatchToProps = {
  addCategoryfilter,
  addSearchFilter,
  addSubCategory00filter,
  addSubCategory01filter,
  addSubCategory02filter,
  addSubCategory03filter,
  addBrandFilter,
  resetCategoriesFilter,
};

export default withRouter(connect(null, mapDispatchToProps)(MegamenuModal));
