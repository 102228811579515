import React, { Fragment, useEffect, useState } from "react";
import { message, Pagination, Modal } from "antd";
import ReportsTable from "../ReportsTable";
import { 
    fetchDayBookReport, 
    exportDayBookReport 
} from "../../../../ApiAction/AdminApiAction/reportsApi";
import {
  formatDayBookReport,
  dayBookReportColumns,
  dayBookReportColumnsAdmin
 } from "../Utils/reportHelper";
import { fetchSort } from "../../../../ReduxStore/action";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

const DayBookReport = (props) => {
  const cookies = new Cookies();
  const warning = Modal.warning;
  const { vendorId, reportSummary, isStore } = props;
  const [dataSource, setdataSource] = useState([]);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [totalSize, settotalSize] = useState();
  const [columnData, setcolumnData] = useState();
  const [pageNo, setpageNo] = useState(1);

  const handleShowWarning = (msg) => {
    warning({
      title: msg,
      className: "create-confirm-modal",
    });
  };

  //handling date range part starts here

  const handleValidateDateRange = (startDate, endDate) => {
    console.log("startDate: ", startDate, "endDate: ", endDate);

    var date1 = new Date(startDate);
    var date2 = new Date(endDate);

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    if (Difference_In_Days > 186) {
      handleShowWarning(
        `Please choose a date range of lesser than or equal to ${
          isStore ? "Six" : "One"
        } month.`
      );
      return false;
    }
    return true;
  };

  const handleExportDayBookReport = async () => {
    const payload = {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
      // sortType: props.sort ? props.sort.sortBy : "desc",
      key: props.searchVal,
      vendorId: vendorId ? vendorId : props.vendorFilter,
      // brandId: props.brandId ? props.brandId : "",
    };
    if (props?.sort?.sortType) {
      payload["sortBy"] = props.sort.sortType;
    }

    if (props?.sort?.sortBy) {
      payload["sortType"] = props.sort.sortBy;
    }

    if (props?.sort?.groupBy) {
      payload["type"] = props?.sort?.groupBy;
    }
    if (props?.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endTime"] = props.dateRange.endtime;
    }

    if (props?.dateRange) {
      if (
        handleValidateDateRange(
          props.dateRange.startTime,
          props.dateRange.endtime
        )
      ) {
        await exportDayBookReport(payload)
          .then((response) => {
            if (response.status === 200) {
              if (response?.data?.status === "failed") {
                handleShowWarning(
                  "Couldn't download day book report. Please try again later"
                );
              } else {
                console.log(response, "response for voucher code excel sheet");
                const url = window.URL.createObjectURL(
                  new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `daybook_report.xlsx`);
                document.body.appendChild(link);
                link.click();
              }
            } else {
              handleShowWarning(
                "Couldn't download day book report. Please try again later"
              );
            }
          })
          .catch(() => {
            message.error("Could not export file please try again");
          })
          .finally(() => {});
        return true;
      } else {
        return true;
      }
    } else {
      handleShowWarning(
        `Please Choose a date range maximum of ${
          isStore ? "Six months" : "One month"
        }  to download day book report.`
      );
      return true;
    }
  };

  const handleOnPageChange = (page, pageSize) => {
    console.log(page, pageSize);
    setpageNo(page);

    window.scrollTo(0, 0);
  };

  const handleFetchDayBookReport = (pageNo) => {
    const payload = {
      offset: (pageNo - 1) * 10,
      size: 10,
      accountType: cookies.get("accountType"),
      // userId: cookies.get("userId"),
      // sortType: "desc",
      sortBy: props?.sort?.sortType ? props?.sort?.sortType : "orderedDate",
      sortType: props?.sort?.sortBy ? props?.sort?.sortBy : "desc",
      key: props.searchVal,

      vendorId: vendorId ? vendorId : props.vendorFilter
    };

    if (props?.sort?.groupBy) {
      payload["type"] = props?.sort?.groupBy;
    }

    if (props.dateRange) {
      payload["startTime"] = props.dateRange.startTime;
      payload["endTime"] = props.dateRange.endtime;
    }
    setIsLoadingReport(true);
    // handleUpdateColumns();
    fetchDayBookReport(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            console.log("reponse for day book report: ", response.data.documents);
            settotalSize(response.data.count);
            setdataSource(
              formatDayBookReport(
                response.data.documents,
                response?.data?.count ? (pageNo - 1) * 10 : 0
              )
            );
          }
        }
      })
      .finally(() => {
        setIsLoadingReport(false);
      });
  };

  useEffect(() => {
    console.log("sort:", props.sort);
    handleFetchDayBookReport(pageNo);
  }, [
    props.sort,
    props.brandId,
    props.dateRange,
    props.searchVal,
    props.vendorFilter,
    pageNo,
  ]);

  useEffect(() => {
    const payload = {
      groupBy: "",
      sortType: "",
      sortBy: "",
    };
    props.fetchSort(payload);
  }, []);

  return (
    <Fragment>
      <ReportsTable
        download={handleExportDayBookReport}
        columns={
          cookies.get("accountType") == "admin"? 
          dayBookReportColumnsAdmin : dayBookReportColumns
        }
        dataSource={dataSource}
        type={"Day Book"}
        reportSummary={reportSummary}
        summary={
          totalSize
            ? ""
            : "The latest 10 records are listed below. To get the complete records report, download csv"
        }
        isLoadingReport={isLoadingReport}
      />
      {totalSize > 10 && (
        <Pagination
          className="customer-pagination"
          current={pageNo}
          total={totalSize > 10000 ? 10000 : totalSize}
          showSizeChanger={false}
          onChange={handleOnPageChange}
        />
      )}
    </Fragment>
  );

};

const mapStateToProps = (state) => {
    return {
      sort: state.customerData,
      brandId: state.reportReducer.brandId,
      dateRange: state.reportReducer.dateRange,
      vendorFilter: state.reportReducer.vendorId,
    };
  };
  const mapDispatchToProps = {
    fetchSort,
  };

export default connect(mapStateToProps, mapDispatchToProps)(DayBookReport);