

import React, { useState, useEffect } from "react";

import { Modal, Button, Typography, Divider, message } from "antd";

import { withRouter } from "react-router-dom";

import "./emailVerification.css";

import CompanyLogo from "../../../Assets/Images/Logo/DL_Logo.png";
import CircleLeft from "../../../Assets/Images/Icons/circ 1.svg";
import CircleRight from "../../../Assets/Images/Icons/circ 2.svg";

import Loading from "../Loading";

import { ENV_TYPE, DOMAIN, SSLHTTP } from "../../../Utils/constants";

import { activateAccount } from "../../../ApiAction/CustomerApiActions/customerApi";

const { Text, Title } = Typography;
const EmailVerificationModal = (props) => {
  const { visible, setvisible } = props;
  const [userType, setuserType] = useState();
  const [userName, setuserName] = useState();
  const [resMessage, setResMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOk = (e) => {
    console.log(e);
    // setvisible(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    // setvisible(false);
  };

  const handleRedirectToLoginPage = () => {
    if (ENV_TYPE === "production") {
      switch (userType) {
        case "customer":
          window.location.href = `${SSLHTTP}://${DOMAIN}/`;
          break;

        case "seller":
          window.location.href = `${SSLHTTP}://seller.${DOMAIN}/`;
          break;

        case "store":
          window.location.href = `${SSLHTTP}://agency.${DOMAIN}/`;
          break;

        case "warehouse":
          window.location.href = `${SSLHTTP}://hub.${DOMAIN}/`;
          break;

        default:
          console.log("invalid choice");
      }
    } else {
      switch (userType) {
        case "customer":
          props.history.push(`/customer`);
          break;

        case "seller":
          props.history.push(`/login-seller`);
          break;

        case "store":
          props.history.push(`/login-store`);
          break;

        case "warehouse":
          props.history.push(`/login-warehouse`);
          break;

        default:
          console.log("invalid choice");
      }
    }
  };

  useEffect(() => {
    console.log("activation key: ", props.match.params.activationId);
    setLoading(true);
    activateAccount(props.match.params.activationId)
      .then((response) => {
        console.log("response for account activation: ", response);
        if (response.status === 200) {
          if (response.data.status === "success") {
            setSuccess(true);
            setuserType(response.data.accountType);
            setuserName(response.data.name);
          } else {
            setSuccess(false);
            setuserType(response.data.accountType);
            setResMessage(response.data.message);
          }
        }
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [props.match.params]);

  return (
    <Modal
      visible={visible}
      closable={false}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={600}
    >
      <div className="email_verification_sucessModal">
        <img
          src={CompanyLogo}
          alt="company_logo"
          className="confirmation_company_logo"
        />
        <div className="email_verification_sucessModal_content">
          {loading && <Loading/>}
          {success ? (
            <React.Fragment>
              <Text strong style={{ fontSize: "18px" }}>
                Welcome!
              </Text>

              <Title
                level={4}
                style={{
                  marginTop: "0px",
                  fontSize: "32px",
                  fontWeight: "500",
                }}
              >
                {userName}
              </Title>

              <Divider />

              <Text strong>
                You have successfully created your account. Now you can
                explore thousands of products across our platform, ready to be
                delivered at your doorsteps.
              </Text>
            </React.Fragment>
          ) : (
            <Text strong>{resMessage}</Text>
          )}
          <br />
          <Button
            size="large"
            type="primary"
            className="confirm_activation_btn"
            onClick={() => handleRedirectToLoginPage()}
          >
            CONTINUE TO LOGIN
          </Button>

          <div className="verification_success_vector">
            <img
              style={{ width: "100px" }}
              src={CircleLeft}
              alt="circle_left"
            />
            <img
              style={{ width: "100px" }}
              src={CircleRight}
              alt="circle_right"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(EmailVerificationModal);
