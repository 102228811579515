


import Cookies from "universal-cookie";

import Axios from "../axios";
import { API_SERV_ADDR } from "../../Utils/constants";

const cookies = new Cookies();

//Add customer address
export const addAddress = (payload) => {
  const data = {
    ...payload,
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/user/address/create`,
    method: "PUT",
    data: data,
  });
};

//fetch a users addresslist
export const fetchUserAddressList = (userId) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/${cookies.get("userId")}/address/all`,
    method: "GET",
  });
};

//edit customer address
export const editAddress = (payload) => {
  const data = {
    ...payload,
    userId: cookies.get("userId"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/user/address/update`,
    method: "POST",
    data: data,
  });
};

//delete customer address
export const deleteAddress = (payload) => {
  const data = {
    ...payload,
    userId: cookies.get("userId"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/user/address/delete`,
    method: "DELETE",
    data: data,
  });
};

//set as default address
export const setasDefaultAddress = (payload) => {
  const data = {
    ...payload,
    userId: cookies.get("userId"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/user/address/set-default`,
    method: "POST",
    data: data,
  });
};

export const getDeliveryCharge = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/cart/calculate-delivery-charge_subscription`,
    method: "POST",
    data: payload
  })
}
