import { Divider, message, Pagination } from 'antd';
import Search from 'antd/lib/input/Search';
import React, {useEffect,useState} from 'react';
import { MdSearch } from 'react-icons/md';
import { fetchHighestDiscountProducts } from '../../../ApiAction/AdminApiAction/adminApi';
import dummyImage from "../../../Assets/Images/PlaceHolderImages/Product_Image_Placeholder.jpg";
import { FILE_PATH } from '../../../Utils/constants';
import LoadingCartItem from '../../Customer/Components/Cart/loadingCartItem';
import EmptyList from '../../GeneralComponents/emptyList';
import Loading from '../../GeneralComponents/Loading';

function DiscoutProducts(props) {
  const [discountProducts, setDiscountProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [searchedProduct, setSearchedProduct] = useState("");


  //For Pagination
  const handlePagination = (page) => {
    setOffset(50 * (page - 1));
    setCurrentPage(page);
  };

  const handleProductSearch = (searchWord) =>{
    const payload = {
      searchKey: searchWord,
      size: 50,
      offset: offset,
      sortBy: "highestDiscount.discount",
      sortType: "desc",
  }
  setIsLoading(true);
  setDiscountProducts([]);
  fetchHighestDiscountProducts(payload).then((response) => {
    if(response?.data?.status === "success"){
      setDiscountProducts(response?.data?.Product);
      setTotalPage(response?.data?.count);
    }
    else{
      setDiscountProducts([]);
    }
  })
  .finally(() => {
    setIsLoading(false);
  });
  };


  useEffect(() =>{
    const payload = {
        searchKey: "",
        size: 50,
        offset: offset,
        sortBy: "highestDiscount.discount",
        sortType: "desc",
    }
    setIsLoading(true);
    fetchHighestDiscountProducts(payload).then((response) => {
      if(response?.data?.status === "success"){
        setDiscountProducts(response?.data?.Product);
        setTotalPage(response?.data?.count);
      }
      else{
        setDiscountProducts([]);
      }
    })
    .finally(() => {
      setIsLoading(false);
    });
  },[offset]);
    
    return (
        
    <div className="category-management-container">
    <h1 className="content-name-header sub-title-tags" level={4}>
      <MdSearch /> Highest Discounted Products
    </h1>
    <Divider />
    <Search
      placeholder="Search here."
      size="large"
      // loading={barcodeSearching}
      // value={searchedProduct}
      onChange={(e) => handleProductSearch(e.target.value)}
      onSearch={(value) => handleProductSearch(value)}
    />
    {isLoading && <LoadingCartItem/>}
    <div className="barcode-main-container-view">
    {discountProducts?.map((data, index) => (
      <div className="barcode-container-view-box" key={index}>
        <div className="barcode-container-image-box">
          {data?.images?.length > 0 ? (
            <img
              alt="Product Pic"
              src={FILE_PATH + data?.images[0]}
              className="barcode-container-product-image"
            />
          ) : (
            <img
              alt="Product Pic"
              src={dummyImage}
              className="barcode-container-product-image"
            />
          )}
        </div>
        <div>
          <h2 className="barcode-container-main-heading">{data?.name}</h2>
          <h3>
            {data?.vendor}
            <strong>[{data?.vendorType}]</strong>
          </h3>

          <Divider />

          <div className="barcode-variant-container">
            {data?.productBatches?.filter((item)=> item?.active !== false )?.map((batches, index2) => (
              <>
                {batches?.priceAndStocks?.map((priceAndStock, index3) => (
                  <div
                    className={
                      priceAndStock?.discount === data?.highestDiscount?.discount
                        ? "searchedBarcode-variant"
                        :
                         "barcode-variant-small-box"
                }
                    key={index3}
                  >
                    <h4>
                      <strong>
                        {priceAndStock?.quantity} {priceAndStock?.unit}
                      </strong>
                    </h4>
                    <p>
                      STOCKS <strong>{priceAndStock?.stock}</strong>
                    </p>
                    <p>
                      MRP <strong>{priceAndStock?.price}</strong>
                    </p>
                    <p>
                      DISCOUNT <strong>{priceAndStock?.discount?.toFixed(2) + " %"}</strong>
                    </p>
                    <p>
                      PRICE <strong>{priceAndStock?.sellingPrice}</strong>
                    </p>
                  </div>
                ))}
              </>
            ))}
          </div>
        </div>
      </div>
    ))}
    {isLoading === false && discountProducts?.length === 0 &&
      <EmptyList
        msg={
          "Sorry! No product found"
        }
      />
    }
  </div>
    <Pagination
          size="small"
          total={totalPage}
          current={currentPage}
          pageSize={50}
          // defaultCurrent={defaultPage}
          showSizeChanger={false}
          className="customer-pagination"
          onChange={(page) => {
            handlePagination(page);
            window.scrollTo(0, 0);
          }}
        />
  </div>
    );
}

export default DiscoutProducts;