


import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Typography,
  Divider,
  Form,
  Select,
  Input,
  message,
} from "antd";

import "./requestReturn.scss";
import {
  getCancelMessage,
  getReturnMessage,
} from "../../../../../ApiAction/AdminApiAction/adminApi";

const { Option } = Select;
const CancelOrderRequest = (props) => {
  const [productReturnList, setProductReturnList] = useState([]);
  const [cancelMessage, setCancelMessage] = useState("");

  useEffect(() => {
    getReturnList();
  }, []);

  const getReturnList = () => {
    getCancelMessage().then((res) => {
      if (res.data.status == "success") {
        setProductReturnList(res.data.cancelReasons);
      }
    });
  };

  const handleCancel = () => {
    props.setVisible(false);
  };

  const handleReturnRequest = () => {
    props.setSuccess(cancelMessage);
  };
  return (
    <Modal
      title={props.cancelItem ? "Cancel Item" : "Cancel Order"}
      visible={props.visible}
      onOk={() => props.setVisible(false)}
      onCancel={() => props.setVisible(false)}
      footer={null}
      width={500}
      className="cancle-reason-pop"
    >
      <div className="add-product-tile" style={{ marginBottom: "25px" }}>
        Cancel Reason
        <Select
          onSelect={(value, option, info) => {
            setCancelMessage(option.value);
          }}
          onChange={(value, info) => console.log(info)}
          value={cancelMessage}
          style={{ width: "100%" }}
        >
          {productReturnList.map((item) => (
            <Option value={item}>{item}</Option>
          ))}
        </Select>
      </div>

      <div className="seller-action-button">
        <Button
          style={{ width: "200px" }}
          className="button-seller-request-reject"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>

        <Button
          style={{ width: "200px" }}
          onClick={() => handleReturnRequest()}
          className="button-seller-request-approve"
        >
          {props.cancelItem ? "Cancel Item" : "Cancel Order"}
        </Button>
      </div>
    </Modal>
  );
};

export default CancelOrderRequest;
