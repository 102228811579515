


import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import {
  Modal,
  Divider,
  Typography,
  Input,
  Row,
  Col,
  Image,
  Button,
  Pagination,
} from "antd";

import { fetchSort } from "../../../../ReduxStore/action";

import "./manageReturnsCustomer.scss";
import onlineIcon from "../../../../Assets/Images/Icons/Seller/online-shopping.svg";
import calendarIcon from "../../../../Assets/Images/Icons/Seller/calendar.svg";
import RequestDetails from "./requestDetails";
import {
  getCustomerReturns,
  getCustomerReturnsStatusUpdate,
} from "../../../../ApiAction/CustomerApiActions/orderAPI";
import { FILE_PATH } from "../../../../Utils/constants";
import ReturnStatusCustomer from "./returnStatusCustomer";
import EmptyList from "../../../GeneralComponents/emptyList";
import LoadingCartItem from "../Cart/loadingCartItem";
const { Text } = Typography;
const ManageReturnsCustomer = (props) => {
  const cookies = new Cookies();

  const [detailModal, setDetailModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const [productItem, setProductItem] = useState();
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalCount, settotalCount] = useState();

  useEffect(() => {
    getProductsList(props.searchVal, 1);
  }, [props]);
  useEffect(() => {
    props.fetchSort({ ...props.sort, sortType: "date", sortBy: "desc" });
  }, []);

  const getSortType = (sortType) => {
    switch (sortType) {
      case "name":
        return "productName";
        break;
      case "date":
        return "requestedDate";
        break;
      default:
        console.log("invalid type");
    }
  };

  const getProductsList = (search = "", page = 1) => {
    setLoading(true);

    console.log("current page number: ", page);

    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      userId: cookies.get("userId"),
      key: search,
      sortBy: props?.sort
        ? props.sort?.sortType
          ? getSortType(props.sort.sortType)
          : "requestedDate"
        : "requestedDate",
      sortType: props
        ? props.sort && props.sort.sortBy
          ? props.sort.sortBy
          : "desc"
        : "desc",
    };
    getCustomerReturns(payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(response.data.documents);
          setPage(Math.ceil(response.data.count / 10) * 10);
          settotalCount(response.data.count);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const setSuccess = (payload) => {
    setUpdateModal(false);
    getCustomerReturnsStatusUpdate(payload)
      .then((response) => {
        if (response.data.status == "success") {
          getProductsList(props.searchVal, 1);
        }
      })
      .catch(() => {});
  };
  return (
    <div
      className="manage_orders_container_card admin-manage-customer"
      style={{ paddingTop: "10px" }}
    >
      <Text strong className="manae_orders_title">
        My Returns
      </Text>
      {loading ? (
        <div className="loading-cart-container">
          <LoadingCartItem />
        </div>
      ) : (
        <div>
          {productList.length === 0 && !loading && (
            <EmptyList msg={"No items found"} />
          )}
          {productList.map((data) => (
            <div
              className="justify-space-between customer-return-list"
              key={data.id}
            >
              <div className="flex custr_mng_rtrn">
                <div className="return_pdt_img">
                  <img
                    className="manage-products-draft-product-card-image"
                    src={FILE_PATH + data.source.image}
                    alt={data.source.productName}
                  />
                </div>
                <div className="return-data-card-seller">
                  <div className="seller-return-product-name">
                    {data.source.productName}
                  </div>
                  <div className="flex">
                    <div
                      className="seller-return-product-price"
                      style={{ marginRight: "12px" }}
                    >
                      ₹ {data.source.itemCurrentPrice.sellingPrice}
                    </div>
                    <div
                      className="seller-return-name"
                      style={{ paddingBottom: "0px" }}
                    >
                      {data.source.itemCurrentPrice.quantity +
                        " " +
                        data.source.itemCurrentPrice.unit}
                    </div>
                    <div
                      className="seller-return-product-price"
                      style={{ fontSize: "13px" }}
                    >
                      <Text
                        style={{
                          color: "gray",
                          marginLeft: "15px",
                          fontWeight: "500",
                        }}
                      >
                        Qty: {data.source.quantity}
                      </Text>
                    </div>
                  </div>
                  <div className="seller-return-product-price">
                    <Text delete style={{ color: "#c1c1c1" }}>
                      ₹ {data.source.itemCurrentPrice.price}
                    </Text>
                  </div>

                  <div className="flex-align-center date_return rtn-mob">
                    <img
                      src={calendarIcon}
                      style={{ width: "16px" }}
                      className="verified-icon"
                      alt="calendarIcon"
                    />
                    {moment(data.source.requestedDate).format(
                      " MMM DD, hh:mm A"
                    )}
                  </div>
                  <div className="flex order-item-active ord_typ rtn-mob">
                    <img
                      src={onlineIcon}
                      style={{ width: "20px" }}
                      className="verified-icon"
                      alt="onlineIcon"
                    />
                    {data.source.paymentMode}
                  </div>
                </div>
              </div>
              <div className="mob_visible_rtn_data custr_mng_rtrn justify-content-between">
                <div className="frm_ip_small">
                  <div className="flex-align-center date_return">
                    <img
                      src={calendarIcon}
                      style={{ width: "16px" }}
                      className="verified-icon"
                      alt="calendarIcon"
                    />
                    {moment(data.source.requestedDate).format(
                      " MMM DD, hh:mm A"
                    )}
                  </div>
                  <div className="flex order-item-active ord_typ">
                    <img
                      src={onlineIcon}
                      style={{ width: "20px" }}
                      className="verified-icon"
                      alt="onlineIcon"
                    />
                    {data.source.paymentMode}
                  </div>
                </div>

                <div
                  className="order-item-active rqst_status"
                  style={{ textAlign: "end" }}
                  datastatus={"request-" + data.source.status.toLowerCase()}
                >
                  REQUEST{" " + data.source.status}
                </div>
              </div>
              <div className="algn_retn_data">
                <div
                  className="order-item-active rqst_status rtn-mob"
                  style={{ textAlign: "end" }}
                  datastatus={"request-" + data.source.status.toLowerCase()}
                >
                  REQUEST{" " + data.source.status}
                </div>

                <div className="date-ac" style={{ textAlign: "end" }}>
                  {moment(
                    data.source?.statusModifiedDate
                      ? data.source?.statusModifiedDate
                      : data.source?.requestedDate
                  ).format(" MMM DD")}
                </div>
                <div> ORDER ID{" : " + data.source.groupOrderId}</div>
                <div
                  className="btn_grps"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Button
                    style={{
                      minWidth: "160px",
                      padding: "0",
                      marginTop: "10px",
                      height: "38px",
                      borderColor: "#0074be",
                      backgroundColor: "#0074be",
                      color: "#fff",
                      fontWeight: "500",
                    }}
                    onClick={() => {
                      setProductItem(data);
                      setDetailModal(true);
                    }}
                    className="button-seller-request-approve"
                  >
                    View Details
                  </Button>
                  {data.source.vendorType === "SELLER" &&
                    data.source.status === "ACCEPTED" && (
                      <Button
                        style={{
                          // width: "160px",
                          minWidth: "160px",
                          padding: "0",
                          marginTop: "10px",
                          marginLeft: "15px",
                          height: "38px",
                          fontWeight: "500",
                          border: "1px solid black",
                          color: "black",
                        }}
                        onClick={() => {
                          setProductItem(data);
                          setUpdateModal(true);
                        }}
                        // className="seller-manage-products-card-action-button"
                      >
                        Update Status
                      </Button>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {totalCount > 10 && (
        <div className="admin-manage-customer">
          <Pagination
            defaultCurrent={1}
            defaultPageSize={10}
            total={totalCount}
            showSizeChanger={false}
            className="customer-pagination"
            onChange={(page) => {
              getProductsList(props.searchVal, page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}

      {detailModal && (
        <RequestDetails
          detailModal={detailModal}
          setCancel={setDetailModal}
          data={productItem}
        />
      )}
      {updateModal && (
        <ReturnStatusCustomer
          visible={updateModal}
          selectedItems={productItem}
          setCancel={setUpdateModal}
          saveChanges={setSuccess}
        />
      )}
    </div>
  );
};
const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageReturnsCustomer);
