


import React, { useState, useEffect } from "react";
import { Layout, Dropdown, Button, Menu, Typography } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { fetchSort, fetchSortBy } from "../../../ReduxStore/action";
import { connect } from "react-redux";
const { Text } = Typography;
const GroupByReport = (props) => {
  const [sortValue, setSortValue] = useState("None");

  useEffect(() => {}, []);
  const renderSortStatus = () => {
    return (
      <Menu>
        <Menu.Item
          onClick={() => {
            setSortValue("None");

            const payload = {
              groupBy: "",
            };
            props.fetchSort(payload);
          }}
        >
          None
          {sortValue === "None" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setSortValue("Invoice Number");
            const res = "orderGroupId";
            const payload = {
              groupBy: "orderGroupId",
            };

            props.fetchSort(payload);
          }}
        >
          Invoice Number
          {sortValue === "Invoice Number" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setSortValue("Product Name");
            const re = "productName";
            const payload = {
              groupBy: "productName",
            };

            props.fetchSort(payload);
            // this.handleSelectChange("open", "projectStatus");
            // this.props.searchFilterStore.projectStatusFilterChange("open");
          }}
        >
          Product Name
          {sortValue === "Product Name" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setSortValue("Product Name Variant");
            const res = "productNameVariant";
            const payload = {
              groupBy: "productNameVariant",
            };

            props.fetchSort(payload);
          }}
        >
          Product Variant Name
          {sortValue === "Product Name Variant" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setSortValue("Batch Number");
            const res = "batchNumber";

            const payload = {
              groupBy: "batchNumber",
            };

            props.fetchSort(payload);
          }}
        >
          Batch Number
          {sortValue === "Batch Number" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </Menu.Item>
      </Menu>
    );
  };
  return (
    <Dropdown
      overlay={() => renderSortStatus()}
      placement="bottomCenter"
      trigger={["click"]}
      className="sort-dropdown-admin"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Button style={{ height: "40px" }}>
        <span style={{ color: "#827e7e" }}>Group By: </span>
        <span>
          {" "}
          <Text strong style={{ color: "red" }}>
            &nbsp;
            {sortValue}
          </Text>
        </span>
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};
const mapDispatchToProps = {
  fetchSort,
  fetchSortBy,
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupByReport);
