


import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";


export default ({style}) => {
const antIcon = <LoadingOutlined style={{ fontSize: 24, color: style?.color||"red" }} spin />;
  return (
    <div style={style||{}} className="flex" style={{justifyContent:"center"}}>
      <Spin indicator={antIcon} />
    </div>
  );
};
