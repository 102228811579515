import React, { Fragment, useState, useEffect } from 'react'
import { Tabs } from 'antd';
import { FILE_PATH } from '../../../Utils/constants';
import { FiChevronDown } from "react-icons/fi";
import { IoIosTimer } from "react-icons/io";
import { message } from 'antd';
import moment from 'moment';
import ViewBrands from './ViewBrands';
import { deleteAds } from '../../../ApiAction/AdminApiAction/adminApi'

function ViewAds(props) {

    const { TabPane } = Tabs;
    const { data, reloadData, setReloadData, primaryKey, reloadAds } = props;
    const [ total3Images, setTotal3Images ] = useState([])
    const [ expandArea, setExpandArea ] = useState(false)
    const [ totalAds, setTotalAds ] = useState(0)

    useEffect(() => {
        calCulateImages()
        return (()=> {
            setExpandArea(false)
        })
    }, [props])

    const calCulateImages = () => {
        let bannerLength = data?.advertisementBanner !== null ? data?.advertisementBanner?.length : 0;
        let cardLength = data?.advertisementCard !== null ? data?.advertisementCard?.length : 0;
        let totalAds = bannerLength + cardLength;
        let totalImages = []
        let displacement = 0
        let temp = data?.advertisementBanner?.map((banner)=> {
            totalImages.push(banner?.images?.map((image)=> {
                return image
            }))
        })
        temp = data?.advertisementCard?.map((card)=> {
            totalImages.push(card?.images?.map((image)=> {
                return image
            }))
        })
        console.log("Images :: ")
        console.log(totalImages)
        if(totalImages.length < 3) {
            displacement = 3 - totalImages.length;
        }
        console.log("Displacement :: ", displacement)
        for(let i = 0; i < displacement; i++) {
            totalImages.push(totalImages[0])
        }
        setTotal3Images(totalImages)
        setTotalAds(totalAds)
    }

    const deleteAd = (adId) => {
        console.log("Payload :: ", data)
        const payload = {
            categoryId: data?.categoryId,
            subCategoryId: data?.subCategoryId,
            advertisementId: adId
        }
        deleteAds(payload).then((response)=> {
            if(response?.data?.status === 'success') {
                message.success(response?.data?.message)
            }else {
                message.error(response?.data?.message)
            }
        }).catch((error)=> {
            message.error(error.message)
        }).finally(()=> {
            setReloadData(!reloadData)
        })
    }    

    return (
      <div className="ads-each-list">
        <div className="ads-each-top-part">
          <span style={{ display: "flex", alignItems: "center" }}>
            <div className="ads-each-top-3-img-box">
              {total3Images.map((images, index) => {
                if (index < 3) {
                  return (
                    <img
                      loading="lazy"
                      src={FILE_PATH + images}
                      className="ads-each-top-3-images"
                    />
                  );
                }
              })}
            </div>
            <span className="ads-each-cat-box">
              <strong>{data?.categoryName}</strong>
              <h5>{data?.subCategoryName}</h5>
            </span>
          </span>
          <span style={{ display: "flex", columnGap: "3em" }}>
            <div className="ads-each-flex-box">
              <IoIosTimer style={{ fontSize: "1.4rem" }} />
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0.5em",
                }}
              >
                <p>RECENT UPDATED</p>
                <strong>
                  {moment(data?.lastModifiedDate).format("YYYY-MM-DD hh:MM A")}
                </strong>
              </span>
            </div>
            <FiChevronDown
              onClick={() => setExpandArea(!expandArea)}
              className="ads-each-drop-icon"
            />
          </span>
        </div>
        {expandArea && (
          <div className="ads-each-bottom-part">
            <div className="ads-each-banner-and-card-area">
              <Tabs>
                {data?.advertisementBanner !== null && (
                  <TabPane key="1" tab="Banners">
                    {data?.advertisementBanner?.map((bannerData) => (
                      <ViewBrands
                        primaryKey={primaryKey}
                        allData={data}
                        brandData={bannerData}
                        type="banner"
                        deleteAd={deleteAd}
                        reloadAds={reloadAds}
                      />
                    ))}
                  </TabPane>
                )}
                {data?.advertisementCard !== null && (
                  <TabPane key="2" tab="Cards">
                    {data?.advertisementCard?.map((cardData) => (
                      <ViewBrands
                        primaryKey={primaryKey}
                        allData={data}
                        brandData={cardData}
                        type="card"
                        deleteAd={deleteAd}
                        reloadAds={reloadAds}
                      />
                    ))}
                  </TabPane>
                )}
              </Tabs>
            </div>
          </div>
        )}
      </div>
    );
}

export default ViewAds
