import   React from 'react'
import { usePalette } from 'react-palette'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { FILE_PATH } from '../../../../Utils/constants'
import { ENV_TYPE } from '../../../../Utils/constants';
import { addSellerId, addStoreId, addBrandFilter, addSubCategory00filter } from '../../../../ReduxStore/action/productActions'

function FmcgBrandAds(props) {

    const { banner, adInfo, categoryId, categoryName } = props;

    const adImage = FILE_PATH + banner;

    const { data } = usePalette(adImage)

    const handleRedirection = (categoryName, Id) => {
        props.addSubCategory00filter(Id)
        if(adInfo?.advertisementType === 'STORES') {
            props.addStoreId([adInfo?.id])
        }else if(adInfo?.advertisementType === 'SELLERS') {
            props.addSellerId([adInfo?.id])
        }else {
            props.addBrandFilter([adInfo?.id])
        }        
          handleManageBreadCrumb(categoryName, Id);
          props.history.push(
            ENV_TYPE === "production"
            ? `/product-listing/${categoryName}`
            : `/customer/product-listing/${categoryName}`,
          );
      }; 
      
      const handleManageBreadCrumb = (categoryName, Id) => {
        let breadCrumbList = [];
        let breadcrumb = {
          url:
            ENV_TYPE === "production"
            ? `/product-listing/${categoryName}`
            : `/customer/product-listing/${categoryName}`,
          type: "category",
        };
        breadcrumb["label"] = categoryName;
        breadcrumb["id"] = Id;
    
        breadCrumbList.push(breadcrumb);
        localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
      };     


    return (
        <div className="fmcg-brand-each-ads"
         style={{background: data.lightMuted}}
         onClick={()=>handleRedirection(categoryName, categoryId)} >
            <img src={adImage} loading="lazy" className="fmcg-brand-each-ads-image" />
        </div>
    )
}

const mapDispatchToProps = {
    addSellerId,
    addStoreId,
    addBrandFilter,
    addSubCategory00filter
}

//export default FmcgBrandAds
export default withRouter(
    connect(null, mapDispatchToProps)(FmcgBrandAds)
  );
