


import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import { Row, Col, message, Modal } from "antd";

import {
  deleteAddress,
  setasDefaultAddress,
} from "../../../../../ApiAction/CustomerApiActions/addressAPI";
import { fetchUserAddressList } from "../../../../../ApiAction/CustomerApiActions/addressAPI";

import AddressCard from "./addressCard";
import AddAddress from "../../Cart/addAddress";

const confirm = Modal.confirm;
const AddressList = (props) => {
  const [addressList, setaddressList] = useState([]);
  const [loading, setloading] = useState(false);
  const [errorLoading, seterrorLoading] = useState(false);
  const [addressModalVisible, setaddressModalVisible] = useState(false);
  const [editItem, seteditItem] = useState();

  //edit address
  const handleEditAddress = (address) => {
    seteditItem(address);
    setaddressModalVisible(true);
  };

  //this act as confirm modal for both remove Address and setAs default Address
  const handleConfirmationModal = ({ remove, setAsDefault, address }) => {
    const msg = remove
      ? "Do you want to delete this address"
      : "Do you want to set this address as default address";

    confirm({
      title: msg,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        remove
          ? handleDeleteAddress(address)
          : handleSetDefaultAddress(address);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  ////delete address
  const handleDeleteAddress = (data) => {
    const payload = {
      addressId: data.id,
    };

    deleteAddress(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(response.data.message);
            // fetchAddressList();

            //updating address list
            setaddressList((prevAddressList) => {
              return prevAddressList.filter((address) => {
                return address.id !== data.id; //removing the deleted address from local state
              });
            });
          } else message.error(response.data.message);
        }
      })
      .catch(() => {});
  };

  //set as default address
  const handleSetDefaultAddress = (data) => {
    const payload = {
      addressId: data.id,
    };

    setasDefaultAddress(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(response.data.message);
            // fetchAddressList();

            //updating addressList
            setaddressList((prevAddressList) => {
              return prevAddressList.map((address) => {
                return {
                  ...address,
                  primary: address.id === data.id ? true : false,
                }; //updating primary field of updated address in local state
              });
            });
          } else message.error(response.data.message);
        }
      })
      .catch(() => {});
  };

  const fetchAddressList = () => {
    fetchUserAddressList()
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setaddressList(response.data.address);
          } else seterrorLoading(true);
        } else seterrorLoading(true);
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };

  //fetching addressList
  useEffect(() => {
    setloading(true); //loading indicator needs to be shown only when the page initialy loads
    fetchAddressList();
  }, []);

  useEffect(() => {
    fetchAddressList();
  }, [props.refetchAddressList]);

  return (
    <Fragment>
      {loading && !errorLoading ? (
        <Row gutter={[16, 24]}>
          {[1, 2, 3, 4, 5, 6].map((data) => {
            return (
              <Col className="gutter-row address_card_container" span={12}>
                <AddressCard loading />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Row gutter={[16, 24]}>
          {addressList.map((data) => {
            return (
              <Col className="gutter-row address_card_container" span={12}>
                <AddressCard
                  data={data}
                  handleEditAddress={handleEditAddress}
                  handleAction={handleConfirmationModal}
                />
              </Col>
            );
          })}
        </Row>
      )}

      <AddAddress
        visible={addressModalVisible}
        editItem={editItem}
        setVisible={setaddressModalVisible}
        isEdit
        // setSuccess={setSuccesRegister}
      />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    refetchAddressList: state.customerAddressReducer.refetchAddressList,
  };
};

export default connect(mapStateToProps, null)(AddressList);
