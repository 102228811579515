


import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { connect } from "react-redux";

import ManageCustomerTable from "./manageCustomerTable";
import { fetchUsers } from "../../../ApiAction/AdminApiAction/adminApi";

const { Title } = Typography;
const ManageCustomer = (props) => {
  const [customerData, setCustmerData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingCustomer, setIsLoadingCustomer] = useState(false);

  useEffect(() => {
    fetchData(1, props.searchVal);
  }, [props]);

  const fetchData = (page, search = props.searchVal) => {
    setCurrentPage(page)
    const term = [];

    term.push({
      accountType: ["customer"],
    });

    if (props.sort && props.sort.all === false) {
      term.push({
        isArchived: [
          props.sort ? (props.sort.archive ? "true" : "false") : "false",
        ],
      });
      term.push({
        block: [props.sort ? (props.sort.block ? "true" : "false") : "false"],
      });
    }

    const payload = {
      key: search,
      sort: props.sort ? props.sort.sortType : "date",
      sortBy: props.sort ? props.sort.sortBy : "asc",
      accountType: "customer",
      offset: 10 * (page - 1),
      size: 10,
      filter: {
        term: term,
      },
    };
    setIsLoadingCustomer(true);
    fetchUsers(payload)
      .then((response) => {
        setCustmerData(response.data.documents);
        setTotal(Math.ceil(response.data.count / 10) * 10);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingCustomer(false);
      });
  };

  return (
    <div className="admin-manage-customer">
      <h1 className="content-name-header sub-title-tags" level={4}>
        {" "}
        Customer
      </h1>

      <ManageCustomerTable
        userData={customerData}
        total={total}
        currentPage={currentPage}
        onPageChange={fetchData}
        isLoadingCustomer={isLoadingCustomer}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps)(ManageCustomer);
