


import React, { useState, useEffect } from "react";
import { Typography, Pagination, message } from "antd";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";

import { connect } from "react-redux";
import ReturnProductData from "./returnProductData";
import SellerReturnDetails from "./sellerReturnDetails";
import {
  getSellerReturns,
  updateSellerStatus,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import EmptyList from "../../GeneralComponents/emptyList";
import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";

import { fetchSort } from "../../../ReduxStore/action";

const { Title, Text } = Typography;
const ManageReturns = (props) => {
  const cookies = new Cookies();
  const [loading, setloading] = useState(true);

  const [productList, setProductList] = useState([]);
  const [totalCount, settotalCount] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [detailModal, setDetailModal] = useState(false);
  const [productItem, setProductItem] = useState();
  useEffect(() => {
    props.fetchSort({ ...props.sort, sortType: "date", sortBy: "desc" });
  }, []);
  useEffect(() => {
    getProductsList(props.searchVal, 1);

    console.log(props.sort, ":sort values");
  }, [props]);

  const getSortType = (sortType) => {
    switch (sortType) {
      case "name":
        return "productName";
        break;
      case "date":
        return "requestedDate";
        break;
      default:
        console.log("invalid type");
    }
  };

  const getProductsList = (search = "", page = 1) => {
    setCurrentPage(page);
    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      userId: cookies.get("vendorId"),
      key: search,
      sortBy: props?.sort
        ? props.sort?.sortType
          ? getSortType(props.sort.sortType)
          : "requestedDate"
        : "requestedDate",
      sortType: props
        ? props.sort && props.sort.sortBy
          ? props.sort.sortBy
          : "desc"
        : "desc",
    };
    getSellerReturns(payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(response.data.documents);
          settotalCount(Math.ceil(response.data.count / 10) * 10);
        }
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };

  const openDetail = (item) => {
    setProductItem(item);
    setDetailModal(true);
  };

  const updateStatus = (payload) => {
    setDetailModal(false);
    updateSellerStatus(payload)
      .then((response) => {
        if (response.data.status == "success") {
          message.success(response.data.message);
          getProductsList(props.searchVal, currentPage);
        }
      })
      .catch(() => {});
  };

  return (
    <div className="manage-products-container">
      <div className="manage-products-header">
        <h1 level={4} className="sub-title-tags">
          {" "}
          Manage Returns
        </h1>
      </div>
      {loading ? (
        <LoadingCartItem />
      ) : (
        <>
          {productList.length === 0 && !loading ? (
            <EmptyList msg={"No returns found"} /> //case when orders list is empty
          ) : (
            productList.map((data) => (
              <ReturnProductData data={data} openDetail={openDetail} />
            ))
          )}
        </>
      )}

      {totalCount > 10 && (
        <div className="admin-manage-customer">
          <Pagination
            defaultCurrent={1}
            defaultPageSize={10}
            current={currentPage}
            total={totalCount}
            showSizeChanger={false}
            className="customer-pagination"
            onChange={(page) => {
              getProductsList("", page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}

      {detailModal && (
        <SellerReturnDetails
          detailModal={detailModal}
          setCancel={setDetailModal}
          updateStatus={updateStatus}
          data={productItem}
        />
      )}
    </div>
  );
};
const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageReturns)
);
