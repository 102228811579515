


export const formatStockOverviewData = (data) => {
  const res = data?.overviews.map((entity, index) => {
    const temp = {
      slno: index + 1,
      invoiceNumber: entity.orderGroupId,
      status: entity.orderStatus,
      quantity: entity.quantity,
      key: index,
    };
    return temp;
  });

  return res;
};
