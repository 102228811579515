import React, { useState, useEffect }  from 'react'
import { RequestConstants } from '../../../Utils/constants';
import { Menu, Button, Dropdown, message, notification, Input, Divider, Drawer, Tooltip, Timeline, Popconfirm, Collapse  } from 'antd'
import { SendOutlined, FrownTwoTone, LoadingOutlined, SafetyCertificateFilled, SaveOutlined, CheckCircleOutlined, SwapRightOutlined, DownOutlined, CheckCircleTwoTone, StopOutlined, BankOutlined, LikeTwoTone } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import { AiOutlineNumber } from 'react-icons/ai'
import { CgRename, CgCalendarDates } from "react-icons/cg";
import { BiCommentDots } from "react-icons/bi";
import { BiClipboard } from "react-icons/bi";
import { RiHandCoinLine } from "react-icons/ri";
import { FaExchangeAlt } from "react-icons/fa";
import BankDetails from './BankDetails';
import moment from 'moment';
import { manageAccountUpdateStatus } from '../../../ApiAction/SellerApiActions/sellerApi'
import van from '../../../Assets/Images/Vectors/van.png'
import { Fragment } from 'react';
import { BiSitemap, BiChevronsRight } from "react-icons/bi";


const AccountModal = (props) => {

    const [selectedStatus, setSelectedStatus] = useState(props.data.status)
    const [suggestedStatus, setSuggestedStatus] = useState(null)
    const [loading, setLoading] = useState(false)
    const [drawerVisibleInvoice, setDrawerVisible] = useState(false)
    const [drawerVisibleUpdate, setDrawerVisibleUpdate] = useState(false)
    const [transactionIdTooltipText, setTransactionIdTooltipText] = useState("Only for SENT TO BANK")
    const [orderGroupIds] = useState([props.data.orderGroupIds])
    const [overallStatus] = useState([props.data.overallStatus])
    const [invoiceSplitData] = useState([props.data.invoiceSplitData && props.data.invoiceSplitData])
    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const [comment, setComment] = useState('')
    const [transactionId, setTransactionId] = useState('')

    const { Panel } = Collapse;

    useEffect(() => {
        console.log("Account Modal Props")
        console.log(props.data.transactionId)
        if(props.data.transactionId) {
            setTransactionId(props.data.transactionId)
        }
        findNextOption(selectedStatus)
        checkTooltipStatus()
    }, [selectedStatus])

    const findNextOption = (currentStatus) => {
        switch(currentStatus) {
            case RequestConstants.REQUESTED:
                setSuggestedStatus(RequestConstants.APPROVED)
                break;
            case RequestConstants.APPROVED:
                setSuggestedStatus(RequestConstants.SENT_TO_BANK)
                break;
            case RequestConstants.REJECTED:
                setSuggestedStatus(RequestConstants.APPROVED)
                break;
            case RequestConstants.SENT_TO_BANK:
                setSuggestedStatus(RequestConstants.PAID)
                break;
            case RequestConstants.PAID:
                setSuggestedStatus(null)
                break;
            default:
                break;
        }
    }

    const checkTooltipStatus = () => {
        if (selectedStatus !== RequestConstants.PAID) {
            setTransactionIdTooltipText("Only for PAID status")
        } else {
            setTransactionIdTooltipText("")
        }
    }

    function handleMenuClick(e) {
        setSelectedStatus(e.key)
        checkTooltipStatus()
    }

    function showCollapseTitle(id, amount) {
        return(
            <span className="show-collapse-title">
                <p>{id}</p>
                <p><NumberFormat value={amount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹ '} style={{ color: "#008543", fontWeight: "bold"}} /></p>
            </span>
        )
    }

    const razorPayTotal = (invoiceData) => {
        let sum = 0;
        invoiceData.forEach((invoice)=> {
            console.log("Printing Each Data")
            console.log(invoice)
            sum += invoice.vendorAmount
        })
        return(
            <p style={{ color: '#072551' }}><NumberFormat value={sum.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹ '} /> </p>
        )
    }

    const saveData = () => {
        setLoading(true);
        setVisibleConfirm(false)
        if(selectedStatus === RequestConstants.PAID && transactionId === '') {
            notification.open({
                message: 'Transaction Id Missing',
                description:
                    'Please add Transaction Id to continue.',
                icon: <FrownTwoTone style={{ color: '#108ee9' }} />,
            });
            setLoading(false)
        }else {
            let updatedBy = localStorage.getItem("activeCustomer") ? JSON.parse(localStorage.getItem("activeCustomer")) : null;
            let updatedDate = new Date().getTime();
            // if updatedBy is empty then a warning popup will be shown
            if(updatedBy !== null) {
                const payload = {
                    id: props.data.id,
                    version: props.data.version,
                    adminId: updatedBy.userId,
                    updatedDate: updatedDate,
                    status: selectedStatus,
                    transactionId: transactionId,
                    comments: comment,
                }
                manageAccountUpdateStatus(payload).then((response)=> {
                    console.log(response)
                    message.success('Data Updated successfully');
                }).catch(error => {
                    console.log(error.message)
                    message.error('Failed to Updated');
                }).finally(()=> {
                    setLoading(false)
                    props.setDataUpdated(!props.dataUpdated)
                    props.setModalVisible(false)
                })
            }else {
                notification.open({
                    message: 'Incomplete Data',
                    description:
                        'Some data is missing, Please try again',
                    icon: <FrownTwoTone style={{ color: '#108ee9' }} />,
                });
            }
        }

    }

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key={RequestConstants.REQUESTED} icon={<SendOutlined style={{ color: "blue" }} />}>
                {RequestConstants.REQUESTED}
            </Menu.Item>
            <Menu.Item key={RequestConstants.APPROVED} icon={<CheckCircleTwoTone twoToneColor="#52c41a" />}>
                {RequestConstants.APPROVED}
            </Menu.Item>
            <Menu.Item key={RequestConstants.REJECTED} icon={<StopOutlined style={{ color: "red" }} />}>
                {RequestConstants.REJECTED}
            </Menu.Item>
            <Menu.Item key={RequestConstants.SENT_TO_BANK} icon={< BankOutlined style={{ color: "magenta" }} />}>
                {RequestConstants.SENT_TO_BANK && "SENT TO BANK"}
            </Menu.Item>
            <Menu.Item key={RequestConstants.PAID} icon={<LikeTwoTone />}>
                {RequestConstants.PAID}
            </Menu.Item>
        </Menu>
    );

    return (
      <div className="manage-account-model-box">
        <div className="manage-account-model-heading">
          <span className="container-with-icon-heading">
            <span className="container-with-text">
              <p>
                <SafetyCertificateFilled /> Manage Accounts
              </p>
              <p>#{props.data.requestId}</p>
            </span>
          </span>
          <Button
            className="account-Model-Close-Button"
            onClick={() => {
              props.setModalVisible(!props.modalVisible);
              window.scrollTo(0, 300);
            }}
          >
            {" "}
            x close
          </Button>
        </div>
        <Divider />
        <div className="manage-account-details-box">
          <div
            className="manage-account-details-box-subBox"
            onClick={() => setDrawerVisible(true)}
            style={{ cursor: "pointer" }}
          >
            <p className="subBox-heading">
              <AiOutlineNumber /> REQUEST ID
            </p>
            <p className="subBox-subHeading">{props.data.requestId}</p>
          </div>
          <div className="manage-account-details-box-subBox">
            <p className="subBox-heading">
              <CgRename /> VENDOR NAME
            </p>
            <p className="subBox-subHeading">{props.data.vendorName}</p>
          </div>
          <div className="manage-account-details-box-subBox">
            <p className="subBox-heading">
              <CgCalendarDates /> REQUESTED DATE
            </p>
            <p className="subBox-subHeading">{props.data.requestDate}</p>
          </div>
          <div className="manage-account-details-box-subBox">
            <p className="subBox-heading">
              <BiCommentDots /> COMMENT
            </p>
            <p className="subBox-subHeading">
              {props.data.comments
                ? props.data.comments
                : "Updated By loremIpsum"}
            </p>
          </div>
          {props.data.status === RequestConstants.PAID ? (
            <div className="manage-account-details-box-subBox paid-status-box">
              <p className="subBox-heading">STATUS • {props.data.status}</p>
              <p className="subBox-subHeading">
                {props.data.transactionId
                  ? props.data.transactionId
                  : 12343493043434}
              </p>
              <CheckCircleOutlined className="paid-icon" />
            </div>
          ) : (
            <div className="manage-account-details-box-subBox">
              <p className="subBox-heading">
                <FaExchangeAlt /> STATUS
              </p>
              <p className="subBox-subHeading">
                {props.data.status === RequestConstants.SENT_TO_BANK
                  ? "SENT TO BANK"
                  : props.data.status}
              </p>
            </div>
          )}
          <div
            className="manage-account-details-box-subBox"
            onClick={() => setDrawerVisibleUpdate(true)}
            style={{ cursor: "pointer" }}
          >
            <p className="subBox-heading">
              <BiClipboard /> UPDATED BY
            </p>
            <p className="subBox-subHeading">{props.data.updatedBy}</p>
          </div>
          <div className="manage-account-details-box-subBox">
            <p className="subBox-heading">
              <CgCalendarDates /> UPDATED DATE
            </p>
            <p className="subBox-subHeading">{props.data.updateDate}</p>
          </div>
          <div className="manage-account-details-box-subBox">
            <p className="subBox-heading">
              <RiHandCoinLine /> AMOUNT
            </p>
            <span style={{ display: "flex" }}>
              <p className="subBox-subHeading">
                            {props?.data?.amount} - (P.C) {props?.data?.platformCharge} ={" "}
              </p>
              <p className="subBox-subHeading" style={{ color: "#008543" }}>
                <NumberFormat
                  value={props.data.amount - props?.data?.platformCharge}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₹ "}
                />
              </p>
            </span>
          </div>
        </div>
        {props.data.status !== RequestConstants.PAID && (
          <>
            <Divider />
            <div className="manage-account-update-section">
              <div className="manage-account-update-status-sub-box">
                <p className="subBox-heading">UPDATE STATUS</p>
                <Dropdown
                  overlay={menu}
                  trigger="click"
                  style={{ borderRadius: "0.5em" }}
                >
                  <Button style={{ fontWeight: "600" }}>
                    {selectedStatus}
                    {suggestedStatus !== null && (
                      <>
                        {" "}
                        <SwapRightOutlined /> {suggestedStatus}
                      </>
                    )}{" "}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              </div>
              <div className="manage-account-update-status-sub-box">
                <p className="subBox-heading">COMMENT</p>
                <Input
                  placeholder="Comment (Eg: Will complete soon.)"
                  style={{ borderRadius: "0.5em" }}
                  value={comment && comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <Tooltip placement="top" title={transactionIdTooltipText}>
                <div className="manage-account-update-status-sub-box">
                  <p className="subBox-heading">TRANSACTION ID</p>
                  <Input
                    value={transactionId && transactionId}
                    onChange={(e) => setTransactionId(e.target.value)}
                    placeholder="Transaction Id (Eg: 3WACY452MVZ3434)"
                    disabled={
                      selectedStatus === RequestConstants.PAID ? false : true
                    }
                    style={{ borderRadius: "0.5em" }}
                  />
                </div>
              </Tooltip>
            </div>
          </>
        )}
        <Divider />
        <BankDetails info={props.data.bankInformations} />
        <Divider />
        <div className="manage-account-action-box">
          {/* phase 2 navigation button */}
          {/* <div>
                    <Button><ArrowLeftOutlined /></Button>
                    <Button><ArrowRightOutlined /></Button>
                </div> */}
          {props.data.status !== RequestConstants.PAID && (
            <>
              <Popconfirm
                title={
                  comment !== ""
                    ? "Confirm your Action"
                    : "Your are saving without any Comments.Are you sure?"
                }
                visible={visibleConfirm}
                onConfirm={saveData}
                onCancel={() => setVisibleConfirm(false)}
              >
                <Button
                  onClick={() => setVisibleConfirm(true)}
                  type="primary"
                  disabled={loading}
                >
                  {loading ? <LoadingOutlined /> : <SaveOutlined />}
                  Update Status
                </Button>
              </Popconfirm>
            </>
          )}
        </div>
        {/* For listing invoice ids */}
        <Drawer
          title="► ORDER INVOICE IDs"
          placement="right"
          closable={false}
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisibleInvoice}
          getContainer={false}
          style={{ position: "absolute" }}
          width={450}
        >
          {invoiceSplitData[0]?.length > 0 ? (
            <Fragment>
              <Collapse className="manage-account-collapse">
                {invoiceSplitData[0].map((doc, index) => (
                  <Panel
                    header={showCollapseTitle(
                      doc.orderGroupId,
                      doc.invoiceAmount
                    )}
                    key={index}
                  >
                    <p className="collapse-split-title">
                      <BiSitemap /> Invoice Split Amounts
                    </p>
                    {doc.invoiceAmountSplits.map((split) => (
                      <h4
                        style={{
                          whiteSpace: "nowrap",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          color: "gray",
                        }}
                      >
                        <span>
                          <BiChevronsRight /> {split.vendor}
                        </span>{" "}
                        <b style={{ color: "#b5b5b5" }}>
                          {split.vendorAmount.toFixed(2)}
                        </b>
                      </h4>
                    ))}
                    <Divider />
                    <span
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        color: "#318FF5",
                        fontWeight: "bold",
                      }}
                    >
                      <p>RazorPay Total</p>
                      {razorPayTotal(doc.invoiceAmountSplits)}
                    </span>
                  </Panel>
                ))}
              </Collapse>
            </Fragment>
          ) : (
            <Fragment>
              <Collapse className="manage-account-collapse">
                {orderGroupIds[0].map((doc, index) => (
                  <Panel
                    header={showCollapseTitle(
                      doc.orderGroupId,
                      doc.invoiceAmount
                    )}
                    key={index}
                  >
                    <h4
                      style={{
                        whiteSpace: "nowrap",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        color: "gray",
                      }}
                    >
                      • {props.data.vendorName}{" "}
                      <b style={{ color: "#b5b5b5" }}>
                        {doc.invoiceAmount.toFixed(2)}
                      </b>
                    </h4>
                  </Panel>
                ))}
              </Collapse>
            </Fragment>
          )}
          <Divider />
          <h4
            style={{
              whiteSpace: "nowrap",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              color: "#d1d1d1",
              columnGap: "0.5em",
              padding: "0 1.25em",
            }}
          >
            GRAND TOTAL{" "}
            <b style={{ color: "#008543" }}>
              <NumberFormat
                value={props.data.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₹ "}
              />
            </b>
          </h4>
          {/* <img src={van} className={orderGroupIds[0].length > 27 ? "manage-account-banner-img-relative" : "manage-account-banner-img-absolute"} alt="banner" /> */}
        </Drawer>
        <Drawer
          title="► UPDATE HISTORY"
          placement="right"
          width={400}
          closable={false}
          onClose={() => setDrawerVisibleUpdate(false)}
          visible={drawerVisibleUpdate}
          getContainer={false}
          style={{ position: "absolute" }}
        >
          <Timeline mode="left">
            {overallStatus[0].map((doc) => (
              <Timeline.Item
                label={
                  doc.status === RequestConstants.SENT_TO_BANK
                    ? "SENT TO BANK"
                    : doc.status
                }
              >
                Updated by {doc.updatedBy} on,{" "}
                <b style={{ whiteSpace: "nowrap" }}>
                  {moment(doc.updatedDate).format("DD • MM • YYYY hh:mm A")}
                </b>
              </Timeline.Item>
            ))}
          </Timeline>
          <img
            src={van}
            className={
              overallStatus[0].length > 8
                ? "manage-account-banner-img-relative"
                : "manage-account-banner-img-absolute"
            }
            alt="banner"
          />
        </Drawer>
      </div>
    );
}

export default AccountModal
