


import React, { useEffect, useState } from "react";

import { Layout } from "antd";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import "antd/dist/antd.css";
import "../Admin/admin.scss";

import { fetchSort } from "../../ReduxStore/action";
import { sideBarDataWarehouse } from "../../Utils/Seller/Data";

import ProductDetailPage from "../Customer/Components/ProductDetailPage";
import Cart from "../Customer/Components/Cart/cart";
import Cartpayment from "../Customer/Components/Cart/cartpayment";
import OrderResponse from "../Customer/Components/Cart/OrderResponseComponents";

import AddProduct from "../Seller/ManageProducts/Components/addProduct";
import FooterLayout from "../../Layouts/FooterLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import ManageProfile from "../Admin/ManageProfile/manageProfile";
import ManageProducts from "../Seller/ManageProducts";
import SideBarMenu from "../Seller/SideBarMenu";
import SearchBarInput from "../SearchBar/SearchBarInput";
import SortBy from "../Seller/SortBy";
import ManageStore from "../Admin/ManageStore/manageStore";
import WarehouseLinkedStore from "./warehouseLinkedStore";
import WarehouseLinkedWarehouse from "./warehouseLinkedWarehouse";
import WarehouseProducts from "./warehouseProducts";
import ManageOrder from "../Seller/ManageOrder/manageOrder";
import FilterAndSortOrder from "../Seller/ManageOrder/filterAndSortOrder";
import ManageProductStock from "../Seller/ManageStocks/manageProductStock";
import ManageStocks from "../Seller/ManageStocks/manageStocks";
import AccountTypeContext from "../Admin/accountTypeContext";
import ContentSection from "../Admin/ManageContent/contentSection";
import CheckAvailablity from "./components/CheckAvailiablity";
import ManageMyOrder from "../Customer/Components/ManageOrders";

import FilterBy from "./components/CheckAvailiablity/filterBy";
import { availiableProductsFilterOptions } from "./components/CheckAvailiablity/checkAvailablityHelper";

import ManageReturnsCustomer from "../Customer/Components/ManageReturns/manageReturnsCustomer";
import ManageReturns from "../Seller/ManageReturns/manageReturns";

import ReportsFilter from "../Admin/Reports/Filter/reportsFilter";
import SalesReport from "../Admin/Reports/SalesReport";
import StocksReport from "../Admin/Reports/StocksReport";
import ExpiryReport from "../Admin/Reports/ExpiryReport";
import CentralRepo from "../Admin/CentralRepo";
import GroupByReport from "../Seller/SortBy/groupByReport";

const cookies = new Cookies();
const { Content, Sider } = Layout;
const Warehouse = (props) => {
  const [searchVal, setSearchval] = useState("");
  const [urlPath, setUrlPath] = useState("");
  const [activeTab, setactiveTab] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const [filterBy, setfilterBy] = useState();
  const [reportSummary, setreportSummary] = useState("");
  const [vendorId, setvendorId] = useState("");
  const [relevance, setrelevance] = useState(true);
  useEffect(() => {
    if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
      setCollapsed(true);
    } else setCollapsed(false);
  }, []);

  useEffect(() => {
    setUrlPath(props.location.pathname);
    setactiveTab(props.location.pathname.split("/").pop());
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <Layout>
      <HeaderLayout pathname={urlPath} isWarehouse />
      {urlPath != "/warehouse/profile" &&
      urlPath != "/warehouse/linked-warehouse" ? (
        <div className="sort-breadcrumb" style={{}}>
          <div className="admin-breadcrumb"></div>
          {urlPath === "/warehouse/check-availiablity" && (
            <FilterBy
              options={availiableProductsFilterOptions}
              setfilterBy={setfilterBy}
            />
          )}
          {(urlPath === "/warehouse/manage-products" ||
            urlPath === "/warehouse/manage-orders" ||
            urlPath === "/warehouse/my-returns" ||
            urlPath === "/warehouse/manage-returns" ||
            urlPath === "/warehouse/linked-stores" ||
            urlPath === "/warehouse/sales-report" ||
            urlPath === "/warehouse/stocks-report" ||
            urlPath === "/warehouse/expiry-report" ||
            urlPath === "/warehouse/manage-stocks" ||
            urlPath === "/warehouse/central-repo") && (
            <>
              <SearchBarInput searchValue={setSearchval} urlPath={urlPath} />
              {
                // urlPath === "/warehouse/stocks-report" ||
                // urlPath === "/warehouse/expiry-report" ||

                (urlPath === "/warehouse/sales-report" ||
                  urlPath === "/warehouse/manage-products") && (
                  <ReportsFilter
                    isVendor
                    activePath={activeTab}
                    setreportSummary={setreportSummary}
                  />
                )
              }
              {urlPath === "/warehouse/sales-report" && <GroupByReport />}
              {urlPath === "/warehouse/manage-orders" ? (
                <FilterAndSortOrder
                  setvendorId={setvendorId}
                  setreportSummary={setreportSummary}
                  isWarehouse
                />
              ) : (
                urlPath !== "/warehouse/expiry-report" &&
                urlPath !== "/warehouse/stocks-report" &&
                urlPath !== "/warehouse/sales-report" &&
                urlPath !== "/warehouse/central-repo" && (
                  <SortBy activePath={activeTab} setrelevance={setrelevance} />
                )
              )}
            </>
          )}
          {urlPath === "/warehouse/my-orders" && <SortBy />}
        </div>
      ) : (
        <div style={{ marginTop: "70px" }}></div>
      )}

      <Layout className="nav-side-content-admin" style={{ padding: "3%" }}>
        <div className="profile_column_data">
          {!collapsed && (
            <Sider
              className="nav-side-bar-admin"
              trigger={null}
              collapsed={collapsed}
            >
              <SideBarMenu
                sideBarData={sideBarDataWarehouse}
                activeTab={activeTab}
                setSearchval={setSearchval}
              />
            </Sider>
          )}
          <Layout className="admin-layout-content right_profile_layout">
            <Content>
              <Switch>
                <Route
                  exact
                  path="/warehouse"
                  render={(props) => {
                    return <Redirect to="/warehouse/profile" />;
                  }}
                />

                <Route
                  exact
                  path="/warehouse/profile"
                  render={(props) => <ManageProfile {...props} isWarehouse />}
                />
                <Route
                  exact
                  path="/warehouse/manage-products"
                  render={(props) => (
                    <ManageProducts
                      searchVal={searchVal}
                      isWarehouse
                      relevance={relevance}
                    />
                  )}
                />
                <Route
                  exact
                  path="/warehouse/central-repo"
                  render={(props) => (
                    <CentralRepo searchVal={searchVal} isVendor />
                  )}
                />
                <Route
                  exact
                  path="/warehouse/add-product"
                  render={(props) => {
                    return <AddProduct {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/warehouse/edit-product/:productId"
                  render={(props) => {
                    return <AddProduct {...props} isEdit />;
                  }}
                />
                <Route
                  exact
                  path="/warehouse/linked-stores"
                  render={(props) => (
                    <WarehouseLinkedStore linkedStore searchVal={searchVal} />
                  )}
                />

                <Route
                  exact
                  path="/warehouse/linked-warehouse"
                  render={(props) => (
                    <WarehouseLinkedWarehouse
                      isWarehouse
                      searchVal={searchVal}
                    />
                  )}
                />
                <Route
                  exact
                  path="/warehouse/linked-warehouse/product-detail/:productId"
                  render={(props) => {
                    return <ProductDetailPage {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/warehouse/cart"
                  render={(props) => {
                    return <Cart {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/warehouse/cart/remove-items"
                  render={(props) => {
                    return <Cart {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/warehouse/cart-payment"
                  render={(props) => {
                    return <Cartpayment {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/warehouse/order-response/:status"
                  render={(props) => {
                    return <OrderResponse />;
                  }}
                />

                <Route
                  exact
                  path="/warehouse/linked-warehouse/:warehouseId"
                  render={(props) => {
                    return <WarehouseProducts {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/warehouse/check-availiablity"
                  render={(props) => {
                    return <CheckAvailablity {...props} filterBy={filterBy} />;
                  }}
                />
                <Route
                  exact
                  path="/warehouse/my-orders"
                  render={(props) => {
                    return <ManageMyOrder />;
                  }}
                />
                <Route
                  exact
                  path="/warehouse/manage-orders"
                  render={(props) => (
                    <ManageOrder
                      searchVal={searchVal}
                      vendorId={vendorId}
                      isWarehouse
                    />
                  )}
                />

                <Route
                  exact
                  path="/warehouse/my-returns"
                  render={(props) => <ManageReturnsCustomer />}
                />

                <Route
                  exact
                  path="/warehouse/manage-price-stock/:productId"
                  render={(props) => {
                    return <ManageProductStock {...props} isWarehouse />;
                  }}
                />

                <Route
                  exact
                  path="/warehouse/manage-returns"
                  render={(props) => (
                    <ManageReturns searchVal={searchVal} {...props} />
                  )}
                />

                <Route
                  exact
                  path="/warehouse/manage-stocks"
                  render={(props) => (
                    <ManageStocks
                      searchVal={searchVal}
                      isWarehouse
                      relevance={relevance}
                    />
                  )}
                />
                <Route
                  exact
                  path="/warehouse/content/:type"
                  render={(props) => {
                    return <ContentSection {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/warehouse/sales-report"
                  render={(props) => (
                    <SalesReport
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                <Route
                  exact
                  path="/warehouse/stocks-report"
                  render={(props) => (
                    <StocksReport
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                <Route
                  exact
                  path="/warehouse/expiry-report"
                  render={(props) => (
                    <ExpiryReport
                      searchVal={searchVal}
                      vendorId={cookies.get("userId")}
                      reportSummary={reportSummary}
                    />
                  )}
                />
                <Route
                  render={(props) => {
                    return <Redirect to="/404" />;
                  }}
                />
              </Switch>
            </Content>
          </Layout>
        </div>
      </Layout>
      <AccountTypeContext.Provider value="warehouse">
        <FooterLayout />
      </AccountTypeContext.Provider>
    </Layout>
  );
};
const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);
