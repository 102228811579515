


import React, { useState, Fragment, useEffect } from "react";
import {
  Divider,
  Popover,
  Typography,
  Input,
  Button,
  DatePicker,
  Space,
  Tooltip,
  Dropdown
} from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { log_get_screens } from "../../../../ApiAction/AdminApiAction/logApi";

import "./reportsFilter.scss";

import DateActiveIcon from "../../../../Assets/Images/Icons/admin-report/date_active.svg";
import DateInActiveIcon from "../../../../Assets/Images/Icons/admin-report/date_inactive.svg";
import BrandActiveIcon from "../../../../Assets/Images/Icons/admin-report/brand_active.svg";
import BrandInActiveIcon from "../../../../Assets/Images/Icons/admin-report/brand_inactive.svg";
import StoreActiveIcon from "../../../../Assets/Images/Icons/admin-report/Asset 9.svg";
import StoreInActiveIcon from "../../../../Assets/Images/Icons/admin-report/Asset 12.svg";
import BatchSortInactiveIcon from "../../../../Assets/Images/Icons/admin-report/Asset 10.svg";
import BatchSortActiveIcon from "../../../../Assets/Images/Icons/admin-report/Asset 11.svg";
import Arrowicon from "../../../../Assets/Images/Icons/admin-report/arrow.svg";
import ResetIcon from "../../../../Assets/Images/Icons/admin-report/reset.svg";

import CheckedRadioIcon from "../../../../Assets/Images/Icons/radio_check.svg";
import UncheckedRadioIcon from "../../../../Assets/Images/Icons/radio_uncheck.svg";

import { fetchBrandList } from "../../../../ApiAction/AdminApiAction/brandApi";
import { fetchUsers, fetchVendorList } from "../../../../ApiAction/AdminApiAction/adminApi";
import { formatBrandData } from "../Utils/reportHelper";

import { fetchSort, fetchSortBy } from "../../../../ReduxStore/action";

import {
  setFilterByBrand,
  setFilterByVendor,
  setFilterByDateRange,
  setFilterByStockPercentage,
  setFilterByScreen
} from "../../../../ReduxStore/action/reportAction";

const { Text } = Typography;
const { RangePicker } = DatePicker;
const ReportsFilter = (props) => {
  const { isVendor, activePath, setreportSummary, isStore } = props;
  const [activeFilter, setactiveFilter] = useState("");
  const [activeRadio, setactiveRadio] = useState("");
  const [searchBy, setsearchBy] = useState("");
  const [searchKey, setsearchKey] = useState("");

  const [brandSearchKey, setbrandSearchKey] = useState("");
  const [vendorSearchKey, setvendorSearchKey] = useState("");
  const [searchSuggestions, setsearchSuggestions] = useState([]);
  const [radioOptions, setradioOptions] = useState([]);

  const [filterOptions, setfilterOptions] = useState([]); //types of filter
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();

  const [dates, setDates] = useState([]);

  useEffect(() => {
    handleClearDateRange();
  }, []);

  //render radio options dynamically
  useEffect(() => {
    switch (activeFilter) {
      case "stock":
        setradioOptions(stockRadioOptions);
        break;
      default:
        console.log("invalid option");
    }
  }, [activeFilter]);

  //render filter options dynamicaly according to parent component
  useEffect(() => {
    console.log("active path: ", activePath);
    switch (activePath) {
      case "manage-products":
        if (isVendor) {
          setfilterOptions(filterList.filter((data) => data.key === "brand"));
        } else
          setfilterOptions(
            filterList.filter(
              (data) => data.key !== "date" && data.key !== "stock"
            )
          );
        break;
      case "check-availiablity":
        setfilterOptions(filterList.filter((data) => data.key === "brand"));
        break;
      case "stocks-report":
        if (isVendor) {
          setfilterOptions(
            filterList.filter(
              (data) =>
                data.key !== "date" &&
                data.key != "store" &&
                data.key != "vendorType" &&
                data.key != "screen"
            )
          );
        } else
          setfilterOptions(
            filterList.filter(
              (data) => data.key !== "date" && data.key != "vendorType"
            )
          );
        break;
      case "sales-report":
        setfilterOptions(
          filterList.filter(
            (data) =>
              data.key !== "stock" &&
              data.key != "store" &&
              data.key != "vendorType" &&
              data.key !== "brand" &&
              data.key != "screen"
          )
        );
        // if (isVendor)
        //   setfilterOptions(
        //     filterList.filter(
        //       (data) =>
        //         data.key !== "stock" &&
        //         data.key != "store" &&
        //         data.key != "vendorType" &&
        //         data.key !== "brand"
        //     )
        //   );
        // else
        //   setfilterOptions(
        //     filterList.filter(
        //       (data) => data.key !== "stock" && data.key != "vendorType"
        //     )
        //   );
        break;
      case "online-payment":
        setfilterOptions(
          filterList.filter(
            (data) =>
              data.key !== "stock" &&
              data.key != "store" &&
              data.key != "vendorType" &&
              data.key !== "brand" &&
              data.key != "screen"
          )
        );
        break;
      case "request-payment":
        setfilterOptions(
          filterList.filter(
            (data) =>
              data.key !== "stock" &&
              data.key != "store" &&
              data.key != "vendorType" &&
              data.key !== "brand" &&
              data.key != "screen"
          )
        );
        break;

      case "search-report":
        setfilterOptions(
          filterList.filter(
            (data) =>
              data.key !== "stock" &&
              data.key != "store" &&
              data.key != "stock" &&
              data.key != "brand" &&
              data.key != "vendorType" &&
              data.key != "screen"
          )
        );
        break;
      case "expiry-report":
        setfilterOptions(
          filterList.filter(
            (data) =>
              data.key !== "stock" &&
              data.key != "store" &&
              data.key != "stock" &&
              data.key != "brand" &&
              data.key != "vendorType" &&
              data.key != "screen"
          )
        );
        break;
      case "day-book-report":
        setfilterOptions(
          filterList.filter(
            (data) =>
              data.key !== "stock" &&
              data.key != "brand" &&
              data.key != "vendorType" &&
              data.key != "screen"
          )
        );
        break;
      case "auto-approval-report":
        setfilterOptions(
          filterList.filter(
            (data) =>
              data.key !== "stock" &&
              data.key != "brand" &&
              data.key != "vendorType" &&
              data.key != "screen"
          )
        );
        break;
      case "affiliate-charge-report":
        setfilterOptions(
          filterList.filter(
            (data) =>
              data.key !== "stock" &&
              data.key != "brand" &&
              data.key != "vendorType" &&
              data.key != "screen" &&
              data.key != "store"
          ).concat(isVendor?  [] : [{
            active: StoreActiveIcon,
            inactive: StoreInActiveIcon,
            key: "affiliate",
          }])
        );
        break;
      case "manage-orders":
        setfilterOptions(filterList.filter((data) => data.key === "store"));
        break;
      case "work-report":
        getScreens();
        setfilterOptions(filterList.filter((data) => data.key === "date" || data.key === "screen"));
        break;
      default:
        setfilterOptions([...filterList]);
    }
  }, [activePath]);

  //fetch brand list
  useEffect(() => {
    if (brandSearchKey && searchBy === "brand") {
      const payload = {
        offset: 0,
        size: 20,
        key: brandSearchKey,
      };

      fetchBrandList(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setsearchSuggestions(formatBrandData(response.data.documents));
          }
        }
      });

    } else if (vendorSearchKey && searchBy === "affiliate") {
      const term = [];

      term.push({
        accountType: ["affiliate"],
      });

      const payload = {
        offset: 0,
        size: 20,
        key: vendorSearchKey,
        accountType: "affiliate",
        filter: {
          term: term
        }
      };

      fetchUsers(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setsearchSuggestions(response.data.documents);
          }
        }
      });

    } else if (vendorSearchKey && searchBy === "store") {
      const payload = {
        offset: 0,
        size: 20,
        key: vendorSearchKey,
      };

      fetchVendorList(payload).then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setsearchSuggestions(response.data.documents);
          }
        }
      });
    }
  }, [searchKey, searchBy, brandSearchKey, vendorSearchKey]);

  const handleRadioFilter = (option) => {
    setactiveRadio(option.key);
    setreportSummary(`for ${option.value}`);
    if(activePath === "work-report") {
      props.setFilterByScreen(option.value)
    } else {
      props.setFilterByStockPercentage(option.key);
    }
  };

  const handleVendorTypeFilter = (option) => {
    let payload;
    setactiveRadio(option.key);
    setreportSummary(`for ${option.value}`);
    switch (option.key) {
      case "SELLER":
        payload = {
          sortType: props.sort.sortType,
          sortBy: props.sort.sortBy,
          block: true,
          archive: false,
          all: false,
          status: props.sort.status,
          filterType: "SELLER",
        };
        props.fetchSort(payload);
        break;
      case "STORE":
        payload = {
          sortType: props.sort.sortType,
          sortBy: props.sort.sortBy,
          block: false,
          archive: false,
          all: false,
          status: props.sort.status,
          filterType: "STORE",
        };
        props.fetchSort(payload);
        break;

      case "WAREHOUSE":
        payload = {
          sortType: props.sort.sortType,
          sortBy: props.sort.sortBy,
          block: true,
          archive: true,
          all: false,
          status: props.sort.status,
          filterType: "WAREHOUSE",
        };
        props.fetchSort(payload);
    }
  };

  const handleChangeSearchKey = (value) => {
    switch (searchBy) {
      case "brand":
        setbrandSearchKey(value);
        break;

      case "store":
        setvendorSearchKey(value);
        break;

      case "affiliate":
        setvendorSearchKey(value);
        break;

      default:
        console.log("invalide choice");
    }
  };
  //updating search key from input field
  const handleupdateSearchKey = (value, all) => {
    if (all) {
      setbrandSearchKey(value);
      props.setFilterByBrand(value);
      setvendorSearchKey(value);
      props.setFilterByVendor("");
    } else {
      switch (searchBy) {
        case "brand":
          setbrandSearchKey(value);
          props.setFilterByBrand(value);
          break;

        case "store":
          setvendorSearchKey(value);
          props.setFilterByVendor("");
          break;

        case "affiliate":
          setvendorSearchKey(value);
          props.setFilterByVendor("");
          break;

        default:
          console.log("invalide choice");
      }
    }
  };

  // to filter by brand & store
  const handleSetSearchFilter = (selectedSuggestion) => {
    handleupdateSearchKey(selectedSuggestion.name);
    setsearchKey(selectedSuggestion.name);
    switch (searchBy) {
      case "brand":
        props.setFilterByBrand(selectedSuggestion.id);
        setreportSummary(`for ${selectedSuggestion.name} brand.`);
        break;

      case "store":
        props.setFilterByVendor(selectedSuggestion.id);
        if (props.setvendorId) {
          props.setvendorId(selectedSuggestion.id);
        }
        setreportSummary(`for ${selectedSuggestion.name} vendor.`);
        break;

      case "affiliate":
        props.setFilterByVendor(selectedSuggestion.referralId);
        if (props.setvendorId) {
          props.setvendorId(selectedSuggestion.referralId);
        }
        setreportSummary(`for ${selectedSuggestion.name} affiliate.`);
        break;

      default:
        console.log("invalid choice");
    }
  };
  //to clear search filter
  const handleClearSearchFilter = () => {
    handleupdateSearchKey("");
    setsearchSuggestions([]);
    setreportSummary(``);
    if (props.setvendorId) {
      props.setvendorId("");
    }
  };

  //to handle update in date
  const handleDateChange = (dates, dateStrings) => {
    dates?.length > 0 &&
      setreportSummary(
        `During the period from  ${moment(dates[0]).format(
          "MMMM Do YYYY"
        )} to  ${moment(dates[1]).format("MMMM Do YYYY")}   .`
      );
    if (dates?.length > 0) {
      props.setFilterByDateRange({
        startTime: moment(dates[0]).format("YYYY-MM-DD"),
        endtime: moment(dates[1]).format("YYYY-MM-DD"),
      });
      setstartDate(dates[0]);
      setendDate(dates[1]);
    } else {
      props.setFilterByDateRange(null);
      setreportSummary(``);
      setstartDate("");
      setendDate("");
    }
  };

  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate =
      dates[0] && current.diff(dates[0], "days") > (isStore ? 185 : 30);
    const tooEarly =
      dates[1] && dates[1].diff(current, "days") > (isStore ? 185 : 30);
    return tooEarly || tooLate;
  };

  //to clear date filter
  const handleClearDateRange = () => {
    props.setFilterByDateRange(null);
    setreportSummary(``);
    setstartDate("");
    setendDate("");
  };

  const handleClearRadioFilter = () => {
    props.setFilterByStockPercentage(null);
    props.setFilterByScreen(null);
    setactiveRadio("");
    setreportSummary(``);
    const payload = {
      sortType: props.sort.sortType,
      sortBy: props.sort.sortBy,
      block: true,
      archive: false,
      all: false,
      status: props.sort.status,
      filterType: "ALL",
    };
    props.fetchSort(payload);
  };

  //to reset all filters
  const handleResetFilters = () => {
    props.setFilterByStockPercentage(null);
    props.setFilterByDateRange(null);
    setstartDate("");
    setendDate("");
    handleupdateSearchKey("", true);
    setsearchSuggestions([]);
    setactiveRadio("");
    setreportSummary(``);
    if (props.setvendorId) {
      props.setvendorId("");
    }
    const payload = {
      ...props.sort,
      sortType: props.sort?.sortType,
      sortBy: props.sort?.sortBy,
      block: true,
      archive: false,
      all: false,
      status: props.sort.status,
      filterType: "ALL",
    };
    props.fetchSort(payload);
  };

  const getScreens = () => {
    // if(props.location.url.includes('work-report')) {
      log_get_screens().then((response) => {
        if(response.status === 200) {
          if(response.data.status === "success") {
            screenRadioOptions = response.data.screens.map((screen) => {
              return {key: screen, value: screen}
            })
            setradioOptions(screenRadioOptions)
          }
        }
      })
    // }
  }
  //types of filter
  const filterList = [
    {
      active: BatchSortInactiveIcon,
      inactive: BatchSortActiveIcon,
      key: "vendorType",
    },
    {
      active: BatchSortInactiveIcon,
      inactive: BatchSortActiveIcon,
      key: "stock",
    },
    {
      active: DateActiveIcon,
      inactive: DateInActiveIcon,
      key: "date",
    },
    {
      active: BrandActiveIcon,
      inactive: BrandInActiveIcon,
      key: "brand",
    },
    {
      active: StoreActiveIcon,
      inactive: StoreInActiveIcon,
      key: "store",
    },
    {
      active: BatchSortInactiveIcon,
      inactive: BatchSortActiveIcon,
      key: "screen",
    },
  ];

  //this should be dynamic for radio type filter
  const stockRadioOptions = [
    {
      key: "all",
      value: "Stock (All)",
    },
    {
      key: "isStockLow",
      value: "Stock (Less than 20%)",
    },
    {
      key: "isStockZero",
      value: "Stock(Zero)",
    },
  ];

  let screenRadioOptions = [
    {
      key: "all",
      value: "Stock (All)",
    },
    {
      key: "isStockLow",
      value: "Stock (Less than 20%)",
    },
    {
      key: "isStockZero",
      value: "Stock(Zero)",
    },
  ];

  //vendorType radio options

  const vendorTypeRadioOptions = [
    {
      key: "SELLER",
      value: "Seller",
    },
    {
      key: "STORE",
      value: "Store",
    },
    {
      key: "WAREHOUSE",
      value: "Warehouse",
    },
  ];

  const SearchReportRadioOptions = [
    {
      key: "key",
      value: "By key",
    },
    {
      key: "view",
      value: "By view",
    },
  ];

  const batchRadioOptions = [
    {
      key: "all",
      value: "ALL",
    },
    {
      key: "desc",
      value: "Batchwise (Newest First)",
    },
    {
      key: "asc",
      value: "Batchwise (Oldest First)",
    },
  ];

  //render different types dynamicaly
  const renderFilter = () => {
    switch (activeFilter) {
      case "date":
        return dateRangePicker;
        break;
      case "brand":
        setsearchBy("brand");
        return content;
        break;

      case "store":
        setsearchBy("store");
        return content;
        break;

      case "affiliate":
        setsearchBy("affiliate");
        return content;
        break;

      case "stock":
        // setradioOptions(batchRadioOptions);
        return radiofilter;
        break;

      case "vendorType":
        return vendorTypeFilter;
        break;

      case "screen":
        return radiofilter;

      default:
        break;
    }
  };

  //search filter type
  const content = (
    <div className="rprt_filter_container">
      <Text>Search {searchBy}:</Text> <br />
      <Input
        placeholder={`search ${searchBy}`}
        value={searchBy === "brand" ? brandSearchKey : vendorSearchKey}
        onChange={(event) => {
          handleChangeSearchKey(event.target.value);
        }}
      />
      <br />
      <div className="rptr_filter_body">
        <Text type="secondary">
          Search related to{" "}
          {searchBy === "brand" ? brandSearchKey : vendorSearchKey}:
        </Text>
        {searchSuggestions?.length > 0 &&
          ((searchBy === "brand" && brandSearchKey) ||
            (searchBy === "store" && vendorSearchKey) ||
            (searchBy === "affiliate" && vendorSearchKey)) &&
          searchSuggestions.map((suggestion) => {
            return (
              <div
                className="rptr_search_filter_suggestion"
                key={suggestion.id}
                onClick={() => handleSetSearchFilter(suggestion)}
              >
                <Text>{suggestion.name} {searchBy === "affiliate" ? suggestion.referralId : ""}</Text>
                <img src={Arrowicon} style={{ width: "10px" }} />
              </div>
            );
          })}
      </div>
      <div className="rptr_filter_clear">
        <Button type="text" onClick={() => handleClearSearchFilter()}>
          clear filter
        </Button>
      </div>
    </div>
  );

  //date range filter
  const dateRangePicker = (
    <div className="rprt_filter_container">
      <Text>Choose date</Text> <br />
      <RangePicker
        onCalendarChange={(val) => setDates(val)}
        value={[startDate, endDate]}
        onChange={handleDateChange}
        renderExtraFooter={() => "extra footer"}
        className="margin10"
      />
      <div className="rptr_filter_clear">
        <Button type="text" onClick={() => handleClearDateRange()}>
          clear filter
        </Button>
      </div>
    </div>
  );

  //radiobutton type filter
  const radiofilter = (
    <div className="rprt_filter_container">
      <div className="margin10">
        <Text type="secondary">{activeFilter}</Text>

        {radioOptions?.map((option) => {
          return (
            <div
              onClick={() => handleRadioFilter(option)}
              key={option.key}
              className="rprt_filter_radio_options"
            >
              <Text style={{ color: "#3e6df3" }}> {option.value}</Text>
              <img
                src={
                  activeRadio === option.key
                    ? CheckedRadioIcon
                    : UncheckedRadioIcon
                }
                className="rprt_filter_radio_btn"
              />
            </div>
          );
        })}
      </div>
      <div className="rptr_filter_clear">
        <Button type="text" onClick={() => handleClearRadioFilter()}>
          clear filter
        </Button>
      </div>
    </div>
  );

  //vendor type filter
  const vendorTypeFilter = (
    <div className="rprt_filter_container">
      <div className="margin10">
        <Text type="secondary">{activeFilter}</Text>

        {vendorTypeRadioOptions?.map((option) => {
          return (
            <div
              onClick={() => handleVendorTypeFilter(option)}
              key={option.key}
              className="rprt_filter_radio_options"
            >
              <Text style={{ color: "#3e6df3" }}> {option.value}</Text>
              <img
                src={
                  activeRadio === option.key
                    ? CheckedRadioIcon
                    : UncheckedRadioIcon
                }
                className="rprt_filter_radio_btn"
              />
            </div>
          );
        })}
      </div>
      <div className="rptr_filter_clear">
        <Button type="text" onClick={() => handleClearRadioFilter()}>
          clear filter
        </Button>
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="reports_filter_container">
        FilterBy :
        <div className="reports_filters_types_wrappers">
          {filterOptions?.map((filterBy) => {
            return (
              <Popover
                width="300"
                placement="bottom"
                content={renderFilter}
                trigger="click"
                key={filterBy.key}
              >
                <img
                  onClick={() => setactiveFilter(filterBy.key)}
                  src={
                    activeFilter === filterBy.key
                      ? filterBy.active
                      : filterBy.inactive
                  }
                  className="reports_filter_types_icon"
                />
              </Popover>
            );
          })}
          <Tooltip title="reset-filter">
            <img
              src={ResetIcon}
              className="reports_filter_types_icon"
              onClick={() => handleResetFilters()}
            />
          </Tooltip>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = {
  setFilterByBrand,
  setFilterByVendor,
  setFilterByDateRange,
  setFilterByStockPercentage,
  setFilterByScreen,
  fetchSort,
  fetchSortBy,
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsFilter);
