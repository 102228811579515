


import React from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './ImageGallery.scss';

class MyGallery extends React.Component {
    render() {
        return <ImageGallery
        items={this.props.items} 
        thumbnailPosition={'left'} 
        showPlayButton={false}
        showNav={true} 
        showBullets={true}
        additionalClass="hr-product-gallery"
        />;
    }
}

export default MyGallery;