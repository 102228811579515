

import React, { useRef, useEffect, Fragment, useState } from "react";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { connect } from "react-redux";
import { ToWords } from "to-words";

import { Modal, Typography, Divider } from "antd";
import LogoRecipet from "../../../../../../Assets/Images/Logo/DL_Logo.png";
import "../TaxInvoice/taxinvoice.scss";
import Cookies from "universal-cookie";
import { unionTerritories } from "../../../../../../Utils/constants";
import { fetchCompanyDetails } from "../../../../../../ReduxStore/action";

const { Text } = Typography;
const TaxInvoice = (props) => {
  const cookies = new Cookies();
  const pdfRef = useRef();
  const toWords = new ToWords();
  const { visible, setvisible, orderSummary, companyDetails } = props;
  const [isIGST, setIsIGST] = useState(false);
  const [vendorType, setVendorType] = useState("");
  const [vendorGSTType, setVendorGSTType] = useState("");
  const [sellerAddress, setSellerAddress] = useState({});

  const [gstTableHeader, setGstTableHeader] = useState("");
  const accountType = cookies.get("accountType");
  let vendorState = "";
  let customerState = "";
  const [multiWindow, setMultiWindow] = useState(false);

  useEffect(() => {
    console.log("orderSummary for invoice: ", orderSummary);
    console.log("GST To No : ", orderSummary.gstNumber);
    try {
      // the temp is just used to get vendor state
      let fetchedState = orderSummary?.orderedItems?.map((data, index) => {
        return data?.vendorAddress?.state;
      });
      //setVendorState(...temp[0])
      vendorState = fetchedState[0];
      // to get vendorType
      let fetchedType = orderSummary?.orderedItems?.map((data, index) => {
        return data?.vendorType;
      });
      setVendorType(fetchedType[0]);
      // to get sellerGST
      let fetchedGST = orderSummary?.orderedItems?.map((data, index) => {
        return data?.vendorGstNumber;
      });
      setVendorGSTType(fetchedGST[0]);
      // to get seller Address
      let fetchedAddress = orderSummary?.orderedItems?.map((data, index) => {
        return data?.vendorAddress;
      });
      setSellerAddress(fetchedAddress[0]);
      // to get multiWindow
      let vendorWindow = orderSummary?.orderedItems[0]?.windowType;
      if (vendorWindow === "multiWindow") {
        setMultiWindow(true);
      } else {
        setMultiWindow(false);
      }

      console.log("Vendor type and gst", vendorType);
      customerState = orderSummary?.shippingAddress?.state;
      //setCustomerState(...orderSummary?.shippingAddress?.state)
    } catch (error) {
      console.log("ERROR:", error);
    }
    findHeader();
  }, [props]);

  const findHeader = () => {
    setIsIGST(false);
    if (vendorState?.toLowerCase() === customerState?.toLowerCase()) {
      if (unionTerritories.includes(vendorState?.toLowerCase())) {
        setGstTableHeader("UTGST");
      } else {
        setIsIGST(false);
        setGstTableHeader("SGST");
      }
    } else {
      setIsIGST(true);
      setGstTableHeader("SGST");
    }
  };

  const formattedArray = [
    orderSummary?.shippingAddress?.ownerName
      ? `${orderSummary?.shippingAddress?.ownerName}`
      : null,
    orderSummary?.shippingAddress?.buildingName
      ? `${orderSummary?.shippingAddress?.buildingName}`
      : null,
    orderSummary?.shippingAddress?.addressLine1
      ? `${orderSummary?.shippingAddress?.addressLine1}`
      : null,
    orderSummary?.shippingAddress?.addressLine2
      ? `${orderSummary?.shippingAddress?.addressLine2}`
      : null,
    orderSummary?.shippingAddress?.state
      ? `${orderSummary?.shippingAddress?.state}`
      : null,
    orderSummary?.shippingAddress?.district
      ? `${orderSummary?.shippingAddress?.district}`
      : null,
  ];

  console.log(
    "formattedArray",
    formattedArray,
    formattedArray.filter(function (el) {
      return el != null;
    })
  );

  const filtered = formattedArray.filter(function (el) {
    return el != null;
  });

  const pincodeWithPhNumber = [
    orderSummary?.shippingAddress?.pinCode
      ? `${orderSummary?.shippingAddress?.pinCode}`
      : null,
    orderSummary?.shippingAddress?.phoneNo
      ? `${orderSummary?.shippingAddress?.phoneNo}`
      : null,
  ];

  const pincodeWithPhNumberFiltered = pincodeWithPhNumber.filter(function (el) {
    return el != null;
  });

  return (
    <div>
      <Modal
        title="Tax Invoice"
        visible={visible}
        onOk={() => {
          setvisible(false);
          if (props.quickCheckout) {
            props.handleRemove(props.pane);
          }
        }}
        onCancel={() => {
          setvisible(false);
          if (props.quickCheckout) {
            props.handleRemove(props.pane);
          }
        }}
        footer={null}
        width={760}
        className="ord_dlt_pop_ext taxvoice"
      >
        <div class="taxinvoice_receipt" ref={pdfRef}>
          <h6 class="inv-main-title">Tax Invoice</h6>
          <div class="lg-row-top">
            <div class="row-top-left">
              {/* <div class="logo-receipt">
                <p>Ordered Through</p>
                <img src={LogoRecipet} />
              </div> */}
              <div>
                {vendorType === "SELLER" && (
                  <label>
                    Sold By :{" "}
                    {orderSummary?.orderedItems[0]?.vendorAddress.ownerName}
                  </label>
                )}
                {vendorType === "SELLER" && (
                  <p>
                    Seller ID :{" "}
                    {orderSummary?.orderedItems[0]?.vendorAddress.id}
                  </p>
                )}
                {vendorType === "STORE" ? (
                  <Fragment>
                    {multiWindow ? (
                      <p>Seller GST : {vendorGSTType}</p>
                    ) : (
                      <p>GST IN: {companyDetails?.gstNumber}</p>
                    )}
                  </Fragment>
                ) : (
                  <p>Seller GST : {vendorGSTType}</p>
                )}
                {vendorType === "STORE" ? (
                  <Fragment>
                    {multiWindow ? (
                      <Fragment>
                        {orderSummary?.orderedItems
                          ? orderSummary?.orderedItems[0]?.vendorAddress && (
                              <p>
                                {
                                  orderSummary?.orderedItems[0]?.vendorAddress
                                    .ownerName
                                }
                                <br></br>
                                {
                                  orderSummary?.orderedItems[0]?.vendorAddress
                                    .addressLine1
                                }
                                <br></br>
                                {
                                  orderSummary?.orderedItems[0]?.vendorAddress
                                    .addressLine2
                                }
                                ,
                                {
                                  orderSummary?.orderedItems[0]?.vendorAddress
                                    .state
                                }
                                ,{" "}
                                {
                                  orderSummary?.orderedItems[0]?.vendorAddress
                                    .pinCode
                                }
                                <br></br>
                                Ph:{" "}
                                {
                                  orderSummary?.orderedItems[0]?.vendorAddress
                                    .phoneNo
                                }
                                <br></br>
                                {
                                  orderSummary?.orderedItems[0]?.vendorAddress
                                    .emailId
                                }
                              </p>
                            )
                          : ""}
                      </Fragment>
                    ) : (
                      <p>
                        {companyDetails?.address?.ownerName} <br></br> {companyDetails?.address?.addressLine1}
                        {companyDetails?.address?.addressLine2}<br></br>{" "}
                        {companyDetails?.address?.state}, {companyDetails?.address?.pinCode} <br></br> Ph: {companyDetails?.address?.phoneNo}{" "}
                        <br></br>
                        {companyDetails?.email}
                      </p>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {orderSummary?.orderedItems
                      ? orderSummary?.orderedItems[0]?.vendorAddress && (
                          <p>
                            {
                              orderSummary?.orderedItems[0]?.vendorAddress
                                .ownerName
                            }
                            <br></br>
                            {
                              orderSummary?.orderedItems[0]?.vendorAddress
                                .addressLine1
                            }
                            <br></br>
                            {
                              orderSummary?.orderedItems[0]?.vendorAddress
                                .addressLine2
                            }
                            ,
                            {orderSummary?.orderedItems[0]?.vendorAddress.state}
                            ,{" "}
                            {
                              orderSummary?.orderedItems[0]?.vendorAddress
                                .pinCode
                            }
                            <br></br>
                            Ph:{" "}
                            {
                              orderSummary?.orderedItems[0]?.vendorAddress
                                .phoneNo
                            }
                            <br></br>
                            {
                              orderSummary?.orderedItems[0]?.vendorAddress
                                .emailId
                            }
                          </p>
                        )
                      : ""}
                  </Fragment>
                )}
              </div>
            </div>
            <div class="row-top-right"></div>
          </div>
          {orderSummary?.referralId && (
            <p>Customer ID :{orderSummary?.referralId}</p>
          )}
          <div class="lg-row-second">
            <div class="row-second-left">
              <p>Invoice No : {orderSummary.orderGroupId}</p>
            </div>
            <div class="row-second-center">
              <p>
                Invoice Date :{" "}
                {moment(orderSummary.orderedDate).format("DD-MM-YYYY")}
              </p>
            </div>
            <div class="row-second-payment-mode">
              <p>{orderSummary?.paymentMode}</p>
            </div>
            <div class="row-second-right">
              <p>
                Place of Supply :{" "}
                {orderSummary?.shippingAddress?.state
                  ? orderSummary?.shippingAddress?.state
                  : "Kerala"}
              </p>
            </div>
          </div>
          {orderSummary?.razorPayPaymentId && (
            <Fragment>
              <Text strong>Razor-pay reference Id:</Text>{" "}
              <Text strong>{orderSummary?.razorPayPaymentId}</Text>
            </Fragment>
          )}

          <div class="lg-row-bottom">
            <div class="row-bot-left">
              <div>
                {vendorType === "STORE" ? (
                  <Fragment>
                    <label>Shipped From :</label>
                    {orderSummary?.orderedItems ? (
                      orderSummary?.orderedItems[0]?.vendorAddress ? (
                        <p>
                          {orderSummary?.orderedItems[0]?.vendorGstNumber ? (
                            `GSTIN: ${orderSummary?.orderedItems[0]?.vendorGstNumber}`
                          ) : (
                            <></>
                          )}
                          <br></br>
                          {
                            orderSummary?.orderedItems[0]?.vendorAddress
                              .ownerName
                          }
                          <br></br>
                          {
                            orderSummary?.orderedItems[0]?.vendorAddress
                              .addressLine1
                          }
                          <br></br>
                          {
                            orderSummary?.orderedItems[0]?.vendorAddress
                              .addressLine2
                          }
                          ,{" "}
                          {orderSummary?.orderedItems[0]?.vendorAddress.pinCode}
                          <br></br>
                          Ph:{" "}
                          {orderSummary?.orderedItems[0]?.vendorAddress.phoneNo}
                          <br></br>
                          {orderSummary?.orderedItems[0]?.vendorAddress.emailId}
                        </p>
                      ) : (
                        <p>
                          {companyDetails?.address?.ownerName} <br></br> {companyDetails?.address?.addressLine1}
                          {companyDetails?.address?.addressLine2}<br></br>{" "}
                          {companyDetails?.address?.state}, {companyDetails?.address?.pinCode} <br></br> Ph: {companyDetails?.address?.phoneNo}{" "}
                          <br></br>
                          {companyDetails?.email}
                        </p>
                      )
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <label>Shipped To :</label>
                    <p style={{ fontWeight: "600" }}>
                      {orderSummary?.gstNumber &&
                        `GSTIN: ${orderSummary?.gstNumber}`}
                      {filtered &&
                        filtered.length !== 0 &&
                        filtered.map((i, index) => (
                          <p style={{ fontWeight: "600" }}>
                            {i}
                            {index !== filtered.length - 1
                              ? " "
                              : pincodeWithPhNumberFiltered &&
                                pincodeWithPhNumberFiltered.length !== 0 &&
                                " "}
                          </p>
                        ))}
                      {/* <br></br> */}
                      {orderSummary?.shippingAddress?.pinCode
                        ? `Pincode: ${orderSummary?.shippingAddress?.pinCode}`
                        : ""}
                      <br></br>
                      {orderSummary?.shippingAddress?.phoneNo
                        ? `Ph: +${orderSummary?.shippingAddress?.phoneNo}`
                        : ""}
                    </p>
                  </Fragment>
                )}
              </div>
            </div>
            <div class="row-bot-right">
              <div>
                <label>Billed To :</label>
                {/* <p>
                  Karam Singh Chokhan,<br></br>#14NM Matrix Curved Pathway,{" "}
                  <br></br>2nd Diamond Tunnel Road, <br></br> North Mumbai,{" "}
                  <br></br>Pincode: 675639
                </p> */}
                <p>
                  {/* {orderSummary?.shippingAddress?.ownerName
                    ? orderSummary?.shippingAddress?.ownerName + ", "
                    : ""}
                  {orderSummary?.shippingAddress?.buildingName
                    ? orderSummary?.shippingAddress?.buildingName + ", "
                    : ""}
                  <br></br>
                  {orderSummary?.shippingAddress?.addressLine1
                    ? orderSummary?.shippingAddress?.addressLine1 + ", "
                    : ""}
                  {orderSummary?.shippingAddress?.addressLine2
                    ? orderSummary?.shippingAddress?.addressLine2 + ", "
                    : ""}{" "}
                  <br></br>{" "}
                  {orderSummary?.shippingAddress?.district
                    ? orderSummary?.shippingAddress?.district + ", "
                    : ""}
                  <br></br> */}
                  {orderSummary?.gstNumber ? (
                    `GSTIN: ${orderSummary?.gstNumber}`
                  ) : (
                    <></>
                  )}
                  {filtered &&
                    filtered.length !== 0 &&
                    filtered.map((i, index) => (
                      <p>
                        {i}
                        {index !== filtered.length - 1
                          ? " "
                          : pincodeWithPhNumberFiltered &&
                            pincodeWithPhNumberFiltered.length !== 0 &&
                            " "}
                      </p>
                    ))}
                  {/* <br></br> */}
                  {orderSummary?.shippingAddress?.pinCode
                    ? `Pincode: ${orderSummary?.shippingAddress?.pinCode}`
                    : ""}
                  <br></br>
                  {orderSummary?.shippingAddress?.phoneNo
                    ? `Ph: +${orderSummary?.shippingAddress?.phoneNo}`
                    : ""}
                </p>
              </div>
            </div>
          </div>
          <div class="table_item">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Item</th>
                  <th>
                    HSN <br></br>Code
                  </th>

                  <th>Unit</th>
                  <th>MRP</th>
                  <th>Selling Price</th>
                  <th>Qty</th>

                  <th>
                    Dis <br></br>Amt.
                  </th>
                  <th>
                    Txble <br></br>Amt.
                  </th>
                  {isIGST && vendorType === "SELLER" ? (
                    <Fragment>
                      <th>
                        IGST <br></br>Rate
                      </th>
                      <th>
                        IGST <br></br> Amt
                      </th>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <th>
                        CGST <br></br>Rate
                      </th>
                      <th>
                        CGST<br></br> Amt
                      </th>
                      <th>
                        {gstTableHeader} <br></br>Rate
                      </th>
                      <th>
                        {gstTableHeader}
                        <br></br> Amt
                      </th>
                    </Fragment>
                  )}

                  {/* {orderSummary.accountType === "customer" && <th>SI</th>} */}
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {orderSummary?.orderedItems?.length > 0 &&
                  orderSummary?.orderedItems.map((product, index) => {
                    if (product?.itemOrderStatus?.currentStatus !== "CANCELLED")
                      return (
                        <tr>
                          <td>{index + 1}.</td>
                          <td>{product.productName}</td>
                          <td>{product.hsnCode}</td>
                          <td>
                            {`${product.itemCurrentPrice.quantity}  ${product.itemCurrentPrice.unit}`}
                          </td>
                          <td>{product.itemCurrentPrice.price.toFixed(2)}</td>
                          <td>
                            {product.itemCurrentPrice.sellingPrice.toFixed(2)}
                          </td>
                          <td>{product.quantity}</td>

                          {/* /discount/ */}
                          <td>
                            {(
                              (product.itemCurrentPrice.price -
                                product.itemCurrentPrice.sellingPrice) *
                              product.quantity
                            ).toFixed(2)}
                          </td>
                          {/* /Taxabe amount/ */}
                          {isIGST && vendorType === "SELLER" ? (
                            <Fragment>
                              <td>
                                {(
                                  ((100 *
                                    product.itemCurrentPrice.sellingPrice) /
                                    (100 + product.taxPerCent)) *
                                  product.quantity
                                ).toFixed(2)}
                              </td>
                              <td>{product.taxPerCent}%</td>
                              {/* /cgst/ */}
                              <td>
                                {(
                                  (product.taxPerCent / 2 / 100) *
                                  ((100 *
                                    product.itemCurrentPrice.sellingPrice) /
                                    (100 + product.taxPerCent)) *
                                  product.quantity *
                                  2
                                ).toFixed(2)}
                              </td>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <td>
                                {(
                                  ((100 *
                                    product.itemCurrentPrice.sellingPrice) /
                                    (100 + product.taxPerCent)) *
                                  product.quantity
                                ).toFixed(2)}
                              </td>
                              <td>{product.taxPerCent / 2}%</td>
                              {/* /cgst/ */}
                              <td>
                                {(
                                  (product.taxPerCent / 2 / 100) *
                                  ((100 *
                                    product.itemCurrentPrice.sellingPrice) /
                                    (100 + product.taxPerCent)) *
                                  product.quantity
                                ).toFixed(2)}
                              </td>
                              <td>{product.taxPerCent / 2}%</td>
                              {/* /sgst/ */}
                              <td>
                                {(
                                  (product.taxPerCent / 2 / 100) *
                                  ((100 *
                                    product.itemCurrentPrice.sellingPrice) /
                                    (100 + product.taxPerCent)) *
                                  product.quantity
                                ).toFixed(2)}
                              </td>
                            </Fragment>
                          )}

                          {/* {orderSummary.accountType === "customer" && (
                            <td>
                              {(
                                product.itemCurrentPrice.salesIncentive *
                                product.quantity
                              ).toFixed(2)}
                            </td>
                          )} */}
                          <td>{product.totalAmount.toFixed(2)}</td>
                        </tr>
                      );
                  })}

                <tr>
                  <td></td>
                  <td>Delivery Charge</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>{}</td>
                  {/* <td>{}</td> */}
                  {isIGST && vendorType === "SELLER" ? (
                    ""
                  ) : (
                    <Fragment>
                      <td>{}</td>
                      <td>{}</td>
                    </Fragment>
                  )}
                  <td>{}</td>
                  {orderSummary.accountType === "customer" && <td>{}</td>}
                  <td>
                    {orderSummary?.deliveryCharge
                      ? orderSummary.deliveryCharge.toFixed(2)
                      : "00.00"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="lg-row-price">
            <div class="row-price-left">
              <p>
                You Saved &nbsp; ₹{" "}
                {Number(orderSummary?.totalDiscount).toFixed(2)}
              </p>
            </div>
            <div class="row-price-right">
              <p>
                <b>GRAND TOTAL</b> (All Tax Included){" "}
                <b>: ₹ {Number(orderSummary?.totalPrice).toFixed(2)}</b>
              </p>
            </div>
          </div>

          <div class="tax_sum">
            {orderSummary.taxSummaries?.length > 0 && (
              <Fragment>
                <h6>Tax Summary</h6>
                <div class="tax-sum-table">
                  <table>
                    <thead>
                      <tr>
                        {/* <td rowspan="0">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </td> */}
                        <td>&nbsp;&nbsp;</td>
                        <th colspan="1" scope="colgroup">
                          Taxable
                        </th>
                        <th colspan="2" scope="colgroup">
                          Central Value
                        </th>
                        {!isIGST && (
                          <th colspan="2" scope="colgroup">
                            State Value
                          </th>
                        )}
                        <th colspan="2" scope="colgroup">
                          Total
                        </th>
                      </tr>
                      <tr>
                        {/* <td rowspan="0">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </td> */}
                        <td>&nbsp;&nbsp;</td>
                        <th scope="col">Price</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Amount</th>
                        {!isIGST && (
                          <Fragment>
                            <th scope="col">Rate</th>
                            <th scope="col">Amount</th>
                          </Fragment>
                        )}
                        <th scope="col">Tax Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                      <td rowspan="0">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </td>
                      <td>&nbsp;&nbsp;</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr> */}

                      {orderSummary?.taxSummaries.map((tax) => {
                        return (
                          <tr>
                            {/* <td rowspan="0">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </td>
                          <td>&nbsp;&nbsp;</td> */}
                            <td>&nbsp;&nbsp;</td>
                            <td>
                              {(tax.taxablePrice - tax.taxValue).toFixed(2)}
                            </td>
                            {isIGST && vendorType === "SELLER" ? (
                              <Fragment>
                                <td>{tax.tax}%</td>
                                <td>{tax.taxValue.toFixed(2)}</td>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <td>{tax.tax / 2}%</td>
                                <td>{(tax.taxValue / 2).toFixed(2)}</td>
                                <td>{tax.tax / 2}%</td>
                                <td>{(tax.taxValue / 2).toFixed(2)}</td>
                              </Fragment>
                            )}
                            <td>{tax.taxValue.toFixed(2)}</td>
                          </tr>
                        );
                      })}

                      {orderSummary && (
                        <tr>
                          <td>Total:</td>
                          <td>
                            {" "}
                            {Number(
                              (
                                orderSummary.subTotal - orderSummary.totalTax
                              ).toFixed(2)
                            )}
                          </td>
                          {isIGST && vendorType === "SELLER" ? (
                            <Fragment>
                              <td>&nbsp;&nbsp;</td>
                              <td>
                                {Number(orderSummary.totalTax).toFixed(2)}
                              </td>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <td>&nbsp;&nbsp;</td>
                              <td>
                                {Number(orderSummary.totalTax / 2).toFixed(2)}
                              </td>
                              <td>&nbsp;&nbsp;</td>
                              <td>
                                {Number(orderSummary.totalTax / 2).toFixed(2)}
                              </td>
                            </Fragment>
                          )}
                          <td>{Number(orderSummary.totalTax).toFixed(2)}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <p class="tax-inamount">
                  Tax Amount ( in words ) : INR{" "}
                  {orderSummary &&
                    orderSummary.totalTax &&
                    toWords.convert(orderSummary.totalTax, { currency: true })}
                </p>
              </Fragment>
            )}
            <p class="declaration">
              <b> Declaration </b>: We declare that this invoice shows the
              actual price of the goods Authozied signatory Describe and that
              all particulars are true and correct
            </p>
            <div className="invoice-footer-image">
              <div class="logo-receipt">
                <p>Ordered Through</p>
                <img src={LogoRecipet} />
              </div>
            </div>
          </div>
        </div>
        <div class="print-copy">
          <ReactToPrint
            trigger={() => <button class="print-receipt">Print</button>}
            content={() => pdfRef.current}
          />
          {/* <button class="print-receipt">Print</button> */}
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    companyDetails: state.homePageReducer.companyDetails,
  };
};
const mapDispatchToProps = {
  fetchCompanyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaxInvoice);
