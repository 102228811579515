

import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import { Input, message } from "antd";

import "./Searchbar.css";
import useDebounce from "../../Hooks/useDebounce";

const { Search } = Input;
const SearchbarInput = (props) => {
  const [searchVal, setSearchval] = useState("");
  const list = [];
  const debouncedSearchTerm = useDebounce(searchVal, 500);

  const handleproductSearch = (key) => {
    setSearchval(key);
  };
  useEffect(() => {
    var spclOnlyRegex = new RegExp("/ ^[^\w\s./+()&'%:-]+$/");
    if (spclOnlyRegex.test(debouncedSearchTerm)) {
      message.warning("Invalid search key!");
    } else {
      if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
        props.searchValue(debouncedSearchTerm);
      } else {
        props.searchValue("");
      }
    }
  }, [debouncedSearchTerm]);


  useEffect(() => {
    setSearchval("");
  }, [props.urlPath]);

  return (
    <Input
      placeholder="Search"
      enterButton="Search"
      size="large"
      style={{ width: "30%" }}
      value={searchVal}
      // onSearch={(value) => {
      //     handleproductSearch(value)
      // }}
      onChange={(event) => {
        handleproductSearch(event.target.value);
      }}
      className="manage-order-header-search"
    />
  );
};

export default withRouter(SearchbarInput);
