import React from "react"
import { Tag } from 'antd';
import { RequestConstants } from "../../../Utils/constants";
import moment from 'moment'

export const requestStatusHeader = [
  {
    title: "REQUEST ID",
    dataIndex: "requestId",
  },
  {
    title: "REQUEST DATE",
    dataIndex: "requestedDate",
    sorter: (a, b) => {
      if (a.requestedDate < b.requestedDate) {
        return -1;
      }
      if (a.requestedDate > b.requestedDate) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: "AMOUNT",
    dataIndex: "requestedAmount",
    sorter: (a, b) => a.requestedAmount - b.requestedAmount,
    align: "right",
  },
  {
    title: "PLATFORM CHARGE",
    dataIndex: "platformCharge",
    sorter: (a, b) => a.platformCharge - b.platformCharge,
    align: "right",
  },
  {
    title: "SETTLED AMOUNT",
    dataIndex: "settledAmount",
    sorter: (a, b) => a.settledAmount - b.settledAmount,
    align: "right",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    render: (status) => {
      switch (status) {
        case RequestConstants.REQUESTED:
          return (
            <Tag color="gold" key={status}>
              {status.toUpperCase()}
            </Tag>
          );
        case RequestConstants.APPROVED:
          return (
            <Tag color="geekblue" key={status}>
              {status.toUpperCase()}
            </Tag>
          );
        case RequestConstants.REJECTED:
          return (
            <Tag color="red" key={status}>
              {status.toUpperCase()}
            </Tag>
          );
        case RequestConstants.SENT_TO_BANK:
          return (
            <Tag color="purple" key={status}>
              {status.toUpperCase()}
            </Tag>
          );
        case RequestConstants.PAID:
          return (
            <Tag color="green" key={status}>
              {status.toUpperCase()}
            </Tag>
          );
        default:
          break;
      }
    },
  },
  {
    title: "UPDATED DATE",
    dataIndex: "updatedDate",
    sorter: (a, b) => {
      if (a.updatedDate < b.updatedDate) {
        return -1;
      }
      if (a.updatedDate > b.updatedDate) {
        return 1;
      }
      return 0;
    },
  },
  {
    title: "UPDATED BY",
    dataIndex: "updatedBy",
  },
  {
    title: "TRANSACTION ID",
    dataIndex: "transactionId",
    render: (transactionId) => {
      if (transactionId !== "") {
        return (
          <Tag color="green" key={transactionId}>
            {transactionId}
          </Tag>
        );
      } else {
        return (
          <Tag color="grey" key={transactionId}>
            {"PENDING"}
          </Tag>
        );
      }
    },
  },
  {
    title: "COMMENTS",
    dataIndex: "comments",
  },
];


export const requestPaymentHeader = [
        
    {
        title: 'ORDER ID',
        dataIndex: 'orderGroupId',
    },
    {
        title: 'ORDER DATE',
        dataIndex: 'orderedDate',
        sorter: (a, b) => {
            if (a.orderedDate < b.orderedDate) {
                return -1
            }
            if (a.orderedDate > b.orderedDate) {
                return 1
            }
            return 0
        },
    },
    {
        title: 'AMOUNT',
        dataIndex: 'amountToConsider',
        sorter: (a, b) => a.amountToConsider - b.amountToConsider,
        align: 'right'
    },
];