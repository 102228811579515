


export const formatOrderSummary = (order, vendor) => {
  let temp = { ...order };
  // temp && delete temp.processedOrderedItems;

  console.log("product list: ", order?.processedOrderedItems);

  if (order && vendor) {
    console.log("order:", order);
    console.log("vendor:", vendor);
  }
  if (order?.processedOrderedItems) {
    //in case of customer order summary
    temp && delete temp.processedOrderedItems;

    temp.orderedItems = vendor?.orderedItemsOfVendor;
    temp.taxSummaries = vendor?.vendorTaxSummaries;
    temp.bagTotal = vendor?.vendorOrderSummary?.bagTotal;
    temp.deliveryCharge = vendor?.vendorOrderSummary?.deliveryCharge;
    temp.totalDiscount = vendor?.vendorOrderSummary?.totalDiscount;
    temp.totalPrice = vendor?.vendorOrderSummary?.totalPrice;
    temp.subTotal = vendor?.vendorOrderSummary?.subTotal;
    temp.totalTax = vendor?.vendorOrderSummary?.totalTax;

    console.log("Result: ", temp);
    // var productList = order?.processedOrderedItems.map((vendor) => {
    //   return vendor.orderedItemsOfVendor.map((product) => {
    //     return product;
    //   });
    // });
    // temp.orderedItems = productList?.[0];
  } else {
    //in case of vendor order summary
    temp && delete temp.orderedItemsOfVendor;

    var productList = order?.orderedItemsOfVendor.map((product) => {
      return product;
    });
    productList = productList?.filter((item) => {
      if(item?.itemOrderStatus?.currentStatus !== 'CANCELLED'){
        return true;
      }
      return false;
    })
    temp.orderedItems = productList;
  }

  return temp;
};

export const getDeliveryAddress = (order) => {
  console.log("delivery address :", order?.shippingAddress);
};
