import React, { useState } from 'react'
import moment from 'moment';
import { BiDotsVerticalRounded } from "react-icons/bi";
import LogDetailedTable from './LogDetailedTable';

function LogDataTable(props) {

    const { logs } = props;

    const [ showModel, setShowModel ] = useState(false)
    const [ choosenData, setChoosenData ] = useState({})

    const detailsModelHandler = (list) => {
        setShowModel(true)
        setChoosenData(list)
    }

    return (
        <div className="LogDataTable-main-container">
            <div className="LogDataTable-table-heading-box">
                <p>USER</p>
                <p>ACTION</p>
                <p>SCREEN</p>
                <p>STATUS</p>
                <p>TIMESTAMP</p>
                <p>URL</p>
                <p>CLASS</p>
                <p>FUNCTION</p>
                <p>BROWSER</p>
                <p>OS</p>
            </div>

                <div className="LogDataTable-table-content-box">
                    { logs?.map((list, index) => (
                        <div className="LogDataTable-table-each-content" key={index}>
                            <p> <BiDotsVerticalRounded className="LogDataTable-more-menu" onClick={()=> detailsModelHandler(list)} /> {list?.source?.userInformations?.username}</p>
                            <p>{list?.source?.action}</p>
                            <p>{list?.source?.screenName}</p>
                            <p>{list?.source?.status}</p>
                            <p>{moment(list?.source?.timestamp).format('DD-MM-YYYY (hh:mm A)')}</p>
                            <p>{list?.source?.pageURL}</p>
                            <p>{list?.source?.className}</p>
                            <p>{list?.source?.functionName}</p>
                            <p>{list?.source?.userInformations?.browserInfo}</p>
                            <p>{list?.source?.userInformations?.deviceModel}</p>
                        </div> 
                        ))}
                </div>
                { showModel && (<LogDetailedTable data={choosenData} setShowModel={setShowModel} />)}
        </div>
    )
}

export default LogDataTable
