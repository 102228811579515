


import Axios from "../axios";
import Cookies from "universal-cookie";

import { API_SERV_ADDR } from "../../Utils/constants";
const cookies = new Cookies();

//place order by Customer
export const placeOrder = (payload) => {
  const data = {
    ...payload,
    customerId: cookies.get("userId"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/order/add`,
    method: "POST",
    data: data,
  });
};

export const verifyPayment = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/verify-payment`,
    method: "POST",
    data: payload,
  });
};

export const paymentFailed = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/failed-payment`,
    method: "POST",
    data: payload,
  });
};

export const getCustomerReturns = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/return/customer/list-orders`,
    method: "POST",
    data: payload,
  });
};

export const getCustomerReturnsStatusUpdate = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/return/customer/update-details`,
    method: "POST",
    data: payload,
  });
};

//fetch orderlist by customer
export const fetchOrderList = (payload) => {
  const data = {
    ...payload,
    userId: cookies.get("userId"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/order/customer/list-search`,
    method: "POST",
    data: data,
  });
};

export const fetchSubscribeList = (payload) => {
  const data = {
    ...payload,
    userId: cookies.get("userId"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/order/get-subscriptions`,
    method: "POST",
    data: data,
  });
};

export const cancelSubscribeList = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/cancel-subscriptions`,
    method: "POST",
    data: payload,
  });
};
//cancel order Item
export const CancelOrderItem = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/item/cancel`,
    method: "POST",
    data: payload,
  });
};

//request return
export const requestReturn = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/return/raise-request`,
    method: "POST",
    data: payload,
  });
};

//get cart count

export const fetchUserCartCount = () => {
  const userId = cookies.get("userId");
  const accountType = cookies.get("accountType");
  return Axios({
    url: `${API_SERV_ADDR}/user/${userId}/${accountType}/cart-items/count`,
    method: "GET",
  });
};

//return period expired
export const returnPeriodExpired = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/end-return-period`,
    method: "POST",
    data: payload,
  });
};

export const addGuestCart = (cartItems) => {
  const data = {
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
    cartItems: cartItems,
  };
  return Axios({
    url: `${API_SERV_ADDR}/user/cart/add-all`,
    method: "PUT",
    data: data,
  });
};
