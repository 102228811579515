


import React from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {
  addCategoryfilter,
  addSubCategory00filter,
  addSearchFilter,
} from "../../ReduxStore/action/productActions";
import { ENV_TYPE } from "../../Utils/constants";

import { SearchOutlined } from "@ant-design/icons";

import SearchIconImg from "../../Assets/Images/Vectors/search-image.svg";

import "./CategoryCard.scss";

const CategoryCard = (props) => {
  const { image, title, id, seller, subCategoryId, subCategoryName } = props;

  //to add selected categoryName to bread crumb
  const handleManageBreadCrumb = (data) => {
    let breadCrumbList = [];
    breadCrumbList.push(data);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  const handleRedirection = () => {
    console.log(id, "categoryId");
    props.addCategoryfilter(id);
    if(subCategoryId) {
      props.addSubCategory00filter(subCategoryId);
    }
    props.history.push({
      pathname: ENV_TYPE === "production"
        ? `/product-listing/${subCategoryName? subCategoryName : title}`
        : `/customer/product-listing/${subCategoryName? subCategoryName : title}`,
      seller: seller
  });
    const data = {
      label: title,
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${subCategoryName? subCategoryName : title}`
          : `/customer/product-listing/${subCategoryName? subCategoryName : title}`,
      type: "catogery",
      id: id,
    };
    handleManageBreadCrumb(data);
  };
  return (
    <div
      className="category-card-container"
      onClick={() => handleRedirection()}
    >
      <div
        className="pdt_category_image"
        style={{ backgroundImage: "url(" + image + ")" }}
      >
        <p className="category-card-title">
          {subCategoryName? subCategoryName : title}
        </p>
      </div>
      {/* <img className="category-card-img" src={image} alt="" /> */}

      <div className="category-card-overlay"></div>
    </div>
  );
};

const mapDispatchToProps = { addCategoryfilter, addSubCategory00filter };
export default withRouter(connect(null, mapDispatchToProps)(CategoryCard));
