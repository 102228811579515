


import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Button,
  Typography,
  Form,
  Input,
  Modal,
  Row,
  Col,
  message,
  Radio,
} from "antd";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import CompanyLogo from "../../../Assets/Images/Logo/DL_Logo.png";
import SellerLoginVector from "../../../Assets/Images/Vectors/store-logo.svg";
import {
  registerStore,
  editStoreWarehouse,
} from "../../../ApiAction/CustomerApiActions/customerApi";
import Loading from "../../../components/GeneralComponents/Loading";
import { userAddByAdmin } from "../../../ApiAction/AdminApiAction/adminApi";
import ValidateOtp from "../../GeneralComponents/validateOtp";
const { Title, Text } = Typography;

const AddStoreModal = (props) => {
  const [form] = Form.useForm();
  const [initialItem, setInitialItems] = useState();
  const [loading, setloading] = useState(false);
  const [paymentGatewayModalVisible, setPaymentGatewayModal] = useState(false);
  const [paymentGateway, setPaymentGateway] = useState("CCAVENUE");
  const [ccavenueRequest, setCcavenueRequest] = useState();
  const [formValues, setFormValues] = useState();
  const [verifyOtp, setVerifyOtp] = useState();
  const [requestId, setRequestId] = useState();
  const [otp, setOtp] = useState();

  const CCAvenuePaymentForm = useRef();
  const formRef = useRef();

  const handleOk = () => {
    setInitialItems({});
    props.setVisible(false);
  };

  useEffect(() => {
    setInitialItems({});
    if (props.isEdit) {
      // console.log("props.editItem: ", props.editItem)
      const initialValues = {
        UserName: props.editItem.name,
        emailId: props.editItem.email,
        communicationEmailId: props.editItem.communicationEmail,
        phoneNumber: props.editItem.mobile,
        storeName: props.editItem.storeName,
        referalId: "",
        pinCode: props.editItem.pinCode,
      };
      setInitialItems(initialValues);
    }
  }, [props]);

  useEffect(() => {
    if(CCAvenuePaymentForm.current)
      CCAvenuePaymentForm.current.submit();
  }, [ccavenueRequest]);

  useEffect(() => {
    if(formValues) {
      // if(props.isEdit || props.isAdmin)
        onFinish();
      // else
      //   setPaymentGatewayModal(true)
    }
  }, [formValues]);

  useEffect(() => {
    if(otp)
      onFinish();
  }, [otp]);

  const handleCancel = () => {
    setInitialItems({});
    if(props.addModal)
      props.addModal(false);
    props.setVisible(false);
    setloading(false);
    form.resetFields();
  };

  const onFinish = () => {
    let payload = {
      name: formValues.UserName,
      email: formValues.emailId,
      //communication Email
      communicationEmail: formValues.communicationEmailId,
      mobile: formValues.phoneNumber,
      pinCode: formValues.pinCode,
      referralId: formValues.referalId,
      storeName: formValues.storeName,
      accountType: props.isWarehouse ? "warehouse" : "store",
    };

    let payloadEditUser = {
      name: formValues.UserName,
      email: formValues.emailId,
      communicationEmail: formValues.communicationEmailId,
      mobile: formValues.phoneNumber,
      pinCode: formValues.pinCode,
      referralId: formValues.referalId,
      storeName: formValues.storeName,
    };
    let paylodWarehouse = {
      name: formValues.UserName,
      email: formValues.emailId,
      communicationEmail: formValues.communicationEmailId,
      mobile: formValues.phoneNumber,
      pinCode: formValues.pinCode,
      referralId: formValues.referalId,
      storeName: formValues.storeName,
      accountType: props.isWarehouse ? "warehouse" : "store",
    };

    if (props.isEdit) {
      //for updating store / warehouse
      payload["id"] = props.editItem.id;
      payload["version"] = props.editItem.version;

      paylodWarehouse["id"] = props.editItem.id;
      paylodWarehouse["version"] = props.editItem.version;

      const payloadEdit = {
        id: props.editItem.id,
        accountType: props.isWarehouse ? "warehouse" : "store",
        user: payloadEditUser,
      };
      setloading(true);
      editStoreWarehouse(payloadEdit)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              message.success("Updated successfully");
              props.setSuccess();
            } else message.error(response.data.message);
          } else message.error("Unable to update please try again later");
        })
        .catch(() => {})
        .finally(() => {
          setloading(false);
        });
    }
    //for creating new store / warehouse
    else {
      setloading(true);
      const finalPayload = props.isWarehouse? paylodWarehouse : payload;
      (props.isAdmin? userAddByAdmin(finalPayload) :
        registerStore({...finalPayload, paymentGateway: paymentGateway, otp: otp, userId: requestId}))
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status == "success") {
              if(!props.isAdmin) {
                if(otp) {
                  message.success("Redirecting to payment page")
                  paymentHandler(response.data.order);
                } else {
                  setRequestId(response.data.userId);
                  setVerifyOtp(true);
                }
              } else {
                message.success("Added store successfully");
                props.setSuccess();
              }
            } else {
              message.error(response.data.message);
              setloading(false);
            };
          } else message.error("Unexpected error occured please try again");
        })
        .catch(() => {});
      }
  };

  const paymentHandler = async (data) => {

    if(data.redirectInfo) {
      window.location.replace(data.redirectInfo.url);
    } else if(data.ccavenuePaymentRequest) {
      setCcavenueRequest(data.ccavenuePaymentRequest);
    } else message.error("Order creation failed");

  };

  const PincodeValidator = (rule, value, callback) => {
    if (value && value.length > 0 && value.length != 6) {
      console.log("value for validation: ", value);
      callback("");
    } else {
      callback();
    }
  };
  const PhoneValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 12) {
      callback("Enter valid phone number");
    } else {
      callback();
    }
  };
  const formFields = [
    {
      name: "UserName",
      placeHolder: "User Name*",
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z ]+$"),
          message: "Enter a valid User Name",
        },
        { required: true, message: "This field is required !" },
      ],
    },
    {
      name: "emailId",
      placeHolder: "Email ID*",
      ruleType: "email",
      message: "Enter a valid email Id",
    },
    {
      name: "communicationEmailId",
      placeHolder: "Communication Email ID*",
      ruleType: "email",
      message: "Enter a valid email Id",
    },
    {
      name: "phoneNumber",
      placeHolder: "Phone Number*",
      validator: PhoneValidator,
    },
    {
      name: "storeName",
      placeHolder: props.isWarehouse ? "Warehouse Name*" : "Store Name*",
    },

    { name: "referalId", placeHolder: "Referal ID", referalId: true },
    {
      name: "pinCode",
      placeHolder: "PIN Code*",
      type: "text",
      message: "Enter a valid pincode",
      maxLength: 6,

      rules: [
        {
          pattern: new RegExp("^[1-9][0-9]{5}$"),
          message: "Enter a valid pincode",
        },
        { required: true, message: "This field is required !" },
        { validator: PincodeValidator },
      ],
    },
  ];

  return (
    <div>
    <Modal
      // closable={false}
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={props.isEdit ? 500 : 1000}
      footer={null}
    >
      <div className="flex">
        <Row style={{ width: "100%" }}>
          {!props.isEdit && (
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div>
                <img
                  className="login-company-logo"
                  src={CompanyLogo}
                  alt={"company logo"}
                />

                <div className="store-logo" style={{ paddingTop: "12%" }}>
                  <img src={SellerLoginVector} alt={"company logo"} />
                </div>
              </div>
            </Col>
          )}
          <Col
            xs={24}
            sm={24}
            md={props.isEdit ? 24 : 12}
            lg={props.isEdit ? 24 : 12}
            xl={props.isEdit ? 24 : 12}
          >
            <div style={{ padding: "12%" }}>
              <Title level={3}>
                {props.isWarehouse
                  ? props.isEdit
                    ? "Update Warehouse"
                    : "Add Warehouse"
                  : props.isEdit
                  ? "Update Store"
                  : "Register Store"}
              </Title>
              <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={props.isEdit ? initialItem : {}}
                ref={formRef}
                onFinish={(values) => {
                  setFormValues(values);
                }}
              >
                {formFields.map((data, index) => {
                  return (
                    <Fragment key={index}>
                      {props.isEdit ? data.placeHolder : ""}
                      <Form.Item
                        hasFeedback
                        name={data.name}
                        // label={props.isEdit ? data.placeHolder : ""}
                        rules={
                          data.rules
                            ? data.rules
                            : [
                                {
                                  type: data.ruleType ? data.ruleType : null,
                                  message: data.message ? data.message : null,
                                },
                                {
                                  required: data.referalId ? false : true,
                                  message: "This field is required !",
                                },
                                {
                                  validator: data.validator
                                    ? data.validator
                                    : "",
                                },
                              ]
                        }
                      >
                        {data.name === "phoneNumber" ? (
                          <PhoneInput
                            country={"in"}
                            style={{ border: "1px solid #d9d9d9" }}
                            className="phone_number_inpt"
                            countryCodeEditable={false}
                            // value={this.state.phone}
                            onChange={(phone) => console.log(phone)}
                          />
                        ) : (
                          <Input
                            ref={data.ref ? data.ref : null}
                            type={data.type ? data.type : null}
                            placeholder={data.placeHolder}
                            maxLength={data.name === "pinCode" ? 6 : null}
                            style={{ height: "50px" }}
                            disabled={props.isEdit && data.name === "emailId"}
                          />
                        )}
                      </Form.Item>
                    </Fragment>
                  );
                })}
                {props.signUpAmount && !props.isWarehouse && (
                  <div style={{marginBottom: "20px"}}>
                    <Text style={{color: "red"}}>*</Text>
                    <Text level={8}> Requires a payment of INR {props.signUpAmount} for registering </Text>
                    {ccavenueRequest && (
                      <form id="nonseamless" method="post" name="redirect" action={ccavenueRequest.paymentLink} ref={CCAvenuePaymentForm}>
                        <input type="hidden" id="encRequest" name="encRequest" value={ccavenueRequest.encryptedRequest} />
                        <input type="hidden" name="access_code" id="access_code" value={ccavenueRequest.accessCode} />
                      </form>
                    )}
                  </div>
                )}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ height: "50px" }}
                  >
                    {loading ? (
                      <Loading style={{ color: "white" }} />
                    ) : props.isWarehouse ? (
                      props.isEdit ? (
                        " UPDATE WAREHOUSE"
                      ) : (
                        " REGISTER WAREHOUSE"
                      )
                    ) : props.isEdit ? (
                      " UPDATE STORE"
                    ) : (
                      " REGISTER STORE"
                    )}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={handleCancel}
                    className="cancel-form-button"
                  >
                    CANCEL
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>

    <Modal
      title="Select payment gateway"
      centered={true}
      okText={"Pay"}
      cancelText={null}
      visible={paymentGatewayModalVisible}
      onOk={onFinish}
      onCancel={() => setPaymentGatewayModal(false)}
    >

    <Radio.Group
      style={{
        width: "100%",
        // marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        padding: "0 0px",
      }}
      onChange={e => setPaymentGateway(e.target.value)}
      value={paymentGateway}
      confirmLoading={loading}
    >
      <Radio
        value={"CCAVENUE"}
        className="payment-radio"
        defaultChecked={true}
        style={{ borderBottom: "1px solid #f0f0f0" }}
      >
        CCAVENUE
      </Radio>
      <Radio value={"PHONEPE"} className="payment-radio">
        PHONEPE
      </Radio>

    </Radio.Group>

  </Modal>

  <ValidateOtp
    signUp={true}
    visible={verifyOtp}
    setVisible={setVerifyOtp}
    setOtp={setOtp}
  />
  </div>
  );
};
export default AddStoreModal;
