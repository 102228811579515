

import React,{useState} from 'react'
import './manageAccounts.scss'
import { Table, Pagination,Divider,Tag, Popover } from 'antd';
import { SafetyCertificateFilled, InfoCircleOutlined } from '@ant-design/icons';
import FilterRequest from '../../Seller/RequestPayment/FilterRequest'
import Column from 'antd/lib/table/Column';
import { RequestConstants } from '../../../Utils/constants';
import AccountModal from './AccountModal';
import { useEffect } from 'react';
import { getManageAccount, getInvoiceSplits } from '../../../ApiAction/SellerApiActions/sellerApi';
import moment from 'moment';

export default function ManageAccounts() {
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false)
    const [data, setData] = useState({})
    const [searchVal, setSearchVal] = useState('')
    const [statusKey, setStatusKey] = useState('')
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [totalCount, setTotalCount] = useState(0)
    const [offset, setOffset] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [downloadType] = useState("admin")
    const [placeholder] = useState("Search here (Eg: Nadathara)")
    const [manageAccountsData, setManageAccountsData] = useState([])
    const [dataUpdated, setDataUpdated] = useState(false)

    useEffect(()=>{
        setLoading(true)
        const payload = {
            size: 10,
            offset: offset,
            sortType: 'desc',
            sortBy: 'requestedDate',
            key: searchVal,
            statusKey: statusKey,
            startTime: startTime,
            endTime: endTime
        }
        getManageAccount(payload).then((response)=> {
            if(response) {
                setTotalCount(response.data.count)
                mapData(response.data.result)
            }
        }).catch(error => {
            console.log(error.message)
        }).finally(()=> {
            setLoading(false)
        })
    },[offset,searchVal, statusKey, endTime, dataUpdated])

    const mapData = (data) => {
        let tempArray = []
        data.map((doc, index) => {
            tempArray.push({
              key: index,
              id: doc.id,
              version: doc.version,
              requestId: doc.requestId,
              vendorName: doc.vendor,
              requestDate: moment(doc.requestedDate).format(
                "DD • MM • YYYY (hh:mm A)"
              ),
              status: doc.requestStatus,
              updatedBy:
                doc.requestStatus === RequestConstants.REQUESTED
                  ? "-"
                  : doc.updatedBy,
              updateDate:
                doc.requestStatus === RequestConstants.REQUESTED
                  ? "-"
                  : moment(doc.updatedDate).format("DD • MM • YYYY (hh:mm A)"),
              amount: doc.requestedAmount.toFixed(2),
              comments: doc.comments,
              platformCharge: doc?.adjustedAmount,
              amountToPay: (doc?.requestedAmount - doc?.adjustedAmount).toFixed(2),
              orderGroupIds: doc.orderGroupIds,
              overallStatus: doc.overallStatus,
              bankInformations: doc.bankInformations,
              transactionId: doc.transactionId,
            });
        })
        setManageAccountsData(tempArray)
    }

    const handlePagination = (page) => {
        setOffset(10 * (page - 1))
        setCurrentPage(page)
    }

    return (
      <div className="manage-accounts">
        <div className="manage-top-part">
          <h2 style={{ fontWeight: "bold" }}>
            <SafetyCertificateFilled /> Manage Accounts
          </h2>
          <h4 style={{ color: "rgba(0,0,0,0.5)" }}>
            Manage Account can handle all the Request Payment send by the
            vendor.
          </h4>
          {/* This Popover will display information about this Service */}
        </div>
        <Divider />
        <FilterRequest
          downloadType={downloadType}
          placeHolder={placeholder}
          setSearchVal={setSearchVal}
          setStatusKey={setStatusKey}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          searchVal={searchVal}
          startTime={startTime}
          endTime={endTime}
          statusKey={statusKey}
          totalDoc={totalCount}
          setOffset={setOffset}
          setCurrentPage={setCurrentPage}
          offset={offset}
        />
        <Table
          className="manageAccountTable"
          loading={loading}
          onRow={(item) => ({
            onClick: (e) => {
              e.stopPropagation();
              const payload = {
                invoice: item.orderGroupIds,
              };
              getInvoiceSplits(payload)
                .then((response) => {
                  if (response) {
                    if (response.data.status === "success") {
                      console.log("inside response");
                      Object.assign(item, {
                        invoiceSplitData: response.data.data,
                      });
                    }
                  }
                })
                .catch((error) => {
                  console.log(error.message);
                })
                .finally(() => {
                  setData(item);
                  setModalVisible(true);
                });
            },
          })}
          dataSource={manageAccountsData}
          pagination={false}
          scroll={{ x: 2000 }}
          style={{ cursor: "pointer" }}
        >
          <Column
            title={"REQUEST ID"}
            dataIndex="requestId"
            fixed="left"
            width="280px"
          />
          <Column
            title={"VENDOR NAME"}
            dataIndex="vendorName"
            fixed="left"
            width="250px"
          />
          <Column
            title={"REQUESTED DATE"}
            dataIndex="requestDate"
            sorter={(a, b) => {
              if (a.requestDate < b.requestDate) {
                return -1;
              }
              if (a.requestDate > b.requestDate) {
                return 1;
              }
              return 0;
            }}
            width="280px"
          />
          <Column
            title={"STATUS"}
            dataIndex="status"
            render={(status) => {
              switch (status) {
                case RequestConstants.REQUESTED:
                  return (
                    <Tag color="gold" key={status}>
                      {status.toUpperCase()}
                    </Tag>
                  );
                case RequestConstants.APPROVED:
                  return (
                    <Tag color="geekblue" key={status}>
                      {status.toUpperCase()}
                    </Tag>
                  );
                case RequestConstants.REJECTED:
                  return (
                    <Tag color="red" key={status}>
                      {status.toUpperCase()}
                    </Tag>
                  );
                case RequestConstants.SENT_TO_BANK:
                  return (
                    <Tag color="purple" key={status}>
                      {status.toUpperCase()}
                    </Tag>
                  );
                case RequestConstants.PAID:
                  return (
                    <Tag color="green" key={status}>
                      {status.toUpperCase()}
                    </Tag>
                  );
                default:
                  break;
              }
            }}
          />
          <Column
            title={"REQUESTED AMOUNT"}
            dataIndex="amount"
            sorter={(a, b) => {
              if (a.amount < b.amount) {
                return -1;
              }
              if (a.amount > b.amount) {
                return 1;
              }
              return 0;
            }}
            align="right"
          />
          <Column
            title={"PLATFORM CHARGE"}
            dataIndex="platformCharge"
            sorter={(a, b) => {
              if (a.platformCharge < b.platformCharge) {
                return -1;
              }
              if (a.platformCharge > b.platformCharge) {
                return 1;
              }
              return 0;
            }}
            align="right"
          />
          <Column
            title={"AMOUNT TO PAY"}
            dataIndex="amountToPay"
            sorter={(a, b) => {
              if (a.amountToPay < b.amountToPay) {
                return -1;
              }
              if (a.amountToPay > b.amountToPay) {
                return 1;
              }
              return 0;
            }}
            align="right"
          />
          <Column
            title={"UPDATED DATE"}
            dataIndex="updateDate"
            sorter={(a, b) => {
              if (a.updateDate < b.updateDate) {
                return -1;
              }
              if (a.updateDate > b.updateDate) {
                return 1;
              }
              return 0;
            }}
            width="280px"
          />
          <Column title={"UPDATED BY"} dataIndex="updatedBy" width="280px" />
        </Table>

        {modalVisible && (
          <>
            <div
              className={
                modalVisible
                  ? "manage-account-model-container"
                  : "hide-container"
              }
            >
              <AccountModal
                data={data}
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                setDataUpdated={setDataUpdated}
                dataUpdated={dataUpdated}
              />
            </div>
          </>
        )}

        <br />
        <br />
        <Pagination
          currentPage={currentPage}
          total={totalCount}
          showSizeChanger={false}
          onChange={(page) => {
            window.scrollTo(0, 0);
            handlePagination(page);
          }}
        />
      </div>
    );
}
