

import React, { useState, useEffect } from "react";

import { Typography, Pagination, Card } from "antd";
import { connect } from "react-redux";

import SortIcon from "../../../Assets/Images/Icons/Seller/Sort2.svg";
import { withRouter } from "react-router-dom";

import { getProducts } from "../../../ApiAction/SellerApiActions/sellerApi";
import Cookies from "universal-cookie";
import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";
import EmptyList from "../../GeneralComponents/emptyList";
import Search from "antd/lib/input/Search";
import { fetchReplacedProducts } from "../../../ApiAction/AdminApiAction/adminApi";
import { GoVerified } from "react-icons/go";

const { Title, Text } = Typography;

const ReplacedProducts = (props) => {
  const [isMobileView, setisMobileView] = useState(false);
  const [productList, setProductList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingProd, setisLoadingProd] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    getProductsList(searchVal,1);
  }, []);


  const getProductsList = (searchKey=searchVal, page) => {
    setCurrentPage(page);
    const payload = {
      offset: 10 * (page - 1),
      size: 10,
      key: searchKey,
      sortBy: "fromName",
      sortType: "asc"
      
    };
    setisLoadingProd(true);
    fetchReplacedProducts(payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(response.data.documents);
          setTotal(Math.ceil(response.data.count / 10) * 10);
        }
        else{
            setProductList([]); 
        }
      })
      .catch(() => {})
      .finally(() => {
        setisLoadingProd(false);
      });
  };

  const handleSearch =(e) =>{
    setCurrentPage(1);
    setSearchVal(e.target.value);
    getProductsList(searchVal,1);
  }


  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        {" "}
        {!isMobileView ? (
          <h1 level={4} className="sub-title-tags">
            {" "}
            Replaced Products
          </h1>
        ) : (
          <Text>
            {" "}
            <span>
              {" "}
              <img
                src={SortIcon}
                alt="delete-icon"
                style={{ width: "25px", paddingRight: "5px" }}
              />
            </span>
            Sort
          </Text>
        )}
      </div>
      <Search
      placeholder="Search products here."
      size="large"
      // loading={barcodeSearching}
      // value={searchedProduct}
      onChange={(e)=>{
        setSearchVal(e.target.value);
        getProductsList(e.target.value,1);
      }}
      // onSearch={(value) => setSearchVal(value,1)}
    />
      {isLoadingProd ? (
        <LoadingCartItem />
      ) : (
        productList?.length === 0 && <EmptyList msg={"No Products found"} />
      )}
      {productList.map((data) => (
        <Card
            hoverable
            bordered={false}
            height={250}
            style={{ width: "100%", marginBottom: "20px", marginTop: "10px" }}
            className="manage-product-card-container"
        >
            <div>
                <div className="replace-central-repo-prod">
                    <p style={{color:"red",fontWeight:"bold"}}>OLD NAME:</p>
                    <b>{data?.source?.fromName}</b>
                </div>
                <div className="replace-central-repo-prod">
                    <p style={{color:"green",fontWeight:"bold"}}>NEW NAME:</p>
                    <b>{data?.source?.toName}</b>
                    {<div style={{paddingTop: "2px"}}><GoVerified></GoVerified></div>}
                </div>
            </div>

        </Card>
      ))}
      {total > 10 && (
        <div className="admin-manage-customer">
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            total={total}
            showSizeChanger={false}
            className="customer-pagination"
            onChange={(page) => {
              getProductsList(searchVal ? searchVal : "", page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ReplacedProducts;


