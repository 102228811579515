import {
    SELLER_STATUS
} from "../action/types";


export default function sellerStatus(
    state = {
        sellerStatus: "",
    },
    action
) {
    switch (action.type) {

        case SELLER_STATUS:
            state = { ...state, sellerStatus: action.payload };
            break;

        default:
        // console.log("invalid choice");
    }

    return state;
}
