


import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { Layout, Typography, Avatar, Select, Col, Row, Button } from "antd";
import BreadCrumb from "../ProductListing/breadCrumb";
import "./cart.css";
import { UserOutlined, CloudUploadOutlined } from "@ant-design/icons";
import QuantityController from "../../../ProductCard/components/QuantityController";
import { Link, withRouter } from "react-router-dom";
import { cartGet } from "../../../../ApiAction/SellerApiActions/sellerApi";
import { ENV_TYPE, FILE_PATH } from "../../../../Utils/constants";

const { Content, Sider } = Layout;
const { Option } = Select;
const { Title, Text } = Typography;
const CartItem = (props) => {
  const {
    product,
    deleteProduct,
    updateProduct,
    number,
    remove,
    update,
  } = props;
  const cookies = new Cookies();
  const [activeCustomer, setactiveCustomer] = useState(
    JSON.parse(localStorage.getItem("activeCustomer"))
  );
  const [quantity, setQuantity] = useState(1);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [processedPrice, setProcessedPrice] = useState();
  const [price, setPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [selectedItem, setSelectedItem] = useState();

  const [selectedQuantity, setSelectedQuantity] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState("");

  const accountType = cookies.get("accountType")
    ? cookies.get("accountType")
    : "customer";

  useEffect(() => {
    setProcessedPrice(product.processedPriceAndStocks);
    setQuantity(product.quantity);
    if (product.itemCurrentPrice) {
      setSelectedItem(product.itemCurrentPrice);
      setPrice(product.itemCurrentPrice.price);
      setSellingPrice(product.itemCurrentPrice.sellingPrice);
      setSelectedVariant(product.itemCurrentPrice.serialNumber);
      setAvailableQuantity(product.itemCurrentPrice.stock);

      // setSelectedQuantity(
      //   product.itemCurrentPrice.quantity + product.itemCurrentPrice.unit
      // );

      setSelectedQuantity(
        `${product.itemCurrentPrice.quantity} ${product.itemCurrentPrice.unit}`
      );

      setSelectedDiscount(product.itemCurrentPrice.discount);
    }

    console.log("product:", product);
  }, [product, product?.itemCurrentPrice]);

  function handleChange(value, info) {
    const selected = processedPrice.find(
      (item) => item.serialNumber == info.key
    );

    console.log(info, selected);

    setSelectedItem(selected);
    setPrice(selected.price);
    setSellingPrice(selected.sellingPrice);
    setSelectedVariant(selected.serialNumber);
    setAvailableQuantity(selected.stock);
    setSelectedQuantity(`${selected.quantity} ${selected.unit}`);
    //  setSelectedQuantity(selected.quantity + selected.unit);
    setSelectedDiscount(selected.discount);

    // const paylaod = {
    //   userId: cookies.get("userId"),
    //   item: {
    //     productId: product.productId,

    //     vendorId: product.vendorId,
    //     vendorType: product.vendorType,
    //     quantity: quantity,
    //     itemCurrentPrice: selected,
    //   },
    // };
    const paylaod = {
      id: product?.id ? product.id : product.productId,
      quantity: quantity,
      productId: product.productId,
      itemCurrentPrice: selected,
    };
    updateProduct(
      localStorage.getItem("guestCart")
        ? { ...paylaod, itemId: product.itemId, isChangeVariant: true }
        : paylaod
    );
  }
  const removeProduct = () => {
    const payload = {
      id: product?.id ? product.id : product.productId,
      productId: product.productId,
      userId: cookies.get("userId"),
    };
    deleteProduct(
      localStorage.getItem("guestCart")
        ? {
            ...payload,
            itemCurrentPrice: product?.itemCurrentPrice,
            itemId: product.itemId,
          }
        : payload
    );
  };

  const updateCart = (value, updatedValue) => {
    setQuantity(value);
    const paylaod = {
      // userId: cookies.get("userId"),
      id: product?.id ? product.id : product.productId,
      quantity: updatedValue,
      productId: product.productId,
      itemCurrentPrice: selectedItem,
    };
    updateProduct(
      localStorage.getItem("guestCart")
        ? { ...paylaod, itemId: product.itemId }
        : paylaod
    );
  };

  const handleRedirection = () => {
    // if (accountType === "store") {
    //   props.history.push(
    //     `/store/linked-warehouse/product-detail/${product.productId}`
    //   );
    // } else
    if (accountType === "customer") {
      props.history.push(
        ENV_TYPE === "production"
          ? `/product-detail/${product.productId}`
          : `/customer/product-detail/${product.productId}`
      );
    }
    //  else if (accountType === "warehouse") {
    //   props.history.push(
    //     `/warehouse/linked-warehouse/product-detail/${product.productId}`
    //   );
    // }
  };
  useEffect(() => {}, []);

  return (
    <div
      className={
        remove
          ? "cart-items remove-item"
          : update
          ? "cart-items update-item"
          : "cart-items"
      }
    >
      <Row style={{ width: "100%" }}>
        <Col span={12}>
          <div className="flex cart_resp">
            <span
              className="flex-center cart_counter"
              style={{
                color: "#000000",
                fontWeight: "500",
                paddingRight: "25px",
                paddingLeft: "15px",
              }}
            >
              {number}
            </span>
            {/* <Link to={`/customer/product-detail/${product.productId}`}> */}
            {/* {console.log(`This is the product url : customer/product-detail/${product.productId}` )} */}
            <div className={`flex-center ${accountType === "customer" ?  "avatar-link" : ""}`}>
              <Avatar
                src={FILE_PATH + product.image}
                size={95}
                icon={<UserOutlined />}
                onClick={() => handleRedirection()}
              />
            </div>
            {/* </Link> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginLeft: "15px",
              }}
            >
              <div className="cart-name-layout cnl-stripe">
                <div className="product-cart-name avatar-link" onClick={handleRedirection}>{product.productName}</div>
                <div className="flex">
                  <div>
                    <div className="cart-price">
                      ₹ {Number(sellingPrice).toFixed(2)}
                    </div>
                    <div className="cart-actual-price">
                      <del>₹ {Number(price).toFixed(2)}</del>
                    </div>
                  </div>
                  <div className="cart-size">{selectedQuantity}</div>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={12} className="remove-layout">
          <div className="product-cart-counter-layout checkout-cart-pg">
            <QuantityController
              quantity={quantity}
              stock={availableQuantity}
              update={true}
              isFromCart
              setQuantity={updateCart}
            />
            <Select
              //  size={"small"}
              value={selectedQuantity}
              onChange={handleChange}
              className="cart-action-select qty_drop"
            >
              {processedPrice &&
                processedPrice.map((item) => (
                  <Option
                    value={`${item.quantity} ${item.unit}`}
                    key={item.serialNumber}
                  >
                    {`${item.quantity} ${item.unit}`}
                  </Option>
                ))}
            </Select>
          </div>

          <div className="cart-size">
            {quantity > availableQuantity ? (
              availableQuantity !== 0 ? (
                <Text
                  strong
                  type="danger"
                >{`${availableQuantity} stocks left `}</Text>
              ) : (
                <Text strong type="danger">{`Out of stock`}</Text>
              )
            ) : (
              ""
            )}
          </div>

          <div className="cart-remove" onClick={() => removeProduct()}>
            {" "}
            <p style={{ margin: "0" }}>Remove</p>
            <span className="icon-rmv"></span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default withRouter(CartItem);
