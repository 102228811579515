


export const formatCategoryList = ({ list, filterKey }) => {
  let result = [];

  list.map((data, index) => {
    let temp = {
      id: index,
      categoryId: data.id,
      categoryName: data.categoryName,
      minCategoryDiscount: data.minCategoryDiscount,
      categoryCommission: data.categoryCommission,
      categoryFeature: data.categoryFeature,
      brands: data.brands,
      brandIds: data.brandIds,
      topBrandIds: data.topBrandIds,
      version: data.version,
      subCategories: data?.subCategories,
      childCategoryId: data?.childCategoryId,
      typeImage: data?.image,
      isSellerEnabled: data?.isSellerEnabled,
      salesIncentiveRanges: data?.salesIncentiveRanges
    };

    result.push(temp);
  });

  //   if category list need to be filtered based on search key//
  if (filterKey) {
    result = result.filter((data) =>
      data.categoryName.toLowerCase().includes(filterKey.toLowerCase())
    );
  }

  return result;
};

export const formatBrandList = (list) => {
  let result = [];
  list.map((brand) => {
    let temp = {
      id: brand.brandId,
      name: brand.brandName,
      logo: brand.image,
      topBrand: brand.topBrand,
    };
    result.push(temp);
  });

  return result;
};
