


import React, { useEffect, useState } from "react";
import { message, Pagination } from "antd";

import "./manageProductList.css";
import ManageProductCard from "./manageProductCard";
import ManagaProductCardDraft from "./managaProductCardDraft";
import {
  getProducts,
  moveProducts,
  deleteSingleProduct,
  updateSellerProduct,
  approveSingleProduct,
} from "../../../../ApiAction/SellerApiActions/sellerApi";
import {
  deleteProductFromCentralRepo,
  draftToggleCentralRepo,
} from "../../../../ApiAction/AdminApiAction/centralRepoApi";
import AddProduct from "./addProduct";
import Cookies from "universal-cookie";
import LoadingCartItem from "../../../Customer/Components/Cart/loadingCartItem";
export default function ManageProductList(props) {
  const cookies = new Cookies();
  const { isCentralRepo, isActive,setReload, isVendor, isLoading } = props;
  const [productList, setProductList] = useState([]);
  // const [total, setTotal] = useState(0);
  const { type } = props;
  const product = [1, 2, 3, 4, 5, 6, 7, 8];

  useEffect(() => {
    setProductList(props.productList);
    // setTotal(props.total);
  }, [props]);
  const getProductsList = (page = 1) => {
    props.nextpage(page);

    // const term = [];

    // term.push({
    //   vendorId: [cookies.get("vendorId")],
    // });
    // term.push({
    //   isDraft: [type === "listed"?"false":"true"],
    // });
    // const payload={
    //   offset:10 * (page - 1),
    //   size:10,
    //   key:"",
    //   sortBy:"lastModifiedDate",
    //     sortType:"asc",
    //   filter:{
    //     term:term
    //   }
    // }
    // getProducts(payload)
    //         .then((response) => {
    //           if(response.data.status=="success")
    //           {
    //             setProductList(response.data.products)
    //             setPage(Math.ceil(response.data.count / 10)*10)
    //           }

    //         })
    //         .catch(() => {

    //         })
  };

  const handleMoveFromDraft = (productId) => {
    isCentralRepo
      ? handlDraftToggleCentralRepo(productId)
      : moveToList(productId);
  };
  const handlDraftToggleCentralRepo = (productId) => {
    draftToggleCentralRepo(productId).then((response) => {
      if (response?.status === 200) {
        if (response.data.status === "success") {
          message.success(response.data.message);
          setProductList(
            productList.filter((item) => item.source.productId != productId)
          );
        } else {
          message.error(
            "Product cannot be published at the moment as some important datas related to product are missing"
          );
        }
      }
    });
  };
  const moveToList = (id) => {
    moveProducts(id)
      .then((response) => {
        if (response.data.status == "success") {
          message.success(response.data.message);
          setProductList(
            productList.filter((item) => item.source.productId != id)
          );
        } else {
          message.error("Please add all product details before publish");
        }
      })
      .catch(() => {});
  };

  const handleDeleteProduct = (productId) => {
    isCentralRepo
      ? handleDeleteFromCentralRepo(productId)
      : deleteProduct(productId);
  };

  const handleDeleteFromCentralRepo = (productId) => {
    deleteProductFromCentralRepo(productId).then((response) => {
      if (response?.status === 200) {
        if (response?.data?.status === "success") {
          message.success("Product deleted successfully");
          setProductList(
            productList.filter((item) => item.source.productId != productId)
          );
        } else response?.data?.message && message.error(response.data.message);
      } else
        message.error("unable to delete the product please try again later");
    });
  };

  const deleteProduct = (productId) => {
    deleteSingleProduct(productId)
      .then((res) => {
        if (res.data.status == "success") {
          setProductList(
            productList.filter((item) => item.source.productId != productId)
          );
        }
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {});
  };
  const approveProduct = (productId, type, rejectMessage) => {
    const adminData = JSON.parse(localStorage.getItem("activeCustomer"));
    const payload = {
      productId: [productId],
      status: type === "approve" ? "APPROVED" : "REJECTED",
      approvedBy: adminData?.name,
      reason: rejectMessage,
    };
    approveSingleProduct(payload)
      .then((res) => {
        if (res.data.status == "success") {
          setProductList(
            productList.filter((item) => item.source.productId != productId)
          );
        }
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {});
  };

  const deactivateProduct = (productId, value) => {
    const payload = {
      active: value,
    };
    const payloadEdit = {
      id: productId,
      product: payload,
    };
    updateSellerProduct(payloadEdit)
      .then((res) => {
        setProductList(
          productList.map((item) =>
            item.source.productId === productId
              ? { ...item, active: !value }
              : item
          )
        );
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {});
  };

  return (
    <div>
      {isLoading ? (
        <LoadingCartItem />
      ) : (type === "listed") || (type === "rejected") ? (
        productList.map((data) => {
          return (
            <ManageProductCard
              product={data}
              key={data.source.productId}
              deactivate={deactivateProduct}
              isAdmin={props.isAdmin}
              productStatus={props.productStatus}
              approveProduct={approveProduct}
              deleteProduct={handleDeleteProduct}
              isCentralRepo={isCentralRepo}
              refetch={props.nextpage}
              type={type}
              publish={handleMoveFromDraft}
              isAutoApproval={props.isAutoApproval}
            />
          );
        })
      ) : (
        productList.map((data) => {
          return (
            <ManagaProductCardDraft
              isCentralRepo={isCentralRepo}
              product={data}
              key={data.source.productId}
              publish={handleMoveFromDraft}
              deleteProduct={handleDeleteProduct}
              isActive={isActive}
              setReload={setReload}
              isVendor={isVendor}
            />
          );
        })
      )}

      {
        props.total > 10 && (
          // <div className="pagination-conatiner">
          <Pagination
            defaultCurrent={1}
            current={props.currentPage}
            showSizeChanger={false}
            total={props.total}
            pageSize={isCentralRepo && cookies.get("accountType") == "admin"? 30 : 10}
            className="customer-pagination"
            onChange={(page) => {
              getProductsList(page);
              window.scrollTo(0, 0);
            }}
          />
        )
        // </div>
      }
    </div>
  );
}
