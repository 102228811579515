

import React, { Fragment, useState, useEffect } from "react";

import { Typography } from "antd";

import AddIcon from "../../../Assets/Images/Icons/add.svg";
import CategoryList from "./Components/categoryList";
import ManageCategoryModal from "./Components/manageCategoryModal";
import CategoryListContainer from "./Components/categoryListContainer";
import "./manageCategory.css";

import { getMegamenu } from "../../../ApiAction/CustomerApiActions/homepageAPI";
import { megamenuDataResponse } from "../../../DummyData/MegamenuData";
import * as ManageCategoryHelper from "./manageCategoryHelper";
import { CgMenuGridO } from "react-icons/cg";

const { Text, Title } = Typography;
const ManageCategory = (props) => {
  const [modalVisible, setmodalVisible] = useState(false);
  const [categoryList, setcategoryList] = useState([]);
  const [level, setlevel] = useState();
  const [parentCategoryId, setparentCategoryId] = useState([]);
  const [refetch, setrefetch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const categoryLevel = [
    {
      level: 1,
      titile: "Subcatogery Level 0",
    },
    {
      level: 2,
      titile: "Subcatogery Level 1",
    },
    {
      level: 3,
      titile: "Subcatogery Level 2",
    },
    {
      level: 4,
      titile: "Subcatogery Level 3",
    },
  ];

  const { isView } = props;

  const handleAddCategory = (level, parentCategoryId) => {
    setmodalVisible(true);
    setparentCategoryId(parentCategoryId);
    console.log("Level ==> " + level);
    console.log("Parent ==> " + parentCategoryId);
    setlevel(level);
  };

  // useEffect(() => {
  //   console.log("category response", megamenuDataResponse.documents);
  //   setcategoryList(megamenuDataResponse.documents);
  // }, []);

  useEffect(() => {
    setIsLoading(true);
    getMegamenu()
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.status === "success") {
            setcategoryList(response.data.documents);
          }
        }
      })
      .catch(() => {});
  }, [refetch]);
  return (
    <Fragment>
      <div className="category-management-container">
        <h1 className="content-name-header sub-title-tags" level={4}>
          <CgMenuGridO /> Categories
        </h1>
        <CategoryListContainer
          categoryList={categoryList}
          onClick={handleAddCategory}
          setlevel={setlevel}
          refetch={refetch}
          setrefetch={setrefetch}
          isLoading={isLoading}
          isView={isView}
        />
        {/* <div className="admin-manage-category-main">
          {categoryLevel.map((data) => {
            return (
              <div className="category-level-each">
                <div className="category-level-header">
                  <Text strong>headerI</Text>

                  <img
                    src={AddIcon}
                    alt="add-icon"
                    className="add-icon-cat"
                    onClick={() => handleAddCategory()}
                  />
                </div>

                <React.Fragment>
                  <CategoryList level={data.level} categories={categoryList} />
                </React.Fragment>
              </div>
            );
          })}
        </div> */}
        <ManageCategoryModal
          visible={modalVisible}
          setvisible={setmodalVisible}
          type={"Add Category"}
          parentCategoryId={parentCategoryId}
          level={level}
          refetch={refetch}
          setrefetch={setrefetch}
          categoryList={categoryList}
          isView={isView}
        />
      </div>
    </Fragment>
  );
};

export default ManageCategory;
