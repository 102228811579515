


import { REFTECH_ADDRESS_LIST } from "./types";

export const refetchAddressList = () => {
  return {
    type: REFTECH_ADDRESS_LIST,
    data: null,
  };
};
