


import React, { useState, useEffect, Fragment } from "react";
import {
  Typography,
  Modal,
  Row,
  Col,
  Collapse,
  Button,
  Divider,
  Form,
  Input,
  message,
} from "antd";
import { CaretRightOutlined, PlusOutlined } from "@ant-design/icons";
import "./manageConfigure.scss";
import AddConfigureItem from "./addConfigureItem";
import {
  addIdProof,
  getIdProof,
  updateIdProof,
  getReturnMessage,
  updateReturnMessage,
  addReturnMessage,
  getBatchTypeAndVariants,
  addBatchAndType,
  updateBatchAndType,
  updateCancelMessage,
  addCancelMessage,
  deleteBatchAndType,
  getCompanyCommission,
  getCancelMessage,
  postCompanyCommission,
} from "../../../ApiAction/AdminApiAction/adminApi";
import SellerTags from "../../Seller/ManageProducts/Components/sellerTags";
import BatchTags from "./batchTags";
import ConfigureDeliveryCharge from "./configureDeliveryCharge";
import ConfigureUnconfirmedOrder from "./configureUnconfirmedOrder";
import Loading from "../../GeneralComponents/Loading";
import ConfigureSignUpAmount from "./configureSignUpAmount";
import ConfigureCompanyDetails from "./configureCompanyDetails";
const { Title, Text } = Typography;
const { Panel } = Collapse;
const confirm = Modal.confirm;
const Configure = (props) => {
  const [form] = Form.useForm();
  const [addModal, setAddModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [idProofList, setIdProofList] = useState([]);
  const [productReturnList, setProductReturnList] = useState([]);
  const [cancelMessageList, setCancelMessageList] = useState([]);
  const [batchTypeList, setBatchTypeList] = useState([]);
  const [commisionList, setCommisionList] = useState();
  const [isEditProfit, setisEditProfit] = useState(false);
  const [isProfitLoading, setisProfitLoading] = useState(false);

  useEffect(() => {
    getIdprooflist();
    getReturnList();
    getBatchList();
    getCancelList();
    getCommission();
  }, []);
  const getIdprooflist = () => {
    getIdProof().then((res) => {
      if (res.data.status == "success") {
        setIdProofList(res.data.idProofConfiguration);
      }
    });
  };
  const getCommission = () => {
    getCompanyCommission().then((res) => {
      if (res.data.status == "success") {
        setCommisionList(res.data.companyCommissionConfiguration);
      }
    });
  };

  const getBatchList = () => {
    getBatchTypeAndVariants().then((res) => {
      if (res.data.status == "success") {
        setBatchTypeList(res.data.productBatchConfigurations);
      }
    });
  };
  const getReturnList = () => {
    getReturnMessage().then((res) => {
      if (res.data.status == "success") {
        setProductReturnList(res.data.returnReasons);
      }
    });
  };

  const getCancelList = () => {
    getCancelMessage().then((res) => {
      if (res.data.status == "success") {
        setCancelMessageList(res.data.cancelReasons);
      }
    });
  };
  const setSuccess = (payload, type, isEdited = false) => {
    setAddModal(false);

    if (type == "idProof") {
      if (isEdited) {
        const payloadField = {
          idProofField: idProofList.map((item) =>
            item == selectedId ? payload.idProofField : item
          ),
        };
        updateIdProof(payloadField).then((res) => {
          if (res.data.status == "success") {
            getIdprooflist();
          }
        });
      } else
        addIdProof(payload).then((res) => {
          if (res.data.status == "success") {
            getIdprooflist();
          }
        });
    } else if (type == "cancel") {
      const returnPayload = {
        cancelReasonField: payload.idProofField,
      };
      if (isEdited) {
        const payloadField = {
          cancelReasonField: cancelMessageList.map((item) =>
            item == selectedId ? payload.idProofField : item
          ),
        };
        updateCancelMessage(payloadField).then((res) => {
          if (res.data.status == "success") {
            getCancelList();
          }
        });
      } else
        addCancelMessage(returnPayload).then((res) => {
          if (res.data.status == "success") {
            getCancelList();
          }
        });
    } else if (type == "return") {
      const returnPayload = {
        returnReasonField: payload.idProofField,
      };
      if (isEdited) {
        const payloadField = {
          returnReasonField: productReturnList.map((item) =>
            item == selectedId ? payload.idProofField : item
          ),
        };
        updateReturnMessage(payloadField).then((res) => {
          if (res.data.status == "success") {
            getReturnList();
          }
        });
      } else
        addReturnMessage(returnPayload).then((res) => {
          if (res.data.status == "success") {
            getReturnList();
          }
        });
    } else if (type == "batch") {
      const payloadBatch = {
        productBatchConfigurationField: {
          type: payload.idProofField,
          variants: [],
        },
      };
      addBatchAndType(payloadBatch).then((res) => {
        if (res.data.status == "success") {
          getBatchList();
        }
      });
    }
  };

  const setEdit = (item, type) => {
    setSelectedId(item);
    setSelectedType(type);
    setIsEdit(true);
    setAddModal(true);
  };

  const setTagValues = (item, type) => {
    const payloadBatch = {
      productBatchConfigurationField: {
        type: type,
        variants: item,
      },
    };
    updateBatchAndType(payloadBatch).then((res) => {
      if (res.data.status == "success") {
        getBatchList();
      }
    });
  };
  const setDelete = (selectedItem, type) => {
    confirm({
      title: "Do you want to delete this!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        if (type == "idProof") {
          const payload = {
            idProofField: idProofList.filter((item) => item != selectedItem),
          };
          updateIdProof(payload).then((res) => {
            if (res.data.status == "success") {
              getIdprooflist();
            }
          });
        } else if (type == "return") {
          const payload = {
            returnReasonField: productReturnList.filter(
              (item) => item != selectedItem
            ),
          };
          updateReturnMessage(payload).then((res) => {
            if (res.data.status == "success") {
              getReturnList();
            }
          });
        } else if (type == "cancel") {
          const payload = {
            cancelReasonField: cancelMessageList.filter(
              (item) => item != selectedItem
            ),
          };
          updateCancelMessage(payload).then((res) => {
            if (res.data.status == "success") {
              getCancelList();
            }
          });
        } else if (type == "batch") {
          const payload = {
            productBatchConfigurationField: selectedItem,
          };
          deleteBatchAndType(payload).then((res) => {
            if (res.data.status == "success") {
              getBatchList();
            }
          });
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const formFields = [
    // { name: "salesIncentive", placeHolder: "Sales Incentive", value: 0 },
    { name: "storeProfit", placeHolder: "Store Profit", value: 0 },
    { name: "warehouseProfit", placeHolder: "Warehouse Profit", value: 0 },
    { name: "digitalPool", placeHolder: "Digital Pool", value: 0 },
    { name: "companyProfit", placeHolder: "Company Profit", value: 0 },
  ];
  const handleCancelProfitUpdation = () => {
    form.setFieldsValue(commisionList);
    setisEditProfit(false);
  };

  const onFinish = (values) => {
    const totalProfit =
      // Number(values.salesIncentive) +
      Number(values.storeProfit) +
      Number(values.warehouseProfit) +
      Number(values.digitalPool) +
      Number(values.companyProfit);
    if (totalProfit === 100 || totalProfit === 0) {
      const payload = {
        // salesIncentive: Number(values.salesIncentive),
        storeProfit: Number(values.storeProfit),
        warehouseProfit: Number(values.warehouseProfit),
        digitalPool: Number(values.digitalPool),
        companyProfit: Number(values.companyProfit),
      };
      setisProfitLoading(true);
      postCompanyCommission(payload)
        .then((res) => {
          if (res.data.status == "success") {
            setisEditProfit(false);
          }
        })
        .catch(() => { })
        .finally(() => {
          setisProfitLoading(false);
        });
    } else {
      message.error("Please do a proper splitting");
    }
  };
  const ProfitValidator = (rule, value, callback) => {
    if (value !== undefined && Number(value) > 100) {
      callback("Plese enter valid profit percentage");
    } else {
      callback();
    }
  };
  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        {" "}
        <h1 level={4} className="sub-title-tags">
          {" "}
          Configure
        </h1>
      </div>
      <Collapse
        bordered={false}
        defaultActiveKey={["1"]}
        accordion
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        className="stock-collapse config-collapse"
      >
        <Panel
          key={"1"}
          header={<div className="configure-title">ID Proofs</div>}
          className="site-collapse-custom-panel"
        >
          <div>
            <Divider />
            {idProofList.map((item) => (
              <div
                className="justify-space-between"
                style={{ borderBottom: "1px solid #a3a0a0", padding: "10px" }}
              >
                <div>{item} </div>
                <div className="flex">
                  <div
                    className="edit-button"
                    onClick={() => setEdit(item, "idProof")}
                  >
                    {" "}
                    Edit
                  </div>
                  <div
                    className="delete-button"
                    onClick={() => setDelete(item, "idProof")}
                  >
                    {" "}
                    Remove
                  </div>
                </div>
              </div>
            ))}
            <div className="seller-action-button view-invoice-layout">
              <PlusOutlined
                className="add-idproof"
                onClick={() => {
                  setIsEdit(false);
                  setSelectedType("idProof");
                  setSelectedId("");
                  setAddModal(true);
                }}
              />
            </div>
          </div>
        </Panel>

        <Panel
          key={"2"}
          header={<div className="configure-title">Product Return Message</div>}
          className="site-collapse-custom-panel"
        >
          <div>
            <Divider />
            {productReturnList.map((item) => (
              <div
                className="justify-space-between"
                style={{ borderBottom: "1px solid #a3a0a0", padding: "10px" }}
              >
                <div>{item} </div>
                <div className="flex">
                  <div
                    className="edit-button"
                    onClick={() => setEdit(item, "return")}
                  >
                    {" "}
                    Edit
                  </div>
                  <div
                    className="delete-button"
                    onClick={() => setDelete(item, "return")}
                  >
                    {" "}
                    Remove
                  </div>
                </div>
              </div>
            ))}
            <div className="seller-action-button view-invoice-layout">
              <PlusOutlined
                className="add-idproof"
                onClick={() => {
                  setIsEdit(false);
                  setSelectedType("return");
                  setSelectedId("");
                  setAddModal(true);
                }}
              />
            </div>
          </div>
        </Panel>

        <Panel
          key={"3"}
          header={
            <div className="configure-title">Batch Type And Variants</div>
          }
          className="site-collapse-custom-panel"
        >
          <div>
            <Divider />

            {batchTypeList.map((item) => (
              <div className="batch-and-type">
                <div
                  className="justify-space-between"
                  style={{ borderBottom: "1px solid #a3a0a0", padding: "10px" }}
                >
                  <div>{item.type} </div>
                  <div className="flex">
                    {/* <div className="edit-button"
                                        onClick={() => setEdit(item, "batch")}> Edit</div> */}
                    <div
                      className="delete-button"
                      onClick={() => setDelete(item.type, "batch")}
                    >
                      {" "}
                      Remove
                    </div>
                  </div>
                </div>
                <BatchTags
                  batch={item.type}
                  setTagValues={setTagValues}
                  tagValues={item.variants}
                />
              </div>
            ))}
            <div className="seller-action-button view-invoice-layout">
              <PlusOutlined
                className="add-idproof"
                onClick={() => {
                  setIsEdit(false);
                  setSelectedType("batch");
                  setSelectedId("");
                  setAddModal(true);
                }}
              />
            </div>
          </div>
        </Panel>

        <Panel
          key={"4"}
          header={<div className="configure-title">Product Cancel Message</div>}
          className="site-collapse-custom-panel"
        >
          <div>
            <Divider />
            {cancelMessageList.map((item) => (
              <div
                className="justify-space-between"
                style={{ borderBottom: "1px solid #a3a0a0", padding: "10px" }}
              >
                <div>{item} </div>
                <div className="flex">
                  <div
                    className="edit-button"
                    onClick={() => setEdit(item, "cancel")}
                  >
                    {" "}
                    Edit
                  </div>
                  <div
                    className="delete-button"
                    onClick={() => setDelete(item, "cancel")}
                  >
                    {" "}
                    Remove
                  </div>
                </div>
              </div>
            ))}
            <div className="seller-action-button view-invoice-layout">
              <PlusOutlined
                className="add-idproof"
                onClick={() => {
                  setIsEdit(false);
                  setSelectedType("cancel");
                  setSelectedId("");
                  setAddModal(true);
                }}
              />
            </div>
          </div>
        </Panel>

        <Panel
          key={"5"}
          header={
            <div className="configure-title">Sales Profit in Percentage</div>
          }
          className="site-collapse-custom-panel"
        >
          <div>
            <Divider />
            <Form
              form={form}
              name="normal_login"
              initialValues={commisionList}
              onFinish={onFinish}
            >
              <Row>
                {formFields.map((data) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={7}
                      xl={8}
                      className="add-image-col"
                    >
                      <Text>
                        {data.placeHolder}{" "}
                        <span className="required-field-asterisk">*</span>
                      </Text>
                      <Form.Item
                        name={data.name}
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          { validator: ProfitValidator },
                        ]}
                      >
                        <Input
                          disabled={!isEditProfit}
                          type="number"
                          placeholder={data.placeHolder}
                          defaultValue="0"
                          min="0"
                        />
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
              <div className="config_action_main">
                {isEditProfit ? (
                  <Fragment>
                    <Button
                      onClick={() => handleCancelProfitUpdation()}
                      className="config_action_btn"
                    >
                      Cancel
                    </Button>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="config_action_btn"
                        style={{ marginLeft: "20px" }}
                      >
                        {isProfitLoading ? (
                          <Loading style={{ color: "white" }} />
                        ) : (
                            " Submit"
                          )}
                      </Button>
                    </Form.Item>
                  </Fragment>
                ) : (
                    <Button
                      type="primary"
                      onClick={() => setisEditProfit(true)}
                      className="config_action_btn"
                    >
                      Edit
                    </Button>
                  )}
              </div>
              {/* <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
                <br />
              </Form.Item> */}
            </Form>
          </div>
        </Panel>

        <Panel
          key={"6"}
          header={
            <div className="configure-title">Configure Delivery Charge</div>
          }
          className="site-collapse-custom-panel"
        >
          <ConfigureDeliveryCharge isAdmin />
        </Panel>

        <Panel
          key={"7"}
          header={
            <div className="configure-title">
              Configure Unconfirmed Order Cancel Threshold
            </div>
          }
          className="site-collapse-custom-panel"
        >
          <ConfigureUnconfirmedOrder />
        </Panel>

        <Panel
          key={"8"}
          header={
            <div className="configure-title">
              Signup Payment Amount
            </div>
          }
          className="site-collapse-custom-panel"
        >
          <ConfigureSignUpAmount />
        </Panel>

        <Panel
          key={"9"}
          header={
            <div className="configure-title">
              Company Details
            </div>
          }
          className="site-collapse-custom-panel"
        >
          <ConfigureCompanyDetails />
        </Panel>
      </Collapse>
      <AddConfigureItem
        visible={addModal}
        setCancel={setAddModal}
        isEdit={isEdit}
        type={selectedType}
        selectedId={selectedId}
        setSuccess={setSuccess}
      />
    </div>
  );
};
export default Configure;
