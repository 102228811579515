import React, { useState, useEffect } from 'react'
import { Table, Button, Divider, Result, notification} from 'antd';
import { FrownTwoTone } from '@ant-design/icons'
import {requestPaymentHeader} from "./tableHeader"
import NumberFormat from 'react-number-format';
import moment from 'moment'
import {SendOutlined } from '@ant-design/icons'
import { RequestConstants } from '../../../Utils/constants';
import { requestPayment } from '../../../ApiAction/SellerApiActions/sellerApi'
import { getRequestPayment } from '../../../ApiAction/SellerApiActions/sellerApi';
import Cookies from 'universal-cookie';

// This component is to show Request Payment, Venter can select different transaction and request
// for payment
export default function RequestPayment(props) {

    const cookies = new Cookies();

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedGroupIds, setSelectedGroupIds] = useState([])
    const [totalAmount, setTotalAmount] = useState(0);
    const [selectedRowKeys, setSelectedRowKey] = useState([])
    const hasSelected = selectedRowKeys.length > 0;
    const [dataSubmission, setDataSubmission] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [reloadTable, setReloadTable] = useState(false)
    const [walletAmount, setWalletAmount] = useState(0)
    const [accountType] = useState(cookies.get("accountType"))
    

    // function that gets called when we select row of table
    const onSelectChange = selectedRowKeys => {
        setSelectedRowKey(selectedRowKeys)
        calculateTotal(selectedRowKeys)
    };

    // This is antd's function when capture all the selected rows and add to SelectedRowKeys Array
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }

    const tempRowData = []
    const [rowData, setRowData] = useState([])

    useEffect(() => {
       const fetchDocuments = async () => {
           setTableLoading(true)
           const payload = {
               size: 10000,
               offset: 0,
               sortType: "desc",
               sortBy: "orderedDate",
               vendorId: props.vendorId
           }
           await getRequestPayment(payload).then((response) => {

               if (response.data) {
                   if (response.data.status === "failed") {
                    //    notification.open({
                    //        message: 'Network Error',
                    //        description:
                    //            'Something went wrong, Please try again',
                    //        icon: <DisconnectOutlined style={{ color: '#108ee9' }} />,
                    //    });
                       setTableLoading(false)
                       setData([])
                       mapData([])
                       setWalletAmount(0)
                       return;
                   }
                   setData(response.data.documents)
                   setWalletAmount(response.data.walletAmount)
                   mapData(response.data.documents)
                   console.log(data)
               } else {
                   setData([])
               }
               setTableLoading(false)
           })
       }

       fetchDocuments();
    }, [reloadTable])

    const mapData = (response) => {
            response.map((doc, index) => {
                tempRowData.push({
                    key: index,
                    id: doc.id,
                    uuid: doc.source.id,
                    version: doc.version,
                    orderGroupId: doc.source.orderGroupId,
                    orderedDate: moment(parseInt(doc.source.orderedDate)).format("DD • MM • YYYY (hh:mm A)"),
                    amountToConsider: doc.source.amountToConsider.toFixed(2),
                    invoiceAmountSplits: doc.source.invoiceAmountSplits
                })
            })
            setRowData(tempRowData)
        }

    // function that calculate all the selected transactions
    const calculateTotal = (rows) => {
        setTotalAmount(0)
        let tempSelectedIds = []
        let total = 0;
        let tempDataObj = {}
        rowData.map((payment,index) => {
            rows.map((eachPay,i) =>{
                if(eachPay === index) {
                    total += parseFloat(payment.amountToConsider)
                    setTotalAmount(total)
                    tempDataObj = {
                        id: payment.id,
                        version: payment.version,
                        orderGroupId: payment.orderGroupId,
                        amountToConsider: parseFloat(payment.amountToConsider),
                        invoiceAmountSplits: payment.invoiceAmountSplits,
                    }
                    tempSelectedIds.push(tempDataObj)
                    setSelectedGroupIds(tempSelectedIds)
                }
            })
        })
    }

    // function to submit Request Payment after selecting the transactions
    const requestHandler = () => {
        setLoading(true)
        console.log(typeof(totalAmount))
        const payload = {
            requestPayment: selectedGroupIds,
            requestedAmount: totalAmount,
            vendorId: props.vendorId,
            requestStatus: RequestConstants.REQUESTED,
            requestedDate: new Date().getTime(),
            accountType: accountType
        }
        console.log(payload)
        requestPayment(payload).then((response) => {
            console.log(response)
            if(response.data.status === 'success') {
                setDataSubmission(true)
            }else if(response.data.error === 'BankNotFound') {
                notification.open({
                    message: 'Bank Details Missing',
                    description:
                        'Please Update your Bank Details at My Profile',
                    icon: <FrownTwoTone style={{ color: '#108ee9' }} />,
                });
                setDataSubmission(false)
            }
            setLoading(false)
            setSelectedRowKey([])
            setSelectedGroupIds([])
            setTotalAmount(0)
            window.scrollTo(0, 200)
        })
    }

    return (
        <div className="request-table-view">
            <Table rowSelection={rowSelection} columns={requestPaymentHeader} dataSource={rowData} loading={tableLoading} />
            <Divider />
            <div className="totalBox">
                <div className="request-payment-details">
                    <h3 style={{ padding: '0em', color: 'grey', whiteSpace: "nowrap" }}>Requested Amount  </h3><span style={{ fontWeight: "bold", color: "#10884D", whiteSpace: "nowrap", textAlign: 'right' }}><NumberFormat value={totalAmount.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹ '} /></span>
                    <h3 style={{ padding: '0em', color: 'grey', whiteSpace: "nowrap" }}>Wallet Amount  </h3><span style={{whiteSpace: "nowrap", textAlign: 'right'}}><NumberFormat value={parseFloat(walletAmount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹ '} /></span>
                </div>
            </div>
            <Divider />
            <Button size="middle" type="primary" disabled={!hasSelected} onClick={requestHandler} loading={loading} style={{ float: "right", marginBottom: "1em" }}>
                <SendOutlined /> Request {selectedRowKeys.length > 0 && selectedRowKeys.length} Payment{selectedRowKeys.length > 1 && "s"}
            </Button>
            {dataSubmission && (<>
                {/* if request payment is success then this message will be shown */}
                <div className="data-submission-success-message-box">
                <Result
                    status="success"
                    title="Request for Payment Successfully Placed"
                    subTitle="It will take around 2-4 working days to Completed the transaction, Please wait"
                    extra={[
                        <Button type="primary" key="console" onClick={() => { setReloadTable(!reloadTable); setDataSubmission(false)}}>
                            Thank You
                        </Button>
                    ]} />
                </div>
            </>)}
        </div>
    )
}
