


import React, { useState, useEffect, Fragment } from "react";
import { Layout, Dropdown, Button, Menu, Typography } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { fetchSort, fetchSortBy } from "../../../ReduxStore/action";
import { connect } from "react-redux";
const { Text } = Typography;
const SortBy = (props) => {
  const { isCustomer, activePath, setrelevance } = props;
  const [sortValue, setSortValue] = useState("Relevance");
  const [sortType, setsortType] = useState("");

  const [salesReport, setsalesReport] = useState(false);
  const [stocksReport, setstocksReport] = useState(false);
  const [searchReport, setsearchReport] = useState(false);
  useEffect(() => {
    setsalesReport(
      window.location.href.split("/").pop().includes("sales-report")
    );

    setstocksReport(
      window.location.href.split("/").pop().includes("stocks-report")
    );

    setsearchReport(
      window.location.href.split("/").pop().includes("search-report")
    );
  }, [window.location.href, activePath]);

  useEffect(() => {
    if (salesReport) {
      setsortType("Sales");
      setSortValue("(High to Low)");
    } else if (stocksReport) {
      setsortType("Batchwise");
      setSortValue("(Newest First)");
    } else if (searchReport) {
      setSortValue("(High to Low)");
      setsortType("Search Count");
    } else if (
      window.location.href.split("/").pop().includes("my-orders") ||
      window.location.href.split("/").pop().includes("manage-returns") ||
      window.location.href.split("/").pop().includes("manage-orders") ||
      window.location.href.split("/").pop().includes("my-returns")
    ) {
      setSortValue("(Newest First)");
      setsortType("Date");
    } else {
      setSortValue("Relevance");
      setsortType("");
    }
  }, [salesReport, stocksReport, searchReport, activePath]);
  const renderSortStatus = () => {
    return (
      <Menu>
        {/* //sort applicable for stocks reports starts here// */}

        {searchReport && (
          <Menu.Item
            onClick={() => {
              const payload = {
                sortType: "searchCount",
                sortBy: "desc",
                block: props.sort.block,
                archive: props.sort.archive,
              };
              props.fetchSort(payload);
              props.fetchSortBy("desc");
            }}
          >
            Search Count (High to Low)
            {sortValue === "(High to Low)" && sortType === "Search Count" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}

        {searchReport && (
          <Menu.Item
            onClick={() => {
              setSortValue("(Low to High)");
              setsortType("Search Count");
              const payload = {
                sortType: "searchCount",
                sortBy: "asc",
                block: props.sort.block,
                archive: props.sort.archive,
              };
              props.fetchSort(payload);
              props.fetchSortBy("desc");
            }}
          >
            Search Count (Low to High)
            {sortValue === "(Low to High)" && sortType === "Search Count" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}
        {stocksReport && (
          <Menu.Item
            onClick={() => {
              setSortValue("(Newest First)");
              setsortType("Batchwise");
              const payload = {
                sortType: "addedDate",
                sortBy: "desc",
                block: props.sort.block,
                archive: props.sort.archive,
              };
              props.fetchSort(payload);
              props.fetchSortBy("desc");
            }}
          >
            Batchwise (Newest First)
            {sortValue === "(Newest First)" && sortType === "Batchwise" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}

        {stocksReport && (
          <Menu.Item
            onClick={() => {
              setSortValue("(Oldest First)");
              setsortType("Batchwise");
              const payload = {
                sortType: "addedDate",
                sortBy: "asc",
                block: props.sort.block,
                archive: props.sort.archive,
              };
              props.fetchSort(payload);
              props.fetchSortBy("asc");
            }}
          >
            Batchwise (Oldest First)
            {sortValue === "(Oldest First)" && sortType === "Batchwise" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}
        {/* // sort applicable for sales report starts here// */}
        {salesReport && (
          <Menu.Item
            onClick={() => {
              setSortValue("(High to Low)");
              setsortType("Sales");
              const payload = {
                sortType: "sales",
                sortBy: "desc",
                block: props.sort.block,
                archive: props.sort.archive,
              };
              props.fetchSort(payload);
              props.fetchSortBy("desc");
            }}
          >
            Sales (High to Low)
            {sortValue === "(High to Low)" && sortType === "Sales" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}

        {salesReport && (
          <Menu.Item
            onClick={() => {
              setSortValue("(Low to High)");
              setsortType("Sales");
              const payload = {
                sortType: "sales",
                sortBy: "asc",
                block: props.sort.block,
                archive: props.sort.archive,
              };
              props.fetchSort(payload);
              props.fetchSortBy("asc");
            }}
          >
            SortBy Sales (Low to high)
            {sortValue === "(Low to High)" && sortType === "Sales" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}
        {/* // sort applicable for sales report ends here// */}

        {(window.location.href.includes("manage-products") ||
          window.location.href.includes("manage-stocks")) && (
          <Menu.Item
            onClick={() => {
              setSortValue("Relevance");
              setsortType("");
              setrelevance && setrelevance(true);
              const payload = {
                sortType: "",
                sortBy: "",
                block: props.sort.block,
                archive: props.sort.archive,
                status: props.sort.status,
                filterType: props.sort.filterType,
              };
              props.fetchSort(payload);
              props.fetchSortBy("");

              // this.handleSelectChange("open", "projectStatus");
              // this.props.searchFilterStore.projectStatusFilterChange("open");
            }}
          >
            Relevance
            {sortValue === "Relevance" && sortType === "" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}

        {
          // !window.location.href.includes("manage-products") &&
          !window.location.href.includes("report") &&
            !window.location.href.includes("my-orders") &&
            !window.location.href.includes("manage-orders") &&
            !window.location.href.includes("verified-products") &&
            !window.location.href.includes("central-repo") && (
              <Menu.Item
                onClick={() => {
                  setSortValue("(Ascending)");
                  setsortType("Name");
                  const payload = {
                    sortType: "name",
                    sortBy: "asc",
                    block: props.sort.block,
                    archive: props.sort.archive,
                  };
                  props.fetchSort(payload);
                  props.fetchSortBy("asc");
                  setrelevance && setrelevance(false);
                  // this.handleSelectChange("open", "projectStatus");
                  // this.props.searchFilterStore.projectStatusFilterChange("open");
                }}
              >
                Name (Ascending)
                {sortValue === "(Ascending)" && sortType === "Name" && (
                  <CheckCircleTwoTone
                    twoToneColor="#0074be"
                    style={{ fontSize: "16px", float: "right" }}
                  />
                )}
              </Menu.Item>
            )
        }
        {
          // !window.location.href.includes("manage-products") &&
          !window.location.href.includes("report") &&
            !window.location.href.includes("my-orders") &&
            !window.location.href.includes("manage-orders") &&
            !window.location.href.includes("verified-products") &&
            !window.location.href.includes("central-repo") && (
              <Menu.Item
                onClick={() => {
                  setSortValue("(Decending)");
                  setsortType("Name");
                  const payload = {
                    sortType: "name",
                    sortBy: "desc",
                    block: props.sort.block,
                    archive: props.sort.archive,
                  };
                  props.fetchSort(payload);
                  props.fetchSortBy("desc");
                  setrelevance && setrelevance(false);
                }}
              >
                Name (Decending)
                {sortValue === "(Decending)" && sortType === "Name" && (
                  <CheckCircleTwoTone
                    twoToneColor="#0074be"
                    style={{ fontSize: "16px", float: "right" }}
                  />
                )}
              </Menu.Item>
            )
        }
        {!salesReport && !stocksReport && !searchReport && (
          <Menu.Item
            onClick={() => {
              setSortValue("(Newest First)");
              setsortType("Date");
              const payload = {
                sortType: "date",
                sortBy: "desc",
                block: props.sort.block,
                archive: props.sort.archive,
                status: props.sort.status,
                filterType: props.sort.filterType,
              };
              props.fetchSort(payload);
              props.fetchSortBy("asc");
              setrelevance && setrelevance(false);
              // this.handleSelectChange("open", "projectStatus");
              // this.props.searchFilterStore.projectStatusFilterChange("open");
            }}
          >
            Date (Newest First)
            {sortValue === "(Newest First)" && sortType === "Date" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}
        {!salesReport && !stocksReport && !searchReport && (
          <Menu.Item
            onClick={() => {
              setSortValue("(Oldest First)");
              setsortType("Date");
              const payload = {
                sortType: "date",
                sortBy: "asc",
                block: props.sort.block,
                archive: props.sort.archive,
                status: props.sort.status,
                filterType: props.sort.filterType,
              };
              props.fetchSort(payload);
              props.fetchSortBy("asc");
              setrelevance && setrelevance(false);
            }}
          >
            Date (Oldest First)
            {sortValue === "(Oldest First)" && sortType === "Date" && (
              <CheckCircleTwoTone
                twoToneColor="#0074be"
                style={{ fontSize: "16px", float: "right" }}
              />
            )}
          </Menu.Item>
        )}
      </Menu>
    );
  };
  return (
    <Dropdown
      overlay={() => renderSortStatus()}
      placement="bottomCenter"
      trigger={["click"]}
      className="sort-dropdown-admin"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Button style={{ height: "40px" }}>
        <span style={{ color: "#827e7e" }}>Sort By: </span>
        <span>
          &nbsp;
          <Text strong> {" " + sortType}</Text>
          <Text strong style={{ color: "red" }}>
            {" "}
            {sortValue}
          </Text>
        </span>
        <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};
const mapDispatchToProps = {
  fetchSort,
  fetchSortBy,
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SortBy);
