


import React, { useEffect, useState, Fragment } from "react";
import { Menu } from "antd";

import { NavLink } from "react-router-dom";
import Icon from "@ant-design/icons";
import "antd/dist/antd.css";
import "./admin.scss";
import { designerSideBar, designerUserIds, sideBarDataAdmin } from "../../Utils/Seller/Data";
import { ReactComponent as CartIcon } from "../../Assets/Images/Icons/open-menu.svg";
import { fetchSort } from "../../ReduxStore/action";
import { connect } from "react-redux";

import ManageReturnsIcon from "../../Assets/Images/Icons/Seller/returns.svg";
import ManageReturnsIconActive from "../../Assets/Images/Icons/Seller/returnsActive.svg";
const { SubMenu } = Menu;
const AdminMenu = (props) => {
  const { activeTab, setSearchval } = props;
  const [urlPath, setUrlPath] = useState("");
  const [selectedMenu, setActiveMenu] = useState("my-profile");

  const isDesigner = designerUserIds.includes(
    JSON.parse(localStorage.getItem("activeCustomer"))?.userId
  );

  const menuItemClick = (data) => {
    setActiveMenu(data.key);
    setSearchval && setSearchval("");
    if (props.isNav) {
      props.setNavVisible(false);
    }
  };

  useEffect(() => {
    setUrlPath(props.pathname);
  }, [props]);

  return (
    <Menu
      className="side-nav-menu"
      // theme="dark"
      mode="inline"
      defaultSelectedKeys={[activeTab]}
      selectedKeys={[activeTab]} //activeTab refers to the current active tab
      onClick={menuItemClick}
      style={{borderRadius: "1em"}}
    >
      {(isDesigner? designerSideBar : sideBarDataAdmin).map((data) => {
        return (
          <Menu.Item
            key={data.key}
          // disabled={data.key !== "manage-products" ? true : false}
          >
            <NavLink to={data.url}>
              <span>
                {" "}
                <img
                  src={data.key === activeTab ? data.activeIcon : data.icon}
                  style={{ width: "20px", marginRight: "10px" }} alt={data.label}
                />
              </span>
              <span className="side-menu-link-content">
                <span>{data.label}</span>
                {data.newModule && (<span className="new-label">{data.newModule}</span>)}
              </span>
            </NavLink>
          </Menu.Item>
        );
      })}

      {!isDesigner && (
        <SubMenu
          key="sales-report"
          title={
            <Fragment>
              <span>
                <img alt="Reports"
                  src={
                    "sales-report" === activeTab
                      ? ManageReturnsIconActive
                      : ManageReturnsIcon
                  }
                  style={{ width: "20px", marginRight: "10px" }}
                />
              </span>
              <span className="side-menu-link-content" style={{fontWeight:"400",fontSize:"15px",lineHeight:"normal"}}>
                <span>Reports</span>
              </span>
            </Fragment>
          }
        >
          <Menu.Item key="sales-report">
            <NavLink to={"/admin/sales-report"}>
              <span className="side-menu-link-content">Sales Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="stocks-report">
            <NavLink to={"/admin/stocks-report"}>
              <span className="side-menu-link-content">Stocks Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="auto-approval-report">
          <NavLink to={"/admin/auto-approval-report"}>
            <span className="side-menu-link-content">Auto Approval Report</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="affiliate-charge-report">
          <NavLink to={"/admin/affiliate-charge-report"}>
            <span className="side-menu-link-content">Affiliate Charge Report</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="search-report">
            <NavLink to={"/admin/search-report"}>
              <span className="side-menu-link-content">Search Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="expiry-report">
            <NavLink to={"/admin/expiry-report"}>
              <span className="side-menu-link-content">Expiry Report</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="day-book-report">
            <NavLink to={"/admin/day-book-report"}>
              <span className="side-menu-link-content">Day Book Report</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      )}
    </Menu>
  );
};
const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminMenu);
