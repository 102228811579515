


import { FETCH_CART_ITEMS } from "../action/types";

const cartItemsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_CART_ITEMS:
      // console.log("reducer: ", action.data);
      state = action.data;

    default:
      // console.log("invalid choice");
  }
  return state;
};

export default cartItemsReducer;
