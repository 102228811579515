


import axios from "axios";
import { API_SERV_ADDR } from "../../Utils/constants";
import Axios from "../axios"
//store - warehouse
export const linkWarehouseToStore = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/store/link-warehouse`,
    method: "POST",
    data: payload,
  });
};

export const removeLiinkWarehouseToStore = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/store/remove-linked-warehouse`,
    method: "POST",
    data: payload,
  });
};

//warehouse - warehouse

export const linkWarehouseToWarehouse = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/warehouse/link-warehouse`,
    method: "POST",
    data: payload,
  });
};

export const getLinkedWarehouses = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/warehouse/get-linked-warehouses`,
    method: "POST",
    data: payload,
  });
};

export const getWarehouseLinkedWarehouses = (id) => {
  return Axios({
    url: `${API_SERV_ADDR}/warehouse/${id}/get-linked-warehouses`,
    method: "GET",
    
  });
};

export const getWarehouseLinkedStores = (id,payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/warehouse/${id}/get-linked-stores`,
    method: "POST",
    data: payload
    
  });
};

export const getLinkedStores = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/warehouse/get-linked-stores`,
    method: "POST",
    data: payload,
  });
};

export const removeLiinkWarehouseToWarehouse = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/warehouse/remove-linked-warehouse`,
    method: "POST",
    data: payload,
  });
};
