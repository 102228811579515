


import React, { useState, useEffect, Fragment } from "react";

import { connect } from "react-redux";

import { Tabs, Skeleton } from "antd";
import "./productDetail.scss";
import { FILE_PATH } from "../../../../Utils/constants";

import BreadCrumb from "../ProductListing/breadCrumb";
import ProductSliderGallery from "./Components/imagePreviewOptions";
import ProductDetailContent from "./Components/productDetailContent";

import Description from "./Components/description";
import Specifications from "./Components/specifications";
import MetaDecorator from "../../../../Utils/MetaDecorator";
import { history } from "../../../../App";

import OneLineCardstSection from "../../../../Views/HomePage/Components/OneLineCardstSection";

import { productData } from "../../../../DummyData/productData";
import * as CustomerProduct from "../../../../ApiAction/CustomerApiActions/productApi";
import * as ProductDetailHelper from "./productDetailHelper";

import { formatProductData } from "../ProductListing/productListingHelper";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const { TabPane } = Tabs;

const ProductDetailPage = (props) => {
  const [activeImage, setactiveImage] = useState(0);
  // const [quantity, setquantity] = useState();
  const [mobileView, setmobileView] = useState();

  const [breadcrumbData, setbreadcrumbData] = useState();
  const [productData1, setproductData] = useState();
  const [productImages, setproductImages] = useState();
  const [relatedProducts, setrelatedProducts] = useState([]);
  const [recentlyViewedProducts, setrecentlyViewedProducts] = useState([]);
  const [frequentlyBoughtTogetherProducts, setfrequentlyBoughtTogether] = useState([]);
  const [otherVendorProducts, setOtherVendorProducts] = useState([]);
  const accountType = cookies.get("accountType")
    ? cookies.get("accountType")
    : "customer";

  const images = [
    "https://www.a.net/operations/assets/adminarea/images/products/300X300/18246886891580276535.jpg",
    "https://www.a.net/operations/assets/adminarea/images/products/300X300/10405071881579848941.jpg",
    "https://www.a.net/operations/assets/adminarea/images/products/300X300/10405071881579848941.jpg",
  ];

  useEffect(() => {
    navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)
      ? setmobileView(true)
      : setmobileView(false);
  }, []);

  // to scroll to top when the page loads
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    CustomerProduct.getProductDetails(
      props.match.params.productId,
      props.pinCode
    )
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === "Not found") {
            history.push("/404")
            return
          }
          if (response.data.status === "success") {
            setproductData(
              ProductDetailHelper.formatProductDetailData(response.data.product)
            );
            setbreadcrumbData(response.data.breadCrumb);
            setproductImages(
              ProductDetailHelper.getFormatedImageForPreview(
                response.data.product
              )
            );

            setrelatedProducts(
              formatProductData(response.data.relatedProducts)
            );
            setrecentlyViewedProducts(
              formatProductData(response.data.recentlyViewedProducts)
            );
            setfrequentlyBoughtTogether(
              formatProductData(response.data.frequentlyBoughtTogether)
            );
            setOtherVendorProducts(
              formatProductData(response.data.otherVendorProducts)
            );
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  }, [props.match.params.productId]);

  return (
    <div className="prodduct-detail-page-conatiner">
      <MetaDecorator
        title={
          productData1 &&
          productData1.productName &&
          `Buy ${productData1?.productName} Online At Best Price - Lorem Ipsum`
        }
        description={`Buy ${productData1?.productName} online at lowest price from Lorem Ipsum and get them delivered at your doorstep.`}
        image={FILE_PATH + productData1?.productImages[0]}
      />

      <div class="container">
        <div className="product_desc_section">
          {accountType === "customer" && (
            <BreadCrumb breadCrumbData={breadcrumbData} />
          )}

          {productData1 && productImages ? (
            <>
              {/* {mobileView && (
                <MobileImagePreview images={productData1.productImages} />
              )} */}

              <Fragment>
                <div className="product-detail-conatiner product_detail_data_column">
                  <ProductSliderGallery productImages={productImages} />
                  <ProductDetailContent productData={productData1} />
                </div>

                <Tabs
                  className="hr_pdt_tabs_data"
                  defaultActiveKey="Description"
                  // onChange={(key) => console.log(key)}
                  size={"large"}
                >
                  <TabPane tab="Description" key="Description">
                    <Description description={productData1?.description} />
                  </TabPane>
                  <TabPane tab="Specifications" key="Specifications">
                    <Specifications specs={productData1?.specifications} />
                  </TabPane>
                </Tabs>
              </Fragment>
            </>
          ) : (
            <Fragment>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </Fragment>
          )}
          {accountType === "customer" && (
            <Fragment>
              {recentlyViewedProducts?.length > 0 && (
                <OneLineCardstSection
                  type={"product"}
                  title={"Recently Viewed"}
                  productData={recentlyViewedProducts}
                  viewall={false}
                />
              )}
              {relatedProducts?.length > 0 && (
                <OneLineCardstSection
                  type={"product"}
                  title={"Related Products"}
                  productData={relatedProducts}
                  viewall={false}
                />
              )}
              {frequentlyBoughtTogetherProducts?.length > 0 && (
                <OneLineCardstSection
                  type={"product"}
                  title={"Frequently Bought Together"}
                  productData={frequentlyBoughtTogetherProducts}
                  viewall={false}
                />
              )}
              {otherVendorProducts?.length > 0 && (
                <OneLineCardstSection
                  type={"product"}
                  title={"Other Seller Products"}
                  productData={otherVendorProducts}
                  viewall={false}
                />
              )}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  return {
    pinCode: state.productReducer.pinCode,
  };
};

export default ProductDetailPage;
