


import React, { useState, useEffect } from "react";
import { Checkbox, message, notification } from "antd";
import Cookies from "universal-cookie";
import { deliveryChargeChange } from "./quickCheckoutAPI";

// display order summary data responsonse

const OrderSummary = (props) => {
  const cookies = new Cookies();
  const [deliveryCharge, setdeliveryCharge] = useState(
    props.data?.deliveryCharge ? props.data.deliveryCharge : 0
  );
  const [checked, setchecked] = useState(false);

  useEffect(() => {
    if (!props?.data?.customerAddress) {
      setchecked(false);
      setdeliveryCharge(0);
      props.setshippingRequired(false);
    }
  }, [props.data]);
  const handleCheckboxClick = (event) => {
    if (props.pinCode) {
      setchecked(event.target.checked);

      if (!event.target.checked) {
        setdeliveryCharge(0);
        props.setdeliveryCharge(0);
        props.setshippingRequired(false);
      } else {
        setchecked(true);
        handledeliveryCharge();
        props.setshippingRequired(true);
      }
    } else {
      notification["error"]({
        message: "Failed",
        description: "Please Add Address",
      });
      setdeliveryCharge(0);
      props.setdeliveryCharge(0);
      props.setshippingRequired(false);
    }
  };

  const handledeliveryCharge = () => {
    const payload = {
      checkoutCartId: props.data?.checkoutCartId,
      pinCode: props.pinCode ? props.pinCode : "",
      vendorId: cookies.get("vendorId"),
    };

    deliveryChargeChange(payload).then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          setdeliveryCharge(response.data.deliveryCharge);
          props.setdeliveryCharge(response.data.deliveryCharge);
        }
        if (response.data.status === "failed") {
          notification["error"]({
            message: "Failed",
            description: response.data.message,
          });

          setchecked(false);
          setdeliveryCharge(0);
          props.setdeliveryCharge(0);
        }
      }
    });
  };

  return (
    <div className="order-summary">
      <div className="order-summary-heading">
        <h4>Order Summary</h4>
        <Checkbox
          checked={checked}
          onChange={(event) => {
            handleCheckboxClick(event);
          }}
        >
          Delivery Charge Applicable
        </Checkbox>
      </div>
      <div className="order-summary-body">
        <span>
          <h4>MRP</h4>
          <p>₹ {props.data?.totalPrice}</p>
        </span>
        <span>
          <h4>Discounts</h4>
          <p>₹ {props.data?.totalDiscount}</p>
        </span>
        <span>
          <h4>Total DLP</h4>
          <p>₹ {Number(props.data?.totalSellingPrice).toFixed(2)}</p>
        </span>
        <span>
          <h4>Delivery Charge</h4>
          <p>₹ {Number(deliveryCharge).toFixed(2)}</p>
        </span>
        <span>
          <h4>Tax</h4>
          <p>₹ {props.data?.totalTax}</p>
        </span>
        <span>
          <h4>Total</h4>

          <p>
            ₹{Number(props.data?.totalSellingPrice + deliveryCharge).toFixed(2)}
          </p>
        </span>
      </div>
    </div>
  );
};

export default OrderSummary;
