import React, { Fragment, useEffect, useState } from 'react';
import LogDataTable from './LogDataTable';
import { log_get_all } from '../../../../ApiAction/AdminApiAction/logApi';
import { message } from 'antd';
import LogPagination from './LogPagination';

function LogTable(props) {
    const [ logList, setLogList ] = useState([]);
    const { action, screen, status, searchKey, startDate, endDate, size, offSet, currentPage, setCurrentPage, setOffSet, setCount, count } = props;
    const [ dataLoading, setDataLoading ] = useState(false);
    const [ restrictFetch, setRestrictFetch ] = useState(true);
    const [ noRecordsFound, setNoRecordsFound ] = useState(false)

    useEffect(() => {
        if(restrictFetch) {
            setDataLoading(true)
            setLogList([])
            fetchAllLogs()
        }
    }, [props])

    const fetchAllLogs = () => {
        setRestrictFetch(false)
        
        const payload = {
            actionName: action,
            screenName: screen,
            statusKey: status,
            searchKey: searchKey,
            startTime: startDate,
            endTime: endDate,
            sortBy: 'timestamp',
            sortType: 'desc',
            size: size,
            offset: offSet
        }
        log_get_all(payload).then((response)=> {
            if(response.data.status === 'success') {
                setLogList(response?.data?.documents)
                setCount(response?.data?.count)
            }else {
                setLogList([])
                setCount(0)
                message.error(response.data.message)
            }
            if(response?.data?.count) {
                setNoRecordsFound(false)
            }else {
                setNoRecordsFound(true)
            }
            setRestrictFetch(true)
        }).catch((error) => {
            console.log("Error in fetch log :: ", error.message)
            setRestrictFetch(true)
        }).finally(()=> {
            setDataLoading(false)
        });
    }

    return (
        <div className="LogTable-main-container">
            { dataLoading && (
                <div className="LogDataTable-loading-box"></div>
            )}
            {logList?.length > 0 && (
                <LogDataTable logs={logList} />
            )}
            {noRecordsFound && (
                <div className="LogDataTable-empty-box">
                    <span className="LogDataTable-empty-box-icon">🧐</span>
                    <p className="LogDataTable-empty-box-text">Hmm that's weird</p>
                    <p className="LogDataTable-empty-box-sub-text" >log is empty that means no one is doing anything, good for me.</p>
                </div>
            )}


            <LogPagination
             offSet={offSet}
             count={count}
             currentPage={currentPage}
             setCurrentPage={setCurrentPage}
             setOffSet={setOffSet}
             size={size}
             />
        
        </div>
    )
}

export default LogTable
