


import React, { useState, useEffect, Fragment } from "react";
import {
  Typography,
  Row,
  Col,
  Button,
  Divider,
  Form,
  Input,
  message,
} from "antd";
import {
  fetchDeliveryCharge,
  updateDeliveryCharge,
} from "../../../ApiAction/SellerApiActions/deliveryChargeAPI";

import "./manageConfigure.scss";
import Loading from "../../GeneralComponents/Loading";
import { formatDeliveryChargePayload } from "./configurehelper";

const { Text, Title } = Typography;

const ConfigureDeliveryCharge = (props) => {
  const { isSeller, isStore, isAdmin } = props;
  const [fields, setfields] = useState([]);
  const [initialConfig, setinitialConfig] = useState({});
  const [isEdit, setisEdit] = useState(false);

  const [loading, setloading] = useState(false);

  const [form] = Form.useForm();

  const positiveNumberValidator = (rule, value, callback) => {
    if (value && value <= 0) {
      callback("Enter a valid value !");
    } else {
      callback();
    }
  };
  const formFieldsForSeller = [
    {
      name: "maxWeight",
      placeHolder: "Max weight in KG",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "deliveryChargeWithInLocality",
      placeHolder: "Delivery charge Within locality",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "deliveryChargeWithInDistrict",
      placeHolder: "Delivery charge within district",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "deliveryChargeWithInState",
      placeHolder: "Delivery charge within state",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "deliveryChargeOutOfState",
      placeHolder: "Delivery charge out of state",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "additionalChargePerKg",
      placeHolder: "Additional Charge per KG",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "minAmountToExcludeDeliveryCharge",
      placeHolder: "Min Amount to Exclude Delivery Charge",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
  ];
  const formFields = [
    {
      name: "maxWeight",
      placeHolder: "Max weight in KG",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "deliveryCharge",
      placeHolder: "Delivery charge upto max weight",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "additionalChargePerKg",
      placeHolder: "Additional Charge per KG",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
    {
      name: "minAmountToExcludeDeliveryCharge",
      placeHolder: "Min Amount to Exclude Delivery Charge",
      rules: [
        {
          validator: positiveNumberValidator,
        },
      ],
    },
  ];

  const handleUpdateDeliveryCharge = (payload) => {
    setloading(true);
    updateDeliveryCharge(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(response.data.message);
            setisEdit(false);
            handleFetchDeliveryCharge();
          } else message.error(response.data.message);
        } else
          message.error(
            "unable to update configuration please try again later."
          );
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };

  const onFinishDeliveryCharge = (values) => {
    const payload = {
      deliveryChargeCriteria: formatDeliveryChargePayload(values),
    };
    handleUpdateDeliveryCharge(payload);
  };

  const handleCancelUpdation = () => {
    form.resetFields();
    // form.setFieldsValue(initialConfig);
    setisEdit(false);
  };

  const handleFetchDeliveryCharge = () => {
    fetchDeliveryCharge().then((response) => {
      if (response?.data?.deliveryChargeCriteria?.maxWeight) {
        form.setFieldsValue(response.data.deliveryChargeCriteria);
        setinitialConfig(response.data.deliveryChargeCriteria);
      } else {
        console.log("no data has been added yet");
      }
    });
  };

  useEffect(() => {
    isSeller ? setfields(formFieldsForSeller) : setfields(formFields);
  }, [isSeller]);

  useEffect(() => {
    handleFetchDeliveryCharge();
  }, []);
  return (
    <div>
      {!isAdmin && <Title level={4}>Manage Delivery Charges</Title>}

      <Divider />

      {fields.length > 0 && (
        <Form
          form={form}
          initialValues={initialConfig}
          onFinish={onFinishDeliveryCharge}
        >
          <Row>
            {fields.map((data) => {
              return (
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={7}
                  xl={12}
                  className="add-image-col"
                  key={data.name}
                >
                  <Text>
                    {data.placeHolder}
                    <span className="required-field-asterisk">*</span>
                  </Text>
                  <Form.Item
                    name={data.name}
                    rules={
                      data.rules
                        ? [
                            ...data.rules,
                            {
                              required: true,
                              message: "This field is required !",
                            },
                          ]
                        : [
                            {
                              required: true,
                              message: "This field is required !",
                            },
                          ]
                    }
                  >
                    <Input
                      disabled={!isEdit}
                      type="number"
                      placeholder={data.placeHolder}
                    />
                  </Form.Item>
                </Col>
              );
            })}
          </Row>

          <div className="config_action_main">
            {isEdit ? (
              <Fragment>
                <Button
                  onClick={() => handleCancelUpdation()}
                  className="config_action_btn"
                >
                  Cancel
                </Button>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="config_action_btn"
                    style={{ marginLeft: "20px" }}
                  >
                    {loading ? (
                      <Loading style={{ color: "white" }} />
                    ) : (
                      " Submit"
                    )}
                  </Button>
                  <br />
                </Form.Item>
              </Fragment>
            ) : (
              <Button
                type="primary"
                onClick={() => setisEdit(true)}
                className="config_action_btn"
              >
                Edit
              </Button>
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default ConfigureDeliveryCharge;
