


import Productlist from "./components/Productlist";

export const getThumbImg = (image) => {
  if(image !== null) {
    var filenameWithOutExtension = image.replace(/^.*[\\\/]/, "");
    let thumbFileName =
      "thumbnail-" + filenameWithOutExtension.split(".")[0] + ".png";
    let filePathArray = image.split("/");
    filePathArray.splice(-1, 1, thumbFileName);

    return filePathArray.join("/");
  }
  console.log("Image", image)
};

const deliveryPincode = (pincodes) => {
  let flag = 0;
  pincodes.map((data)=> {
    if (data === "All India") {
      flag = 1;
    }
  })
  return flag === 1 ? true : false
}

export const formatProductData = (products, vendor) => {
  let productList = [];

  products.map((data) => {
    // console.log("PPP")
    // console.log(data?.source)
    let temp = {
      productId: data.source.productId,
      productName: data.source.name,
      productImage: data?.source?.images !== null && getThumbImg(data?.source?.images[0]),
      vendorId: data.source.vendorId,
      vendorType: data.source.vendorType,
      processedPrice: data.source.processedPriceAndStock,
      allIndiaDelivery: deliveryPincode(data?.source?.serviceLocations),
      serviceLocations: data?.source?.serviceLocations
    };

    //checking where the helper is called from checkAvailableProducts component
    if (vendor) {
      temp["vendor"] = data.source.vendor;
    }

    productList.push(temp);
  });

  // console.log("products  formated:", productList);
  return productList;
};
