

export const stateSuggestions = [
  "ANDAMAN AND NICOBAR ISLANDS",
  "ANDHRA PRADESH",
  "ARUNACHAL PRADESH",
  "ASSAM",
  "BIHAR",
  "CHANDIGARH",
  "CHATTISGARH",
  "DAMAN & DIU",
  "DELHI",
  "GOA",
  "GUJARAT",
  "HARYANA",
  "HIMACHAL PRADESH",
  "JAMMU & KASHMIR",
  "JHARKHAND",
  "KARNATAKA",
  "KERALA",
  "LAKSHADWEEP",
  "LADAKH",
  "MADHYA PRADESH",
  "MAHARASHTRA",
  "MANIPUR",
  "MEGHALAYA",
  "MIZORAM",
  "NAGALAND",
  "ODISHA",
  "PONDICHERRY",
  "PUNJAB",
  "RAJASTHAN",
  "SIKKIM",
  "TAMIL NADU",
  "TELANGANA",
  "TRIPURA",
  "UTTAR PRADESH",
  "UTTARAKHAND",
  "WEST BENGAL",
];

export const stateOptions = [
  {
    key: "ANDAMAN AND NICOBAR ISLANDS",
    value: "ANDAMAN AND NICOBAR ISLANDS",
  },
  {
    key: "ANDHRA PRADESH",
    value: "ANDHRA PRADESH",
  },
  {
    key: "ARUNACHAL PRADESH",
    value: "ARUNACHAL PRADESH",
  },
  {
    key: "ASSAM",
    value: "ASSAM",
  },
  {
    key: "BIHAR",
    value: "BIHAR",
  },
  {
    key: "CHANDIGARH",
    value: "CHANDIGARH",
  },
  {
    key: "CHATTISGARH",
    value: "CHATTISGARH",
  },
  {
    key: "DAMAN & DIU",
    value: "DAMAN & DIU",
  },
  {
    key: "DELHI",
    value: "DELHI",
  },
  {
    key: "GOA",
    value: "GOA",
  },
  {
    key: "GUJARAT",
    value: "GUJARAT",
  },
  {
    key: "HIMACHAL PRADESH",
    value: "HIMACHAL PRADESH",
  },
  {
    key: "JAMMU & KASHMIR",
    value: "JAMMU & KASHMIR",
  },
  {
    key: "JHARKHAND",
    value: "JHARKHAND",
  },
  {
    key: "KARNATAKA",
    value: "KARNATAKA",
  },
  {
    key: "KERALA",
    value: "KERALA",
  },
  {
    key: "LAKSHADWEEP",
    value: "LAKSHADWEEP",
  },
  {
    key: "LADAKH",
    value: "LADAKH",
  },
  {
    key: "MADHYA PRADESH",
    value: "MADHYA PRADESH",
  },
  {
    key: "MAHARASHTRA",
    value: "MAHARASHTRA",
  },
  {
    key: "MANIPUR",
    value: "MANIPUR",
  },
  {
    key: "MEGHALAYA",
    value: "MEGHALAYA",
  },
  {
    key: "MIZORAM",
    value: "MIZORAM",
  },
  {
    key: "NAGALAND",
    value: "NAGALAND",
  },
  {
    key: "ODISHA",
    value: "ODISHA",
  },
  {
    key: "PONDICHERRY",
    value: "PONDICHERRY",
  },
  {
    key: "PUNJAB",
    value: "PUNJAB",
  },
  {
    key: "RAJASTHAN",
    value: "RAJASTHAN",
  },
  {
    key: "SIKKIM",
    value: "SIKKIM",
  },
  {
    key: "TAMIL NADU",
    value: "TAMIL NADU",
  },
  {
    key: "TELANGANA",
    value: "TELANGANA",
  },
  {
    key: "TRIPURA",
    value: "TRIPURA",
  },
  {
    key: "UTTAR PRADESH",
    value: "UTTAR PRADESH",
  },
  {
    key: "UTTARAKHAND",
    value: "UTTARAKHAND",
  },
  {
    key: "WEST BENGAL",
    value: "WEST BENGAL",
  },
];

export const printerOptions = [
  {
    printerName: "Normal Invoice Printer",
    printerValue: "normalInvoicePrinter",
  },
  {
    printerName: "Thermal Printer",
    printerValue: "thermalPrinter",
  },
];

export const PLATFORM_STATUS = [
  {
    name: "INVOICED",
  },
  {
    name: "PROCESSING",
  },
  {
    name: "PAID",
  },
];
export const PLATFORM_MODE_OF_PAY = [
  // { name: "ONLINE" },
  {
    name: "BANK",
  },
  {
    name: "CASH",
  },
  {
    name: "CHEQUE",
  },
  {
    name: "DD",
  },
  {
    name: "UPI",
  },
  {
    name: "OTHER",
  },
];
