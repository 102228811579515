


import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";



import Interweave from "interweave";

import { GetStaticFiles } from "../../../ApiAction/AdminApiAction/adminApi";
import MetaDecorator from "../../../Utils/MetaDecorator";
const ContentSection = (props) => {
  const url = props.match.url.replace("/", "");
  const [currentUrl, setcurrentUrl] = useState();
  const [title, setTitle] = useState("");

  const pageHeading = {
    "contact-us": "Contact Us",
    "terms-of-use": "Terms of use",
    "privacy-policy": "Privacy Policy",
    "about-us": "About Us",
    "returns": "Returns",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetStaticFiles(props.match.params.type).then((response) => {
      if(response.data.status=="success" && response.data.message!="Not found")
      {
      setcurrentUrl(response.data?.content?.source.body)
      setTitle(response.data?.content?.source.title)
      }
    }
    )

  }, [url]);

  return (

    <div className="enquiry-form-body">
    <MetaDecorator
        title={title?`${title} | loremIpsum.in`:"loremIpsum - Ecommerce website providing quality products"}
        description={"loremIpsum - Ecommerce website providing quality products"}

      />
      <h1 className="content-customer-title">{title}</h1>

      <div className="details-container-main">

          <Interweave content={currentUrl} />


      </div>
    </div>

  );
};

export default withRouter(ContentSection);
