


import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Typography,
  Divider,
  Form,
  Select,
  Input,
  message,
} from "antd";

// import "./requestReturn.scss";
import {
  getCancelMessage,
  getReturnMessage,
} from "../../../ApiAction/AdminApiAction/adminApi";

import QuantityController from "../../Customer/Components/ProductDetailPage/Components/QuantityController";

const { Option } = Select;
const EditOrderModal = (props) => {
  const { maxQuantity, productName, paymentMode, availableStock } = props;
  const [productReturnList, setProductReturnList] = useState([]);
  const [cancelMessage, setCancelMessage] = useState("");
  const [quantity, setquantity] = useState(0);

  useEffect(() => {
    setquantity(maxQuantity);
  }, [maxQuantity]);

  const handleCancel = () => {
    props.setVisible(false);
  };

  return (
    <Modal
      title={`Edit Order of ${productName} `}
      visible={props.visible}
      onOk={() => props.setVisible(false)}
      onCancel={() => props.setVisible(false)}
      footer={null}
      width={500}
      className="cancle-reason-pop"
    >
      <div
        className="add-product-tile"
        style={{ marginBottom: "25px", display: "flex" }}
      >
        Quantity to be updated
        <QuantityController
          quantity={quantity}
          stock={paymentMode === "COD" ? availableStock : maxQuantity}
          update={true}
          isOrderEdit={paymentMode === "COD" ? false : true} //if payment method is cod then only vendor can increase product quantity
          setQuantity={(value) => setquantity(value)}
        />
      </div>

      <div className="seller-action-button">
        <Button
          style={{ width: "200px" }}
          className="button-seller-request-reject"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>

        <Button
          style={{ width: "200px" }}
          onClick={() => props.handleEditOrder(quantity)}
          className="button-seller-request-approve"
        >
          Edit Order
        </Button>
      </div>
    </Modal>
  );
};

export default EditOrderModal;
