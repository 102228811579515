

import React, { useEffect, useState, Fragment } from "react";
import {
  Row,
  Col,
  Checkbox,
  Typography,
  Input,
  Select,
  Collapse,
  Button,
  Modal,
  message,
  notification,
} from "antd";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import { CaretRightOutlined } from "@ant-design/icons";
import moment from "moment";
import EditIcon from "../../../Assets/Images/Icons/Seller/edit-black.svg";
import DeleteIcon from "../../../Assets/Images/Icons/Seller/delete.svg";

import AddBatch from "./addBatch";
import StockVarient from "./stockVarient";
import { getVendorProductDetails } from "../../../ApiAction/CustomerApiActions/productApi";
import ManagaProductCardDraft from "../ManageProducts/Components/managaProductCardDraft";
import {
  addBatch,
  updateBatch,
  deleteBatch,
  getSellerProfile,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import { getBatchTypeAndVariants } from "../../../ApiAction/AdminApiAction/adminApi";
import { fetchProductStocks } from "../../../ApiAction/SellerApiActions/vendoOrderAPI";
import Cookies from "universal-cookie";
import {
  findBrowser,
  findDeviceModel,
} from "../../Admin/Logger/notificationBar/LogHelper";

const { Title, Text } = Typography;
const { Option } = Select;
const { Panel } = Collapse;
const confirmModal = Modal.confirm;
const ManageProductStock = (props) => {
  const [singleProduct, setSingleProduct] = useState();
  const [updateVarient, setUpdateVarient] = useState([]);
  const [isAddBatch, setAddBatch] = useState(false);
  const [isActivePanel, setActive] = useState("");
  const [productBatch, setProductBatch] = useState([]);
  const [isEdit, setEdit] = useState(true);
  const [typeVariants, settypeVariants] = useState([]);
  const [isLoadingAddBatch, setisLoadingAddBatch] = useState(false);
  const [emptyFieldErrorMsg, setEmptyFieldErrorMsg] = useState("");
  const [stockOverviews, setstockOverviews] = useState();
  const [distinctVariant, setDistinctVariant] = useState();
  const [reloadInputs, setReloadInputs] = useState(false);

  const cookies = new Cookies();

  const [accountType] = useState(cookies.get("accountType"));
  const [modifiedCommission, setModifiedCommission] = useState(0);
  const [barcode, setBarcode] = useState(0);
  const [selectedQuantity] = useState(0);
  const [selectedUnit] = useState("refresh");
  const [newBarcode, setNewBarcode] = useState();

  useEffect(() => {
    if (accountType === "seller") {
      getProfileDetails();
    }

    getProductDetails();
  }, []);

  const getProfileDetails = () => {
    getSellerProfile()
      .then((response) => {
        if (response?.data?.status === "success") {
          if (
            response?.data?.profile?.source?.tradeDetails?.isCompanyGst === false
          ) {
            setModifiedCommission(5);
          } else {
            setModifiedCommission(7.5);
          }
        }
      })
      .catch((error) => {
        console.log(
          "Error in Getting Seller Details in Batch ::",
          error.message
        );
      })
      .finally();
  };

  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };

  const fetchTypeVariantsList = (type) => {
    getBatchTypeAndVariants().then((response) => {
      if (response?.data?.status === "success") {
        settypeVariants(response.data.productBatchConfigurations);
      }
    });
  };
  //stocks overview here
  const getProductStocksOverview = () => {
    fetchProductStocks(props.match.params.productId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setstockOverviews(response?.data?.orders?.overviews);
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const getProductDetails = () => {
    getProductStocksOverview();
    getVendorProductDetails(props.match.params.productId)
      .then((res) => {
        if (res.data.status === "success") {
          setDistinctVariant(
            filterVariant(res?.data?.product?.source?.productBatches)
          );
          setSingleProduct(res.data.product);
          setProductBatch(
            res.data?.product?.source?.productBatches.filter((item) => {
              return item.active !== false;
            })
          );
          if (res.data.product.source.productBatches) {
            console.log("product batches updated");
            // setProductBatch(res.data.product.source.productBatches);
            if (res.data.product.source.productBatches.length > 0)
              setActive(
                res.data.product.source.productBatches[
                  res.data.product.source.productBatches.length - 1
                ].addedDate
              );
          }
        }
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {});
    fetchTypeVariantsList();
  };

  const filterVariant = (batches) => {
    let variantCollection = [];
    let temp = batches
      ?.map((batch) => {
        let tempVariant = `${batch?.priceAndStocks[0]?.quantity} ${batch?.priceAndStocks[0]?.unit}`;
        if (
          variantCollection?.filter((data) => {
            if (data?.value === tempVariant) {
              return data;
            }
          })?.length === 0
        ) {
          let tempObj = {
            value: tempVariant,
            unit: batch?.priceAndStocks[0]?.unit,
            quantity: batch?.priceAndStocks[0]?.quantity,
            barcode: batch?.priceAndStocks[0]?.barcode,
            type: batch?.type,
          };
          variantCollection.push(tempObj);
        }
      });
    return variantCollection;
  };

  const editVarient = (variant, type, value) => {
    setUpdateVarient(
      updateVarient.map((item) =>
        item.variant === variant ? { ...item, [type]: value } : item
      )
    );
  };

  const updateMultipleField = (updatedFields) => {
    setUpdateVarient((prevBatchVariant) => {
      console.log("prevBatchVariant: ", prevBatchVariant);
      return prevBatchVariant.map((variant) => {
        if (variant.variant === updatedFields[0].variant) {
          let temp = variant;
          updatedFields.map((data) => {
            temp[data.type] = data.value;
          });
          return temp;
        } else return variant;
      });
    });
  };

  const addproductBatch = (payload) => {
    setisLoadingAddBatch(true);
    addBatch(payload)
      .then((res) => {
        if (res.data.status === "success") {
          openNotificationWithIcon("success", "Success", res.data.message);
          setAddBatch(false);
          getProductDetails();
        } else {
          openNotificationWithIcon("error", "Failed", res.data.message);
        }

        //  setSingleProduct(res.data.product)
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {
        setisLoadingAddBatch(false);
      });
  };

  const renderConfirmModal = (data, handleAction, msg) => {
    Modal.confirm({
      title: "Confirm",
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to ${msg} this batch ? `,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleAction(data),
    });
  };

  const handleDeleteProductBatch = (batchNo) => {
    const adminUser = JSON.parse(localStorage.getItem("activeCustomer"));
    const payload = {
      productId: props.match.params.productId,
      batchNumber: batchNo,
      adminUser: adminUser.userId,
      pageURL: window.location.href,
      screenName: "Manage Stocks",
      browserInfo: findBrowser(),
      accountType: adminUser.accountType,
      deviceModel: findDeviceModel(),
    };
    deleteBatch(payload)
      .then((res) => {
        if (res.data.status === "success") {
          message.success(res.data.message);
          setProductBatch(
            productBatch.filter(
              (item) => item.batchNumber !== batchNo && item.active !== false
            )
          );
        }

        //  setSingleProduct(res.data.product)
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {});
  };

  const deleteproductBatch = (batchNo) => {
    let msg = "delete";
    renderConfirmModal(batchNo, handleDeleteProductBatch, msg);
  };

  const handleUpdateProductBatch = async (data) => {
    const adminUser = JSON.parse(localStorage.getItem("activeCustomer"));
    const payload = {
      productId: props.match.params.productId,
      batchNumber: data.batch,
      addedDate: data.time,
      type: updateVarient?.[0]?.type || data.type,
      priceAndStocks: updateVarient,
      invoiceNumber: data.invoiceNumber,
      expiryDate: data.expiryDate,
      notificationPeriodForToBeExpiredBatch:
        data.notificationPeriodForToBeExpiredBatch,
      adminUser: adminUser.userId,
      pageURL: window.location.href,
      screenName: "Manage Stocks",
      browserInfo: findBrowser(),
      accountType: adminUser.accountType,
      deviceModel: findDeviceModel(),
    };

    updateBatch(payload)
      .then((res) => {
        if (res.data.status === "success") {
          message.success(res.data.message);

          setEdit(true);

          getProductDetails();
        } else {
          message.error(res.data.message);
        }
        //  setSingleProduct(res.data.product)
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {});
  };

  const updateProductBatch = async (batch, time, type, item) => {
    console.log(item, "item to update");
    let msg = "update";
    let data = {
      batch: batch,
      time: time,
      type: type,
      invoiceNumber: item.invoiceNumber,
      expiryDate: item.expiryDate,
      notificationPeriodForToBeExpiredBatch:
        item.notificationPeriodForToBeExpiredBatch,
    };

    // < -------------- New Code Added Here ------------------ >

    setEmptyFieldErrorMsg("");
    // console.log("This is the update Varient : ");
    // console.log(updateVarient);

    const isVerificationError = await updateVarient.find((batch) => {
      return (
        !batch.quantity ||
        !batch.unit ||
        // (!batch.stock && batch.stock < 0) ||
        batch.stock === undefined ||
        batch.stock === null ||
        batch.stock === "" ||
        !batch.price ||
        // !batch.discount ||
        !batch.sellingPrice ||
        !batch.weightInKg ||
        !batch.dealerPrice
      );
    });

    const isDiscountNotVerified = await updateVarient.find((batch) => {
      return (
        batch.discount < singleProduct?.source?.minCategoryDiscount &&
        batch.variant >= 0
      );
    });

    if (isDiscountNotVerified) {
      setEmptyFieldErrorMsg(
        `There should be minimum discount of ${singleProduct?.source?.minCategoryDiscount}% for this product `
      );
      return;
    } else if (!isVerificationError) {
      renderConfirmModal(data, handleUpdateProductBatch, msg);
    } else {
      setEmptyFieldErrorMsg("Some fields are missing");
    }
  };

  useEffect(() => {
    console.log(productBatch, ":updated product Batch");
  }, [productBatch]);

  // useEffect(() => {
  //   console.log(updateVarient, ":update variant");
  // }, [updateVarient]);

  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        {" "}
        <h1 level={4} className="sub-title-tags">
          {" "}
          Price & Stock
        </h1>
      </div>
      {singleProduct && (
        <React.Fragment>
          <ManagaProductCardDraft
            product={singleProduct}
            isSingleStock
            isStore={props.isStore}
            isWarehouse={props.isWarehouse}
            stocksOverviews={stockOverviews}
          />

          <div
            className="manage-products-header"
            style={{
              marginBottom: "10px",
              paddingBottom: "10px",
              borderBottom: "1px solid #cac7c7",
            }}
          >
            {" "}
            <div style={{ fontWeight: "600", color: "#000", fontSize: "15px" }}>
              {" "}
              Batch and Stock
            </div>
            <div className="manage-products-action">
              <Button
                type="primary"
                className="manage-products-action-button"
                onClick={() => setAddBatch(true)}
              >
                Add Batch
              </Button>
            </div>
          </div>
          {isAddBatch && (
            <AddBatch
              addproductBatch={addproductBatch}
              isLoading={isLoadingAddBatch}
              productId={props.match.params.productId}
              setAddBatch={setAddBatch}
              categoryCommission={
                accountType === "seller"
                  ? modifiedCommission
                  : singleProduct?.source?.categoryCommission
              }
              minCategoryDiscount={singleProduct?.source?.minCategoryDiscount}
              distinctVariant={distinctVariant}
              centralRepoId={singleProduct?.source?.centralRepoProductId}
            />
          )}

          {productBatch?.map((item, index) => (
            <Collapse
              bordered={false}
              activeKey={[isActivePanel]}
              accordion
              expandIconPosition="right"
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="stock-collapse"
              onChange={() => {
                isActivePanel === item.addedDate
                  ? setActive()
                  : setActive(item.addedDate);
              }}
              style={{ marginTop: "20px", background: "#f2f2f2" }}
            >
              <Panel
                key={item.addedDate}
                header={
                  <Row
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <Col
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      className="add-image-col"
                    >
                      <div className="add-product-tile">
                        Batch Number:{" "}
                        <span style={{ color: "#3e6df3" }}>
                          {item.batchNumber}{" "}
                        </span>
                      </div>
                      {isActivePanel != item.addedDate ? (
                        <div className="add-product-tile">
                          Date Added:{" "}
                          {moment(item.addedDate).format(" MMM DD YYYY ")}
                        </div>
                      ) : (
                        <>
                          <div className="add-product-tile">
                            Type:{" "}
                            <span style={{ color: "#3e6df3" }}>
                              {item.type}{" "}
                            </span>
                          </div>
                          <div className="add-product-tile">
                            Invoice Number:{" "}
                            <span style={{ color: "#3e6df3" }}>
                              {item.invoiceNumber ? item.invoiceNumber : "NA"}{" "}
                            </span>
                          </div>
                        </>
                      )}
                      <div className="add-product-tile">
                        Expiry Date:{" "}
                        <span style={{ color: "#3e6df3" }}>
                          {item.expiryDate
                            ? moment(item.expiryDate).format(" MMM DD YYYY ")
                            : "NA"}
                        </span>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      className="add-image-col"
                    >
                      {isActivePanel != item.addedDate && (
                        <React.Fragment>
                          <div className="add-product-tile">
                            Available Varients:{" "}
                            {item?.priceAndStocks
                              ?.filter((item) => item.variant >= 0)
                              .map(
                                (value, index, { length }) =>
                                  value.quantity +
                                  " " +
                                  value.unit +
                                  (index !== length - 1 ? ", " : "")
                              )}
                          </div>
                          <div className="add-product-tile">
                            Total Stock:{" "}
                            {item?.priceAndStocks
                              ?.filter((item) => item.variant >= 0)
                              .reduce((total, obj) => obj.stock + total, 0)}
                          </div>
                          {/* Adding Invoice Number below */}
                          <div className="add-product-tile">
                            Invoice Number:{" "}
                            <span style={{ color: "#3e6df3" }}>
                              {item.invoiceNumber ? item.invoiceNumber : "NA"}{" "}
                            </span>
                          </div>
                          <div className="add-product-tile">
                            Barcode:{" "}
                            <span style={{ color: "#3e6df3" }}>
                              {item?.priceAndStocks
                                ?.filter((item) => item.variant >= 0)
                                .map(
                                  (value, index, { length }) =>
                                    value.barcode +
                                    (index !== length - 1 ? ", " : "")
                                )}
                            </span>
                          </div>
                        </React.Fragment>
                      )}
                    </Col>
                    <Col
                      xs={12}
                      sm={12}
                      md={8}
                      lg={8}
                      xl={8}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingRight: "20px",
                      }}
                    >
                      {/* stock edit */}

                      <img
                        src={EditIcon}
                        style={{ width: "40px", paddingRight: "15px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setUpdateVarient(item.priceAndStocks);
                          setEdit(false);
                          isActivePanel === item.addedDate && !isEdit
                            ? setActive()
                            : setActive(item.addedDate);
                        }}
                        alt="Edit Button"
                        title={item.stock}
                      />

                      <img
                        src={DeleteIcon}
                        style={{ width: "40px", paddingRight: "20px" }}
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteproductBatch(item.batchNumber);
                        }}
                        alt="Delete Button"
                      />
                    </Col>
                  </Row>
                }
                className="site-collapse-custom-panel"
              >
                {item?.priceAndStocks
                  ?.filter((item) => item.variant >= 0)
                  .map((value) => (
                    <StockVarient
                      key={value.variant}
                      priceStock={value}
                      initialValues={value}
                      edit={isEdit}
                      setEdit={setEdit}
                      editVarient={editVarient}
                      updateMultipleField={updateMultipleField}
                      stockType={item?.type}
                      typeVariants={typeVariants}
                      categoryCommission={
                        accountType === "seller"
                          ? modifiedCommission
                          : singleProduct?.source?.categoryCommission
                      }
                      distinctVariant={distinctVariant}
                      barcode={barcode}
                      selectedQuantity={selectedQuantity}
                      selectedUnit={selectedUnit}
                      setBarcode={setBarcode}
                      acceptBarcodeValues={false}
                      newBarcode={newBarcode}
                      setNewBarcode={setNewBarcode}
                      productId={singleProduct?.id}
                      getProductDetails={getProductDetails}
                      reloadInputs={reloadInputs}
                      setReloadInputs={setReloadInputs}
                    />
                  ))}

                <div className="price-commision-text">
                  <span style={{ color: "#3e6df3" }}>*Selling price </span>
                  includes{" "}
                  {/* <span style={{ color: "#3e6df3" }}>
                    {" "}
                    {accountType === "seller"
                      ? modifiedCommission
                      : singleProduct?.source?.categoryCommission}
                    %{" "}
                  </span>{" "} */}
                  Dream Life commission
                </div>

                {!isEdit && (
                  <Fragment>
                    {emptyFieldErrorMsg ? (
                      <div className="missing-field-text">
                        <span>{emptyFieldErrorMsg}</span>
                      </div>
                    ) : (
                      <></>
                    )}

                    <div className="seller-action-button">
                      <Button
                        className="button-seller-request-reject"
                        onClick={() => {
                          setEdit(true);
                          getProductDetails();
                        }}
                      >
                        Discard
                      </Button>

                      <Button
                        onClick={() =>{
                          updateProductBatch(
                            item.batchNumber,
                            item.addedDate,
                            item.type,
                            item
                          );
                        console.log(item)}
                        }
                        className="button-seller-request-approve"
                      >
                        Update Batch
                      </Button>
                    </div>
                  </Fragment>
                )}
              </Panel>
            </Collapse>
          ))}
        </React.Fragment>
      )}
    </div>
  );
};
export default ManageProductStock;
