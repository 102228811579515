


import React from "react";
import { Layout } from "antd";
import FooterView from "../../Views/FooterView";

const { Footer } = Layout;

export default function FooterLayout() {
  return (
    <Footer className="footer-style">
      <FooterView />
    </Footer>
  );
}
