

import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Input,
  Upload,
  Button,
  message,
  Select,
  Avatar,
  Form,
  Modal,
  Spin,
  notification,
  Radio,
  Checkbox,
  Divider,
  Switch,
} from "antd";
import { UserOutlined, CloudUploadOutlined, InfoCircleFilled } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import "./profile.scss";
import { ReactComponent as pdfIcon } from "../../../Assets/Images/Icons/pdf.svg";
import { ReactComponent as CorrectIcon } from "../../../Assets/Images/Icons/correct.svg";
import { DeleteFilled } from "@ant-design/icons";

import { getPrimaryAddressOfCustomer } from "../../Customer/Components/ManageAddress/manageAddressHelper";
import Loading from "../../GeneralComponents/Loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Cookies from "universal-cookie";
import {
  getCustomerOrders,
  getSellerProfile,
  AddSellerProfileAddress,
  AddAdminProfileAddress,
  AddUserProfilePic,
  downloadSellerAttachment,
  deleteVendorAttachment,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import { ENV_TYPE, FILE_PATH, DOMAIN } from "../../../Utils/constants";
import {
  fetchAllExceededPlatformCharge,
  fetchPlatformChargeExceed,
  getIdProof,
} from "../../../ApiAction/AdminApiAction/adminApi";
import ServiceLocationModal from "../../Seller/ManageProducts/Components/serviceLocationModal";
import { type } from "jquery";
import { BiFolderOpen } from "react-icons/bi";
import { FiRefreshCw } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { FaClipboard } from "react-icons/fa";
import { IoMdSave } from "react-icons/io";
import { IoIosCloseCircle } from "react-icons/io";
import { LoadingOutlined } from "@ant-design/icons";
import { printerOptions, stateSuggestions } from "../../../Utils/constData";
import { WarningFilled } from "@ant-design/icons";
import NumberFormat from "react-number-format";
import { BsInfoCircleFill } from "react-icons/bs";
import Text from "antd/lib/typography/Text";

const { Option } = Select;
const { Dragger } = Upload;
const ManageProfile = (props) => {
  const cookies = new Cookies();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [generalEdit, setGeneralEdit] = useState(false);
  const [bankEdit, setBankEdit] = useState(false);
  const [tradeEdit, setTradeEdit] = useState(false);
  const [storeEdit, setStoreEdit] = useState(false);
  const [printerEdit, setPrinterEdit] = useState(false);
  const [personalEdit, setPersonalEdit] = useState(false);
  const [locationEdit, setLocationEdit] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [newImgUrl, setNewImgUrl] = useState([]);
  const [pincodes, setPincodes] = useState([]);
  const [districtList, setDistrictList] = useState(["Ernakulam", "Calicut"]);
  const [proofList, setProofList] = useState([]);
  const [pincodeList, setPincodeList] = useState([
    "673501",
    "673502",
    "673503",
    "673504",
  ]);
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [showPasswordModal, setPasswordModalVisible] = useState(false);
  const [sellerDetails, setSellerDetails] = useState();
  const [adminDetails, setAdminDetails] = useState();

  const [sellerName, setSellerName] = useState("");
  const [sellerPhone, setPhone] = useState("");
  const [sellerAddress1, setAddress1] = useState("");
  const [sellerAddress2, setAddress2] = useState("");
  const [sellerState, setsellerState] = useState("");
  const [sellerPin, setPin] = useState("");
  const [storeName, setStoreName] = useState("");

  const [bank, setBank] = useState("");
  const [branch, setBranch] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [pan, setPan] = useState("");
  const [location, setLocation] = useState("");
  const [ifsc, setIfsc] = useState("");

  const [gstNo, setGstNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [businessEmail, setBusinessEmail] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [typeOfBusiness, setTypeOfBusiness] = useState("");
  const [tradeLicensePdf, setTradeLicensePdf] = useState();

  const [idProof, setIdProof] = useState("");
  const [idNumber, setIdNumber] = useState("");

  const [newImgSrc, setNewImgSrc] = useState("");
  const [newImageFile, setNewImageFile] = useState();
  const generalRef = useRef();
  const [changePassword, setChangePassword] = useState(false);

  const [isLoading, setisLoading] = useState(false);

  const [bankLoading, setbankLoading] = useState(false);
  const [tradeLoading, settradeLoading] = useState(false);
  const [storeLoading, setstoreLoading] = useState(false);
  const [personalLoading, setpersonalLoading] = useState(false);

  const [serviceModal, setserviceModal] = useState(false);
  const [serviceLocations, setserviceLocations] = useState([]);
  const [serviceLocationObject, setserviceLocationObject] = useState();
  const [infoCount, setInfoCount] = useState(0);
  const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [windowGroup, setWindowGroup] = useState("multiWindow");
  const [GSTType, setGSTType] = useState(false);
  const [printerType, setPrinterType] = useState();
  const [totalNotification, setTotalNotification] = useState(0);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };

  const [needToShow, setNeedToShow] = useState(!cookies.get("needToShow"));
  const [needToShowIncomingOrders, setNeedToShowIncomingOrders] = useState(!cookies.get("needToShowIncomingOrders"));
  const [platformData, setPlatformData] = useState();
  const [incomingOrders, setIncomingOrders] = useState();

  const handleDeleteDoc = (filepath, fileIndex) => {
    deleteVendorAttachment(filepath)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.status === "success") {
            openNotificationWithIcon(
              "success",
              "Success",
              "Attachment removed successfully  "
            );
            // setNewImgUrl([]);
            const updatedImageList = newImgUrl.filter((data, index) => {
              return index !== fileIndex;
            });
            setNewImgUrl(updatedImageList);

            getSellerProfileData();
            setTradeEdit(true);
          } else {
            openNotificationWithIcon(
              "error",
              "Failed",
              response?.data?.message
            );
          }
        }
      })
      .catch((error) => {});
  };

  const handleConfirmDeleteDoc = (event, filepath, index) => {
    event.stopPropagation();
    Modal.confirm({
      title: "Are you sure you want to remove this attachment?",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        handleDeleteDoc(filepath, index);
      },
    });
  };

  const downloadAttachement = (urlPayload) => {
    const payload = {
      url: urlPayload,
    };
    downloadSellerAttachment(payload).then((res) => {
      console.log(res);
      if (res.data.url !== undefined) {
        const link = document.createElement("a");
        link.href = res.data.url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    });
  };

  const getValueWithFormat = (sellerDetails) => {
    if (sellerDetails) {
      const filterArray = [
        // deliveryAddress?.ownerName?deliveryAddress?.ownerName:null,
        sellerDetails.address.addressLine1
          ? sellerDetails.address.addressLine1
          : null,
        sellerDetails.address.addressLine2
          ? sellerDetails.address.addressLine2
          : null,
        sellerDetails.address.state ? sellerDetails.address.state : null,
        sellerDetails.address.pinCode ? sellerDetails.address.pinCode : null,
      ];
      // console.log("data??", filterArray);

      const filtered = filterArray.filter(function (el) {
        return el != null;
      });
      const filteredNullStringValues = filtered.filter(Boolean);

      return (
        filteredNullStringValues &&
        filteredNullStringValues.toString().split(",").join(", ")
      );
    }
  };

  const onImageChange = {
    name: "file",
    multiple: false,
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload(info) {
      const isLt2M = info.size / 1024 / 1024 < 4;

      if (!isLt2M) {
        message.error("File size should be less than 4 MB");
        setUploading(false);
        return false;
      } else if (
        !(
          info.type === "image/png" ||
          info.type === "image/jpeg" ||
          info.type === "application/pdf" ||
          info.type === "image/webp"
        )
      ) {
        message.error("You can only upload PDF/JPEG/JPG/PNG images");
        setUploading(false);
        return false;
      }
    },
    onChange(info) {
      if (info.file.status === "done") {
        setUploading(false);
        setTradeLicensePdf(info.file.originFileObj);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setNewImgUrl([
            ...newImgUrl,
            { url: reader.result, file: info.file.originFileObj },
          ]);
        });
        reader.readAsDataURL(info.file.originFileObj);
        return;
      }
    },
  };

  useEffect(() => {
    setisLoading(true);
    if (props?.isAdmin) {
      if (ENV_TYPE === "dev") {
        cookies.remove("needToShow", { path: "/" });
        cookies.remove("needToShowIncomingOrders", { path: "/" });
      } else {
        cookies.remove("needToShow", { path: "/", domain: `.${DOMAIN}` });
        cookies.remove("needToShowIncomingOrders", { path: "/", domain: `.${DOMAIN}` });
      }
    }
    getSellerProfileData();
    getIdProof().then((res) => {
      setisLoading(false);
      if (res.data.status == "success") {
        setProofList(res.data.idProofConfiguration);
      }
    });
  }, []);

  const getSellerProfileData = () => {
    getSellerProfile()
      .then((response) => {
        if (response.data) {
          if (ENV_TYPE == "dev") {
            cookies.set("vendorId", response.data.profile.id, {
              path: "/",
            });
          } else {
            cookies.set("vendorId", response.data.profile.id, {
              path: "/",
              domain: `.${DOMAIN}`,
            });
          }
          if (ENV_TYPE == "dev") {
            cookies.set(
              "printerType",
              response.data?.profile?.source?.printerType,
              {
                path: "/",
              }
            );
          } else {
            cookies.set(
              "printerType",
              response.data?.profile?.source?.printerType,
              {
                path: "/",
                domain: `.${DOMAIN}`,
              }
            );
          }
          if (props.isWarehouse) {
            if (ENV_TYPE == "dev") {
              cookies.set(
                "linkedStores",
                response.data.profile.source.linkedStores,
                {
                  path: "/",
                }
              );
              cookies.set(
                "linkedWarehouse",
                response.data.profile.source.linkedWarehouses,
                {
                  path: "/",
                }
              );
            } else {
              cookies.set(
                "linkedStores",
                response.data.profile.source.linkedStores,
                {
                  path: "/",
                  domain: `.${DOMAIN}`,
                }
              );
              cookies.set(
                "linkedWarehouse",
                response.data.profile.source.linkedWarehouses,
                {
                  path: "/",
                  domain: `.${DOMAIN}`,
                }
              );
            }
          } else if (props.isStore) {
            if (ENV_TYPE == "dev") {
              cookies.set(
                "linkedWarehouse",
                response.data.profile.source.linkedWarehouse,
                {
                  path: "/",
                }
              );
            } else {
              cookies.set(
                "linkedWarehouse",
                response.data.profile.source.linkedWarehouse,
                {
                  path: "/",
                  domain: `.${DOMAIN}`,
                }
              );
            }
          }
          setAdminDetails(response.data.profile);
          setSellerDetails(response.data.profile.source);
          const details = response.data.profile.source;
          setTwoFactorAuth(details.twoFactorAuth? details.twoFactorAuth: false);

          dispatch({ type: "SELLER_STATUS", payload: details.status });
          checkSellerDataToUpdate(response.data.profile.source);
          setSellerName(
            details.address.ownerName ? details.address.ownerName : details.name
          );
          setPhone(
            details.address.phoneNo ? details.address.phoneNo : details.mobile
          );
          setAddress1(details.address ? details.address.addressLine1 : "");
          setAddress2(details.address ? details.address.addressLine2 : "");
          setsellerState(details.address ? details.address.state : "");
          setStoreName(details.storeName);
          setIdProof(details.idProof);
          setserviceLocations(details.serviceablePinCodes);
          setserviceLocationObject(details.serviceLocationObject);
          setIdNumber(details.idProofNumber);
          // generalRef.current.setFieldsValue({
          //   sellerName: details.address?.ownerName
          //     ? details.address.ownerName
          //     : details.name,
          //   communicationEmailId: details.communicationEmail
          //     ? details.communicationEmail
          //     : "",
          //   sellerAddress1: details.address ? details.address.addressLine1 : "",
          //   sellerAddress2: details.address ? details.address.addressLine2 : "",
          //   state: details.address ? details.address.state : "",
          //   setsellerState: details.address ? details.address.state : "",
          //   sellerPhone: details.address.phoneNo
          //     ? details.address.phoneNo
          //     : details.mobile,
          //   sellerPin: details.address?.pinCode
          //     ? details.address.pinCode
          //     : details.pinCode,
          // });

          if (details.image && details.image.length > 0)
            setNewImgSrc(FILE_PATH + details.image[0]);

          setPin(
            details.address.pinCode ? details.address.pinCode : details.pinCode
          );

          if (details.bankAccount) {
            setBank(details.bankAccount.bankName);
            setBranch(details.bankAccount.branch);
            setIfsc(details.bankAccount.ifsc);
            setAccountNumber(details.bankAccount.accountNumber);
            setPan(details.bankAccount.panNo);
          }
          if (details.tradeDetails) {
            setGstNumber(details.tradeDetails.gstNumber);
            setBusinessName(details.tradeDetails.businessName);
            setBusinessLocation(details.tradeDetails.businessLocation);
            setBusinessEmail(details.tradeDetails.businessEmail);
            setTypeOfBusiness(details.tradeDetails.typeOfBusiness);
            setBusinessWebsite(details.tradeDetails.website);
            setGSTType(details?.tradeDetails?.isHRgst);
          }
        }
      })
      .catch(() => {});
  };

  const checkSellerDataToUpdate = (sellerData) => {
    let tempFlag = 0;
    if (
      !sellerData.mobile ||
      !sellerData.email ||
      !sellerData.address.addressLine1 ||
      !sellerData.address.addressLine2 ||
      !sellerData.address.state ||
      !sellerData.address.pinCode ||
      !sellerData.userCode ||
      !sellerData.bankAccount.bankName ||
      !sellerData.bankAccount.branch ||
      !sellerData.bankAccount.accountNumber ||
      !sellerData.bankAccount.ifsc ||
      !sellerData.bankAccount.panNo ||
      !sellerData.idProof ||
      !sellerData.idProofNumber ||
      (props.isAffiliate? false : !sellerData.storeName ||
        !sellerData.communicationEmail ||
        !sellerData.tradeDetails.gstNumber ||
        !sellerData.tradeDetails.businessName ||
        !sellerData.tradeDetails.businessLocation ||
        !sellerData.tradeDetails.businessEmail ||
        !sellerData.tradeDetails.typeOfBusiness ||
        !sellerData.serviceLocationObject.country ||
        !sellerData.serviceLocationObject.state ||
        !sellerData.serviceLocationObject.district ||
        !sellerData.serviceLocationObject.pincode
      )
    ) {
      tempFlag = 1;
    }
    console.log(tempFlag)
    setInfoCount(tempFlag);
  };

  const initializeGeneralInfo = () => {
    form.setFieldsValue({
      email: sellerDetails?.email ? sellerDetails?.email : "",
      sellerName: sellerDetails.name
        ? sellerDetails.name
        : sellerDetails?.address?.ownerName,
      communicationEmailId: sellerDetails?.communicationEmail
        ? sellerDetails?.communicationEmail
        : "",
      sellerPhone: sellerDetails?.address?.phoneNo
        ? sellerDetails.address.phoneNo
        : sellerDetails.mobile,
      sellerAddress1: sellerDetails?.address
        ? sellerDetails.address.addressLine1
        : "",
      sellerAddress2: sellerDetails?.address
        ? sellerDetails.address.addressLine2
        : "",
      state: sellerDetails.address ? sellerDetails.address.state : "",
      sellerPin: sellerDetails?.address?.pinCode
        ? sellerDetails.address.pinCode
        : sellerDetails.pinCode,
    });
  };

  const onFinishAddress = (values) => {
    console.log("values for general info:", values);
    setGeneralEdit(false);
    if (props.isCustomer) {
      const payloadCustomer = {
        id: sellerDetails.memberId,
        version: adminDetails.version,
        accountType: props.isAdmin ? "admin" : "customer",
        name: values.sellerName,
        mobile: values.sellerPhone,
        pinCode: values.sellerPin,
        email: values?.email,
      };
      console.log("values2: ", payloadCustomer);
      if (props.isCustomer) {
        addProfileAdmin(payloadCustomer);
      }
    } else {
      const payload = {
        name: values?.sellerName,
        address: {
          ownerName: values?.sellerName,
          addressLine1: values?.sellerAddress1.trim(),
          addressLine2: values?.sellerAddress2.trim(),
          state: values?.state.trim(),
          pinCode: values?.sellerPin,
          phoneNo: "" + values?.sellerPhone,
        },
        communicationEmail: values?.communicationEmailId,
        email: values.email,
        password: values?.password
      };

      const payloadAdmin = {
        id: sellerDetails.memberId,
        version: adminDetails.version,
        accountType: props.isAdmin ? "admin" : "customer",
        name: values.sellerName,
        address: {
          ownerName: values.sellerName,
          addressLine1: values.sellerAddress1.trim(),
          addressLine2: values.sellerAddress2.trim(),
          state: values.state.trim(),
          pinCode: values.sellerPin,
          phoneNo: values.sellerPhone,
        },
      };

      // setisLoading(true);
      // setGeneralEdit(false);

      if (props.isAdmin) {
        addProfileAdmin(payloadAdmin);
      } else addProfile(payload);
    }
  };

  const handleChangePincode = (value) => {
    setPincodes(value);
  };

  const handleInitialiseStoreDetails = () => {
    form.setFieldsValue({
      storeName: sellerDetails?.storeName ? sellerDetails.storeName : "",
    });
  };

  const handleInitialPrinterDetails = () => {
    form.setFieldsValue({
      printerName: sellerDetails?.printerType ? sellerDetails?.printerType : "",
    });
  };

  const handlInitialiseBankDetails = () => {
    form.setFieldsValue({
      bank: sellerDetails?.bankAccount?.bankName
        ? sellerDetails.bankAccount.bankName
        : "",
      branch: sellerDetails?.bankAccount?.branch
        ? sellerDetails?.bankAccount?.branch
        : "",
      accountNumber: sellerDetails?.bankAccount?.accountNumber
        ? sellerDetails?.bankAccount?.accountNumber
        : "",
      ifsc: sellerDetails?.bankAccount?.ifsc
        ? sellerDetails?.bankAccount?.ifsc
        : "",
      pan: sellerDetails?.bankAccount?.panNo
        ? sellerDetails?.bankAccount?.panNo
        : "",
    });
  };
  const onFinishBank = (values) => {
    console.log("values for bank info:", values);

    const payload = {
      bankAccount: {
        bankName: values.bank,
        branch: values.branch,
        ifsc: values.ifsc,
        accountNumber: values.accountNumber,
        panNo: values.pan,
      },
    };
    setbankLoading(true);
    addProfile(payload);
    form.resetFields();
    setBankEdit(false);
  };

  const handleInitialiseTradeDetails = () => {
    form.setFieldsValue({
      gstNo: sellerDetails?.tradeDetails?.gstNumber
        ? sellerDetails?.tradeDetails?.gstNumber
        : "",
      businessName: sellerDetails?.tradeDetails?.businessName
        ? sellerDetails?.tradeDetails?.businessName
        : "",
      businessLocation: sellerDetails?.tradeDetails?.businessLocation
        ? sellerDetails?.tradeDetails?.businessLocation
        : "",
      businessEmail: sellerDetails?.tradeDetails?.businessEmail
        ? sellerDetails?.tradeDetails?.businessEmail
        : "",
      businessWebsite: sellerDetails?.tradeDetails?.website
        ? sellerDetails?.tradeDetails?.website
        : "",
      typeOfBusiness: sellerDetails?.tradeDetails?.typeOfBusiness
        ? sellerDetails?.tradeDetails?.typeOfBusiness
        : "",
    });
  };

  const onFinishTrade = (values) => {
    console.log("values for trade info:", values);

    const payload = {
      tradeDetails: {
        gstNumber: values.gstNo,
        businessName: values.businessName,
        businessLocation: values.businessLocation,
        businessEmail: values.businessEmail,
        website: values.businessWebsite,
        typeOfBusiness: values.typeOfBusiness,
        windowType: windowGroup,
        isCompanyGst: GSTType,
      },
    };
    settradeLoading(true);
    addProfile(payload, newImgUrl);
    form.resetFields();
    setTradeEdit(false);
  };

  const onFinishStore = (values) => {
    console.log("values for store info:", values);
    const payload = {
      storeName: values.storeName,
    };

    setstoreLoading(true);
    addProfile(payload);
    setStoreEdit(false);
    form.resetFields();
  };

  const onFinishService = (pinocdes, serviceObject) => {
    const payload = {
      serviceablePinCodes: pinocdes,
      serviceLocationObject: serviceObject,
    };
    addProfile(payload);

    setLocationEdit(false);
  };

  const handleInitialisePersonalDetails = () => {
    form.setFieldsValue({
      idName: sellerDetails?.idProof ? sellerDetails.idProof : "",
      idNumber: sellerDetails?.idProofNumber ? sellerDetails.idProofNumber : "",
    });
  };

  const onFinishId = (values) => {
    console.log("values for personal info:", values);
    if (idProof && idNumber && idNumber.match(/^[a-zA-Z0-9]+$/)) {
      const payload = {
        idProof: idProof,
        idProofNumber: idNumber,
      };
      // setpersonalLoading(true);
      addProfile(payload);
      form.resetFields();
      setPersonalEdit(false);
    }
  };

  const onFinishPrinter = (values) => {
    const payload = {
      printerType: printerType,
    };
    addProfile(payload);
    form.resetFields();
    setPrinterEdit(false);
  };

  const addProfile = (payload, imageUrl = []) => {
    setisLoading(true);
    AddSellerProfileAddress(payload, imageUrl)
      .then((res) => {
        if (res.data.status == "success") {
          getSellerProfileData();
          setisLoading(false);
          setbankLoading(false);
          settradeLoading(false);
          setstoreLoading(false);
          setpersonalLoading(false);
          setGeneralEdit(false);
          setBankEdit(false);
          setTradeEdit(false);
          setPersonalEdit(false);
          setPrinterEdit(false);
          cookies.set("printerType", printerType);
          message.success("Updated Profile Successfully!");
        } else message.error(res.data.message);
      })
      .catch((error) => {
        if (error?.response) {
          message.error(error.response.message);
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const addProfileAdmin = (payload) => {
    setisLoading(true);
    AddAdminProfileAddress(payload)
      .then((res) => {
        if (res.data.status == "success") {
          message.success(res?.data?.message);

          getSellerProfileData();
          setGeneralEdit(false);
        } else message.error(res?.data?.message);
      })
      .catch(() => {})
      .finally(() => {
        setisLoading(false);
      });
  };

  const onImageChangePic = {
    name: "file",
    multiple: false,
    customRequest: ({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    beforeUpload(info) {
      const isLt2M = info.size / 1024 / 1024 < 4;

      if (!isLt2M) {
        message.error("File size should be less than 4 MB");

        return false;
      } else if (
        !(
          info.type === "image/png" ||
          info.type === "image/jpeg" ||
          info.type === "image/webp"
        )
      ) {
        message.error("You can only upload JPEG/JPG/PNG images");

        return false;
      } else if (newImgUrl.length > 9) {
        message.error("You can upload only maximum 10 images");

        return false;
      }
    },
    onChange(info) {
      if (info.file.status === "done") {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setNewImgSrc(reader.result);
          setNewImageFile(info.file.originFileObj);
          AddUserProfilePic(info.file.originFileObj).then((res) => {
            if (res.data.status == "success") {
            } else {
            }
          });
        });
        reader.readAsDataURL(info.file.originFileObj);
        return;
      }
    },
  };

  const PhoneValidator = (rule, value, callback) => {
    if (value && value !== undefined && value.length > 0 && value.length < 12) {
      callback("Enter valid phone number");
    } else {
      callback();
    }
  };

  const NameValidator = (rule, value, callback) => {
    if (value && !value.match(/^[a-zA-Z\s ]+$/) && value.length > 0) {
      callback("Special Character not allowed");
    } else {
      callback();
    }
  };

  const IfscValidation = (rule, value, callback) => {
    if (
      value &&
      !value.match(/^[A-Za-z]{4}0[A-Z0-9]{6}$/) &&
      value.length > 0
    ) {
      callback("Invalid IFSC Code");
    } else {
      callback();
    }
  };

  const StoreNameValidator = (rule, value, callback) => {
    if (
      value &&
      !value.match(/^[a-zA-Z0-9 -'()&%/+:.]+$/) &&
      value.length > 0
    ) {
      callback("Special Character not allowed");
    } else {
      callback();
    }
  };

  const NumberValidator = (rule, value, callback) => {
    if (value && !value.match(/^[0-9]+$/) && value.length > 0) {
      callback("Alphabets/Special Character not allowed");
    } else {
      callback();
    }
  };

  const AlphaNumValidator = (rule, value, callback) => {
    if (value && !value.match(/^[a-zA-Z0-9]+$/) && value.length > 0) {
      callback("Special Character not allowed");
    } else {
      callback();
    }
  };

  const stateNameValidator = (rule, value, callback) => {
    if (value && !value.match(/^[a-zA-Z& ]+$/) && value.length > 0) {
      callback("Please Enter a valid State.");
    } else {
      callback();
    }
  };

  const handleFormfinish = (values) => {
    if (generalEdit) {
      onFinishAddress(values);
    } else if (personalEdit) {
      onFinishId(values);
    } else if (tradeEdit) {
      onFinishTrade(values);
    } else if (bankEdit) {
      onFinishBank(values);
    } else if (storeEdit) {
      onFinishStore(values);
    } else if (printerEdit) {
      onFinishPrinter(values);
    }
  };

  const handleTriggerEdit = (section) => {
    switch (section) {
      case "general":
        setGeneralEdit(true);
        setPersonalEdit(false);
        setTradeEdit(false);
        setBankEdit(false);
        setStoreEdit(false);
        setPrinterEdit(false);
        break;

      case "printer":
        setGeneralEdit(false);
        setPersonalEdit(false);
        setTradeEdit(false);
        setBankEdit(false);
        setStoreEdit(false);
        setPrinterEdit(true);
        break;

      case "personal":
        setGeneralEdit(false);
        setPersonalEdit(true);
        setTradeEdit(false);
        setBankEdit(false);
        setStoreEdit(false);
        setPrinterEdit(false);
        break;
      case "trade":
        setGeneralEdit(false);
        setPersonalEdit(false);
        setTradeEdit(true);
        setBankEdit(false);
        setStoreEdit(false);
        setPrinterEdit(false);
        break;

      case "bank":
        setGeneralEdit(false);
        setPersonalEdit(false);
        setTradeEdit(false);
        setBankEdit(true);
        setStoreEdit(false);
        setPrinterEdit(false);
        break;

      case "store":
        setGeneralEdit(false);
        setPersonalEdit(false);
        setTradeEdit(false);
        setBankEdit(false);
        setStoreEdit(true);
        setPrinterEdit(false);
        break;
      default: {
      }
    }
  };

  const handleVerifyPassword = (values) => {
    console.log(values);
    AddSellerProfileAddress({
      twoFactorAuth: !twoFactorAuth,
      password: values.confirmPassword
    }, [])
    .then((res) => {
      if (res.data.status == "success") {
        getSellerProfileData();
        message.success((!twoFactorAuth? "Enabled" : "Disbaled") + " Two Factor Successfully!");
      } else message.error("Incorrect password");
    })
    .catch((error) => {
      if (error?.response) {
        setTwoFactorAuth(!twoFactorAuth)
        message.error(error.response.message);
      }
    })
    .finally(() => {
      setisLoading(false);
      setPasswordModalVisible(false);
      form.resetFields();
    });
  }

  //* For getting platform warning
  useEffect(() => {
    if (props?.isStore) {
      const payload = {
        vendorId: cookies.get("vendorId"),
      };
      fetchPlatformChargeExceed(payload)
        .then((response) => {
          if (response?.data?.status === "success") {
            if (cookies.get("needToShow") !== "false") {
              setPlatformData(response?.data?.documents[0]);
              setNeedToShow(true);
            } else {
              setNeedToShow(false);
            }
          }
        })
        .catch()
        .finally();
    }

    const incomingOrdersPayload = {
      userId: cookies.get("vendorId"),
      vendorOrderStatuses: "PENDING"
    }
    if (props?.isStore || props?.location.pathname.includes("seller")) {
      getCustomerOrders(incomingOrdersPayload)
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.orders && response.data.orders.length > 0 &&
              cookies.get("needToShowIncomingOrders") !== "false") {
              setNeedToShowIncomingOrders(true);
              setIncomingOrders(response.data.orders.length);
            } else {
              setNeedToShowIncomingOrders(false);
            }
          }
        })
        .catch(() => {});
    }

  }, [cookies.get("vendorId")]);

  // For Getting total platform notification
  useEffect(() => {
    if (props?.isAdmin) {
      const payload = {
        vendorKey: "",
        searchKey: -1,
      };
      fetchAllExceededPlatformCharge(payload)
        .then((response) => {
          if (response?.data?.status === "success") {
            let totalBlocks = response?.data?.documents?.filter(
              (data) => !data?.blocked && data?.platformAmount >= 1000
            );
            setTotalNotification(totalBlocks?.length);
          }
        })
        .catch((error) => {
          console.log(error?.message);
        })
        .finally();
    }
  }, []);

  return (
    <div className="store-profile">
      {/* {!props.isCustomer && (
        <div className="admin-banner-container">
          <div className="admin-banner-announcement-banner">
            <p>ANNOUNCEMENT</p>
          </div>
          <div className="admin-banner-image-container">
            <img src={Banner} className="banner-img-admin" alt="banner" /> */}
      {/* <button className="admin-banner-ok-button">Got It</button> */}
      {/* </div>
          <div className="admin-banner-text-container">
            <h2 style={{fontWeight: "bold"}}>Announcement about System Update</h2>
            <p>Dear loremIpsum family members, Due to scheduled maintenance activities, loremIpsum Online Shoppe's website and panel services may be affected between 9:00PM (27/08/2021)- 6:00AM (28/08/2021).</p>
          </div>
        </div>
    )}*/}

      {!props.isStore &&
        !props.isWarehouse &&
        !props.isAdmin &&
        !props.isCustomer &&
        showTutorialModal && (
          <div className="seller-tutorial-modal">
            <p
              className="seller-tutorial-modal-close-button"
              onClick={() => setShowTutorialModal(!showTutorialModal)}
            >
              <IoIosCloseCircle style={{ fontSize: "1rem" }} /> CLOSE
            </p>
            <div className="seller-tutorial-modal-video-box">
              <div itemscope itemtype="https://schema.org/VideoObject">
                <meta
                  itemprop="uploadDate"
                  content="Sat Jul 03 2021 16:27:12 GMT+0530 (India Standard Time)"
                />
                <meta
                  itemprop="name"
                  content="Spoken English Module 9 (Malayalam)"
                />
                <meta itemprop="duration" content="P0Y0M0DT1H1M38S" />
                <meta
                  itemprop="thumbnailUrl"
                  content="https://content.jwplatform.com/thumbs/Kcg5PSOs-1920.jpg"
                />
                <meta
                  itemprop="contentUrl"
                  content="https://content.jwplatform.com/videos/Kcg5PSOs-nkitx7Tx.mp4"
                />
                <div
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    paddingBottom: "56.25%",
                  }}
                >
                  <iframe
                    src="https://cdn.jwplayer.com/players/Kcg5PSOs-pZoy4RUe.html"
                    width="100%"
                    height="100%"
                    frameborder="0"
                    scrolling="auto"
                    title="Spoken English Module 9 (Malayalam)"
                    style={{ position: "absolute" }}
                    autostart="false"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        )}

      {/* {!props.isStore && !props.isWarehouse && !props.isAdmin && !props.isCustomer && (
        <div className="tutorial-video-button" onClick={() => setShowTutorialModal(!showTutorialModal)}>
          <FaPlay style={{ position: "absolute", left: "50%", top: "25%", color: "#fff", fontSize: "2rem", transform: "translateX(-50%)" }} /> */}
      {/* <IoVideocam style={{fontSize: "2em"}} /> QUICK TOUR */}
      {/* <img alt="thumbnail" src="https://content.jwplatform.com/thumbs/Kcg5PSOs-1920.jpg" style={{borderRadius: "0.5em"}} />
          <p>Take a quick tour</p>
        </div>
      )} edit here for video */}

      {!props.isStore &&
        !props.isWarehouse &&
        !props.isAdmin &&
        !props.isCustomer &&
        infoCount > 0 &&
        sellerDetails && (
          <div className="show-update-warning-box-profile">
            <p>
              <FaClipboard /> Hello <strong>{sellerDetails?.name}</strong>,
              please update all the information for Profile Approval.
            </p>
          </div>
        )}

      <Form onFinish={handleFormfinish} form={form}>
        <div
          className={
            props.isCustomer
              ? "store-profile-section-avatar store-profile-section"
              : "store-profile-section"
          }
        >
          {props.isCustomer && (
            <div className="profile-icon-user">
              <Avatar src={newImgSrc} size={100} icon={<UserOutlined />} />
              <Upload {...onImageChangePic} showUploadList={false}>
                <CloudUploadOutlined className="profile-icon-upolad" />
              </Upload>
            </div>
          )}
          {/* <Form name="control-ref" onFinish={onFinishAddress} form={form}> */}
          <div className="seller-profile-section-title">
            <div className="d_flex">
              <h1 className="sub-title-tags">
                {" "}
                <BiFolderOpen /> General Information{" "}
              </h1>
              {!props.isAdmin && !props.isCustomer && (
                <div className="verified-icon">
                  {sellerDetails?.status === "APPROVED" ? (
                    <h5 className="verified-text-profile">VERIFIED</h5>
                  ) : (
                    <h5 className="pending-text-profile">PENDING</h5>
                  )}
                </div>
              )}
            </div>

            {!generalEdit && (
              <Button
                className="profile-edit-button update-button-only"
                disabled={props.isCustomer || props.isAdmin || props.isAffiliate? false : cookies.get("switchuser")=="false"}
                onClick={() => {
                  // setGeneralEdit(true);
                  handleTriggerEdit("general");
                  initializeGeneralInfo();
                }}
              >
                <FiRefreshCw /> Update
              </Button>
            )}
          </div>
          <div className="merchent-section-content">
            {!generalEdit ? (
              sellerDetails &&
              (isLoading ? (
                <Spin
                  indicator={antIcon}
                  style={{
                    width: "100%",
                    height: "10em",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              ) : (
                <Fragment>
                  <div className="profile-section-container">
                    <div className="section-each-tile">
                      <p className="section-label-name">Display name</p>
                      <p className="section-label-value">
                        {sellerDetails?.name}
                      </p>
                    </div>

                    <div className="section-each-tile">
                      <p className="section-label-name">User code</p>
                      <p className="section-label-value">
                        {sellerDetails?.userCode}
                      </p>
                    </div>

                    {props.isAdmin ? (
                      <div className="section-each-tile">
                        <p className="section-label-name">Phone number</p>
                        {sellerDetails?.address?.phoneNo ? (
                          <p className="section-label-value">
                            +{sellerDetails?.address?.phoneNo}
                          </p>
                        ) : (
                          <Fragment>
                            {sellerDetails?.mobile ? (
                              <p className="section-label-value">
                                +{sellerDetails?.mobile}
                              </p>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  handleTriggerEdit("general");
                                  initializeGeneralInfo();
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </Fragment>
                        )}
                      </div>
                    ) : (
                      // this section is for showing store , seller and warehouse phone number
                      <div className="section-each-tile">
                        <p className="section-label-name">Phone number</p>
                        {sellerDetails?.address?.phoneNo ? (
                          <p className="section-label-value">
                            +
                            {sellerDetails?.address?.phoneNo
                              ? sellerDetails?.address?.phoneNo
                              : sellerDetails?.mobile}
                          </p>
                        ) : // this section is for showing customers phone number
                        sellerDetails?.mobile ? (
                          <p className="section-label-value">
                            +{sellerDetails?.mobile && sellerDetails?.mobile}
                          </p>
                        ) : (
                          <Fragment>
                            <Button
                              className="section-label-value-update"
                              onClick={() => {
                                handleTriggerEdit("general");
                                initializeGeneralInfo();
                              }}
                            >
                              UPDATE
                            </Button>
                          </Fragment>
                        )}
                      </div>
                    )}

                    <div className="section-each-tile">
                      <p className="section-label-name">Email</p>
                      {sellerDetails?.email ? (
                        <p className="section-label-value">
                          {sellerDetails?.email}
                        </p>
                      ) : (
                        <Fragment>
                          <Button
                            className="section-label-value-update"
                            onClick={() => {
                              // setGeneralEdit(true);
                              handleTriggerEdit("general");
                              initializeGeneralInfo();
                            }}
                          >
                            UPDATE
                          </Button>
                        </Fragment>
                      )}
                    </div>

                    {!props.isAdmin && !props.isCustomer && !props.isAffiliate && (
                      <div className="section-each-tile">
                        <p className="section-label-name">
                          Communication Email
                        </p>
                        {sellerDetails?.communicationEmail ? (
                          <p className="section-label-value">
                            {sellerDetails?.communicationEmail}
                          </p>
                        ) : (
                          <Fragment>
                            <Button
                              className="section-label-value-update"
                              onClick={() => {
                                // setGeneralEdit(true);
                                handleTriggerEdit("general");
                                initializeGeneralInfo();
                              }}
                            >
                              UPDATE
                            </Button>
                          </Fragment>
                        )}
                      </div>
                    )}

                    {!props.isCustomer && (
                      <Fragment>
                        <div className="section-each-tile">
                          <p className="section-label-name">Address Line 1</p>
                          {sellerDetails?.address?.addressLine1 ? (
                            <p className="section-label-value">
                              {sellerDetails?.address?.addressLine1}
                            </p>
                          ) : (
                            <Fragment>
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("general");
                                  initializeGeneralInfo();
                                }}
                              >
                                UPDATE
                              </Button>
                            </Fragment>
                          )}
                        </div>

                        <div className="section-each-tile">
                          <p className="section-label-name">Address Line 2</p>
                          {sellerDetails?.address?.addressLine2 ? (
                            <p className="section-label-value">
                              {sellerDetails?.address?.addressLine2}
                            </p>
                          ) : (
                            <Fragment>
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("general");
                                  initializeGeneralInfo();
                                }}
                              >
                                UPDATE
                              </Button>
                            </Fragment>
                          )}
                        </div>

                        <div className="section-each-tile">
                          <p className="section-label-name">State</p>
                          {sellerDetails?.address?.state ? (
                            <p className="section-label-value">
                              {sellerDetails?.address?.state}
                            </p>
                          ) : (
                            <Fragment>
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("general");
                                  initializeGeneralInfo();
                                }}
                              >
                                UPDATE
                              </Button>
                            </Fragment>
                          )}
                        </div>

                        <div className="section-each-tile">
                          <p className="section-label-name">Pincode</p>
                          {sellerDetails?.address?.pinCode ? (
                            <p className="section-label-value">
                              {sellerDetails?.address?.pinCode}
                            </p>
                          ) : (
                            <Fragment>
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("general");
                                  initializeGeneralInfo();
                                }}
                              >
                                UPDATE
                              </Button>
                            </Fragment>
                          )}
                        </div>

                        {(props.isStore || props.isSeller) && (
                          <div className="section-each-tile">
                            <p className="section-label-name">Two Factor Authentication</p>

                              <Fragment>
                                <Switch
                                  // className="section-label-value-update"
                                  style={{width: "fit-content"}}
                                  checked={twoFactorAuth}
                                  onChange={(e) => setPasswordModalVisible(true)}
                                />
                              </Fragment>

                          </div>
                        )}
                        <Modal
                          visible={showPasswordModal}
                          onCancel={() => setPasswordModalVisible(false)}
                          onOk={() => form.submit()}
                        >
                          <Form form={form} onFinish={handleVerifyPassword}>
                            <Text>Enter Password</Text>
                            <Form.Item
                              name="confirmPassword"
                              rules={[
                                {
                                  required: true,
                                  message: "Account password required for verification"
                                }
                              ]}>
                              <Input.Password
                                style={{ marginTop: "5px" }}
                                placeholder={"Confirm Password"}
                                required={true}
                              />
                            </Form.Item>
                          </Form>
                        </Modal>
                      </Fragment>
                    )}
                  </div>
                </Fragment>
              ))
            ) : (
              <React.Fragment>
                {/* edit starts here */}
                <div className="updateInfoSection">
                  <div className="updateInfoSectionBox">
                    <p className="updateInfoTitle">Display Name</p>
                    <Form.Item
                      name="sellerName"
                      rules={[
                        {
                          required: true,
                          message: "This field is required !",
                        },
                        {
                          validator: NameValidator,
                        },
                      ]}
                    >
                      <Input
                        className="updateInfoInput"
                        placeholder={props.isAdmin ? "Name" : "Display Name"}
                        onChange={(e) => setSellerName(e.target.value)}
                        disabled={cookies.get("switchuser")=="false"}
                      />
                    </Form.Item>
                  </div>

                  {/* {props.isCustomer && ( */}
                  <div className="updateInfoSectionBox">
                    <p className="updateInfoTitle">Email</p>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "This field is required !",
                        },
                        {
                          type: "email",
                          message: "Enter a valid email Id",
                        },
                      ]}
                    >
                      <Input
                        className="updateInfoInput"
                        placeholder={"Email"}
                        disabled={props.isCustomer? false : cookies.get("switchuser")=="false"}
                        // onChange={(e) => setSellerName(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                  {/* )} */}
                  {!props.isCustomer && !props.isAdmin && !props.isAffiliate && (
                    <div className="updateInfoSectionBox">
                      <p className="updateInfoTitle">Communication Email</p>
                      <Form.Item
                        name="communicationEmailId"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                        ]}
                      >
                        <Input
                          className="updateInfoInput"
                          placeholder={"Communication Email"}
                          onChange={(e) => setSellerName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  )}
                  {!props.isCustomer && (
                    <Fragment>
                      <div className="updateInfoSectionBox">
                        <p className="updateInfoTitle">Address Line 1</p>
                        <Form.Item
                          name="sellerAddress1"
                          rules={[
                            {
                              required: true,
                              message: "Address is required.",
                            },
                          ]}
                        >
                          <Input
                            className="updateInfoInput"
                            placeholder="Address"
                            onChange={(e) => setAddress1(e.target.value)}
                          />
                        </Form.Item>
                      </div>

                      <div className="updateInfoSectionBox">
                        <p className="updateInfoTitle">Address Line 2</p>
                        <Form.Item
                          name="sellerAddress2"
                          rules={[
                            {
                              required: true,
                              message: "Address is required.",
                            },
                          ]}
                        >
                          <Input
                            className="updateInfoInput"
                            placeholder="Address"
                            onChange={(e) => setAddress2(e.target.value)}
                          />
                        </Form.Item>
                      </div>

                      <div className="updateInfoSectionBox">
                        <p className="updateInfoTitle">
                          State ({sellerDetails?.address?.state})
                        </p>
                        <Form.Item
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: "State is required.",
                            },
                            {
                              validator: stateNameValidator,
                            },
                          ]}
                        >
                          <select
                            className="updateInfoInput selectArrow"
                            name="state"
                          >
                            {stateSuggestions.map((state, index) => (
                              <option>{state}</option>
                            ))}
                          </select>
                          {/* <Input
                              className="updateInfoInput"
                              placeholder="State"
                              onChange={(e) => setsellerState(e.target.value)}
                            /> */}
                        </Form.Item>
                      </div>
                    </Fragment>
                  )}
                  <div className="updateInfoSectionBox">
                    <p className="updateInfoTitle">Pincode</p>
                    <Form.Item
                      name="sellerPin"
                      rules={[
                        {
                          required: true,
                          message: "This field is required !",
                        },
                        {
                          pattern: new RegExp("^[1-9][0-9]{5}$"),
                          message: "Enter a valid pincode",
                        },
                      ]}
                    >
                      <Input
                        className="updateInfoInput"
                        placeholder="Pincode"
                        type="text"
                        maxLength={6}
                        onChange={(e) => setPin(e.target.value)}
                      />
                    </Form.Item>
                  </div>
                  <div className="updateInfoSectionBox">
                    <p className="updateInfoTitle">Phone number</p>
                    <Form.Item
                      name="sellerPhone"
                      rules={[
                        {
                          required: true,
                          message: "Phone number is required.",
                        },
                        {
                          validator: PhoneValidator,
                        },
                      ]}
                    >
                      <PhoneInput
                        className="updateInfoInput"
                        country={"in"}
                        placeholder="Phone Number"
                        countryCodeEditable={false}
                        width="100"
                        onChange={(phone) => setPhone(phone)}
                      />
                    </Form.Item>
                  </div>
                  {changePassword && (
                    <div className="updateInfoSectionBox">
                      <p className="updateInfoTitle">New Password</p>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "Password is required.",
                          }
                        ]}
                      >
                        <Input
                          className="updateInfoInput"
                          placeholder="New Password"
                          type="text"
                          maxLength={20}
                          minLength={6}
                          onChange={(e) => setPin(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  )}
                </div>
                {/* edit ends here */}
                {/* <div className="seller-profile-tile">
                    <p class="required">{"Display Name:"}</p>
                    <Form.Item
                      name="sellerName"
                      rules={[
                        {
                          required: true,
                          message: "This field is required !",
                        },
                        {
                          validator: NameValidator,
                        },
                      ]}
                    >
                      <Input
                        placeholder={props.isAdmin ? "Name" : "Display Name"}
                        onChange={(e) => setSellerName(e.target.value)}
                      />
                    </Form.Item>
                  </div> */}

                {/* {props.isCustomer && (
                    <div className="seller-profile-tile">
                      <p class="required">{"Email:"}</p>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            type: "email",
                            message: "Enter a valid email Id",
                          },
                        ]}
                      >
                        <Input
                          placeholder={"Email"}
                        // onChange={(e) => setSellerName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  )} */}
                {/* <div className="seller-profile-tile admin-profile-phone">
                    <p class="required">{"Phone Number:"}</p>
                    <Form.Item
                      name="sellerPhone"
                      rules={[
                        {
                          required: true,
                          message: "Phone number is required.",
                        },
                        {
                          validator: PhoneValidator,
                        },
                      ]}
                    >
                      <PhoneInput
                        country={"in"}
                        placeholder="Phone Number"
                        countryCodeEditable={false}
                        onChange={(phone) => setPhone(phone)}
                      />
                    </Form.Item>
                  </div> */}
                {/* {!props.isCustomer && !props.isAdmin && (
                    <div className="seller-profile-tile">
                      <p class="required">{"Communication Email:"}</p>
                      <Form.Item
                        name="communicationEmailId"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address!",
                          },
                        ]}
                      >
                        <Input
                          placeholder={"Communication Email"}
                          onChange={(e) => setSellerName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  )} */}

                {/* {!props.isCustomer && (
                    <div className="seller-profile-tile">
                      <p class="required">{"Address Line1:"}</p>
                      <Form.Item
                        name="sellerAddress1"
                        rules={[
                          {
                            required: true,
                            message: "Address is required.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Address"
                          onChange={(e) => setAddress1(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  )} */}
                {/* {!props.isCustomer && (
                    <div className="seller-profile-tile">
                      <p class="required">{"Address Line2:"}</p>
                      <Form.Item
                        name="sellerAddress2"
                        rules={[
                          {
                            required: true,
                            message: "Address is required.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Address"
                          onChange={(e) => setAddress2(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  )} */}
                {/* {!props.isCustomer && (
                    <div className="seller-profile-tile">
                      <p class="required">{"State:"}</p>
                      <Form.Item
                        name="state"
                        rules={[
                          {
                            required: true,
                            message: "State is required.",
                          },
                          {
                            validator: stateNameValidator,
                          },
                        ]}
                      >
                        <Input
                          placeholder="State"
                          onChange={(e) => setsellerState(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  )} */}
                {/* <div className="seller-profile-tile">
                    <p class="required">{"Pincode:"}</p>
                    <Form.Item
                      name="sellerPin"
                      rules={[
                        {
                          required: true,
                          message: "This field is required !",
                        },
                        {
                          pattern: new RegExp("^[1-9][0-9]{5}$"),
                          message: "Enter a valid pincode",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Pincode"
                        type="text"
                        maxLength={6}
                        onChange={(e) => setPin(e.target.value)}
                      />
                    </Form.Item>
                  </div> */}
              </React.Fragment>
            )}
            {generalEdit && (
              <>{cookies.get("switchuser") == "true" && (
                <div>
                  <Button
                    className="profile-edit-cancel-button"
                    onClick={() => {
                      changePassword? setChangePassword(false) :
                      setChangePassword(true)
                    }}>
                    {changePassword? "Cancel":"Change Password"}
                  </Button>
                </div>
              )}
              <div className="profile-save-and-cancel-box">
                <Button
                  className="profile-edit-cancel-button"
                  onClick={() => {
                    setGeneralEdit(false);
                    setChangePassword(false);
                    form.resetFields();
                  } }
                  style={{ background: "none", border: "none" }}
                >
                  CANCEL
                </Button>
                <Form.Item>
                  <Button
                    className="profile-edit-save-button"
                    htmlType="submit"
                    style={{ background: "none", border: "none" }}
                  >
                    <IoMdSave /> SAVE
                  </Button>
                </Form.Item>
              </div></>
            )}
          </div>
          {/* </Form> */}
        </div>

        {/* {props.isStore && (
          <div className="store-profile-section"> */}
        {/* <Form form={form} onFinish={onFinishId}> */}
        {/* <div className="seller-profile-section-title">
              <div className="d_flex">
                <h3 className="sub-title-tags">
                  {" "}
                  <BiFolderOpen /> Printer Details{" "}
                </h3>
                <div className="verified-icon">
                  <h5 className="new-label-profile">NEW</h5>
                </div>
              </div>

              <div className="edit-section">
                {!printerEdit && (
                  <Button
                    className="profile-edit-button"
                    onClick={() => {
                      handleTriggerEdit("printer");
                      handleInitialPrinterDetails();
                    }}
                    style={{ background: "none", border: "none" }}
                  >
                    <FiRefreshCw /> Update
                  </Button>
                )}
              </div>
            </div>
            <div className="merchent-section-content">
              {!printerEdit ? (
                sellerDetails &&
                (personalLoading ? (
                  <Loading style={{ color: "#3e6df3" }} />
                ) : (
                  <Fragment>
                    <div className="profile-section-container">
                      <div className="section-each-tile">
                        <p className="section-label-name">Selected Printer</p>
                        {sellerDetails?.printerType ? (
                          <p className="section-label-value">
                            {sellerDetails?.printerType}
                          </p>
                        ) : (
                          <Button
                            className="section-label-value-update"
                            onClick={() => {
                              // setGeneralEdit(true);
                              handleTriggerEdit("printer");
                              handleInitialPrinterDetails();
                            }}
                          >
                            UPDATE
                          </Button>
                        )}
                      </div>
                    </div>
                  </Fragment>
                ))
              ) : (
                <React.Fragment>
                  <div className="seller-profile-tile">
                    <p class="required">{"Select Printer Type"}</p>
                    <Form.Item
                      name="printerName"
                      rules={[
                        {
                          required: true,
                          message: "This field is required !",
                        },
                      ]}
                    >
                      <Select
                        onSelect={(value, option) =>
                          setPrinterType(option.value)
                        }
                        style={{ width: "100%" }}
                        defaultValue={printerOptions[0].printerValue}
                      >
                        {printerOptions.map((printer, index) => {
                          return (
                            <Option key={index} value={printer.printerValue}>
                              {printer.printerName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </React.Fragment>
              )}
              {printerEdit && (
                <div className="profile-save-and-cancel-box">
                  <Button
                    className="profile-edit-cancel-button"
                    onClick={() => {
                      setPrinterEdit(false);
                      form.resetFields();
                    }}
                  >
                    CANCEL
                  </Button>
                  <Form.Item>
                    <Button
                      className="profile-edit-save-button"
                      htmlType="submit"
                      onClick={() => {
                        onFinishPrinter();
                      }}
                    >
                      <IoMdSave /> SAVE
                    </Button>
                  </Form.Item>
                </div>
              )}
            </div> */}
        {/* </Form> */}
        {/* </div>
        )} */}

        {!props.isAdmin && !props.isCustomer && (
          <React.Fragment>
            {!props.isAffiliate && (
              <div className="store-profile-section">
              {/* <Form form={form} onFinish={onFinishStore}> */}
              <div className="seller-profile-section-title">
                <div className="d_flex">
                  <h3 className="sub-title-tags">
                    {" "}
                    <BiFolderOpen /> Store Details{" "}
                  </h3>
                </div>

                <div className="edit-section">
                  {!storeEdit && (
                    <Button
                      className="profile-edit-button"
                      disabled={cookies.get("switchuser") == "false"}
                      onClick={() => {
                        // setStoreEdit(true);
                        handleInitialiseStoreDetails();
                        handleTriggerEdit("store");
                      }}
                      style={{ background: "none", border: "none" }}
                    >
                      <FiRefreshCw /> Update
                    </Button>
                  )}
                </div>
              </div>

              <div className="merchent-section-content">
                {!storeEdit ? (
                  sellerDetails &&
                  (storeLoading ? (
                    <Loading style={{ color: "#3e6df3" }} />
                  ) : (
                    <Fragment>
                      <div className="profile-section-container">
                        <div className="section-each-tile">
                          <p className="section-label-name">Store Name</p>
                          {sellerDetails?.storeName ? (
                            <p className="section-label-value">
                              {sellerDetails?.storeName}
                            </p>
                          ) : (
                            <Button
                              className="section-label-value-update"
                              onClick={() => {
                                // setGeneralEdit(true);
                                handleInitialiseStoreDetails();
                                handleTriggerEdit("store");
                              }}
                            >
                              UPDATE
                            </Button>
                          )}
                        </div>

                        <div className="section-each-tile">
                          <p className="section-label-name">Store ID</p>
                          {sellerDetails?.userCode ? (
                            <p className="section-label-value">
                              {sellerDetails?.userCode}
                            </p>
                          ) : (
                            <Button
                              className="section-label-value-update"
                              onClick={() => {
                                // setGeneralEdit(true);
                                handleInitialiseStoreDetails();
                                handleTriggerEdit("store");
                              }}
                            >
                              UPDATE
                            </Button>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  ))
                ) : (
                  <Fragment>
                    <div className="seller-profile-tile">
                      <p class="required">{"Store Name:"}</p>
                      <Form.Item
                        name="storeName"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            validator: StoreNameValidator,
                          },
                        ]}
                      >
                        <Input
                          placeholder={storeName ? storeName : "Store Name"}
                          defaultValue={
                            storeName ? storeName : sellerDetails.storeName
                          }
                          // value={storeName}
                          onChange={(e) => setStoreName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </Fragment>
                )}

                {storeEdit && (
                  <div className="profile-save-and-cancel-box">
                    <Button
                      className="profile-edit-cancel-button"
                      onClick={() => {
                        setStoreEdit(false);
                        form.resetFields();
                        setStoreName(sellerDetails.storeName);
                      }}
                    >
                      CANCEL
                    </Button>
                    <Form.Item>
                      <Button
                        className="profile-edit-save-button"
                        htmlType="submit"
                      >
                        <IoMdSave /> SAVE
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </div>
              {/* </Form> */}
              </div>
            )}

            <div className="store-profile-section">
              {/* <Form form={form} onFinish={onFinishBank}> */}
              <div className="seller-profile-section-title">
                <div className="d_flex">
                  <h3 className="sub-title-tags">
                    {" "}
                    <BiFolderOpen /> Bank Details{" "}
                  </h3>
                </div>

                <div className="edit-section">
                  {!bankEdit && (
                    <Button
                      className="profile-edit-button"
                      onClick={() => {
                        // setBankEdit(true);
                        handleTriggerEdit("bank");
                        handlInitialiseBankDetails();
                      }}
                      style={{ background: "none", border: "none" }}
                    >
                      <FiRefreshCw /> Update
                    </Button>
                  )}
                </div>
              </div>

              <div className="merchent-section-content">
                {!bankEdit ? (
                  sellerDetails &&
                  (bankLoading ? (
                    <Loading style={{ color: "#3e6df3" }} />
                  ) : (
                    <div className="profile-section-container">
                      <div className="section-each-tile">
                        <p className="section-label-name">Bank Name</p>
                        {sellerDetails?.bankAccount?.bankName ? (
                          <p className="section-label-value">
                            {sellerDetails?.bankAccount?.bankName}
                          </p>
                        ) : (
                          <Button
                            className="section-label-value-update"
                            onClick={() => {
                              // setGeneralEdit(true);
                              handleTriggerEdit("bank");
                              handlInitialiseBankDetails();
                            }}
                          >
                            UPDATE
                          </Button>
                        )}
                      </div>

                      <div className="section-each-tile">
                        <p className="section-label-name">Branch</p>
                        {sellerDetails?.bankAccount?.branch ? (
                          <p className="section-label-value">
                            {sellerDetails?.bankAccount?.branch}
                          </p>
                        ) : (
                          <Button
                            className="section-label-value-update"
                            onClick={() => {
                              // setGeneralEdit(true);
                              handleTriggerEdit("bank");
                              handlInitialiseBankDetails();
                            }}
                          >
                            UPDATE
                          </Button>
                        )}
                      </div>

                      <div className="section-each-tile">
                        <p className="section-label-name">Account Number</p>
                        {sellerDetails?.bankAccount?.accountNumber ? (
                          <p className="section-label-value">
                            {sellerDetails?.bankAccount?.accountNumber}
                          </p>
                        ) : (
                          <Button
                            className="section-label-value-update"
                            onClick={() => {
                              // setGeneralEdit(true);
                              handleTriggerEdit("bank");
                              handlInitialiseBankDetails();
                            }}
                          >
                            UPDATE
                          </Button>
                        )}
                      </div>

                      <div className="section-each-tile">
                        <p className="section-label-name">Bank IFSC</p>
                        {sellerDetails?.bankAccount?.ifsc ? (
                          <p className="section-label-value field-uppercase">
                            {sellerDetails?.bankAccount?.ifsc}
                          </p>
                        ) : (
                          <Button
                            className="section-label-value-update"
                            onClick={() => {
                              // setGeneralEdit(true);
                              handleTriggerEdit("bank");
                              handlInitialiseBankDetails();
                            }}
                          >
                            UPDATE
                          </Button>
                        )}
                      </div>

                      <div className="section-each-tile">
                        <p className="section-label-name">PAN Number</p>
                        {sellerDetails?.bankAccount?.panNo ? (
                          <p className="section-label-value field-uppercase">
                            {sellerDetails?.bankAccount?.panNo}
                          </p>
                        ) : (
                          <Button
                            className="section-label-value-update"
                            onClick={() => {
                              // setGeneralEdit(true);
                              handleTriggerEdit("bank");
                              handlInitialiseBankDetails();
                            }}
                          >
                            UPDATE
                          </Button>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <Fragment>
                    <div className="seller-profile-tile">
                      <p class="required">{"Bank Name:"}</p>
                      <Form.Item
                        name="bank"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          // {
                          //   validator: NameValidator,
                          // },
                          {
                            pattern: new RegExp("^[a-zA-Z0-9 ]+$"),
                            message: "Special characters not allowed!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Bank name"
                          value={bank}
                          defaultValue={sellerDetails?.bankAccount?.bankName}
                          onChange={(e) => setBank(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="seller-profile-tile">
                      <p class="required">{"Branch:"}</p>
                      <Form.Item
                        name="branch"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            validator: NameValidator,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Branch"
                          value={branch}
                          onChange={(e) => setBranch(e.target.value)}
                          defaultValue={sellerDetails?.bankAccount?.branch}
                        />
                      </Form.Item>
                    </div>
                    <div className="seller-profile-tile">
                      <p class="required">{"IFSC :"}</p>
                      <Form.Item
                        name="ifsc"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            validator: IfscValidation,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Ifsc"
                          value={ifsc}
                          onChange={(e) => {
                            setIfsc(e.target.value);
                          }}
                          defaultValue={sellerDetails?.bankAccount?.ifsc}
                          className="field-uppercase"
                        />
                      </Form.Item>
                    </div>
                    <div className="seller-profile-tile">
                      <p class="required">{"Account Number:"}</p>
                      <Form.Item
                        name="accountNumber"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            validator: NumberValidator,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Account Number"
                          value={accountNumber}
                          defaultValue={
                            sellerDetails?.bankAccount?.accountNumber
                          }
                          onChange={(e) => setAccountNumber(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="seller-profile-tile">
                      <p class="required">{"PAN:"}</p>
                      <Form.Item
                        name="pan"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            pattern: new RegExp("^[a-zA-Z0-9]{10}$"),
                            message: "Enter a valid PAN",
                          },
                          // {
                          //   validator: AlphaNumValidator,
                          // },
                        ]}
                      >
                        <Input
                          placeholder="PAN"
                          value={pan}
                          defaultValue={sellerDetails?.bankAccount?.panNo}
                          onChange={(e) => setPan(e.target.value)}
                          className="field-uppercase"
                        />
                      </Form.Item>
                    </div>
                  </Fragment>
                )}

                {bankEdit && (
                  <div className="profile-save-and-cancel-box">
                    <Button
                      className="profile-edit-cancel-button"
                      onClick={() => {
                        setBankEdit(false);
                        form.resetFields();
                      }}
                      style={{ background: "none", border: "none" }}
                    >
                      CANCEL
                    </Button>
                    <Form.Item>
                      <Button
                        className="profile-edit-save-button"
                        htmlType="submit"
                        // onClick={() => {
                        //   onFinishBank();
                        // }}
                        style={{ background: "none", border: "none" }}
                      >
                        <IoMdSave /> SAVE
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </div>
              {/* </Form> */}
            </div>

            {!props.isAffiliate && (
              <div className="store-profile-section">
                {/* <Form form={form} onFinish={onFinishTrade}> */}
                <div className="seller-profile-section-title">
                  <div className="d_flex">
                    <h3 className="sub-title-tags">
                      {" "}
                      <BiFolderOpen /> Trade Details{" "}
                    </h3>
                  </div>

                  <div className="edit-section">
                    {!tradeEdit && (
                      <Button
                        className="profile-edit-button"
                        onClick={() => {
                          // setTradeEdit(true);
                          handleTriggerEdit("trade");
                          handleInitialiseTradeDetails();
                        }}
                        disabled={cookies.get("switchuser")=="false"}
                        style={{ background: "none", border: "none" }}
                      >
                        <FiRefreshCw /> Update
                      </Button>
                    )}
                  </div>
                </div>
                <div className="merchent-section-content">
                  {!tradeEdit ? (
                    sellerDetails &&
                    (tradeLoading ? (
                      <Loading style={{ color: "#3e6df3" }} />
                    ) : (
                      <React.Fragment>
                        <div className="profile-section-container">
                          <div className="section-each-tile">
                            <p className="section-label-name">GST Number</p>
                            {sellerDetails?.tradeDetails?.gstNumber ? (
                              <p className="section-label-value field-uppercase">
                                {sellerDetails?.tradeDetails?.gstNumber}
                              </p>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("trade");
                                  handlInitialiseBankDetails();
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>

                          <div className="section-each-tile">
                            <p className="section-label-name">Business Name</p>
                            {sellerDetails?.tradeDetails?.businessName ? (
                              <p className="section-label-value">
                                {sellerDetails?.tradeDetails?.businessName}
                              </p>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("trade");
                                  handlInitialiseBankDetails();
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>

                          <div className="section-each-tile">
                            <p className="section-label-name">
                              Business Location
                            </p>
                            {sellerDetails?.tradeDetails?.businessLocation ? (
                              <p className="section-label-value">
                                {sellerDetails?.tradeDetails?.businessLocation}
                              </p>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("trade");
                                  handlInitialiseBankDetails();
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>

                          <div className="section-each-tile">
                            <p className="section-label-name">Business Email</p>
                            {sellerDetails?.tradeDetails?.businessEmail ? (
                              <p className="section-label-value">
                                {sellerDetails?.tradeDetails?.businessEmail}
                              </p>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("trade");
                                  handlInitialiseBankDetails();
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>

                          {sellerDetails?.tradeDetails?.website && (
                            <div className="section-each-tile">
                              <p className="section-label-name">
                                Business Website
                              </p>
                              <p className="section-label-value">
                                {sellerDetails?.tradeDetails?.website}
                              </p>
                            </div>
                          )}

                          <div className="section-each-tile">
                            <p className="section-label-name">Type Of Business</p>
                            {sellerDetails?.tradeDetails?.typeOfBusiness ? (
                              <p className="section-label-value">
                                {sellerDetails?.tradeDetails?.typeOfBusiness}
                              </p>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  // setGeneralEdit(true);
                                  handleTriggerEdit("trade");
                                  handlInitialiseBankDetails();
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                    ))
                  ) : (
                    <React.Fragment>
                      <Form.Item>
                        <Radio.Group
                          defaultValue={sellerDetails?.tradeDetails?.isCompanyGst}
                          buttonStyle="solid"
                          onChange={(e) => setGSTType(e.target.value)}
                        >
                          <Radio.Button value={false}>Own GST</Radio.Button>
                          <Radio.Button value={true}>loremIpsum GST</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <div className="seller-profile-tile">
                        <p class="required">{"GST Number:"}</p>

                        <Form.Item
                          name="gstNo"
                          rules={[
                            {
                              required: true,
                              message: "This field is required !",
                            },
                            {
                              pattern: new RegExp(
                                "^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[aA-zZ]{1}[0-9A-Za-z]{1})+$"
                              ),
                              message: "Enter a valid GST",
                            },
                            // {
                            //   pattern: new RegExp(
                            //     "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                            //   ),
                            //   message: "Enter a valid GST",
                            // },
                            // {
                            //   validator: AlphaNumValidator,
                            // },
                          ]}
                        >
                          <Input
                            placeholder="GST Number"
                            value={gstNo}
                            defaultValue={sellerDetails?.tradeDetails?.gstNumber}
                            onChange={(e) => setGstNumber(e.target.value)}
                            className="field-uppercase"
                          />
                        </Form.Item>
                      </div>
                      <div className="seller-profile-tile">
                        <p class="required">{"Business Name:"}</p>

                        <Form.Item
                          name="businessName"
                          rules={[
                            {
                              required: true,
                              message: "This field is required !",
                            },
                            {
                              validator: StoreNameValidator,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Business Name"
                            value={businessName}
                            defaultValue={
                              sellerDetails?.tradeDetails?.businessName
                            }
                            onChange={(e) => setBusinessName(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="seller-profile-tile">
                        <p class="required">{"Business Location:"}</p>

                        <Form.Item
                          name="businessLocation"
                          rules={[
                            {
                              required: true,
                              message: "This field is required !",
                            },
                            {
                              validator: AlphaNumValidator,
                            },
                          ]}
                        >
                          <Input
                            placeholder="Business Location"
                            value={businessLocation}
                            defaultValue={
                              sellerDetails?.tradeDetails?.businessLocation
                            }
                            onChange={(e) => setBusinessLocation(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="seller-profile-tile">
                        <p class="required">{"Business Email:"}</p>
                        <Form.Item
                          name="businessEmail"
                          rules={[
                            {
                              required: true,
                              message: "This field is required !",
                            },
                            {
                              type: "email",
                              message: "Enter a valid E-mail!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Business Email"
                            value={businessEmail}
                            defaultValue={
                              sellerDetails?.tradeDetails?.businessEmail
                            }
                            onChange={(e) => setBusinessEmail(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="seller-profile-tile">
                        <p class="required">{"Business Website:"}</p>
                        <Form.Item
                          name="businessWebsite"
                          rules={[
                            {
                              type: "url",
                              message: "Enter a valid Website!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Business Website"
                            value={businessWebsite}
                            defaultValue={
                              sellerDetails?.tradeDetails?.website ||
                              "https://www.loremIpsum.in/"
                            }
                            onChange={(e) => setBusinessWebsite(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                      <div className="seller-profile-tile">
                        <p class="required">{"Type Of Business:"}</p>
                        <Form.Item
                          name="typeOfBusiness"
                          rules={[
                            {
                              required: true,
                              message: "This field is required !",
                            },
                            // {
                            //   validator: NameValidator,
                            // },
                            {
                              pattern: new RegExp("^[a-zA-Z0-9 ]+$"),
                              message: "Special characters not allowed!",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Type Of Business"
                            value={typeOfBusiness}
                            defaultValue={
                              sellerDetails?.tradeDetails?.typeOfBusiness
                            }
                            onChange={(e) => setTypeOfBusiness(e.target.value)}
                          />
                        </Form.Item>
                      </div>
                    </React.Fragment>
                  )}
                </div>

                <div className="seller-profile-tile-title">
                  <h3>Attached Documents</h3>
                </div>
                <div
                  className="merchent-section-content"
                  // style={{ display: "flex", alignItems: "center" }}
                >
                  {!tradeEdit ? (
                    sellerDetails &&
                    sellerDetails?.tradeDetails &&
                    sellerDetails?.tradeDetails?.attachmentPaths && (
                      <Fragment>
                        {sellerDetails.tradeDetails?.attachmentPaths?.map(
                          (item) => (
                            <div
                              className="flex"
                              style={{
                                marginRight: "10px",
                                alignItems: "center",
                                cursor: "pointer",
                                marginBottom: "10px",
                              }}
                              onClick={() => {
                                downloadAttachement(item);
                              }}
                            >
                              <Icon
                                component={pdfIcon}
                                style={{ fontSize: "35px" }}
                              />
                              <div
                                style={{
                                  marginLeft: "10px",
                                  textDecoration: "underline",
                                }}
                              >
                                {item.split("/").pop()}
                              </div>
                            </div>
                          )
                        )}
                      </Fragment>
                    )
                  ) : sellerDetails?.tradeDetails?.attachmentPaths?.length > 0 ? (
                    sellerDetails?.tradeDetails?.attachmentPaths.map(
                      (item, index) => (
                        <div
                          className="flex"
                          style={{
                            marginRight: "10px",
                            alignItems: "center",
                            cursor: "pointer",
                            marginBottom: "10px",
                          }}
                          // onClick={() => {
                          //   downloadAttachement(item);
                          // }}
                        >
                          <Icon
                            component={pdfIcon}
                            style={{ fontSize: "35px" }}
                          />
                          <div
                            style={{
                              marginLeft: "10px",
                              textDecoration: "underline",
                            }}
                          >
                            {item.split("/").pop()}
                          </div>
                          <DeleteFilled
                            className="delete_icon_spec"
                            onClick={(e) =>
                              handleConfirmDeleteDoc(e, item, index)
                            }
                          />
                        </div>
                      )
                    )
                  ) : (
                    <Dragger
                      {...onImageChange}
                      className="dragger-upload"
                      accept="application/pdf,image/jpg,image/png,image/jpeg"
                    >
                      {uploading ? (
                        <Loading />
                      ) : (
                        <Button
                        // onClick={() => setUploading(true)}
                        >
                          Browse
                        </Button>
                      )}
                    </Dragger>
                  )}

                  {adminDetails?.type !== "sellers" && (
                    <Fragment>
                      <h3 className="sub-section-second-heading">Window Type</h3>

                      {tradeEdit ? (
                        <Radio.Group
                          defaultValue={sellerDetails?.tradeDetails?.windowType}
                          buttonStyle="solid"
                          onChange={(e) => setWindowGroup(e.target.value)}
                        >
                          <Radio.Button value="multiWindow">
                            Multi Window
                          </Radio.Button>
                          <Radio.Button value="singleWindow">
                            Single Window
                          </Radio.Button>
                        </Radio.Group>
                      ) : sellerDetails?.tradeDetails?.windowType ? (
                        <p>
                          Current Window Selected ::{" "}
                          <strong>
                            {sellerDetails?.tradeDetails?.windowType}
                          </strong>{" "}
                        </p>
                      ) : (
                        <Button
                          className="section-label-value-update"
                          onClick={() => {
                            // setGeneralEdit(true);
                            handleTriggerEdit("trade");
                            handleInitialiseTradeDetails();
                          }}
                        >
                          UPDATE
                        </Button>
                      )}
                    </Fragment>
                  )}

                  {tradeEdit && (
                    <div className="profile-save-and-cancel-box">
                      <Button
                        className="profile-edit-cancel-button"
                        onClick={() => {
                          setTradeEdit(false);
                          form.resetFields();
                        }}
                        style={{ background: "none", border: "none" }}
                      >
                        CANCEL
                      </Button>
                      <Form.Item>
                        <Button
                          className="profile-edit-save-button"
                          htmlType="submit"
                          // onClick={() => {
                          //   onFinishTrade();
                          // }}
                          style={{ background: "none", border: "none" }}
                        >
                          <IoMdSave /> SAVE
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </div>

                {/* </Form> */}
              </div>
            )}

            <div className="store-profile-section">
              {/* <Form form={form} onFinish={onFinishId}> */}
              <div className="seller-profile-section-title">
                <div className="d_flex">
                  <h3 className="sub-title-tags">
                    {" "}
                    <BiFolderOpen /> Personal Details{" "}
                  </h3>
                </div>

                <div className="edit-section">
                  {!personalEdit && (
                    <Button
                      className="profile-edit-button"
                      onClick={() => {
                        // setPersonalEdit(true);
                        handleTriggerEdit("personal");
                        handleInitialisePersonalDetails();
                      }}
                      style={{ background: "none", border: "none" }}
                    >
                      <FiRefreshCw /> Update
                    </Button>
                  )}
                </div>
              </div>
              <div className="merchent-section-content">
                {!personalEdit ? (
                  sellerDetails &&
                  (personalLoading ? (
                    <Loading style={{ color: "#3e6df3" }} />
                  ) : (
                    <Fragment>
                      <div className="profile-section-container">
                        <div className="section-each-tile">
                          <p className="section-label-name">ID Proof</p>
                          {sellerDetails?.idProof ? (
                            <p className="section-label-value">
                              {sellerDetails?.idProof}
                            </p>
                          ) : (
                            <Button
                              className="section-label-value-update"
                              onClick={() => {
                                // setGeneralEdit(true);
                                handleTriggerEdit("personal");
                                handleInitialisePersonalDetails();
                              }}
                            >
                              UPDATE
                            </Button>
                          )}
                        </div>

                        <div className="section-each-tile">
                          <p className="section-label-name">ID Proof Number</p>
                          {sellerDetails?.idProof ? (
                            <p className="section-label-value">
                              {sellerDetails?.idProofNumber}
                            </p>
                          ) : (
                            <Button
                              className="section-label-value-update"
                              onClick={() => {
                                // setGeneralEdit(true);
                                handleTriggerEdit("personal");
                                handleInitialisePersonalDetails();
                              }}
                            >
                              UPDATE
                            </Button>
                          )}
                        </div>
                      </div>
                    </Fragment>
                  ))
                ) : (
                  <React.Fragment>
                    <div className="seller-profile-tile">
                      <p class="required">{"ID Proof:"}</p>
                      <Form.Item
                        name="idName"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                        ]}
                      >
                        <Select
                          onSelect={(value, option) => setIdProof(option.value)}
                          style={{ width: "100%" }}
                          defaultValue={sellerDetails.idProof}
                        >
                          {proofList &&
                            proofList.map((item, index) => {
                              return (
                                <Option key={index} value={item}>
                                  {item}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="seller-profile-tile">
                      <p class="required">{" ID Proof Number:"}</p>
                      <Form.Item
                        name="idNumber"
                        rules={[
                          {
                            required: true,
                            message: "This field is required !",
                          },
                          {
                            validator: AlphaNumValidator,
                          },
                        ]}
                      >
                        <Input
                          placeholder="ID Proof Number"
                          value={idNumber}
                          defaultValue={sellerDetails.idProofNumber}
                          onChange={(e) => setIdNumber(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                  </React.Fragment>
                )}
                {personalEdit && (
                  <div className="profile-save-and-cancel-box">
                    <Button
                      className="profile-edit-cancel-button"
                      onClick={() => {
                        setPersonalEdit(false);
                        form.resetFields();
                      }}
                    >
                      CANCEL
                    </Button>
                    <Form.Item>
                      <Button
                        className="profile-edit-save-button"
                        htmlType="submit"
                        onClick={() => {
                          onFinishId();
                        }}
                      >
                        <IoMdSave /> SAVE
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </div>
              {/* </Form> */}
            </div>

            {!props.isStore && !props.isWarehouse && !props.isAffiliate && (
              <div className="store-profile-section">
                <div className="seller-profile-section-title">
                  <div className="d_flex">
                    <h3 className="sub-title-tags">
                      {" "}
                      <BiFolderOpen /> Service Locations
                    </h3>
                  </div>
                  <div
                    className="edit-section"
                    onClick={() => {
                      setLocationEdit(!locationEdit);
                      if (locationEdit) onFinishService();
                      if (!locationEdit) setserviceModal(true);
                    }}
                  >
                    {locationEdit ? (
                      <Button
                        className="profile-edit-button"
                        style={{ background: "none", border: "none" }}
                      >
                        <FiRefreshCw /> Save
                      </Button>
                    ) : (
                      <Button
                        className="profile-edit-button"
                        style={{ background: "none", border: "none" }}
                      >
                        <FiRefreshCw /> Update
                      </Button>
                    )}
                  </div>
                </div>
                <div className="merchent-section-content">
                  {
                    // !locationEdit ? (
                    sellerDetails && (
                      <React.Fragment>
                        <div className="profile-section-container profile-section-container-4-grid">
                          <div className="section-each-tile">
                            <p className="section-label-name">Country</p>
                            {serviceLocationObject?.country ? (
                              <p className="section-label-value">
                                {serviceLocationObject?.country}
                              </p>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  setLocationEdit(!locationEdit);
                                  if (locationEdit) onFinishService();
                                  if (!locationEdit) setserviceModal(true);
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>

                          <div className="section-each-tile">
                            <p className="section-label-name">States</p>
                            {serviceLocationObject?.state.length > 0 ? (
                              <Fragment>
                                {serviceLocationObject?.state.map(
                                  (state, index) => {
                                    return (
                                      <p className="section-label-value">
                                        {state}
                                      </p>
                                    );
                                  }
                                )}
                              </Fragment>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  setLocationEdit(!locationEdit);
                                  if (locationEdit) onFinishService();
                                  if (!locationEdit) setserviceModal(true);
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>

                          <div className="section-each-tile">
                            <p className="section-label-name">District</p>
                            {serviceLocationObject?.district ? (
                              <Fragment>
                                {serviceLocationObject?.district.map(
                                  (district, index) => {
                                    return (
                                      <p className="section-label-value">
                                        {district}
                                      </p>
                                    );
                                  }
                                )}
                              </Fragment>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  setLocationEdit(!locationEdit);
                                  if (locationEdit) onFinishService();
                                  if (!locationEdit) setserviceModal(true);
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>

                          <div className="section-each-tile">
                            <p className="section-label-name">PinCodes</p>
                            {sellerDetails?.serviceablePinCodes ? (
                              <div className="profile-pincode-grid-3">
                                {sellerDetails?.serviceablePinCodes?.map(
                                  (serviceablePinCodes, index) => {
                                    return (
                                      <p className="section-label-value">
                                        {serviceablePinCodes}
                                      </p>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <Button
                                className="section-label-value-update"
                                onClick={() => {
                                  setLocationEdit(!locationEdit);
                                  if (locationEdit) onFinishService();
                                  if (!locationEdit) setserviceModal(true);
                                }}
                              >
                                UPDATE
                              </Button>
                            )}
                          </div>
                        </div>
                      </React.Fragment>
                      //   )
                      // ) : (
                      //   <React.Fragment>
                      //     <div className="seller-profile-tile">
                      //       District:
                      //       <Select
                      //         onSelect={(value, option) => {
                      //           setLocation(option.value);
                      //         }}
                      //         style={{ width: "100%" }}
                      //       >
                      //         {districtList &&
                      //           districtList.map((item, index) => {
                      //             return (
                      //               <Option key={index} value={item}>
                      //                 {item}
                      //               </Option>
                      //             );
                      //           })}
                      //       </Select>
                      //     </div>
                      //     <div className="seller-profile-tile">
                      //       Availbale Pincode:
                      //       <Select
                      //         onSelect={(value, option) => {}}
                      //         mode="multiple"
                      //         onChange={handleChangePincode}
                      //         style={{ width: "100%" }}
                      //       >
                      //         {pincodeList &&
                      //           pincodeList.map((item, index) => {
                      //             return (
                      //               <Option key={index} value={item}>
                      //                 {item}
                      //               </Option>
                      //             );
                      //           })}
                      //       </Select>
                      //     </div>
                      //   </React.Fragment>
                    )
                  }
                </div>
              </div>
            )}
          </React.Fragment>
        )}
        {serviceModal && (
          <ServiceLocationModal
            visible={serviceModal}
            setVisible={setserviceModal}
            serviceLocations={serviceLocations}
            setServiceLocations={setserviceLocations}
            serviceLocationsObject={serviceLocationObject}
            setServiceLocationsObject={setserviceLocationObject}
            setisEdit={setLocationEdit}
            submit={onFinishService}
          />
        )}
      </Form>
      {incomingOrders && (
        <>
          {needToShowIncomingOrders && (
            <div className="store-platform-warning-container">
              <div className="store-platform-container order-alert-background">
                <span>

                  <InfoCircleFilled
                  className="store-platform-container-icon"
                  style={{color: "cornflowerblue"}} />
                  <h4>Orders found</h4>
                  <h3 style={{color: "black"}}>
                    INCOMING ORDERS :
                    <NumberFormat
                      style={{marginLeft: "3px"}}
                      value={incomingOrders}
                      displayType={"text"}
                      thousandSeparator
                    />
                  </h3>
                </span>

                <Divider />

                <div className="store-platform-button-container">
                  <button
                    className="store-platform-button"
                    onClick={() => {
                      setNeedToShowIncomingOrders(false);
                      if (ENV_TYPE === "dev") {
                        cookies.set("needToShowIncomingOrders", false, {
                          path: "/",
                        });
                      } else {
                        cookies.set("needToShowIncomingOrders", false, {
                          path: "/",
                          domain: `.${DOMAIN}`,
                        });
                      }
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {platformData?.color && (
        <>
          {props?.isStore && needToShow && (
            <div className="store-platform-warning-container">
              <div
                className={
                  platformData?.color === 3
                    ? "store-platform-container warning-high-background"
                    : platformData?.color === 2
                    ? "store-platform-container warning-mid-background"
                    : platformData?.color === 1 &&
                      "store-platform-container warning-low-background"
                }
              >
                <span>
                  <WarningFilled className="store-platform-container-icon" />
                  <h4>Please Remit the Platform Charge!</h4>
                  <h3>
                    AMOUNT TO PAY :
                    <NumberFormat
                      value={platformData?.platformAmount?.toFixed(2)}
                      displayType={"text"}
                      thousandSeparator
                    />
                  </h3>
                </span>

                <Divider />

                <div className="store-platform-button-container">
                  <button
                    className={
                      platformData?.color === 3
                        ? "store-platform-button warning-high-button"
                        : platformData?.color === 2
                        ? "store-platform-button warning-mid-button"
                        : platformData?.color === 1 &&
                          "store-platform-button warning-low-button"
                    }
                    onClick={() => {
                      setNeedToShow(false);
                      if (ENV_TYPE === "dev") {
                        cookies.set("needToShow", false, {
                          path: "/",
                        });
                      } else {
                        cookies.set("needToShow", false, {
                          path: "/",
                          domain: `.${DOMAIN}`,
                        });
                      }
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {totalNotification > 0 && props?.isAdmin && (
        <div className="platform-notification-container">
          <span>
            <BsInfoCircleFill className="platform-notification-container-icon" />
          </span>
          <div>
            <h2>Platform Charge Alert</h2>
            <h4>
              Platform Charge collection can be started. Please Check{" "}
              <b>Platform Charge Summary.</b>
            </h4>
            <button onClick={() => setTotalNotification(0)}>CLOSE</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageProfile;
