


import React, { useState, useEffect } from "react";

import { Typography, Tabs, Button, Modal, message } from "antd";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import {
  getProducts,
  approveAllProducts,
} from "../../../ApiAction/SellerApiActions/sellerApi";
import ManageProductList from "../../Seller/ManageProducts/Components/manageProductList";

import { fetchSort, fetchSortBy } from "../../../ReduxStore/action";
import EmptyList from "../../GeneralComponents/emptyList";
import "./manageProductAdmin.scss";
import Loading from "../../GeneralComponents/Loading";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";

const { Title } = Typography;
const { TabPane } = Tabs;
const ManageProductAdmin = (props) => {
  const { searchVal } = props;
  const [isMobileView, setisMobileView] = useState(false);
  const [type, setType] = useState("listed");
  const [productList, setProductList] = useState([]);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setloading] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [isLoadingPublishAll, setIsLoadingPublishAll] = useState(false);

  const typeOptions = [
    {
      key: "APPROVED",
      label: "Approved",
    },
    {
      key: "PENDING",
      label: "Pending",
    },
    {
      key: "REJECTED",
      label: "Rejected",
    },
  ];
  const handleChangetab = async (activeKey) => {
    let payload;
    switch (activeKey) {
      case "PENDING":
        setActiveTab("Pending");
        payload = {
          sortType: props?.sort?.sortType,
          sortBy: props?.sort?.sortBy,
          block: false,
          archive: false,
          all: false,
          status: "PENDING",
          filterType: props?.sort.filterType,
        };
        props.fetchSort(payload);
        break;
      case "REJECTED":
        setActiveTab("Rejected");
        payload = {
          sortType: props?.sort?.sortType,
          sortBy: props?.sort?.sortBy,
          block: false,
          archive: false,
          all: false,
          status: "REJECTED",
          filterType: props.sort.filterType,
        };
        props.fetchSort(payload);
        break;

      case "APPROVED":
        setActiveTab("Approved");
        payload = {
          sortType: props?.sort?.sortType,
          sortBy: props?.sort?.sortBy,
          block: true,
          archive: false,
          all: false,
          status: "APPROVED",
          filterType: props.sort.filterType,
        };
        props.fetchSort(payload);
        break;

      default:
        console.log("not a valid key");
        setActiveTab("");
    }
  };

  const handleApproveAllProducts = () => {
    setIsLoadingPublishAll(true);
    approveAllProducts()
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            message.success(response.data.message);
            nextpage();
          } else {
            message.error(response.data.message);
          }
        }
        console.log(response);
      })
      .finally(() => {
        setIsLoadingPublishAll(false);
      });
  };

  const renderConfirmModal = () => {
    Modal.confirm({
      title: "Confirm",
      className: "manage-category-confirm-modal",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to approve all products ? `,
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleApproveAllProducts(),
      onCancel: () => {
        console.log("Approve All Cancelled");
      },
    });
  };

  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    nextpage(1);
  }, [props]);

  const nextpage = (page = 1) => {
    setCurrentPage(page)
    const term = [{ active: [true] }];
    // const term = [];

    setloading(true);
    if (props.sort) {
      term.push({
        status: [props.isAutoApproval? "APPROVED": 
        props.sort.status ? props.sort.status : "APPROVED"],
      });
      if (props.sort.filterType && props.sort.filterType != "ALL")
        term.push({
          vendorType: [props.sort.filterType],
        });
    } else
      term.push({
        status: ["APPROVED"],
      });
    if (props.vendorId) term.push({ vendorId: [props.vendorId] });
    if (props.brandId) term.push({ brandId: [props.brandId] });
    if (props.isAutoApproval) term.push({ approvedBy: ["AUTOAPPROVED"]})
    const payload = {
      offset: 10 * (page - 1),
      size: 10,

      key: searchVal ? searchVal : "",
      sortBy: "lastModifiedDate",
      sortType: props.sort?.sortType ? props.sort.sortBy : "desc",
      filter: {
        term: term,
      },
    };

    getProducts(payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(response.data.products);
          setPage(Math.ceil(response.data.count / 10) * 10);
        }
      })
      .catch(() => {})
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        <h1 level={4} className="sub-title-tags">
          {" "}
          {props.isAutoApproval && "Auto Approved Products"}
          {!props.isAutoApproval && "Products"}
        </h1>
        {/* {activeTab === "Pending" ? (
          <Button
            className="approve-all-products-btn"
            type="primary"
            onClick={() => renderConfirmModal()}
          >
            {isLoadingPublishAll ? (
              <Loading style={{ color: "white" }} />
            ) : (
              "Approve All"
            )}
          </Button>
        ) : (
          <></>
        )} */}
      </div>
      {props.isAutoApproval && 
        <ManageProductList
          type={"listed"}
          productList={productList}
          total={page}
          currentPage={currentPage}
          nextpage={nextpage}
          productStatus={"APPROVED"}
          isAdmin
          isLoading={loading}
          isAutoApproval
        />
      }
      {!props.isAutoApproval &&
        <Tabs defaultActiveKey="active" onChange={handleChangetab} size={"large"}>
          {typeOptions.map((type) => {
            return (
              <TabPane tab={type.label} key={type.key}>
                {loading || productList.length > 0 ? (
                  <ManageProductList
                    type={"listed"}
                    productList={productList}
                    total={page}
                    currentPage={currentPage}
                    nextpage={nextpage}
                    productStatus={
                      props.sort?.status ? props.sort.status : "APPROVED"
                    }
                    isAdmin
                    isLoading={loading}
                  />
                ) : (
                  <EmptyList msg={"No Products found"} />
                )}
              </TabPane>
            );
          })}
        </Tabs>
        }
    </div>
  );
};
const mapDispatchToProps = {
  fetchSort,
  fetchSortBy,
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
    brandId: state.reportReducer.brandId,
    vendorId: state.reportReducer.vendorId,
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageProductAdmin)
);
