

import React from "react";
import packageJson from "../package.json";
import Cookies from "universal-cookie";
import { DOMAIN, ENV_TYPE } from "./Utils/constants";
// import { Modal } from "antd";
import { history } from "./App";
import { fetchCompanyDetails } from "./ReduxStore/action/homePageAction";
import { connect } from "react-redux";
import { getCompanyDetails } from "./ApiAction/AdminApiAction/adminApi";

global.appVersion = packageJson.version;

const cookies = new Cookies();

// const warning = Modal.warning;

function compareVersion(v1, v2) {
  console.log(typeof v1, ":type of v1", v1);
  if (typeof v1 !== "string") return true;
  if (typeof v2 !== "string") return true;
  v1 = v1.split(".");
  v2 = v2.split(".");
  const k = Math.min(v1.length, v2.length);
  for (let i = 0; i < k; ++i) {
    v1[i] = parseInt(v1[i], 10);
    v2[i] = parseInt(v2[i], 10);
    if (v1[i] > v2[i]) return 1;
    if (v1[i] < v2[i]) return -1;
  }
  return v1.length == v2.length ? 0 : v1.length < v2.length ? -1 : 1;
}

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  let isDiff = compareVersion(versionB, versionA);
  if (isDiff) {
    console.log("is different version");
    return true;
  } else {
    console.log("is same version");
    return false;
  }
};

// const config = {
//   title:
//     "We have new update available, please login again to continue using neew version.",
// };
class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log("Clearing cache and hard reloading...");
        // if (caches) {
        //   // Service worker cache should be cleared with caches.delete()
        //   caches.keys().then(function (names) {
        //     for (let name of names) caches.delete(name);
        //   });
        // }

        // delete browser cache and hard reload
        if (ENV_TYPE === "production") {
          localStorage.removeItem("activeUser");
          localStorage.removeItem("remember");
          sessionStorage.removeItem("authToken");
          localStorage.removeItem("authToken");
          // cookies.remove("authToken", {
          //   path: "/",
          //   domain: `.${DOMAIN}`,
          // });
          cookies.remove("vendorId", { path: "/", domain: `.${DOMAIN}` });
          cookies.remove("userId", { path: "/", domain: `.${DOMAIN}` });

          cookies.remove("activeUserPincode", {
            path: "/",
            domain: `.${DOMAIN}`,
          });
          // history.push("/");
          cookies.remove("accountType", {
            path: "/",
            domain: `.${DOMAIN}`,
          });
          // window.location.reload(true);
        } else {
          localStorage.removeItem("activeUser");
          localStorage.removeItem("remember");
          localStorage.removeItem("authToken");
          sessionStorage.removeItem("authToken");
          // cookies.remove("authToken", { path: "/" });
          cookies.remove("vendorId", { path: "/" });
          cookies.remove("userId", { path: "/" });

          cookies.remove("activeUserPincode", { path: "/" });
          switch (cookies.get("accountType")) {
            case "customer":
              history.push("/customer");
              break;
            case "store":
              history.push("/login-store");
              break;
            case "seller":
              history.push("/login-seller");
              break;
            case "warehouse":
              history.push("/login-warehouse");
              break;
            case "admin":
              history.push("/login-admin");
              break;
            case "logger":
              history.push("/logger-admin");
              break;
            default:
              console.log("invalid case");
          }
          cookies.remove("accountType", { path: "/" });
          // window.location.reload(true);
        }
      },
    };
  }

  handleConfirmUpdate() {
    this.setState({ loading: false, isLatestVersion: false });
    // warning({
    //   title:
    //     "We have an update available, please login again to continue using new features!!",
    //   className: "create-confirm-modal",

    //   onOk: () => this.setState({ loading: false, isLatestVersion: false }),
    // });
  }

  componentDidMount() {
    fetch(`/meta.json?${new Date().getTime()}, { cache: 'no-cache' }`)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = localStorage.getItem("appVersion");
        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          localStorage.setItem("appVersion", meta.version);
          console.log(
            `We have a new version - ${latestVersion}. Do you want to update`
          );
          this.handleConfirmUpdate();
        } else {
          localStorage.setItem("appVersion", meta.version);
          console.log(
            `You already have the latest version - ${latestVersion}.`
          );

          this.setState({ loading: false, isLatestVersion: true });
        }
      });

      getCompanyDetails()
      .then((response) => {
        if(response?.data.status == "success") {
          this.props.fetchCompanyDetails(response.data.companyDetails)
        }
      })
  }
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    });
  }
}

const mapStateToProps = (state) => {
  return {
    companyData: state.homePageReducer.companyData,
  };
};
const mapDispatchToProps = {
  fetchCompanyDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(CacheBuster);
