


import React, { useState, useEffect } from "react";
import { Layout, Divider, Button, Popover, Typography } from "antd";
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import { fetchManageOrderFilter } from "../../../ReduxStore/action";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
const { Text } = Typography;
const FilterOrder = (props) => {
  const cookies = new Cookies();

  const [sortValue, setSortValue] = useState("(All)");
  const [sortType, setsortType] = useState("");
  const [sortFrom, setSortFrom] = useState("");
  useEffect(() => {}, []);
  const renderSortStatus = () => {
    return (
      <div style={{ width: "200px" }}>
        <div className="sort-option-heading"> Payment method</div>
        <div
          className="sort-option-content"
          onClick={() => {
            setsortType("COD");
            setSortValue("(COD)");
            const payload = {
              sortType: props.sort.sortType,
              sortBy: props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode: "COD",
              itemOrderStatus: props.sort.itemOrderStatus,
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          COD
          {sortType === "COD" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div>

        <div
          className="sort-option-content"
          onClick={() => {
            setsortType("Online");
            setSortValue("(Online)");
            const payload = {
              sortType: props.sort.sortType,
              sortBy: props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode: "ONLINE",
              itemOrderStatus: props.sort.itemOrderStatus,
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          Online
          {sortType === "Online" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div>
        <Divider style={{ margin: "10px 0px" }} />
        {/* <div className="sort-option-heading"> Order status</div>
        <div
          onClick={() => {
            setSortValue("(Open Order)");

            const payload = {
              sortType: props.sort.sortType,
              sortBy: props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode: props.sort.paymentMode,
              itemOrderStatus: "ORDERED",
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
          className="sort-option-content"
        >
          Open Order
          {sortValue === "(Open Order)" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div> */}

        {/* confirmed */}
        {/* <div
          className="sort-option-content"
          onClick={() => {
            setSortValue("(Item Confirmed)");

            const payload = {
              sortType: props.sort.sortType,
              sortBy: props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode: props.sort.paymentMode,
              itemOrderStatus: "CONFIRMED",
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          Item Confirmed
          {sortValue === "(Item Confirmed)" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div> */}
        {/* picked */}
        {/* <div
          className="sort-option-content"
          onClick={() => {
            setSortValue("(Item Picked)");

            const payload = {
              sortType: props.sort.sortType,
              sortBy: props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode: props.sort.paymentMode,
              itemOrderStatus: "PICKED",
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          Item Picked
          {sortValue === "(Item Picked)" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div> */}

        {/* <div
          className="sort-option-content"
          onClick={() => {
            setSortValue("(Item Packed)");

            const payload = {
              sortType: props.sort.sortType,
              sortBy: props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode: props.sort.paymentMode,
              itemOrderStatus: "PACKED",
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          Item Packed
          {sortValue === "(Item Packed)" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div> */}
        {/* <div
          className="sort-option-content"
          onClick={() => {
            setSortValue("(Item Shipped)");

            const payload = {
              sortType: props.sort.sortType,
              sortBy: props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode: props.sort.paymentMode,
              itemOrderStatus: "SHIPPED",
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          Item Shipped
          {sortValue === "(Item Shipped)" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div>
        <div
          className="sort-option-content"
          onClick={() => {
            setSortValue("(Cancelled)");

            const payload = {
              sortType: props.sort.sortType,
              sortBy: props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode: props.sort.paymentMode,
              itemOrderStatus: "CANCELLED",
              orderFrom: props.sort.orderFrom,
            };
            props.fetchManageOrderFilter(payload);
          }}
        >
          Cancelled
          {sortValue === "(Cancelled)" && (
            <CheckCircleTwoTone
              twoToneColor="#0074be"
              style={{ fontSize: "16px", float: "right" }}
            />
          )}
        </div> */}
        {/* {cookies.get("accountType") != "store" && (
          <React.Fragment>
            <Divider style={{ margin: "10px 0px" }} />
            <div className="sort-option-heading"> Order From</div>
            {cookies.get("accountType") != "warehouse" && (
              <div
                onClick={() => {
                  setSortFrom("(Customer)");

                  const payload = {
                    sortType: props.sort.sortType,
                    sortBy: props.sort.sortBy,
                    startTime: props.sort.startTime,

                    endTime: props.sort.endTime,
                    paymentMode: props.sort.paymentMode,
                    itemOrderStatus: props.sort.itemOrderStatus,
                    orderFrom: "customer",
                  };
                  props.fetchManageOrderFilter(payload);
                }}
                className="sort-option-content"
              >
                Customer
                {sortFrom === "(Customer)" && (
                  <CheckCircleTwoTone
                    twoToneColor="#0074be"
                    style={{ fontSize: "16px", float: "right" }}
                  />
                )}
              </div>
            )}
            <div
              onClick={() => {
                setSortFrom("(Store)");

                const payload = {
                  sortType: props.sort.sortType,
                  sortBy: props.sort.sortBy,
                  startTime: props.sort.startTime,

                  endTime: props.sort.endTime,
                  paymentMode: props.sort.paymentMode,
                  itemOrderStatus: props.sort.itemOrderStatus,
                  orderFrom: "store",
                };
                props.fetchManageOrderFilter(payload);
              }}
              className="sort-option-content"
            >
              Store
              {sortFrom === "(Store)" && (
                <CheckCircleTwoTone
                  twoToneColor="#0074be"
                  style={{ fontSize: "16px", float: "right" }}
                />
              )}
            </div>
            <div
              onClick={() => {
                setSortFrom("(Warehouse)");

                const payload = {
                  sortType: props.sort.sortType,
                  sortBy: props.sort.sortBy,
                  startTime: props.sort.startTime,

                  endTime: props.sort.endTime,
                  paymentMode: props.sort.paymentMode,
                  itemOrderStatus: props.sort.itemOrderStatus,
                  orderFrom: "warehouse",
                };
                props.fetchManageOrderFilter(payload);
              }}
              className="sort-option-content"
            >
              Warehouse
              {sortFrom === "(Warehouse)" && (
                <CheckCircleTwoTone
                  twoToneColor="#0074be"
                  style={{ fontSize: "16px", float: "right" }}
                />
              )}
            </div>
          </React.Fragment>
        )} */}
        <div
          className="minimum-tags"
          onClick={() => {
            console.log("PAYMENT TYPE::: " +props.sort.paymentMode);
            console.log("SORT TYPE::: " +props.sort.sortType);
            setSortValue("(All)");
            setSortFrom("");
            setsortType("");

            const payload = {
              sortType: props.sort.sortType === undefined ? "desc"  : props.sort.sortType,
              sortBy: props.sort.sortBy === undefined ? "orderedDate" : props.sort.sortBy,
              startTime: props.sort.startTime,

              endTime: props.sort.endTime,
              paymentMode:  "ALL" ,
              // itemOrderStatus: "All",
              orderFrom: "",
            };
            props.fetchManageOrderFilter(payload);
            console.log("PAYMENT TYPE AFTERR::: " +payload.paymentMode);
            console.log("SORT TYPE AFTER::: " + payload.sortType);
          }}
        >
          Clear All
        </div>
      </div>
    );
  };
  return (
    <Popover
      content={renderSortStatus()}
      placement="bottom"
      trigger="click"
      className="sort-dropdown-admin"
      getPopupContainer={(trigger) => trigger.parentElement}
    >
      <Button style={{ height: "40px" }}>
        <span style={{ color: "#827e7e" }}>Filter By: </span>
        <span>
          &nbsp;
          <Text strong> {" " + "Status"}</Text>
          <Text strong style={{ color: "green" }}>
            {" "}
            {sortValue}
          </Text>
        </span>
        <CaretDownOutlined />
      </Button>
    </Popover>
  );
};
const mapDispatchToProps = {
  fetchManageOrderFilter,
};

const mapStateToProps = (state) => {
  return {
    sort: state.manageOrderReducer,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FilterOrder);
