


import React, { useState } from "react";
import { Card, Button } from "antd";

import "./manageAddress.scss";
import AddAddress from "../Cart/addAddress";

// const AddressList = React.lazy(() => import("./Components/addressList"));
import AddressList from "./Components/addressList";

const ManageAddress = () => {
  const [addressModalVisible, setaddressModalVisible] = useState(false);

  return (
    <Card
      title={<h1 className="sub-title-tags">Address</h1>}
      extra={
        <Button onClick={() => setaddressModalVisible(true)} className="add_addr_btn">
          + Add Address
        </Button>
      }
      style={{ width: "100%", backgroundColor: "#f2f2f2" }}
      className="manage_address_container_card"
    >
      <AddressList />

      <AddAddress
        visible={addressModalVisible}
        setVisible={setaddressModalVisible}
      />
    </Card>
  );
};

export default ManageAddress;
