


import React from "react";
import { useEffect, useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import ShareUrlIcon from '../../../../../Assets/Images/Icons/share-url.png'

const ShareContentOnSocialMedia = (props) => {
  const { title, url } = props;
  const [copySuccess, setCopySuccess] = useState('')

  useEffect(() => {
    setTimeout(() => setCopySuccess(''), 2000)
  }, [copySuccess])
  return (
    <div className="social-share-popover-content">
      <FacebookShareButton quote={title} hashtag="#Company" url={url}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>
      <TwitterShareButton title={title} url={url}>
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>
      <WhatsappShareButton title={title} url={url}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
      <div id="copy-icon" onClick={() => {navigator.clipboard.writeText(window.location.href); setCopySuccess("Copied!")}} className="cursor-pointer">
        <div>
          <img src={ShareUrlIcon} alt="Copy" style={{"margin": "10px", "width": "35px", "vertical-align": "top"}}/>
          <p className="ml-2 text-base-secondary text-sm light">
            {copySuccess ? <span className="blink">{copySuccess}</span> : 'Copy link'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShareContentOnSocialMedia;
