

import React, { useState, useEffect } from "react";

import { Button, Typography, Form, Input, Checkbox, message } from "antd";
import { connect } from "react-redux";
import Cookies from "universal-cookie";

import "./login.scss";
import {
  openLoginModal,
  openSignUpModal,
  closeAuthModal,
  openContactModal,
  updateReferralId,
  updateAffiliateId,
} from "../../../ReduxStore/action/authModalActions";
import { setPincode } from "../../../ReduxStore/action/productActions";
import { withRouter } from "react-router-dom";
import { loginCustomer } from "../../../ApiAction/CustomerApiActions/customerApi";
import Loading from "../Loading";
import { ENV_TYPE, DOMAIN } from "../../../Utils/constants";
import { addGuestCart } from "../../../ApiAction/CustomerApiActions/orderAPI";
import ValidateOtp from "../validateOtp";
import { fetchCompanyDetails } from "../../../ReduxStore/action";

const { Title } = Typography;
var CryptoJS = require("crypto-js");
const LoginForm = (props) => {
  const cookies = new Cookies();
  const [visible, setvisible] = useState(false);
  const [remember, setRemember] = useState(true);
  const [loading, setLoading] = useState(false);
  const [userType, setuserType] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isTwoFactorAuth, setIsTwoFactorAuth] = useState(false);
  const [twoFactorAuthResponse, setTwoFactorAuth] = useState(false);
  const [payload, setPayload] = useState();

  const handleOpenSignUpModal = () => {
    if (props.isSeller) {
      props.openSignUpModal("seller");
    } else if (props.isAffiliate) {
      props.openSignUpModal("affiliate");
      console.log("affiliate selected")
    } else props.openSignUpModal("customer");
  };

  //related to form starts here
  const onFinish = (values) => {
    setLoading(true);
    localStorage.setItem("remember", values.remember);
    setRemember(values.remember);
    console.log(props);
    const paylodAdmin = {
      username: values.username,
      password: values.password,
    };
    const paylod = {
      username: values.username,
      password: values.password,
      accountType: props.isSeller
        ? "seller"
        : props.isStore
        ? "store"
        : props.isWarehouse
        ? "warehouse"
        : props.isWorkAdmin
        ? "workAdmin"
        : props.isAffiliate
        ? "affiliate"
        : "customer",
    };
    setPayload(props.isAdmin ? paylodAdmin : paylod);

    console.log("Received values of form: ", values);
  };

  const successfulLoginActions = (response) => {
    if (ENV_TYPE === "dev") {
      var ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(response.data.token),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      if (localStorage.getItem("remember") == "true") {
        localStorage.setItem("authToken", ciphertext, {
          path: "/",
        });
      } else {
        sessionStorage.setItem("authToken", ciphertext, {
          path: "/",
        });
      }
      // cookies.set("authToken", ciphertext, {
      //   path: "/",
      // });
      cookies.set("userId", response.data.userId, {
        path: "/",
      });
      cookies.set("adminId", response.data.userId, {
        path: "/",
      });
      cookies.set("accountType", response.data.accountType, {
        path: "/",
      });
      cookies.set("activeUserPincode", response.data.pinCode, {
        path: "/",
      });
    } else {
      var ciphertext2 = CryptoJS.AES.encrypt(
        JSON.stringify(response.data.token),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      if (localStorage.getItem("remember") == "true") {
        localStorage.setItem("authToken", ciphertext2, {
          path: "/",
          domain: `.${DOMAIN}`,
        });
      } else {
        sessionStorage.setItem("authToken", ciphertext2, {
          path: "/",
          domain: `.${DOMAIN}`,
        });
      }
      // cookies.set("authToken", ciphertext2, {
      //   path: "/",
      //   domain: `.${DOMAIN}`,
      // });
      cookies.set("userId", response.data.userId, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      cookies.set("adminId", response.data.userId, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      cookies.set("accountType", response.data.accountType, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
        cookies.set("activeUserPincode", response.data.pinCode, {
          path: "/",
          domain: `.${DOMAIN}`,
        });
      }
    props.setPincode(response.data.pinCode);
    message.success(response.data.message);
    const activeCustomer = response.data;
    delete activeCustomer.token;
    localStorage.setItem(
      "activeCustomer",
      JSON.stringify(activeCustomer)
    );

    console.log("response for login api: ", response);

    if (props.isAdmin) {
      props.history.push(`/admin/profile`);
    } else if (props.isSeller) {
      props.history.push("/seller");
    } else if (props.isStore) {
      props.history.push("/store");
    } else if (props.isWarehouse) {
      props.history.push("/warehouse");
    } else if (props.isWorkAdmin) {
      props.history.push("/work-report");
    } else if (props.isAffiliate) {
      props.history.push("/affiliate");
    } else {
      props.setVisible();
    }
  }
  //related to form ends here

  const handleCustomerLogin = (response) => {
    switch (response?.type) {
      case "DUPLICATE":
        props.handleOpenUpdateEmailModal({
          userId: response.userId,
          email: response.email,
        });
        break;
      case "NEW":
        console.log("here is a new customer from MLM:", response);
        handleOpenSignUpModal();
        props.updateReferralId(response?.referralId);
        props.updateAffiliateId(response?.affliateId);

        break;
      case "EXISTING":
        handleTriggerCustomerLogin(response);
        console.log("already existing customer");

        break;
      default:
        handleTriggerCustomerLogin(response);
        console.log("already existing customer in our new site");
        break;
    }
  };
  const handleAddGuestCart = () => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart"));

    if (guestCart) {
      guestCart.forEach((elm) => delete elm.itemId);
      addGuestCart(guestCart)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              localStorage.removeItem("guestCart");
            }
          }
        })
        .catch(() => {})
        .finally(() => window.location.reload(true));
    }
  };

  const handleTriggerCustomerLogin = async (response) => {
    if (ENV_TYPE === "dev") {
      var ciphertext3 = CryptoJS.AES.encrypt(
        JSON.stringify(response.token),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      if (localStorage.getItem("remember") == "true") {
        localStorage.setItem("authToken", ciphertext3, {
          path: "/",
        });
      } else {
        sessionStorage.setItem("authToken", ciphertext3, {
          path: "/",
        });
      }
      // cookies.set("authToken", ciphertext3, {
      //   path: "/",
      // });
      cookies.set("userId", response.userId, {
        path: "/",
      });

      cookies.set("accountType", response.accountType, {
        path: "/",
      });
      if(response.data?.pinCode) {
        cookies.set("activeUserPincode", response.pinCode, {
          path: "/",
        });
      }
    } else {
      var ciphertext4 = CryptoJS.AES.encrypt(
        JSON.stringify(response.token),
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString();
      if (localStorage.getItem("remember") == "true") {
        localStorage.setItem("authToken", ciphertext4, {
          path: "/",
          domain: `.${DOMAIN}`,
        });
      } else {
        sessionStorage.setItem("authToken", ciphertext4, {
          path: "/",
          domain: `.${DOMAIN}`,
        });
      }
      // cookies.set("authToken", ciphertext4, {
      //   path: "/",
      //   domain: `.${DOMAIN}`,
      // });
      cookies.set("userId", response.userId, {
        path: "/",
        domain: `.${DOMAIN}`,
      });

      cookies.set("accountType", response.accountType, {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      if(response.data?.pinCode) {
        cookies.set("activeUserPincode", response.pinCode, {
          path: "/",
          domain: `.${DOMAIN}`,
        });
      }
    }
    props.setPincode(response.pinCode);
    message.success(response.message);
    const activeCustomer = response;
    delete activeCustomer.token;
    await localStorage.setItem(
      "activeCustomer",
      JSON.stringify(activeCustomer)
    );
    props.closeAuthModal();
    if (localStorage.getItem("guestCart")) {
      handleAddGuestCart();
    } else window.location.reload(true);
  };

  const PasswordValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 2) {
      callback("Password should consists of minimun of 1 chartcters");
    } else {
      callback();
    }
  };

  useEffect(() => {
    console.log(props.authModalUserType, props.authModalType, "updated login");

    props.authModalType === "login" ? setvisible(true) : setvisible(false);

    props.authModalUserType && setuserType(props.authModalUserType);
  }, [props]);

  useEffect(() => {
    if (ENV_TYPE == "dev") {
      cookies.set("switchuser", "false", {
        path: "/",
      });
    } else
      cookies.set("switchuser", "false", {
        path: "/",
        domain: `.${DOMAIN}`,
      });
  }, []);

  useEffect(() => {
    if(payload) {
      loginCustomer(props.isAdmin ? payload : payload, props.isAdmin, props.isWorkAdmin)
        .then((response) => {
          setLoading(false);

          if (response.status === 200) {
            if (response.data.status === "success") {
              if(response.data.twoFactorAuth) {
                setIsTwoFactorAuth(true);
                message.success(response.data.message)
              }
              else if (props.isCustomer) {
                handleCustomerLogin(response.data);
              } else {
                successfulLoginActions(response);
              }
            } else setErrorMessage(response.data.message);
          } else setErrorMessage(response.data.message);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            setErrorMessage(error.response.data.message);
          }
        });
    }
  }, [payload]);

  useEffect(() => {
    if(twoFactorAuthResponse) {
      successfulLoginActions(twoFactorAuthResponse);
    }
  }, [twoFactorAuthResponse]);

  return (
    <>
      <div className="login-modal-form form_data_group">
        <Title level={3}>Login </Title>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your Username!",
              },
            ]}
          >
            <Input placeholder="Email ID or Customer ID*" />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
              // { validator: PasswordValidator },
            ]}
          >
            <Input.Password placeholder="Password*" />
          </Form.Item>
          <Form.Item className="rm-height">
            <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <a className="login-form-forgot" onClick={() => props.setForgot()}>
              Forgot password?
            </a>
          </Form.Item>

          <Form.Item className="button_submit">
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              style={{ fontWeight: "600" }}
            >
              {loading ? <Loading style={{ color: "white" }} /> : "LOGIN"}
            </Button>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            {(props.isStore || props.isWarehouse) && (
              <React.Fragment>
                <br />
                <span style={{ color: "#000" }}>
                  Own a {props.isStore ? "Store" : "Warehouse"} ?{" "}
                </span>
                <a
                  onClick={
                    () => props.isStore? props.setRegisterStore(): props.setContact()
                    //  props.openContactModal("seller")
                  }
                  style={{ fontWeight: "700", color: "#3e6df3" }}
                >
                  {props.isStore? "REGISTER" :  "CONTACT US"}
                </a>
              </React.Fragment>
            )}
            {(props.isSeller || props.isCustomer || props.isAffiliate) && (
              <React.Fragment>
                <br />
                <p className="go_to_register">
                  New to{" "}
                  <span style={{ color: "#3e6df3", fontSize: "15px" }}>
                    {" "}
                    {props.companyDetails?.companyName}?
                  </span>{" "}
                  <a
                    onClick={() => handleOpenSignUpModal()}
                    style={{ fontWeight: "600" }}
                  >
                    REGISTER
                  </a>
                </p>
              </React.Fragment>
            )}
          </Form.Item>
        </Form>
      </div>
      <ValidateOtp
        payload={payload}
        visible={isTwoFactorAuth}
        setVisible={setIsTwoFactorAuth}
        setTwoFactorAuth={setTwoFactorAuth}
      />
    </>
  );
};
const mapDispatchToProps = {
  openLoginModal,
  openSignUpModal,
  closeAuthModal,
  openContactModal,
  setPincode,
  updateReferralId,
  updateAffiliateId,
  fetchCompanyDetails
};

const mapStateToProps = (state) => {console.log(state)
  return {
    authModalUserType: state.authModalReducer.userType,
    authModalType: state.authModalReducer.modalType,
    companyDetails: state.homePageReducer.companyDetails
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
