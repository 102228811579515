


import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import { withRouter } from "react-router-dom";
import { MobileOutlined } from "@ant-design/icons";
import Cookies from "universal-cookie";

const cookie = new Cookies();

const CartAddressList = (props) => {
  const [isPrimary, setPrimary] = useState(false);

  const getValue = (value) => {
    if (value !== null) return value ;
    else return "";
  };


  const getAddressFormattedValues = (address) => {
    if(address)
    {

     
      const filterArray=[
        address.addressLine1?address.addressLine1:null,
        address.addressLine2?address.addressLine2:null,
        address.buildingName?address.buildingName:null, 
        address.district?address.district:null
      ];
      console.log("data??",filterArray);
    
      const filtered = filterArray.filter(function (el) {
        return el != null;
      })
      const filteredNullStringValues = filtered.filter(Boolean)
    
      return filteredNullStringValues && filteredNullStringValues.toString().split(',').join(', ');
    }
    
    
        // if (value !== null) return value + ",";
        // else return "";
      };

  useEffect(() => {
    setPrimary(props.address.primary);
  }, [props]);
  return (
    <div className="cart-add-new-address ">
      <div style={{ padding: "10px", width: "100%" }}>
        <div className="cart-total-mrp">
          <Checkbox
            className="carousel-checkbox"
            style={{ margin: "0", paddingLeft: "10px" }}
            onChange={() => props.selectMaster(props.address.id)}
            checked={isPrimary}
          />
        </div>

        <div style={{ width: "100%", marginLeft: "20px" }}>
          <div
            className="cart-address-phone"
            style={{ fontWeight: "600", fontSize: "15px" }}
          >
            {props.address.ownerName}
          </div>
          <div className="cart-address-phone" style={{ marginTop: "8px" }}>
            {/* {getValue(props.address.addressLine1) +
              getValue(props.address.addressLine2)}
            <br />
            {getValue(props.address.buildingName) +
              getValue(props.address.district)}
            <br /> */}
            {getAddressFormattedValues(props.address)}
            <br></br>
            {"Pincode: " + getValue(props.address.pinCode)}
          </div>

          <div className="cart-address-phone" style={{ margin: "10px 0px" }}>
            <MobileOutlined style={{ color: "blue", marginRight: "5px" }} />
            {props.address.phoneNo}
          </div>

          <div className="cart-total-price" style={{ marginBottom: "0" }}>
            <div className="cart-total-mrp"></div>
            {!isPrimary && (
              <div
                onClick={() => props.handleSetDefaultAddress(props.address.id)}
                style={{
                  color: "#3e6df3",
                  fontWeight: "500",
                  fontSize: "13px",
                  cursor: "pointer",
                  textTransform: "uppercase",
                }}
              >
                Set as default address
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CartAddressList;
