import React, { Fragment, useState, useEffect } from 'react'
import { Table, Pagination, Divider } from 'antd';
import {requestStatusHeader} from "./tableHeader"
import FilterRequest from './FilterRequest';
import { getRequestPaymentStatus } from '../../../ApiAction/SellerApiActions/sellerApi';
import moment from 'moment'
import { RequestConstants } from '../../../Utils/constants';

// This component will display the Status of Request Payment
export default function RequestStatusPage(props) {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const tempRowData = []
    const [activeTab] = props.activeTab
    const [searchVal, setSearchVal] = useState("")
    const [offset, setOffset] = useState(0)
    const [totalDoc, setTotalDoc] = useState(30)
    const [currentPage, setCurrentPage] = useState(0)
    const [statusKey, setStatusKey] = useState("")
    const [startTime, setStartTime] = useState("")
    const [endTime, setEndTime] = useState("")
    const [placeHolder] = useState("Search Request IDs (Eg: RQS123)")
    const [downloadType] = useState("vendor")

    useEffect(() => {
        setLoading(true)
        const payload = {
            size: 10,
            offset: offset,
            sortType: "desc",
            sortBy: "requestedDate",
            vendorId: props.vendorId,
            key: searchVal,
            statusKey: statusKey,
            startTime: startTime,
            endTime: endTime
        }
        console.log("Payload")
        console.log(payload)
        getRequestPaymentStatus(payload).then((response)=>{
            console.log(response)
            if(response) {
                if(response.data.status !== 'failed') {
                    mapData(response.data.result)
                    setTotalDoc(response.data.count)
                }else {
                    mapData([])
                }
            }else {
                mapData([])
            }
        }).catch(error => {
            console.log(error.message)
        })

    }, [activeTab, searchVal, offset, statusKey, startTime, endTime])

    const paginationHandler = (page) => {
        setOffset(10* (page - 1))
        setCurrentPage(page)
    }

    const mapData = (tempData) => {
            tempData.map((doc, index) => {
                tempRowData.push({
                    key: index,
                    id: doc.id,
                    version: doc.version,
                    requestId: doc.requestId,
                    requestedDate: moment(doc.requestedDate).format("DD • MM • YYYY (hh:mm A)"),
                    platformCharge: doc?.adjustedAmount,
                    settledAmount: (doc.requestedAmount - doc?.adjustedAmount).toFixed(2),
                    requestedAmount: doc.requestedAmount.toFixed(2),
                    comments: doc.overallStatus.comments,
                    updatedBy: doc.requestStatus === RequestConstants.REQUESTED ? "-" : doc.overallStatus.updatedBy,
                    status: doc.requestStatus,
                    updatedDate: doc.requestStatus === RequestConstants.REQUESTED ? "-" : moment(doc.overallStatus.updatedDate).format("DD • MM • YYYY (hh:mm A)"),
                    transactionId: doc?.transactionId
                })
        })
        setData(tempRowData)
        setLoading(false)
    }

    return (
        <Fragment>
        <FilterRequest
         downloadType={downloadType}
        placeHolder={placeHolder} 
        setSearchVal={setSearchVal} 
        setStatusKey={setStatusKey} 
        setStartTime={setStartTime} 
        setEndTime={setEndTime}
        searchVal={searchVal}
        startTime={startTime}
        endTime={endTime}
        statusKey={statusKey}
        totalDoc={totalDoc}
        setOffset={setOffset}
        setCurrentPage={setCurrentPage}
        offset={offset} />
        <div className="request-table-view">
            {data.length > 0 ? (
                    <Table columns={requestStatusHeader} dataSource={data} pagination={false} scroll={{ x: 1900 }} loading={loading}  />
            ) : (
                        <Table columns={requestStatusHeader} dataSource="" pagination={false} loading={loading} />
            )}
            
            <Divider />
            <Pagination
             defaultCurrent={1}
             total={totalDoc}
             currentPage={currentPage}
             showSizeChanger={false}
             onChange={(page)=>{
                 window.scrollTo(0,0)
                 paginationHandler(page)
             }} />
        </div>
        </Fragment>
    )
}
