import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ENV_TYPE, FILE_PATH } from "../../../../Utils/constants";
import {
  addSellerId,
  addStoreId,
  addBrandFilter,
  addSubCategory00filter,
} from "../../../../ReduxStore/action/productActions";
import { Carousel } from "antd";

function FmcgBrands(props) {
  const { adInfo, categoryId, categoryName } = props;

  useEffect(() => {
    console.log("Banners");
    console.log(adInfo);
  }, []);

  const handleRedirection = (categoryName, Id) => {
    props.addSubCategory00filter(Id);
    if (adInfo[0]?.advertisementType === "STORES") {
      props.addStoreId([adInfo[0]?.id]);
    } else if (adInfo[0]?.advertisementType === "SELLERS") {
      props.addSellerId([adInfo[0]?.id]);
    } else {
      props.addBrandFilter([adInfo[0]?.id]);
    }
    handleManageBreadCrumb(categoryName, Id);
    props.history.push(
      ENV_TYPE === "production"
        ? `/product-listing/${categoryName}`
        : `/customer/product-listing/${categoryName}`
    );
  };

  const handleManageBreadCrumb = (categoryName, Id) => {
    let breadCrumbList = [];
    let breadcrumb = {
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${categoryName}`
          : `/customer/product-listing/${categoryName}`,
      type: "category",
    };
    breadcrumb["label"] = categoryName;
    breadcrumb["id"] = Id;

    breadCrumbList.push(breadcrumb);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  return (
    <Carousel autoplay dots className="fmcg-big-size-ads-container">
      {adInfo?.map((banner, index) => (
        <img
          onClick={() => handleRedirection(categoryName, categoryId)}
          src={FILE_PATH + banner?.images[0]}
          className="fmcg-big-size-ads-image"
          key={index}
        />
      ))}
    </Carousel>
  );
}

const mapDispatchToProps = {
  addSellerId,
  addStoreId,
  addBrandFilter,
  addSubCategory00filter,
};

//export default FmcgBrandAds
export default withRouter(connect(null, mapDispatchToProps)(FmcgBrands));
