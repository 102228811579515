


import React, { useState, useEffect } from "react";
import "./QuantityController.css";
import { message } from "antd";

const QuantityController = (props) => {
  const [value, setValue] = useState(props.quantity);

  const increment = () => {
    if (value + 1 <= props.stock) {
      if (props.isFromCart) props.setQuantity(value + 1, value + 1);
      else {
        props.setQuantity(value + 1, props.item);
      }

      setValue(value + 1);
      // props.setQuantity((prevValue) => prevValue + 1);
    } else {
      message.error(
        "sorry for the inconvinience only " +
          props.stock +
          " stocks are available for now."
      );
    }
  };

  const decrement = () => {
    if (value > 1) {
      if (props.isFromCart) props.setQuantity(value - 1, value - 1);
      else {
        props.setQuantity(value - 1, props.item);
      }

      setValue(value - 1);
    }
    // props.setQuantity((prevValue) => (prevValue > 1 ? prevValue - 1 : 1));
  };
  // useEffect(() => {
  //   console.log(value, ":value here");
  //   if (props.update == true) {
  //     console.log("value updated");
  //     props.setQuantity(value);
  //   }
  // }, [value]);

  useEffect(() => {
    setValue(props.quantity);
  }, [props.quantity]);

  return (
    <div className="quantity-main">
      <div className="quantity-input">
        <button
          className="quantity-input__modifier quantity-input__modifier--left cart-quantity-controller"
          style={{ color: "#07044c", fontWeight: "500" }}
          onClick={() => decrement()}
        >
          &mdash;
        </button>
        <input
          className="quantity-input__screen"
          style={{ color: "#07044c", fontWeight: "500" }}
          type="text"
          value={value}
          readOnly
        />
        <button
          className="quantity-input__modifier quantity-input__modifier--right cart-quantity-controller"
          style={{ color: "#07044c", fontWeight: "500" }}
          onClick={() => increment()}
        >
          &#xff0b;
        </button>
      </div>
    </div>
  );
};

export default QuantityController;
