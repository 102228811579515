

import React, { useState, useEffect } from "react";

import { Typography, Pagination } from "antd";
import { connect } from "react-redux";

import SortIcon from "../../../Assets/Images/Icons/Seller/Sort2.svg";
import { withRouter } from "react-router-dom";

import { getProducts } from "../../../ApiAction/SellerApiActions/sellerApi";
import Cookies from "universal-cookie";
import ManagaProductCardDraft from "../ManageProducts/Components/managaProductCardDraft";
import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";
import EmptyList from "../../GeneralComponents/emptyList";

const { Title, Text } = Typography;

const ManageStocks = (props) => {
  const cookies = new Cookies();
  const [isMobileView, setisMobileView] = useState(false);
  const [type, setType] = useState("draft");
  const [productList, setProductList] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [bulkUploadModalVisible, setBulkUploadModalVisible] = useState(false);
  const [isLoadingStock, setisLoadingStock] = useState(false);

  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  useEffect(() => {
    console.log("ManageStocks props are : ", props);
    getProductsList(props.searchVal, 1);
  }, [props.relevance, props.sort, props.searchVal]);

  const singleClick = (id = 123) => {
    if (props.isWarehouse)
      props.history.push(`/warehouse/manage-price-stock/${id}`);
    else if (props.isStore)
      props.history.push(`/store/manage-price-stock/${id}`);
    else props.history.push(`/seller/manage-price-stock/${id}`);
  };

  const getProductsList = (search = "", page) => {
    setCurrentPage(page);
    // const term = [{ active: ["true"] }];
    const term = [];
    term.push({
      vendorId: [cookies.get("vendorId")],
    });
    term.push({
      archive: ["false"],
    });

    const payload = {
      offset: 10 * (page - 1),
      size: 10,

      key: search,
      sortBy: props.relevance
        ? ""
        : props?.sort?.sortType == "name"
        ? "name.keyword"
        : "lastModifiedDate",
      sortType: props.relevance
        ? ""
        : props.sort?.sortType
        ? props.sort.sortBy
        : "asc",

      filter: {
        term: term,
      },
    };
    setisLoadingStock(true);
    getProducts(payload)
      .then((response) => {
        if (response.data.status == "success") {
          setProductList(response.data.products);
          setTotal(Math.ceil(response.data.count / 10) * 10);
        }
      })
      .catch(() => {})
      .finally(() => {
        setisLoadingStock(false);
      });
  };

  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        {" "}
        {!isMobileView ? (
          <h1 level={4} className="sub-title-tags">
            {" "}
            Available Products
          </h1>
        ) : (
          <Text>
            {" "}
            <span>
              {" "}
              <img
                src={SortIcon}
                alt="delete-icon"
                style={{ width: "25px", paddingRight: "5px" }}
              />
            </span>
            Sort
          </Text>
        )}
      </div>
      {isLoadingStock ? (
        <LoadingCartItem />
      ) : (
        productList?.length === 0 && <EmptyList msg={"No Products found"} />
      )}
      {productList.map((data) => (
        <ManagaProductCardDraft
          product={data}
          key={data.source.productId}
          isStock
          singleClick={singleClick}
        />
      ))}
      {total > 10 && (
        <div className="admin-manage-customer">
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            total={total}
            showSizeChanger={false}
            className="customer-pagination"
            onChange={(page) => {
              getProductsList(props.searchVal ? props.searchVal : "", page);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default withRouter(connect(mapStateToProps)(ManageStocks));
