import { Divider, message } from "antd";
import Search from "antd/lib/input/Search";
import React, { useState } from "react";
import { MdSearch } from "react-icons/md";
import { universalBarcodeSearch } from "../../../ApiAction/AdminApiAction/adminApi";
import dummyImage from "../../../Assets/Images/PlaceHolderImages/Product_Image_Placeholder.jpg";
import { FILE_PATH } from "../../../Utils/constants";

function BarcodeSearch() {
  const [productData, setProductData] = useState([]);
  const [barcodeSearching, setBarcodeSearching] = useState(false);
  const [searchedBarcode, setSearchedBarcode] = useState("");
  const [searchedValue, setSearchedValue] = useState("");

  const handleBarcodeSearch = (barcode) => {
    setBarcodeSearching(true);
    if (barcode?.length > 0) {
      const payload = {
        barcode: barcode,
        size: 30,
        offset: 0,
        sortBy: "addedDate",
        sortType: "asc",
      };
      universalBarcodeSearch(payload)
        .then((response) => {
          if (response?.data?.status === "success") {
            setProductData(response?.data?.products);
            setSearchedValue(barcode);
          } else {
            message.error(response?.data?.message);
          }
        })
        .catch()
        .finally(() => {
          setBarcodeSearching(false);
        });
    }
  };

  return (
    <div className="category-management-container">
      <h1 className="content-name-header sub-title-tags" level={4}>
        <MdSearch /> Barcode Search
      </h1>
      <Divider />
      <Search
        placeholder="Search your Barcode here."
        size="large"
        loading={barcodeSearching}
        value={searchedBarcode}
        onChange={(e) => setSearchedBarcode(e.target.value)}
        onSearch={(value) => handleBarcodeSearch(value)}
      />
      <div className="barcode-main-container-view">
        {productData?.map((data, index) => (
          <div className="barcode-container-view-box" key={index}>
            <div className="barcode-container-image-box">
              {data?.images?.length > 0 ? (
                <img
                  alt="Product Pic"
                  src={FILE_PATH + data?.images[0]}
                  className="barcode-container-product-image"
                />
              ) : (
                <img
                  alt="Product Pic"
                  src={dummyImage}
                  className="barcode-container-product-image"
                />
              )}
            </div>
            <div>
              <h2 className="barcode-container-main-heading">{data?.name}</h2>
              <h3>
                {data?.vendor}
                <strong>[{data?.vendorType}]</strong>
              </h3>

              <Divider />

              <div className="barcode-variant-container">
                {data?.productBatches?.filter((item)=> item?.active !== false )?.map((batches, index2) => (
                  <>
                    {batches?.priceAndStocks?.map((priceAndStock, index3) => (
                      <div
                        className={
                          priceAndStock?.barcode === searchedValue
                            ? "searchedBarcode-variant"
                            : "barcode-variant-small-box"
                        }
                        key={index3}
                      >
                        <h4>
                          <strong>
                            {priceAndStock?.quantity} {priceAndStock?.unit}
                          </strong>
                        </h4>
                        <p>
                          STOCKS <strong>{priceAndStock?.stock}</strong>
                        </p>
                        <p>{priceAndStock?.barcode}</p>
                      </div>
                    ))}
                  </>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BarcodeSearch;
