


import React, { useRef } from "react";
import { Modal, Typography, Divider, Button } from "antd";
import ReactToPrint from "react-to-print";

import OrderDetails from "../../Cart/OrderResponseComponents/Components/orderDetails";

const { Text } = Typography;

const Invoice = (props) => {
  const componentRef = useRef();
  const { visible, setvisible, orderSummary } = props;

  const RenderBasicInfo = ({ data }) => {
    return (
      <div className="odr-sm-info">
        <Text type="secondary">{data.key} : </Text>
        <Text strong>{data.value} </Text>
      </div>
    );
  };
  return (
    <Modal
      title="Order Details"
      visible={visible}
      onOk={() => setvisible(false)}
      onCancel={() => setvisible(false)}
      footer={null}
      width={700}
      className="ord_dlt_pop_ext"
      ref={componentRef}
    >
      <div ref={componentRef} style={{ padding: "20px" }}>
        <RenderBasicInfo
          data={{ key: "Ordered By", value: orderSummary?.customerName }}
        />
        <RenderBasicInfo
          data={{
            key: "Contact",
            value: orderSummary?.shippingAddress?.phoneNo,
          }}
        />
        <Divider />
        <OrderDetails orderSummary={orderSummary} />
      </div>
      <ReactToPrint
        trigger={() => <Button type="primary">Print Invoice</Button>}
        content={() => componentRef.current}
      />
    </Modal>
  );
};

export default Invoice;
