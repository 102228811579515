

import React, { useEffect } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
import { createBrowserHistory } from "history";
import packageJson from "../package.json";
import { LastLocationProvider } from "react-router-last-location";

import "./App.scss";
import ThemeModeSetup from "./ThemeModeSetup";
import MainLayout from "./Layouts/MainLayout";
import Admin from "./components/Admin";
import Seller from "./components/Seller";
import Login from "./components/GeneralComponents/Login";
import SignUp from "./components/GeneralComponents/Signup";
import Store from "./components/Store";
import Warehouse from "./components/Warehouse";
import LogHome from "./components/Admin/Logger/LogHome/LogHome";

import MetaDecorator from "./Utils/MetaDecorator";
import CacheBuster from "./CacheBuster";

import EmailVerificationModal from "./components/GeneralComponents/EmailVerification";
import { ENV_TYPE, DOMAIN, SSLHTTP } from "./Utils/constants";
import PageNotFound from "./components/GeneralComponents/pageNotFound";
import LogLogin from "./components/Admin/Logger/LogLogin";
import WorkReport from "./components/WorkReport";
import VerifyPayment from "./components/Customer/Components/Cart/verifyPayment";
import Affiliate from "./components/Affiliate";

export const history = createBrowserHistory();
global.appVersion = packageJson.version;
const cookies = new Cookies();
function App() {
  useEffect(() => {
    if (
      localStorage.getItem("authToken") == null &&
      sessionStorage.getItem("authToken") == null
    ) {
      localStorage.removeItem("activeCustomer");
      // cookies.remove("accountType", { path: "/" });
      cookies.remove("userId", { path: "/" });
      cookies.remove("vendorId", { path: "/" });
      if (ENV_TYPE == "production") {}
      else
        switch (cookies.get("accountType")) {
          case "customer":
            history.push("/customer");
            break;
          case "store":
            history.push("/login-store");
            break;
          case "seller":
            history.push("/login-seller");
            break;
          case "warehouse":
            history.push("/login-warehouse");
            break;
          case "admin":
            history.push("/login-admin");
            break;
          case "logger":
            history.push("/logger-admin");
            break;
          default:
            console.log("invalid case");
        }
      cookies.remove("accountType", { path: "/" });
    }
    cookies.remove("activeUserPincode", { path: "/" });
  });

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <>
            <MetaDecorator />
            <Router history={history}>
              <LastLocationProvider>
                <Switch>
                  <Route
                    path="/activation/:activationId"
                    render={(props) => (
                      <EmailVerificationModal {...props} visible={true} />
                    )}
                  />
                  {ENV_TYPE == "production" ? (
                    <>
                      {window.location.href.includes(
                        `${SSLHTTP}://${DOMAIN}/`
                      ) ? (
                        <Switch>
                          <Route
                            path="/"
                            render={(props) => (
                              <MainLayout {...props} isCustomer />
                            )}
                          />
                          {/* <Route
                    exact
                    path="/404"
                    render={(props) => {
                      return <PageNotFound />;
                    }}
                  />
                  <Route
                    render={(props) => {
                      return <Redirect to="/404" />;
                    }}
                  /> */}
                        </Switch>
                      ) : (
                        <Switch>
                          <Route
                            exact
                            path="/"
                            render={(props) =>
                              window.location.href ===
                              `${SSLHTTP}://agency.${DOMAIN}/` ? (
                                <Login {...props} isStore />
                              ) : window.location.href ===
                                `${SSLHTTP}://hub.${DOMAIN}/` ? (
                                <Login {...props} isWarehouse />
                              ) : window.location.href ===
                                `${SSLHTTP}://seller.${DOMAIN}/` ? (
                                <>
                                  <Login {...props} isSeller />
                                  <SignUp {...props} isSeller />
                                </>
                              ) :  window.location.href ===
                              `${SSLHTTP}://archon.${DOMAIN}/` ? (

                                  <Login {...props} isAdmin />
                              ) : window.location.href ===
                                  `${SSLHTTP}://worklog.${DOMAIN}/` ? (
                                  <Login {...props} isWorkAdmin />
                              ) : ( window.location.href ===
                                `${SSLHTTP}://affiliate.${DOMAIN}/` ? (
                                  <Login {...props} isAffiliate />
                                ) : (
                                  window.location.href ===
                                    `${SSLHTTP}://logger.${DOMAIN}/` && (
                                    <LogLogin {...props} isLogger />
                                  )
                                )
                              )

                            }
                          />
                          {/* <Route
                    exact
                    path="/404"
                    render={(props) => {
                      return <PageNotFound />;
                    }}
                  />
                  <Route
                    render={(props) => {
                      return <Redirect to="/404" />;
                    }}
                  /> */}
                        </Switch>
                      )}

                      {/* <Route
                path="/customer"
                render={(props) => (
                  <>
                    <MainLayout {...props} isCustomer />
                  </>
                )}
              /> */}

                      <Route
                        path="/seller"
                        render={(props) => <Seller {...props} />}
                      />

                      <Route
                        path="/admin"
                        render={(props) => <Admin {...props} />}
                      />

                      <Route
                        path="/store"
                        render={(props) => <Store {...props} />}
                      />

                      <Route
                        path="/warehouse"
                        render={(props) => <Warehouse {...props} />}
                      />

                      <Route
                        path="/log"
                        render={(props) => <LogHome {...props} />}
                      />

                      <Route
                        path="/work-report"
                        render={(props) => <WorkReport {...props} />}
                      />

                      <Route
                        path="/affiliate"
                        render={(props) => <Affiliate {...props} />}
                      />

                      <Route
                        exact
                        path="/verify-payment/:orderGroupId"
                        render={(props) => {
                          return <VerifyPayment {...props} signUp/>;
                        }}
                      />

                    </>
                  ) : (
                    <Switch>
                      <Route
                        exact
                        path="/"
                        render={(props) => {
                          return <Redirect to="/customer" />;
                        }}
                      />
                      <Route
                        exact
                        path="/404"
                        render={(props) => {
                          return <PageNotFound />;
                        }}
                      />

                      <Route
                        path="/login-store"
                        render={(props) => <Login {...props} isStore />}
                      />
                      <Route
                        exact
                        path="/verify-payment/:orderGroupId"
                        render={(props) => {
                          return <VerifyPayment {...props} signUp/>;
                        }}
                      />
                      <Route
                        path="/login-warehouse"
                        render={(props) => <Login {...props} isWarehouse />}
                      />
                      <Route
                        path="/login-seller"
                        render={(props) => (
                          <>
                            {" "}
                            <Login {...props} isSeller />
                            <SignUp {...props} isSeller />
                          </>
                        )}
                      />
                      <Route
                        path="/login-admin"
                        render={(props) => <Login {...props} isAdmin />}
                      />
                      <Route
                        path="/logger-admin"
                        render={(props) => <LogLogin {...props} isLogger />}
                       />

                      <Route
                        path="/login-work-report"
                        render={(props) => <Login {...props} isWorkAdmin />}
                      />

                      <Route
                        path="/login-affiliate"
                        render={(props) => (
                          <>
                            <Login {...props} isAffiliate />
                            <SignUp {...props} isAffiliate />
                          </>
                        )}
                      />

                      <Route
                        path="/customer"
                        render={(props) => (
                          <>
                            {/* <ThemeModeSetup/> */}
                            <MainLayout {...props} isCustomer />
                          </>
                        )}
                      />

                      <Route
                        path="/seller"
                        render={(props) => <Seller {...props} />}
                      />

                      <Route
                        path="/admin"
                        render={(props) => <Admin {...props} />}
                      />

                      <Route
                        path="/store"
                        render={(props) => <Store {...props} />}
                      />

                      <Route
                        path="/warehouse"
                        render={(props) => <Warehouse {...props} />}
                      />

                      <Route
                        path="/log"
                        render={(props) => <LogHome {...props} />}
                      />

                      <Route
                        path="/work-report"
                        render={(props) => <WorkReport {...props} />}
                      />

                      <Route
                        path="/affiliate"
                        render={(props) => <Affiliate {...props} />}
                      />

                      <Route
                        render={(props) => {
                          return <Redirect to="/404" />;
                        }}
                      />
                    </Switch>
                  )}
                </Switch>
              </LastLocationProvider>
            </Router>
          </>
        );
      }}
    </CacheBuster>
  );
}

export default App;
