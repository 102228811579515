

/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, Fragment } from "react";
import { Card, Typography, Button, Radio, Modal, Input } from "antd";
import { withRouter } from "react-router-dom";
import "./manageProductCard.css";
import Cookies from "universal-cookie";

import EditIcon from "../../../../Assets/Images/Icons/Seller/edit.svg";
import EditIconMobile from "../../../../Assets/Images/Icons/Seller/editMobile.svg";
import DeleteIcon from "../../../../Assets/Images/Icons/Seller/delete.svg";
import DeactivateIcon from "../../../../Assets/Images/Icons/Seller/deactivate.svg";
import NoImage from "../../../../Assets/Images/PlaceHolderImages/placeholder-product-card.jpg";
import { FILE_PATH, ENV_TYPE } from "../../../../Utils/constants";
import {
  updateSellerProduct,
  getSellerProfile,
} from "../../../../ApiAction/SellerApiActions/sellerApi";
import { BsChevronDoubleRight } from "react-icons/bs";
import LazyImage from "../../ManageOrder/CustomLazyImage";
import moment from "moment";
import { RightCircleFilled } from "@ant-design/icons";
import { GoDiff, GoDiffModified, GoVerified } from "react-icons/go";

const confirm = Modal.confirm;
const warning = Modal.warning;
const { Text } = Typography;
const ManageProductCard = (props) => {
  const { isCustomer, isCentralRepo, refetch, type } = props;
  const cookies = new Cookies();
  const [quantity, setquantity] = useState();
  const [isMobileView, setisMobileView] = useState(false);
  const [active, setActive] = useState(false);

  const [processedPrice, setProcessedPrice] = useState();
  const [price, setPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [expiryDate, setExpiryDate] = useState();
  const [hsnCode, setHsnCode] = useState();
  const [taxPercent, setTaxPercent] = useState();
  const [margin, setMargin] = useState(0);
  const [vendorType] = useState(props?.product?.source?.vendorType);
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [batch, setBatch] = useState("");
  const [categoryCommission, setCategoryCommission] = useState(0);
  const [platformCharge, setPlatformCharge] = useState(0);
  const [dealerPrice, setDealerPrice] = useState(0);
  const [qtyType, setQtyType] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [rejectedMessage, setRejectedMessage] = useState("");

  const options = [
    { label: "100g", value: "100g" },
    { label: "200g", value: "200g" },
    { label: "500g", value: "500g" },
  ];

  useEffect(() => {
    setProcessedPrice(props.product.source.processedPriceAndStock);
    setHsnCode(props.product.source.hsnCode);
    setTaxPercent(props.product.source.taxPerCent);

    if (
      props.product.source.processedPriceAndStock &&
      props.product.source.processedPriceAndStock.length > 0
    ) {
      setSelectedItem(props.product.source.processedPriceAndStock[0]);
      setPrice(props.product.source.processedPriceAndStock[0].price);
      setSellingPrice(
        props.product.source.processedPriceAndStock[0].sellingPrice
      );
      setExpiryDate(
        moment(
          props?.product?.source?.processedPriceAndStock[0]?.expiryDate
        ).format("YYYY-MM-DD")
      );
      setMargin(
        props.product.source.processedPriceAndStock[0].sellingPrice -
          props.product.source.processedPriceAndStock[0].dealerPrice
      );
      setDealerPrice(
        props?.product?.source?.processedPriceAndStock[0].dealerPrice
      );
      setSelectedVariant(
        props.product.source.processedPriceAndStock[0].serialNumber
      );
      setAvailableQuantity(
        props.product.source.processedPriceAndStock[0].stock
      );
      setQtyType(props?.product?.source?.processedPriceAndStock[0]?.stock);
      if (vendorType === "SELLER") {
        getSellerProfile()
          .then((response) => {
            if (response?.data?.status === "success") {
              if (
                response?.data?.profile?.source?.tradeDetails?.isCompanyGst === false
              ) {
                setCategoryCommission(5);
              } else {
                setCategoryCommission(7.5);
              }
            }
          })
          .catch((error) => {
            console.log(
              "Error in Getting Seller Details in Batch ::",
              error.message
            );
          })
          .finally();
      } else {
        setCategoryCommission(props?.product?.source?.categoryCommission);
      }

      setBatch(props.product.source.processedPriceAndStock[0].batchNumbers[0]);
      setSelectedDiscount(
        props.product.source.processedPriceAndStock[0].discount
      );
    }
  }, [props]);

  useEffect(() => {
    let tempPc = sellingPrice * (categoryCommission / 100);
    setPlatformCharge(tempPc);
  }, [sellingPrice, categoryCommission]);

  const changeVariant = (item) => {
    setSelectedItem(item);
    setPrice(item.price);
    setSellingPrice(item.sellingPrice);
    setSelectedVariant(item.serialNumber);
    setAvailableQuantity(item.quantity);
    setSelectedDiscount(item.discount);
    setExpiryDate(moment(item.expiryDate).format("YYYY-MM-DD"));
    setMargin(item.sellingPrice - item.dealerPrice);
    setDealerPrice(item.dealerPrice);
    setQtyType(item.stock);
  };
  //to add selected productName to bread crumb
  const handleManageBreadCrumb = (data) => {
    let breadCrumbList = localStorage.getItem("breadCrumbData")
      ? JSON.parse(localStorage.getItem("breadCrumbData"))
      : [];
    breadCrumbList.push(data);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  const handleRedirection = () => {
    if (isCustomer) {
      props.history.push(
        ENV_TYPE === "production"
          ? `/product-detail/${props.product.productId}`
          : `/customer/product-detail/${props.product.productId}`
      );

      const data = {
        label: props.product.productName,
        url:
          ENV_TYPE === "production"
            ? `/product-detail`
            : `/customer/product-detail`,
        type: "product",
        id: props.product.productId,
      };
      handleManageBreadCrumb(data);
    }
  };

  const onChange3 = (e) => {
    setquantity(e.target.value);
  };
  useEffect(() => {
    window.innerWidth <= 760 ? setisMobileView(true) : setisMobileView(false);
  }, []);

  const deleteProduct = (id) => {
    confirm({
      title: "Do you want to delete this product!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        props.deleteProduct(id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const approveProduct = (id, type) => {
    confirm({
      title:
        type === "approve"
          ? "Do you want to approve this product!!"
          : "Do you want to reject this product!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        props.approveProduct(id, type, rejectedMessage);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deactivateProduct = (id, value) => {
    confirm({
      title: `"Do you want to ${
        value ? "deactivate" : "activate"
      } this product!!"`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        // props.deactivate(id)
        const payload = {
          active: !value,
          isDraft: value,
        };
        const payloadEdit = {
          id: id,
          product: payload,
        };
        updateSellerProduct(payloadEdit)
          .then((res) => {
            setActive(!value);
            if (res.data.status === "success") {
              refetch();
            }
          })
          .catch((error) => {})
          .finally(() => {});
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const publish = (id) => {
    confirm({
      title: "Do you want to move this product to listing!!",
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        props.publish(props.product.source.productId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //rejection modify for product
  const rejectModify = () => {
    console.log("STATUS REJ::" + props?.product?.source?.rejectionModified);
    warning({
      title: "Warning",
      content:"Before Republish you need to make sure that all the faults are solved.",
      className: "create-confirm-modal",
      centered: true,
      // cancelText: "No",
      okText: "OK",
      onOk: () => {
        console.log("OK");
      },
      // onCancel() {
      //   console.log("Cancel");
      // },
    });
  };


  useEffect(() => {
    !isCustomer && setActive(props.product.source.active);
  }, [props]);

  //reusing this component in product listing mobile view of customer with condition isCustomer//
  return (
    <Card
      hoverable
      key={props.product.source.productId}
      bordered={false}
      height={250}
      style={{ width: "100%", marginBottom: "20px", marginTop: "10px" }}
      className="manage-product-card-container"
      onClick={() => handleRedirection()}
    >
      <div style={{ display: "flex" }}>
        <div>
          {isCustomer ? (
            /* <img
              className="seller-manage-product-card-image"
              src={
                props.product.productImage
                  ? FILE_PATH + props.product.productImage
                  : NoImage
              }
            /> */
            <LazyImage
              classname="seller-manage-product-card-image"
              src={
                props.product.productImage
                  ? FILE_PATH + props.product.productImage
                  : NoImage
              }
              alt={
                isCustomer
                  ? props.product.productName
                  : props.product.source.name
              }
            />
          ) : (
            /* <img
              className="seller-manage-product-card-image"
              src={
                props.product.source.images
                  ? props.product.source.images.length > 0
                    ? FILE_PATH + props.product.source.images[0]
                    : NoImage
                  : NoImage
              }
            /> */

            <LazyImage
              classname="seller-manage-product-card-image"
              src={
                props.product.source.images
                  ? props.product.source.images.length > 0
                    ? FILE_PATH + props.product.source.images[0]
                    : NoImage
                  : NoImage
              }
              alt={
                isCustomer
                  ? props.product.productName
                  : props.product.source.name
              }
            />
          )}
        </div>

        <div className="seller-manage-product-card-summary">
          <div
            style={{
              maxWidth: "500px",
            }}
          >
            {isMobileView ? (
              <Text style={{ fontSize: "12px" }} strong>
                {isCustomer
                  ? props.product.productName
                  : props.product.source.name}
              </Text>
            ) : (
              <Text style={{ fontSize: "20px" }} strong>
                {isCustomer
                  ? props.product.productName
                  : props.product.source.name}
              </Text>
            )}
          </div>
          {/* Showing Product Category */}
          <div>
            {isMobileView ? (
              <Fragment>
                <Text style={{ fontSize: "10px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props.product.categoryName
                    : props.product.source.categoryName}
                </Text>
                <BsChevronDoubleRight
                  style={{
                    fontSize: "12px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    top: "4px",
                    position: "relative",
                  }}
                />
                <Text style={{ fontSize: "10px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props.product.subCategory0Name
                    : props.product.source.subCategory0Name}
                </Text>
                <BsChevronDoubleRight
                  style={{
                    fontSize: "12px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    top: "4px",
                    position: "relative",
                  }}
                />
                <Text style={{ fontSize: "10px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props?.product?.subCategory1Name
                    : props?.product?.source?.subCategory1Name}
                </Text>
                <BsChevronDoubleRight
                  style={{
                    fontSize: "12px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    top: "4px",
                    position: "relative",
                  }}
                />
                <Text style={{ fontSize: "10px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props?.product?.subCategory2Name
                    : props?.product?.source?.subCategory2Name}
                </Text>
                <BsChevronDoubleRight
                  style={{
                    fontSize: "12px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    top: "4px",
                    position: "relative",
                  }}
                />
                <Text style={{ fontSize: "10px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props?.product?.subCategory3Name
                    : props?.product?.source.subCategory3Name}
                </Text>
              </Fragment>
            ) : (
              <div style={{ display: "flex" }}>
                <Text style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props.product.categoryName
                    : props.product.source.categoryName}
                </Text>
                <BsChevronDoubleRight
                  style={{
                    fontSize: "12px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    top: "4px",
                    position: "relative",
                  }}
                />
                <Text style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props.product.subCategory0Name
                    : props.product.source.subCategory0Name}
                </Text>
                <BsChevronDoubleRight
                  style={{
                    fontSize: "12px",
                    width: "20px",
                    display: "flex",
                    justifyContent: "center",
                    top: "4px",
                    position: "relative",
                  }}
                />
                <Text style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props?.product?.subCategory1Name
                    : props?.product?.source?.subCategory1Name}
                </Text>

                {isCustomer
                  ? props?.product?.subCategory2Name
                  : props?.product?.source?.subCategory2Name && (
                      <BsChevronDoubleRight
                        style={{
                          fontSize: "12px",
                          width: "20px",
                          display: "flex",
                          justifyContent: "center",
                          top: "4px",
                          position: "relative",
                        }}
                      />
                    )}

                <Text style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props?.product?.subCategory2Name
                    : props?.product?.source?.subCategory2Name}
                </Text>
                {isCustomer
                  ? props?.product?.subCategory3Name
                  : props?.product?.source.subCategory3Name && (
                      <BsChevronDoubleRight
                        style={{
                          fontSize: "12px",
                          width: "20px",
                          display: "flex",
                          justifyContent: "center",
                          top: "4px",
                          position: "relative",
                        }}
                      />
                    )}

                <Text style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
                  {isCustomer
                    ? props?.product?.subCategory3Name
                    : props?.product?.source.subCategory3Name}
                </Text>
              </div>
            )}
          </div>

          {props.product?.source?.reason?.length > 0 && (
            <>
              <div className="thermal-padding"></div>
              <div className="thermal-padding"></div>
              <div className="vendor-product-stats-container">
                <h4>REJECTED DUE TO </h4>

                <p style={{ color: "red" }}>
                  <RightCircleFilled />
                  {props.product?.source?.reason}
                </p>
              </div>
            </>
          )}
          <br />

          {props.isAdmin? (
            <div className="vendor-product-stats-container">
                <Fragment>
                  <div className="addedType-container">
                    {props?.product?.source?.addedType === "admin" ? (
                      <p className="addedType-tags addedType-admin">ADMIN</p>
                    ) : (
                      <Fragment>
                        {props?.product?.source?.addedType === "store" ? (
                          <p className="addedType-tags addedType-store">STORE</p>
                        ) : (
                          <Fragment>
                            {props?.product?.source?.addedType === "seller" ? (
                              <p className="addedType-tags addedType-seller">
                                SELLER
                              </p>
                            ) : (
                              <Fragment>
                                {props?.product?.source?.addedType ===
                                  "warehouse" && (
                                  <p className="addedType-tags addedType-warehouse">
                                    HUB
                                  </p>
                                )}
                              </Fragment>
                            )}
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                    {props?.product?.source?.verified && (
                      <p className="verified-tag">
                      <div style={{paddingRight: "0.2em", paddingTop: "0.1em"}}><GoVerified></GoVerified></div>
                      Verified
                      </p>
                    )}
                    {props?.product?.source?.hsnCodeModifiedDate && (
                      <p className="hsn-modified-tag">
                      <div style={{paddingRight: "0.2em", paddingTop: "0.1em"}}><GoDiffModified></GoDiffModified></div>
                      HSN Modified
                      </p>
                    )}
                  </div>
                  <p>
                    Request By :{" "}
                    <strong>
                      {" "}
                      {props.product?.source?.vendor
                        ? props.product.source.vendor
                        : "Vendor Name"}
                    </strong>
                  </p>
                  {props?.product?.source?.status === "APPROVED" && (
                    <Fragment>
                      {props?.product?.source?.approvedBy && (
                        <Fragment>
                          <p>
                            Approved By :{" "}
                            <strong>{props.product?.source?.approvedBy} </strong>
                          </p>
                          <p>
                            Approved On :{" "}
                            <strong>
                              {moment(
                                parseInt(props.product?.source?.approvedDate)
                              ).format("YYYY-MM-DD hh:MM A")}
                            </strong>
                          </p>
                        </Fragment>
                      )}
                    </Fragment>
                  )}

                  {props?.product?.source?.status === "REJECTED" && (
                    <Fragment>
                      {props?.product?.source?.rejectedBy && (
                        <Fragment>
                          <p>
                            Rejected By :{" "}
                            <strong>{props.product?.source?.rejectedBy}</strong>
                          </p>
                          <p>
                            Rejected On :{" "}
                            <strong>
                              {moment(
                                parseInt(props.product?.source?.rejectedDate)
                              ).format("YYYY-MM-DD hh:MM A")}
                            </strong>
                          </p>
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                </Fragment>
            </div>
          ) : (
            <div className="addedType-container">
              {props?.product?.source?.verified && (
                <p className="verified-tag">
                <div style={{paddingRight: "0.2em", paddingTop: "0.1em"}}><GoVerified></GoVerified></div>
                Verified
                </p>
              )}
              {props?.product?.source?.hsnCodeModifiedDate && (
                <p className="hsn-modified-tag">
                <div style={{paddingRight: "0.2em", paddingTop: "0.1em"}}><GoDiffModified></GoDiffModified></div>
                HSN Modified
                </p>
              )}
            </div>
          )}

          <div className="seller-manage-product-card-summary-price">
            Selling Price
            <Text strong style={{ color: "#3e6df3" }}>
              ₹{" "}
              {Number(sellingPrice) % 1 !== 0
                ? Number(sellingPrice).toFixed(2)
                : sellingPrice}
            </Text>{" "}
            {/* <Text delete>₹ {price}</Text>{" "} */}
          </div>
          <div className="seller-manage-product-card-summary-price">
            MRP
            <Text delete>₹ {price}</Text>{" "}
          </div>

          {isMobileView ? (
            <div className="seller-manage-product-card-summary-price">
              Discount
              <Text style={{ color: "green", fontSize: "10px" }}>
                {Number(selectedDiscount) % 1 !== 0
                  ? Number(selectedDiscount).toFixed(2)
                  : selectedDiscount}
                % off
              </Text>
            </div>
          ) : (
            <div className="seller-manage-product-card-summary-price">
              Discount
              <Text style={{ color: "green", fontSize: "12px" }}>
                {Number(selectedDiscount) % 1 !== 0
                  ? Number(selectedDiscount).toFixed(2)
                  : selectedDiscount}
                % off
              </Text>
            </div>
          )}
          {props.isAdmin && (
            <Fragment>
              <div className="seller-manage-product-card-summary-price">
                ExP:
                <Text strong style={{ color: "#3e6df3" }}>
                  {expiryDate}
                </Text>
              </div>
              <div className="seller-manage-product-card-summary-price">
                Stock
                <Text strong style={{ color: "#3e6df3" }}>
                  {qtyType}
                </Text>
              </div>
              <div className="seller-manage-product-card-summary-price">
                D.P:
                <Text strong style={{ color: "#3e6df3" }}>
                  ₹ {dealerPrice.toFixed(2)}
                </Text>
              </div>
              <div className="seller-manage-product-card-summary-price">
                Margin:{" "}
                <Text strong style={{ color: "#3e6df3" }}>
                  ₹ {margin.toFixed(2)}
                </Text>
              </div>
              <div className="seller-manage-product-card-summary-price">
                Platform Charge:{" "}
                <Text strong style={{ color: "#3e6df3" }}>
                  ₹ {platformCharge.toFixed(2)} ({categoryCommission}%)
                </Text>
              </div>
              <div className="seller-manage-product-card-summary-price">
                HSN Code:
                <Text strong style={{ color: "#3e6df3" }}>
                  {hsnCode}
                </Text>
              </div>
              <div className="seller-manage-product-card-summary-price">
                Tax Percent:
                <Text strong style={{ color: "#3e6df3" }}>
                  {taxPercent}%
                </Text>
              </div>
            </Fragment>
          )}

          {isCustomer && (
            <div>
              <Text style={{ fontSize: "10px" }}>1 kg</Text>
            </div>
          )}

          <br />
          {!isCustomer && (
            // <Radio.Group
            //   size="small"
            //   defaultValue="100g"
            //   options={options}
            //   onChange={onChange3}
            //   value={quantity}
            //   optionType="button"
            // />
            <div
              className="flex"
              style={{ maxWidth: "415px", flexWrap: "wrap" }}
            >
              {processedPrice &&
                !isCentralRepo &&
                processedPrice.map((item) => (
                  <Button
                    className={
                      selectedVariant == item.serialNumber
                        ? "button-product-detail-item-sizes-selected"
                        : "button-product-detail-item-sizes"
                    }
                    onClick={() => changeVariant(item)}
                  >
                    {`${item.quantity} ${item.unit}`}
                  </Button>
                ))}
            </div>
          )}

          <Modal
            title="Product Rejection"
            visible={showRejectModal}
            onOk={() => {
              approveProduct(props.product.source.productId, "reject");
            }}
            onCancel={() => {
              setShowRejectModal(false);
            }}
          >
            <p>
              Give some reason for Rejecting the Product, so that the vendor can
              correct and RePublish.
            </p>
            <Input
              placeholder="Type your Reason"
              className="stock-variant-input"
              value={rejectedMessage}
              onChange={(e) => {
                setRejectedMessage(e.target.value);
              }}
              autoFocus={true}
            />
          </Modal>
        </div>

        {/* //filtering components to shown in seller manage products and customer product listing// */}
        {!isCustomer ? (
          <div className="seller-manage-products-card-action">
            {!isMobileView ? (
              <Button
                className="seller-manage-products-card-action-button-edit"
                type="primary"
                onClick={() => {
                  if (isCentralRepo) {
                    props.history.push(
                      `/admin/central-repo/edit-product/${props.product.source.productId}`
                    );
                  } else if (props.isAdmin)
                    props.history.push(
                      `/admin/edit-product/${props.product.source.productId}`
                    );
                  else {
                    switch (cookies.get("accountType")) {
                      case "seller":
                        props.history.push(
                          `/seller/edit-product/${props.product.source.productId}`
                        );
                        break;

                      case "warehouse":
                        props.history.push(
                          `/warehouse/edit-product/${props.product.source.productId}`
                        );
                        break;
                      case "store":
                        props.history.push(
                          `/store/edit-product/${props.product.source.productId}`
                        );
                        break;
                      default:
                        console.log("invalid choice");
                    }
                  }
                }}
              >
                <img
                  src={EditIcon}
                  alt="edit-icon"
                  style={{ width: "35px", paddingRight: "15px" }}
                />
                Edit {props.isAutoApproval && "Product"}
              </Button>
            ) : (
              <img
                src={EditIconMobile}
                alt="deactivate-icon"
                style={{ width: "35px", paddingRight: "20px" }}
              />
            )}
            {props.isAutoApproval && (
              <Button
                className="seller-manage-products-card-action-button-edit"
                type="primary"
                onClick={() =>
                  props.history.push(
                    `/admin/manage-price-stock/${props.product.source.productId}`
                  )
                }
              >
                <img
                  src={EditIcon}
                  alt="edit-icon"
                  style={{ width: "35px", paddingRight: "15px"}}
                />
                Edit Stock
              </Button>
            )}
            {!isMobileView && type !== "rejected" ? (
              <Button
                className="seller-manage-products-card-action-button"
                onClick={() =>
                  deactivateProduct(
                    props.product.source.productId,
                    // props.product.source.active
                    active
                  )
                }
              >
                <img
                  src={DeactivateIcon}
                  alt="delete-icon"
                  style={{ width: "35px", paddingRight: "20px" }}
                />
                {active ? "Deactivate" : "Activate"}
              </Button>
            ) : (
              // <img
              //   src={DeactivateIcon}
              //   alt="delete-icon"
              //   style={{ width: "35px", paddingRight: "20px" }}
              // />
              <></>
            )}

            {!isMobileView && type === "rejected" && (
              <Button
                className="seller-manage-products-card-action-button-edit"
                type="primary"
                onClick={()=>
                  {props?.product?.source?.rejectionModified === false ?
                    publish(props.product.source.productId) :
                    rejectModify()}
                  }
              >
                Re Publish
              </Button>
            )}

            {!isMobileView ? (
              <>
                {props.isAdmin && (
                  <Button
                    className="seller-manage-products-card-action-button"
                    onClick={() =>
                      deleteProduct(props.product.source.productId)
                    }
                  >
                    <img
                      src={DeleteIcon}
                      alt="deactivate-icon"
                      style={{ width: "35px", paddingRight: "20px" }}
                    />
                    Delete
                  </Button>
                )}
                {props.isAdmin && props.isAutoApproval && (
                  <Button
                    className="seller-manage-products-card-reject-button"
                    type="secondary"
                    onClick={() =>
                      //approveProduct(props.product.source.productId, "reject");
                      setShowRejectModal(true)
                    }
                    style={{ top: "5px", position: "relative", marginRight: "0px"}}
                  >
                    Reject Product
                  </Button>
                )}
                {props.isAdmin && props.productStatus != "APPROVED" && (
                  <Button
                    className="seller-manage-products-card-approve-button"
                    type="primary"
                    onClick={() =>
                      approveProduct(props.product.source.productId, "approve")
                    }
                  >
                    Approve Product
                  </Button>
                )}
                {props.isAdmin && props.productStatus === "PENDING" && (
                  <Button
                    className="seller-manage-products-card-reject-button"
                    type="secondary"
                    onClick={() =>
                      //approveProduct(props.product.source.productId, "reject");
                      setShowRejectModal(true)
                    }
                  >
                    Reject Product
                  </Button>
                )}
              </>
            ) : (
              <img
                src={DeleteIcon}
                alt="deactivate-icon"
                style={{ width: "35px", paddingRight: "20px" }}
              />
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "space-between",
              fontSize: "12px",
            }}
          >
            <Text>BV 012</Text>
            <Button
              type={"primary"}
              size={"small"}
              style={{
                fontSize: "12px",
                backgroundColor: "#3e6df3",
                border: "none",
              }}
            >
              {" "}
              Add To Cart
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default withRouter(ManageProductCard);
