// take name and return name with dots at the
// end if the product name length is greater than 30
export const reduceStringLength = (name, limit) => {
  let newName;
  limit = parseInt(limit);
  if (name !== null) {
    if (name.length > limit) {
      newName = name.slice(0, limit);
      return newName + "...";
    }
  }

  return name;
};
