


import { FETCH_COMPANY_DETAILS, FETCH_HOMEPAGE_DATA } from "./types";

export const fetchHomePageSections = (data) => {
  return {
    type: FETCH_HOMEPAGE_DATA,
    data,
  };
};

export const fetchCompanyDetails = (data) => {
  return {
    type: FETCH_COMPANY_DETAILS,
    data,
  };
};
