import React, { useState } from 'react'

function DummyArea(props) {
    const { cardSelected, bannerSelected, imageURLCards, imageURLBanners, makeDefault, topGradient, bottomGradient, setTopGradient, setBottomGradient } = props;

    const paddingStyle = {
        display: 'block'
    }


    console.log("Image URL", cardSelected, bannerSelected)
    return (
        <div className="ads-dummy-container">
        <div className="ads-dummy-color-box" style={{background: `linear-gradient(${topGradient}, ${bottomGradient})`}}>
            <span className="ads-dummy-color-label-box" style={{background: 'inherit'}}>
                <input type="color" id="gradientTop" className="inputColor" onChange={(e)=> setTopGradient(e.target.value)} />
                <label htmlFor="gradientTop" className="gradientColorLabels gradientLabel1" style={{background: `${topGradient}`}}></label>
                <input type="color" id="gradientBottom" className="inputColor" onChange={(e)=> setBottomGradient(e.target.value)} />
                <label htmlFor="gradientBottom" className="gradientColorLabels gradientLabel2" style={{background: `${bottomGradient}`}}></label>
            </span>
            <p className="ads-dummy-skeleton"></p>
        </div>
        {/* <div className="ads-dummy-branner-box2"></div> */}
        <div className={cardSelected ? (imageURLCards ? 'ads-dummy-brand-box1' : 'ads-dummy-brand-box1 ads-dummy-selected-cards') : 'ads-dummy-brand-box1'} style={{padding: makeDefault ? '0.5em' : '0'}}>
            {imageURLCards && (<img src={imageURLCards} className="ads-dummy-image" />)}
        </div>
        <div className={cardSelected ? (imageURLCards ? 'ads-dummy-brand-box2' : 'ads-dummy-brand-box2 ads-dummy-selected-cards') : 'ads-dummy-brand-box2'} style={{padding: makeDefault ? '0.5em' : '0'}}>
            {imageURLCards && (<img src={imageURLCards} className="ads-dummy-image" />)}
        </div>
        <div className={cardSelected ? (imageURLCards ? 'ads-dummy-brand-box3' : 'ads-dummy-brand-box3 ads-dummy-selected-cards') : 'ads-dummy-brand-box3'} style={{padding: makeDefault ? '0.5em' : '0'}}>
            {imageURLCards && (<img src={imageURLCards} className="ads-dummy-image" />)}
        </div>
        <div className={bannerSelected ? (imageURLBanners ? 'ads-dummy-branner-box' : 'ads-dummy-selected') : 'ads-dummy-branner-box'} style={{padding: makeDefault ? '0.5em' : '0'}}>
            {imageURLBanners && (<img src={imageURLBanners} className="ads-dummy-image" />)}
        </div>
        <div className="ads-dummy-product-box"></div>
        <div className="ads-dummy-product-box2"></div>
        <div className="ads-dummy-product-box3"></div>
    </div>
    )
}

export default DummyArea
