


import React, { useState, Fragment, useEffect } from "react";

import { Modal, Form, Input, Upload, message, Button, Typography } from "antd";

import { LoadingOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";
import { FILE_PATH } from "../../../../Utils/constants";

import Loading from "../../../GeneralComponents/Loading";

import {
  addBrand,
  uploadBrandImage,
  updateBrand,
} from "../../../../ApiAction/AdminApiAction/brandApi";

import "./addEditBrandModal.css";

const { Text } = Typography;
const AddEditBrandModal = (props) => {
  const {
    visible,
    setvisible,
    isEdit,
    editItem,
    refetchBrandList,
    setrefetchBrandList,
    setisEdit,
  } = props;

  const [form] = Form.useForm();
  const [uploading, setuploading] = useState(false);
  const [imgUrl, setimgUrl] = useState();
  const [imgFile, setimgFile] = useState();
  const [loading, setloading] = useState(false);
  const [initialItems, setinitialItems] = useState();

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUploadImage = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      setuploading(true);
      return;
    }
    if (info.file.status === "done") {
      console.log(info, ":uploaded file detail");
      getBase64(info.file.originFileObj, (imageUrl) => {
        setuploading(false);
        setimgFile(info.file.originFileObj);
        setimgUrl(imageUrl);
      });
    }
  };

  const handleActionImage = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 100);
  };

  async function handleEditBrand(values) {
    const payload = { brandName: values.brandName, brandId: editItem.id };
    setloading(true);
    const brandId = await updateBrand(payload)
      .then((response) => {
        console.log("response for update brand: ", response);
        if (response.status === 200) {
          if (response.data.status === "success") {
          }
        }
      })
      .finally(() => {
        setloading(false);
      });
    if (imgFile) {
      setloading(true);
      uploadBrandImage(editItem.id, imgFile)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              message.success("Brand updated succesfully");
              setvisible(false);
              form.resetFields();
              setrefetchBrandList(!refetchBrandList);
            }
          }
        })
        .finally(() => {
          setloading(false);
        });
    } else {
      message.success("Brand updated succesfully");
      setvisible(false);
      form.resetFields();
      setrefetchBrandList(!refetchBrandList);
      setisEdit(false);
    }
  }

  async function handleAddBrand(values) {
    const payload = { brandName: values.brandName };
    setloading(true);
    const brandId = await addBrand(payload)
      .then((response) => {
        console.log("response for add brand: ", response);
        if (response.status === 200) {
          if (response.data.status === "success") {
            return response.data.brand.id;
          }
        }
      })
      .finally(() => {
        setloading(false);
      });
    if (imgFile) {
      setloading(true);
      uploadBrandImage(brandId, imgFile)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.status === "success") {
              message.success("Brand Added successfully");
              setvisible(false);
              form.resetFields();
              setrefetchBrandList(!refetchBrandList);
              setisEdit(false);
            }
          }
        })
        .finally(() => {
          setloading(false);
        });
    } else {
      message.success("Brand Added successfully");
      setvisible(false);
      form.resetFields();
      setrefetchBrandList(!refetchBrandList);
    }
  }
  const handleSubmit = (values) => {
    console.log(values);
    console.log(imgFile);

    if (isEdit) {
      handleEditBrand(values);
    } else {
      handleAddBrand(values);
    }
  };

  const uploadButton = () => {
    return (
      <div>
        {uploading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
  };

  useEffect(() => {
    form.resetFields();
    if (isEdit && editItem) {
      console.log("to edit: ", editItem);

      const initialValues = { brandName: editItem.name };
      setinitialItems(initialValues);
      if (editItem?.image) {
        setimgUrl(FILE_PATH + editItem.image);
      } else setimgUrl();
    } else {
      setimgUrl();
    }
  }, [props]);
  return (
    <Modal
      title={isEdit ? "Edit Brand" : "Add Brand"}
      visible={visible}
      onOk={() => {
        setvisible(false);
        form.resetFields();
        setisEdit(false);
      }}
      onCancel={() => {
        setvisible(false);
        form.resetFields();
        setisEdit(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={isEdit ? initialItems : {}}
      >
        <Text strong className="manage_brand_label">
          Brand Name*
        </Text>

        <Form.Item
          className="manage_brand_field"
          name="brandName"
          rules={[
            {
              required: true,
              message: "Please enter the brand name!",
            },
          ]}
        >
          <Input size="large" placeHolder="Brand Name" />
        </Form.Item>

        <Text strong className="manage_brand_label">
          Brand Logo
        </Text>

        <Form.Item className="manage_brand_field" name="brandImage">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={handleActionImage}
            beforeUpload={beforeUploadImage}
            onChange={handleChangeImage}
          >
            {imgUrl ? (
              <Fragment>
                <img src={imgUrl} alt="avatar" style={{ width: "100%" }} />
                <Button type="text" className="edit_logo_btn">
                  <EditOutlined /> Edit
                </Button>
              </Fragment>
            ) : (
              uploadButton()
            )}
          </Upload>

          <div className="manage_brand_form_action">
            <Button
              size="large"
              onClick={() => setvisible(false)}
              className="manage_brand_cancel_btn"
            >
              Cancel
            </Button>
            <Form.Item>
              {" "}
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                className="manage_brand_save_btn"
              >
                {loading ? (
                  <Loading style={{ color: "white" }} />
                ) : isEdit ? (
                  "Edit Brand"
                ) : (
                  "Add Brand"
                )}
              </Button>
            </Form.Item>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditBrandModal;
