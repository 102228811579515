


import React from 'react';
import { Table } from "antd";
import { fetchSort, fetchSortBy } from "../../../../ReduxStore/action";
import { connect } from "react-redux";
import LoadingCartItem from "../../../Customer/Components/Cart/loadingCartItem";

const TableView = (props) => {
  const { columns, dataSource, isLoadingReport, type } = props;

  const handleOnChange = (pagination, filters, sorter) => {
    console.log(sorter, ":sorter");

    props.fetchSort({
      ...props.sort,
      sortType: sorter.field,
      sortBy: sorter.order === "ascend" ? "asc" : "desc",
    });
  };

  return (
    <Table
      className="report_table"
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      loading={isLoadingReport}
      onChange={handleOnChange}
      scroll={{ x: 1000 }}
      locale={{
        emptyText: (
          <span style={{ color: "black", fontWeight: "600" }}>
            {isLoadingReport ? <LoadingCartItem /> : "No Data Available"}
          </span>
        ),
      }}
    />
  );
};

const mapDispatchToProps = {
  fetchSort,
  fetchSortBy,
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TableView);
