

import React from "react";
import moment from "moment";
import { Popconfirm, Button, message } from "antd";
import Cookies from "universal-cookie";
import "./reportHelper.scss";

import { exportWorkReport, handleBatchVisiblity } from "../../../../ApiAction/AdminApiAction/reportsApi";
import Text from "antd/lib/typography/Text";
const cookies = new Cookies();

export const salesColumnWithoutSort = [
  { title: "SNO", dataIndex: "slno" },

  {
    title: "Cust/User ID",
    dataIndex: "customerCode",
  },
  {
    title: "Customer ID",
    dataIndex: "referralId",
  },

  {
    title: "Cust/User Name",
    dataIndex: "customerName",
  },
  {
    title: "Invoice Date",
    dataIndex: "orderedDate",
  },
  {
    title: "Invoice No",
    dataIndex: "orderGroupId",
  },

  {
    title: "Batch No",
    dataIndex: "batchNumber",
  },

  {
    title: "Product Name",
    dataIndex: "productName",
  },
  { title: "Brand Name", dataIndex: "brandName" },

  {
    title: "Product Variant",
    dataIndex: "productVariant",
  },
  {
    title: "Min Discount %",
    dataIndex: "minDiscountPerCent",
  },

  // {
  //   title: "Category Commission-%",
  //   dataIndex: "categoryCommissionPerCent",
  // },
  {
    title: "Quantity",
    dataIndex: "quantity",
  },
  {
    title: "MRP",
    dataIndex: "mrp",
  },

  {
    title: "Selling Price",
    dataIndex: "sellingPrice",
  },

  {
    title: "Taxable Amount",
    dataIndex: "totalAmount",
  },
  {
    title: "DP (Landing Cost)",
    dataIndex: "dealerPrice",
  },
  { title: "Vendor Name", dataIndex: "vendor" },
  {
    title: "Margin",
    dataIndex: "margin",
  },
  {
    title: "Margin %",
    dataIndex: "marginPercent",
  },
  // {
  //   title: "Total SI",
  //   dataIndex: "totalSalesIncentive",
  // },
  // {
  //   title: "SI Rate %",
  //   dataIndex: "salesIncentivePerCent",
  // },

  // {
  //   title: "Warehouse Commission",
  //   dataIndex: "warehouseCommission",
  // },

  // {
  //   title: "Company Profit %",
  //   dataIndex: "companyProfitPerCent",
  // },
  // {
  //   title: "Company Profit Total",
  //   dataIndex: "companyProfitTotal",
  // },
  // {
  //   title: "Total Company Commission",
  //   dataIndex: "totalCompanyCommission",
  // },
  // {
  //   title: "Total Store Profit",
  //   dataIndex: "totalStoreProfit",
  // },
  // {
  //   title: "Total Store Profit %",
  //   dataIndex: "totalStoreProfitPerCent",
  // },
];

const makeHRId = (customerName, hrId) => {
  if (!isNaN(hrId)) {
    if (customerName && customerName?.length > 2) {
      let alpha = customerName?.substring(0, 2);
      return ("HR" + alpha + hrId).toUpperCase();
    } else {
      return hrId;
    }
  } else {
    return hrId
  }
};

export const columns = [
  { title: "SNO", dataIndex: "slno" },

  {
    title: "Cust/User ID",
    dataIndex: "customerCode",
    sorter: true,
  },
  {
    title: "Customer ID",
    dataIndex: ["referralId", "customerName"],
    sorter: true,
    render: (text, record) => (
      <Text strong style={{ fontSize: "12px" }}>
        {makeHRId(record["customerName"], record["referralId"])}
      </Text>
    ),
  },

  {
    title: "Cust/User Name",
    dataIndex: "customerName",
    sorter: true,
  },
  {
    title: "Invoice Date",
    dataIndex: "orderedDate",
    sorter: true,
    sorter: true,
  },
  {
    title: "Invoice No",
    dataIndex: "orderGroupId",
    sorter: true,
  },

  {
    title: "Batch No",
    dataIndex: "batchNumber",
    sorter: true,
  },

  {
    title: "Product Name",
    dataIndex: "productName",
    sorter: true,
  },
  {
    title: "HSN Code",
    dataIndex: "hsnCode",
  },
  { title: "Brand Name", dataIndex: "brandName", sorter: true },

  {
    title: "Product Variant",
    dataIndex: "productVariant",
    sorter: true,
  },
  {
    title: "Min Discount %",
    dataIndex: "minDiscountPerCent",
    sorter: true,
  },

  // {
  //   title: "Category Commission-%",
  //   dataIndex: "categoryCommissionPerCent",
  // },
  {
    title: "Quantity",
    dataIndex: "quantity",
    sorter: true,
  },
  {
    title: "MRP",
    dataIndex: "mrp",
    sorter: true,
  },

  {
    title: "Selling Price",
    dataIndex: "sellingPrice",
    sorter: true,
  },

  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    sorter: true,
  },
  {
    title: "Taxable Amount",
    dataIndex: "taxableAmount",
    sorter: true,
  },
  {
    title: "DP (Landing Cost)",
    dataIndex: "dealerPrice",
    sorter: true,
  },
  { title: "Vendor Name", dataIndex: "vendor", sorter: true },
  {
    title: "Margin",
    dataIndex: "margin",
    sorter: true,
  },
  {
    title: "Margin %",
    dataIndex: "marginPercent",
    sorter: true,
  },
  {
    title: "Tax Percent",
    dataIndex: "taxPerCent",
  },
  {
    title: "SGST",
    dataIndex: "sgst",
  },
  {
    title: "CGST",
    dataIndex: "cgst",
  },
  {
    title: "IGST",
    dataIndex: "igst",
  },
  // {
  //   title: "Total SI",
  //   dataIndex: "totalSalesIncentive",
  //   sorter: true,
  // },
  // {
  //   title: "SI Rate %",
  //   dataIndex: "salesIncentivePerCent",
  //   sorter: true,
  // },
  // {
  //   title: "Warehouse Commission",
  //   dataIndex: "warehouseCommission",
  //   sorter: true,
  // },

  // {
  //   title: "Company Profit %",
  //   dataIndex: "companyProfitPerCent",
  //   sorter: true,
  // },
  {
    title: "Total Platform Charge",
    dataIndex: "totalCompanyCommission",
    sorter: true,
  },
  // {
  //   title: "Total Company Commission",
  //   dataIndex: "totalCompanyCommission",
  //   sorter: true,
  // },
  {
    title: "Total Store Profit",
    dataIndex: "totalStoreProfit",
    sorter: true,
  },
  {
    title: "Total Store Profit %",
    dataIndex: "totalStoreProfitPerCent",
    sorter: true,
  },
];

export const autoApprovalColumns = [
  { title: "SNO:", dataIndex: "slno" },
  {
    title: "Product Id",
    dataIndex: "productId",
    width: 150,
  },
  {
    title: "Approved Date",
    dataIndex: "date",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    width: 150,
  },
  {
    title: "Brand",
    dataIndex: "brand",
  },
  {
    title: "Category",
    dataIndex: "category",
  },
  {
    title: "Vendor",
    dataIndex: "vendor",
  },
  {
    title: "Vendor Type",
    dataIndex: "vendorType",
  },
  {
    title: "HSN Code",
    dataIndex: "hsnCode",
  },
  {
    title: "Selling Price",
    dataIndex: "sellingPrice",
  },
  {
    title: "MRP",
    dataIndex: "price",
  },
  {
    title: "Tax Percent",
    dataIndex: "taxPerCent",
  },
  {
    title: "Variant",
    dataIndex: "quantity",
  },
  {
    title: "Weight In Kg",
    dataIndex: "weightInKg",
  },
];

export const stocksColumns = [
  { title: "SNO:", dataIndex: "slno" },
  {
    title: "Product Id",
    dataIndex: "productId",
    width: 150,
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    width: 150,
  },

  {
    title: "Brand",
    dataIndex: "brand",
  },
  {
    title: "Category",
    dataIndex: "category",
  },
  {
    title: "Vendor",
    dataIndex: "store",
  },
  {
    title: "HSN Code",
    dataIndex: "hsnCode",
  },
  {
    title: "Tax Percent",
    dataIndex: "taxPerCent",
  },

  {
    title: "Variant",
    dataIndex: "quantity",
  },

  {
    title: "Current Stock",
    dataIndex: "stock",
  },
];
//detailed-stock-report
export const detailedColumns = [
  { title: "SNO:", dataIndex: "slno" },
  {
    title: "Product Id",
    dataIndex: "productId",
    width: 150,
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    width: 150,
  },

  {
    title: "Batch No",
    dataIndex: "batchNumber",
  },
  {
    title: "Stock",
    dataIndex: "stock",
  },
  {
    title: "Selling Price",
    dataIndex: "sellingPrice",
  },
  {
    title: "Dealer Price",
    dataIndex: "dealerPrice",
  },
  {
    title: "MRP",
    dataIndex: "price",
  },
  {
    title: "Variant",
    dataIndex: "productVariant",
  },
  {
    title: "Weight In Kg",
    dataIndex: "weightInKg"
  },
  {
    title: "Barcode",
    dataIndex: "barcode"
  }
];

export const searchColumns = [
  {
    title: "SNO:",
    dataIndex: "slno",
  },
  {
    title: "Keyword",
    dataIndex: "keyword",
  },
  {
    title: "Location(Pincode)",
    dataIndex: "pinCode",
  },
  {
    title: "Search Count",
    dataIndex: "totalCount",
  },
];
export const searchByViewColumns = [
  {
    title: "SNO:",
    dataIndex: "slno",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
  },
  {
    title: "Location(Pincode)",
    dataIndex: "pinCode",
  },
  {
    title: "Search Count",
    dataIndex: "totalViews",
  },
];

export const affiliateChargeColumns = [
  {
    title: "SNO:",
    dataIndex: "slno",
  },
  {
    title: "Affiliate Name",
    dataIndex: "affiliateName",
  },
  {
    title: "Affiliate Id",
    dataIndex: "affiliateId",
  },
  {
    title: "Affiliate Invoice Id",
    dataIndex: "affiliateInvoiceId",
  },
  {
    title: "Invoice Id",
    dataIndex: "orderGroupId",
  },
  {
    title: "Invoice Amount",
    dataIndex: "invoiceAmount",
  },
  {
    title: "Affiliate Amount",
    dataIndex: "affiliateAmount",
  },
  {
    title: "Request Status",
    dataIndex: "requestStatus",
  },
  {
    title: "Requested Date",
    dataIndex: "requestedDate",
  },
  {
    title: "Payment Mode",
    dataIndex: "paymentMode",
  },
  {
    title: "Transaction Id",
    dataIndex: "transactionId",
  },
];

export const workReportColumns = [
  { title: "SNO", dataIndex: "slno" },
  { title: "Screen Name", dataIndex: "screenName"},
  { title: "Status", dataIndex: "status"},
  { title: "Log Message", dataIndex: "logMessage"},
  { title: "Timestamp", dataIndex: "timestamp"}
];

export const dailyWorkReportColumns = [
  {title: "SNO", dataIndex: "slno"},
  {title: "User", dataIndex: "name"},
  {
    title: "Daily Report",
    dataIndex: "donwload",
    key: "action",
    render: (text, record) => {
      return (
        <div className="colored-text">
          <Button
              type="primary"
              disabled={cookies.get("accountType") === "workAdmin" ? false : true}
              className={record.active ? "disable_btn" : "enable_btn"}
              onClick={() => {
                const payload = {
                  userId: record.id,
                  searchKey : '',
                  statusKey : '',
                  startTime : '',
                  endTime : '',
                  sortBy: 'timestamp',
                  sortType: 'desc',
                  dailyReport: true
                };
                exportWorkReport(payload)
                .then((response) => {
                  if (response.status === 200) {
                    if (response?.data?.status === "failed") {
                      message.error(
                        "Couldn't download work report. Please try again later"
                      );
                    } else {
                      console.log(response, "response for voucher code excel sheet");
                      const url = window.URL.createObjectURL(
                        new Blob([response.data])
                      );
                      const date = new Date().toLocaleString();
                      const link = document.createElement("a");
                      link.href = url;
                      link.setAttribute("download", `work report of ${record.name} (${date}).xlsx`);
                      document.body.appendChild(link);
                      link.click();
                    }
                  } else {
                    message.error(
                      "Couldn't download work report. Please try again later"
                    );
                  }
                })
                .catch((error) => {
                  message.error("Could not export file please try again");
                })
              }}
            >
              {"Download"}
            </Button>
        </div>
      )
    }
  },
];

export const dayBookReportColumns = [
  {title: "SNO", dataIndex: "slno"},
  {title: "Date", dataIndex: "date"},
  {title: "Customer ID", dataIndex: "customerId"},
  {title: "Invoice No", dataIndex: "invoiceNumber"},
  {title: "Amount Without Delivery Charge", dataIndex: "amountWithoutDelivery"},
  {title: "Delivery Charge", dataIndex: "deliveryCharge"},
  {title: "Total Amount", dataIndex: "totalAmount"},
  {title: "Payment Mode", dataIndex: "paymentMode"},
];

export const dayBookReportColumnsAdmin = [
  {title: "SNO", dataIndex: "slno"},
  {title: "Date", dataIndex: "date"},
  {title: "Customer ID", dataIndex: "customerId"},
  {title: "Invoice No", dataIndex: "invoiceNumber"},
  {title: "Vendor Name", dataIndex: "vendorName"},
  {title: "Amount Without Delivery Charge", dataIndex: "amountWithoutDelivery"},
  {title: "Delivery Charge", dataIndex: "deliveryCharge"},
  {title: "Total Amount", dataIndex: "totalAmount"},
  {title: "Payment Mode", dataIndex: "paymentMode"},
];

export const formatDayBookReport = (reports, count) => {
  console.log("daybook report to format", reports);

  let result = [];

  reports.map((data, index) => {
    let currentData = data?.source ? data.source : data;
    const temp = {
      slno: count + index + 1,
      date: moment(currentData.orderedDate).format("YYYY-MM-DD"),
      customerId: currentData.customerId,
      invoiceNumber: currentData.orderGroupId,
      vendorName: currentData.orderedItems?.[0]?.vendor,
      amountWithoutDelivery: currentData.totalPrice - currentData.deliveryCharge,
      deliveryCharge: currentData.deliveryCharge,
      totalAmount: currentData.totalPrice,
      paymentMode: currentData.paymentMode
    }
    console.log(temp)
    result.push(temp)
  })
  return result
}

export const formatDailyWorkReport = (reports, count) => {
  console.log("work report to format", reports);

  let result = [];

  reports.map((data, index) => {
    const temp = {
      slno: count + index + 1,
      name: data.name,
      id: data.id
    }
    result.push(temp)
  })
  return result
}

export const formatWorkReport = (reports, count = 0) => {
  console.log("work report to format", reports);

  let result = [];
  // console.log("sales report data: ", reports);
  reports.map((data, index) => {
    let currentData = data?.source ? data.source : data;
    const temp = {
      slno: count + index + 1,
      logMessage: currentData.logMessage,
      screenName: currentData.screenName,
      timestamp: moment(currentData.timestamp).format(
        " DD-MM-YYYY, h:mm:ss a"
      ),
      status: currentData.status
    };
    result.push(temp);
  });

  console.log("formated data: ", result);
  return result;
};

//customise sales report data to fit to stocks table
export const formatSalesReport = (reports, count = 0) => {
  console.log("sales report to format", reports);

  let result = [];
  // console.log("sales report data: ", reports);
  reports.map((data, index) => {
    let currentData = data?.source ? data.source : data;
    const temp = {
      slno: count + index + 1,
      orderGroupId: currentData.orderGroupId,
      customerCode: currentData.customerCode,
      referralId: currentData.referralId,
      affiliateId: currentData.affiliateId,
      customerName: currentData.customerName,
      orderedDate: currentData.orderedDate,
      orderId: currentData.orderId,
      batchNumber: currentData.batchNumber,
      brandName: currentData.brandName,
      warehouseCommission: currentData.warehouseCommission,
      productId: currentData.productId,
      productName: currentData.productName,
      vendorId: currentData.vendorId,
      vendor: currentData.vendor,
      vendorType: currentData.vendorType,
      productVariant: currentData.productVariant,
      minDiscountPerCent: currentData.minDiscountPerCent,
      categoryCommissionPerCent: currentData.categoryCommissionPerCent,
      quantity: currentData.quantity,
      mrp: currentData.mrp.toFixed(2),
      // discount: data.source.discount.toFixed(2),
      sellingPrice: currentData.sellingPrice.toFixed(2),
      dealerPrice: currentData.dealerPrice.toFixed(2),
      totalAmount: currentData.totalAmount.toFixed(2),
      taxableAmount: (
        ((100 *
          currentData.sellingPrice) /
          (100 + currentData.taxPerCent)) *
          currentData.quantity
      ).toFixed(2),
      margin: currentData?.margin?.toFixed(2),
      marginPercent:
        Number(currentData.marginPercent) % 1 !== 0
          ? currentData.marginPercent?.toFixed(2)
          : currentData.marginPercent,
      // totalSalesIncentive: currentData.totalSalesIncentive?.toFixed(2),
      // salesIncentivePerCent:
      //   Number(currentData.salesIncentivePerCent) % 1 !== 0
      //     ? currentData.salesIncentivePerCent?.toFixed(2)
      //     : currentData.salesIncentivePerCent,
      totalCompanyCommission: currentData.totalCompanyCommission?.toFixed(2),
      companyProfitPerCent:
        Number(currentData.companyProfitPerCent) % 1 !== 0
          ? currentData.companyProfitPerCent?.toFixed(2)
          : currentData.companyProfitPerCent,
      companyProfitTotal: currentData.companyProfitTotal?.toFixed(2),
      totalStoreProfit: currentData.totalStoreProfit?.toFixed(2),
      totalStoreProfitPerCent: currentData.totalStoreProfitPerCent,
      taxPerCent: currentData.taxPerCent,
      sgst: currentData.igst? 0 : ((
        (currentData.taxPerCent / 2 / 100) *
        ( 100 * currentData.sellingPrice) / (100 + currentData.taxPerCent) * currentData.quantity).toFixed(2)),
      cgst: currentData.igst? 0 : ((
        (currentData.taxPerCent / 2 / 100) *
        ( 100 * currentData.sellingPrice) / (100 + currentData.taxPerCent) * currentData.quantity).toFixed(2)),
      igst: currentData.igst?((
        (currentData.taxPerCent / 2 / 100) *
        ( 100 * currentData.sellingPrice) / (100 + currentData.taxPerCent) * currentData.quantity).toFixed(2)) : 0,
      hsnCode: currentData.hsnCode

      // code: data.productId.slice(0, 4),
      // brand: data.brandName,
      // category: data.currentCategory,
      // store: data.vendor,
      // sales: data.totalSales,
    };
    result.push(temp);
  });

  console.log("formated data: ", result);
  return result;
};

//customise stocks report data to fit to stocks table
export const formatStocksReport = (reports) => {
  console.log("stock reports to be formated :", reports);

  let result = [];

  reports.map((data, index) => {
    const temp = {
      slno: index + 1,
      key: index,

      productName: data.productName,
      productId: data.productId,

      brand: data.brandName,
      category: data.currentCategory,
      store: data.vendor,
      hsnCode: data.hsnCode,
      taxPerCent: data.taxPerCent,
      quantity: data.variant,

      stock: data.currentStock,
    };
    result.push(temp);
  });

  return result;
};

export const formatAutoApprovalReport = (reports) => {
  console.log("stock reports to be formated :", reports);

  let result = [];

  reports.map((data, index) => {
    const temp = {
      slno: index + 1,
      key: index,

      productName: data.productName,
      productId: data.productId,
      date: data.approvedDate,

      brand: data.brandName,
      category: data.currentCategory,
      vendor: data.vendor,
      vendorType: data.vendorType,
      hsnCode: data.hsnCode,
      taxPerCent: data.taxPerCent,
      quantity: data.variant,
      sellingPrice: data.sellingPrice,
      price: data.price,

      stock: data.currentStock,
      weightInKg: data.weightInKg,
    };
    result.push(temp);
  });

  return result;
};

export const formatAffiliateChargeReport = (reports) => {
  console.log("affiliate charge reports to be formated :", reports);

  let result = [];

  reports.map((data, index) => {
    const temp = {
      slno: index + 1,
      key: index,

      affiliateId: data.affiliateId,
      affiliateInvoiceId: data.affiliateInvoiceId,
      paymentMode: data.paymentMode,
      requestedAmount: data.requestedAmount,
      requestStatus: data.requestStatus,
      affiliateName: data.affiliateName,
      requestedDate: moment(data.requestedDate).format("YYYY-MM-DD"),
      orderGroupId: data.orderGroupId,
      affiliateAmount: data.affiliateAmount,
      invoiceAmount: data.invoiceAmount,
      transactionId: data.transactionId
    };
    result.push(temp);
  });

  return result;
};

//customise search report data to fit to search report table
export const FormatSearchReport = (reports) => {
  let result = [];

  reports.map((data, index) => {
    const temp = {
      slno: index + 1,
      key: data.serialNumber,
      keyword: data.key,
      pinCode: data.pinCode,
      totalCount: data.totalCount,
    };
    result.push(temp);
  });

  return result;
};

export const FormatSearchReportByView = (reports) => {
  let result = [];

  reports.map((data, index) => {
    const temp = {
      slno: index + 1,
      key: data.serialNumber,
      productName: data.productName,
      pinCode: data.pinCode,
      totalViews: data.totalViews,
    };
    result.push(temp);
  });

  return result;
};

//expiry report

export const getExpiryReportColumns = ({ reload, setreload }) => {
  const expiryReportColumns = [
    {
      title: "SL NO:",
      dataIndex: "slno",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
    },
    {
      title: "Product Code",
      dataIndex: "productCode",
    },
    {
      title: "Batch Number",
      dataIndex: "batchNumber",
    },
    {
      title: "Days to expire",
      dataIndex: "daysToExpire",
    },
    {
      title: "Stocks Count",
      dataIndex: "currentStock",
    },
    {
      title: "Enable/Disable",
      dataIndex: "export",
      key: "action",
      render: (text, record) => {
        return (
          <div className="colored-text">
            <Popconfirm
              title={`Sure to ${record.active ? "disable" : "enable"}?`}
              onConfirm={() =>
                handleProductbatchVIsiblity({
                  reportId: record.key,
                  reload,
                  setreload,
                })
              }
              disabled={cookies.get("accountType") == "admin" ? false : true}
            >
              <Button
                type="primary"
                className={record.active ? "disable_btn" : "enable_btn"}
                disabled={cookies.get("accountType") == "admin" ? false : true}
              >
                {record.active ? "Disable" : "Enable"}
              </Button>
            </Popconfirm>{" "}
          </div>
        );
      },
    },
  ];
  return expiryReportColumns;
};
export const expiryReportColumns = [
  {
    title: "SL NO:",
    dataIndex: "slno",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
  },
  {
    title: "Product Code",
    dataIndex: "productCode",
  },
  {
    title: "Batch Number",
    dataIndex: "batchNumber",
  },
  {
    title: "Days to expire",
    dataIndex: "daysToExpire",
  },
  {
    title: "Stocks Count",
    dataIndex: "currentStock",
  },
  {
    title: "Enable/Disable",
    dataIndex: "export",
    key: "action",
    render: (text, record) => {
      return (
        <div className="colored-text">
          <Popconfirm
            title={`Sure to ${record.active ? "disable" : "enable"}?`}
            onConfirm={() => handleProductbatchVIsiblity(record.key)}
            disabled={cookies.get("accountType") == "admin" ? false : true}
          >
            <Button
              type="primary"
              disabled={cookies.get("accountType") == "admin" ? false : true}
              className={record.active ? "disable_btn" : "enable_btn"}
            >
              {record.active ? "Disable" : "Enable"}
            </Button>
          </Popconfirm>{" "}
        </div>
      );
    },
  },
];

const handleProductbatchVIsiblity = ({ reportId, reload, setreload }) => {
  const payload = {
    id: reportId,
  };
  handleBatchVisiblity(payload)
    .then((response) => {
      if (response.status === 200) {
        if (response.data.status === "success") {
          message.success("Upodated successfully");
          setreload(!reload);
        } else message.error(response.data.message);
      } else message.error("something went wrong please try again");
    })
    .catch(() => {});
};

export const formatExpiryReport = (report) => {
  console.log("expiry repor to be formated ", report);
  let result = [];
  report.map((data, index) => {
    let temp = {
      slno: index + 1,
      key: data.id,
      active: data.source.active,
      productName: data.source.productName,
      productCode: data.source.productId,
      batchNumber: data.source.batchNumber,
      daysToExpire: moment(data.source.expiryDate).fromNow(),
      currentStock: data.source.currentStock,
    };
    result.push(temp);
  });

  return result;
};

//* For Formatting Detailed Stock Report
export const formatDetailedStockReport = (report) => {
  let result = [];
  report.map((data, index) => {
    let temp = {
      slno: index + 1,
      productId: data?.productId,
      productName: data?.productName,
      batchNumber: data?.batchNo,
      stock: data?.stock,
      sellingPrice: data?.sellingPrice,
      dealerPrice: data?.dealerPrice,
      price: data?.price,
      productVariant: data?.variant,
      weightInKg: data.weightInKg,
      barcode: data.barcode
    };
    result.push(temp);
  });
  return result;
};

export const formatBrandData = (brands) => {
  console.log("brands: ", brands);

  let result = [];
  brands.map((brand) => {
    const temp = {
      name: brand.source.brandName,
      id: brand.source.brandId,
    };
    result.push(temp);
  });

  return result;
};