


import { FILE_PATH } from "../../../../Utils/constants";
import { getThumbImg } from "../ProductListing/productListingHelper";

export const formatProductDetailData = (product) => {
  let vendorName =
    product?.source?.vendor === null
      ? product?.source?.vendorType
      : product?.source?.vendor;
  const regex = '[?=.* ?=.*-]';
  const found = vendorName.match(regex);
  vendorName = found !== null ? vendorName : '';

  console.log("All India Delivery : ", product.source.serviceLocations.includes('All India'), product.source.serviceLocations )

  const productData = {
    productId: product.source.productId,
    productName: product.source.name,
    productImages: product.source.images,
    ProductVedio: product.source.videos,
    specifications: product.source.specifications,
    description: product.source.overview,
    brandName: product.source.brandName,
    vendorMobile: product.source.vendorMobile,
    vendorId: product.source.vendorId,
    vendorType: product.source.vendorType,
    processedPrice: product.source.processedPriceAndStock,
    vendor: vendorName && vendorName,
    allIndiaDelivery: product.source.serviceLocations.includes('All India') ? true : false,
    serviceLocations: product.source.serviceLocations,
    vendorAddress: product.source.vendorAddress
  };

  return productData;
};

export const getFormatedImageForPreview = (product) => {
  let result = [];

  product.source.images.map((image) => {
    let temp = {
      original: FILE_PATH + image,
      thumbnail: FILE_PATH + getThumbImg(image),
    };
    result.push(temp);
  });

  return result;
};
