

import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";

import { withRouter } from "react-router-dom";
import "./Megamenu.scss";
import MegamenuModal from "./Components/MegamenuModal";

import {
  addCategoryfilter,
  addSearchFilter,
} from "../../ReduxStore/action/productActions";

import { ENV_TYPE } from "../../Utils/constants";
var CryptoJS = require("crypto-js");

const { Text } = Typography;

const Megamenu = (props) => {
  const { catogeries } = props;
  const [delayHandler, setDelayHandler] = useState(null);
  const [modalVisible, setmodalVisible] = useState(false);

  const [activeModal, setActiveModal] = useState();

  const getResposiveCategoryList = (menuList) => {
    if (menuList.length > 0) {
      let temp = [...menuList].filter(({ topCategory }) => !topCategory);
      if (window.innerWidth <= 760) {
        temp = temp.slice(0, 4);
        return temp;
      } else if (window.innerWidth <= 1100) {
        temp = temp.slice(0, 5);
        return temp;
      } else if (window.innerWidth <= 1430) {
        temp = temp.slice(0, 10);
        return temp;
      } else if (window.innerWidth <= 1500) {
        temp = temp.slice(0, 10);
        return temp;
      } else {
        temp = temp.slice(0, 10);
        return temp;
      }
    }
    return [];
  };

  const handleShowMegaMenu = async (data) => {
    delayHandler && clearTimeout(delayHandler);
    const delay = await setTimeout(() => {
      setmodalVisible(true);
      setActiveModal(data);

      console.log(data, " is active now");
    }, 500);
    setDelayHandler(delay);
  };

  const handleHideMegaMenu = async () => {
    delayHandler && clearTimeout(delayHandler);
    const delay = await setTimeout(() => {
      setmodalVisible(false);
      setActiveModal();
    }, 500);
    setDelayHandler(delay);
  };

  //to add selected categoryName to bread crumb
  const handleManageBreadCrumb = (data) => {
    let breadCrumbList = [];
    let breadcrumb = {
      url:
        ENV_TYPE === "production"
          ? `/product-listing/${data.categoryName}`
          : `/customer/product-listing/${data.categoryName}`,
      type: "catogery",
    };
    breadcrumb["label"] = data.categoryName;
    breadcrumb["id"] = data.id;

    breadCrumbList.push(breadcrumb);
    localStorage.setItem("breadCrumbData", JSON.stringify(breadCrumbList));
  };

  //this part of code redirects to a catogeries product listing page
  const handleRedirection = (categoryData) => {
    props.addCategoryfilter(categoryData.id);
    let encryptedCategoryId = CryptoJS.AES.encrypt(
      JSON.stringify(categoryData?.id),
      process.env.REACT_APP_ENCRYPTION_KEY
    ).toString();

    props.history.push(
      ENV_TYPE === "production"
        ? `/fastmoving-listing/${encryptedCategoryId}`
        : `/customer/fastmoving-listing/${encryptedCategoryId}`
    );
    setmodalVisible(false);
    setActiveModal();

    console.log(categoryData.categoryName, "catogery data");

    handleManageBreadCrumb(categoryData);
  };

  return (
    <div
      onMouseOut={() => {
        handleHideMegaMenu();
      }}
      className="megamenu-contaner"
    >
      <div
        onMouseOver={() => {
          handleShowMegaMenu("all");
        }}
        className="all-catogeries-menu-conatiner"
      >
        <div className="all-categories-menu">
          <Text>ALL CATEGORIES</Text>
          <CaretDownOutlined className="all-catogeries-icon" />
        </div>
        {activeModal === "all" && (
          <MegamenuModal
            visible={modalVisible}
            type={"all-catogery"}
            data={catogeries}
            onMouseOver={() => {
              handleHideMegaMenu();
            }}
            setvisible={setmodalVisible}
          />
        )}
      </div>
      <div className="line-vertical"></div>
      <div className="header-category-vertical-list">
        {!!getResposiveCategoryList(catogeries).length &&
          getResposiveCategoryList(catogeries).map((data) => {
            return (
              <div
                key={data.id}
                className="mega-menu-option-main"
                onMouseOver={() => {
                  handleShowMegaMenu(data.id);
                }}
                onClick={() => handleRedirection(data)}
              >
                <Text className="mega-menu-option">{data.categoryName}</Text>
                {activeModal === data.id && (
                  <MegamenuModal
                    type="each-catogery"
                    visible={modalVisible}
                    topBrands={data.topBrands}
                    data={data.subCategories}
                    onMouseOver={() => {
                      handleHideMegaMenu();
                    }}
                    setvisible={setmodalVisible}
                  />
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

const mapDispatchToProps = { addCategoryfilter, addSearchFilter };

export default withRouter(connect(null, mapDispatchToProps)(Megamenu));
