


import React ,{useState,useEffect} from "react";
import { Upload, Button, message, Progress, Typography } from "antd";
import ReactQuill ,{Quill}from 'react-quill';

import 'react-quill/dist/quill.snow.css';
var Size = Quill.import('attributors/style/size');
Size.whitelist = ['12px', '16px', '18px'];
Quill.register(Size, true);
export default function UpdateContentEditor(props) {
  // '20px','22px','24px','26px','28px','30px'

 
    const modules = {
        
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
         
          [{ 'size': ['14px', '16px', '18px',] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, 
           {'indent': '-1'}, {'indent': '+1'},
           { 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00',
            '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
            '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', 
            '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100',
             '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700',
              '#002966', '#3d1466'] },
        ],
          ['link', 'image', 'video'],
          ['clean']
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
        
    
      }
      /* 
       * Quill editor formats
       * See https://quilljs.com/docs/formats/
       */
      const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
      ]
    const [htmlFile, setHtmlFile] = useState("");
    const [read, setRead] = useState(true);


    const handleChange =(html)=>{
      setHtmlFile(html)

    }

    useEffect(() => {
      setHtmlFile(props.content)
      
    }, [props]);
  return (
    <div>
      {/* <ParseHTML path={fileUrl} /> */}
      <div>
      <ReactQuill 
       theme={"snow"}
       style={{height:"300px", color:"black"}}
          onChange={(html)=>handleChange(html)}
          value={htmlFile}
          modules={modules}
          formats={formats}
          bounds={'.app'}
          readOnly={read}
          placeholder={"Add your content"}
          
         />
         </div>
      <div className="update-section" style={{marginTop:"50px"}}>
        {!read ? <Button onClick={()=>{setRead(true)}} className="cancel-button">Cancel</Button> : <></>}
       
          <Button className="update-button" 
           onClick={() => {
             if(read)
               setRead(false)
               else
               {
                 if(props.add)
                 {
                  props.addContent(htmlFile,props.version,props.id,props.typeAdd,props.type)
                  setRead(true)
                 }
                 else
                 {
                  props.update(htmlFile,props.version,props.id,props.typeAdd,props.type)
                  setRead(true)
                 }
               
               }
            

           }
            // props.update(htmlFile,"","",props.typeAdd)
          }
          >
           {read? "Edit": props.add ?"Add":"Update"} 
          </Button>
        
      </div>
    </div>
  );
}
