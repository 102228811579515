import React, { useEffect, useState } from 'react'
import { Typography } from 'antd';
import NumberFormat from 'react-number-format';
import { HiShoppingCart } from "react-icons/hi";
import { FILE_PATH, ENV_TYPE } from '../../../../Utils/constants';
import { TiArrowRightThick } from "react-icons/ti";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { fetchCartCount } from "../../../../ReduxStore/action";

function FmcgVariantProduct(props) {

    const { product, variant, addTocartProduct } = props;
    const { Text } = Typography;
    const [ inCart, setInCart ] = useState(false)

    useEffect(() => {
        handleCheckInCart(variant)
      }, [
        localStorage.getItem("guestCart"),
        props.cartItems,
        props
      ]);  
      
      const handleCheckInCart = async (variant) => {
        console.log("VARIENTS serialNumber :: ", variant.serialNumber)
        console.log("product id :: ", product.productId)
        const guestCart = JSON.parse(localStorage.getItem("guestCart"));
        let isAlreadyExists;
    
        if (guestCart && guestCart.length > 0) {
          isAlreadyExists = await guestCart.find((item) => {
            return (
              item.productId == product.productId &&
              item.itemCurrentPrice.serialNumber === variant.serialNumber
            );
          });
        } else if (props.cartItems) {
          isAlreadyExists = await props.cartItems.find((item) => {
            return (
              item.productId == product.productId &&
              item.serialNumber === variant.serialNumber
            );
          });
        }

        console.log(props.cartItems)

        console.log("Before :: ", inCart)
    
        if (isAlreadyExists){
            setInCart(true);
        } 
        else setInCart(false);
        console.log("After :: ", inCart)
      };      

    return (
        <div className="fmcg-varient-box">
            <img className="fmcg-varient-image" src={FILE_PATH + product?.images[0]} />
            <span className="fmcg-varient-details">
                <span style={{display: "flex", columnGap: "0.5em", alignItems: "center", width: '100%'}}>
                <Text className="fmcg-productlist-each-product-mrp-price"><NumberFormat value={variant.price.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></Text>
                <Text className="fmcg-varient-text">{variant?.quantity +" "+ variant?.unit}</Text>
                </span>
                <Text className="fmcg-productlist-each-product-price"><NumberFormat value={variant?.sellingPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></Text>
            </span>
            { inCart ? (
                <button onClick={()=> {
                    props.history.push(
                        ENV_TYPE === "production" ? `/cart` : `/customer/cart`
                      );
                }} className="fmcg-varient-cart-button fmcg-productlist-already-carted-button-variant"><TiArrowRightThick className="fmcg-varient-icon" /></button> 
            ) : (
                <button className="fmcg-varient-cart-button" onClick={()=> addTocartProduct(variant)} ><HiShoppingCart className="fmcg-varient-icon" /></button>    
            )}
                
        </div>  
    )
}

const mapDispatchToProps = { fetchCartCount };
const mapStateToProps = (state) => {
  return {
    cartCountStore: state.cartCountReducer,
    pinCode: state.productReducer.pinCode,
    cartItems: state.cartItemsReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FmcgVariantProduct)
);

//export default FmcgVariantProduct
