


import React, { useState, useEffect } from "react";
import { Row, Col, Pagination } from "antd";

import ProductCard from "../../../../ProductCard";
import ManageProductCard from "../../../../Seller/ManageProducts/Components/manageProductCard";

export default function Productlist(props) {
  const { productData, handleAddToCart, loading } = props;
  const product = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const [mobileView, setmobileView] = useState();

  useEffect(() => {
    navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)
      ? setmobileView(false)
      : setmobileView(false);
  }, []);
  return (
    <div class="productList_row_block">
      {mobileView ? (
        productData.map((data) => {
          return (
            <ManageProductCard isCustomer key={data.productId} product={data} />
          );
        })
      ) : (
        <Row gutter={[0, 0]}>
          {productData.map((data) => {
            return (
              <Col className="gutter-row product_col" key={data.productId}>
                <ProductCard
                  product={data}
                  handleAddToCart={handleAddToCart}
                  loading={loading}
                />
              </Col>
            );
          })}
        </Row>
      )}

      {/* <div className="pagination-conatiner">
        {productData?.length > 30 && (
          <Pagination
            defaultCurrent={1}
            total={productData.length}
            className="customer-pagination"
          />
        )}
      </div> */}
    </div>
  );
}
