


import React from "react";
import { Button } from "antd";

const VariantSelector = (props) => {
  const { processedPrice, selectedVariant, handleChangeVariant } = props;
  return (
    <div className="flex" style={{ maxWidth: "415px", flexWrap: "wrap" }}>
      {processedPrice &&
        processedPrice.map((item) => (
          <Button
            className={
              selectedVariant == item.serialNumber
                ? "button-product-detail-item-sizes-selected"
                : "button-product-detail-item-sizes"
            }
            onClick={() => handleChangeVariant(item)}
          >
            {`${item.quantity} ${item.unit}`}
          </Button>
        ))}
    </div>
  );
};

export default VariantSelector;
