

import {
  Table,
  Select,
  notification,
  Checkbox,
  Button,
  Modal,
  Spin,
} from "antd";
import $, { event } from "jquery";
import React, { useState, useEffect } from "react";

import QuantityController from "../../Customer/Components/ProductDetailPage/Components/QuantityController";
import BatchDropdown from "./batchDropdown";
import {
  removeCartItem,
  updateCartBatch,
  removeMultipleCartItems,
  getCart,
} from "./quickCheckoutAPI";
import LoadingCartItem from "../../Customer/Components/Cart/loadingCartItem";
import EmptyList from "../../GeneralComponents/emptyList";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const confirm = Modal.confirm;
const { Column } = Table;
const { Option } = Select;

const TableContent = (props) => {
  const [focus, setfocus] = useState(false);
  const [currentItem, setItem] = useState([]);
  const [batches, setbatches] = useState([]);
  const [removeArray, setremoveArray] = useState([]);
  const [removeArrayPayload, setremoveArrayPayload] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [tableData, settableData] = useState(
    props.data?.quickCheckoutCartItems
  );
  const [modalVisible, setmodalVisible] = useState(false);
  const [modalVisibleMultiple, setmodalVisibleMultiple] = useState(false);
  const [visible, setVisible] = useState(false);
  const [activeBatchNumber, setactiveBatchNumber] = useState([]);
  const [isLoading, setisLoading] = useState(props.isLoading);
  const { outOfStockList, lowStockList } = props;

  useEffect(() => {
    settableData(props.data?.quickCheckoutCartItems.reverse());
    setisLoading(props.isLoading);
  }, [props]);

  const handleCheckboxClick = (event, item) => {
    var list = removeArray;
    if (event.target.checked) {
      list.push(item);
    } else {
      list = list.filter((c) => c.itemId !== item.itemId);
    }
    setremoveArray(list);
    const payload = {
      checkoutCartId: props.data.checkoutCartId,
      quickCheckoutCartItems: list,
    };
    setremoveArrayPayload(payload);
  };

  const handleRemoveMultipleCartItems = () => {
    setisLoading(true);
    removeMultipleCartItems(removeArrayPayload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            setremoveArray([]);
            props.setdata(response.data.quickCheckoutCart);
          }
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const handleVisibileChange = (key) => {
    if (key) {
      $("body").addClass("scroll-disabled");
    } else {
      $("body").removeClass("scroll-disabled");
    }
  };
  const handleBatch = (product) => {
    setactiveBatchNumber(product.activeBatch.batchNumber);
    var varient = product.activeBatch.quantity + " " + product.activeBatch.unit;
    const sel = product.checkoutVariants.find(
      (item) => item.variantName == varient
    );
    setbatches(sel?.checkoutBatches);
  };

  function handleVarientChange(value, info, item) {
    const selectedItem = item.checkoutVariants.find(
      (item) => item.variantName == info.key
    );
    var changedItem = item;
    changedItem.activeBatch = selectedItem.checkoutBatches[0];
    setbatches(selectedItem.checkoutBatches);

    // setItem(changedItem);
    // let index;
    // let td = tableData;

    // td = td.map((item) => {
    //   if (item.productId == changedItem.productId) {
    //     index = td.indexOf(item);
    //
    // });
    // td[index] = changedItem;

    // settableData(td);

    // var product = td[index];

    const payload = {
      checkoutCartId: props.data.checkoutCartId,
      quickCheckoutCartItem: changedItem,
    };
    handleUpdateProduct(payload);
  }

  const handleFetchCart = () => {
    const payload = {
      checkoutCartId: props?.data?.checkoutCartId,
    };
    setisLoading(true);

    getCart(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.setdata(response.data.quickCheckoutCart);
          }
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const handleUpdateProduct = (payload) => {
    setisLoading(true);
    updateCartBatch(payload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.setdata(response.data.quickCheckoutCart);
            notification["success"]({
              message: "Success",
              description: "Product Updated",
            });
          }
          if (response.data.status === "failed") {
            handleFetchCart();
            notification["error"]({
              message: "Failed",
              description: response.data.message,
            });
          }
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const handleBatchChange = (product, batch) => {
    product.activeBatch = batch;
    const payload = {
      checkoutCartId: props.data.checkoutCartId,
      quickCheckoutCartItem: product,
    };
    handleUpdateProduct(payload);
  };

  const handleQuantityChange = (value, item) => {
    var product = item;
    setQuantity(value);
    product.addedQuantity = value;
    const payload = {
      checkoutCartId: props.data.checkoutCartId,
      quickCheckoutCartItem: product,
    };
    handleUpdateProduct(payload);
  };

  const handleRemoveProduct = (item) => {
    const removePayload = {
      checkoutCartId: props.data.checkoutCartId,
      quickCheckoutCartItem: item,
    };
    setisLoading(true);
    removeCartItem(removePayload)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.setdata(response.data.quickCheckoutCart);
          }
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const renderConfirmModalRemoveSingle = (item) => {
    Modal.confirm({
      title: "Confirm",
      id: "genrate-invoice-modal",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete this product?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleRemoveProduct(item),
    });
  };

  const renderConfirmModalRemoveMultiple = () => {
    Modal.confirm({
      title: "Confirm",
      id: "genrate-invoice-modal",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure you want to delete all selected Products?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: () => handleRemoveMultipleCartItems(),
    });
  };

  const checkForOutOfStock = (item) => {
    if (outOfStockList?.includes(item.itemId)) {
      return <h6 className="out-of-stock-label">OUT OF STOCK</h6>;
    }
    return "";
  }

  const checkForLowStock = (item) => {
    if (lowStockList?.includes(item.itemId)) {
      return <h6 className="low-stock-label">LOW STOCKS</h6>;
    }
    return "";
  }

  return (
    <div className="table-container">
      {removeArray.length > 0 && (
        <Button
          className="multiple-delete"
          onClick={() => {
            renderConfirmModalRemoveMultiple();
          }}
        >
          Remove Selected
        </Button>
      )}
      <Table
        rowClassName="table-row"
        scroll={{ x: 500 }}
        dataSource={tableData}
        pagination={false}
        loading={isLoading}
        locale={{
          emptyText: (
            <span style={{ color: "black", fontWeight: "600", margin: "auto" }}>
              <EmptyList msg={"No Products Added"} />
            </span>
          ),
        }}
      >
        <Column
          title={"Sl No."}
          dataIndex="slno"
          key="slno"
          width="60px"
          fixed="left"
          render={(name, item) => (
            <div>
              <Checkbox
                onChange={(event) => {
                  handleCheckboxClick(event, item);
                }}
              />
              <h5>{tableData.indexOf(item) + 1}</h5>
            </div>
          )}
        />
        <Column
          title={"Product"}
          dataIndex="product"
          width="200px"
          fixed="left"
          render={(name, item) => (
            <div>
              <h4>{item.productName}</h4>
              <h5 className="orange">
                {Number(item.activeBatch.sellingPrice).toFixed(2)}
              </h5>
              {checkForOutOfStock(item)}
              {checkForLowStock(item)}
            </div>
          )}
        />
        <Column
          title={""}
          dataIndex="batch"
          width="75px"
          fixed="left"
          className="batch-col"
          render={(name, item) => (
            <div
              onClick={() => {
                setVisible(!visible);
                handleBatch(item);
                // handleVisibileChange(!visible);
              }}
            >
              {/* <Popover
                overlayClassName="popover-custom"
                id="batch"
               placement="bottom"
                content={
                  <BatchDropdown
                    batches={batches}
                    item={item}
                    handleBatchChange={handleBatchChange}
                    setVisible={setVisible}
                  />
                }
                visible={visible}
                trigger="click"
                onClick={() => {
                  setVisible(!visible);
                  handleBatch(item);
                  handleVisibileChange(!visible);
                }}
              >
                <button className="ant-select-selector">Batch</button>
              </Popover> */}
              <Select size={"small"} placeholder={"Batch"} value="Batch">
                <Option>
                  <BatchDropdown
                    batches={batches}
                    activeBatchNumber={activeBatchNumber}
                    item={item}
                    handleBatchChange={handleBatchChange}
                    setVisible={setVisible}
                  />
                </Option>
              </Select>
            </div>
          )}
        />
        <Column
          title={""}
          dataIndex="varient"
          width="75px"
          fixed="left"
          className="varient"
          render={(name, item) => (
            <Select
              size={"small"}
              // placeholder={
              //   item.activeBatch.quantity + " " + item.activeBatch.unit
              // }
              value={item.activeBatch.quantity + " " + item.activeBatch.unit}
              onSelect={(value, info) => {
                handleVarientChange(value, info, item);
              }}
            >
              {item.checkoutVariants &&
                item.checkoutVariants.map((x) => (
                  <Option value={x.variantName} key={x.variantName}>
                    {x.variantName}
                  </Option>
                ))}
            </Select>
          )}
        />
        <Column
          className="qty"
          title={""}
          dataIndex="quantity"
          width="100px"
          fixed="left"
          render={(name, item) => (
            <QuantityController
              quantity={item.addedQuantity}
              stock={item.activeBatch.availableQuantity}
              setQuantity={handleQuantityChange}
              update={true}
              item={item}
            />
          )}
        />
        )
        <Column
          title={"HSN"}
          dataIndex="hsn"
          width="75px"
          render={(name, item) => (
            <div>
              <h4>{item.hsnCode}</h4>
            </div>
          )}
        />
        <Column
          title={"DLP"}
          dataIndex="hrp"
          width="75px"
          render={(name, item) => (
            <div>
              <h4>{Number(item.activeBatch.sellingPrice).toFixed(2)}</h4>
            </div>
          )}
        />
        <Column
          title={"MRP"}
          dataIndex="mrp"
          width="75px"
          render={(name, item) => (
            <div>
              <h4>{Number(item.activeBatch.price).toFixed(2)}</h4>
            </div>
          )}
        />
        <Column
          title={"TAX"}
          dataIndex="tax"
          width="75px"
          render={(name, item) => (
            <div>
              <h4>{Number(item.totalTaxPercent).toFixed(2)} %</h4>
            </div>
          )}
        />
        <Column
          title={"CGST"}
          dataIndex="csgt"
          width="75px"
          render={(name, item) => (
            <div>
              <h4>{Number(item.totalTaxPercent / 2).toFixed(2)} %</h4>
            </div>
          )}
        />
        <Column
          title={"SGST"}
          dataIndex="sgst"
          width="75px"
          render={(name, item) => (
            <div>
              <h4>{Number(item.totalTaxPercent / 2).toFixed(2)} %</h4>
            </div>
          )}
        />
        <Column
          title={"Total"}
          dataIndex="total"
          width="100px"
          fixed="right"
          render={(name, item) => (
            <div className="last-total">
              <h4 className="orange">
                ₹{Number(item.activeBatch.totalSellingPrice).toFixed(2)}
              </h4>
              <Select
                placeholder={<span class="ellipsis">...</span>}
                value={<span class="ellipsis">...</span>}
                className="total-select"
                onSelect={() => {
                  renderConfirmModalRemoveSingle(item);
                }}
              >
                <Option>Remove</Option>
              </Select>
            </div>
          )}
        />
      </Table>
      {/* {modalVisible && (
        <Modal
          id="genrate-invoice-modal"
          visible={modalVisible}
          onOk={() => {
            setmodalVisible(false);
            handleRemoveProduct(currentItem);
          }}
          onCancel={() => {
            setmodalVisible(false);
          }}
          width={500}
        >
          Are you sure you want to delete this product?
        </Modal>
      )}
      {modalVisibleMultiple && (
        <Modal
          id="genrate-invoice-modal"
          visible={modalVisibleMultiple}
          onOk={() => {
            setmodalVisibleMultiple(false);
            handleRemoveMultipleCartItems();
          }}
          onCancel={() => {
            setmodalVisibleMultiple(false);
          }}
          width={500}
        >
          Are you sure you want to delete all selected Products?
        </Modal> */}
    </div>
  );
};

export default TableContent;
