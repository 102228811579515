


import React, { useState } from "react";

import { withRouter } from "react-router-dom";
import { Card, Typography, Button } from "antd";

import "./MobileProductCard.css";
import { ENV_TYPE } from "../../Utils/constants";

import PlaceholderImage from "../../Assets/Images/PlaceHolderImages/placeholder-product-card.jpg";

const { Text } = Typography;
const MobileProductCard = (props) => {
  const [imageLoadError, setImageLoadError] = useState(false);

  const handleRedirection = () => {
    props.history.push(
      ENV_TYPE === "production" ? `/product-detail` : `/customer/product-detail`
    );
  };

  return (
    <Card
      style={{ width: 150, minHeight: "200px" }}
      className="mobile-product-card-container"
      onClick={() => handleRedirection()}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        {!imageLoadError ? (
          <img
            alt="example"
            src="https://rukminim1.flixcart.com/image/150/150/jmwch3k0/dress/6/a/r/xl-sfkurt0235-olive-sassafras-original-imaf9pevxresrgfe.jpeg?q=70"
            onError={() => {
              setImageLoadError(true);
            }}
          />
        ) : (
          <img
            alt="example"
            src={PlaceholderImage}
            style={{ width: "100px" }}
          />
        )}
      </div>

      <div className="mobile-product-card-product-name-container">
        <Text strong style={{ fontSize: "13px" }}>
          Product Name
        </Text>
      </div>
      <div
        style={{ padding: "0px 10px", marginTop: "5%" }}
        className="product-card-des"
      >
        <div className="product-card-des-line1">
          <Text style={{ color: "#3e6df3", fontSize: "12px" }}>₹ 123</Text>
          <Text type="secondary" delete>
            ₹ 123
          </Text>
        </div>
        <div className="product-card-des-line1">
          <Text style={{ fontSize: "12px" }}>100gm</Text>
          <Text style={{ color: "green", fontSize: "12px" }}>20% off</Text>
        </div>
      </div>
      <Button style={{ display: "block" }} className="add-to-cart-button">
        Add to cart
      </Button>
    </Card>
  );
};

export default withRouter(MobileProductCard);
