
import React, { useState, useEffect, useRef, Fragment } from "react";
import { connect } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Button,
  Typography,
  Form,
  Input,
  Modal,
  Row,
  Col,
  message,
  Radio,
  Checkbox,
  Select,
} from "antd";
import {addAddress,editAddress,} from "../../../../ApiAction/CustomerApiActions/addressAPI";
import { refetchAddressList } from "../../../../ReduxStore/action/customerAddressActions";
import Loading from "../../../GeneralComponents/Loading";
import "./addAddress.scss";
import { stateSuggestions } from '../../../../Utils/constData';
import { fetchDistrictList } from "../../../../ApiAction/GeneralApiActions/pinCodeAPI";

const { Title, Text } = Typography;

const AddAddress = (props) => {
  const { editItem, isEdit, setVisible } = props;
  const [form] = Form.useForm();
  const [initialItem, setInitialItems] = useState();
  const [addressType, setaddressType] = useState("Home");
  const [loading, setloading] = useState(false);
  const [districtSuggestion, setDistrictSuggestion] = useState([]);
  const [selectedState, setSelectedState ] = useState()
  const [selectedDistrict, setSelectedDistrict] = useState();
  const districtRef = useRef()
  const { Option } = Select;
  const formRef = useRef();

  const pinCodeValidator = (rule, value, callback) => {
    if (value !== undefined && (value.length > 6 || value.length < 6)) {
      callback("Enter a valid pincode");
    } else {
      callback();
    }
  };

  const fetchDistrict = (value) => {
    setSelectedState(value)
    let state = [value]
    setSelectedDistrict("District *")
    setDistrictSuggestion([])
    fetchDistrictList(state).then((response)=> {
      setDistrictSuggestion(response?.data?.documents[0]?.source?.districts)
    }).catch((error)=> {
      console.log("Address District Error :: ", error.message)
    })
  }

  const handleDistrictSelection = (value) => {
    setSelectedDistrict(value)
  }

  const handleonChangeType = (e) => {
    setaddressType(e.target.value);
  };

  const handleOk = () => {
    setInitialItems({});
    props.setVisible(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setInitialItems({});
    props.setVisible(false);
    form.resetFields();
  };

  const onFinish = (values) => {
    console.log("addAddressPayload:", values);

    values.addressLine1 =
      values && values.addressLine1 && values.addressLine1.trim();
    values.addressLine2 =
      values && values.addressLine2 && values.addressLine2.trim();
    values.buildingName =
      values && values.buildingName && values.buildingName.trim();
    values.ownerName = values && values.ownerName && values.ownerName.trim();
    if(selectedState === 'State *') {
      message.warning("Select your State")
      return false;
    }
    values.state = selectedState
    if (selectedDistrict === 'District *') {
      message.warning("Select your District")
      return false;
    }
    values.district = selectedDistrict

    if (isEdit) {
      if (props.quickCheckout) {
        props.setaddress(values);
      } else {
        const payload = { ...values, id: initialItem.id };
        console.log("Address payload :: ")
        console.log(payload)
        setloading(true);

        editAddress(payload)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                message.success(response.data.message);
                form.resetFields();
                props.refetchAddressList();
                setVisible(false);
                setSelectedState('')
                setSelectedDistrict('')
                setDistrictSuggestion([]);
              }
            } else message.error(response.data.message);
          })
          .catch(() => {})
          .finally(() => {
            setloading(false);
          });
      }
    } else {
      if (props.quickCheckout) {
        props.setaddress(values);
      } else {
        setloading(true);
        addAddress(values)
          .then((response) => {
            if (response.status === 200) {
              if (response.data.status === "success") {
                message.success(response.data.message);
                form.resetFields();
                if (props.isCart) props.setSuccess();
                else {
                  props.refetchAddressList();
                  setVisible(false);
                }
                setSelectedState('')
                setSelectedDistrict('')
                setDistrictSuggestion([]);
              }
            } else message.error(response.data.message);
          })
          .catch(() => {})
          .finally(() => {
            setloading(false);
          });
      }
    }
  };
  const PhoneValidator = (rule, value, callback) => {
    if (value !== undefined && value.length > 0 && value.length < 12) {
      callback("Enter valid phone number");
    } else {
      callback();
    }
  };
  const formFields = [
    {
      name: "ownerName",
      placeHolder: "Name",
      required: true,
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z ]+$"),
          message: "Enter a valid User Name",
        },
      ],
    },
    {
      name: "phoneNo",
      placeHolder: "Phone Number",
      validator: PhoneValidator,
      required: true,
    },
    { name: "alternatePhoneNo", placeHolder: "Alternate PhoneNumber" },
    {
      name: "emailId",
      placeHolder: "Email ID",
      ruleType: "email",
      message: "Enter a vallid email Id",
    },
    {
      name: "buildingName",
      placeHolder: "Building Name/Number",
      required: true,
    },
    { name: "addressLine1", placeHolder: "Address Line1", required: true },
    { name: "addressLine2", placeHolder: "Address Line2" },
    {
      name: "state",
      placeHolder: "State",
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z& ]+$"),
          message: "Special characters and numerics are not allowed",
        },
      ],
    },
    {
      name: "district",
      placeHolder: "District",
      rules: [
        {
          pattern: new RegExp("^[a-zA-Z ]+$"),
          message: "Special characters and numerics are not allowed",
        },
      ],
    },
    {
      name: "pinCode",
      placeHolder: "PIN Code",
      // validator: pinCodeValidator,
      required: true,
      maxLength: 6,
      rules: [
        {
          pattern: new RegExp("^[1-9][0-9]{5}$"),
          message: "Enter a valid pincode",
        },
      ],
    },
  ];

  useEffect(() => {
    console.log("Edit Item")
    console.log(editItem)
    if(editItem) {
      setSelectedState(editItem.state)
      setSelectedDistrict(editItem.district)
    }
    form.resetFields();
    editItem && setInitialItems(editItem);
  }, [editItem]);
  return (
    <Modal
      title={
        <h1 level={4} className="sub-title-tags">
          {" "}
          {isEdit ? "Edit Address" : "Add Address"}
        </h1>
      }
      // closable={false}
      visible={props.visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={500}
      footer={null}
      className="circle_drop circle-left"
    >
      <div className="flex">
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="add_address_form_cnt login-modal-form">
              <Text className="c-txt" strong style={{ fontSize: "14px" }}>
                {" "}
                Address Details
              </Text>
              <Form
                form={form}
                name="normal_login"
                className="login-form add_address_form"
                initialValues={
                  props.isEdit
                    ? editItem
                    : { addressType: "Home", primary: false }
                }
                ref={formRef}
                onFinish={onFinish}
              >
                {formFields.map((data) => {
                  return (
                    <Fragment>
                      <Form.Item
                        hasFeedback
                        name={data.name}
                        // label={props.isEdit ? data.placeHolder : ""}

                        rules={
                          data.rules
                            ? [
                                ...data.rules,
                                {
                                  type: data.ruleType ? data.ruleType : null,
                                  message: data.message ? data.message : null,
                                },
                                {
                                  required: data.required ? true : false,
                                  message: "This field is required !",
                                },
                                {
                                  validator: data.validator
                                    ? data.validator
                                    : "",
                                },
                              ]
                            : [
                                {
                                  type: data.ruleType ? data.ruleType : null,
                                  message: data.message ? data.message : null,
                                },
                                {
                                  required: data.required ? true : false,
                                  message: "This field is required !",
                                },
                                {
                                  validator: data.validator
                                    ? data.validator
                                    : "",
                                },
                              ]
                        }
                      >
                        {data.name === "phoneNo" || data.name === "alternatePhoneNo" ? (
                          <PhoneInput
                            country={"in"}
                            className="phone_number_inpt"
                            countryCodeEditable={false}
                            onChange={(phone) => console.log(phone)}
                          />
                        ) : (data.name === 'state') ? (
                          <Fragment>
                              <Select name={data.name}
                              defaultValue={editItem && editItem.state}
                              placeholder="State *"
                              onChange={fetchDistrict}>
                                {stateSuggestions?.map((state, index) => (
                                  <Option key={index} value={state}>{state}</Option>
                                ))}
                            </Select>
                          </Fragment>
                        ) : (data.name === 'district') ? (
                          <Fragment>
                            <Select name="district"
                             defaultValue={editItem && editItem?.district}
                             placeholder="District *"
                             value={selectedDistrict === '' ? 'District *' : selectedDistrict}
                             onChange={handleDistrictSelection}>
                              {districtSuggestion?.map((district, index) => (
                                <Option key={index} value={district}>{district}</Option>
                              ))}
                            </Select>
                          </Fragment>
                        ) : (
                          <Input
                            maxLength={data.maxLength ? data.maxLength : "  "}
                            ref={data.ref ? data.ref : null}
                            type={data.type ? data.type : null}
                            placeholder={`${data.placeHolder} ${
                              data.required ? "*" : ""
                            }`}
                            style={{ height: "50px" }}
                          />
                        )}
                      </Form.Item>
                    </Fragment>
                  );
                })}

                <Text
                  className="c-txt"
                  strong
                  style={{ marginBottom: "10px", display: "block" }}
                >
                  Type of Address
                </Text>
                <Form.Item name="addressType" className="addr_type lg-drop">
                  <Radio.Group
                    onChange={handleonChangeType}
                    value={addressType}
                  >
                    <Radio value={"Home"}>Home</Radio>
                    <Radio value={"Office"}>Office</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  className="add_address_default_checkbox lg-drop"
                  name="primary"
                  valuePropName="checked"
                  style={{ marginTop: "25px", display: "block" }}
                >
                  {!props.quickCheckout && (
                    <Checkbox onChange={(e) => console.log(e.target.checked)}>
                      SET AS DEFAUT ADDRESS
                    </Checkbox>
                  )}
                </Form.Item>

                <Form.Item className="button_submit">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ height: "50px" }}
                  >
                    {loading ? (
                      <Loading style={{ color: "white" }} />
                    ) : isEdit ? (
                      "Update Address"
                    ) : (
                      "Add Address"
                    )}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    className="cancel-address-form-button"
                    onClick={() => handleCancel()}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = { refetchAddressList };

export default connect(null, mapDispatchToProps)(AddAddress);
