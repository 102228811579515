


import axios from "axios";
import { API_SERV_ADDR } from "../../Utils/constants";

import Axios from "../axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();

export const registerCustomer = (payload) => {
  return axios({
    url: `${API_SERV_ADDR}/user/signup`,
    method: "PUT",
    data: payload,
  });
};

export const registerStore = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/user/paid/add`,
    method: "POST",
    data: payload,
  });
};

export const editStoreWarehouse = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/admin/user/update`,
    method: "POST",
    data: payload,
  });
};

export const loginCustomer = (payload, isAdmin = false, isWorkAdmin = false) => {
  return axios({
    url: isAdmin ? `${API_SERV_ADDR}/admin/login` : isWorkAdmin? `${API_SERV_ADDR}/work-report/login` :`${API_SERV_ADDR}/login`,
    method: "POST",
    data: payload,
  });
};

export const forgotPasswordApi = (payload) => {
  return axios({
    url: `${API_SERV_ADDR}/forgot-password`,
    method: "POST",
    data: payload,
  });
};

export const resetPasswordApi = (payload) => {
  return axios({
    url: `${API_SERV_ADDR}/reset/forgot-password`,
    method: "POST",
    data: payload,
  });
};

//activate account api
export const activateAccount = (activationKey) => {
  return axios({
    url: `${API_SERV_ADDR}/user/account/activate/${activationKey}`,
    method: "GET",
  });
};

//update-email-and-login

export const updateEmailAndLogin = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/update-email-and-login`,
    method: "POST",
    data: payload,
  });
};
