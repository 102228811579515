import React, { useEffect, useState } from "react";
import { Table, Pagination, Button, message, Tooltip } from "antd";
import Column from "antd/lib/table/Column";
import {
  fetchAffiliateInvoice,
  fetchAllExceededPlatformCharge,
  fetchEligibleAffiliateCharges,
  fetchEligibleCharges,
  fetchPlatformInvoice,
  generateAffiliateInvoice,
  generateInvoice,
  platformVendorBlock,
} from "../../../ApiAction/AdminApiAction/adminApi";
import moment from "moment";
import PlatformInvoiceModal from "../../Admin/ManagePlatform/PlatformInvoiceModal";
import Cookies from "universal-cookie";
import NumberFormat from "react-number-format";
import PlatformUpdateModal from "./PlatformUpdateModal";
import { FaLastfmSquare } from "react-icons/fa";
import {
  findBrowser,
  findDeviceModel,
} from "../../Admin/Logger/notificationBar/LogHelper";

function ManagePlatformTable(props) {
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowKeys, setSelectedRowKey] = useState([]);
  const [platformData, setPlatformData] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [showPlatformInvoice, setShowPlatformInvoice] = useState(false);
  const [showPlatformUpdateModal, setShowPlatformUpdateModal] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [reload, setReload] = useState(false);
  const cookies = new Cookies();
  const [accountType] = useState(cookies.get("accountType"));
  const [updateModalData, setUpdateModalData] = useState();

  const {
    vendorType,
    startTime,
    endTime,
    searchVal,
    requestStatus,
    activeTab,
    keys,
    vendorId,
    colorFilterKey,
  } = props;

  const isAffiliate = vendorType === "AFFILIATE";

  //*For Getting initial Value
  useEffect(() => {
    setTotalAmount(0);
    setLoading(true);
    if (activeTab === keys) {
      if (activeTab === "2" || activeTab === "1") {
        const payload = {
          size: 100,
          offset: offset,
          sortType: "asc",
          sortBy: "orderedDate",
          searchKey: searchVal,
          vendorKey: vendorId,
          vendorType: vendorType,
          startTime: startTime,
          endTime: endTime,
        };
        (isAffiliate? 
          fetchEligibleAffiliateCharges(payload) : fetchEligibleCharges(payload)
        ).then((response) => {
            if (response?.data?.status === "success") {
              setPlatformData(mapData(response?.data?.documents));
              setTotalCount(response?.data?.count);
            } else {
              setPlatformData([]);
              setTotalCount(response?.data?.count);
            }
          })
          .catch((error) => {
            console.log(error.message);
          })
          .finally(() => {
            setLoading(false);
            setSelectedRowKey([]);
            setSelectedGroupIds([]);
          });
      } else if (activeTab === "4") {
        const payload = {
          vendorKey: vendorId,
          searchKey: parseInt(colorFilterKey),
        };
        fetchAllExceededPlatformCharge(payload)
          .then((response) => {
            if (response?.data?.status === "success") {
              setPlatformData(mapData3(response?.data?.documents));
              setTotalCount(response?.data?.count);
            } else {
              setPlatformData([]);
              setTotalCount(0);
            }
          })
          .catch((error) => {})
          .finally(() => setLoading(false));
      } else {
        const payload = {
          size: 100,
          offset: offset,
          sortBy: "requestedDate",
          sortType: "asc",
          searchKey: searchVal,
          vendorKey: vendorId,
          vendorType: vendorType,
          menuType: vendorType,
          startTime: startTime,
          endTime: endTime,
          vendorId: vendorId,
          requestStatus: requestStatus,
          accountType: accountType,
        };
        const affiliatePayload = {
          size: 100,
          offset: offset,
          sortBy: "requestedDate",
          sortType: "asc",
          searchKey: searchVal,
          vendorId: vendorId,
          vendorType: vendorType,
          startTime: startTime,
          endTime: endTime,
          requestStatus: requestStatus,
          accountType: accountType,
        };
        (isAffiliate ? 
          fetchAffiliateInvoice(affiliatePayload) : fetchPlatformInvoice(payload)
        ).then((response) => {
            if (response?.data?.status === "success") {
              setPlatformData(mapData2(response?.data?.documents));
              setTotalCount(response?.data?.count);
            } else {
              setPlatformData([]);
            }
          })
          .catch((error) => {
            console.log("Error in Getting Data :: ", error.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [
    endTime,
    startTime,
    vendorType,
    searchVal,
    offset,
    requestStatus,
    activeTab,
    keys,
    vendorId,
    reload,
    colorFilterKey,
  ]);

  //*For mapping Raw Data
  const mapData = (rawData) => {
    let tempArray = [];
    let apple = rawData?.map((data, index) => {
      let tempObj = {
        key: index,
        id: data?.id,
        version: data?.version,
        invoiceId: data?.orderGroupId,
        paymentMode: data?.paymentMode,
        invoiceDate: moment(data?.orderedDate).format("YYYY-MM-DD hh:mm A"),
        invoiceTotal: data?.invoiceTotal.toFixed(2),
        vendorName: data?.vendorName,
        invoiceDate2: data?.orderedDate,
        vendorType: data?.vendorType,
        paymentMode: data?.paymentMode,
        platformCharge: data?.platformAmount.toFixed(2),
        affiliateId: data?.affiliateId
      };
      tempArray.push(tempObj);
    });
    return tempArray;
  };

  //*For mapping Raw Data
  const mapData2 = (rawData) => {
    let tempArray = [];
    let apple = rawData?.map((data, index) => {
      let tempObj = {
        key: index,
        id: data?.id,
        invoiceDate: moment(data?.source?.requestedDate).format(
          "YYYY-MM-DD hh:mm A"
        ),
        vendorAddress: isAffiliate? data?.source?.affiliateAddress : data?.source?.vendorAddress,
        vendorName: isAffiliate? data?.source?.affiliateName : data?.source?.vendorName,
        platformOrders: isAffiliate? data?.source?.affiliateOrders : data?.source?.platformOrders,
        platformCharge: data?.source?.requestedAmount.toFixed(2),
        platformId: isAffiliate?
          data?.source?.affiliateInvoiceId : data?.source?.platformId,
        accountType: accountType,
        requestStatus: data?.source?.requestStatus,
        requestedDate: data?.source?.requestedDate,
        transactionId: data?.source?.transactionId,
        comments:
          data?.source?.overallStatus[
            parseInt(data?.source?.overallStatus?.length) - 1
          ]?.comments,
        paymentMode: data?.source?.paymentMode,
        history: data?.source?.overallStatus,
      };
      tempArray.push(tempObj);
    });
    return tempArray;
  };

  //*For mapping Raw Data
  const mapData3 = (rawData) => {
    let tempArray = [];
    let apple = rawData?.map((data, index) => {
      let tempObj = {
        key: index,
        vendorId: data?.vendorId,
        vendorName: data?.vendorName,
        platformCharge: data?.platformAmount,
        color: data?.color,
        block: data?.blocked,
      };
      tempArray.push(tempObj);
    });
    return tempArray;
  };
  //For Pagination
  const handlePagination = (page) => {
    setOffset(50 * (page - 1));
    setCurrentPage(page);
  };

  // function that gets called when we select row of table
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKey(selectedRowKeys);
    calculateTotal(selectedRowKeys);
  };

  //* For checking for disable checkbox
  const onDisableChange = (record) => ({
    disabled: record?.paymentMode === "ONLINE",
    name: record?.paymentMode,
  });

  // This is antd's function when capture all the selected rows and add to SelectedRowKeys Array
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: onDisableChange,
  };

  // function that calculate all the selected transactions
  const calculateTotal = (rows) => {
    setTotalAmount(0);
    let tempSelectedIds = [];
    let total = 0;
    let tempDataObj = {};
    platformData.map((payment, index) => {
      rows.map((eachPay, i) => {
        if (eachPay === index) {
          total += parseFloat(payment.platformCharge);
          setTotalAmount(total);
          tempDataObj = {
            orderGroupId: payment?.invoiceId,
            platformAmount: parseFloat(payment?.platformCharge),
            invoiceDate: payment?.invoiceDate2,
            version: payment?.version,
            paymentMode: payment?.paymentMode,
            invoiceAmount: parseFloat(payment?.invoiceTotal),
            ...(isAffiliate ?
              {
                affiliateAmount: parseFloat(payment?.platformCharge),
                affiliateChargeId: payment?.id,
                affiliateId: payment?.affiliateId,
              } : {
                platformAmount: parseFloat(payment?.platformCharge),
                platformChargeId: payment?.id,
              })
          };
          tempSelectedIds.push(tempDataObj);
          setSelectedGroupIds(tempSelectedIds);
        }
      });
    });
  };

  const handlePlatformPayment = () => {
    let updatedBy = localStorage.getItem("activeCustomer")
      ? JSON.parse(localStorage.getItem("activeCustomer"))
      : null;
    setRequestLoading(true);
    const affiliateId = selectedGroupIds[0]?.affiliateId;
    if(isAffiliate) {
      console.log(selectedGroupIds)
      // if(selectedGroupIds.find(order => order.affiliateId !== affiliateId)) {
      //   message.error("Affiliate");
      //   return;
      // }
    }
    const payload = {
      requestedDate: new Date().getTime(),
      requestPaymentId: "",
      updatedBy: "admin",
      updatedId: updatedBy?.userId,
      requestedAmount: totalAmount,
      comments: "Request Sent",
      ...(isAffiliate ?
        {
          affiliateOrders: selectedGroupIds,
          affiliateId: affiliateId
        } : {
          vendorId: vendorId,
          vendorType: vendorType,
          platformOrders: selectedGroupIds
        })
    };
    console.log(payload);
    (isAffiliate? generateAffiliateInvoice(payload) : generateInvoice(payload))
      .then((response) => {
        if (response?.data?.status === "success") {
          message.success(response?.data?.message);
          setTotalAmount(0);
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        console.log("Error in Generating Invoice :: ", error.message);
        setReload(prev => !prev);
      })
      .finally(() => {
        setRequestLoading(false);
        setReload(prev => !prev);
      });
  };

  //*For showing Invoice modal
  const handleShowPlatformInvoice = (value) => {
    console.log(value);
    setDataList(value);
    setShowPlatformInvoice(true);
  };

  const blockVendor = (data) => {
    setLoading(true);
    const payload = {
      vendorId: data?.vendorId,
      //adminUser: cookies.get("userId"),
      //pageURL: window.location.href,
      //screenName: "Platform Charge Store",
      //browserInfo: findBrowser(),
      //accountType: accountType,
      //deviceModel: findDeviceModel(),
      block: !data?.block
    };
    platformVendorBlock(payload)
      .then((response) => {
        if (response?.data?.status === "success") {
          message.success(response?.data?.message);
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch()
      .finally(() => {
        setLoading(false);
        setReload((prev) => {
          return !prev;
        });
      });
  };

  return (
    <div>
      <Table
        className="manageAccountTable"
        loading={loading}
        dataSource={platformData}
        style={{ cursor: "pointer" }}
        rowSelection={
          activeTab === "2"
            ? accountType === "admin" || isAffiliate
              ? rowSelection
              : false
            : false
        }
        pagination={activeTab === "4" ? true : false}
        scroll={{ x: 1000 }}
        rowClassName={(record, index) => {
          let className =
            record?.color === 3
              ? "warning-high-background block-vendor-platform-text"
              : record?.color === 2
              ? "warning-mid-background block-vendor-platform-text"
              : record?.color === 1
              ? "warning-low-background block-vendor-platform-text"
              : "";
          return className;
        }}
      >
        {activeTab !== "4" ? (
          <>
            {activeTab === "2" && (
              <>
                <Column title={"INVOICE NO."} dataIndex="invoiceId" />
                <Column title={"PAYMENT MODE"} dataIndex="paymentMode" />
              </>
            )}
            {activeTab === "1" && (
              <>
                <Column title={"INVOICE NO."} dataIndex="invoiceId" />
                <Column title={"PAYMENT MODE"} dataIndex="paymentMode" />
              </>
            )}
            {activeTab !== "2" && activeTab !== "1" && (
              <Column
                title={(isAffiliate ? "AFFILIATE" : "PLATFORM") + " CH. ID"}
                dataIndex="platformId"
                render={(platformId, option) => {
                  return (
                    <p
                      onClick={() => {
                        setShowPlatformUpdateModal(true);
                        setUpdateModalData(option);
                        console.log(option);
                      }}
                      className="platform-id-in-table"
                    >
                      {platformId}
                    </p>
                  );
                }}
              />
            )}

            <Column title={"INVOICE DATE"} dataIndex="invoiceDate" />
            {accountType === "admin" && (
              <Column title={(isAffiliate ? "AFFILIATE" : "VENDOR") + " NAME"} dataIndex="vendorName" />
            )}

            <Column
              title={(isAffiliate ? "AFFILIATE" : "PLATFORM") + " CHARGE"}
              dataIndex="platformCharge"
              align="right"
              render={(platformCharge) => {
                return (
                  <NumberFormat
                    value={platformCharge}
                    thousandSeparator
                    displayType="text"
                  />
                );
              }}
            />
            {activeTab === "2" && (
              <Column
                title={"INVOICE TOTAL"}
                dataIndex="invoiceTotal"
                align="right"
                render={(invoiceTotal) => {
                  return (
                    <NumberFormat
                      value={invoiceTotal}
                      thousandSeparator
                      displayType="text"
                    />
                  );
                }}
              />
            )}

            {activeTab === "1" && (
              <Column
                title={"INVOICE TOTAL"}
                dataIndex="invoiceTotal"
                align="right"
                render={(invoiceTotal) => {
                  return (
                    <NumberFormat
                      value={invoiceTotal}
                      thousandSeparator
                      displayType="text"
                    />
                  );
                }}
              />
            )}
            {activeTab !== "2" && activeTab !== "1" && (
              <Column
                title={"INVOICE"}
                dataIndex={"invoice"}
                render={(value, option) => (
                  <Button onClick={() => handleShowPlatformInvoice(option)}>
                    View Invoice
                  </Button>
                )}
              />
            )}
          </>
        ) : (
          <>
            <Column title={"VENDOR ID"} dataIndex="vendorId" />
            <Column title={"VENDOR NAME"} dataIndex="vendorName" />
            <Column
              title={"PLATFORM CHARGE"}
              dataIndex="platformCharge"
              align="right"
              sorter={(a, b) => {
                if (a.platformCharge < b.platformCharge) {
                  return -1;
                }
                if (a.platformCharge > b.platformCharge) {
                  return 1;
                }
                return 0;
              }}
              render={(platformCharge) => {
                return (
                  <NumberFormat
                    value={platformCharge?.toFixed(2)}
                    thousandSeparator
                    displayType="text"
                  />
                );
              }}
            />
            <Column
              title={"ACTION"}
              render={(value, option) => (
                <Tooltip
                  top
                  title={option?.block && "Go To Manage Store, to UNBLOCK"}
                >
                  <Button
                    onClick={() => {
                      blockVendor(option);
                    }}
                    value={!option?.block}
                  >
                    {option?.block ? "UNBLOCK" : "BLOCK VENDOR"}
                  </Button>
                </Tooltip>
              )}
            />
          </>
        )}
      </Table>

      <br />
      <br />
      {activeTab !== "4" && (
        <Pagination
          currentPage={currentPage}
          total={totalCount}
          showSizeChanger={false}
          defaultPageSize={50}
          onChange={(page) => {
            window.scrollTo(0, 0);
            handlePagination(page);
          }}
        />
      )}

      {activeTab === "2" && (accountType === "admin" || accountType === "affiliate") && (
        <div>
          <div className="thermal-padding"></div>
          <p>
            Total Amount :{" "}
            <NumberFormat
              value={totalAmount.toFixed(2)}
              thousandSeparator
              displayType="text"
            />
          </p>
          <Button
            type="primary"
            onClick={handlePlatformPayment}
            disabled={totalAmount > 0 ? false : true}
            loading={requestLoading}
          >
            Send Request
          </Button>
        </div>
      )}

      {showPlatformInvoice && (
        <PlatformInvoiceModal
          dataList={dataList}
          setShowPlatformInvoice={setShowPlatformInvoice}
          isAffiliate={isAffiliate}
        />
      )}
      {showPlatformUpdateModal && (
        <PlatformUpdateModal
          setModalVisible={setShowPlatformUpdateModal}
          dataList={updateModalData}
          reload={setReload}
          activeTab={activeTab}
          accountType={accountType}
          isAffiliate={isAffiliate}
        />
      )}
    </div>
  );
}

export default ManagePlatformTable;
