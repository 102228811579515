


import React, { Fragment, useEffect, useState } from "react";
import { Button, message } from "antd";
import Cookies from "universal-cookie";
import { history } from "../../../App";
import { connect } from "react-redux";
import { fetchCartCount } from "../../../ReduxStore/action/index";

import { addToCartVendor } from "../../../ApiAction/SellerApiActions/sellerApi";
import { ENV_TYPE } from "../../../Utils/constants";

const AddToCart = (props) => {
  const { product, quantity, selectedItem } = props;
  const cookies = new Cookies();
  const [btnText, setbtnText] = useState("Add to cart");
  const [inCart, setinCart] = useState(false);

  const handleConfirmAddToCart = async () => {
    if (inCart) {
      switch (cookies.get("accountType")) {
        case "customer":
          history.push(ENV_TYPE === "production" ? `/cart` : `/customer/cart`);
          break;
        case "warehouse":
          history.push(`/warehouse/cart`);
          break;
        case "store":
          history.push(`/store/cart`);
          break;
        default:
          break;
      }
    }
    // if (props.cartItems) {
    //   const isAlreadyExists = await props.cartItems.find((item) => {
    //     return (
    //       item.productId == product.productId &&
    //       item.serialNumber === selectedItem.serialNumber
    //     );
    //   });

    //   if (isAlreadyExists) {
    //     message.warning("Product already added to cart");
    //   } else {
    //     handleAddToCart();
    //   }
    // }
    else handleAddToCart();
  };
  const handleAddToCart = () => {
    const paylaod = {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
      item: {
        productId: product.productId,
        vendorId: product.vendorId,
        vendorType: product.vendorType,
        quantity: quantity,
        itemCurrentPrice: selectedItem,
      },
    };

    addToCartVendor(paylaod)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.fetchCartCount(props.cartCountStore + 1);
            message.success(response.data.message);
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleCheckInCart = async () => {
    const isAlreadyExists = await props.cartItems.find((item) => {
      return (
        item?.productId == product?.productId &&
        item?.serialNumber === selectedItem?.serialNumber
      );
    });

    if (isAlreadyExists) setinCart(true);
    else setinCart(false);
  };
  useEffect(() => {
    if (selectedItem) {
      handleCheckInCart();
      console.log("check in cart");
    }
  }, [product, props.cartItems, selectedItem]);
  return (
    <Button
      size="large"
      type="primary"
      className="btn_theme"
      onClick={() => handleConfirmAddToCart()}
    >
      {inCart ? "Go To Cart" : "Add To Cart"}
    </Button>
  );
};

const mapDispatchToProps = { fetchCartCount };
const mapStateToProps = (state) => {
  return {
    cartCountStore: state.cartCountReducer,
    cartItems: state.cartItemsReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCart);
