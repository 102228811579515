


import { API_SERV_ADDR } from "../../Utils/constants";
import Axios from "../axios";

import Cookies from "universal-cookie";
import { getLogAttributes } from "../../components/Admin/Logger/notificationBar/LogHelper";
const cookies = new Cookies();

export const CheckAvailablProducts = (data) => {
  const payload = {
    ...data,
    userId: cookies.get("userId"),
    accountType: cookies.get("accountType"),
  };
  return Axios({
    url: `${API_SERV_ADDR}/store-warehouse/products/by-vendor-type`,
    method: "POST",
    data: payload,
  });
};

export const editVendorOrder = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/item/update`,
    method: "POST",
    data: payload,
  });
};

export const generateEwayBill = (data) => {
  const payload = { ...data, vendorId: cookies.get("userId") };
  return Axios({
    url: `${API_SERV_ADDR}/warehouse/generate-e-way-bill`,
    method: "POST",
    data: payload,
  });
};

export const downloadOrderPickList = (data) => {
  const payload = { ...data, vendorId: cookies.get("userId") };
  return Axios({
    url: `${API_SERV_ADDR}/order/picklist`,
    method: "POST",
    data: payload,
    responseType: "blob",
  });
};

export const changeStatusOfOrderGroup = (payload) => {
  payload = getLogAttributes(payload, "Manage Orders");
  return Axios({
    url: `${API_SERV_ADDR}/order/change-status`,
    method: "POST",
    data: payload
  })
}

export const cancelOrderGroup = (payload) => {
  payload = getLogAttributes(payload, "Manage Orders");
  return Axios({
    url: `${API_SERV_ADDR}/order/cancel-bulk`,
    method: "POST",
    data: payload,
  });
};

//confirm -> not-confirmed orders admin
export const confirmOrderGroup = (payload) => {
  payload = getLogAttributes(payload, "Manage Orders");
  return Axios({
    url: `${API_SERV_ADDR}/order/confirm-order-overall`,
    method: "POST",
    data: payload,
  });
};

export const reOrderGroup = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/re-order`,
    method: "POST",
    data: payload,
  });
};

export const reOrderGroupStoreWarehouse = (payload) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/re-order/store-warehouse`,
    method: "POST",
    data: payload,
  });
};

export const fetchProductStocks = (productId) => {
  return Axios({
    url: `${API_SERV_ADDR}/order/product/${productId}`,
    method: "GET",
  });
};
