

import React, { useEffect, useState } from "react";
import { Layout, Button } from "antd";
import { Route, Redirect, Switch } from "react-router-dom";
import Cookies from "universal-cookie";
import { PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import "../Admin/admin.scss";

import ManageCustomer from "../Admin/ManageCustomer/manageCustomer";
import ManageCatogery from "../Admin/ManageCategory";
import ManageAccounts from "../Admin/ManageAccounts/index";
import ManageBrand from "../Admin/ManageBrand";
import ManageSeller from "../Admin/ManageSeller/manageSeller";
import ManageProfile from "../Admin/ManageProfile/manageProfile";
import ManageStore from "../Admin/ManageStore/manageStore";
import ManageWarehouse from "../Admin/ManageWarehouse/manageWarehouse";
import SearchBarInput from "../SearchBar/SearchBarInput";
import FilterBlockArchive from "../Seller/SortBy/filterBlockArchive";
import ManageProductAdmin from "../Admin/ManageProduct/manageProductAdmin";
import AddProduct from "../Seller/ManageProducts/Components/addProduct";
import ManageSections from "../Admin/ManageSections/manageSections";
import InvoiceSearch from "../Admin/InvoiceSearch/InvoiceSearch";
import SalesReport from "../Admin/Reports/SalesReport";
import StocksReport from "../Admin/Reports/StocksReport";
import SearchReport from "../Admin/Reports/SearchReport";
import ExpiryReport from "../Admin/Reports/ExpiryReport";
import Configure from "../Admin/Configure/configure";
import ManageContent from "../Admin/ManageContent/manageContent";
import ContentSection from "../Admin/ManageContent/contentSection";
import AccountTypeContext from "../Admin/accountTypeContext";
import CentralRepo from "../Admin/CentralRepo";
import GroupByReport from "../Seller/SortBy/groupByReport";
import ManageAdvertisement from "../Admin/ManageAdvertisement/AdIndex";
import ManagePlatformIndex from "../Admin/ManagePlatform/ManagePlatformIndex";
import BarcodeSearch from "../Admin/BarcodeSearch/BarcodeSearch";
import DiscoutProducts from "../Admin/DiscountProducts/DiscoutProducts";
import ManageAllOrders from "../Admin/ManageAllOrders/manageAllOrders";
import FilterAndSortOrder from "../Admin/ManageAllOrders/filterAndSortOrder";
import RequestPayment from "../Admin/RequestPayment";
import ReplacedProducts from "../Admin/CentralRepo/replacedProducts";
import SortBy from "../Seller/SortBy";

import { fetchSort } from "../../ReduxStore/action";
import { connect } from "react-redux";
import FooterLayout from "../../Layouts/FooterLayout";
import HeaderLayout from "../../Layouts/HeaderLayout";
import WorkReportMenu from "./workReportMenu";
import ReportsFilter from "../Admin/Reports/Filter/reportsFilter";
import { ENV_TYPE, DOMAIN } from "../../Utils/constants";
import WorkLogReport from "../Admin/Reports/WorkReport";
import { get_data_entry_users } from "../../ApiAction/AdminApiAction/logApi";

const { Content, Sider } = Layout;
const WorkReport = (props) => {
  const cookies = new Cookies();
  const [urlPath, setUrlPath] = useState("");
  const [activeTab, setactiveTab] = useState();
  const [searchVal, setSearchval] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const [dataEntryUsers, setDataEntryUsers] = useState([]);
  const [openAddStore, setOpenAddStore] = useState(false);
  const [openAddWarehouse, setOpenAddWarehouse] = useState(false);

  const [openAddBrand, setopenAddBrand] = useState(false);

  const [reportSummary, setreportSummary] = useState("");

  const urlPathswithExtraHeader = [
    "/work-report/manage-customer",
    "/work-report/manage-seller",
    "/work-report/manage-store",
    "/work-report/manage-warehouse",
    "/work-report/manage-brand",
    "/work-report/manage-products",
    "/work-report/manage-all-order",
    "/work-report/sales-report",
    "/work-report/report",
    "/work-report/stocks-report",
    "/work-report/search-report",
    "/work-report/expiry-report",
    "/work-report/central-repo",
  ];

  useEffect(() => {
    // if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
    //   setCollapsed(true);
    // } else setCollapsed(false);

    if (ENV_TYPE == "dev") {
      cookies.set("userId", cookies.get("adminId"), {
        path: "/",
      });

      cookies.set("accountType", "workAdmin", {
        path: "/",
      });
    } else {
      cookies.set("userId", cookies.get("adminId"), {
        path: "/",
        domain: `.${DOMAIN}`,
      });
      cookies.set("accountType", "workAdmin", {
        path: "/",
        domain: `.${DOMAIN}`,
      });
    }
    getDataEntryMembers();
  }, []);

  useEffect(() => {
    setUrlPath(props.location.pathname);
    setactiveTab(props.location.pathname.split("/")[1]);
    setSearchval("");
    window.scrollTo(0, 0);
  }, [props.location.pathname, activeTab]);

  const getDataEntryMembers = () => {
    get_data_entry_users()
      .then((response) => {
        if(response.status === 200) {
          if(response.data.status === "success") {
            setDataEntryUsers(
              response.data.documents.map(
                (doc) => {
                  return doc.source
                }
              )
            )
          }
        }
      })
      .catch(() => {})
  };

  return (
    <Layout>
      <HeaderLayout pathname={urlPath} isWorkAdmin />
      {urlPath == "/work-report/profile" ||
      urlPath == "/work-report/manage-sections" ||
      urlPath == "/work-report/manage-content" ||
      urlPath == "/work-report/configure" ||
      urlPath == "/work-report/manage-category" ||
      urlPath == "/work-report/manage-accounts" ||
      urlPath == "/work-report/manage-platform" ||
      urlPath == "/work-report/manage-platform-sellers" ||
      urlPath == "/work-report/manage-platform-stores" ||
      urlPath == "/work-report/manage-platform-summary" ||
      // urlPath == "/work-report/manage-all-order" ||
      urlPath == "/work-report/invoice-search" ||
      urlPath == "/work-report/barcode-search" ||
      urlPath == "/work-report/manage-ads" ||
      urlPath == "/work-report/daily-report"? (
        <div style={{ marginTop: "70px" }}></div>
      ) : (
        <div className="sort-breadcrumb">
        <div className="admin-breadcrumb" style={{ width: "325px" }}></div>
        {urlPathswithExtraHeader.includes(urlPath) && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              marginLeft: "2%",
            }}
          >
            {<SearchBarInput searchValue={setSearchval} urlPath={urlPath} />}
            {(urlPath === "/work-report/manage-store" ||
              urlPath === "/work-report/manage-warehouse" ||
              urlPath === "/work-report/manage-brand") && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                size={"large"}
                onClick={() => {
                  if (urlPath === "/work-report/manage-warehouse")
                    setOpenAddWarehouse(true);
                  else if (urlPath === "/work-report/manage-store")
                    setOpenAddStore(true);
                  else if (urlPath === "/work-report/manage-brand")
                    //open add brand modal
                    setopenAddBrand(true);
                }}
              >
                {urlPath === "/work-report/manage-warehouse"
                  ? "Add Warehouse"
                  : urlPath === "/work-report/manage-store"
                  ? "Add Store"
                  : "Add Brand"}
              </Button>
            )}
            {/* {urlPath === "/work-report/manage-products" && <FilterbyDomin />} */}
            {/* {urlPath === "/work-report/manage-products" && <FilterbyStatus />} */}
            {
              // urlPath === "/work-report/stocks-report" ||
              // urlPath === "/work-report/search-report" ||
              // urlPath === "/work-report/expiry-report" ||
              (urlPath === "/work-report/sales-report" ||
                urlPath === "/work-report/manage-products" ||
                urlPath === "/work-report/report") && (
                <ReportsFilter
                  activePath={activeTab}
                  setreportSummary={setreportSummary}
                />
              )
            }
            {urlPath === "/work-report/sales-report" && <GroupByReport />}
            {
            urlPath !== "/work-report/expiry-report" &&
              urlPath != "/work-report/stocks-report" &&
              urlPath != "/work-report/sales-report" &&
              urlPath != "/work-report/manage-all-order" &&
              urlPath != "/work-report/search-report" &&
              urlPath != "/work-report/report" && (
                <SortBy activePath={activeTab} />
              )}
            {
               urlPath === "/work-report/manage-all-order" ?
               <FilterAndSortOrder/> :

              urlPath != "/work-report/manage-products" &&
              urlPath != "/work-report/stocks-report" &&
              urlPath != "/work-report/sales-report" &&
              urlPath != "/work-report/search-report" &&
              urlPath != "/work-report/expiry-report" &&
              urlPath !== "/work-report/central-repo" &&
              urlPath != "/work-report/report" && <FilterBlockArchive />}

          </div>
        )}
      </div>)}
      {/* <div className="sort-breadcrumb">
          <div className="admin-breadcrumb" style={{ width: "325px" }}></div> */}

         {/* {props.location.pathname.split("/")[2] === "report" && (
          <div className="sort-breadcrumb">
          <div className="admin-breadcrumb" style={{ width: "325px" }}></div>

            <ReportsFilter
              activePath={activeTab}
              setreportSummary={setreportSummary}
            />
          </div>
          )} */}
          {/* <SearchBarInput searchValue={setSearchval} urlPath={urlPath} /> */}
      {/* </div> */}

      <Layout className="nav-side-content-admin" style={{ padding: "3%" }}>
        <div className="profile_column_data">
          {!collapsed && (
            <Sider
              className="nav-side-bar-admin"
              trigger={null}
              collapsed={collapsed}
            >
              <WorkReportMenu activeTab={activeTab} setSearchval={setSearchval} dataEntryUsers={dataEntryUsers} />
            </Sider>
          )}
          <Layout className="admin-layout-content right_profile_layout">
            <Content>
              <Switch>
                <Route
                  exact
                  path="/work-report"
                  render={(props) => {
                    return <Redirect to="/work-report/daily-report" />;
                  }}
                />

                <Route
                  exact
                  path="/work-report/report"
                  render={(props) => {
                    return <WorkLogReport {...props} reportSummary={reportSummary} dataEntryUsers={dataEntryUsers} />;
                  }}
                />

                <Route
                  exact
                  path="/work-report/daily-report"
                  render={(props) => {
                    return <WorkLogReport {...props} reportSummary={reportSummary} isDailyReport dataEntryUsers={dataEntryUsers}/>;
                  }}
                />

                <Route
                  exact
                  path="/work-report/profile"
                  render={(props) => <ManageProfile {...props} isAdmin />}
                />
                <Route
                  exact
                  path="/work-report/manage-customer"
                  render={(props) => (
                    <ManageCustomer {...props} searchVal={searchVal} />
                  )}
                />
                <Route
                  exact
                  path="/work-report/manage-seller"
                  render={(props) => (
                    <ManageSeller {...props} isSeller searchVal={searchVal} />
                  )}
                />
                <Route
                  exact
                  path="/work-report/manage-store"
                  render={(props) => (
                    <ManageStore
                      {...props}
                      openAdd={openAddStore}
                      addModal={setOpenAddStore}
                      searchVal={searchVal}
                    />
                  )}
                />

                <Route
                  exact
                  path="/work-report/manage-warehouse"
                  render={(props) => (
                    <ManageWarehouse
                      {...props}
                      openAdd={openAddWarehouse}
                      isWarehouse
                      addModal={setOpenAddWarehouse}
                      searchVal={searchVal}
                    />
                  )}
                />
                <Route
                  exact
                  path="/work-report/manage-accounts"
                  render={(props) => <ManageAccounts isAdmin />}
                />

                <Route
                  exact
                  path="/work-report/manage-platform-sellers"
                  render={(props) => (
                    <ManagePlatformIndex vendorType="SELLER" />
                  )}
                />

                <Route
                  exact
                  path="/work-report/manage-platform-stores"
                  render={(props) => <ManagePlatformIndex vendorType="STORE" />}
                />

               <Route
                  exact
                  path="/work-report/discount-products"
                  render={(props) => <DiscoutProducts vendorType="STORE" />}
                />
                <Route
                  exact
                  path="/work-report/manage-ads"
                  render={(props) => <ManageAdvertisement isAdmin />}
                />
                <Route
                  exact
                  path="/work-report/manage-platform-summary"
                  render={(props) => (
                    <ManagePlatformIndex isAdmin type="summary" />
                  )}
                />

                <Route
                  exact
                  path="/work-report/manage-brand"
                  render={(props) => (
                    <ManageBrand
                      modalVisible={openAddBrand}
                      setModalVisible={setopenAddBrand}
                      searchVal={searchVal}
                      {...props}
                    />
                  )}
                />

                <Route
                  exact
                  path="/work-report/manage-category"
                  render={(props) => <ManageCatogery {...props} />}
                />
                <Route
                  exact
                  path="/work-report/manage-products"
                  render={(props) => (
                    <ManageProductAdmin
                      searchVal={searchVal}
                      {...props}
                      summary={reportSummary}
                    />
                  )}
                />
                <Route
                  exact
                  path="/work-report/edit-product/:productId"
                  render={(props) => {
                    return <AddProduct {...props} isEdit isAdmin />;
                  }}
                />

                <Route
                  exact
                  path="/work-report/manage-sections"
                  render={(props) => {
                    return <ManageSections {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/work-report/manage-all-order"
                  render={(props) => {
                    return <ManageAllOrders searchVal={searchVal} {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/work-report/invoice-search"
                  render={(props) => {
                    return <InvoiceSearch {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/work-report/configure"
                  render={(props) => {
                    return <Configure {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/work-report/manage-content"
                  render={(props) => {
                    return <ManageContent {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/work-report/content/:type"
                  render={(props) => {
                    return <ContentSection {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/work-report/barcode-search"
                  render={(props) => {
                    return <BarcodeSearch {...props} />;
                  }}
                />

                <Route
                  exact
                  path="/work-report/sales-report"
                  render={(props) => {
                    return (
                      <SalesReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/work-report/stocks-report"
                  render={(props) => {
                    return (
                      <StocksReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/work-report/search-report"
                  render={(props) => {
                    return (
                      <SearchReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />

                <Route
                  exact
                  path="/work-report/expiry-report"
                  render={(props) => {
                    return (
                      <ExpiryReport
                        searchVal={searchVal}
                        reportSummary={reportSummary}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/work-report/central-repo"
                  render={(props) => {
                    return <CentralRepo searchVal={searchVal} />;
                  }}
                />
                <Route
                  exact
                  path="/work-report/replaced-products"
                  render={(props) => {
                    return <ReplacedProducts searchVal={searchVal} />;
                  }}
                />
                <Route
                  exact
                  path="/work-report/central-repo/edit-product/:productId"
                  render={(props) => {
                    return (
                      <AddProduct {...props} isEdit isAdmin isCentralRepo />
                    );
                  }}
                />
                <Route
                  exact
                  path="/work-report/central-repo/add-product"
                  render={(props) => {
                    return <AddProduct {...props} isAdmin isCentralRepo />;
                  }}
                />
                 <Route
                  exact
                  path="/work-report/request-payment"
                  render={(props) => (
                    <RequestPayment />
                  )}
                />
                <Route
                  render={(props) => {
                    return <Redirect to="/404" />;
                  }}
                />
              </Switch>
            </Content>
          </Layout>
        </div>
      </Layout>
      <AccountTypeContext.Provider value="work-report">
        <FooterLayout />
      </AccountTypeContext.Provider>
    </Layout>
  );
};
const mapDispatchToProps = {
  fetchSort,
};

const mapStateToProps = (state) => {
  return {
    customer: state.customerData,
    dateRange: state.reportReducer.dateRange,
    screenFilter: state.reportReducer.screenFilter
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkReport);
