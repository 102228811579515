


import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import thumbnailImg from "../../../Assets/Images/PlaceHolderImages/Product_Image_Placeholder.jpg"



const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
 
  animation: ${loadingAnimation} 1s infinite;
  line-height: 50px; font-size: 25px;
`;

const StyledImage = styled.img`
  
   line-height: 50px; font-size: 25px;
  
`;

const LazyImage = ({ src, alt ,classname}) => {
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  return (
  <>
      <Placeholder ref={refPlaceholder} />
      <LazyLoad>
        <StyledImage
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={src?src:thumbnailImg}
          alt={alt}
          className={classname}
        />
      </LazyLoad>
</>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default LazyImage;
