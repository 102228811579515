


import React, { useState, useEffect } from "react";
import { Layout, Menu } from "antd";
import "antd/dist/antd.css";

import HeaderView from "../../Views/HeaderView";
import { PropertySafetyFilled } from "@ant-design/icons";

const { Header } = Layout;

export default function HeaderLayout(props) {
  const [customerView, setcustomerView] = useState();

  useEffect(() => {
    if (window.location.href.indexOf("/customer/home-page") > -1) {
      setcustomerView(true);
    } else setcustomerView(false);
  }, []);
  return (
    <Header
      className={customerView ? "header-style" : "header-style-non-customer"}
    >

      <HeaderView {...props} />
    </Header>
  );
}
