


import React, { useState, useEffect } from "react";

import { Modal, Typography } from "antd";
import { connect } from "react-redux";

import "../Login/login.scss";
import {
  openLoginModal,
  openSignUpModal,
  closeAuthModal,
} from "../../../ReduxStore/action/authModalActions";
import SignUpForm from "./signUpForm";

import CutomerLoginVector from "../../../Assets/Images/Vectors/DL_Customer.png";
import CompanyLogo from "../../../Assets/Images/Logo/DL_Logo.png";
import SellerLoginVector from "../../../Assets/Images/Vectors/DL_Seller-20.svg";
import { registerCustomer } from "../../../ApiAction/CustomerApiActions/customerApi";

const { Title } = Typography;
const Signup = (props) => {
  const [visible, setvisible] = useState(false);
  const [userType, setuserType] = useState();
  const [vectorImage, setvectorImage] = useState();

  const handleOk = () => {
    props.isCustomer && setvisible(false);
  };

  const handleCancel = () => {
    props.isCustomer && setvisible(false);
  };

  //related to form starts here
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  //related to form ends here

  const getWebViewVector = () => {
    if (window.innerWidth <= 760) {
      return;
    } else
      return (
        <div className="login-modal-media">
          <Title level={3}>
          <span>Welcome to{" "}</span>
            <span>
              {" "}
              <img
                className="login-company-logo"
                src={CompanyLogo}
                alt={"company logo"}
              />
            </span>
          </Title>{" "}
          <img
            className="login-company-customer-vector"
            src={vectorImage}
            alt={"company logo"}
          />
        </div>
      );
  };

  useEffect(() => {
    console.log(props.authModalUserType, props.authModalType, "updated signup");
    props.authModalType === "signup" ? setvisible(true) : setvisible(false);

    props.authModalUserType && setuserType(props.authModalUserType);

    props.authModalUserType && props.authModalUserType === "customer"
      ? setvectorImage(CutomerLoginVector)
      : setvectorImage(SellerLoginVector);
  }, [props]);

  // useEffect(() => {
  //   props.isSeller && openSignUpModal("seller");
  // }, [props]);

  return (
    <>
      {/* <Button
        className="login-button"
        type="primary"
        onClick={() => showModal()}
      >
        Sign Up
      </Button> */}
      <Modal className="circle_drop signup_drop for_login_state"
        closable={props.isCustomer ? true : false}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={null}
      >
        <div className="login-modal-container">
          {getWebViewVector()}
          <SignUpForm isAffiliate={props.isAffiliate} setvisible={setvisible} />
        </div>
      </Modal>
    </>
  );
};

const mapDispatchToProps = { openLoginModal, openSignUpModal, closeAuthModal };

const mapStateToProps = (state) => {
  return {
    authModalUserType: state.authModalReducer.userType,
    authModalType: state.authModalReducer.modalType,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
