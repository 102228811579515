


import Cookies from "universal-cookie";
const cookies = new Cookies();

export const formatDeliveryChargePayload = (data) => {
  var result;
  if (cookies.get("accountType") === "seller") {
    result = {
      additionalChargePerKg: Number(data.additionalChargePerKg),
      deliveryChargeWithInLocality: Number(data.deliveryChargeWithInLocality),
      deliveryChargeWithInDistrict: Number(data.deliveryChargeWithInDistrict),
      deliveryChargeWithInState: Number(data.deliveryChargeWithInState),
      deliveryChargeOutOfState: Number(data.deliveryChargeOutOfState),
      maxWeight: Number(data.maxWeight),
      minAmountToExcludeDeliveryCharge: Number(
        data.minAmountToExcludeDeliveryCharge
      ),
    };
  } else {
    result = {
      additionalChargePerKg: Number(data.additionalChargePerKg),
      deliveryCharge: Number(data.deliveryCharge),
      maxWeight: Number(data.maxWeight),
      minAmountToExcludeDeliveryCharge: Number(
        data.minAmountToExcludeDeliveryCharge
      ),
    };
  }
  return result;
};
