


import Axios from "../axios";
import { API_SERV_ADDR } from "../../Utils/constants";

export const fetchDistrictList = (stateList) => {
  return Axios({
    url: `${API_SERV_ADDR}/get-districts-by-state`,
    method: "POST",
    data: {
      states: stateList,
    },
  });
};

export const fetchPincodeList = (districitList) => {
  return Axios({
    url: `${API_SERV_ADDR}/get-pin-codes-by-districts`,
    method: "POST",
    data: {
      districts: districitList,
    },
  });
};
