


import React, { useEffect, useState } from "react";

import { Layout } from "antd";
import { Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import HeaderLayout from "../../../../Layouts/HeaderLayout";
import FooterLayout from "../../../../Layouts/FooterLayout";
import ManageProfile from "../../../Admin/ManageProfile/manageProfile";
import SideBarMenu from "../../../Seller/SideBarMenu";
import {
  sideBarDataCustomer,
  sideBarDataWarehouse,
} from "../../../../Utils/Seller/Data";

import SortBy from "../../../Seller/SortBy";

import ManageAddress from "../ManageAddress";
import ManageMyOrders from "../ManageOrders";
import ManageReturnsCustomer from "../ManageReturns/manageReturnsCustomer";
import { ENV_TYPE } from "../../../../Utils/constants";

const { Content, Sider } = Layout;
const CustomerProfile = (props) => {
  const [searchVal, setSearchval] = useState("");
  const [urlPath, setUrlPath] = useState("");
  const [activeTab, setactiveTab] = useState();
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)) {
      //setCollapsed(true);
    } else setCollapsed(false);
  }, []);

  useEffect(() => {
    setactiveTab(props.location.pathname.split("/").pop());
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <div className="nav-side-content-admin">
      {/* <HeaderLayout pathname={urlPath} /> */}

      <div className="container">
        <div className="hr_sort_order">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "20px",
            }}
          >
            {(props.location.pathname.includes("/profile/manage-orders") ||
              props.location.pathname.includes("/profile/manage-returns")) && (
              <SortBy isCustomer />
            )}
          </div>
        </div>
        <div className="profile_column_data cust-bar-hide">
          {!collapsed && (
            <Sider
              className="nav-side-bar-admin"
              trigger={null}
              collapsed={collapsed}
            >
              <SideBarMenu
                sideBarData={sideBarDataCustomer}
                activeTab={activeTab}
              />
            </Sider>
          )}
          <div className="admin-layout-content right_profile_layout">
            {ENV_TYPE === "production" ? ( //for production
              <Switch>
                <Route
                  exact
                  path="/profile"
                  render={(props) => <ManageProfile {...props} isCustomer />}
                />
                <Route
                  exact
                  path="/profile/manage-address"
                  render={(props) => <ManageAddress {...props} isCustomer />}
                />
                <Route
                  exact
                  path="/profile/manage-orders"
                  render={(props) => <ManageMyOrders isCustomer />}
                />
                <Route
                  exact
                  path="/profile/manage-returns"
                  render={(props) => <ManageReturnsCustomer {...props} />}
                />
                <Route
                  render={(props) => {
                    return <Redirect to="/404" />;
                  }}
                />
              </Switch>
            ) : (
              //for enviornments other than production
              <Switch>
                <Route
                  exact
                  path="/customer/profile"
                  render={(props) => <ManageProfile {...props} isCustomer />}
                />
                <Route
                  exact
                  path="/customer/profile/manage-address"
                  render={(props) => <ManageAddress {...props} isCustomer />}
                />
                <Route
                  exact
                  path="/customer/profile/manage-orders"
                  render={(props) => <ManageMyOrders isCustomer />}
                />
                <Route
                  exact
                  path="/customer/profile/manage-returns"
                  render={(props) => <ManageReturnsCustomer {...props} />}
                />
                <Route
                  render={(props) => {
                    return <Redirect to="/404" />;
                  }}
                />
              </Switch>
            )}
          </div>
        </div>
      </div>

      {/* <FooterLayout /> */}
    </div>
  );
};

export default CustomerProfile;
