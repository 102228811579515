


import React, { useState, useEffect } from "react";
import { Tabs, Typography } from "antd";
import { connect } from "react-redux";

import ManageCustomerTable from "../ManageCustomer/manageCustomerTable";
import {
  fetchUsers,
  fetchVendors,
} from "../../../ApiAction/AdminApiAction/adminApi";
const { Title } = Typography;
const { TabPane } = Tabs;
const ManageSeller = (props) => {
  const [sellerrData, setSellerData] = useState([]);
  const [activeKey, setActiveKey] = useState("APPROVED");
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoadingSeller, setIsLoadingSeller] = useState(false);

  useEffect(() => {
    getsellers(1, activeKey, props.searchVal);
  }, [props]);

  const getsellers = (page, key = activeKey, search = props.searchVal) => {
    setCurrentPage(page)
    setActiveKey(key);
    const term = [];

    // term.push({
    //   accountType: ["seller"],
    // });
    if (props.sort && props.sort.all === false) {
      term.push({
        isArchived: [
          props.sort ? (props.sort.archive ? "true" : "false") : "false",
        ],
      });
      term.push({
        block: [props.sort ? (props.sort.block ? "true" : "false") : "false"],
      });
    }
    term.push({
      status: [key],
    });
    if(props.isAffiliate) {
      term.push({
        accountType: ["affiliate"]
      });
    }

    const payload = {
      key: search,
      sort: props.sort ? props.sort.sortType : "date",
      sortBy: props.sort ? props.sort.sortBy : "asc",
      accountType: props.isAffiliate? "affiliate" : "seller",
      offset: 10 * (page - 1),
      size: 10,
      filter: {
        term: term,
      },
    };
    setIsLoadingSeller(true);
   (props.isAffiliate? fetchUsers(payload) : fetchVendors(payload))
      .then((response) => {
        setSellerData(response.data.documents);
        setPage(Math.ceil(response.data.count / 10) * 10);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingSeller(false);
      });
  };

  return (
    <div className="admin-manage-customer">
      {/* <div className="content-name-header"> Seller</div> */}
      <h1 className="content-name-header sub-title-tags" level={4}>
        {" "}
        {props.isAffiliate? "Affiliate" : "Seller"}
      </h1>
      <Tabs
        // tabBarExtraContent={operations}
        onChange={(activeKey) => {
          setSellerData([]);
          getsellers(1, activeKey);
        }}
        defaultActiveKey="Approved"
        style={{ overflowX: "auto" }}
      >
        <TabPane tab="Approved" key="APPROVED">
          <ManageCustomerTable
            userData={sellerrData}
            isSeller={props.isSeller}
            isAffiliate={props.isAffiliate}
            isLoadingSeller={isLoadingSeller}
            total={page}
            currentPage={currentPage}
            onPageChange={getsellers}
          />
        </TabPane>
        <TabPane tab="Requested" key="REQUESTED">
          <ManageCustomerTable
            delete
            requests
            isSeller={props.isSeller}
            isAffiliate={props.isAffiliate}
            isLoadingSeller={isLoadingSeller}
            userData={sellerrData}
            total={page}
            currentPage={currentPage}
            onPageChange={getsellers}
          />
        </TabPane>
        <TabPane tab="Pending" key="PENDING">
          <ManageCustomerTable
            delete
            isSeller={props.isSeller}
            isAffiliate={props.isAffiliate}
            isLoadingSeller={isLoadingSeller}
            userData={sellerrData}
            total={page}
            currentPage={currentPage}
            onPageChange={getsellers}
          />
        </TabPane>
        <TabPane tab="Rejected" key="REJECTED">
          <ManageCustomerTable
            delete
            isSeller={props.isSeller}
            isAffiliate={props.isAffiliate}
            isLoadingSeller={isLoadingSeller}
            userData={sellerrData}
            total={page}
            currentPage={currentPage}
            onPageChange={getsellers}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.customerData,
  };
};
export default connect(mapStateToProps)(ManageSeller);
