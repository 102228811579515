


import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Typography,
  Pagination,
  Row,
  Col,
  Collapse,
  Button,
  message,
  Tabs,
} from "antd";
import { connect } from "react-redux";
import "./manageOrder.scss";
import { withRouter } from "react-router-dom";

import InitiateEwayBillGeneration from "./initiateEwayBillGeneration";
import ManageAllOrderGroups from "./manageAllOrderGroups";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const ManageAllOrders = (props) => {
  const { isWarehouse, isStore } = props;

  const [activeTab, setactiveTab] = useState("NOT_CONFIRMED");

  const handleChangeTab = (activeKey) => {
    console.log(activeKey);
    setactiveTab(activeKey);
  };

  return (
    <div className="manage-products-container">
      {" "}
      <div className="manage-products-header">
        {" "}
        <h1 level={4} className="sub-title-tags"> All Orders</h1>
        {isWarehouse ? <InitiateEwayBillGeneration action /> : <></>}
      </div>
      <Tabs
        defaultActiveKey={activeTab}
        onChange={handleChangeTab}
        style={{ overflowX: "auto" }}
      >
        <TabPane tab="Not-Confirmed Orders" key="NOT_CONFIRMED">
          <ManageAllOrderGroups
            searchVal={props.searchVal}
            orderStatus="NOT_CONFIRMED"
            vendorId={props.vendorId}
            activeTab={activeTab}
            isWarehouse={isWarehouse}
            isStore={isStore}
          />
        </TabPane>
        <TabPane tab="Incoming Orders" key="PENDING">
          <ManageAllOrderGroups
            searchVal={props.searchVal}
            orderStatus="PENDING"
            vendorId={props.vendorId}
            activeTab={activeTab}
            isWarehouse={isWarehouse}
            isStore={isStore}
          />
        </TabPane>
        <TabPane tab="In-Progress Orders" key="INPROGRESS">
          <ManageAllOrderGroups
            searchVal={props.searchVal}
            orderStatus="INPROGRESS"
            vendorId={props.vendorId}
            activeTab={activeTab}
            isWarehouse={isWarehouse}
            isStore={isStore}
          />
        </TabPane>
        <TabPane tab="Delivered Orders" key="DELIVERED">
          <ManageAllOrderGroups
            searchVal={props.searchVal}
            orderStatus="DELIVERED"
            vendorId={props.vendorId}
            // delivered
            activeTab={activeTab}
            isWarehouse={isWarehouse}
            isStore={isStore}
          />
        </TabPane>
        <TabPane tab="Cancelled Orders" key="CANCELLED">
          <ManageAllOrderGroups
            searchVal={props.searchVal}
            orderStatus="CANCELLED"
            vendorId={props.vendorId}
            // delivered
            activeTab={activeTab}
            isWarehouse={isWarehouse}
            isStore={isStore}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    sort: state.manageOrderReducer,
  };
};
export default withRouter(connect(mapStateToProps)(ManageAllOrders));
