


import React, { useState, useEffect } from "react";
import moment from "moment";

import { Modal, Button, Select, Input, Row, Col, Image } from "antd";
import { CheckCircleOutlined, CheckCircleTwoTone } from "@ant-design/icons";
import { FILE_PATH } from "../../../Utils/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const { Option } = Select;
const confirm = Modal.confirm;
const TrackingDetails = (props) => {
  const [status, setStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [trackingId, setTrackingId] = useState("");

  const [trackingLink, setTrackingLink] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNo, setDriverNo] = useState("");

  const [statusList, setStatusList] = useState([
    "CONFIRMED",
    "PICKED",
    "PACKED",
    "SHIPPED",
    "DELIVERED",
  ]);

  useEffect(() => {
    console.log(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    // setOrderData(props.orderData)
    const ordersStatus = props.selectedItems.map(
      (item) => item.itemOrderStatus.currentStatus
    );
    if (ordersStatus.includes("SHIPPED")) {
      setStatus("DELIVERED");
      setCurrentStatus("SHIPPED");
      setStatusList(["DELIVERED"]);
    } else if (ordersStatus.includes("PACKED")) {
      setStatus("SHIPPED");
      setCurrentStatus("PACKED");
      setStatusList(["SHIPPED"]);
    } else if (ordersStatus.includes("PICKED")) {
      setStatus("PACKED");
      setCurrentStatus("PICKED");
      setStatusList(["PACKED"]);
    } else if (ordersStatus.includes("CONFIRMED")) {
      setStatus("PICKED");
      setCurrentStatus("CONFIRMED");
      setStatusList(["PICKED"]);
    } else if (ordersStatus.includes("ORDERED")) {
      setStatus("CONFIRMED");
      setCurrentStatus("ORDERED");
      setStatusList(["CONFIRMED"]);
    } else if (ordersStatus.includes("DELIVERED")) {
      setStatus("DELIVERED");
      setCurrentStatus("DELIVERED");
      setStatusList([]);
    } else if (ordersStatus.includes("CANCELLED")) {
      setStatus("CANCELLED");
      setCurrentStatus("CANCELLED");
      setStatusList([]);
    } else if (ordersStatus.includes("RETURN_REQUESTED")) {
      setStatus("RETURN_REQUESTED");
      setCurrentStatus("RETURN_REQUESTED");
      setStatusList([]);
    }
  }, [props]);

  const saveChanges = () => {
    const prodId = props.selectedItems.map((item) => item.orderId);

    confirm({
      title: `Do you want to change the status ?`,
      className: "create-confirm-modal",
      centered: true,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        props.saveChanges(
          prodId,
          status,
          props.accountType,
          trackingId,
          trackingLink,
          driverNo,
          driverName,
          vehicleNo
        );
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <Modal
      visible={props.visible}
      onOk={() => props.setCancel(false)}
      onCancel={() => {
        props.setCancel(false);
      }}
      width={500}
      footer={null}
    >
      <div className="view-invoice-layout">
        <div className="detail-modal-heading">Tracking Details</div>
        <div className="">
          Order Id:
          <span
            className="manage-order-product-name"
            style={{ marginLeft: "10px" }}
          >
            {props.groupId}
          </span>
        </div>
      </div>
      <div className="">
        {props.selectedItems.map((item) => (
          <React.Fragment>
            <Row>
              <Col
                xs={24}
                sm={24}
                md={18}
                lg={18}
                xl={18}
                className="add-image-col"
              >
                <div className="flex">
                  <img width={100} height={100} src={FILE_PATH + item.image} />
                  <div className="tracking-product">
                    {item.productName}
                    <div className="flex" style={{ fontSize: "12px" }}>
                      <div>
                        {`${item.itemCurrentPrice.quantity}  ${item.itemCurrentPrice.unit} `}
                      </div>
                      <div style={{ marginLeft: "15px" }}>
                        Qty:{item.quantity}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                xl={6}
                className="add-image-col"
              >
                <div className="tracking-price">
                  ₹ {item.itemCurrentPrice.sellingPrice}
                </div>
              </Col>
            </Row>
            <div className="track-item">
              {item.itemOrderStatus.orderedDate && (
                <div className="order-track-status">
                  <CheckCircleTwoTone twoToneColor="#52c41a" /> Ordered:{" "}
                  {moment(item.itemOrderStatus.orderedDate).format(
                    " DD-MM-YYYY, h:mm:ss a"
                  )}
                </div>
              )}
              {item.itemOrderStatus.confirmedDate && (
                <div className="order-track-status">
                  <CheckCircleTwoTone twoToneColor="#52c41a" /> Confirmed:{" "}
                  {moment(item.itemOrderStatus.confirmedDate).format(
                    " DD-MM-YYYY, h:mm:ss a"
                  )}
                </div>
              )}
              {item.itemOrderStatus.pickedDate && (
                <div className="order-track-status">
                  <CheckCircleTwoTone twoToneColor="#52c41a" /> Picked:{" "}
                  {moment(item.itemOrderStatus.pickedDate).format(
                    " DD-MM-YYYY, h:mm:ss a"
                  )}
                </div>
              )}
              {item.itemOrderStatus.packedDate && (
                <div className="order-track-status">
                  <CheckCircleTwoTone twoToneColor="#52c41a" /> Packed:{" "}
                  {moment(item.itemOrderStatus.packedDate).format(
                    " DD-MM-YYYY, h:mm:ss a"
                  )}
                </div>
              )}
              {item.itemOrderStatus.shippedDate && (
                <div className="order-track-status">
                  <CheckCircleTwoTone twoToneColor="#52c41a" /> Shipped:{" "}
                  {moment(item.itemOrderStatus.shippedDate).format(
                    " DD-MM-YYYY, h:mm:ss a"
                  )}
                </div>
              )}
              {item.itemOrderStatus.deliveredDate && (
                <div className="order-track-status">
                  <CheckCircleTwoTone twoToneColor="#52c41a" /> Delivered:{" "}
                  {moment(item.itemOrderStatus.deliveredDate).format(
                    " DD-MM-YYYY, h:mm:ss a"
                  )}
                </div>
              )}
              {item.itemOrderStatus.cancelledDate && (
                <div className="order-track-status">
                  <CheckCircleTwoTone twoToneColor="#52c41a" /> Cancelled:{" "}
                  {moment(item.itemOrderStatus.cancelledDate).format(
                    " DD-MM-YYYY, h:mm:ss a"
                  )}
                </div>
              )}
            </div>
          </React.Fragment>
        ))}
      </div>

      <div className="contact-modal-form update-tracking-details">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="add-image-col"
          >
            <Select
              onSelect={(value, option) => {
                setStatus(option.value);
              }}
              value={status}
              showArrow={false}
              className="tracking-select tracking-input"
              style={{ width: "100%" }}
            >
              {statusList &&
                statusList.map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  );
                })}
            </Select>
          </Col>
        </Row>
        {status === "SHIPPED" &&
          (props.accountType == null || props.accountType == "customer") && (
            <React.Fragment>
              <Row>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="add-image-col"
                >
                  <Input
                    placeholder="Tracking ID"
                    className="tracking-input"
                    value={trackingId}
                    onChange={(e) => setTrackingId(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    placeholder="Tracking Link"
                    className="tracking-input"
                    value={trackingLink}
                    onChange={(e) => setTrackingLink(e.target.value)}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
        {status === "SHIPPED" &&
          props.accountType != null &&
          props.accountType != "customer" && (
            <React.Fragment>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    placeholder="Vehicle Registration Number"
                    className="tracking-input"
                    value={vehicleNo}
                    onChange={(e) => setVehicleNo(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Input
                    placeholder="Driver Name"
                    className="tracking-input"
                    value={driverName}
                    onChange={(e) => setDriverName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <PhoneInput
                    country={"in"}
                    className="driver-number"
                    placeholder="drver mobile number"
                    countryCodeEditable={false}
                    // value={this.state.phone}
                    onChange={(phone) => setDriverNo(phone)}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
      </div>
      <br></br>
      <div className="tracking-details-button-wrapper">
        <Button
          className="button-seller-request-reject"
          style={{ width: "200px" }}
          onClick={() => {
            props.setCancel(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="button-seller-request-approve"
          onClick={() => saveChanges()}
          disabled={
            currentStatus === "ORDERED" ||
            currentStatus === "CONFIRMED" ||
            currentStatus === "PICKED" ||
            currentStatus === "PACKED" ||
            currentStatus === "SHIPPED"
              ? false
              : true
          }
        >
          Save Changes
        </Button>
      </div>
    </Modal>
  );
};
export default TrackingDetails;
