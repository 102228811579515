


import React, { useEffect, useState, useContext } from "react";
import "./footer.scss";

import { Link } from "react-router-dom";

import FooterListColumn3 from "./Components/FooterList";
import ContactUs from "./Components/ContactUs";
import SocialMedia from "./Components/SocialMedia";
import PaymentOptions from "./Components/PaymentOptions";
import AccountTypeContext from "../../components/Admin/accountTypeContext"
import playStore from '../../Assets/Images/Icons/googlePlaySVG.svg';
import appleStore from '../../Assets/Images/Icons/appleStore.png';
import {
    AboutItemList,
    HeadOfficeAddress,
    RegisteredOfficeAddress,
    PolicyItemList,
    SocialMediaItemList,
    ContactInfo,
    HelpItemList,
    COOInfo,
    AppStores,
    SellerInfo,
    MarketingService,
    FinanceCustomerService,
    CustomerReview
} from "./FooterListJson";
// import { Link } from "react-router-dom";
import FooterLinks from "./FooterLinks";
import { Row, Col } from "antd";
import { getSocialMediaLinks } from "../../ApiAction/AdminApiAction/adminApi";
import formatSocialMediaData from "./FooterHelper/formatSocialMediaData";
import { IoLocationSharp } from "react-icons/io5"
import { AiFillPhone } from "react-icons/ai"

const fetchUrl = (url, accountTypeContext) => {
    // eslint-disable-next-line default-case
    switch (url) {
        case "about": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/aboutUs`;
        }
        case "sell": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/aboutUs`;
        }
        case "returnP": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/returnPolicy`;
        }
        case "shipping": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/shippingPolicy`;
        }
        case "terms": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/termsAndCondition`;
        }
        case "privacy": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/privacyPolicy`;
        }
        case "cookies": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/cookiesPolicy`;
        }
        case "refund": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/refundPolicy`;
        }
        case "dnd": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/dndPolicy`;
        }
        case "onlinePayment": {
            return `/${accountTypeContext ? accountTypeContext : "customer"}/content/onlinePayment`;
        }
        // case "privacy": {
        //     return "AbortController";
        // }
        // case "privacy": {
        //     return "AbortController";
        // }
        default: {
            return "";
        }
    }
};


const openInNewTabSocial = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};
const FooterView = () => {
    const [socialMediaData, setsocialMediaData] = useState();
    useEffect(() => {
        getSocialMediaLinks()
            .then((response) => {
                console.log(response)
                if (response.status === 200) {
                    if (response.data.status === "success") {

                        const res = response.data.socialMediaProfiles;
                        // console.log("response.data.socialMediaProfiles", response.data.socialMediaProfiles);
                        var array = Object.keys(response.data.socialMediaProfiles)
                            .map(function (key) {
                                return {
                                    "link": response.data.socialMediaProfiles[key],
                                    "text": key
                                };
                            });
                        console.log("array", array);

                        setsocialMediaData(
                            array
                        );
                    }
                }
            })
            .catch(() => { });
    }, []);

    const accountTypeContext = useContext(AccountTypeContext);
    console.log(process.env.dirname)




    return (
        <div className="footer">
            <div className="contain">
                <div className="footerDetails">
                    <div className="footerContactInfo">
                        <h4 className="footerMainHeading">CONTACT US</h4>
                        <div className="footerSubBox">
                            <IoLocationSharp className="footer-icons" />
                            <div className="footerSubBoxInfo">
                                {HeadOfficeAddress && HeadOfficeAddress.map((i, index) =>
                                    <p key={index}> {i.text}</p>
                                )}
                                <div className="paddingBox"></div>
                                {RegisteredOfficeAddress && RegisteredOfficeAddress.map((i, index) =>
                                    <p key={index}>{i.text}</p>
                                )}
                            </div>
                        </div>
                        {/* <div className="paddingBox"></div>
                        <div className="footerSubBox">
                            <AiFillPhone className="footer-icons" />
                            <div className="footerSubBoxInfo">
                                {ContactInfo && ContactInfo.map(i =>
                                    <p> {i.text}</p>
                                )}
                            </div>
                        </div> */}
                        <div className="paddingBox"></div>
                        <div className="footerSubBox">
                            <AiFillPhone className="footer-icons" />
                            <div className="footerSubBoxInfo">
                                <span style={{ display: "flex", columnGap: "0.5em" }}><p>For Enquires - Franchise</p><a href="/Franchise_Agreement.pdf" download style={{ textDecoration: "underline"}}>(Agreement)</a></span>
                                <p>+91 7376183049</p>
                            </div>
                        </div>
                        <div className="paddingBox"></div>
                        <div className="footerSubBox">
                            <AiFillPhone className="footer-icons" />
                            <div className="footerSubBoxInfo">
                                {COOInfo && COOInfo.map((i, index) =>
                                    <p key={index}> {i.text}</p>
                                )}
                            </div>
                        </div>
                        <div className="paddingBox"></div>
                        <div className="footerSubBox">
                            <AiFillPhone className="footer-icons" />
                            <div className="footerSubBoxInfo">
                                {SellerInfo && SellerInfo.map((i, index) =>
                                    <p key={index}> {i.text}</p>
                                )}
                            </div>
                        </div>
                        <div className="paddingBox"></div>
                        <div className="footerSubBox">
                            <AiFillPhone className="footer-icons" />
                            <div className="footerSubBoxInfo">
                                {MarketingService && MarketingService.map((i, index) =>
                                    <p key={index}> {i.text}</p>
                                )}
                            </div>
                        </div>
                        <div className="paddingBox"></div>
                        <div className="footerSubBox">
                            <AiFillPhone className="footer-icons" />
                            <div className="footerSubBoxInfo">
                                {FinanceCustomerService && FinanceCustomerService.map((i, index) =>
                                    <p key={index}> {i.text}</p>
                                )}
                            </div>
                        </div>
                        <div className="paddingBox"></div>
                        <div className="footerSubBox">
                            <AiFillPhone className="footer-icons" />
                            <div className="footerSubBoxInfo">
                                {CustomerReview && CustomerReview.map((i, index) =>
                                    <p key={index}> {i.text}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="footerNavLinkBox">
                        <div className="footerNavSubInfo">
                            <h4 className="footerMainHeading">ABOUT</h4>
                            {AboutItemList && AboutItemList.map((i, index) =>
                                <Link key={index} to={fetchUrl(i.link, accountTypeContext)}> {i.text}</Link>)
                            }
                        </div>
                        <div className="footerNavSubInfo">
                            <h4 className="footerMainHeading">HELP</h4>
                            {HelpItemList && HelpItemList.map((i, index) =>
                                <Link key={index} to={fetchUrl(i.link, accountTypeContext)}> {i.text}</Link>)
                            }
                        </div>
                        <div className="footerNavSubInfo">
                            <h4 className="footerMainHeading">POLICY</h4>
                            {PolicyItemList && PolicyItemList.map((i, index) =>
                                <Link key={index} to={fetchUrl(i.link, accountTypeContext)}> {i.text}</Link>)
                            }
                        </div>
                        <div className="footerNavSubInfo">
                            <h4 className="footerMainHeading">SOCIAL</h4>
                            {socialMediaData && socialMediaData.map((i, index) =>
                                <a key={index} href={i.link} style={{textTransform: "capitalize"}}>{i.text}</a>
                                )
                            }
                        </div>
                        <div className="footerNavSubInfo">
                            <h4 className="footerMainHeading">GET OUR APP{AppStores.length > 1 && "S"}</h4>
                            <a href="https://play.google.com/store/apps/details?id=com.app" target="_blank" rel="noopener noreferrer"><img src={playStore} alt="playStore" className="appStoreImages" /></a>
                            <a href="https://apps.apple.com/us/app/id1577895771" target="_blank" rel="noopener noreferrer"><img src={appleStore} alt="appleStore" className="appStoreImages" /></a>
                        </div>
                    </div>
                </div>
                <FooterLinks />
            </div>
        </div>
    );
};

export default FooterView;
