


import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import Homepage from "../../Views/HomePage";
import Customer from "../../components/Customer";

const { Content } = Layout;

export default function ContentLayout(props) {
  const { isCustomer } = props;
  const [customerView, setcustomerView] = useState();

  useEffect(() => {
    if (window.location.href.indexOf("/customer") > 1) {
      setcustomerView(true);
    } else setcustomerView(false);
  }, []);
  return (
    <Content
      style={{}}
      className={isCustomer ? "content-style" : "content-style-non-customer"}
    >
      <div className="site-layout-content">
        <Customer {...props} />
      </div>
    </Content>
  );
}
