


import React, { useEffect, useState } from "react";

import { Typography } from "antd";
import "./specifications.scss";

const { Text } = Typography;
export default function Specifications(props) {
  const { specs } = props;
  const [specsList, setspecsList] = useState([]);

  const chunkArray = (myArray, chunk_size) => {
    var index = 0;
    var tempArray = [];
    let temp = myArray.filter((data) => data.value !== "");
    var arrayLength = temp.length;

    for (index = 0; index < arrayLength; index += chunk_size) {
      let myChunk = temp.slice(index, index + chunk_size);
      tempArray.push(myChunk);
    }

    return tempArray;
  };
  useEffect(() => {
    setspecsList(chunkArray(specs, 3));
  }, []);

  return (
    <div className="specifications-container hr_pdt_specification">
      {specsList.map((data, index) => {
        return (
          <div
            key={index}
            className={
              index % 2 === 0 ? "specifications-odd" : "specifications-even"
            }
          >
            {data.map((specifications) => {
              return (
                <div className="spec-each" key={specifications.key}>
                  <>
                    {" "}
                    <Text>{specifications.key}: </Text>
                    <Text className="spec_value">{specifications.value} </Text>
                  </>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
