


import React, { useEffect } from "react";
import { Modal, Button, Form, Input } from "antd";

const AddConfigureItem = (props) => {
  const [form] = Form.useForm();
  const formFields = [{ name: "name", placeHolder: "Enter Name" }];

  const onFinish = (values) => {
    const value = values.name.toUpperCase();

    const payload = {
      idProofField: value,
    };
    props.setSuccess(payload, props.type, props.isEdit);
  };

  const handleCancel = () => {
    form.resetFields();
    props.setCancel(false);
  };

  useEffect(() => {
    if (props.selectedId) {
      form.setFieldsValue({ name: props.selectedId });
    } else form.resetFields();
  }, [props.selectedId]);
  return (
    <Modal
      visible={props.visible}
      onOk={() => {
        props.setCancel(false);
        form.resetFields();
      }}
      onCancel={() => {
        props.setCancel(false);
        form.resetFields();
      }}
      width={500}
      footer={null}
    >
      <div className="contact-modal-form">
        <Form
          form={form}
          name="normal_login"
          style={{ marginTop: "25px" }}
          initialValues={{ name: props.selectedId }}
          onFinish={onFinish}
        >
          {formFields.map((data) => {
            return (
              <React.Fragment>
                Type Name <span className="required-field-asterisk">*</span>
                <Form.Item
                  hasFeedback
                  name={data.name}
                  rules={[
                    {
                      required: true,
                      message: "This field is required !",
                    },
                  ]}
                >
                  <Input
                    style={{ textTransform: "uppercase" }}
                    placeholder={data.placeHolder}
                  />
                </Form.Item>
              </React.Fragment>
            );
          })}

          <div className="seller-action-button">
            <Form.Item>
              <Button
                style={{ width: "200px" }}
                className="cancel-config-button"
                onClick={() => {
                  handleCancel();
                }}
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: "200px" }}
                htmlType="submit"
                className="button-seller-request-approve"
              >
                Save
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
export default AddConfigureItem;
