


export const formatQUickCheckoutTabData = (orderList) => {
  var res = [];

  orderList.map((data) => {
    var temp = {
      title: `Order: ${data.checkoutCartId}`,
      content: "New Tab Pane",
      key: data.checkoutCartId,
      data: data,
    };
    res.push(temp);
  });
  return res;
};
