import React, { useEffect, useState, useContext } from 'react'
import { HiShoppingCart } from "react-icons/hi";
import { FILE_PATH } from '../../../../Utils/constants';
import { reduceStringLength } from '../../../../Utils/Helpers';
import NumberFormat from 'react-number-format';
import { withRouter } from "react-router-dom";
import { ENV_TYPE } from '../../../../Utils/constants';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { message, Modal } from 'antd';
import { connect } from "react-redux";
import { addToCart } from "../../../../ApiAction/SellerApiActions/sellerApi"
import { openLoginModal } from '../../../../ReduxStore/action/authModalActions';
import { fetchCartCount, fetchCartItems } from "../../../../ReduxStore/action";
import { TiArrowRightThick } from "react-icons/ti";
import FmcgVarient from './FmcgVarient';
import { AiFillCaretDown } from "react-icons/ai";
import { AppContext } from '../../../GeneralComponents/appContext';


function FmcgProduct(props) {
    const { product } = props;
    const cookies = new Cookies();
    const history = useHistory();
    const accountType = cookies.get("accountType")
                        ? cookies.get("accountType")
                        : "customer";
    const [activeCustomer, setactiveCustomer] = useState(
      JSON.parse(localStorage.getItem("activeCustomer"))
    );
    const [quantity, setQuantity] = useState(1);
    const [processedPrice, setProcessedPrice] = useState();
    const [price, setPrice] = useState("");
    const [sellingPrice, setSellingPrice] = useState("");
    const [salesIncentive, setsalesIncentive] = useState("");
    const [selectedVariant, setSelectedVariant] = useState("");
    const [availableQuantity, setAvailableQuantity] = useState("");
    const [selectedItem, setSelectedItem] = useState();
    const warning = Modal.warning;
    const [selectedQuantity, setSelectedQuantity] = useState("");
    const [selectedDiscount, setSelectedDiscount] = useState("");
    const [inCart, setinCart] = useState(false);
    const [showVarient, setShowVarient] = useState(false);
    const ProviderProps = useContext(AppContext);

    useEffect(() => {
      setProcessedPrice(product?.processedPriceAndStock);
      if (product.processedPriceAndStock && product.processedPriceAndStock.length > 0) {
        setSelectedItem(product.processedPriceAndStock[0]);
        setPrice(product.processedPriceAndStock[0].price);
        setSellingPrice(product.processedPriceAndStock[0].sellingPrice);
        // setsalesIncentive(product.processedPriceAndStock[0]?.salesIncentive);
        setSelectedVariant(product.processedPriceAndStock[0].serialNumber);
        setAvailableQuantity(product.processedPriceAndStock[0]?.stock);
        setSelectedQuantity(
          `${product.processedPriceAndStock[0].quantity} ${product.processedPriceAndStock[0].unit}`
        );
        setSelectedDiscount(product.processedPriceAndStock[0].discount);
      }
    }, [product]);

    const handleRedirection = () => {
        if (accountType === "store") {
          history.push(
            `/store/linked-warehouse/product-detail/${product.productId}`
          );
        } else if (accountType === "customer") {
          history.push(
            ENV_TYPE === "production"
              ? `/product-detail/${product.productId}`
              : `/customer/product-detail/${product.productId}`
          );
        } else if (accountType === "warehouse") {
          history.push(
            `/warehouse/linked-warehouse/product-detail/${product.productId}`
          );
        }
    }

    useEffect(() => {
      if(product?.processedPriceAndStock.length > 0) {
        let temp = product?.processedPriceAndStock?.map((variant) => {
          if(handleCheckInCart(variant)) {
            return
          }
        })
      }
    }, [
      localStorage.getItem("guestCart"),
      product,
      props.cartItems,
      selectedItem,
      props
    ]);

  const addTocartProduct = async () => {
    if(product?.processedPriceAndStock.length > 1) {
      setShowVarient(true)
      return
    }
    if (availableQuantity > 0) {
      if (activeCustomer && cookies.get("accountType") && cookies.get("activeUserPincode")) {
        if (props.cartItems) {
          if (inCart) {
            props.history.push(
              ENV_TYPE === "production" ? `/cart` : `/customer/cart`
            );
          } else {
            handleCustomerAddToCart();
          }
        } else handleCustomerAddToCart();
      } else {
        if (cookies.get("activeUserPincode") || isAllIndiaProduct()) {
          handleUpdateGuestCart();
        } else {
          console.log("Is all India :: ", isAllIndiaProduct())
          ProviderProps.setPincodeModalVisible(true);
          ProviderProps.setProduct(product);
          ProviderProps.setSelectedItem(selectedItem);
          ProviderProps.setQuantity(quantity);
          message.warning("Please enter PINCODE")
        }
      }
    } else message.warning("Product out of stock");
  };

  const isAllIndiaProduct = () => {
    return product?.serviceLocations.includes('All India') ? true : false
  }

  const handleCustomerAddToCart = () => {
    const paylaod = {
      userId: cookies.get("userId"),
      accountType: cookies.get("accountType"),
      item: {
        productId: product.productId,

        vendorId: product.vendorId,
        vendorType: product.vendorType,
        quantity: Math.abs(quantity),
        itemCurrentPrice: selectedItem,
      },
    };

    addToCart(paylaod)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === "success") {
            props.fetchCartCount(props.cartCountStore + 1);
            message.success(response.data.message);
          } else {
            message.error(response.data.message);
          }
        }
      })
      .catch((error) => {
        message.error("Cart Error", error.message)
      })
      .finally(() => {});
  };

  const handleUpdateGuestCart = async () => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart"));
    console.log("Selected ITEM :: ", selectedItem)
    const temp = {
      productId: product.productId,
      productName: product.productName,
      vendorId: product.vendorId,
      vendorType: product.vendorType,
      quantity: quantity,
      image: product.images[0],
      itemCurrentPrice: selectedItem,
      processedPriceAndStocks: product.processedPriceAndStock,
      itemId: product.productId + selectedItem.serialNumber,
    };
    console.log(temp, guestCart, "guest cart validation");
    if (guestCart) {
      // const isAlreadyExists = await guestCart.find((item) => {
      //   return (
      //     item.productId == temp.productId &&
      //     item.itemCurrentPrice.serialNumber ===
      //       temp.itemCurrentPrice.serialNumber
      //   );
      // });

      if (inCart) {
        // message.warning("Product already added to cart");
        props.history.push(
          ENV_TYPE === "production" ? `/cart` : `/customer/cart`
        );
      } else {
        localStorage.setItem("guestCart", JSON.stringify([...guestCart, temp]));
        message.success("Product added to cart");
        //this part of code is for calculating guest cart count
        const cartCount = guestCart.reduce((prev, cur) => {
          return prev + cur.quantity;
        }, 0);
        props.fetchCartCount(cartCount + temp.quantity);
      }
    } else {
      localStorage.setItem("guestCart", JSON.stringify([temp]));
      message.success("Product added to cart");
      //this part of code is for calculating guest cart count
      props.fetchCartCount(temp.quantity);
    }
  };

  const handleCheckInCart = async (variant) => {
    const guestCart = JSON.parse(localStorage.getItem("guestCart"));
    let isAlreadyExists;

    if (guestCart && guestCart.length > 0) {
      isAlreadyExists = await guestCart.find((item) => {
        return (
          item.productId == product.productId
        );
      });
    } else if (props.cartItems) {
      isAlreadyExists = await props.cartItems.find((item) => {
        return (
          item.productId == product.productId
        );
      });
    }

    if (isAlreadyExists){
      setinCart(true);
      return true;
    }
    else { setinCart(false)
    return false};
  };

    return (
        <div className="fmcg-each-container-productlist-each-box">
            <span className="fmcg-each-container-off-percent">
                <p>{ parseInt(selectedItem?.discount)}% OFF</p>
            </span>
            <div onClick={()=> {
                    handleRedirection()
                }} className="fmcg-productlist-each-top-part">
                <img async="on" src={ FILE_PATH + product?.images[0]} loading="lazy" className="fmcg-productlist-each-product-image" />
            </div>
            <div className="fmcg-productlist-each-bottom-part">
                <span>
                    <p className="fmcg-productlist-each-product-name">{reduceStringLength(product?.name,35)}</p>
                    <p className="fmcg-productlist-each-product-vendor-name">{reduceStringLength(product?.vendor,20)}</p>
                </span>
                <span>
                    <span style={{display: "flex", columnGap: "0.5em", alignItems: "center"}}><p className="fmcg-productlist-each-product-mrp-price"><NumberFormat value={selectedItem?.price.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></p>
                    <p className="fmcg-productlist-each-product-varients">{selectedItem?.quantity +" "+ selectedItem?.unit} {product?.processedPriceAndStock?.length > 1 && ( <AiFillCaretDown />)}</p></span>
                    <p className="fmcg-productlist-each-product-price"><NumberFormat value={selectedItem?.sellingPrice.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'₹'} /></p>
                </span>
            </div>
            { inCart ? (
                <button onClick={() => addTocartProduct()} className="fmcg-productlist-cart-button fmcg-productlist-already-carted-button"><TiArrowRightThick className="fmcg-productlist-cart-icon" /></button>
            ) : (
              <button onClick={() => addTocartProduct()} className="fmcg-productlist-cart-button" ><HiShoppingCart className="fmcg-productlist-cart-icon" /></button>
            )}
            {showVarient && (
              <FmcgVarient product={product} setShowVarient={setShowVarient} setSelectedVariant={setSelectedVariant} />
            )}


        </div>
    )
}

const mapDispatchToProps = { openLoginModal, fetchCartCount };
const mapStateToProps = (state) => {
  return {
    cartCountStore: state.cartCountReducer,
    pinCode: state.productReducer.pinCode,
    cartItems: state.cartItemsReducer,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FmcgProduct)
);

//export default FmcgProduct