


import { CUSTOMER,SORTBY } from "../action/types";

export default function warehouseReducer(state = "", action) {
  switch (action.type) {
    case CUSTOMER:
      return action.data;
     
    default:
      return state;
  }
}
