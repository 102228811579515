


import {
  blockBrand,
  archeiveBrand,
} from "../../../ApiAction/AdminApiAction/brandApi";

export const formatBrandData = (data) => {
  let result = [];

  data.map((brand) => {
    let temp = {
      brandCode: "BR" + brand.id.slice(0, 5).toUpperCase(), //for temprary use
      id: brand.id,
      name: brand.source.brandName,
      block: brand.source.block,
      isArchived: brand.source.isArchived,
      image: brand.source.image,
    };

    result.push(temp);
  });

  return result;
};

export const handleArcheiveBrand = (brandId, value, block, data) => {
  let result = archeiveBrand(brandId)
    .then((response) => {
      if (response.data.status === "success") {
        return true;
      } else result = false;
    })
    .catch(() => {});

  if (result) {
    return data.map((item) =>
      item.id === brandId
        ? { ...item, isArchived: !value, block: !block }
        : item
    );
  } else {
    return data;
  }
};

export const handleBlockBrand = (brandId, value, data) => {
  let result = blockBrand(brandId)
    .then((response) => {
      if (response.data.status === "success") {
        return true;
      } else result = false;
    })
    .catch(() => {});

  if (result) {
    return data.map((item) =>
      item.id === brandId ? { ...item, block: !value } : item
    );
  } else {
    return data;
  }
};
