


import React, { useEffect } from "react";
import { Modal, Button, Form, Input } from "antd";

const AddNewSpecification = (props) => {
  const { isEdit, data, handleEditSpecification } = props;
  const [form] = Form.useForm();
  const formFields = [
    { name: "key", placeHolder: "Specification Name" },
    { name: "value", placeHolder: "Specification Value" },
  ];

  const onFinish = (values) => {
    const payload = {
      key: values.key,
      value: values.value,
    };
    if (isEdit) {
      handleEditSpecification(data, payload);
    } else {
      if (props.setSuccess(payload)) {
        form.resetFields();
      }
    }
  };

  useEffect(() => {
    console.log(isEdit, data);
    if (isEdit && data) {
      form.setFieldsValue({
        key: data.key,
        value: data.value,
      });
    } else {
      form.setFieldsValue({
        key: "",
        value: "",
      });
    }
  }, [data, isEdit]);
  return (
    <Modal
      title={isEdit ? "Edit Specifications" : "Add Specification"}
      visible={props.visible}
      onOk={() => {
        props.setCancel(false);
        form.resetFields();
      }}
      onCancel={() => {
        props.setCancel(false);
        form.resetFields();
      }}
      width={500}
      footer={null}
    >
      <div className="contact-modal-form">
        <Form
          form={form}
          name="normal_login"
          initialValues={
            isEdit
              ? { key: data.key, value: data.value }
              : { key: "", value: "" }
          }
          onFinish={onFinish}
        >
          {formFields.map((data, index) => {
            return (
              <Form.Item
                // hasFeedback
                key={index}
                name={data.name}
                rules={[
                  {
                    type: data.ruleType ? data.ruleType : null,
                    message: data.message ? data.message : null,
                  },
                  {
                    required: data.referalId ? false : true,
                    message: "This field is required !",
                  },
                  {
                    validator: data.validator ? data.validator : "",
                  },
                ]}
              >
                {data.type && data.type === "password" ? (
                  <Input.Password
                    ref={data.ref ? data.ref : null}
                    placeholder={data.placeHolder}
                  />
                ) : (
                  <Input
                    disabled={data.name === "key" && isEdit}
                    ref={data.ref ? data.ref : null}
                    type={data.type ? data.type : null}
                    placeholder={data.placeHolder}
                  />
                )}
              </Form.Item>
            );
          })}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              {isEdit ? "Edit Specification" : "Add Specification"}
            </Button>
            <br />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default AddNewSpecification;
